import { HiPencil } from "react-icons/hi";
import { makeReloadListButton } from "./Button";
import { useState } from "react";
import React from "react";
import { JSX } from "react/jsx-runtime";
import { GenericTableWithDeleteIconRow } from "../context/variables";
import { AiOutlinePoweroff } from "react-icons/ai";
import { FaAngleDown, FaAngleUp, FaEdit, FaTrashAlt } from "react-icons/fa";
import { Pagination, Tooltip } from "@mui/material";
import LoadingSpinner from "./atoms/LoadingSpinner";
import { IconType } from "react-icons/lib";

export function genericTable(columnNames: string[], rows: string[][]) {
  const rowContents = rows.length === 0 ? [columnNames.map(() => "")] : rows;
  const table = (
    <table className="table-auto w-fit">
      <thead>
        <tr>
          {columnNames.map((name, index) => (
            <th className="px-5" key={index}>
              {name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="border-2">
        {rowContents.map((row, index) => (
          <tr className="border-b-2 last:border-b-0" key={index}>
            {row.map((cell, index) => (
              <td className="px-2 border-r-2 last:border-r-0" key={index}>
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
  return table;
}

export function genericTableEditableOld(
  columnNames: string[],
  rows: string[][],
  onEditRowClick: (row: string[]) => void
) {
  const colsWithEdit = columnNames.concat("Edit");
  let rowComponents: JSX.Element[];
  if (rows.length === 0) {
    const textCells = colsWithEdit.map((cell, index) => (
      <td className="px-2 border-r-2 last:border-r-0" key={index}>
        {""}
      </td>
    ));
    const rowComponent = (
      <tr className="border-b-2 last:border-b-0" key={0}>
        {textCells}
      </tr>
    );
    rowComponents = [rowComponent];
  } else {
    rowComponents = rows.map((row, index) => {
      const textCells = row.map((cell, index) => (
        <td className="px-2 border-r-2 last:border-r-0" key={index}>
          {cell}
        </td>
      ));
      const editCell = (
        <td
          className="px-2 border-r-2 cursor-pointer last:border-r-0"
          key={"edit"}
          onClick={() => onEditRowClick(row)}
        >
          <HiPencil className="text-2xl " />
        </td>
      );
      const rowComponent = (
        <tr className="border-b-2 last:border-b-0" key={index}>
          {textCells.concat(editCell)}
        </tr>
      );
      return rowComponent;
    });
  }
  // const rowContents = (rows.length === 0) ? [columnNames.map(()=>"")] : rows
  // const rowComponents = rowContents.map((row, index)=>{
  //   const textCells = row.map((cell, index)=><td className="px-2 border-r-2 last:border-r-0" key={index}>{cell}</td>)
  //   const editCell = <td className="px-2 border-r-2 last:border-r-0" key={"edit"} onClick={()=>onEditRowClick(row)}>{"Edit!!"}</td>
  //   const rowComponent = <tr className="border-b-2 last:border-b-0" key={index}>
  //     {textCells.concat(editCell)}
  //   </tr>
  //   return rowComponent
  // })
  const table = (
    <table className="table-auto w-fit">
      <thead>
        <tr>
          {colsWithEdit.map((name, index) => (
            <th className="px-5" key={index}>
              {name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="border-2">{rowComponents}</tbody>
    </table>
  );
  return table;
}

function veryGenericTable(
  columnNames: string[],
  rows:
    | {
        id: string;
        contents: (string | JSX.Element)[];
        allowManage?: boolean;
      }[]
    | null,
  onEditRowClick: ((id: string) => void) | null,
  makeRow: (id: string, cells: JSX.Element[], index: number) => JSX.Element
) {
  const allCols =
    onEditRowClick === null ? columnNames : columnNames.concat("Manage");
  let rowComponents: JSX.Element[];
  if (rows === null || rows.length === 0) {
    const reformattedRows = rows === null ? undefined : rows;
    const loadingCell = (
      <td colSpan={allCols.length} className="h-32 bg-[#BBBBBB]">
        <div className="flex items-center justify-center w-full h-full text-3xl font-bold text-gray-600">
          {tableLoadOrNoDataNotice(reformattedRows)}
        </div>
      </td>
    );
    rowComponents = [
      <tr className="h-10" key={0}>
        {loadingCell}
      </tr>,
    ];
    // if(rows.length === 0) {
    //   const textCells = allCols.map((cell, index)=><td className="px-2" key={index}>{"--"}</td>)
    //   const rowComponent = <tr className="h-10 border-b-2 last:border-b-0" key={0}>
    //     {textCells}
    //   </tr>
    //   rowComponents = [rowComponent]
  } else {
    rowComponents = rows.map(({ id, contents, allowManage }, index) => {
      const textCells = contents.map((cell, index) => (
        <td className="px-2" key={index}>
          {cell}
        </td>
      ));
      const allCells =
        onEditRowClick === null
          ? textCells
          : allowManage === false
          ? textCells.concat(<td key={"no edit"} />)
          : textCells.concat(
              <td
                className="px-2 cursor-pointer text-deep_blue/50 hover:text-deep_blue"
                key={"edit"}
                onClick={() => onEditRowClick(id)}
              >
                <HiPencil className="text-2xl" />
              </td>
            );
      const rowComponent = makeRow(id, allCells, index);
      return rowComponent;
    });
  }
  const table = (
    <div className="text-lg bg-white rounded-xl h-fit w-fit overflow-hidden shadow-[4px_4px_8px_2px_rgba(0,0,0,0.1)]">
      <table className="w-fit table-auto px-20">
        <thead className="h-10 px-20  bg-deep_blue text-white">
          <tr className="border-b-2 mx-20 border-[#d9d9d9]">
            {allCols.map((name, index) => (
              <th className="pl-2 pr-2 text-left" key={index}>
                {name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{rowComponents}</tbody>
      </table>
    </div>
  );
  return table;
}

function veryGenericTableAcceptsIcons(
  columnNames: string[],
  rows:
    | {
        id: string;
        contents: (string | JSX.Element)[];
        actions: JSX.Element | null;
      }[]
    | null,
  onEditRowClick: ((id: string) => void) | null,
  makeRow: (
    id: string,
    cells: JSX.Element[],
    actions: JSX.Element | null,
    index: number
  ) => JSX.Element
) {
  const allCols =
    onEditRowClick === null ? columnNames : columnNames.concat("Manage");
  let rowComponents: JSX.Element[];
  if (rows === null || rows.length === 0) {
    const reformattedRows = rows === null ? undefined : rows;
    const loadingCell = (
      <td colSpan={allCols.length} className="h-32 bg-[#BBBBBB]">
        <div className="flex items-center justify-center w-full h-full text-3xl font-bold text-gray-500">
          {tableLoadOrNoDataNotice(reformattedRows)}
        </div>
      </td>
    );
    rowComponents = [
      <tr className="h-10" key={0}>
        {loadingCell}
      </tr>,
    ];
  } else {
    rowComponents = rows.map(({ id, contents, actions }, index) => {
      const textCells = contents.map((cell, index) => (
        <td className="px-2" key={index}>
          {cell}
        </td>
      ));
      const allCells =
        onEditRowClick === null
          ? textCells
          : textCells.concat(
              <td
                className="px-2 cursor-pointer text-deep_blue/50 hover:text-deep_blue"
                key={"edit"}
                onClick={() => onEditRowClick(id)}
              >
                <HiPencil className="text-2xl" />
              </td>
            );
      const rowComponent = makeRow(id, allCells, actions, index);
      return rowComponent;
    });
  }
  const table = (
    <div className="text-lg bg-white rounded-xl h-fit w-fit overflow-hidden shadow-[4px_4px_8px_2px_rgba(0,0,0,0.1)]">
      <table className="w-fit table-auto px-20">
        <thead className="h-10 px-20  bg-deep_blue text-white">
          <tr className="border-b-2 mx-20 border-[#d9d9d9]">
            {allCols.map((name, index) => (
              <th className="pl-2 pr-2 text-left" key={index}>
                {name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{rowComponents}</tbody>
      </table>
    </div>
  );
  return table;
}

function makeRowWithIcon(
  id: string,
  cells: JSX.Element[],
  actions: JSX.Element | null,
  index: number
) {
  if (actions) {
    cells.push(<td key={"actions"}>{actions}</td>);
  }
  return (
    <tr
      className="h-10 border-b-2 border-[#d9d9d9] last:border-b-0 hover:bg-[#d9d9d980]"
      key={index}
    >
      {cells}
    </tr>
  );
}

function makeStandardRow(id: string, cells: JSX.Element[], index: number) {
  return (
    <tr
      className="h-10 border-b-2 border-[#d9d9d9] last:border-b-0 hover:bg-[#d9d9d980]"
      key={index}
    >
      {cells}
    </tr>
  );
}

function makeSelectableRow(
  id: string,
  cells: JSX.Element[],
  index: number,
  selectedId: string | null,
  setSelectedId: (id: string | null) => void
) {
  return id !== selectedId ? (
    <tr
      className="h-10 border-b-2 border-[#d9d9d9] last:border-b-0 hover:bg-[#d9d9d980] cursor-pointer"
      key={index}
      onClick={() => {
        setSelectedId(id);
      }}
    >
      {cells}
    </tr>
  ) : (
    <tr
      className="h-10 border-b-2 border-[#d9d9d9] bg-[#d9d9d9] last:border-b-0"
      key={index}
    >
      {cells}
    </tr>
  );
}

export function genericTableWithIconAction(
  columnNames: string[],
  rows:
    | {
        id: string;
        contents: (string | JSX.Element)[];
        actions: JSX.Element | null;
      }[]
    | null
) {
  return veryGenericTableAcceptsIcons(columnNames, rows, null, makeRowWithIcon);
}

export function genericTable2(
  columnNames: string[],
  rows: { id: string; contents: (string | JSX.Element)[] }[] | null
) {
  return veryGenericTable(columnNames, rows, null, makeStandardRow);
}

export function genericTableEditable(
  columnNames: string[],
  rows: { id: string; contents: string[]; allowManage?: boolean }[] | null,
  onEditRowClick: (id: string) => void
) {
  return veryGenericTable(columnNames, rows, onEditRowClick, makeStandardRow);
}

export function genericTableSelectable(
  columnNames: string[],
  rows: { id: string; contents: string[] }[] | null,
  selectedId: string | null,
  setSelectedId: (id: string | null) => void
) {
  const makeRow = (id: string, cells: JSX.Element[], index: number) =>
    makeSelectableRow(id, cells, index, selectedId, setSelectedId);
  return veryGenericTable(columnNames, rows, null, makeRow);
}

export function genericTableSelectableEditable(
  columnNames: string[],
  rows: { id: string; contents: string[] }[] | null,
  selectedId: string | null,
  setSelectedId: (id: string | null) => void,
  onEditRowClick: (id: string) => void
) {
  const makeRow = (id: string, cells: JSX.Element[], index: number) =>
    makeSelectableRow(id, cells, index, selectedId, setSelectedId);
  return veryGenericTable(columnNames, rows, onEditRowClick, makeRow);
}

export function genericTableRelatedBits(
  title: string,
  length: number | undefined,
  onReloadClick: () => void
) {
  const text = length === undefined ? title : title + " (" + length + ")";
  const loading = length === undefined;
  return (
    <div className="flex items-center space-x-4 text-2xl font-bold">
      <div>{text}</div>
      {makeReloadListButton(onReloadClick, loading)}
    </div>
  );
}

type RowType = {
  id: string;
  contents: (string | JSX.Element)[];
  expandedContent?: JSX.Element;
  onClick?: () => void;
};

export function genericTableWithIcon(
  columnNames: (string | JSX.Element)[],
  length: number,
  rows: RowType[] | null,
  isLoading: boolean
) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const tableClasses =
    "w-fit table-auto px-20 text-lg bg-white rounded-xl h-fit w-fit overflow-hidden shadow-[4px_4px_8px_2px_rgba(0,0,0,0.1)] ml-6";
  const thClasses = "pl-2 pr-2 h-10 bg-deep_blue text-white";
  const tdClasses = "py-1 px-3 border-b border-gray-200";
  const statusClasses = "w-24";
  const loading = length === undefined;
  const reformattedRows = rows === null ? undefined : rows;

  const makeRow = (row: {
    id: string;
    contents: (string | JSX.Element)[];
    expandedContent?: JSX.Element;
    onClick: () => void;
  }) => {
    const isExpanded = row.id === expandedId;
    const onClick = () => {
      setExpandedId(isExpanded ? null : row.id);
      row.onClick();
    };
    return (
      <React.Fragment key={row.id}>
        <tr
          onClick={onClick}
          className={`cursor-pointer ${
            isExpanded ? "bg-gray-200 even:bg-gray-300" : "even:bg-gray-100"
          } border-b-4 border-[#d9d9d9]`}
        >
          {row.contents.map((cell, idx) => (
            <td
              className={`${tdClasses} ${idx === 5 ? statusClasses : ""}`}
              key={idx}
            >
              {cell}
            </td>
          ))}
        </tr>
        {isExpanded && row.expandedContent && (
          <tr>
            <td colSpan={row.contents.length}>{row.expandedContent}</td>
          </tr>
        )}
      </React.Fragment>
    );
  };

  return (
    <div>
      <table className={tableClasses}>
        <thead>
          <tr>
            {columnNames.map((name, index) => (
              <th
                className={`${thClasses} ${index === 5 ? statusClasses : ""}`}
                key={index}
              >
                {name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr className="h-32">
              <td
                colSpan={columnNames.length}
                className={`${tdClasses} flex items-center justify-center`}
              >
                {tableLoadingIcon}
              </td>
            </tr>
          ) : rows && rows.length > 0 ? (
            rows.map((row) => {
              const modifiedRow = {
                ...row,
                contents: row.contents.map((content) => <>{content}</>),
                onClick: row.onClick || (() => {}),
              };
              return makeRow(modifiedRow);
            })
          ) : (
            <tr className="h-32 fill-gray-500">
              <td colSpan={columnNames.length}>
                <div className="flex items-center justify-center w-full h-full">
                  <div className="ml-[30%] mr-[30%] text-3xl font-bold text-gray-500">
                    {tableLoadOrNoDataNotice(reformattedRows)}
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export function GenericTableWithIconAndSorting(
  columnNames: (string | JSX.Element)[],
  length: number,
  rows: RowType[] | null,
  isLoading: boolean,
  sortableColumns: string[]
) {
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);
  const [sortColumn, setSortColumn] = useState<string | null>(null);

  const tableClasses =
    "w-fit table-auto px-20 text-lg bg-white rounded-xl h-fit w-fit overflow-hidden shadow-[4px_4px_8px_2px_rgba(0,0,0,0.1)] ml-6";
  const thClasses = "pl-2 pr-2 h-10 bg-deep_blue text-white";
  const tdClasses = "py-1 px-3 border-b border-gray-200";
  const statusClasses = "w-24";
  const loading = length === undefined;
  const reformattedRows = rows === null ? undefined : rows;
  // Define the type for the keys of timeUnits
  type TimeUnitKey =
    | "second"
    | "minute"
    | "hour"
    | "day"
    | "week"
    | "month"
    | "year";

  const timeUnits: { [K in TimeUnitKey]: number } = {
    second: 1000,
    minute: 60 * 1000,
    hour: 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
    month: 30 * 24 * 60 * 60 * 1000,
    year: 365 * 24 * 60 * 60 * 1000,
  };

  function convertRelativeTimeToTimestamp(relativeTime: string): number {
    const regex =
      /(\d+)\s*(seconds?|minutes?|hours?|days?|weeks?|months?|years?)\s*ago/;
    const matches = relativeTime.match(regex);

    if (matches) {
      const value = parseInt(matches[1], 10);
      const unit = matches[2].toLowerCase() as TimeUnitKey; // Cast to TimeUnitKey
      const now = new Date();
      return (
        now.getTime() - value * timeUnits[unit.replace(/s$/, "") as TimeUnitKey]
      ); // Cast to TimeUnitKey
    }

    return new Date().getTime();
  }
  type JSXElementWithChildren = React.ReactElement<{
    children?: React.ReactNode;
  }> & {
    props: {
      children: React.ReactNode;
    };
  };

  function extractTextFromJSX(jsxElement: React.ReactNode): string {
    if (typeof jsxElement === "string") {
      return jsxElement;
    } else if (React.isValidElement(jsxElement)) {
      const element = jsxElement as JSXElementWithChildren;
      return React.Children.toArray(element.props.children)
        .map((child) => {
          return typeof child === "string" ? child : extractTextFromJSX(child);
        })
        .join("");
    }
    return ""; // If it's not a string or valid JSX element, return an empty string
  }

  const sortRows = (rows: RowType[]): RowType[] => {
    if (!sortOrder || !sortColumn) return rows;
    const columnIndex = columnNames.indexOf(sortColumn);

    return [...rows].sort((a, b) => {
      let aValue = a.contents[columnIndex];
      let bValue = b.contents[columnIndex];
      let numericAValue: number | null = null;
      let numericBValue: number | null = null;

      // If the column is 'Status', extract text content for sorting
      if (sortColumn === "Status" && React.isValidElement(aValue)) {
        aValue = extractTextFromJSX(aValue);
      }
      if (sortColumn === "Status" && React.isValidElement(bValue)) {
        bValue = extractTextFromJSX(bValue);
      }

      // If the content is a JSX element for other columns or other types, extract its inner text or value
      if (React.isValidElement(aValue) && sortColumn !== "Status") {
        const elementA = aValue as React.ReactElement<any>;
        aValue = elementA.props.children || elementA.props.value;
      }
      if (React.isValidElement(bValue) && sortColumn !== "Status") {
        const elementB = bValue as React.ReactElement<any>;
        bValue = elementB.props.children || elementB.props.value;
      }

      // Convert 'ago' strings into timestamps for sorting
      if (typeof aValue === "string" && aValue.includes("ago")) {
        numericAValue = convertRelativeTimeToTimestamp(aValue);
      }
      if (typeof bValue === "string" && bValue.includes("ago")) {
        numericBValue = convertRelativeTimeToTimestamp(bValue);
      }

      // Perform the comparison
      if (numericAValue !== null && numericBValue !== null) {
        // Both are numbers
        return sortOrder === "asc"
          ? numericAValue - numericBValue
          : numericBValue - numericAValue;
      } else if (numericAValue !== null) {
        // Only A is a number
        return sortOrder === "asc" ? -1 : 1;
      } else if (numericBValue !== null) {
        // Only B is a number
        return sortOrder === "asc" ? 1 : -1;
      } else {
        // Both are strings or have been converted to strings from JSX
        const strAValue = typeof aValue === "string" ? aValue : "";
        const strBValue = typeof bValue === "string" ? bValue : "";
        return sortOrder === "asc"
          ? strAValue.localeCompare(strBValue)
          : strBValue.localeCompare(strAValue);
      }
    });
  };

  const renderSortIcons = (column: string | JSX.Element) => {
    const columnString = typeof column === "string" ? column : "unknown";
    if (sortableColumns && !sortableColumns.includes(columnString)) return null;
    return (
      <span className="ml-2 inline-flex flex-col items-center justify-center">
        <FaAngleUp
          onClick={() => {
            setSortColumn(columnString);
            setSortOrder("asc");
          }}
          className={
            sortOrder === "asc" && sortColumn === columnString
              ? "text-blue-500"
              : "text-gray-400"
          }
        />
        <FaAngleDown
          onClick={() => {
            setSortColumn(columnString);
            setSortOrder("desc");
          }}
          className={
            sortOrder === "desc" && sortColumn === columnString
              ? "text-blue-500"
              : "text-gray-400"
          }
        />
      </span>
    );
  };

  const makeRow = (row: {
    id: string;
    contents: (string | JSX.Element)[];
    expandedContent?: JSX.Element;
    onClick: () => void;
  }) => {
    const isExpanded = row.id === expandedId;
    const onClick = () => {
      setExpandedId(isExpanded ? null : row.id);
      row.onClick();
    };
    return (
      <React.Fragment key={row.id}>
        <tr
          onClick={onClick}
          className={`cursor-pointer ${
            isExpanded ? "bg-gray-200 even:bg-gray-300" : "even:bg-gray-100"
          } border-b-4 border-[#d9d9d9]`}
        >
          {row.contents.map((cell, idx) => (
            <td
              className={`${tdClasses} ${idx === 5 ? statusClasses : ""}`}
              key={idx}
            >
              {cell}
            </td>
          ))}
        </tr>
        {isExpanded && row.expandedContent && (
          <tr>
            <td colSpan={row.contents.length}>{row.expandedContent}</td>
          </tr>
        )}
      </React.Fragment>
    );
  };

  return (
    <div>
      <table className={tableClasses}>
        <thead>
          <tr>
            {columnNames.map((name, index) => (
              <th
                className={`${thClasses} ${index === 5 ? statusClasses : ""}`}
                key={index}
              >
                {name}
                {renderSortIcons(name)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr className="h-32">
              <td
                colSpan={columnNames.length}
                className={`${tdClasses} flex items-center justify-center`}
              >
                {tableLoadingIcon}
              </td>
            </tr>
          ) : rows && rows.length > 0 ? (
            sortRows(rows).map((row) => {
              const modifiedRow = {
                ...row,
                contents: row.contents.map((content) => <>{content}</>),
                onClick: row.onClick || (() => {}),
              };
              return makeRow(modifiedRow);
            })
          ) : (
            <tr className="h-32 fill-gray-500">
              <td colSpan={columnNames.length}>
                <div className="flex items-center justify-center w-full h-full">
                  <div className="ml-[30%] mr-[30%] text-3xl font-bold text-gray-500">
                    {tableLoadOrNoDataNotice(reformattedRows)}
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

const tableLoadingIcon = (
  <div className="relative text-3xl font-bold text-gray-600 animate-pulse">
    {"Loading..."}
  </div>
);

const tableNoDataNotice = (
  <div className="text-3xl font-bold text-gray-600">{"No data available"}</div>
);

export function tableLoadOrNoDataNotice<T>(
  data: T[] | undefined | null
): JSX.Element {
  if (data === undefined || data === null) {
    return tableLoadingIcon;
  } else if (data.length < 1) {
    // No data
    return tableNoDataNotice;
  } else {
    // Data
    return <></>;
  }
}

export function GenericTableExpandable({
  columnNames,
  rows,
  expandedContent,
  onClickRow,
}: {
  columnNames: string[];
  rows: { id: string; contents: string[] }[] | null;
  expandedContent: (row: {
    id: string;
    contents: string[];
  }) => JSX.Element | null;
  onClickRow?: (row: { id: string; contents: string[] }) => void;
}) {
  const [expandedId, setExpandedId] = useState<string | null>(null);

  const makeRow = (id: string, cells: JSX.Element[], index: number) => {
    const uniqueId = `${id}_${index}`;
    const isExpanded = uniqueId === expandedId;
    const onClick = () => {
      setExpandedId(isExpanded ? null : uniqueId);
      if (!isExpanded && onClickRow) {
        onClickRow({ id, contents: cells.map((cell) => cell.props.children) });
      }
    };

    return (
      <React.Fragment key={uniqueId}>
        <tr
          onClick={onClick}
          className={`cursor-pointer ${isExpanded ? "bg-gray-200" : ""}`}
        >
          {cells}
        </tr>
        {isExpanded && (
          <tr>
            <td colSpan={cells.length}>
              {expandedContent &&
                expandedContent({
                  id,
                  contents: cells.map((cell) => cell.props.children),
                })}
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  };

  return veryGenericTable(columnNames, rows, null, makeRow);
}

type TableRowContent = string | JSX.Element | (() => JSX.Element);

type TableRow = {
  id: string;
  contents: TableRowContent[];
  expandableRowContent?: (string | JSX.Element)[][] | null;
};

interface TableProps {
  columnNames: string[];
  rows: TableRow[];
  sortableColumns?: string[];
  onEdit: (routeId: string) => void;
  onDelete: (routeId: string) => void;
  onDeactivate: (routeId: string) => void;
  onSetStateRemoved: (routeId: string) => void;
  expandedRow?: string | null;
  onRowExpand?: (rowId: string) => void;
  showExtraData: boolean;
  isRoot: boolean;
}

const TableWithFilter: React.FC<TableProps> = ({
  columnNames,
  rows,
  sortableColumns,
  onEdit,
  onDelete,
  onDeactivate,
  onSetStateRemoved,
  showExtraData,
  isRoot,
}) => {
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  const sortRows = (rows: TableRow[]): TableRow[] => {
    if (!sortOrder || !sortColumn) return rows;
    const columnIndex = columnNames.indexOf(sortColumn);

    return [...rows].sort((a, b) => {
      const contentA = a.contents[columnIndex];
      const contentB = b.contents[columnIndex];

      if (typeof contentA === "string" && typeof contentB === "string") {
        return sortOrder === "asc"
          ? contentA.localeCompare(contentB)
          : contentB.localeCompare(contentA);
      }

      return 0;
    });
  };

  const renderSortIcons = (column: string) => {
    if (sortableColumns && !sortableColumns.includes(column)) return null;
    return (
      <span className="ml-2 inline-flex flex-col items-center justify-center">
        <FaAngleUp
          onClick={() => {
            setSortColumn(column);
            setSortOrder("asc");
          }}
          className={
            sortOrder === "asc" && sortColumn === column
              ? "text-blue-500"
              : "text-gray-400"
          }
        />
        <FaAngleDown
          onClick={() => {
            setSortColumn(column);
            setSortOrder("desc");
          }}
          className={
            sortOrder === "desc" && sortColumn === column
              ? "text-blue-500"
              : "text-gray-400"
          }
        />
      </span>
    );
  };

  const renderStateBadge = (state: string) => {
    let bgClass = "";
    switch (state) {
      case "Active":
        bgClass = "bg-green-100";
        break;
      case "Suggested":
        bgClass = "bg-yellow-100";
        break;
      case "Inactive":
        bgClass = "bg-red-200";
        break;
      case "Removed":
        bgClass = "bg-gray-200";
        break;
      case "Rejected":
        bgClass = "bg-blue-200";
        break;
    }
    return (
      <span
        className={`inline-block px-3 py-1 rounded-full text-gray-800 text-xs ${bgClass}`}
      >
        {state}
      </span>
    );
  };

  const renderActions = (rowId: string) => (
    <>
      {showExtraData && (
        <>
          <FaEdit
            className="text-gray-600 hover:text-gray-800 cursor-pointer mr-3"
            onClick={(e) => {
              e.stopPropagation();
              onEdit(rowId);
            }}
          />
          <AiOutlinePoweroff
            className="text-gray-600 hover:text-gray-800 cursor-pointer mr-3"
            onClick={(e) => {
              e.stopPropagation();
              onDeactivate(rowId);
            }}
          />
          <FaTrashAlt
            className={`text-red-600 hover:text-red-700 cursor-pointer ${
              isRoot ? "" : "mr-3"
            }`}
            onClick={(e) => {
              e.stopPropagation();
              if (isRoot) {
                // This should permanently delete for Root
                onDelete(rowId);
              } else if (showExtraData) {
                // This should only set the state to removed for Admin
                onSetStateRemoved(rowId);
              }
            }}
          />
        </>
      )}
    </>
  );

  return (
    <div className="text-lg bg-white rounded-xl h-fit w-fit overflow-hidden shadow-[4px_4px_8px_2px_rgba(0,0,0,0.1)]">
      <table className="w-fit table-auto px-20">
        <thead className="h-10 px-20 bg-deep_blue text-white">
          <tr className="border-b-2 mx-20 border-[#d9d9d9]">
            {columnNames.map((column) => (
              <th
                className={`pl-2 pr-2 text-left ${
                  column === "State" || column === "Route Name"
                    ? "text-center"
                    : ""
                }`}
                key={column}
              >
                <div className="inline-flex items-center">
                  {column}
                  {renderSortIcons(column)}
                </div>
              </th>
            ))}
            {showExtraData && <th className="pl-2 pr-2 text-left">Actions</th>}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-300">
          {sortRows(rows).map((row) => (
            <React.Fragment key={row.id}>
              <tr
                onClick={() =>
                  setExpandedRow((prev) => (prev === row.id ? null : row.id))
                }
              >
                {row.contents.map((content, index) => {
                  let contentToRender;
                  if (typeof content === "function") {
                    contentToRender = content();
                  } else {
                    contentToRender = content;
                  }
                  const key = `${row.id}-${index}`;
                  if (
                    columnNames[index] === "State" ||
                    columnNames[index] === "Route Name"
                  ) {
                    return (
                      <td className="px-4 items-center" key={key}>
                        {columnNames[index] === "State"
                          ? renderStateBadge(contentToRender as string)
                          : contentToRender}
                      </td>
                    );
                  } else {
                    return (
                      <td className="px-2 py-4 whitespace-nowrap" key={key}>
                        {contentToRender}
                      </td>
                    );
                  }
                })}
                <td className="px-2 py-5 whitespace-nowrap flex justify-center items-center">
                  {renderActions(row.id)}
                </td>
              </tr>
              {expandedRow === row.id && row.expandableRowContent && (
                <tr>
                  <td colSpan={columnNames.length + 1}>
                    <div className="w-full">
                      {row.expandableRowContent.map(
                        (expandableContentRow, index) => (
                          <div key={index} className="flex pl-8 py-2 pr-2 pb-2">
                            {expandableContentRow.map(
                              (
                                content:
                                  | string
                                  | number
                                  | boolean
                                  | React.ReactElement<
                                      any,
                                      string | React.JSXElementConstructor<any>
                                    >
                                  | Iterable<React.ReactNode>
                                  | React.ReactPortal
                                  | null
                                  | undefined,
                                contentIndex: React.Key | null | undefined
                              ) => (
                                <span key={contentIndex} className="flex-1">
                                  {content}
                                </span>
                              )
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableWithFilter;

export function genericTableWithDeleteIcon(
  columnNames: string[],
  rows: GenericTableWithDeleteIconRow[] | null
) {
  const tdClasses = "py-1 px-3 border-b border-gray-200";
  const loading = rows === null;

  const makeRowWithDelete = (
    row: GenericTableWithDeleteIconRow,
    index: number
  ) => {
    const contentCells = row.contents.map((content, cellIndex) => (
      <td key={`cell-${row.id}-${cellIndex}`} className={tdClasses}>
        {content}
      </td>
    ));

    const deleteCell = row.actions ? (
      <td
        key={`action-delete-${row.id}`}
        className="px-2 cursor-pointer text-deep_blue/50 hover:text-deep_blue"
      >
        {row.actions}
      </td>
    ) : null;

    const allCells = deleteCell ? [...contentCells, deleteCell] : contentCells;
    return (
      <tr className="h-10 border-b-2 last:border-b-0" key={`row-${row.id}`}>
        {allCells}
      </tr>
    );
  };

  const reformattedRows = rows === null ? undefined : rows;

  const rowComponents = loading
    ? [
        <tr key="loading-cell">
          <td
            colSpan={columnNames.length}
            className={`${tdClasses} flex items-center justify-center`}
          >
            {tableLoadOrNoDataNotice(reformattedRows)}
          </td>
        </tr>,
      ]
    : rows.map(makeRowWithDelete);

  return (
    <div className="text-lg bg-white rounded-xl h-fit w-fit overflow-hidden shadow-[4px_4px_8px_2px_rgba(0,0,0,0.1)]">
      <table className="w-fit table-auto px-20">
        <thead className="h-10 bg-deep_blue text-white">
          {loading ? (
            <div className="flex items-center justify-center w-full h-full text-3xl font-bold text-gray-500">
              {tableLoadOrNoDataNotice(reformattedRows)}
            </div>
          ) : (
            <tr className="border-b-2 border-[#d9d9d9]">
              {columnNames.map((name, index) => (
                <th key={`header-${index}`} className="pl-2 pr-2 text-left">
                  {name}
                </th>
              ))}
            </tr>
          )}
        </thead>

        <tbody>{rowComponents}</tbody>
      </table>
    </div>
  );
}

export function TripsTableExpandable({
  columnNames,
  rows,
  expandedContent,
  onClickRow,
  sortableColumns,
}: TripsTableExpandableProps) {
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const sortRows = (rows: TripsTableRow[]): TripsTableRow[] => {
    if (!sortOrder || !sortColumn) return rows;
    const columnIndex = columnNames.indexOf(sortColumn);

    return [...rows].sort((a, b) => {
      let valA = a.contents[columnIndex];
      let valB = b.contents[columnIndex];

      // Handle non-sortable or special cases
      if (valA === "N/A") valA = sortOrder === "asc" ? -Infinity : Infinity;
      if (valB === "N/A") valB = sortOrder === "asc" ? -Infinity : Infinity;

      // Define a more flexible date regex pattern
      const dateRegex = /(\d{1,2}) (\w{3}) (\d{2,4}) (\d{2}):(\d{2}):(\d{2})/;
      const matchA = valA.match(dateRegex);
      const matchB = valB.match(dateRegex);

      // Parse dates and compare
      if (matchA && matchB) {
        const [, dayA, monthA, yearA, hourA, minuteA, secondA] = matchA;
        const [, dayB, monthB, yearB, hourB, minuteB, secondB] = matchB;
        const dateA = new Date(
          `${monthA} ${dayA}, ${yearA} ${hourA}:${minuteA}:${secondA}`
        ).getTime();
        const dateB = new Date(
          `${monthB} ${dayB}, ${yearB} ${hourB}:${minuteB}:${secondB}`
        ).getTime();
        return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      }

      // Fallback for non-date values
      // Handle numeric comparison
      const numA = parseFloat(valA.replace(/[^0-9.-]/g, ""));
      const numB = parseFloat(valB.replace(/[^0-9.-]/g, ""));
      if (!isNaN(numA) && !isNaN(numB)) {
        return sortOrder === "asc" ? numA - numB : numB - numA;
      }

      // String comparison
      if (typeof valA === "string" && typeof valB === "string") {
        return sortOrder === "asc"
          ? valA.localeCompare(valB)
          : valB.localeCompare(valA);
      }

      // Fallback for non-comparable types
      return 0;
    });
  };

  const renderSortIcons = (column: string) => {
    if (sortableColumns && !sortableColumns.includes(column)) return null;
    return (
      <span className="ml-2 inline-flex flex-col items-center justify-center">
        <FaAngleUp
          className={
            sortOrder === "asc" && sortColumn === column
              ? "text-blue-500"
              : "text-gray-400"
          }
        />
        <FaAngleDown
          className={
            sortOrder === "desc" && sortColumn === column
              ? "text-blue-500"
              : "text-gray-400"
          }
        />
      </span>
    );
  };

  const makeRow = (row: TripsTableRow, index: number) => {
    const uniqueId = `${row.id}_${index}`;
    const isExpanded = uniqueId === expandedId;
    const cells = row.contents.map((content, cellIndex) => (
      <td key={cellIndex} className="border p-2 pl-6 pr-6 text-center">
        {content}
      </td>
    ));

    const onClick = () => {
      setExpandedId(isExpanded ? null : uniqueId);
      if (!isExpanded && onClickRow) {
        onClickRow(row);
      }
    };

    return (
      <React.Fragment key={uniqueId}>
        <tr
          onClick={onClick}
          className={`cursor-pointer ${
            isExpanded ? "bg-gray-200" : ""
          } hover:bg-gray-100 ${index % 2 === 0 ? "bg-gray-50" : ""}`}
        >
          {cells}
        </tr>
        {isExpanded && (
          <tr>
            <td colSpan={cells.length}>{expandedContent(row)}</td>
          </tr>
        )}
      </React.Fragment>
    );
  };

  let content;
  if (rows === null) {
    content = (
      <tr>
        <td colSpan={columnNames.length} className="h-32">
          <div className="loading-container">
            <LoadingSpinner />
          </div>
        </td>
      </tr>
    );
  } else if (rows.length === 0) {
    content = (
      <tr>
        <td colSpan={columnNames.length} className="h-32">
          <div className="flex items-center justify-center w-full h-full text-3xl font-bold text-gray-600 text-center">
            No data available
          </div>
        </td>
      </tr>
    );
  } else {
    // Regular data rows
    const sortedRows = sortRows(rows);
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = sortedRows.slice(indexOfFirstRow, indexOfLastRow);
    content = currentRows.map((row, index) => makeRow(row, index));
  }

  return (
    <div className="text-lg bg-white rounded-xl h-fit w-fit overflow-hidden shadow-md">
      <table className="w-fit table-auto px-20">
        <thead className="h-10 px-20 bg-deep_blue text-white">
          <tr className="border-b-2 mx-20 border-[#d9d9d9]">
            {columnNames.map((name, idx) => (
              <th
                key={idx}
                className="pl-6 pr-6 text-left border-b border-gray-300"
                onClick={() => {
                  setSortColumn(name);
                  setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                }}
              >
                <div className="flex items-center">
                  {name}
                  {renderSortIcons(name)}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{content}</tbody>
      </table>
      <Pagination
        count={rows && rows.length ? Math.ceil(rows.length / rowsPerPage) : 0}
        page={currentPage}
        onChange={(event, value) => setCurrentPage(value)}
        variant="outlined"
        color="primary"
      />
    </div>
  );
}

export type TripsTableRow = {
  id: string;
  routeId: string;
  timestring: string;
  contents: any[];
  expandableRowContent?: JSX.Element[];
};

export type TripsTableExpandableProps = {
  columnNames: string[];
  rows: TripsTableRow[] | null;
  expandedContent: (row: TripsTableRow) => JSX.Element | null;
  onClickRow?: (row: TripsTableRow) => void;
  sortableColumns?: string[];
};

export interface IconButton {
  Icon: IconType;
  onClick: () => void;
  tooltip?: string;
}

export function TableCellIconButton(key: string, iconButton: IconButton) {
  return (
    <Tooltip title={iconButton.tooltip} placement="top">
      <div
        className="w-full cursor-pointer text-deep_blue/50 hover:text-deep_blue"
        key={key}
        onClick={iconButton.onClick}
      >
        <div className="w-fit">
          <iconButton.Icon className="text-2xl" />
        </div>
      </div>
    </Tooltip>
  );
}

export function TableCellIconButtons(key: string, iconButtons: IconButton[]) {
  const iconButtonComps = iconButtons.map((button, index) => {
    return (
      <Tooltip title={button.tooltip} placement="top">
        <div
          className="w-fit text-deep_blue/50 hover:text-deep_blue cursor-pointer"
          onClick={button.onClick}
        >
          <button.Icon className="text-2xl" />
        </div>
      </Tooltip>
    );
  });
  return (
    <div className="w-full flex space-x-2" key={key}>
      {iconButtonComps}
    </div>
  );
}
