// apiService.ts
import axiosInstance from "../../axiosInstance";
import { setAuthorizationHeader } from "../../helpers";
import { processOverviewTotalWeekResponse } from "./responseHandlers";
import { updateFuelUsageContext } from "./contextHandlers";
import { dateToString, findTimeBounds } from "../../../helpers/dateTimeHelpers";
import {
  OperatorTabMetric,
  Timescale,
  VesselActivity,
} from "../../../context/types";
import { getAllVesselIds } from "../../../apiComms/helpers";
import { makeEnergyOverallUrlTotal } from "../../../apiComms/url";
import { FuelUsageTotalWeek, Maybe } from "../../../types";
import { getErrorDetails } from "../../helpers";

type ApiResponse = {
  columns: string[];
  rows: Array<Array<FuelUsageTotalWeek>>;
};

export const getOverviewTotalWeek = async (
  view: any,
  data: any,
  vars: any,
  navigate: any
): Promise<Maybe<FuelUsageTotalWeek>> => {
  const isVesselsEmpty = data.org.vesselList.length < 1;

  if (isVesselsEmpty) {
    updateFuelUsageContext(null, data);
    return null;
  }

  if (vars.auth.organisationId === null) {
    throw new Error("No organization found");
  }

  setAuthorizationHeader(vars, data, view, navigate, axiosInstance);

  const org = vars.auth.organisationId;

  const theDate = new Date();
  const { fromTime: theFromTime, toTime: theToTime } = findTimeBounds(
    theDate,
    Timescale.Week
  );
  const from = dateToString(theFromTime, false);
  const to = dateToString(theToTime, false);
  const vessels = getAllVesselIds(data.org.vesselList);
  const metric = OperatorTabMetric.FuelVolume;
  const activity = VesselActivity.Total;

  const weekUrl = makeEnergyOverallUrlTotal(
    org,
    from,
    to,
    vessels,
    metric,
    activity
  );

  if (weekUrl === null) {
    throw new Error("An error occurred");
  }

  try {
    const response: ApiResponse = await axiosInstance.get(weekUrl);
    const overviewTotalWeek = processOverviewTotalWeekResponse(response);
    updateFuelUsageContext(overviewTotalWeek, data);
    return overviewTotalWeek;
  } catch (error) {
    getErrorDetails(error);
  }
};
