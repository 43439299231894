import { FuelUsageTotalWeek } from "../../../types";

export const processOverviewTotalWeekResponse = (
  responseJson: any
): FuelUsageTotalWeek => {
  const returnData = responseJson.rows;

  if (!returnData || returnData.length < 1) {
    return null;
  }

  const overviewTotalWeek = returnData[0][0];

  return overviewTotalWeek;
};
