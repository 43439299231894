import React from "react";
import { TooltipContainer, IconWrapper, TooltipText } from "../styles/TooltipStyles";


interface TooltipProps {
  text: string;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
  return (
    <TooltipContainer>
      <IconWrapper>{children}</IconWrapper>
      <TooltipText className="tooltip-text">{text}</TooltipText>
    </TooltipContainer>
  );
};

export default Tooltip;
