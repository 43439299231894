import { useQuery } from "@tanstack/react-query";
import { PanelTable } from "../../../../components/molecules";
import { formatDateTimeYearToMinute } from "../../../../helpers/dateTimeHelpers";
import { createIconLabelForAlarmType } from "../../helpers/helpers";
import { AlarmType, useAppContext } from "../../../../context/variables";
import { getAllCustomAlarmsType } from "../../../../api/services";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Maybe } from "../../../../types";

const CustomAlarmsPanel = () => {
  const { view, data, vars } = useAppContext();
  const navigate = useNavigate();
  const {
    data: allCustomAlarmsType,
    error,
    isLoading,
    refetch,
    isFetching,
  } = useQuery<Maybe<AlarmType[]>, Error>({
    queryKey: ["customAlarmTypes"],
    queryFn: () => getAllCustomAlarmsType(view, data, vars, navigate),
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.idToken, vars.auth.organisationId]);

  return (
    <PanelTable
      isLoading={isLoading || isFetching}
      error={error}
      isEmpty={!allCustomAlarmsType}
      title="Latest Alarms"
      columns={[
        {
          label: "Your vessels with active alarms",
          key: "alarmName",
          formatter: (value: string, row: any) =>
            createIconLabelForAlarmType(row.alarmLevel, value),
        },
        {
          label: "Date/Time",
          key: "date",
          formatter: (value: string) => formatDateTimeYearToMinute(value),
        },
      ]}
      data={allCustomAlarmsType || []}
    />
  );
};

export default CustomAlarmsPanel;
