import { useAppContext } from "../../../../context/variables"
import { makeConfirmModal } from "../../../../components/Modal"
import useManageData from "../../../../apiComms/manageData"

export default function DeleteUserModal(open: boolean, setOpen: (open: boolean)=>void, managedUserId: string|null, onCloseManageUserModal: ()=>void) {
  const { data } = useAppContext()
  const { apiUserDelete } = useManageData()
  const theUserId = (managedUserId !== null) ? managedUserId : "N/A"
  const user = data.org.userList?.find((user)=>user.username === managedUserId)
  const userName = user?.name
  function onConfirmClick() {
    apiUserDelete(theUserId)
    setOpen(false)
    onCloseManageUserModal()
  }
  return makeConfirmModal("Confirm deleting user", 'You are about to PERMANENTLY delete the user "'+userName+'" ('+theUserId+') from the user pool', "Delete user", onConfirmClick, open, setOpen, true)
}
