import React, { useState, useMemo } from 'react';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';

interface WithSortingReturnType {
  sortedData: any[];
  handleSort: (column: string) => void;
  renderSortIcon: (column: string) => React.ReactNode;
}

export const WithSorting = (
  data: any[],
  headers: { name: string }[],
  sortableColumns: string[],
  customSort?: (a: any, b: any, column: string, sortOrder: string) => number,
  disableSorting: boolean = false 
): WithSortingReturnType => {
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | null>(null);
  const [sortColumn, setSortColumn] = useState<string | null>(null);

  const handleSort = (column: string) => {
    if (disableSorting || !sortableColumns.includes(column)) return; 

    const isSameColumn = sortColumn === column;
    const newSortOrder = isSameColumn ? (sortOrder === 'asc' ? 'desc' : 'asc') : 'asc';

    setSortColumn(column);
    setSortOrder(newSortOrder);
  };

  const sortedData = useMemo(() => {
    if (!sortColumn || !sortOrder || disableSorting) return data; 

    return [...data].sort((a, b) => {
      let valA = a[sortColumn.replace(/\s+/g, '').toLowerCase()];
      let valB = b[sortColumn.replace(/\s+/g, '').toLowerCase()];

      if (customSort) {
        return customSort(a, b, sortColumn, sortOrder);
      }

      if (typeof valA === 'string' && typeof valB === 'string') {
        return sortOrder === 'asc' ? valA.localeCompare(valB) : valB.localeCompare(valA);
      }

      if (typeof valA === 'number' && typeof valB === 'number') {
        return sortOrder === 'asc' ? valA - valB : valB - valA;
      }

      // Fallback for non-comparable types
      return 0;
    });
  }, [data, sortColumn, sortOrder, customSort, disableSorting]);

  const renderSortIcon = (column: string) => {
    if (disableSorting) return null; 

    return (
      <span className="ml-2 inline-flex flex-col items-center justify-center">
        <FaAngleUp className={sortOrder === 'asc' && sortColumn === column ? 'text-blue-500' : 'text-gray-400'} />
        <FaAngleDown className={sortOrder === 'desc' && sortColumn === column ? 'text-blue-500' : 'text-gray-400'} />
      </span>
    );
  };

  return { sortedData, handleSort, renderSortIcon };
};
