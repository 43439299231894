import styled from "styled-components";

export const PanelTableContainer = styled.table`
  border-collapse: separate;
  border-spacing: 0 0.5rem;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  min-width: 30rem;
`;

export const PanelTableHeader = styled.thead`
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
`;

export const PanelTableHeaderCell = styled.th`
  color: #595959;
  font-weight: normal;
  padding-right: 1rem;
`;

export const PanelTableBody = styled.tbody`
  font-weight: normal;
  font-size: 1rem;
`;

export const PanelTableBodyCell = styled.td`
  padding-right: 1rem;
`;
