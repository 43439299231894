import { useQuery } from "@tanstack/react-query";
import { PanelTable } from "../../../../components/molecules";
import { useAppContext } from "../../../../context/variables";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getAllOrgVesselList } from "../../../../api/services";
import { Maybe, Vessel } from "../../../../types";

const VesselsPanel = () => {
  const { view, data, vars } = useAppContext();
  const navigate = useNavigate();
  const {
    data: AllOrgVesselList,
    error,
    isLoading,
    refetch,
    isFetching,
  } = useQuery<Maybe<Vessel[]>, Error>({
    queryKey: ["orgVesselList"],
    queryFn: () => getAllOrgVesselList(view, data, vars, navigate),
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.idToken, vars.auth.organisationId]);

  return (
    <PanelTable
      title="Vessels"
      columns={[
        { label: "ID", key: "id" },
        { label: "Name", key: "name" },
      ]}
      data={AllOrgVesselList}
      isLoading={isLoading || isFetching}
      error={error}
      isEmpty={!AllOrgVesselList}
    />
  );
};

export default VesselsPanel;
