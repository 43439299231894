export function firstCharCapital(input: string) {
  if (input.length < 1) return input;
  const firstCap = input[0].toUpperCase();
  const restLower = input.slice(1).toLowerCase();
  return firstCap + restLower;
}

export function strNullToString(
  strNull: string | null,
  processing = (theStr: string) => theStr,
  ifNullStr = "N/A"
): string {
  if (strNull === null || strNull === "") {
    return ifNullStr;
  } else {
    return processing(strNull);
  }
}

export const numNullToString = (
  numNull: number | null,
  processing = (val: number) => val.toString(),
  ifNullStr = "N/A"
): string => {
  if (numNull === null || isNaN(numNull)) {
    return ifNullStr;
  } else {
    return processing(numNull);
  }
};

export function numToStringDecimal(num: number, numOfDecimals: number): string {
  const numStr = num.toLocaleString("en", {
    minimumFractionDigits: numOfDecimals,
    maximumFractionDigits: numOfDecimals,
  });
  return numStr;
}

export function timeDiffToString(diffSeconds: number) {
  const absDiffSeconds = Math.abs(diffSeconds);
  const seconds = Math.floor(absDiffSeconds % 60);
  const totalMinutes = Math.floor(absDiffSeconds / 60);
  const totalHours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes - totalHours * 60;
  const days = Math.floor(totalHours / 24);
  const hours = totalHours - days * 24;
  const theString =
    days >= 1
      ? days + " days " + hours + " h " + minutes + " min " + seconds + " s"
      : hours >= 1
      ? hours + " h " + minutes + " min " + seconds + " s"
      : minutes + " min " + seconds + " s";
  return theString;
}

export function booleanToString(boolean: boolean): string {
  if (boolean) {
    return "True";
  } else {
    return "False";
  }
}

export function booleanYesNoString(value: boolean): string {
  return value ? "Yes" : "No";
}

export function isStringEmpty(str: string): boolean {
  return str === "";
}

export function truncateString(str: string, maxLength: number): string {
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.slice(0, maxLength - 3) + "...";
  }
}
