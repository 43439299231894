import React, { useState, useRef, useEffect } from 'react';
import { DropdownContainer, DropdownButton, DropdownList, DropdownItem } from '../styles/DropdownStyles';

interface DropdownProps {
  name: string;
  value: string;
  options: Array<{ value: string; label: string }>;
  onChange: (name: string, value: string) => void;
  placeholder?: string;
  width?: string;
  disabled?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({ name, value, options, onChange, placeholder, width, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleOptionClick = (optionValue: string) => {
    onChange(name, optionValue);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const selectedLabel = value === '' ? placeholder || 'Select an option' : options.find(option => option.value === value)?.label || placeholder || 'Select an option';

  return (
    <DropdownContainer ref={dropdownRef} width={width}>
      <DropdownButton isPlaceholder={value === ''} onClick={handleToggle} disabled={disabled}>
        {selectedLabel}
      </DropdownButton>
      {isOpen && (
        <DropdownList>
          {options.map(option => (
            <DropdownItem
              key={option.value}
              selected={option.value === value}
              onClick={() => handleOptionClick(option.value)}
            >
              {option.label}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;
