import { useState } from "react";
import { ApiResponseAxios } from "../../types";

const useConfirmActionModal = () => {
  const [hasFeedback, setHasFeedback] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleConfirm = async (action: () => Promise<ApiResponseAxios>) => {
    setLoading(true);

    try {
      const response: ApiResponseAxios = await action();

      if (response.error) {
        setHasFeedback(true);
        setIsSuccess(false);
        return;
      }
      setHasFeedback(true);
      setIsSuccess(true);
    } catch (error) {
      console.error(error);
      setIsSuccess(false);
      setHasFeedback(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    isSuccess,
    setIsSuccess,
    isLoading: loading,
    hasFeedback,
    setHasFeedback,
    handleConfirm,
  };
};
export default useConfirmActionModal;
