import { Vessel, RawVesselEntry } from "../../../types";

const convertVesselList = (rawVessel: RawVesselEntry): Vessel => {
  const id = rawVessel.uid;
  const name = rawVessel.name;
  return { id, name };
};

export const processOrgVesselList = (response: RawVesselEntry[]): Vessel[] => {
  return response.map(convertVesselList);
};
