import { useAppContext } from "../../../../context/variables"
import { makeConfirmModal } from "../../../../components/Modal"
import useManageData from "../../../../apiComms/manageData"

export default function ResendConfirmationModal(open: boolean, setOpen: (open: boolean)=>void, managedUserId: string|null, onCloseManageUserModal: ()=>void) {
  const { apiUserResendConfirmation } = useManageData()
  const { data } = useAppContext()

  const title = "Confirm resending invitation"
  const theUserId = (managedUserId !== null) ? managedUserId : "N/A"
  const userName = data.org.userList?.find((user)=>user.username === managedUserId)?.name
  const text = 'You are about to resend the invitation email to "'+userName+'" ('+theUserId+').'
  const buttonText = "Resend invitation"

  function onConfirmClick() {
    apiUserResendConfirmation(theUserId)
    setOpen(false)
    onCloseManageUserModal()
  }

  return makeConfirmModal(title, text, buttonText, onConfirmClick, open, setOpen)
}
