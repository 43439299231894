import { StyledButton } from "./Button.styles";

interface ButtonProps {
  backgroundColor: string;
  color: string;
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  backgroundColor,
  color,
  text,
  onClick,
  disabled,
}) => {
  return (
    <StyledButton
      backgroundcolor={backgroundColor}
      color={color}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </StyledButton>
  );
};
export default Button;
