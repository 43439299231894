import { useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useAppContext } from "../../../context/variables";
import useFetchData from "../../../apiComms/fetchData";
import useManageData from "../../../apiComms/manageData";

export const DeleteVoyageTripModal: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  orgId: string | null;
  voyageId: string | null;
  onCloseManageModal: () => void;
  setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen, orgId, voyageId, onCloseManageModal }) => {
  const { data } = useAppContext();
  const { apiDeleteVoyageTrip } = useManageData();
  const { fetchDataVoyages } = useFetchData();

  const voyageList = data.voyages.voyageList.val;
  const voyageMaybe = voyageList?.find(
    (voyage) => voyage.vesselId === voyageId
  );
  const voyageName = voyageMaybe ? `Voyage for vessel ${voyageId}` : "N/A";

  const [isLoading, setIsLoading] = useState(false);
  const [voyageConfirmModalOpen, setVoyageConfirmModalOpen] = useState(false);

  const VoyageConfirmationModal: React.FC<{
    voyageConfirmModalOpen: boolean;
    setVoyageConfirmModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }> = ({ voyageConfirmModalOpen, setVoyageConfirmModalOpen }) => {
    return (
      <div
        id="popup-modal"
        className={`fixed inset-0 flex items-center justify-center z-50 p-4 overflow-x-hidden overflow-y-auto ${
          voyageConfirmModalOpen ? "block" : "hidden"
        }`}
      >
        <div className="relative w-full max-w-md">
          <div className="relative bg-white rounded-lg shadow">
            <button
              onClick={() => setVoyageConfirmModalOpen(false)}
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-2"
            >
              <span className="sr-only">Close modal</span>
            </button>
            <div className="p-6 text-center">
              <AiOutlineCheckCircle className="mx-auto mb-4 text-green-500 w-12 h-12" />
              <h3 className="mb-5 text-lg font-normal text-gray-500">
                Voyage deactivated successfully!
              </h3>
              <button
                onClick={() => setVoyageConfirmModalOpen(false)}
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm px-5 py-2.5 hover:text-gray-900"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const DeleteConfirmationModal = () => {
    function onConfirmClick() {
      setIsLoading(true);
      if (voyageMaybe?.vesselId && voyageMaybe?.startTime) {
        apiDeleteVoyageTrip(voyageMaybe.vesselId, voyageMaybe.startTime)
          .then(async () => {
            await fetchDataVoyages();
            setOpen(false);
            onCloseManageModal();
            setIsLoading(false);
            setVoyageConfirmModalOpen(true);
          })
          .catch((error) => {
            console.error("Delete failed", error);
            setIsLoading(false);
          });
      } else {
        console.error("Missing voyage information for deletion");
        setIsLoading(false);
      }
    }

    return (
      <div
        id="popup-modal"
        className={`fixed inset-0 flex items-center justify-center z-50 p-4 overflow-x-hidden overflow-y-auto ${
          open ? "block" : "hidden"
        }`}
      >
        <div className="relative w-full max-w-md">
          <div className="relative bg-white rounded-lg shadow">
            <button
              onClick={() => setOpen(false)}
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="p-6 text-center">
              <svg
                className="mx-auto mb-4 text-gray-400 w-12 h-12"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <h3 className="mb-5 text-lg font-normal text-gray-500">
                Are you sure you want to permanently delete this voyage?
              </h3>

              <button
                onClick={onConfirmClick}
                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
              >
                Confirm
              </button>
              <button
                onClick={() => setOpen(false)}
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <>
        {open && <DeleteConfirmationModal />}
        <VoyageConfirmationModal
          voyageConfirmModalOpen={voyageConfirmModalOpen}
          setVoyageConfirmModalOpen={setVoyageConfirmModalOpen}
        />
      </>
    </>
  );
};