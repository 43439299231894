import { useEffect, useState } from "react";

import {
  coordsGothenburgKindaCentral,
  initializeMap,
  latToPrettyString,
  lngToPrettyString,
} from "../../../components/Map";

import { apiData, variables, geoZonesEntry } from "../../../context/variables";

import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

type coords = {
  latitude: number;
  longitude: number;
};

export function makeLatLng(
  latitude: number,
  longitude: number
): google.maps.LatLng {
  return new google.maps.LatLng(latitude, longitude);
}

let allGeoZoneMarkers: google.maps.Marker[] = [];
let allGeoZoneCircles: google.maps.Circle[] = [];
let allGeoZonePolys: google.maps.Polygon[] = [];
let chosenGeoZonePolygon: google.maps.Polygon | undefined;
let infoWindow: google.maps.InfoWindow | undefined;

export function MainGeoZonesMap(
  data: apiData,
  vars: variables,
  chosenGeoZone: string | null,
  chooseGeoZone: (x: string | null) => void,
  geoZoneList: geoZonesEntry[] | null
): JSX.Element {
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  const [mapInitialized, setMapInitialized] = useState(false);
  const organisationId = vars.auth.organisationId;
  const isRoot =
    vars.auth.userGroups !== null && vars.auth.userGroups.includes("root");
  const showExtraFields = organisationId === null && isRoot;

  const geoZoneCoords = coordsGothenburgKindaCentral;
  const geoZoneRadius = 10;

  function mapInitCustom(map: google.maps.Map) {
    infoWindow = new google.maps.InfoWindow();
  }

  useEffect(() => {
    initializeMap(
      map,
      setMap,
      "geo_zones_map_canvas",
      mapInitCustom,
      setMapInitialized,
      geoZoneCoords,
      geoZoneRadius
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mapInitialized && map !== undefined) {
      if (geoZoneList !== null && geoZoneList.length > 0) {
        // Find the chosen geo zone in the list
        const chosenGeoZoneData = geoZoneList.find(
          (geoZoneData) => geoZoneData.gz_id === chosenGeoZone
        ) as geoZonesEntry | undefined;

        if (chosenGeoZoneData) {
          // Set the map center to the first coordinate of the chosen geo zone
          const focusPoint = chosenGeoZoneData.shape.coordinates[0];
          map.setCenter(
            makeLatLng(
              parseFloat(focusPoint.latitude),
              parseFloat(focusPoint.longitude)
            )
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapInitialized, geoZoneList, chosenGeoZone]);

  function calculateAverageCoordinates(
    coordinates: { latitude: string; longitude: string }[]
  ): coords {
    let sumLatitude = 0;
    let sumLongitude = 0;

    for (const coord of coordinates) {
      sumLatitude += parseFloat(coord.latitude);
      sumLongitude += parseFloat(coord.longitude);
    }

    const averageLatitude = sumLatitude / coordinates.length;
    const averageLongitude = sumLongitude / coordinates.length;

    return { latitude: averageLatitude, longitude: averageLongitude };
  }

  if (mapInitialized) {
    let chosenCoords: google.maps.LatLng | null = null;
    let chosenContentString: string = "";

    if (chosenGeoZone === null) {
      chosenCoords = null;
    }

    // Clear existing markers, circles, and polys
    for (let i = 0; i < allGeoZoneMarkers.length; i++) {
      allGeoZoneMarkers[i].addListener("click", () => {
        chooseGeoZone(geoZoneList![i].gz_id);
      });
      allGeoZoneMarkers[i].setMap(null);
    }

    for (let i = 0; i < allGeoZoneCircles.length; i++) {
      allGeoZoneCircles[i].addListener("click", () => {
        chooseGeoZone(geoZoneList![i].gz_id);
      });
      allGeoZoneCircles[i].setMap(null);
    }

    for (let i = 0; i < allGeoZonePolys.length; i++) {
      allGeoZonePolys[i].addListener("click", () => {
        chooseGeoZone(geoZoneList![i].gz_id);
      });
      allGeoZonePolys[i].setMap(null);
    }

    // Clear existing markers, circles, and polygons
    allGeoZoneMarkers.forEach((marker) => marker.setMap(null));
    allGeoZoneCircles.forEach((circle) => circle.setMap(null));
    allGeoZonePolys.forEach((poly) => poly.setMap(null));

    let chosenGeoZoneMarker: google.maps.Marker | undefined;

    allGeoZoneMarkers =
      geoZoneList === null
        ? []
        : geoZoneList.map((geoZoneData) => {
            const firstCoordinate = geoZoneData.shape.coordinates[0];
            const geoZoneCoords: google.maps.LatLng = makeLatLng(
              parseFloat(firstCoordinate?.latitude || "0"),
              parseFloat(firstCoordinate?.longitude || "0")
            );
            const markerColor =
              geoZoneData.gz_id === chosenGeoZone ? "#ff4d00" : "#4bd4ba";

            const geoZoneMarker = new google.maps.Marker({
              map: map,
              position: geoZoneCoords,
              icon: {
                path: faMapMarkerAlt.icon[4] as string,
                fillColor: markerColor,
                fillOpacity: 1,
                anchor: new google.maps.Point(
                  faMapMarkerAlt.icon[0] / 2, // width
                  faMapMarkerAlt.icon[1] // height
                ),
                strokeWeight: 1,
                strokeColor: "#000000",
                scale: 0.075,
              },
            });

            if (geoZoneData.gz_id === chosenGeoZone) {
              chosenGeoZoneMarker = geoZoneMarker;
              chosenCoords = geoZoneCoords;

              // Check if displayName property exists before accessing it
              chosenContentString =
                '<div id="content">' +
                '<div id="siteNotice">' +
                "</div>" +
                '<div id="bodyContent">' +
                "<p><b>Name</b>: " +
                (geoZoneData.name || "N/A") +
                "</p>" +
                "<p><b>Latitude</b>: " +
                (latToPrettyString(
                  parseFloat(geoZoneData.shape.coordinates[0].latitude)
                ) || "N/A") +
                "</p>" +
                "<p><b>Longitude</b>: " +
                (lngToPrettyString(
                  parseFloat(geoZoneData.shape.coordinates[0].longitude)
                ) || "N/A") +
                "</p>" +
                "<p><b>State</b>: " +
                (geoZoneData.state || "N/A") +
                "</p>" +
                (showExtraFields
                  ? "<p><b>gz_id</b>: " + (geoZoneData.gz_id || "N/A") + "</p>"
                  : "") +
                "</div>" +
                "</div>";
            }

            geoZoneMarker.addListener("click", () => {
              chooseGeoZone(geoZoneData.gz_id);
            });

            return geoZoneMarker;
          });

    allGeoZoneCircles =
      geoZoneList === null
        ? []
        : geoZoneList.map((geoZoneData, i) => {
            const geoZoneCoords: google.maps.LatLng = makeLatLng(
              parseFloat(geoZoneData.shape.coordinates[0].latitude),
              parseFloat(geoZoneData.shape.coordinates[0].longitude)
            );
            const circleColor =
              geoZoneData.gz_id === chosenGeoZone ? "#ff4d00" : "#4bd4ba";

            const geoZoneCircle = new google.maps.Circle({
              map: map,
              center: geoZoneCoords,
              radius: geoZoneRadius,
              strokeColor: circleColor,
              strokeOpacity: 0.8,
              strokeWeight: 3,
              fillColor: circleColor,
              fillOpacity: 0.5,
            });

            if (geoZoneData.gz_id === chosenGeoZone) {
            }

            geoZoneCircle.addListener("click", () => {
              chooseGeoZone(geoZoneData.gz_id);
            });

            return geoZoneCircle;
          });

    allGeoZonePolys =
      geoZoneList === null
        ? []
        : geoZoneList.map((geoZoneData) => {
            const circleColor =
              geoZoneData.gz_id === chosenGeoZone ? "#ff4d00" : "#4bd4ba";
            const path = geoZoneData.shape.coordinates.map(
              (coord: { latitude: string; longitude: string }) =>
                makeLatLng(
                  parseFloat(coord.latitude),
                  parseFloat(coord.longitude)
                )
            );

            const geoZonePoly = new google.maps.Polygon({
              map: map,
              paths: path,
              strokeColor: circleColor,
              strokeOpacity: 0.8,
              strokeWeight: 3,
              fillColor: circleColor,
              fillOpacity: 0.5,
              geodesic: true,
            });

            if (geoZoneData.gz_id === chosenGeoZone) {
              chosenGeoZonePolygon = geoZonePoly;
            }

            geoZonePoly.addListener("click", () => {
              chooseGeoZone(geoZoneData.gz_id);
            });

            return geoZonePoly;
          });

    if (infoWindow !== undefined && chosenCoords !== null) {
      infoWindow.setPosition(chosenCoords);
      infoWindow.setContent(chosenContentString);
      infoWindow.setOptions({ pixelOffset: new google.maps.Size(0, -30) });
      infoWindow.open({
        anchor: chosenGeoZonePolygon,
      });
    }
  }

  return (
    <div
      id="geo_zones_map_canvas"
      className="w-full h-[28rem] rounded-xl text-black bg-[#8c8c8c] border-2 shadow-[4px_4px_8px_2px_rgba(0,0,0,0.1)]"
    />
  );
}
