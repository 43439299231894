import { useEffect, useState } from "react";
import useFetchData from "../../../apiComms/fetchData";
import { useAppContext } from "../../../context/variables";
import { genericTableRelatedBits } from "../../../components/Table";
import Tab from "../../../tabs/Tab";
import {
  inputSetting,
  inputTray,
  makeTripTypePicker,
  makeVesselPicker,
} from "../../../components/variousInputs";
import { GeneralDateRangePicker } from "../../../components/timeInputs";
import { UtcNotice } from "../../../components/miscellaneous";
import TripsTable from "./TripsTable";
import {
  addDays,
  dateToStringUtc,
  nowDate,
  startOfUTCDay,
  startOfUTCDayAfter,
} from "../../../helpers/dateTimeHelpers";
import EmptyState from "../../../components/atoms/EmptyState";
import { CenteredContainer } from "../../../components/styles/EmptyStateStyles";
import HistoryIcon from "../../../assets/icons/EmptyStates/HistoryIcon";

export default function TripsTab() {
  const [vessel, setVessel] = useState("allVessels");
  const [selectedTripType, setSelectedTripType] = useState("leg");
  const initialEndDate = startOfUTCDay(nowDate());
  const initialStartDate = addDays(initialEndDate, -6);
  const [startDate, setStartDate] = useState<Date | null>(initialStartDate);
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate);
  const { data, vars } = useAppContext();
  const {
    fetchTagEvents,
    fetchDataTagTypes,
    fetchDataOrganisations,
    fetchUsersGeneral,
    fetchTripsTypeData,
  } = useFetchData();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchDataOrganisations();
    fetchUsersGeneral();
    fetchDataTagTypes();
  }, [vars.auth.idToken, vars.auth.organisationId]);

  useEffect(() => {
    if (vessel && selectedTripType && startDate && endDate) {
      fetchTrips();
    }
  }, [selectedTripType, vessel, startDate, endDate]);

  const tripTypes = [
    { value: "leg", name: "Legs" },
    { value: "engineonoff", name: "Engine On/Off" },
    { value: "opmode", name: "Operation Mode" },
    { value: "voyage", name: "Voyages" },
  ];

  const fetchTrips = () => {
    const startDateStr = startDate ? dateToStringUtc(startDate, true) : null;
    const endDateStr = endDate
      ? dateToStringUtc(startOfUTCDayAfter(endDate), true)
      : null;
    const vesselStr = vessel === "allVessels" ? null : vessel;

    setIsLoading(true);
    fetchTripsTypeData(selectedTripType, vesselStr, startDateStr, endDateStr);
    setIsLoading(false);
  };

  const tripTypePicker = makeTripTypePicker(
    selectedTripType,
    tripTypes,
    (tripType: string) => setSelectedTripType(tripType)
  );
  const tripTypeDropdown = inputSetting("Trip Type", tripTypePicker, 240);
  
  useEffect(() => {
    if (vessel && selectedTripType && startDate && endDate) {
      const startDateStr = startDate ? dateToStringUtc(startDate, true) : null;
      const endDateStr = endDate
        ? dateToStringUtc(startOfUTCDayAfter(endDate), true)
        : null;
      const vesselStr = vessel === "allVessels" ? null : vessel;

      setIsLoading(true); 
      fetchTripsTypeData(selectedTripType, vesselStr, startDateStr, endDateStr);
      setIsLoading(false);
    }
  }, [selectedTripType, vessel, startDate, endDate]);
  
  const vesselOptions = [{ id: "allVessels", name: "All vessels" }].concat(
    data.org.vesselList
  );
  const vesselPicker = makeVesselPicker(vessel, vesselOptions, setVessel);
  const vesselDropdown = inputSetting("Vessel", vesselPicker, 240);
  const dateRangePicker = GeneralDateRangePicker(
    startDate,
    endDate,
    setStartDate,
    setEndDate
  );
  const inputs = [[vesselDropdown, dateRangePicker, tripTypeDropdown]];

  const isEmptyState =
    !isLoading &&
    (!data.trips.tripList ||
      (Array.isArray(data.trips.tripList[0]) &&
        data.trips.tripList[0].length === 0));

  const content = (
    <div className="flex flex-col space-y-3 text-deep_blue">
      <div className="flex items-center space-x-4">
        {genericTableRelatedBits(
          "Trips",
          data.trips.tripList?.[0]?.length,
          fetchTrips
        )}
        <UtcNotice />
      </div>
      {inputTray(inputs)}
      {isLoading ? (
        <div>Loading...</div>
      ) : isEmptyState ? (
        <CenteredContainer>
          <EmptyState
            icon={<HistoryIcon />}
            title="No trips available"
            description="Choose another timeframe to get data"
          />
        </CenteredContainer>
      ) : (
        <TripsTable
          showVesselColumn={vessel === "allVessels"}
          shouldRenderMap={true}
          selectedTripType={selectedTripType}
        />
      )}
    </div>
  );

  return <Tab title="Trips" content={content} />;
}
