import { coords } from "../components/Map";
import { KM_PER_NM, KNOTS_PER_MPS } from "../context/constants";
import {
  AccessLevel,
  Aggregation,
  EntityState,
  OperatorTabMetric,
  RealTimeSignal,
  ReportTimescale,
  VesselActivity,
  realTimeSignalMap,
  stringToEntityState,
} from "../context/types";
import {
  destinationEntry,
  energyOperatorSeriesData,
  engineDieselRateSogPoint,
  enginePowerSogPoint,
  engineSpeedTorquePoint,
  graphPoint,
  realTimePoint,
  reportEntry,
  sogDistPoint,
  vesselListEntryData,
  usersEntry,
  ReportFileFormat,
} from "../context/variables";
import {
  energyOperatorsMetricCalculation,
  energyOperatorsMetricValueSetMap,
  metricBits,
  rawVesselEntry,
} from "./helpers";

export function apiConverterRealTimeData(
  dataRow: string[],
  columnList: string[],
  graphOutput: RealTimeSignal
): realTimePoint {
  // console.log("In apiConverterRealTimeData:", dataRow, columnList, graphOutput)
  const timestampStr = dataRow[columnList.indexOf("binned_time")];
  const latitudeStr = dataRow[columnList.indexOf("avg_lat")];
  const longitudeStr = dataRow[columnList.indexOf("avg_lon")];
  const headingTrueStr = dataRow[columnList.indexOf("avg_heading_true")];
  const signalValueStr =
    dataRow[columnList.indexOf(realTimeSignalMap[graphOutput].apiColumnName)];
  const signalValueConverted =
    parseFloat(signalValueStr) *
    realTimeSignalMap[graphOutput].conversionFactor;
  // console.log("Signal found:", signalValueStr, graphOutput, realTimeSignalMap[graphOutput].apiColumnName)
  // console.log("Extracted columns:", timestampStr, latitudeStr, longitudeStr, headingTrueStr, signalValueStr)
  // console.log("Convert:", signalValueStr, signalValueConverted)
  return {
    timestamp: timestampStr,
    latitude: parseFloat(latitudeStr),
    longitude: parseFloat(longitudeStr),
    headingTrue: parseFloat(headingTrueStr),
    signalType: graphOutput,
    signalValue: signalValueConverted,
  };
}

export function apiEnergyOperatorsConverter(
  apiData: any[],
  columnList: string[],
  operators: string[] | null,
  userList: usersEntry[] | null,
  metric: OperatorTabMetric,
  activities: VesselActivity[],
  aggregation: Aggregation
): energyOperatorSeriesData[] {
  // apiData format is like: [val_id_1, val_id_2, year, month, activity, operator_id][]
  // (unique times: 0-31, unique activities: 0-7, unique operators: 0-(~10?))

  // console.log("Before any steps:", apiData, columnList, operators, userList)
  // map through returnData, convert to organisedDataPoint format, assemble list of unique timestamps as you go
  const aggrColumnName = aggregation;
  interface organisedDataPoint {
    operator: string;
    activity: string;
    timestamp: string;
    value_set: {
      [key: string]: number;
    };
  }
  let foundTimestamps: string[] = [];
  const organisedData: organisedDataPoint[] = [];
  apiData.forEach((data: any[]) => {
    const timeStr: string = data[columnList.indexOf(aggrColumnName)];
    const timeIso = timeStr.replace(" ", "T").split(".")[0];
    foundTimestamps.push(timeIso);
    const activityStr = data[columnList.indexOf("activity")];
    const operator_idStr = data[columnList.indexOf("operator_id")];
    const valueNames = energyOperatorsMetricValueSetMap[metric];
    let values: { [key: string]: number } | null = {};
    for (let valueName of valueNames) {
      const value = data[columnList.indexOf(valueName)];
      const valNum = parseFloat(value);
      if (isNaN(valNum)) {
        values = null;
        break;
      }
      values[valueName] = valNum;
    }
    if (values !== null)
      organisedData.push({
        operator: operator_idStr,
        activity: activityStr,
        timestamp: timeIso,
        value_set: values,
      });
  });
  let uniqueTimestamps: string[] = [...new Set(foundTimestamps)];
  // console.log("organisedData:", organisedData, typeof organisedData) //foundTimestamps, uniqueTimestamps,

  // Convert to pseudoActivityDataPoint format
  interface actValSet {
    activity: string;
    value_set: { [key: string]: number };
  }
  interface pseudoActVal {
    pseudo_activity: VesselActivity;
    value: number;
  }
  interface pseudoActivityDataPoint {
    operator: string;
    pseudo_activity: VesselActivity;
    timestamp: string;
    value: number;
  }
  function sumField(list: { [key: string]: number }[], fieldName: string) {
    let sum: number = 0;
    list.forEach((element) => {
      const elementFieldValue: number = element[fieldName];
      sum = sum + elementFieldValue;
    });
    return sum;
  }
  // Takes a list of activities and raw value sets (of the same timestamp and operator)
  // and returns a list of pseudo-activities and the calculated value for each
  function lumpActivitiesFindValue(unLumped: actValSet[]): pseudoActVal[] {
    // Replace activity with relevant pseudo-activity
    const pseudofied = unLumped.map((entry) => {
      const activity = entry.activity;
      const pseudo_activity =
        activity === "DOCKED" || activity === "DOCKING"
          ? VesselActivity.AtDock
          : VesselActivity.Sailing;
      return { pseudo_activity: pseudo_activity, value_set: entry.value_set };
    });
    // Collect value sets by pseudo-activity
    let sailingValSets: { [key: string]: any }[] = [];
    let atDockValSets: { [key: string]: any }[] = [];
    pseudofied.forEach((entry) => {
      if (entry.pseudo_activity === VesselActivity.Sailing) {
        sailingValSets = sailingValSets.concat(entry.value_set);
      } else {
        atDockValSets = atDockValSets.concat(entry.value_set);
      }
    });
    // Sum up each field of the value sets for sail/dock/all
    let sailingSums: { [key: string]: number } = {};
    let atDockSums: { [key: string]: number } = {};
    let totalSums: { [key: string]: number } = {};
    const valueNames = energyOperatorsMetricValueSetMap[metric];
    valueNames.forEach((element) => {
      const sailingSum = sumField(sailingValSets, element);
      const atDockSum = sumField(atDockValSets, element);
      const totalSum = sailingSum + atDockSum;
      sailingSums[element] = sailingSum;
      atDockSums[element] = atDockSum;
      totalSums[element] = totalSum;
    });
    // Call metric-specific function to calculate value based on value set, for each pseudo-activity
    const sailingValue = energyOperatorsMetricCalculation[metric](sailingSums);
    const atDockValue = energyOperatorsMetricCalculation[metric](atDockSums);
    const totalValue = energyOperatorsMetricCalculation[metric](totalSums);
    const allValues: pseudoActVal[] = [
      { pseudo_activity: VesselActivity.Sailing, value: sailingValue },
      { pseudo_activity: VesselActivity.AtDock, value: atDockValue },
      { pseudo_activity: VesselActivity.Total, value: totalValue },
    ];
    // filter on requested pseudo_activities
    const lumped: pseudoActVal[] = allValues.filter((entry) =>
      activities.includes(entry.pseudo_activity)
    );
    // console.log("Lumping:", unLumped, lumped)
    return lumped;
  }
  // forEach requested operator & unique timestamp: filter out those that match, make list of activity and value_set, run lumpActivitiesFindValue
  const operatorEntries =
    userList === null ? [] : userList.filter((entry) => entry.is_operator);
  const operatorOptions = operatorEntries.map((entry) => entry.username);
  // console.log("operatorEntries:", operatorEntries, operatorOptions)
  const operatorList = operators !== null ? operators : operatorOptions;
  let pseudoActivityData: pseudoActivityDataPoint[] = [];
  operatorList.forEach((operator) => {
    uniqueTimestamps.forEach((timestamp) => {
      const matching = organisedData.filter(
        (entry) => entry.operator === operator && entry.timestamp === timestamp
      );
      if (matching.length === 0) return;
      const matchingActValsets: actValSet[] = matching.map((entry) => {
        return { activity: entry.activity, value_set: entry.value_set };
      });
      const pseudoActVals = lumpActivitiesFindValue(matchingActValsets);
      const lumpedPoints: pseudoActivityDataPoint[] = pseudoActVals
        .map((pseudoActVal) => {
          return {
            operator: operator,
            pseudo_activity: pseudoActVal.pseudo_activity,
            timestamp: timestamp,
            value: pseudoActVal.value,
          };
        })
        .filter((lumpedPoint) => !isNaN(lumpedPoint.value));
      pseudoActivityData = pseudoActivityData.concat(lumpedPoints);
    });
  });
  // console.log("pseudoActivityData:", pseudoActivityData)

  // Rearranges data to fit context format: one list of {timestamps, value} per operator and pseudo-activity
  let contextSeries: energyOperatorSeriesData[] = [];
  // forEach requested operator & pseudo_activity: filter out those that match, make list of timestamp and value, and add an entry to contextSeries
  operatorList.forEach((operator) => {
    activities.forEach((activity) => {
      const matching = pseudoActivityData.filter(
        (entry) =>
          entry.operator === operator && entry.pseudo_activity === activity
      );
      if (matching.length > 0) {
        const matchingGraphPoints: graphPoint[] = matching.map((entry) => {
          return { timestamp: entry.timestamp, value: entry.value };
        });
        const contextLine: energyOperatorSeriesData = {
          operator: operator,
          activity: activity,
          data: matchingGraphPoints,
        };
        contextSeries = contextSeries.concat(contextLine);
      }
    });
  });
  // console.log("At context format:", contextSeries)

  return contextSeries;
}

export function apiConverterGraph(
  apiData: any[],
  columnList: string[],
  metric: OperatorTabMetric,
  aggregation: Aggregation
): { value: number; timestamp: string } | null {
  // console.log("apiConverterGraph:", apiData, columnList, valColumnName)
  const aggrColumnName = aggregation;
  const valColumnName = metricBits[metric];
  const timeStr: string = apiData[columnList.indexOf(aggrColumnName)];
  const timeIso = timeStr.replace(" ", "T").split(".")[0];
  // const yearVal = apiData[columnList.indexOf("year")]
  // const monthVal = apiData[columnList.indexOf("month")]
  // if (yearVal === undefined || monthVal === undefined) {
  //   return null
  // }
  // let dayVal: number
  // if (columnList.includes("day")) {
  //   dayVal = apiData[columnList.indexOf("day")]
  // } else {
  //   dayVal = 1
  // }
  // let hourVal: number
  // if (columnList.includes("hour")) {
  //   hourVal = apiData[columnList.indexOf("hour")]
  // } else {
  //   hourVal = 0
  // }
  const conversionFactor =
    metric === OperatorTabMetric.FuelOverDistance ||
    metric === OperatorTabMetric.ElectricOverDistance
      ? KM_PER_NM
      : 1;
  const rawVal = apiData[columnList.indexOf(valColumnName)];
  // const convertedTimestamp = yearVal+"-"+zeroPad(monthVal)+"-"+zeroPad(dayVal)+"T"+zeroPad(hourVal)+":00:00"
  // console.log("graph point converted:", conversionFactor, rawVal, timeIso)
  const graphPointConverted =
    rawVal !== null
      ? { value: conversionFactor * rawVal, timestamp: timeIso }
      : null;
  return graphPointConverted;
}

export function apiConverterVesselList(
  rawVessel: rawVesselEntry
): vesselListEntryData {
  const theId = rawVessel.uid;
  const theName = rawVessel.name;
  return { id: theId, name: theName };
}

export function apiConverterDestList(rawDestEntry: {
  lid: string;
  display_name: string;
  location: {
    latitude: number;
    longitude: number;
    radius: number | null;
    polygon: { latitude: number; longitude: number }[] | null;
  };
  state: string | null;
  metadata: any | null;
  org_uid: string;
}): destinationEntry {
  const theLatitude = rawDestEntry.location.latitude;
  const theLongitude = rawDestEntry.location.longitude;
  const theRadius = rawDestEntry.location.radius;
  const polygon = rawDestEntry.location.polygon;
  const polygonCoords: coords[] | null =
    polygon === null
      ? null
      : polygon.map((point: { latitude: number; longitude: number }) => {
          return {
            lat: point.latitude,
            lng: point.longitude,
          };
        });
  const metadata = rawDestEntry.metadata;
  const metadataStr = metadata === null ? null : JSON.stringify(metadata);
  const state = rawDestEntry.state;
  const stateEnumNull: EntityState | null =
    state === null ? null : stringToEntityState(state);
  const stateEnum: EntityState =
    stateEnumNull === null ? EntityState.Active : stateEnumNull;
  return {
    lid: rawDestEntry.lid,
    orgUid: rawDestEntry.org_uid,
    displayName: rawDestEntry.display_name,
    latitude: theLatitude,
    longitude: theLongitude,
    radius: theRadius,
    polygon: polygonCoords,
    metadata: metadataStr,
    state: stateEnum,
  };
}

export function apiConverterUserEntry(rawUserEntry: {
  username: string;
  name: string | null;
  org_id: string | null;
  email: string | null;
  phone: string | null;
  is_root: boolean;
  is_owner: boolean;
  is_admin: boolean;
  is_operator: boolean;
  pin_code: string | null;
  user_status: string;
}): usersEntry {
  const theAccessLevel = rawUserEntry.is_root
    ? AccessLevel.Root
    : rawUserEntry.is_owner
    ? AccessLevel.Owner
    : rawUserEntry.is_admin
    ? AccessLevel.Admin
    : AccessLevel.User;
  return {
    username: rawUserEntry.username.toUpperCase(),
    name: rawUserEntry.name,
    org_id: rawUserEntry.org_id,
    email: rawUserEntry.email,
    phone: rawUserEntry.phone,
    access_level: theAccessLevel,
    is_operator: rawUserEntry.is_operator,
    pin_code: rawUserEntry.pin_code,
    user_status: rawUserEntry.user_status,
  };
}

function apiConverterEngineSpeedTorque(rawEngineSpeedTorquePoint: {
  engine_speed: string;
  engine_torque_percent: string;
  time: string;
  time_percent: string;
}): engineSpeedTorquePoint {
  return {
    engineSpeedRpm: parseFloat(rawEngineSpeedTorquePoint.engine_speed),
    torquePercent: parseFloat(rawEngineSpeedTorquePoint.engine_torque_percent),
    timeHours: parseFloat(rawEngineSpeedTorquePoint.time) / 3600,
    timePercent: parseFloat(rawEngineSpeedTorquePoint.time_percent),
  };
}

export function apiConverterEngineSpeedTorqueMaps(
  rawMaps: any
): { engine: string; map: engineSpeedTorquePoint[] }[] | null {
  if (rawMaps === null) return null;
  const engineKeys = Object.keys(rawMaps);
  const convertedMaps = engineKeys.map((engineKey) => {
    const convertedMap: engineSpeedTorquePoint[] = rawMaps[engineKey].map(
      apiConverterEngineSpeedTorque
    );
    return { engine: engineKey, map: convertedMap };
  });
  return convertedMaps;
}

export function apiConverterSogDist(rawSogDistPoint: {
  sog: string;
  time: string;
  time_percent: string;
}): sogDistPoint {
  return {
    sogKn: parseFloat(rawSogDistPoint.sog) * KNOTS_PER_MPS,
    timeHours: parseFloat(rawSogDistPoint.time) / 3600,
    timePercent: parseFloat(rawSogDistPoint.time_percent),
  };
}

export function apiConverterTotalEngineDieselRateSog(rawTotalEngineDieselRateSogPoint: {
  engine_diesel_rate: string;
  sog: string;
  time: string;
  time_percent: string;
}): engineDieselRateSogPoint {
  return {
    engineDieselRateLph: parseFloat(
      rawTotalEngineDieselRateSogPoint.engine_diesel_rate
    ),
    sogKn: parseFloat(rawTotalEngineDieselRateSogPoint.sog) * KNOTS_PER_MPS,
    timePercent: parseFloat(rawTotalEngineDieselRateSogPoint.time_percent),
    timeHours: parseFloat(rawTotalEngineDieselRateSogPoint.time) / 3600,
  };
}

export function apiConverterTotalEnginePowerSog(rawTotalEnginePowerSogPoint: {
  engine_power: string;
  sog: string;
  time: string;
  time_percent: string;
}): enginePowerSogPoint {
  return {
    enginePowerKw: parseFloat(rawTotalEnginePowerSogPoint.engine_power),
    sogKn: parseFloat(rawTotalEnginePowerSogPoint.sog) * KNOTS_PER_MPS,
    timePercent: parseFloat(rawTotalEnginePowerSogPoint.time_percent),
    timeHours: parseFloat(rawTotalEnginePowerSogPoint.time) / 3600,
  };
}

export function extractReportFileFormat(filename: string): ReportFileFormat {
  return filename.endsWith(".csv")
    ? ReportFileFormat.CSV
    : filename.endsWith(".pdf")
    ? ReportFileFormat.PDF
    : ReportFileFormat.Unknown;
}

export function apiConverterReportList(rawReport: {
  filename: string;
  link: string;
  path: string;
  report_date: string;
  report_interval: string;
  report_kind: string;
  vessel: string;
  category: string | null;
  uid: string | null;
}): reportEntry {
  return {
    filename: rawReport.filename,
    fileFormat: extractReportFileFormat(rawReport.filename),
    link: rawReport.link,
    path: rawReport.path,
    reportDate: rawReport.report_date,
    reportInterval: rawReport.report_interval as ReportTimescale,
    reportKind: rawReport.report_kind,
    vesselId: rawReport.vessel,
    legName: rawReport.category,
    orgId: rawReport.uid,
  };
}
