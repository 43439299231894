import React, { useState } from "react";

import { FaAngleUp, FaAngleDown, FaEdit, FaTrashAlt } from "react-icons/fa";

import { geoZonesEntry, useAppContext } from "../../../context/variables";


interface TableRow {
  id: string;
  contents: (string | React.ReactNode | (() => React.ReactNode))[];
}

interface GeoZonesTableProps {
  columnNames: string[];
  rows: geoZonesEntry[];
  sortableColumns: string[];
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onSetStateRemoved: (id: string) => void;
  showExtraData: boolean;
  isRoot: boolean;
  chooseGeoZone: (geoZone: string | null) => void;
  chosenGeoZone: string | null;
  setDeleteModalOpen: (isOpen: boolean) => void; 
}

const GeoZonesTable: React.FC<GeoZonesTableProps> = ({
  columnNames,
  rows,
  sortableColumns,
  onEdit,
  onDelete,
  onSetStateRemoved,
  showExtraData,
  isRoot,
  chooseGeoZone,
}) => {
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const { vars } = useAppContext();
  const organisationId = vars.auth.organisationId;

  const showExtraColumns = organisationId === null && isRoot;

  const sortRows = (rows: TableRow[]): TableRow[] => {
    if (!sortOrder || !sortColumn) return rows;
    const columnIndex = columnNames.indexOf(sortColumn);

    return [...rows].sort((a, b) => {
      const contentA = a.contents[columnIndex];
      const contentB = b.contents[columnIndex];

      if (typeof contentA === "string" && typeof contentB === "string") {
        return sortOrder === "asc"
          ? contentA.localeCompare(contentB) 
          : contentB.localeCompare(contentA);
      }

      return 0;
    });
  };

  const convertToTableRow = (entry: geoZonesEntry): TableRow => {
    return {
      id: entry.gz_id,
      contents: [
        entry.name || "N/A",
        entry.shape.coordinates.map(
          (coord) => `(${coord.latitude}, ${coord.longitude})`
        ),
        entry.shape.type || "N/A",
        entry.state || "N/A",
        showExtraColumns ? entry.gz_id || "N/A" : null,
        showExtraColumns ? entry.org_id || "N/A" : null,
        showExtraColumns ? entry.metadata || "N/A" : null,
      ].filter((content) => content !== null), 
    };
  };

  const renderSortIcons = (column: string) => {
    if (sortableColumns && !sortableColumns.includes(column)) return null;
    return (
      <span className="ml-2 inline-flex flex-col items-center justify-center">
        <FaAngleUp
          onClick={() => {
            setSortColumn(column);
            setSortOrder("asc");
          }}
          className={
            sortOrder === "asc" && sortColumn === column
              ? "text-blue-500"
              : "text-gray-400"
          }
        />
        <FaAngleDown
          onClick={() => {
            setSortColumn(column);
            setSortOrder("desc");
          }}
          className={
            sortOrder === "desc" && sortColumn === column
              ? "text-blue-500"
              : "text-gray-400"
          }
        />
      </span>
    );
  };


  const renderActions = (rowId: string): React.ReactNode => (
    <>
      {showExtraData && (
        <>
          <FaEdit
            className="text-gray-600 hover:text-gray-800 cursor-pointer mr-3"
            onClick={(e) => {
              e.stopPropagation();
              onEdit(rowId);
            }}
          />
          <FaTrashAlt
            className={`text-red-600 hover:text-red-700 cursor-pointer ${
              isRoot ? "" : "mr-3"
            }`}
            onClick={(e) => {
              e.stopPropagation();
              if (isRoot) {
                onDelete(rowId); 
              } else {
                onSetStateRemoved(rowId);
              }
            }}
          />
        </>
      )}
    </>
  );  

  return (
    <div className="text-lg bg-white rounded-xl h-fit w-fit overflow-hidden shadow-[4px_4px_8px_2px_rgba(0,0,0,0.1)]">
      <table className="w-fit table-auto px-20">
        <thead className="h-10 px-20 bg-deep_blue text-white">
          <tr className="border-b-2 mx-20 border-[#d9d9d9]">
            {columnNames.map((column) => (
              <th className={`pl-2 pr-2 text-left`} key={column}>
                <div className="inline-flex items-center">
                  {column}
                  {renderSortIcons(column)}
                </div>
              </th>
            ))}
            {showExtraData && <th className="pl-2 pr-2 text-left">Actions</th>}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-300">
          {sortRows(rows.map(convertToTableRow)).map((row) => (
            <React.Fragment key={row.id}>
              <tr
                onClick={() => {
                  setSelectedRow(row.id);
                  chooseGeoZone(row.id);
                }}
                className={selectedRow === row.id ? "bg-gray-100" : ""}
                style={{ cursor: "pointer" }} 
              >
                {row.contents.map((content, index) => (
                  <td
                    className="px-2 py-4 whitespace-nowrap"
                    key={`${row.id}-${index}`}
                  >
                    {typeof content === "function" ? content() : content}
                  </td>
                ))}
                <td className="px-2 py-5 whitespace-nowrap flex justify-center items-center">
                  {renderActions(row.id)}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GeoZonesTable;
