import { useEffect, useState } from "react";
import Tab from "../../../tabs/Tab";
import { addButton } from "../../../components/Button";
import { genericTableRelatedBits } from "../../../components/Table";
import LoadingSpinner from "../../../components/atoms/LoadingSpinner";
import EmptyState from "../../../components/atoms/EmptyState";
import NoGPSIcon from "../../../assets/icons/EmptyStates/NoGPSIcon";

import { useAppContext } from "../../../context/variables";
import { EntityState } from "../../../context/types";

import useFetchData from "../../../apiComms/fetchData";
import useManageData from "../../../apiComms/manageData";
import { AddGeoZoneModal } from "./AddGeoZoneModal";
import { EditGeoZoneModal } from "./EditGeoZoneModal";
import GeoZonesTable from "./GeoZonesTable";
import { MainGeoZonesMap } from "./MainGeoZonesMap";

type DeleteGeoZoneConfirmationModalProps = {
  geoZoneId: string;
  onDeleteConfirm: () => void;
  onClose: () => void;
  deleteConfirmModalOpen: boolean;
  setDeleteConfirmModalOpen: (open: boolean) => void;
};

function clickHandler(e: any, chooseGeoZone: (geoZone: string | null) => void) {
  const elementId: string = e.target.id;
  if (
    elementId === "tabPageContainer-Administration" ||
    elementId === "tabPageContent-Administration" ||
    elementId === "tabComponent-GeoZones" ||
    elementId === "GeoZonesContent" ||
    elementId === "GeoZonesTable" ||
    elementId === "GeoZonesTableHeaders"
  ) {
    chooseGeoZone(null);
  }
}

export default function GeoZonesTab() {
  const { vars, data } = useAppContext();
  const { apiDeleteGeoZones } = useManageData();
  const { fetchDataGeoZonesTab, fetchDataOrganisations } = useFetchData();

  type ActionType = "delete" | "setRemoved";

  const [, setActionType] = useState<ActionType>("delete");
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [] = useState(false);
  const [orgIdToEdit, setOrgIdToEdit] = useState<string | null>(null);
  const [] = useState(false);
  const [] = useState<string | null>(null);
  const [geoZoneOrgId, setGeoZoneOrgId] = useState<string | undefined>();
  const [addGeoZoneModalOpen, setAddGeoZoneModalOpen] = useState(false);
  const [manageGeoZoneModalOpen, setManageGeoZoneModalOpen] = useState(false);
  const [managedGeoZoneId, setManagedGeoZoneId] = useState<string | null>(null);
  const [, setSelectedGeoZone] = useState<string | null>(null);
  const [chosenGeoZone, setChosenGeoZone] = useState<string | null>(null);
  const [, setDeleteModalOpen] = useState(false);
  const [geoZoneToDelete, setGeoZoneToDelete] = useState<string | null>(null);
  const [editGeoZoneName, setEditGeoZoneName] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const isRoot =
    vars.auth.userGroups !== null && vars.auth.userGroups.includes("root");

  const organisationId = vars.auth.organisationId;

  const showExtraColumns = organisationId === null && isRoot;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchDataOrganisations();
      await fetchDataGeoZonesTab();
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.idToken, vars.auth.organisationId]);

  const handleEditGeoZone = (id: string) => {
    const geoZoneToEdit = geoZoneListFiltered.find((gz) => gz.gz_id === id);

    if (geoZoneToEdit) {
      setManagedGeoZoneId(id);
      setEditGeoZoneName(geoZoneToEdit.name);
      setOrgIdToEdit(geoZoneToEdit.org_id); 
      setManageGeoZoneModalOpen(true);
    } else {
      console.error("Geo Zone not found for editing");
    }
  };

  const handleDeleteGeoZone = (geoZoneId: string) => {
    const geoZone = geoZoneList?.find((gz) => gz.gz_id === geoZoneId);

    if (geoZone) {
      setGeoZoneToDelete(geoZoneId);
      setGeoZoneOrgId(geoZone.org_id);
      setDeleteConfirmModalOpen(true);
    } else {
      console.error("Geo Zone or Org ID not found for deletion");
    }
  };

  const handleSetStateRemovedGeoZone = (geoZoneId: string) => {
    const geoZoneData = geoZoneList?.find(
      (geoZone) => geoZone.gz_id === geoZoneId
    );
    if (geoZoneData) {
      setGeoZoneToDelete(geoZoneId);
      setGeoZoneOrgId(geoZoneData.org_id);
      setActionType("setRemoved");
      setDeleteConfirmModalOpen(true);
    } else {
      console.error("Geo Zone or Org ID not found for set state to removed");
    }
  };

  useEffect(() => {
    fetchDataOrganisations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.idToken, vars.auth.organisationId]);
  useEffect(() => {
    // console.log("calling fetch in useEffect:", vars.auth.idToken)
    fetchDataGeoZonesTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.organisationId, vars.auth.userGroups]);

  useEffect(() => {
    window.addEventListener("click", (e) => clickHandler(e, chooseGeoZone));
    return () => {
      window.removeEventListener("click", (e) =>
        clickHandler(e, chooseGeoZone)
      );
    };
  }, []);

  function chooseGeoZone(newGeoZone: string | null) {
    setChosenGeoZone(newGeoZone);
    setSelectedGeoZone(newGeoZone);
  }

  const showHiddenGeoZones = organisationId === null && isRoot;
  const geoZoneList = data.org.geoZoneList?.val;
  const geoZoneListFiltered =
    geoZoneList === null
      ? []
      : showHiddenGeoZones
      ? geoZoneList
      : geoZoneList.filter(
          (entry: { state: EntityState }) =>
            entry.state !== EntityState.Rejected &&
            entry.state !== EntityState.Removed
        );

  const DeleteGeoZoneConfirmationModal: React.FC<
    DeleteGeoZoneConfirmationModalProps
  > = ({
    geoZoneId,
    onClose,
    deleteConfirmModalOpen,
    setDeleteConfirmModalOpen,
  }) => {
    const handleConfirm = () => {
      apiDeleteGeoZones(geoZoneOrgId, geoZoneId);
      setDeleteConfirmModalOpen(false);
    };

    return (
      <div
        id="popup-modal"
        className={`fixed inset-0 flex items-center justify-center z-50 p-4 overflow-x-hidden overflow-y-auto ${
          deleteConfirmModalOpen ? "block" : "hidden"
        }`}
      >
        <div className="relative w-full max-w-md">
          <div className="relative bg-white rounded-lg shadow">
            <button
              onClick={() => {
                onClose();
                setDeleteConfirmModalOpen(false);
              }}
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="p-6 text-center">
              <svg
                className="mx-auto mb-4 text-gray-400 w-12 h-12"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1-18 0Z"
                />
              </svg>
              <h3 className="mb-5 text-lg font-normal text-gray-500">
                Are you sure you want to delete this Geo Zone?
              </h3>

              <button
                onClick={handleConfirm}
                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
              >
                Confirm
              </button>
              <button
                onClick={() => {
                  onClose();
                  setDeleteConfirmModalOpen(false);
                }}
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const columnNames = showExtraColumns
    ? [
        "Name",
        "Coordinates",
        "Edge shape",
        "State",
        "geo zone id",
        "org uid (org name)",
        "metadata",
      ]
    : ["Name", "Coordinates", "Edge shape", "State"];

  const geoZonesContent = (
    <div
      id="GeoZonesContent"
      className="h-full flex flex-col space-y-3 text-deep_blue"
    >
      {MainGeoZonesMap(
        data,
        vars,
        chosenGeoZone,
        chooseGeoZone,
        geoZoneList
      )}

      {addButton("Add new Geo Zone", () => setAddGeoZoneModalOpen(true))}
      {genericTableRelatedBits(
        "Geo Zones",
        geoZoneListFiltered?.length,
        fetchDataGeoZonesTab
      )}
      {loading ? (
        <LoadingSpinner />
      ) : geoZoneListFiltered && geoZoneListFiltered.length > 0 ? (
        <GeoZonesTable
          columnNames={columnNames}
          rows={geoZoneListFiltered}
          sortableColumns={["Name", "State"]}
          onEdit={handleEditGeoZone}
          onDelete={handleDeleteGeoZone}
          onSetStateRemoved={handleSetStateRemovedGeoZone}
          showExtraData={true}
          isRoot={isRoot}
          chooseGeoZone={chooseGeoZone}
          chosenGeoZone={chosenGeoZone}
          setDeleteModalOpen={setDeleteModalOpen}
        />
      ) : (
        <EmptyState
          icon={<NoGPSIcon />}
          title="No Geo Zones have been added"
          description="When you add a Geo Zone, it will appear here"
          buttonText="Add Geo Zone"
          onButtonClick={() => setAddGeoZoneModalOpen(true)}
        />
      )}

      <DeleteGeoZoneConfirmationModal
        geoZoneId={geoZoneToDelete || ""}
        onDeleteConfirm={() => apiDeleteGeoZones(geoZoneOrgId, geoZoneToDelete)}
        onClose={() => setDeleteConfirmModalOpen(false)}
        deleteConfirmModalOpen={deleteConfirmModalOpen}
        setDeleteConfirmModalOpen={setDeleteConfirmModalOpen}
      />
      {AddGeoZoneModal(
        addGeoZoneModalOpen,
        setAddGeoZoneModalOpen,
        geoZoneListFiltered
      )}
      {
        <EditGeoZoneModal
          open={manageGeoZoneModalOpen}
          setOpen={setManageGeoZoneModalOpen}
          GeoZonesListFiltered={geoZoneListFiltered}
          editGeoZoneName={editGeoZoneName || ""}
          geoZoneId={managedGeoZoneId}
          orgId={orgIdToEdit}
        />
      }
    </div>
  );

  return <Tab title="GeoZones" content={geoZonesContent} />;
}
