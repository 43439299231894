import React from 'react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className="flex items-center justify-center space-x-4 mt-4">
      <button
        type="button"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`text-blue-500 ${currentPage === 1 ? 'text-gray-400 cursor-not-allowed' : 'hover:text-blue-700'}`}
        aria-label="Previous Page"
        title="Previous Page"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.7092 5.29592C16.0998 5.68631 16.1 6.31948 15.7096 6.71014L10.4186 12.0048L15.7133 17.2959C16.1039 17.6863 16.1042 18.3195 15.7138 18.7101C15.3234 19.1008 14.6902 19.101 14.2995 18.7106L8.29749 12.7127C7.90683 12.3223 7.90662 11.6891 8.29701 11.2985L14.2949 5.29641C14.6853 4.90575 15.3185 4.90553 15.7092 5.29592Z"
            fill="#6D9CBF"
          />
        </svg>
      </button>
      <span className="text-[#143452] font-montserrat text-xl font-medium">
        {currentPage}/{totalPages}
      </span>
      <button
        type="button"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`text-blue-500 ${currentPage === totalPages ? 'text-gray-400 cursor-not-allowed' : 'hover:text-blue-700'}`}
        aria-label="Next Page"
        title="Next Page"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          className="transform rotate-180"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.7092 5.29592C16.0998 5.68631 16.1 6.31948 15.7096 6.71014L10.4186 12.0048L15.7133 17.2959C16.1039 17.6863 16.1042 18.3195 15.7138 18.7101C15.3234 19.1008 14.6902 19.101 14.2995 18.7106L8.29749 12.7127C7.90683 12.3223 7.90662 11.6891 8.29701 11.2985L14.2949 5.29641C14.6853 4.90575 15.3185 4.90553 15.7092 5.29592Z"
            fill="#6D9CBF"
          />
        </svg>
      </button>
    </div>
  );
};

export default Pagination;
