import { styled } from "styled-components";

export const StyledWrapper = styled.div`
  color: black;
  display: inline-grid;
  grid-template-columns: 1fr;
  gap: 2.5rem 2.5rem;

  padding-top: 1rem;

  @media (min-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }
`;
