import React, { useCallback, useEffect, useState } from "react";

import { makeConfirmModal, makeModal } from "../../../components/Modal";
import { makeDropdownInput, makeTextInput } from "../../../components/FormInputs";
import { makeSubmitButton } from "../../../components/Button";
import { CustomTextInputProps, OpModeState, useAppContext } from "../../../context/variables";
import useManageData from "../../../apiComms/manageData";
import LoadingSpinner from "../../../components/atoms/LoadingSpinner";

export function EditOpModeModal(props: { open: boolean, setOpen: (open: boolean) => void, managedId: string | null, onCloseManageModal: () => void }) {
    const { open, setOpen, managedId, onCloseManageModal } = props;

    const { data } = useAppContext();
    const { apiPutOpModes } = useManageData();

    const opModeList = data.opModes.opModeList.val;
    const opModeMaybe = opModeList?.find((opMode) => opMode.opModeId === managedId);
    const oldName = opModeMaybe?.name || "";
    const oldDescription = opModeMaybe?.description || "";
    const oldState = opModeMaybe?.state || OpModeState.Active;
    const stateOptions = Object.values(OpModeState).map((state) => ({ value: state, name: state }));

    const [name, setName] = useState(oldName);
    const [description, setDescription] = useState(oldDescription);
    const [state, setState] = useState(oldState);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false); 

    useEffect(() => {
        setName(oldName);
        setDescription(oldDescription);
        setState(oldState);
    }, [managedId, oldName, oldDescription, oldState]);
    function onClose() {
        setOpen(false);
    }

    function onSubmitClick() {
        setConfirmModalOpen(true);
    }

    function onConfirmClick() {
        setIsLoading(true); 
        const orgId = opModeMaybe?.orgId;
        const opModeId = opModeMaybe?.opModeId;
        const opMode = data.opModes.opModeList.val?.find((opMode) => {
            return opMode.id === managedId
        })
        if (orgId && opModeId) {
            apiPutOpModes(orgId, opModeId, name, description, state);
        } else {
            console.error('Critical data is missing. Cannot proceed:', { orgId, opModeId, opMode });
        }
        setConfirmModalOpen(false);
        setOpen(false);
        onClose()
        onCloseManageModal()
        setIsLoading(false);
    }

    const CustomTextInput: React.FC<CustomTextInputProps> = useCallback((props) => {
        const { title, tooltipText, fieldPrompt, fieldHandle, warning, defaultValue, onChange } = props;
        return (
            <div onChange={onChange}>
                {makeTextInput(title, tooltipText, fieldPrompt, fieldHandle, warning, defaultValue)}
            </div>
        );
    }, []);

    let changedFields = [];
    if (name !== oldName) {
        changedFields.push('name');
    }
    if (description !== oldDescription) {
        changedFields.push('description');
    }
    if (state !== oldState) {
        changedFields.push('state');
    }

    let confirmMessage = '';
    if (changedFields.length > 0) {
        confirmMessage = `You are about to change the ${changedFields.join(', ')} of "${oldName}". Are you sure?`;
    } else {
        confirmMessage = "No changes detected.";
    }

    const content = (
        <div className="flex flex-col space-y-4">
             {isLoading ? <LoadingSpinner /> : (
                    <>
            <CustomTextInput
                title="Name"
                tooltipText="Provide a unique name for this operational mode. This name will be used throughout the system."
                fieldPrompt="Name"
                fieldHandle="opModeName"
                warning={null}
                defaultValue={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            />
            <CustomTextInput
                title="Description"
                tooltipText="Optionally, offer a brief overview that clarifies the purpose or characteristics of this operational mode."
                fieldPrompt="Description"
                fieldHandle="opModeDescription"
                warning={null}
                defaultValue={description}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
            />
            {makeDropdownInput(
                "Operation Mode State",
                "Select the operational mode's current state. Each state may have its own implications on system operations.",
                "State",
                state,
                stateOptions,
                setState,
                null
            )}
            {makeSubmitButton(onSubmitClick)}
            {makeConfirmModal(
                "Confirm editing",
                confirmMessage,
                "Confirm: Edit",
                onConfirmClick,
                confirmModalOpen,
                setConfirmModalOpen
            )}
              </>
            )}
        </div>
    );

    const title = `Edit Operation Mode: ${oldName}`;

    return makeModal(title, content, open, onClose);
}