import { useEffect, useState } from "react";
import { useAppContext } from "../../../context/variables";
import { AccessLevel, accessLevelAtMinimum } from "../../../context/types";
import useFetchData from "../../../apiComms/fetchData";
import Tab from "../../../tabs/Tab";
import { addButton } from "../../../components/Button";
import {
  genericTable2,
  genericTableEditable,
  genericTableRelatedBits,
} from "../../../components/Table";
import EmptyState from "../../../components/atoms/EmptyState";
import HandTabletWrittenIcon from "../../../assets/icons/EmptyStates/HandTabletWrittenIcon";
import { ManageOpModeModal } from "./ManageOpModeModal";
import { AddOpModeModal } from "./AddOpModeModal";

export default function OperationModeTab() {
  const { vars, data } = useAppContext();
  const { fetchDataOpModes, fetchDataOrganisations } = useFetchData();

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [manageModalOpen, setManageModalOpen] = useState(false);
  const [managedId, setManagedId] = useState<string | null>(null);

  const showExtraData =
    vars.auth.organisationId === null &&
    accessLevelAtMinimum(vars.auth.accessLevel[0], AccessLevel.Root);
  const orgsList = data.org.organisationList;
  const opModeList = data.opModes.opModeList.val;
  const opModeListFiltered =
    opModeList === null
      ? null
      : showExtraData
      ? opModeList
      : opModeList.filter((entry) => entry.state !== "Removed");

  useEffect(() => {
    fetchDataOrganisations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.idToken, vars.auth.organisationId]);

  useEffect(() => {
    fetchDataOpModes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.organisationId, vars.auth.accessLevel[0]]);

  const columnNames = showExtraData
    ? ["Name", "Description", "State", "id", "org id (org name)"]
    : ["Name", "Description", "State"];

  const rows =
    opModeListFiltered === null
      ? null
      : opModeListFiltered.map((opMode) => {
          const { name, description, state } = opMode;
          const opModeId = opMode.opModeId || "N/A";
          const orgId = opMode.orgId || "N/A";
          const orgMaybe = orgsList?.find((org) => org.id === orgId);
          const orgName = orgMaybe === undefined ? "N/A" : orgMaybe.name;
          const row = showExtraData
            ? ([
                name,
                description,
                state,
                opModeId,
                `${orgId} (${orgName})`,
              ] as string[])
            : ([name, description, state] as string[]);
          return { id: opMode.opModeId || "N/A", contents: row };
        });

  const hasManagementPermissions = accessLevelAtMinimum(
    vars.auth.accessLevel[0],
    AccessLevel.Admin || AccessLevel.Root
  );

  const content = (
    <div className="h-full flex flex-col space-y-3 text-deep_blue">
      {hasManagementPermissions &&
        addButton("Add new operation mode", () => setAddModalOpen(true))}
      {genericTableRelatedBits(
        "Operation Modes",
        opModeListFiltered?.length,
        fetchDataOpModes
      )}
      {opModeListFiltered?.length === 0 ? (
        <EmptyState
          icon={<HandTabletWrittenIcon />}
          title="No operation modes available"
          description="When you add a new operation mode, it will appear here"
          buttonText="Add operation mode"
          onButtonClick={() => setAddModalOpen(true)}
        />
      ) : hasManagementPermissions ? (
        genericTableEditable(columnNames, rows, (id) => {
          setManagedId(id);
          setManageModalOpen(true);
        })
      ) : (
        genericTable2(columnNames, rows)
      )}
      {AddOpModeModal(addModalOpen, setAddModalOpen)}
      {ManageOpModeModal(
        manageModalOpen,
        setManageModalOpen,
        managedId,
        setManagedId
      )}
    </div>
  );

  return <Tab title="Operation Modes" content={content} />;
}
