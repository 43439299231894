import { useEffect, useState } from "react"
import { useAppContext } from "../../../context/variables"
import { makeDropdownInput, makeOtherInput, makeRadioInput, makeTextInput, makeTextOutput } from "../../../components/FormInputs";
import { TagValueBoolean, TagValueType, tagScopePretty, tagValueBooleanToBoolean, tagValueTypePretty, AccessLevel, EntityState, accessLevelAtMinimum } from "../../../context/types";
import { GeneralDateTimePicker } from "../../../components/timeInputs";
import { makeConfirmModal, makeModal } from "../../../components/Modal";
import { makeSubmitButton } from "../../../components/Button";
import { dateToStringUtc, epochToPrettyStringUtc } from "../../../helpers/dateTimeHelpers";
import useManageData from "../../../apiComms/manageData";
import { UtcNotice } from "../../../components/miscellaneous";
import { getTextFieldValue } from "../../../helpers/elementHelpers";

export function AddTagEventModal(open: boolean, setOpen: (open: boolean)=>void, refreshList: ()=>void) {
  const { data, vars } = useAppContext()
  const { apiPostTagEventsAdd } = useManageData()
  

  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  // ----- Warnings -----
  const [orgWarning, setOrgWarning] = useState<string|null>(null)
  const [vesselWarning, setVesselWarning] = useState<string|null>(null)
  const [dateTimeWarning, setDateTimeWarning] = useState<string|null>(null)
  const [tagTypeWarning, setTagTypeWarning] = useState<string|null>(null)
  const [valueWarning, setValueWarning] = useState<string|null>(null)
  const [userWarning, setUserWarning] = useState<string|null>(null)

  // ----- Standard modal bits -----
  function clearWarnings() {
    setOrgWarning(null)
    setVesselWarning(null)
    setDateTimeWarning(null)
    setTagTypeWarning(null)
    setValueWarning(null)
    setUserWarning(null)
  }
  function resetValues() {
    if(data.tagTypes.tagTypeList.val !== null && data.tagTypes.tagTypeList.val?.length > 0) {
      setTagTypeId(data.tagTypes.tagTypeList.val[0].id)
    }
    if(data.org.vesselList.length > 0) {
      setVessel(data.org.vesselList[0].id)
    }
    setUser("noUser")
    setDateTime(startingDateTime)
  }
  function onClose() {
    setOpen(false)
    clearWarnings()
    resetValues()
  }
  function onSubmitClick() {
    clearWarnings()
    const valueStrMaybe = getTextFieldValue("tagEventValue")
    if(vars.auth.organisationId === null) {
      setOrgWarning("No organisation chosen")
    } else if(vessel === null) {
      setVesselWarning("No vessel chosen")
    } else if(user === null) {
      setUserWarning("No user chosen")
    } else if(dateTime === null) {
      setDateTimeWarning("No date & time chosen")
    } else if(tagTypeId === null) {
      setTagTypeWarning("No tag type chosen")
    } else if((tagValueType === TagValueType.Number || tagValueType === TagValueType.String) && (valueStrMaybe === null || valueStrMaybe.length < 1)) {
      setValueWarning("Please enter a value")
    } else if(tagValueType === TagValueType.Number && (isNaN(Number(valueStrMaybe)))) {
      setValueWarning("Value must be numerical")
    } else {
      setConfirmModalOpen(true)
    }
  }
  function onConfirmClick() {
    const valueFieldStrMaybe = getTextFieldValue("tagEventValue")
    const valueBoolean = tagValueBooleanToBoolean(booleanValue)
    const valueNumber = (valueFieldStrMaybe !== null) ? parseFloat(valueFieldStrMaybe) : null
    const value = 
      (tagType?.valueType === TagValueType.Boolean) ? valueBoolean
      : (tagType?.valueType === TagValueType.String) ? valueFieldStrMaybe
      : (tagType?.valueType === TagValueType.Number) ? valueNumber
      : null
    const dateTimeStrMaybe = (dateTime === null) ? null : dateToStringUtc(dateTime, true)
    const userStrNull = (!accessLevelAtMinimum(vars.auth.accessLevel[0], AccessLevel.Root)) ? vars.auth.username : (user === "noUser") ? "NOUSER" : user
    
    apiPostTagEventsAdd(vars.auth.organisationId, vessel, userStrNull, dateTimeStrMaybe, tagTypeId, value, refreshList)
    setConfirmModalOpen(false)
    onClose()
  }

  // ----- Org -----
  const isRoot = accessLevelAtMinimum(vars.auth.accessLevel[0], AccessLevel.Root)
  // useEffect(()=>{
  //   setOrg(vars.auth.organisationId)
  // }, [vars.auth.organisationId])
  // const [org, setOrg] = useState<string|null>(null)
  // const orgOptions = data.org.organisationList === null ? [] : data.org.organisationList.map((org)=>{return {value: org.id, name: org.name}})
  // const orgPickerMaybe = isRoot ? makeDropdownInput("Organisation:", "Which org to apply the tag for", "Organisation", org, orgOptions, setOrg, orgWarning) : <></>
  const orgPickerMaybe = isRoot ? makeTextOutput("Organisation:", "Which org to apply the tag for", "Choose one in the menubar", orgWarning) : <></>

  // ----- Vessel -----
  useEffect(()=>{
    if(data.org.vesselList.length > 0) {
      setVessel(data.org.vesselList[0].id)
    } else {
      setVessel(null)
    }
  }, [data.org.vesselList])
  const [vessel, setVessel] = useState<string|null>(null)
  const vesselOptions = data.org.vesselList.map((ves)=>{
    return {
      value: ves.id,
      name: ves.name,
    }
  })
  const vesselPicker = makeDropdownInput("Vessel:", "Which vessel to apply the tag with respect to", "Vessel", vessel, vesselOptions, setVessel, vesselWarning)

  // ----- User -----
  // useEffect(()=>{
  //   if(data.org.userList.length > 0) {
  //     setUser(data.org.userList[0].username)
  //   }
  // }, [data.org.userList])
  const [user, setUser] = useState<string>("noUser")
  const userOptionsPeople: {value: string, name: string}[] = (data.org.userList === null) ? [] : data.org.userList.map((user)=>{
    const name = (user.name !== null) ? user.name : user.username
    return {value: user.username, name: name}
  })
  const userOptions: {value: string, name: string}[] = [{value: "noUser", name: "No user"}].concat(userOptionsPeople)
  const userPickerMaybe = isRoot ? makeDropdownInput("User:", "Which user to apply the tag for", "User", user, userOptions, setUser, userWarning) : <></>

  // ----- Time -----
  const startingDateTime = new Date()
  startingDateTime.setSeconds(0)
  startingDateTime.setMilliseconds(0)
  const [dateTime, setDateTime] = useState<Date|null>(startingDateTime)
  const timeComponent = 
    <div className="flex flex-col">
      {GeneralDateTimePicker(dateTime, setDateTime)}
      {UtcNotice()}
    </div>
  const timePicker = makeOtherInput("Date & time:", "The point in time to attach the tag to", timeComponent, dateTimeWarning)

  // ----- Tag type -----
  useEffect(()=>{
    if(data.tagTypes.tagTypeList.val !== null && data.tagTypes.tagTypeList.val?.length > 0) {
      setTagTypeId(data.tagTypes.tagTypeList.val[0].id)
    } else {
      setTagTypeId(null)
    }
  }, [data.tagTypes.tagTypeList.val])
  const [tagTypeId, setTagTypeId] = useState<string|null>(null)
  const activeTagTypes = (data.tagTypes.tagTypeList.val === null) ? [] : data.tagTypes.tagTypeList.val.filter((tagType)=>tagType.state === EntityState.Active)
  const tagTypeOptions = activeTagTypes.map((tagType)=>{
    return {
      value: tagType.id,
      name: tagType.name+" ("+tagScopePretty[tagType.scope]+")",
    }
  })
  const tagTypePicker = makeDropdownInput(
    "Tag type:", 
    "Which tag type to apply (along with its scope), created on the administration page", 
    "Tag type", 
    tagTypeId, tagTypeOptions, setTagTypeId, tagTypeWarning
  )

  // ----- Value -----
  const tagType = data.tagTypes.tagTypeList.val?.find((entry)=>entry.id === tagTypeId)
  const tagValueType = tagType?.valueType
  const unit = (tagType === undefined || tagType.unit === null) ? "" : tagType.unit
  // console.log(unit, tagType)
  const booleanValueOptions: {name: string, value: TagValueBoolean}[] = Object.values(TagValueBoolean).map(option => {return {name: option, value: option}})
  const [booleanValue, setBooleanValue] = useState(TagValueBoolean.True)
  const valueMaybe = 
    (tagValueType === TagValueType.Number) ?
      <div className="flex space-x-2 items-center">
        {makeTextInput("Value ("+tagValueTypePretty[tagValueType].toLowerCase()+"):", "The "+tagValueTypePretty[tagValueType].toLowerCase()+" value to attach to the applied tag", "Value", "tagEventValue", valueWarning, undefined, "Small")}
        {makeTextOutput(null, null, unit, null)}
      </div>
    : (tagValueType === TagValueType.String) ?
      makeTextInput("Value ("+tagValueTypePretty[tagValueType].toLowerCase()+"):", "The "+tagValueTypePretty[tagValueType].toLowerCase()+" value to attach to the applied tag", "Value", "tagEventValue", valueWarning, undefined, "Medium")
    : (tagValueType === TagValueType.Boolean) ?
      makeRadioInput("Value ("+tagValueTypePretty[tagValueType].toLowerCase()+"):", "The "+tagValueTypePretty[tagValueType].toLowerCase()+" value to attach to the applied tag", booleanValue, booleanValueOptions, setBooleanValue)
    : <></>

  // ----- The confirmation modal -----
  const vesselMaybe = data.org.vesselList.find((ves)=>ves.id === vessel)
  const vesselStr = (vesselMaybe === undefined) ? "N/A" : vesselMaybe.name
  const dateTimeStr = (dateTime === null) ? "N/A" : epochToPrettyStringUtc(dateTime.valueOf())
  const tagTypeMaybe = data.tagTypes.tagTypeList.val?.find((type)=>type.id === tagTypeId)
  const tagTypeScopeStr = (tagTypeMaybe === undefined) ? "N/A" : tagTypeMaybe.name+" ("+tagScopePretty[tagTypeMaybe.scope]+")"
  const unitMaybe = tagTypeMaybe?.unit
  const unitStr = (unitMaybe === null || unitMaybe === undefined) ? "" : unitMaybe
  const valueFieldStrMaybe = getTextFieldValue("tagEventValue")
  const valueFieldStr = (valueFieldStrMaybe === null) ? "N/A" : valueFieldStrMaybe
  const valueBooleanStr: string = booleanValue
  const valueStr = 
    (tagTypeMaybe?.valueType === TagValueType.Boolean) ? valueBooleanStr
    : (tagTypeMaybe?.valueType === TagValueType.String) ? '"'+valueFieldStr+'"'
    : valueFieldStr
  const orgMaybe = data.org.organisationList?.find((org)=>org.id === vars.auth.organisationId)
  const orgStr = (orgMaybe === undefined) ? "N/A" : orgMaybe.name
  const userMaybe = data.org.userList?.find((entry)=>entry.username === user)
  const userStr = (userMaybe === undefined) ? "N/A" : userMaybe.name
  const confirmTextAlways = [
    'You are about to apply the following tag:',
    '- Vessel: '+vesselStr,
    '- Date & time: '+dateTimeStr,
    '- Tag type: '+tagTypeScopeStr,
  ]
  const confirmTextValue = [
    '- Value: '+valueStr+' '+unitStr,
  ]
  const confirmTextRoot = [
    '- Organisation: '+orgStr+' ('+vars.auth.organisationId+')',
    '- User: '+userStr+' ('+user+')',
  ]
  const confirmTextStandard = (tagTypeMaybe !== undefined && tagTypeMaybe.valueType !== TagValueType.None) ?
    confirmTextAlways.concat(confirmTextValue)
    : confirmTextAlways
  const confirmText = (accessLevelAtMinimum(vars.auth.accessLevel[0], AccessLevel.Root)) ?
  confirmTextStandard.concat(confirmTextRoot)
    : confirmTextStandard
  const confirmModal = makeConfirmModal(
    "Confirm applying tag",
    confirmText,
    "Confirm: Apply tag",
    onConfirmClick,
    confirmModalOpen,
    setConfirmModalOpen
  )

  // ---------------
  const content = 
    <div className="flex flex-col space-y-4">
      {orgPickerMaybe}
      {vesselPicker}
      {userPickerMaybe}
      {timePicker}
      {tagTypePicker}
      {valueMaybe}
      <div className="pt-2">{makeSubmitButton(onSubmitClick)}</div>
      {confirmModal}
    </div>

  return makeModal("Apply tag", content, open, onClose)
}
