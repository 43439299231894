import { useAppContext } from "../../../../context/variables"
import { makeConfirmModal } from "../../../../components/Modal"
import useManageData from "../../../../apiComms/manageData"

export default function RevokeOperatorStatusModal(open: boolean, setOpen: (open: boolean)=>void, managedUserId: string|null, onCloseManageUserModal: ()=>void) {
  const { apiUserRevokeOperatorStatus } = useManageData()
  const { data } = useAppContext()

  const title = "Confirm revoking operator status"
  const theUserId = (managedUserId !== null) ? managedUserId : "N/A"
  const userName = data.org.userList?.find((user)=>user.username === managedUserId)?.name
  const text = [
    'You are about to revoke the operator status of "'+userName+'" ('+theUserId+').',
    "They will no longer be able to log in onboard vessels.",
  ]
  const buttonText = "Revoke operator status"

  function onConfirmClick() {
    apiUserRevokeOperatorStatus(managedUserId)
    setOpen(false)
    onCloseManageUserModal()
  }

  return makeConfirmModal(title, text, buttonText, onConfirmClick, open, setOpen)
}
