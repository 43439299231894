import { Maybe } from "../../../types";
import LoadingSpinner from "../loading-indicators/loading-spinner/LoadingSpinner";
import {
  PanelContainer,
  PanelTitleSection,
  PanelTitleIconWrapper,
  PanelContent,
  PanelErrorMessage,
  PanelEmptyMessage,
} from "./Panel.styles";

export type PanelStatusProps = {
  isLoading?: boolean;
  error?: Maybe<Error>;
  isEmpty?: boolean;
};

type PanelProps = PanelStatusProps & {
  title: string;
  content: React.ReactNode;
  isStatistical?: boolean;
  icon?: React.ReactNode;
};

const Panel: React.FC<PanelProps> = ({
  title,
  content,
  isStatistical,
  icon,
  isLoading,
  error,
  isEmpty,
}) => {
  const renderContent = () => {
    if (error) {
      return <PanelErrorMessage>{error.message}</PanelErrorMessage>;
    } else if (isLoading) {
      return <LoadingSpinner />;
    } else if (isEmpty) {
      return <PanelEmptyMessage>No data available</PanelEmptyMessage>;
    } else {
      return content;
    }
  };

  return (
    <PanelContainer isStatistical={isStatistical}>
      <PanelTitleSection>
        {icon && <PanelTitleIconWrapper>{icon}</PanelTitleIconWrapper>}
        {title}
      </PanelTitleSection>
      <PanelContent>{renderContent()}</PanelContent>
    </PanelContainer>
  );
};
export default Panel;
