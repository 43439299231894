import React from "react";
import { Panel, PanelStatusProps } from "../../../atoms";
import {
  PanelUserContainer,
  PanelUserUsernameSection,
  PanelUserOrganisationSection,
  PanelUserOrganisationTitle,
} from "./PanelUser.styles";
import { useAppContext } from "../../../../context/variables";

type PanelUserProps = PanelStatusProps & {
  title: string;
};

const PanelUser: React.FC<PanelUserProps> = ({ title, isLoading, error }) => {
  const { data, vars } = useAppContext();

  const username = vars.auth.username;
  const email = vars.auth.email;
  const usernameToDisplay =
    username === null
      ? "N/A"
      : email === null
      ? username
      : email + " (" + username + ")";
  const organisationNameToDisplay =
    data.org.organisationName === null ? "N/A" : data.org.organisationName;
  const organisationIdToDisplay =
    vars.auth.organisationId === null || vars.auth.organisationId === undefined
      ? "N/A"
      : vars.auth.organisationId;
  const userRankToDisplay = vars.auth.userGroups?.includes("Admins")
    ? " (Cetasol Admin)"
    : "";

  const Profile = () => {
    return (
      <PanelUserContainer>
        <PanelUserUsernameSection>
          <p>{usernameToDisplay}</p>
          <p>{userRankToDisplay}</p>
        </PanelUserUsernameSection>
        <PanelUserOrganisationSection>
          <PanelUserOrganisationTitle>
            {organisationNameToDisplay}
          </PanelUserOrganisationTitle>

          <p>Organisation ID: {organisationIdToDisplay}</p>
        </PanelUserOrganisationSection>
      </PanelUserContainer>
    );
  };

  return (
    <Panel
      title={title}
      isLoading={isLoading}
      error={error}
      content={<Profile />}
    />
  );
};
export default PanelUser;
