import { Tooltip } from "@mui/material";

export function UtcNotice() {
  return <div className="text-base font-normal italic">
    <Tooltip title="All dates and times are presented in Coordinated Universal Time (UTC)" placement="top">
      <div>
        {"(All dates & times in UTC)"}
      </div>
    </Tooltip>
  </div>
}

export function performanceGraphCell(title: string, graph: JSX.Element) {
  return <div className="w-fit rounded-xl shadow-tray bg-white px-8 py-4">
    <div className="font-bold text-xl text-deep_blue">
      {title}
    </div>
    {graph}
  </div>
}
