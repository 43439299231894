import React, { useEffect, useState } from "react";
import AlarmTable from "../../components/organisms/AlarmTable";
import Pagination from "../../components/atoms/Pagination";
import PageContainer from "../../components/organisms/PageContainer";
import LoadingSpinner from "../../components/atoms/LoadingSpinner";
import useFetchData from "../../apiComms/fetchData";
import { useAppContext } from "../../context/variables";
import EmptyState from "../../components/atoms/EmptyState";
import AlarmIcon from "../../assets/icons/EmptyStates/AlarmIcon"; 

const HistoryTab: React.FC = () => {
  const { vars, data } = useAppContext();
  const { fetchDataAlarmHistory, fetchDataOrganisations } = useFetchData();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const rowsPerPage = 10;

  useEffect(() => {
    const fetchInitialData = async () => {
      await fetchDataOrganisations();
      await fetchDataAlarmHistory();
      setLoading(false);
      setDataLoaded(true);
    };

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.idToken, vars.auth.organisationId]);

  const alarmData = (data.alarmEvents.alarmEventList.val || []).map(
    (event) => ({
      id: event.id,
      vesselName: event.vesselName,
      alarmName: event.alarmName,
      date: event.date,
      alarmLevel: event.alarmLevel,
    })
  );

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentData = alarmData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(alarmData.length / rowsPerPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const content = (
    <>
      {loading ? (
        <div className="flex items-center justify-center w-full h-full">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {dataLoaded && alarmData.length === 0 ? (
            <EmptyState
              icon={<AlarmIcon />}
              title="No alarms available"
              description="Come back later or configure new alarms"
            />
          ) : (
            <div className="flex flex-col items-center justify-center w-full h-full">
              <div className="w-full mt-4">
                <AlarmTable data={currentData} showActions={false} />
              </div>
              {dataLoaded && alarmData.length > 0 && (
                <div className="mt-4 w-full flex justify-center">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );

  return <PageContainer>{content}</PageContainer>;
};

export default HistoryTab;
