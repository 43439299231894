import { useEffect, useState } from "react"
import { useAppContext } from "../../../../context/variables"
import { StandardModalWithConfirmation } from "../../../../components/Modal"
import useManageData from "../../../../apiComms/manageData"
import { makeRadioInput } from "../../../../components/FormInputs"
import { AccessLevel } from "../../../../context/types"
import { makeAccessLevelOption } from "./ManageUserModal"

export default function EditAccessLevelModal(open: boolean, setOpen: (open: boolean)=>void, managedUserId: string|null, onCloseManageUserModal: ()=>void) {
  const { apiUserEditAccessLevel } = useManageData()
  const { data, vars } = useAppContext()

  const userOldAccessLevel = data.org.userList?.find((user)=>user.username === managedUserId)?.access_level
  useEffect(()=>{
    if(userOldAccessLevel !== undefined) {
      setChosenAccessLevel(userOldAccessLevel)
    }
  }, [userOldAccessLevel])

  const subjectUserAccessLevel = vars.auth.accessLevel[0]
  const subjectIsRoot = subjectUserAccessLevel === AccessLevel.Root

  const accessLevels = 
    subjectIsRoot ? Object.values(AccessLevel)
    : [AccessLevel.User, AccessLevel.Admin]
  const accessLevelOptions: {value: AccessLevel, name: string}[] = accessLevels.map(makeAccessLevelOption)
  const [chosenAccessLevel, setChosenAccessLevel] = useState(AccessLevel.User)

  const title = "Edit access level"
  const confirmTitle = "Confirm editing access level"
  const theUserId = (managedUserId !== null) ? managedUserId : "N/A"
  const userOldName = data.org.userList?.find((user)=>user.username === managedUserId)?.name
  const confirmText = [
    'You are about to edit the access level of "'+userOldName+'" ('+theUserId+')',
    'from '+userOldAccessLevel+' to '+chosenAccessLevel
  ]
  const confirmButtonText = "Edit access level"

  const inputComponents = [
    makeRadioInput("Access level:", "How much of the data and tools the user will have access to", chosenAccessLevel, accessLevelOptions, setChosenAccessLevel)
  ]
  function onConfirm() {
    apiUserEditAccessLevel(managedUserId, chosenAccessLevel)
    onCloseManageUserModal()
  }

  return StandardModalWithConfirmation(open, setOpen, title, confirmTitle, confirmText, confirmButtonText, inputComponents, onConfirm)
}
