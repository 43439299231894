import React from "react";
import { truncateString } from "../../helpers/stringHelpers";

type Props = {
  text: string;
  maxLength?: number;
};

const TextTooltip: React.FC<Props> = ({ text, maxLength = 15 }) => {
  return (
    <div className="relative group">
      {truncateString(text, maxLength)}
      <div className="fixed hidden px-3 py-2 text-white bg-gray-800 rounded group-hover:inline-block transform -translate-y-full">
        {text}
      </div>
    </div>
  );
};

export default TextTooltip;
