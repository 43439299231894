import { NavigateFunction } from "react-router-dom";
import { variables } from "../context/variables";
import Keycloak from "keycloak-js"
import { loginWithAuthData } from "./authentication";

export function keycloakInit(client: Keycloak, vars: variables, navigate: NavigateFunction) {
  const didInitializeEntry = Object.entries(client).find(x => x[0] === "didInitialize")
  const keycloakHasInitialized = didInitializeEntry?.[1] === true
  if(!keycloakHasInitialized) {
    // console.log("initializing keycloak")
    client.init({checkLoginIframe: false})
    .then(function() {
      if(client.authenticated === true && client.tokenParsed !== undefined) {
        // Logging in the user after redirect via keycloak login page
        const username = client.tokenParsed["preferred_username"]
        const email = client.tokenParsed.email
        const isOperatorStr = client.tokenParsed["ihelm-operator"]
        const isAdminStr = client.tokenParsed["ihelm-admin"]
        const isOwnerStr = client.tokenParsed["ihelm-owner"]
        const groups = (client.tokenParsed.groups === undefined) ? null : client.tokenParsed.groups
        const exp = client.tokenParsed.exp
        if(client.idToken !== undefined && client.token !== undefined && client.refreshToken !== undefined && exp !== undefined) {
          loginWithAuthData(vars, navigate, client.idToken, client.token, client.refreshToken, username, email, isOperatorStr, isAdminStr, isOwnerStr, groups, exp)
        }
      }
    })
  }
}

export function keycloakLogin(client: Keycloak) {
  client.login()
}

export function keycloakLogout(client: Keycloak) {
  const didInitializeEntry = Object.entries(client).find(x => x[0] === "didInitialize")
  const keycloakHasInitialized = didInitializeEntry?.[1] === true
  if(!keycloakHasInitialized) {
    // console.log("initializing keycloak")
    client.init({checkLoginIframe: false})
  }
  client.logout()
}
