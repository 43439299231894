import React, { useEffect, useState, useRef } from "react";
import {
  DropdownContainer,
  DropdownButton,
  DropdownList,
  DropdownItem,
} from "../styles/DropdownStyles";
import { useAppContext } from "../../context/variables";
import useFetchData from "../../apiComms/fetchData";

interface SignalsDropdownProps {
  value: string;
  onChange: (value: string) => void;
  width?: string;
}

const SignalsDropdown: React.FC<SignalsDropdownProps> = ({
  value,
  onChange,
  width,
}) => {
  const [signalOptions, setSignalOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const { data, vars } = useAppContext();
  const { fetchDataSignalTypes } = useFetchData();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchDataSignalTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.idToken, vars.auth.organisationId]);

  useEffect(() => {
    if (data.signalTypes?.signalTypeList?.val) {
      const options = data.signalTypes.signalTypeList.val.map(
        (signal: any) => ({ value: signal.value, label: signal.label })
      );
      setSignalOptions(options);
    }
  }, [data.signalTypes?.signalTypeList?.val]);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleOptionClick = (optionValue: string) => {
    onChange(optionValue);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const selectedLabel =
    value === ""
      ? "Signal"
      : signalOptions.find((option) => option.value === value)?.label ||
        "Signal";

  return (
    <DropdownContainer ref={dropdownRef} width={width}>
      <DropdownButton
        isPlaceholder={value === "Signal"}
        onClick={handleToggle}
        className={value === "" ? "text-gray-400" : ""}
      >
        {selectedLabel}
      </DropdownButton>
      {isOpen && (
        <DropdownList>
          {signalOptions.map((option) => (
            <DropdownItem
              key={option.value}
              selected={option.value === value}
              onClick={() => handleOptionClick(option.value)}
            >
              {option.label}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default SignalsDropdown;

