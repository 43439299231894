import { useAppContext } from "../../../../context/variables"
import { MenuModalStandard, menuModalStandardOption } from "../../../../components/Modal"
import { HiOutlineTrash } from "react-icons/hi"
import { TbCursorText } from "react-icons/tb"
import { BsThreeDots } from "react-icons/bs"
import { FaRedoAlt } from "react-icons/fa"
import { TbShip, TbShipOff } from "react-icons/tb"
import { PiIdentificationCard } from "react-icons/pi"
import { AccessLevel, accessLevelAbove, accessLevelAtMinimum } from "../../../../context/types"
import { GiNuclearBomb } from "react-icons/gi"
import RemoveUserModal from "./RemoveUserModal"
import DeleteUserModal from "./DeleteUserModal"
import EditAccessLevelModal from "./EditAccessLevelModal"
import GrantOperatorStatusModal from "./GrantOperatorStatusModal"
import RevokeOperatorStatusModal from "./RevokeOperatorStatusModal"
import EditPinModal from "./EditPinModal"
import RenameModal from "./RenameModal"
import ResendConfirmationModal from "./ResendConfirmationModal"

export function makeAccessLevelOption(level: AccessLevel) {
  return {value: level, name: level}
}

export default function ManageUserModal(open: boolean, setOpen: (open: boolean)=>void, managedUserId: string|null, setManagedUserId: (id: string|null)=>void) {
  const { data, vars } = useAppContext()

  const theUserId = (managedUserId !== null) ? managedUserId : "N/A"
  const userEntry = data.org.userList?.find((user)=>user.username === managedUserId)
  const userName = (userEntry !== undefined) ? userEntry.name : null
  const title = (managedUserId !== null) ? 'Manage user "'+userName+'" ('+theUserId+')' : "Manage user"

  const subjectUserAccessLevel = vars.auth.accessLevel[0]
  const subjectIsRoot = subjectUserAccessLevel === AccessLevel.Root
  const subjectIsOwner = subjectUserAccessLevel === AccessLevel.Owner
  const objectUserAccessLevel = (userEntry !== undefined) ? userEntry.access_level : null
  const objectIsBelowSubject = accessLevelAbove(subjectUserAccessLevel, objectUserAccessLevel)
  const objectIsSubject = (managedUserId !== null) && (managedUserId === vars.auth.username?.toUpperCase())
  const subjectIsAdminOrAbove = accessLevelAtMinimum(subjectUserAccessLevel, AccessLevel.Admin)
  const objectIsOperator = userEntry ? userEntry.is_operator : false
  const underOrSelfGEqAdmin = objectIsBelowSubject || (objectIsSubject && subjectIsAdminOrAbove)
  const objectEmail = (userEntry !== undefined) ? userEntry.email : null
  const objectHasEmail = objectEmail !== null && objectEmail.length > 0
  const objectUserStatus = (userEntry !== undefined) ? userEntry.user_status : null
  const objectIsForceChangePw = objectUserStatus !== null && objectUserStatus === "FORCE_CHANGE_PASSWORD"

  const options: menuModalStandardOption[] = [
    {id: "Rename", buttonIcon: <TbCursorText/>, buttonText: "Rename", showButton: subjectIsRoot || underOrSelfGEqAdmin, modalComponent: RenameModal},
    {id: "EditAccessLevel", buttonIcon: <PiIdentificationCard/>, buttonText: "Edit access level", showButton: subjectIsRoot || (subjectIsOwner && objectIsBelowSubject), modalComponent: EditAccessLevelModal},
    {id: "EditPin", buttonIcon: <BsThreeDots/>, buttonText: "Edit PIN", showButton: subjectIsRoot || (underOrSelfGEqAdmin && objectIsOperator), modalComponent: EditPinModal},
    {id: "GrantOperatorStatus", buttonIcon: <TbShip/>, buttonText: "Grant operator status", showButton: subjectIsRoot || (underOrSelfGEqAdmin && !objectIsOperator), modalComponent: GrantOperatorStatusModal},
    {id: "RevokeOperatorStatus", buttonIcon: <TbShipOff/>, buttonText: "Revoke operator status", showButton: subjectIsRoot || (underOrSelfGEqAdmin && objectIsOperator), modalComponent: RevokeOperatorStatusModal},
    {id: "ResendConfirmation", buttonIcon: <FaRedoAlt/>, buttonText: "Resend invitation", showButton: (subjectIsRoot || objectIsBelowSubject) && objectHasEmail && objectIsForceChangePw, modalComponent: ResendConfirmationModal},
    {id: "RemoveUser", buttonIcon: <HiOutlineTrash/>, buttonText: "Remove", showButton: subjectIsRoot || objectIsBelowSubject, modalComponent: RemoveUserModal, orangeBg: true},
    {id: "DeleteUser", buttonIcon: <GiNuclearBomb/>, buttonText: "PERMA-Delete (root only)", showButton: subjectIsRoot, modalComponent: DeleteUserModal, orangeBg: true},
  ]

  return MenuModalStandard(open, setOpen, managedUserId, setManagedUserId, title, options)
}
