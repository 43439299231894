import { AuthenticationDetails, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { NavigateFunction } from "react-router-dom";
import { variables } from "../context/variables";
import { loginWithAuthData } from "./authentication";

// ---------------------------------
// This is for local development: (cookies, add later maybe)
// const DOMAIN = "localhost"
// This is for the test amplify:
// const DOMAIN = "master.dt95s3zvahsgd.amplifyapp.com" ???
// This is for the production amplify:
// const DOMAIN = ".cetasol.com"
// ---------------------------------

// FUNCTIONS:
//
// login (username, pw) uses username and pw to send to cognito, if good stores tokens
// logout ()            
// timeoutCheck ()     accesses relevant token and sees whether it timed out?/looks up if cognito approves?
//
// later:
// refresh ()           uses refreshToken to get 
// storing tokens in cookies?
// forgotten pw
// registering users
// how to revoke tokens?
// How long should each token last for?
//
// maybe never:
// MFA

// function cognitoSignUp() {
//   // Have a different page for signup, "app.cetasol.com/signup"?
//   // They should get there from the invite email
//   // It should do basically what the old signup page does
//   // after using it correctly, the user is taken to the login page? or are logged in?
//   //
//   // what the old one does:
//   // you click the link
//   // Screen with username and password fields, forgot pw link and sign in button
//   // Screen with two new pw fields, a list of requirements on the pw and tick marks, and a send button
//   // you are sent to app.cetasol.com
//   //
//   // It should look nice, no menubar, same colour scheme, boat pic?
//   // Do it on just one screen?
//   // Design it in figma
//   //
//   // var poolData = {
//   //   UserPoolId: '...', // Your user pool id here
//   //   ClientId: '...', // Your client id here
//   // };
//   // var userPool = new CognitoUserPool(poolData);
  
//   userPool.signUp(username, password, attributeList, validationData, callback)
// }

// function cognitoGetTokens(vars: variables) {
//   const curUser = userPool.getCurrentUser()
//   console.log("outside")
//   if (curUser === null) {
//     console.log("case 1")
//   }
//   curUser?.getSession((err: any, session: CognitoUserSession) => {
//     if (err) {
//       console.log("case 2")
//       throw err;
//     }
//     const accessToken = session.getAccessToken()
//     const idToken = session.getIdToken()
//     const refreshToken = session.getRefreshToken()
//     console.log("Inside getTokens")
//     vars.cognito.setAccessTokenJwt(accessToken)
//     vars.cognito.setIdToken(idToken)
//     vars.cognito.setRefreshTokenStr(refreshToken)
//   })
// }

// function cognitoRefresh(vars: variables, navigate: NavigateFunction) {
//   // Use refreshtoken to refresh all three?
//   // overwrite in context?
//   // When to call this?

//   // refreshing draft:
//   authenticationExpirationCheck(vars, navigate)
//   const refreshToken = vars.cognito.refreshTokenStr
//   const curUser = userPool.getCurrentUser()
//   if (refreshToken === null || curUser === null) {
//     cognitoLogout(vars, navigate)
//   } else {
//     curUser.refreshSession(refreshToken, (err, session) => {
//       if (err) throw err;
//       const newAccessToken = session.getAccessToken()
//       const newIdToken = session.getIdToken()
//       const newRefreshToken = session.getRefreshToken()
//       vars.cognito.setAccessTokenJwt(newAccessToken)
//       vars.cognito.setIdToken(newIdToken)
//       vars.cognito.setRefreshTokenStr(newRefreshToken)
//     })
//   }
// }

// function getIdToken() {
// }

export function cognitoLogin(vars: variables, navigate: NavigateFunction, theUsername: string, thePassword: string, userPool: CognitoUserPool) {
  vars.login.setErrorCode(null)
  vars.login.setErrorMessage(null)

  var cognitoUser = new CognitoUser({
    Username: theUsername,
    Pool: userPool,
    // Storage: new CookieStorage({domain: DOMAIN, secure: false})
  })
  const authenticationDetails = new AuthenticationDetails({
    Username: theUsername,
    Password: thePassword
  })

  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: function(result) {
      // console.log("cognito login success")
      const idTokenObject = result.getIdToken()
      const idToken = idTokenObject.getJwtToken()
      const accessToken = result.getAccessToken().getJwtToken()
      const refreshToken = result.getRefreshToken().getToken()

      const username = idTokenObject.payload["cognito:username"].toUpperCase()
      const email = idTokenObject.payload["email"]
      const isOperatorStr = idTokenObject.payload["custom:ihelm-operator"]
      const isAdminStr = idTokenObject.payload["custom:ihelm-admin"]
      const isOwnerStr = idTokenObject.payload["custom:ihelm-owner"]
      const userGroups = idTokenObject.payload["cognito:groups"]
      const exp = idTokenObject.payload["exp"]

      loginWithAuthData(vars, navigate, idToken, accessToken, refreshToken, username, email, isOperatorStr, isAdminStr, isOwnerStr, userGroups, exp)
    },
    onFailure: function(err) {
      // console.log("cognito login failure")
      vars.login.setErrorCode(err.code)
      vars.login.setErrorMessage(err.message)
      return(err)
    },
  })

  // No username:
  // InvalidParameterException
  // Missing required parameter USERNAME
  // Please enter an email address (red around email address?)

  // username that doesn't exist:
  // UserNotFoundException
  // User does not exist.
  // Email address not registered (red around email address?) OR Incorrect username or password. (Red around both?)

  // username exists, no pw or incorrect pw:
  // NotAuthorizedException
  // Incorrect username or password.
  // Incorrect password (red around password?) OR Incorrect username or password. (Red around both?)

  // Weird one:
  // NotAuthorizedException
  // Password attempts exceeded
  // Password attempts exceeded (red around password?)

  // maybe showing these errors on screen?:
  // username is not in cognito pool: User does not exist.
  // password is not the one registered for the username: Incorrect username or password.
  // 
}

export function cognitoLogout(userPool: CognitoUserPool) {
  userPool.getCurrentUser()?.signOut() // does this work now?
}
