import React, { useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";

import { makeConfirmModal, makeMenuModal } from "../../../components/Modal";
import { useAppContext } from "../../../context/variables";
import useManageData from "../../../apiComms/manageData";
import {
  AccessLevel,
  accessLevelAtMinimum,
} from "../../../context/types";
import { strNullToString } from "../../../helpers/stringHelpers";
import { EditOpModeModal } from "./EditOpModeModal";

function DeleteOpModeModal(
  open: boolean,
  setOpen: (open: boolean) => void,
  managedId: string | null,
  onCloseManageModal: () => void
) {
  const { data } = useAppContext();
  const { apiDeleteOpModes } = useManageData();
  const opModeList = data.opModes.opModeList.val;
  const opModeMaybe = opModeList?.find(
    (opMode) => opMode.opModeId === managedId
  );
  const opModeName = opModeMaybe ? opModeMaybe.name : "N/A";

  function onConfirmClick() {
    apiDeleteOpModes(opModeMaybe?.orgId, managedId);
    setOpen(false);
    onCloseManageModal();
  }

  return makeConfirmModal(
    "Confirm deleting operation mode",
    [
      "You are about to PERMANENTLY delete",
      '"' + opModeName + '" (' + strNullToString(managedId) + ")",
      "from the database",
    ],
    "Confirm: Delete operation mode",
    onConfirmClick,
    open,
    setOpen,
    true
  );
}

export function ManageOpModeModal(
  open: boolean,
  setOpen: (open: boolean) => void,
  managedId: string | null,
  setManagedId: (id: string | null) => void
) {
  const { data, vars } = useAppContext();
  const { accessLevel } = vars.auth;
  const opModeList = data.opModes.opModeList.val;
  const opModeMaybe = opModeList?.find(
    (opMode) => opMode.opModeId === managedId
  );
  const opModeName = opModeMaybe ? opModeMaybe.name : "N/A";
  const isRoot = accessLevelAtMinimum(accessLevel[0], AccessLevel.Root);
  const isAdmin = accessLevelAtMinimum(
    accessLevel[0],
    AccessLevel.Admin
  );

  const [editStateModalOpen, setEditStateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const title = managedId
    ? `Manage Operational Mode "${opModeName}"`
    : "Manage Operational Mode";

  const adminOptions = [
    {
      buttonIcon: <AiFillEdit />,
      buttonText: "Edit Operation Mode",
      onClick: () => {
        setEditStateModalOpen(true);
      },
    },
  ];

  const rootOptions = [
    {
      buttonIcon: <BsFillTrashFill />,
      buttonText: "Delete",
      onClick: () => {
        setDeleteModalOpen(true);
      },
      orangeBg: true,
    },
  ];

  let options: any[] = [];
  if (isAdmin) options = options.concat(adminOptions);
  if (isRoot) options = options.concat(rootOptions);

  function onClose() {
    setManagedId(null);
    setOpen(false);
  }

  const modals = [
    EditOpModeModal({
      open: editStateModalOpen,
      setOpen: setEditStateModalOpen,
      managedId: managedId,
      onCloseManageModal: onClose,
    }),

    DeleteOpModeModal(deleteModalOpen, setDeleteModalOpen, managedId, onClose),
  ];

  return makeMenuModal(title, options, modals, open, onClose);
}
