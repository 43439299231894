import React from 'react';

interface AlarmIconLowProps {
    size?: number;
    color?: string;
}

const AlarmIconLow: React.FC<AlarmIconLowProps> = ({ size = 27, color = '#FAD900' }) => (
    <svg width={size} height={size} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.5 2.25C3.25736 2.25 2.25 3.25736 2.25 4.5V22.5C2.25 23.7426 3.25736 24.75 4.5 24.75H22.5C23.7426 24.75 24.75 23.7426 24.75 22.5V4.5C24.75 3.25736 23.7426 2.25 22.5 2.25H4.5ZM14.625 14.625H12.375V7.875H14.625V14.625ZM14.625 19.125H12.375V16.875H14.625V19.125Z" fill={color}/>
        <path d="M12.375 14.625H14.625V7.875H12.375V14.625Z" fill="black" fillOpacity="0.9"/>
        <path d="M12.375 19.125H14.625V16.875H12.375V19.125Z" fill="black" fillOpacity="0.9"/>
    </svg>
);

export default AlarmIconLow;
