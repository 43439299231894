import { styled } from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div<{ theme: "light" | "dark" }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: between;
  ${({ theme }) => (theme === "light" ? "#e0e0e0" : "#143452")};
  background: ${({ theme }) => (theme === "light" ? "#fff" : "#143452")};
  color: ${({ theme }) => (theme === "light" ? "#000" : "#fff")};
  padding: 1.25rem;
  border-radius: 0.625rem;
  box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.1);
  font-family: "Montserrat", sans-serif;
`;

export const CloseButton = styled.button`
  align-self: flex-end;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  margin-bottom: 0.625rem;
`;
