import styled from "styled-components";

export const PanelContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isStatistical"].includes(prop),
})<{ isStatistical?: boolean }>`
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  overflow: auto;
  max-height: 15rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  @media (min-width: 1280px) {
    max-width: ${({ isStatistical }) => (isStatistical ? "15rem" : "100%")};
  }
`;

export const PanelTitleSection = styled.div`
  display: flex;
  justify-content: justify-between;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  background-color: white;
`;

export const PanelTitleIconWrapper = styled.div`
  margin-right: 0.5rem;
  position: relative;
`;

export const PanelContent = styled.div`
  font-size: 1rem;
  flex-grow: 1;
`;

export const PanelErrorMessage = styled.p`
  color: #ff2a2a;
  font-size: 0.8rem;
  font-weight: 500;
`;

export const PanelEmptyMessage = styled.p`
  color: #595959;
  font-size: 0.8rem;
  font-weight: 400;
`;
