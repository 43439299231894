import { useEffect, useState } from "react";
import { apiData, realTimePoint, viewSettings } from "../../context/variables"
import { Loader } from "@googlemaps/js-api-loader"
import { coords, coordsGothenburgKindaCentral, makeLatLng } from "../../components/Map";
// import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"

//----------------------
// Initializing map vars
//----------------------

let map: google.maps.Map
let mapInitialized = false

let vesselIconLatitude: number|null
let vesselIconLongitude: number|null
let vesselIconHeadingTrue: number|null

let boatPolyline: google.maps.Polyline
let beforePolyline: google.maps.Polyline
let afterPolyline: google.maps.Polyline
const boatIcon: google.maps.Symbol = {
  path: "M0 0 L1 1 L0 -2 L-1 1 L0 0Z",
  fillOpacity: 1,
  fillColor: "white",
  strokeWeight: 0,
  rotation: 0,
  scale: 1,
}
let boatIconNoHeading: google.maps.Symbol

const loader = new Loader({
  apiKey: "AIzaSyAFrWFda9joNnyn20PXgFWACROHq8Hwhxk",
  version: "weekly",
  libraries: ["geometry"],
  // ...additionalOptions,
});

//----------------------
// Map helpers
//----------------------

function updateBoatMarker(pos: coords | null, angle: number | null, visibility: boolean) {
  // boatMarker.setVisible(visibility)
  boatPolyline.setVisible(visibility)
  
  if(pos === null) { // we don't know the position
    // console.log("Case 1: We don't know the position")
  } else if(angle === null) { // we know position, but not angle
    // console.log("Case 2: We know position, but not angle")
    boatIcon.rotation = angle
    boatIcon.anchor = new google.maps.Point(0, -0.5)
    boatIcon.scale = 11
    boatPolyline.setPath([
      { lat: pos.lat - 1, lng: pos.lng },
      { lat: pos.lat, lng: pos.lng },
    ])
    boatPolyline.setOptions({
      icons: [{
        icon: boatIconNoHeading,
      }]
    })
  } else { // we know position and angle
    // console.log("Case 3: We know both position and angle")
    // boatMarker.setPosition(pos)
    boatIcon.rotation = angle
    boatIcon.anchor = new google.maps.Point(0, -0.5)//(0, -27)
    boatIcon.scale = 11//0.7
    // boatMarker.setIcon(boatIcon)
    // boatMarker.setOptions()
    boatPolyline.setPath([
      { lat: pos.lat - 1, lng: pos.lng },
      { lat: pos.lat, lng: pos.lng },
    ])
    boatPolyline.setOptions({
      icons: [{
        icon: boatIcon,
      }]
    })
  }
}

function updateLines(beforeCoords: coords[], afterCoords: coords[]) {
  afterPolyline.setVisible(afterCoords.length > 0)
  afterPolyline.setPath(afterCoords)
  beforePolyline.setVisible(beforeCoords.length > 0)
  beforePolyline.setPath(beforeCoords)
}

//----------------------
// Initializing the map
//----------------------

function mapInit() {
  // console.log("In mapInit")
  const mapCanvas = document.getElementById("map_canvas") as HTMLElement
  // console.log("mapCanvas is:", mapCanvas)
  const mapOpts = {
    center: coordsGothenburgKindaCentral,
    zoom: 10,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    mapId: "d731787bb76c1393",
    clickableIcons: false,
    disableDefaultUI: true,
    fullscreenControl: true,
    zoomControl: true,
    scaleControl: true,
    // streetViewControl: false,
    // mapTypeControl: false,
    keyboardShortcuts: false,
  }
  // console.log("mapOpts is:", mapOpts)
  map = new google.maps.Map(mapCanvas, mapOpts);

  boatPolyline = new google.maps.Polyline({
    path: [
      { lat: 22.291, lng: 153.027 },
      { lat: 18.291, lng: 153.027 },
    ],
    map: map,
    visible: false,
    strokeColor: "#ffffff",
    strokeOpacity: 0,
    icons: [{
      icon: boatIcon,
    }],
    zIndex: 1
  })
  boatIconNoHeading = {
    path: google.maps.SymbolPath.CIRCLE,
    fillOpacity: 1,
    fillColor: "white",
    strokeWeight: 0,
    rotation: 0,
    scale: 9
  }
  beforePolyline = new google.maps.Polyline({
    map: map,
    visible: false,
    strokeColor: "#4BD4BA",
    strokeWeight: 4
    // path: [
    //   { lat: 22.291, lng: 153.027 },
    //   { lat: 18.291, lng: 153.027 },
    // ]
  })
  afterPolyline = new google.maps.Polyline({
    map: map,
    visible: false,
    strokeColor: "#FFFFFF",
    strokeOpacity: 0.70,
    strokeWeight: 3
  })
  mapInitialized = true
}

//----------------------
// The map component
//----------------------

function extractCoordList(pointList: realTimePoint[]) {
  const coordList: coords[] = []
  pointList.forEach((point) => {
    const lat = point.latitude
    const lng = point.longitude
    if(lat !== null && lng !== null && -90 <= lat && lat <= 90 && -180 <= lng && lng <= 180) {
      coordList.push({lat: lat, lng: lng})
    }
  })
  return coordList
}

export default function RealTimeMap(data: apiData, view: viewSettings, chosenTime: number, windowStartTime: number, windowEndTime: number): JSX.Element {
  const [hasCenteredOnce, setHasCenteredOnce] = useState(false)
  let beforePoints: realTimePoint[] = []
  let afterPoints: realTimePoint[] = []
  data.realTime?.realTimePoints.forEach((point)=>{
    if(point.latitude !== null && !isNaN(point.latitude) && point.longitude !== null && !isNaN(point.longitude)) {
      const pointTime = Date.parse(point.timestamp)
      if (pointTime < windowStartTime || pointTime > windowEndTime) {
      } else if (pointTime <= chosenTime) {
        beforePoints.push(point)
      } else {
        afterPoints.push(point)
      }
    }
  })
  if(beforePoints.length > 0) {
    const seamPoint = beforePoints[0]
    afterPoints.push(seamPoint)
    vesselIconLatitude = seamPoint.latitude
    vesselIconLongitude = seamPoint.longitude
    vesselIconHeadingTrue = (seamPoint.headingTrue === null || isNaN(seamPoint.headingTrue)) ? null : seamPoint.headingTrue
  } else if(afterPoints.length > 0) {
    const seamPoint = afterPoints[afterPoints.length-1]
    vesselIconLatitude = seamPoint.latitude
    vesselIconLongitude = seamPoint.longitude
    vesselIconHeadingTrue = (seamPoint.headingTrue === null || isNaN(seamPoint.headingTrue)) ? null : seamPoint.headingTrue
  } else {
    vesselIconLatitude = null
    vesselIconLongitude = null
    vesselIconHeadingTrue = null
  }
  const beforeCoords = extractCoordList(beforePoints)
  const afterCoords = extractCoordList(afterPoints)

  useEffect(()=>{
    // console.log("useEffect for mapInit")
    loader.load().then(() => {
      mapInit()
    });
  }, [])

  useEffect(() => {
    setHasCenteredOnce(false)
  }, [view.realTime.vessel])

  useEffect(() => { // For updating the vessel icon
    if (mapInitialized === true) {
      // console.log("updating boat marker:", vesselIconLatitude, vesselIconLongitude, vesselIconHeadingTrue)
      let positionNow: coords | null
      if (vesselIconLatitude == null || vesselIconLongitude == null) {
        positionNow = null
      } else {
        positionNow = makeLatLng(vesselIconLatitude, vesselIconLongitude)
        if(!hasCenteredOnce) {
          map.panTo(positionNow)
          setHasCenteredOnce(true)
        }
      }
      updateBoatMarker(positionNow, vesselIconHeadingTrue, positionNow !== null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vesselIconLatitude, vesselIconLongitude, vesselIconHeadingTrue, mapInitialized])

  useEffect(() => { // For updating the before and after lines
    if(mapInitialized === true) {
      updateLines(beforeCoords, afterCoords)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beforeCoords, afterCoords, mapInitialized])

  const destinationsMapComponent = <div id="map_canvas" className="w-[60rem] h-[24rem] rounded-xl text-black bg-[#8c8c8c] border-2 shadow-[4px_4px_8px_2px_rgba(0,0,0,0.1)]"/>
  return destinationsMapComponent
}
