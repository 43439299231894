import React from "react"
import { useAppContext } from "../../context/variables"
import TabPage from "../TabPage"
import { environmentConfig } from "../../env/env"
import { AccessLevel, accessLevelAtMinimum } from "../../context/types"

export default function EnergyPage() {
  const { vars } = useAppContext()
  const pageCfg = environmentConfig.features.energy
  if(pageCfg === false) return <></>
  const adminOrAbove = accessLevelAtMinimum(vars.auth.accessLevel[0], AccessLevel.Admin)
  const tabs = [
    { name: "Overall", path: "/energy/overall", visible: pageCfg.overall }, 
    { name: "Operators", path: "/energy/operators", visible: adminOrAbove && pageCfg.operators }, 
  ]
  return (
    <TabPage title="Energy use" tabs={tabs}/>
  )
}
