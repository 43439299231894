import styled from "styled-components";

export const AddContainer = styled.div`
  width: 100%;
  max-width: 90%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 40px;
  border-radius: 12px;
  background: rgba(243, 249, 253, var(--tw-bg-opacity));
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media (min-width: 768px) {
    max-width: 70%;
  }

  @media (min-width: 1024px) {
    max-width: 55%;
  }

  @media (min-width: 1440px) {
    max-width: 45%;
    margin-bottom: 50px;
  }

  @media (min-width: 3440px) {
    max-width: 30%;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Input = styled.input<{ width?: string }>`
  width: ${({ width }) => width || "100%"};
  height: 70px;
  padding: 15px;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  background: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  color: #143452;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #143452;
  }
`;

export const Button = styled.button`
  width: 180px;
  height: 60px;
  padding: 10px;
  background: #8ac1ea;
  color: #143452;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  align-self: flex-end;

  &:hover {
    background-color: #7ab8e0;
  }

  margin-top: 30px;
`;

export const Row = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const MapContainer = styled.div`
  height: 522px;
  width: 752.33px;
  border-radius: 12px;
  overflow: hidden;
`;

export const InlineContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;
