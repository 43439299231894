import styled from "styled-components";

export const StyledTableCell = styled.td.withConfig({
  shouldForwardProp: (prop) =>
    !["isFirst", "isLast", "isCustomizedTab"].includes(prop),
})<{ isFirst?: boolean; isLast?: boolean; isCustomizedTab?: boolean }>`
  padding: ${({ isFirst, isLast, isCustomizedTab }) =>
    isCustomizedTab ? "5px 0" : isFirst || isLast ? "5px 16px" : "5px 10px"};
  text-align: left;
  color: #143452;

  font-family: "Montserrat", sans-serif;
  border-top-left-radius: ${({ isFirst }) => (isFirst ? "10px" : "0")};
  border-bottom-left-radius: ${({ isFirst }) => (isFirst ? "10px" : "0")};
  border-top-right-radius: ${({ isLast }) => (isLast ? "10px" : "0")};
  border-bottom-right-radius: ${({ isLast }) => (isLast ? "10px" : "0")};
  @media (min-width: 1080px) {
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const StyledTableHeaderCell = styled.th.withConfig({
  shouldForwardProp: (prop) =>
    !["isFirst", "isLast", "isCustomizedTab"].includes(prop),
})<{ isFirst?: boolean; isLast?: boolean; isCustomizedTab?: boolean }>`
  padding: ${({ isFirst, isLast, isCustomizedTab }) =>
    isCustomizedTab ? "5px 0" : isFirst || isLast ? "5px 16px" : "5px 16px"};
  text-align: left;
  color: #143452;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  border-top-left-radius: ${({ isFirst }) => (isFirst ? "10px" : "0")};
  border-bottom-left-radius: ${({ isFirst }) => (isFirst ? "10px" : "0")};
  border-top-right-radius: ${({ isLast }) => (isLast ? "10px" : "0")};
  border-bottom-right-radius: ${({ isLast }) => (isLast ? "10px" : "0")};

  @media (min-width: 1080px) {
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const IconTableCell = styled.td.withConfig({
  shouldForwardProp: (prop) =>
    !["isFirst", "isLast", "isCustomizedTab"].includes(prop),
})<{ isFirst?: boolean; isLast?: boolean; isCustomizedTab?: boolean }>`
  padding: 5px 0;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #143452;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  border-top-left-radius: ${({ isFirst }) => (isFirst ? "10px" : "0")};
  border-bottom-left-radius: ${({ isFirst }) => (isFirst ? "10px" : "0")};
  border-top-right-radius: ${({ isLast }) => (isLast ? "10px" : "0")};
  border-bottom-right-radius: ${({ isLast }) => (isLast ? "10px" : "0")};

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  svg {
    width: 35px;
    height: 35px;
  }
`;
