import React from "react"
import { Checkbox, FormControl, FormControlLabel, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { vesselListEntryData } from "../context/variables"
import { OperatorTabMetric, RealTimeSignal, realTimeSignalMap } from "../context/types"

export function makeStandardMenuItem(option: {name: string, value: string}, index: number) {
  return <MenuItem key={index} value={option.value}>{option.name}</MenuItem>
}

export function RawDropdown<T extends string>(label: string, value: T | null, valueOptions: {value: T, name: string}[], valueChanged: (value: T)=>void) {
  return <Select
    label={label}
    value={(value === null) ? "" : value}
    onChange={event => valueChanged(event.target.value as T)}
    inputProps={{
      'aria-label': label,
    }}
  >
    {valueOptions.map(makeStandardMenuItem)}
  </Select>
}

function multipleCheckboxRegularChange<T extends string>(value: T[], valueOptions: {value: T, name: string}[], valueChanged: (value: T[])=>void) {
  let newValue: T[] = []
  valueOptions.forEach((entry) => {
    if (value.includes(entry.value)) {
      newValue = newValue.concat(entry.value)
    }
  })
  valueChanged(newValue)
}

function multipleCheckboxAllClicked<T extends string>(valueBefore: T[], valueOptions: {value: T, name: string}[], valueChanged: (value: T[])=>void) {
  if (valueBefore.length < valueOptions.length) {
    // console.log("Ticking all")
    // Tick all
    valueChanged(valueOptions.map((opt) => opt.value))
  } else {
    // console.log("Unticking all")
    // Untick all
    valueChanged([])
  }
}

export function RawDropdownMultipleWithAll<T extends string>(label: string, value: T[], valueOptions: {value: T, name: string}[], valueChanged: (value: T[])=>void, allText: string) {
  return <Select
    label={label}
    multiple
    value={value}
    onChange={(event: SelectChangeEvent<T[]>) => {
      multipleCheckboxRegularChange(event.target.value as T[], valueOptions, valueChanged)
    }}
    renderValue={(selected) => {
      if (selected.length === valueOptions.length) {
        return allText
      } else {
        const selectedNamesIds = selected.map((id) => {
          const maybeEntry = valueOptions.find(
            (entry) => entry.value === id
          )
          if (maybeEntry !== undefined) {
            return maybeEntry.name
          } else {
            return id
          }
        })
        return selectedNamesIds.join(", ")
      }
    }}
  >
    <FormControlLabel className="pl-2"
      label={allText}
      control={<Checkbox
        checked={value.length === valueOptions.length}
        indeterminate={
          value.length !== valueOptions.length &&
          value.length !== 0
        }
        onChange={()=>multipleCheckboxAllClicked(value, valueOptions, valueChanged)}
      />}
    />
    {valueOptions.map(({ name: theName, value: theValue }, index) => (
      <MenuItem key={index} value={theValue}>
        <Checkbox checked={value.includes(theValue)} />
        <ListItemText primary={theName} />
      </MenuItem>
    ))}
  </Select>
}

export function inputSetting(name: string, picker: JSX.Element, width: number = 240) {
  const labelId = `${name}-label`;

  const inputSettingComponent = (
    <FormControl style={{ width: width }}>
      <InputLabel id={labelId}>{name}</InputLabel>
      {React.cloneElement(picker, { labelId: labelId, "aria-label": name })}
    </FormControl>
  );
  return inputSettingComponent;
}

export function StandardDropdown<T extends string>(label: string, value: T | null, valueOptions: {value: T, name: string}[], valueChanged: (value: T)=>void, width?: number) {
  return inputSetting(label, RawDropdown(label, value, valueOptions, valueChanged), width)
}

export function StandardDropdownMultipleWithAll<T extends string>(label: string, value: T[], valueOptions: {value: T, name: string}[], valueChanged: (value: T[])=>void, allText: string, width?: number) {
  return inputSetting(label, RawDropdownMultipleWithAll(label, value, valueOptions, valueChanged, allText), width)
}

export function makeVesselPicker(vessel: string|null, vesselOptions: vesselListEntryData[], vesselChanged: (vessel: string) => void) {
  const value = (vessel !== null) ? vessel : (vesselOptions.length > 0) ? vesselOptions[0].id : ""
  const vesselDropdown = 
    <Select
      label="Vessel"
      value={value}
      onChange={(event: SelectChangeEvent) => {
        vesselChanged(event.target.value)
      }}
    >
      {vesselOptions.map(({name: theName, id: theValue}, index)=><MenuItem key={index} value={theValue}>{theName}</MenuItem>)}
    </Select>
  return vesselDropdown
}

export function makeMetricPicker(metric: OperatorTabMetric, metricOptions: {name: string, value: OperatorTabMetric}[], metricChanged: (metric: OperatorTabMetric) => void) {
  const metricDropdown = 
    <Select
      label="Metric"
      value={metric}
      onChange={(event: SelectChangeEvent<OperatorTabMetric>) => {
        metricChanged(event.target.value as OperatorTabMetric)
      }}
    >
      {metricOptions.map(({name: theName, value: theValue}, index)=>{return <MenuItem key={index} value={theValue}>{theName}</MenuItem>})}
    </Select>
  return metricDropdown
}

export function makeSignalPicker(signal: RealTimeSignal, signalOptions: RealTimeSignal[], signalChanged: (signal: RealTimeSignal) => void) {
  // const value = (vessel !== null) ? vessel : (vesselOptions.length > 0) ? vesselOptions[0].id : ""
  const signalDropdown = 
    <Select
      label="Signal"
      value={signal}
      onChange={(event: SelectChangeEvent) => {
        signalChanged(event.target.value as RealTimeSignal)
      }}
    >
      {signalOptions.map((sig, index)=><MenuItem key={index} value={sig}>{realTimeSignalMap[sig].prettyName+" ("+realTimeSignalMap[sig].unitShort+")"}</MenuItem>)}
    </Select>
  return signalDropdown
}

export function inputTray(inputs: JSX.Element[][], extraXGap?: boolean) {
  const rows = inputs.map((inputRow, index)=>
    <div key={index} className={`flex items-center ${extraXGap ? "space-x-8" : "space-x-4"}`}>
      {inputRow.map((input, index) =>
        <div key={index}>{input}</div>
      )}
    </div>
  )
  return (
    <div className="w-fit bg-white p-3 rounded-xl shadow-tray flex flex-col space-y-4">
      {rows}
    </div>
  )
}

export function makeTripTypePicker(
  selectedTripType: string,
  tripTypes: { value: string; name: string }[],
  onChange: (tripType: string) => void
) {
  const value = selectedTripType;
  const tripTypeDropdown = (
    <Select
      label="Trip Type"
      value={value}
      onChange={(event: SelectChangeEvent) => {
        onChange(event.target.value);
      }}
    >
      {tripTypes.map(({ name, value: theValue }, index) => (
        <MenuItem key={index} value={theValue}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
  return tripTypeDropdown;
}

