// apiService.ts
import axiosInstance from "../../axiosInstance";
import { getErrorDetails, setAuthorizationHeader } from "../../helpers";
import { AlarmType } from "../../../context/variables";
import { processAlarmTypesResponse } from "./responseHandlers";
import { updateAlarmTypesContext } from "./contextHandlers";
import { Maybe } from "../../../types";

export const getAllCustomAlarmsType = async (
  view: any,
  data: any,
  vars: any,
  navigate: any
): Promise<Maybe<AlarmType[]>> => {
  setAuthorizationHeader(vars, data, view, navigate, axiosInstance);

  try {
    const response = await axiosInstance.get("alarms-types/");
    const alarmTypes = processAlarmTypesResponse(response.data);
    updateAlarmTypesContext(alarmTypes, data);
    return alarmTypes;
  } catch (error) {
    getErrorDetails(error);
  }
};
