import { debounce } from "lodash";
import { TripsTableExpandable } from "../../../components/Table";
import {
  epochToPrettyStringRealTime,
  formatDateTime,
} from "../../../helpers/dateTimeHelpers";
import {
  booleanToString,
  numNullToString,
  numToStringDecimal,
  timeDiffToString,
} from "../../../helpers/stringHelpers";
import { NM_PER_M } from "../../../context/constants";
import {
  TagValueType,
  TagScope,
  AccessLevel,
  accessLevelAtMinimum,
} from "../../../context/types";
import {
  useAppContext,
  secondaryTripData,
  FuelMasses,
} from "../../../context/variables";
import SecondaryTripsMap from "./SecondaryTripsMap";
import SecondaryTripsGraph from "./SecondaryTripsGraph";
import { JSX, useEffect, useState } from "react";
import useFetchData from "../../../apiComms/fetchData";
import LoadingSpinner from "../../../components/atoms/LoadingSpinner";

export default function TripsTable({
  showVesselColumn,
  selectedTripType,
}: {
  showVesselColumn: boolean;
  shouldRenderMap: boolean;
  selectedTripType: string;
}): JSX.Element {
  const { data, vars } = useAppContext();
  const { fetchTripsTypeSecondaryData, fetchDataOpModes } = useFetchData();
  const showExtraData = accessLevelAtMinimum(
    vars.auth.accessLevel[0],
    AccessLevel.Root
  );
  const trips = data.trips?.tripList?.[0];
  const tagEvents = data.tagEvents.tagEventList.val;
  const tagTypes = data.tagTypes.tagTypeList.val;

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [currentSecondaryTripData, setCurrentSecondaryTripData] =
    useState<secondaryTripData | null>(null);

  useEffect(() => {
    fetchDataOpModes();
  }, [vars.auth.organisationId, vars.auth.accessLevel[0]]);

  const debouncedHandleRowClick = debounce(
    async (
      vesselId: string | null,
      timestring: string | null,
      routeId: string | null
    ) => {
      let shouldFetchData = false;

      try {
        let secondaryTripData;

        shouldFetchData = !(
          currentSecondaryTripData &&
          currentSecondaryTripData.timestring === timestring
        );
        if (shouldFetchData) {
          setIsLoadingData(true);

          secondaryTripData = await fetchTripsTypeSecondaryData(
            selectedTripType,
            vesselId,
            routeId,
            timestring
          );
        } else {
          secondaryTripData = currentSecondaryTripData;
        }

        if (secondaryTripData && secondaryTripData.timestring === timestring) {
          setCurrentSecondaryTripData(secondaryTripData);
        } else {
          setCurrentSecondaryTripData(null);
        }
      } catch (error) {
        console.error("Error fetching secondary trip data:", error);
        setCurrentSecondaryTripData(null);
      } finally {
        if (shouldFetchData) {
          setIsLoadingData(false);
        }
      }
    },
    300
  );

  const rows =
    trips === null
      ? null
      : trips.map((tripData) => {
          const orgMaybe = data.org.organisationList?.find(
            (org) => org.id === tripData.orgId
          );
          const orgName = orgMaybe === undefined ? "N/A" : orgMaybe.name;

          const formatTime = (seconds: number | null) => {
            if (seconds === null) return "N/A";
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const secs = seconds % 60;
            return `${hours.toFixed(0).padStart(2, "0")}:${minutes
              .toFixed(0)
              .padStart(2, "0")}:${secs.toFixed(0).padStart(2, "0")}`;
          };

          const formatTransportWork = (work: number | null) =>
            work !== null ? work.toFixed(2) : "N/A";

          const formatTrim = (trim: number | null) =>
            trim !== null ? trim.toFixed(2) : "N/A";

          const formatWindDirection = (direction: number | null | undefined) =>
            direction != null ? direction.toFixed(2) : "N/A";

          const formatWindSpeed = (speed: number | null | undefined) =>
            speed != null ? speed.toFixed(2) : "N/A";

          const formatCargoWeight = (weight: number | undefined): string =>
            weight !== undefined ? (weight / 1000).toFixed(2) : "N/A";

          const formatTransportWorkAdjusted = (
            work: string | null | undefined
          ) =>
            formatTransportWork(
              work !== null && work !== undefined ? parseFloat(work) : null
            );

          const formatTrimAdjusted = (trim: string | null | undefined) =>
            formatTrim(
              trim !== null && trim !== undefined ? parseFloat(trim) : null
            );

          const opModes = data.opModes.opModeList.val;

          const formattedOpModeTimes = tripData.op_mode_times
            ? Object.entries(tripData.op_mode_times)
                .map(([opModeId, time]) => {
                  if (opModes) {
                    const opMode = opModes.find(
                      (opMode) => opMode.opModeId === opModeId
                    );
                    const opModeName = opMode ? opMode.name : null;
                    return `${opModeName}: ${formatTime(Number(time))}`;
                  }
                  return "Unknown Op Mode";
                })
                .join("; ")
            : "N/A";

          const formatFuelMasses = (
            fuelMasses:
              | FuelMasses
              | { [s: string]: unknown }
              | ArrayLike<unknown>
          ) => {
            if (!fuelMasses) return "N/A";
            return Object.entries(fuelMasses)
              .map(
                ([key, value]) =>
                  `${key}: ${(parseFloat(value as string) / 1000).toFixed(2)}`
              )
              .join("; ");
          };

          const vesMaybe = data.org.vesselList.find(
            (ves) => ves.id === tripData.vesselId
          );
          const vesName = vesMaybe === undefined ? "N/A" : vesMaybe.name;

          const operatorsPretty = tripData.operators?.map((operatorId) => {
            const opMaybe = data.org.userList?.find(
              (user) => user.username === operatorId
            );
            return opMaybe?.name ?? operatorId;
          });
          const operatorsStr = operatorsPretty?.join(", ") || "N/A";

          const epochStartTime = new Date(tripData.startTimestamp).valueOf();
          const prettyStartTime = formatDateTime(tripData.startTimestamp);
          const prettyEndTime = tripData.end_timestring
            ? formatDateTime(tripData.end_timestring)
            : "N/A";
          const epochEndTime = new Date(tripData.endTimestamp).valueOf();
          const timeTotalStr =
            tripData.timeTotal !== null
              ? timeDiffToString(tripData.timeTotal)
              : "N/A";
          const distanceTotalStr =
            tripData.distanceTotal !== null
              ? `${numToStringDecimal(tripData.distanceTotal * NM_PER_M, 3)}`
              : "N/A";
          const fuelMassesStr = tripData.fuel_masses
            ? formatFuelMasses(tripData.fuel_masses)
            : "N/A";

          const formatLatitude = (
            latitude: number | null | undefined
          ): string => {
            if (latitude === null || latitude === undefined) return "N/A";
            const direction = latitude >= 0 ? "N" : "S";
            return `${Math.abs(latitude).toFixed(2)}° ${direction}`;
          };

          const formatLongitude = (
            longitude: number | null | undefined
          ): string => {
            if (longitude === null || longitude === undefined) return "N/A";
            const direction = longitude >= 0 ? "E" : "W";
            return `${Math.abs(longitude).toFixed(2)}° ${direction}`;
          };

          const tripTypeTagScope =
            selectedTripType === "leg" ? TagScope.LegInstance : null;
          const applicableTags = tagEvents?.filter((tagEvent) => {
            const epochTagEventTime = new Date(tagEvent.timestamp).valueOf();
            const isBetween =
              epochStartTime <= epochTagEventTime &&
              epochTagEventTime < epochEndTime;
            const correctVessel = tagEvent.vesselId === tripData.vesselId;
            const tagType = tagTypes?.find(
              (tagType) => tagType.id === tagEvent.tagTypeId
            );
            const tagEventScope = tagType?.scope;
            const correctScope =
              tripTypeTagScope !== null && tripTypeTagScope === tagEventScope;
            return isBetween && correctVessel && correctScope;
          });

          const tagsStrs = applicableTags?.map((tagEvent) => {
            const tagType = tagTypes?.find(
              (tagType) => tagType.id === tagEvent.tagTypeId
            );
          });

          const tagsStr =
            applicableTags
              ?.map((tagEvent) => {
                const tagType = tagTypes?.find(
                  (type) => type.id === tagEvent.tagTypeId
                );
                const tagTypeName = tagType?.name ?? tagEvent.tagTypeId;
                const tagValueStr =
                  tagType?.valueType === TagValueType.Number
                    ? `${tagEvent.value}${tagType.unit ?? ""}`
                    : tagType?.valueType === TagValueType.Boolean
                    ? booleanToString(tagEvent.value)
                    : tagType?.valueType === TagValueType.String
                    ? `"${tagEvent.value}"`
                    : tagEvent.value.toString();
                return `${tagTypeName} - ${tagValueStr}`;
              })
              .join(", ") || "N/A";

          const vesselCell = [vesName];

          const opModeName =
            opModes?.find((opMode) => opMode.opModeId === tripData.op_mode)
              ?.name || "N/A";

          const alwaysCells = [
            vesselCell,
            prettyStartTime,
            prettyEndTime,
            timeTotalStr,
            distanceTotalStr,
            operatorsStr,
            tagsStr,
            numNullToString(
              tripData.dieselAverage,
              (val) => `${numToStringDecimal(val, 3)} L/h`
            ),
            numNullToString(
              tripData.dieselTotal,
              (val) => `${numToStringDecimal(val, 3)} L`
            ),
            numNullToString(
              tripData.electricAverage,
              (val) => `${numToStringDecimal(val, 3)} kW`
            ),
            numNullToString(
              tripData.electricTotal,
              (val) => `${numToStringDecimal(val, 3)} kWh`
            ),
          ].map((cell) => cell.toString());
          let contents;
          if (selectedTripType === "voyage" || selectedTripType === "opmode") {
            contents = [
              vesName || "N/A",
              formatDateTime(tripData.startTimestamp),
              tripData.end_timestring
                ? formatDateTime(tripData.end_timestring)
                : "N/A",
              tripData.total_diesel?.toString() || "N/A",
              tripData.dieselAverage?.toString() || "N/A",
              tripData.total_elec?.toString() || "N/A",
              tripData.electricAverage?.toString() || "N/A",
              fuelMassesStr || "N/A",
              distanceTotalStr || "N/A",
              timeTotalStr || "N/A",
              formatTransportWorkAdjusted(tripData.transport_work) || "N/A",
              formatCargoWeight(tripData.cargo_start),
              formatCargoWeight(tripData.cargo_median),
              formatCargoWeight(tripData.cargo_end),
              tripData.cargo_types
                ? JSON.stringify(tripData.cargo_types)
                : "N/A",
              formattedOpModeTimes || "N/A",
              operatorsStr || "N/A",
              tripData.start_dest_id || "N/A",
              tripData.start_dest_in_eu?.toString() || "N/A",
              formatLatitude(tripData.start_dest_lat),
              formatLongitude(tripData.start_dest_lon),
              tripData.end_dest_id || "N/A",
              tripData.end_dest_in_eu?.toString() || "N/A",
              formatLatitude(tripData.end_dest_lat),
              formatLongitude(tripData.end_dest_lon),
              formatTrimAdjusted(tripData.trim) || "N/A",
              formatWindDirection(tripData.wind_dir) || "N/A",
              formatWindSpeed(tripData.wind_spd) || "N/A",
              formatWindDirection(tripData.wind_dir_app) || "N/A",
              formatWindSpeed(tripData.wind_spd_app) || "N/A",
              tripData.current_dir
                ? formatWindDirection(tripData.current_dir)
                : "N/A",
              tripData.current_spd
                ? formatWindSpeed(tripData.current_spd)
                : "N/A",
              tripData.current_dir_app
                ? formatWindDirection(tripData.current_dir_app)
                : "N/A",
              tripData.current_spd_app
                ? formatWindSpeed(tripData.current_spd_app)
                : "N/A",
              opModeName || "N/A",
            ];
          } else {
            contents = alwaysCells;
          }

          if (showExtraData) {
            const extraCells = [
              tripData.orgId ? `${tripData.orgId} (${orgName})` : "N/A",
              tripData.pathId || "N/A",
              tripData.routeId || "N/A",
              tripData.vesselRouteId || "N/A",
            ];
            contents = contents.concat(extraCells);
          }

          return {
            id: tripData.vesselId,
            timestring: tripData.startTimestamp,
            routeId: tripData.routeId,
            contents: contents,
            handleClick: () =>
              debouncedHandleRowClick(
                tripData.vesselId,
                tripData.startTimestamp,
                tripData.routeId
              ),
          };
        });

  const voyageColumnNames = [
    "Vessel",
    "Start Time",
    "End Time",
    "Total Diesel (L)",
    "Average Diesel (L/h)",
    "Total Electric (kWh)",
    "Average Electric (kW)",
    "Fuel Masses (Tonnes)",
    "Total Distance (NM)",
    "Duration",
    "Transport Work",
    "Cargo Start (Tonnes)",
    "Cargo Median (Tonnes)",
    "Cargo End (Tonnes)",
    "Cargo Types",
    "Operation Mode Times",
    "Operators",
    "Start Destination",
    "Start Destination EU",
    "Start Destination Latitude",
    "Start Destination Longitude",
    "End Destination",
    "End Destination EU",
    "End Destination Latitude",
    "End Destination Longitude",
    "Trim (m)",
    "Wind Direction (°)",
    "Wind Speed (m/s)",
    "Apparent Wind Direction (°)",
    "Apparent Wind Speed (m/s)",
    "Current Direction (°)",
    "Current Speed (m/s)",
    "Apparent Current Direction (°)",
    "Apparent Current Speed (m/s)",
    "Operational Mode",
  ];

  const alwaysColumnNames = [
    "Vessel",
    "Start Time",
    "End Time",
    "Duration",
    "Distance",
    "Operator(s)",
    "Applied tags",
    "Fuel usage (Average) (L/h)",
    "Fuel used (L)",
    "Electricity usage (Average) (kW)",
    "Electricity used (kWh)",
  ];

  const extraColumnNames = showExtraData
    ? ["Organisation ID", "Path ID", "Route ID", "Vessel Route ID"]
    : [];

  let columnNames;

  if (selectedTripType === "voyage" || selectedTripType === "opmode") {
    columnNames = [...voyageColumnNames];
    if (showExtraData) {
      columnNames = [...columnNames, ...extraColumnNames];
    }
  } else {
    const baseColumnNames = showVesselColumn
      ? [...alwaysColumnNames]
      : alwaysColumnNames;
    columnNames = showExtraData
      ? [...baseColumnNames, ...extraColumnNames]
      : baseColumnNames;
  }

  const emptyColumns = new Set();
  rows?.forEach((row) => {
    row.contents.forEach((content, index) => {
      let parsedContent;
      try {
        parsedContent = JSON.parse(content);
      } catch {
        parsedContent = content;
      }

      if (
        content === "N/A" ||
        content === null ||
        content === undefined ||
        isNaN(parsedContent) ||
        (Array.isArray(parsedContent) && parsedContent.length === 0)
      ) {
        emptyColumns.add(index);
      }
    });
  });

  const filteredColumnNames = columnNames.filter(
    (_, index) => !emptyColumns.has(index)
  );

  const filteredRows =
    rows?.map((row) => ({
      ...row,
      contents: row.contents.filter((_, index) => !emptyColumns.has(index)),
    })) || [];

  const sortableColumns = [
    "Start Time",
    "End Time",
    "Average Diesel Usage",
    "Average Electric Usage",
    "Total Distance",
    "Total Fuel Used",
    "Wind Speed and Direction",
    "Duration",
    "Distance",
    "Fuel usage (Average)",
    "Fuel used",
    "Cargo Start",
    "Cargo Median",
    "Cargo End",
    "Trim",
    "Wind Direction",
    "Wind Speed",
    "Apparent Wind Direction",
    "Apparent Wind Speed",
    "Current Direction",
    "Current Speed",
    "Apparent Current Direction",
    "Apparent Current Speed",
    "Fuel Masses",
    "Total Distance",
    "Start Destination",
    "End Destination",
    "Start Destination Latitude",
    "Start Destination Longitude",
    "End Destination Latitude",
    "End Destination Longitude",
  ];

  return (
    <TripsTableExpandable
      columnNames={columnNames}
      rows={rows}
      sortableColumns={sortableColumns}
      expandedContent={(row: { id: any; routeId: any; timestring: any }) => {
        const vesselId = row.id;
        const routeIdFromRow = row.routeId;
        const timestring = row.timestring;

        debouncedHandleRowClick(vesselId, timestring, routeIdFromRow);

        if (isLoadingData) {
          return (
            <div className="flex justify-center items-center w-full h-[12rem] bg-gray-100 border-2 rounded-xl shadow-md text-lg font-semibold">
              <LoadingSpinner />
            </div>
          );
        }

        if (
          !currentSecondaryTripData ||
          !currentSecondaryTripData.lats ||
          !currentSecondaryTripData.lons
        ) {
          return (
            <div className="flex justify-center items-center w-full h-[12rem] bg-gray-100 border-2 rounded-xl shadow-md text-lg font-semibold">
              Map or graph data for this trip is currently unavailable. Please
              select a different trip.
            </div>
          );
        }

        return (
          <div className="flex items-center space-x-8">
            <SecondaryTripsMap
              key={`${row.id}-${currentSecondaryTripData.vessel_id}-${currentSecondaryTripData.end_timestring}`}
              secondaryTripData={{
                lats: currentSecondaryTripData.lats,
                lons: currentSecondaryTripData.lons,
              }}
            />
            <div className="flex-grow h-1/2 ml-8 pr-8 rounded-lg overflow-hidden shadow-lg">
              <div className="relative bg-white border border-gray-200 rounded-lg shadow-lg w-full h-1/2 overflow-hidden">
                <SecondaryTripsGraph
                  secondaryTripData={currentSecondaryTripData}
                  chosenTime={new Date().getTime()}
                  signals={[]}
                />
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}
