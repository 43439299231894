import { IconLabel } from "../../../components/atoms";
import {
  AlarmIconHigh,
  AlarmIconMedium,
  AlarmIconLow,
} from "../../../assets/icons";

const iconSize = 20;

const getIconForAlarmType = (alarmLevel: string): React.ReactNode => {
  switch (alarmLevel.toLowerCase()) {
    case "high":
      return <AlarmIconHigh size={iconSize} />;
    case "medium":
      return <AlarmIconMedium size={iconSize} />;
    case "low":
      return <AlarmIconLow size={iconSize} />;
    default:
      return null;
  }
};

export const createIconLabelForAlarmType = (
  alarmLevel: string,
  label: string
): React.ReactNode => {
  const icon = getIconForAlarmType(alarmLevel);
  return <IconLabel icon={icon} label={label} />;
};
