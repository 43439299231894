import { useAppContext } from "../../context/variables"
import { CartesianGrid, Label, Scatter, ScatterChart, Tooltip as RechartsTooltip, XAxis, YAxis, ZAxis } from "recharts"
import { fillGrayIfNoData, graphLoadOrNoDataNotice } from "../../helpers/graphHelpers"

export default function EnginePowerSogMapChart() {
  const { data } = useAppContext()
  const enginePowerSogMapData = (data.performance.totalEnginePowerSogMap === null) ? undefined : data.performance.totalEnginePowerSogMap.filter((p)=>{
    return p.timePercent > 0.1
  })
  return <ScatterChart
    width={500}
    height={500}
    margin={{
      top: 20,
      right: 20,
      bottom: 20,
      left: 30,
    }}
  >
    <CartesianGrid strokeDasharray="3 3" fill={fillGrayIfNoData(enginePowerSogMapData)}/>
    <XAxis dataKey="sogKn" type="number" name="sogKn" >
      <Label
        value="Speed over ground (kn)"
        offset={0}
        position="bottom"
      />
    </XAxis>
    <YAxis dataKey="enginePowerKw" type="number" name="enginePowerKw" unit="kW" >
      <Label
        value="Electricity usage (vessel total)"
        offset={20}
        position="left"
        angle={-90}
        style={{ textAnchor: 'middle' }}
      />
    </YAxis>
    <ZAxis dataKey="timeHours" type="number" scale={"linear"} range={[10, 400]} name="time" unit="" />
    <RechartsTooltip 
      cursor={{ strokeDasharray: '3 3' }} 
      formatter={(value: string, name, props) => {
        const sogKn = Math.round(parseFloat(props.payload.sogKn) * 1000)/1000
        const enginePowerKw = Math.round(parseFloat(props.payload.enginePowerKw) * 1000)/1000
        const timeHoursRounded = Math.round(parseFloat(props.payload.timeHours) * 1000)/1000
        const timePercentRounded = Math.round(parseFloat(props.payload.timePercent) * 1000)/1000
        if(props.dataKey === "sogKn") {
          return [sogKn+" kn", "Speed over ground"]
        } else if(props.dataKey === "enginePowerKw") {
          return [enginePowerKw+" ", "Electricity usage (vessel total)"]
        } else if(props.dataKey === "timeHours") {
          return [timeHoursRounded+" h ("+timePercentRounded+" %)", "Time"]
        } else {
          return [value, name]
        }
      }}
    />
    <Scatter data={enginePowerSogMapData} fill={"#102840"} />
    {graphLoadOrNoDataNotice(enginePowerSogMapData)}
  </ScatterChart>
}
