import React from 'react';
import { CenteredContainer, EmptyStateContainer, IconContainer, Title, Description } from '../styles/EmptyStateStyles';
import { StyledButtonEmptyState } from '../styles/ButtonEmptyStateStyles';

type EmptyStateProps = {
  icon: React.ReactNode;
  title: string;
  description: string;
  buttonText?: string;
  onButtonClick?: () => void;
};

const EmptyState: React.FC<EmptyStateProps> = ({ icon, title, description, buttonText, onButtonClick }) => {
  return (
    <CenteredContainer role="alert" aria-live="assertive" aria-labelledby="empty-state-title" aria-describedby="empty-state-description">
      <EmptyStateContainer>
        <IconContainer aria-hidden="true">{icon}</IconContainer>
        <Title as="h2" id="empty-state-title">{title}</Title>
        <Description id="empty-state-description">{description}</Description>
        {buttonText && onButtonClick && (
          <StyledButtonEmptyState onClick={onButtonClick}>
            {buttonText}
          </StyledButtonEmptyState>
        )}
      </EmptyStateContainer>
    </CenteredContainer>
  );
};

export default EmptyState;
