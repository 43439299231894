import React from 'react';

const HistoryIcon: React.FC = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="113" height="113" viewBox="0 0 113 113" fill="none">
  <path d="M57 27V58.6981L78 69" stroke="#1F64E7" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M3.45808 69.0769C9.13615 93.1131 30.729 111 56.5 111C86.5995 111 111 86.5999 111 56.5C111 26.4005 86.5995 2 56.5 2C35.4737 2 17.2281 13.9071 8.13921 31.3462" stroke="#1F64E7" stroke-width="3" stroke-linecap="round"/>
  <path d="M2 14.5762V35.5377H22.9615" stroke="#1F64E7" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);

export default HistoryIcon;
