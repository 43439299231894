import { parseBooleanAttribute } from "../auth/authentication"
import { dateToString, stringToDate } from "../helpers/dateTimeHelpers"
import { AccessLevel, Aggregation, aggregationToString, OperatorTabMetric, operatorTabMetricToString, stringToAggregation, stringToEnum, stringToOperatorTabMetric, stringToTimescale, stringToVesselActivities, stringToVesselActivity, Timescale, timescaleToString, vesselActivitiesToString, VesselActivity, vesselActivityToString } from "./types"
import { apiData, variables, viewSettings } from "./variables"

//--------------------------------------
// A key for each variable to store locally
//--------------------------------------

export enum localKeys {
  IdToken = "IdToken",
  AccessToken = "AccessToken",
  RefreshToken = "RefreshToken",
  TokenExp = "TokenExp",

  Username = "Username",
  Email = "Email",
  IsOperator = "IsOperator",
  IsAdmin = "IsAdmin",
  IsOwner = "IsOwner",
  UserGroups = "UserGroups",

  OrganisationId = "OrganisationId",
  OrganisationName = "OrganisationName",
  AccessLevel = "AccessLevel",

  FromTime = "viewFromTime",
  ToTime = "viewToTime",
  Vessel = "viewVessel",
  Aggregation = "viewAggregation",
  Metric = "viewMetric",
  Activity = "viewActivity",
  Timescale = "viewTimescale",
  TimePeriod = "viewTimePeriod",

  EnergyOperatorVessels = "EnergyOperatorVessels",
  EnergyOperatorOperators = "EnergyOperatorOperators",
  EnergyOperatorMetric = "EnergyOperatorMetric",
  EnergyOperatorActivities = "EnergyOperatorActivities",
  EnergyOperatorFromTime = "EnergyOperatorFromTime",
  EnergyOperatorToTime = "EnergyOperatorToTime",
  EnergyOperatorTimescale = "EnergyOperatorTimescale",
  EnergyOperatorTimePeriod = "EnergyOperatorTimePeriod",
  EnergyOperatorAggregation = "EnergyOperatorAggregation",
}

//--------------------------------------
// Various general functions
//--------------------------------------

function setLocal (key: localKeys, value: string) {
  localStorage.setItem(key, value)
  // console.log("Set a local: ", key, value)
}

function deleteLocal (key: localKeys) {
  localStorage.removeItem(key)
}

export function removeLocals () {
  localStorage.clear()
  // console.log("Removed locals")
}

//--------------------------------------
// A setter for each local variable
//--------------------------------------

export function setLocalIdToken (idToken: string) { // or null?
  setLocal(localKeys.IdToken, idToken)
}

export function setLocalAccessToken (accessToken: string) {
  setLocal(localKeys.AccessToken, accessToken)
}

export function setLocalRefreshToken (refreshToken: string) {
  setLocal(localKeys.RefreshToken, refreshToken)
}

export function setLocalExp (exp: number) {
  const value = exp.toString()
  setLocal(localKeys.TokenExp, value)
}

export function setLocalUsername (username: string) {
  setLocal(localKeys.Username, username)
}

export function setLocalEmail (email: string) {
  setLocal(localKeys.Email, email)
}

export function setLocalIsOperator (isOperator: boolean) {
  setLocal(localKeys.IsOperator, isOperator.toString())
}

export function setLocalIsAdmin (isAdmin: boolean) {
  setLocal(localKeys.IsAdmin, isAdmin.toString())
}

export function setLocalIsOwner (isOwner: boolean) {
  setLocal(localKeys.IsOwner, isOwner.toString())
}

export function setLocalUserGroups (userGroups: string[]) {
  if (userGroups === undefined) { return }
  // console.log("userGroups: ", userGroups, typeof(userGroups))
  const value = userGroups.toString() // ???
  setLocal(localKeys.UserGroups, value)
}

export function setLocalOrganisationId (organisationId: string|null) {
  if(organisationId !== undefined) {
    if(organisationId !== null) {
      setLocal(localKeys.OrganisationId, organisationId)
    } else {
      deleteLocal(localKeys.OrganisationId)
    }
  }
}

export function setLocalOrganisationName (organisationName: string|null) {
  if(organisationName !== null) {
    setLocal(localKeys.OrganisationName, organisationName)
  } else {
    deleteLocal(localKeys.OrganisationName)
  }
}

export function setLocalAccessLevel(accessLevel: AccessLevel) {
  const str = accessLevel as string
  setLocal(localKeys.AccessLevel, str)
}

export function setLocalFromTime (fromTime: Date) {
  setLocal(localKeys.FromTime, dateToString(fromTime, true))
}

export function setLocalToTime (toTime: Date) {
  setLocal(localKeys.ToTime, dateToString(toTime, true))
}

export function setLocalVessel (vessel: string) {
  setLocal(localKeys.Vessel, vessel)
}

export function setLocalAggregation (aggregation: Aggregation) {
  const stringAggr = aggregationToString(aggregation)
  setLocal(localKeys.Aggregation, stringAggr)
}

export function setLocalMetric (metric: OperatorTabMetric) {
  const stringMetric = operatorTabMetricToString(metric)
  setLocal(localKeys.Metric, stringMetric)
}

export function setLocalActivity (activity: VesselActivity) {
  const stringActivity = vesselActivityToString(activity)
  setLocal(localKeys.Activity, stringActivity)
}

export function setLocalTimescale (timescale: Timescale) {
  const stringTimescale = timescaleToString(timescale)
  setLocal(localKeys.Timescale, stringTimescale)  
}

export function setLocalTimePeriod (timePeriod: string) {
  setLocal(localKeys.TimePeriod, timePeriod)
}

export function setLocalEnergyOperatorVessels (vessels: string[]) {
  const vesselsStr = vessels.join()
  setLocal(localKeys.EnergyOperatorVessels, vesselsStr)
}

export function setLocalEnergyOperatorOperators (operators: string[]) {
  const operatorsStr = operators.join()
  setLocal(localKeys.EnergyOperatorOperators, operatorsStr)
}

export function setLocalEnergyOperatorMetric (metric: OperatorTabMetric) {
  const metricStr = operatorTabMetricToString(metric)
  setLocal(localKeys.EnergyOperatorMetric, metricStr)
}

export function setLocalEnergyOperatorActivities (activities: VesselActivity[]) {
  const activitiesStr = vesselActivitiesToString(activities)
  setLocal(localKeys.EnergyOperatorActivities, activitiesStr)
}

export function setLocalEnergyOperatorFromTime (fromTime: Date) {
  const fromTimeStr = dateToString(fromTime, true)
  setLocal(localKeys.EnergyOperatorFromTime, fromTimeStr)
}

export function setLocalEnergyOperatorToTime (toTime: Date) {
  const toTimeStr = dateToString(toTime, true)
  setLocal(localKeys.EnergyOperatorToTime, toTimeStr)
}

export function setLocalEnergyOperatorTimescale (timescale: Timescale) {
  const timescaleStr = timescaleToString(timescale)
  setLocal(localKeys.EnergyOperatorTimescale, timescaleStr)
}

export function setLocalEnergyOperatorTimePeriod (timePeriod: string) {
  setLocal(localKeys.EnergyOperatorTimePeriod, timePeriod)
}

export function setLocalEnergyOperatorAggregation (aggregation: Aggregation) {
  const aggregationStr = aggregationToString(aggregation)
  setLocal(localKeys.EnergyOperatorAggregation, aggregationStr)
}

//--------------------------------------
// One function getting all local vars into cxt
//--------------------------------------

export function getLocals (vars: variables, view: viewSettings, data: apiData) {
  // console.log("before getLocals")
  // maybe later do something like "cognitoGetTokens(vars)" to get tokens from cookies

  const idTokenLocal = localStorage.getItem(localKeys.IdToken)
  if (idTokenLocal !== null) {
    vars.auth.setIdToken(idTokenLocal)
  }
  const accessTokenLocal = localStorage.getItem(localKeys.AccessToken)
  if (accessTokenLocal !== null) {
    vars.auth.setAccessToken(accessTokenLocal)
  }
  const refreshTokenLocal = localStorage.getItem(localKeys.RefreshToken)
  if (refreshTokenLocal !== null) {
    vars.auth.setRefreshToken(refreshTokenLocal)
  }
  const expLocal = localStorage.getItem(localKeys.TokenExp)
  if (expLocal !== null) {
    const expValue = parseInt(expLocal)
    vars.auth.setExp(expValue)
  }

  const usernameLocal = localStorage.getItem(localKeys.Username)
  if (usernameLocal !== null) {
    vars.auth.setUsername(usernameLocal)
  }
  const emailLocal = localStorage.getItem(localKeys.Email)
  if (emailLocal !== null) {
    vars.auth.setEmail(emailLocal)
  }
  const isOperatorLocal = localStorage.getItem(localKeys.IsOperator)
  if (isOperatorLocal !== null) {
    vars.auth.setIsOperator(parseBooleanAttribute(isOperatorLocal))
  }
  const isAdminLocal = localStorage.getItem(localKeys.IsAdmin)
  if (isAdminLocal !== null) {
    vars.auth.setIsAdmin(parseBooleanAttribute(isAdminLocal))
  }
  const isOwnerLocal = localStorage.getItem(localKeys.IsOwner)
  if (isOwnerLocal !== null) {
    vars.auth.setIsOwner(parseBooleanAttribute(isOwnerLocal))
  }
  const userGroupsLocal = localStorage.getItem(localKeys.UserGroups)
  if (userGroupsLocal !== null) {
    const userGroupsValue = userGroupsLocal.split(",")
    vars.auth.setUserGroups(userGroupsValue)
  } // ???

  const organisationIdLocal = localStorage.getItem(localKeys.OrganisationId)
  if (organisationIdLocal !== null) {
    vars.auth.setOrganisationId(organisationIdLocal)
  }
  const organisationNameLocal = localStorage.getItem(localKeys.OrganisationName)
  if (organisationNameLocal !== null) {
    data.org.setOrganisationName(organisationNameLocal)
  }
  const accessLevelLocal = localStorage.getItem(localKeys.AccessLevel)
  if(accessLevelLocal !== null) {
    const maybeAccessLevel = stringToEnum(AccessLevel, accessLevelLocal)
    if(maybeAccessLevel === null) {
      // console.log("Found invalid AccessLevel in localStorage")
    } else {
      vars.auth.accessLevel[1](maybeAccessLevel)
    }
  }

  const fromTimeLocal = localStorage.getItem(localKeys.FromTime)
  if (fromTimeLocal !== null) {
    view.energyOverall.setFromTime(stringToDate(fromTimeLocal))
  }
  const toTimeLocal = localStorage.getItem(localKeys.ToTime)
  if (toTimeLocal !== null) {
    view.energyOverall.setToTime(stringToDate(toTimeLocal))
  }
  const vesselLocal = localStorage.getItem(localKeys.Vessel)
  if (vesselLocal !== null) {
    view.energyOverall.setVessel(vesselLocal)
  }
  const aggregationLocal = localStorage.getItem(localKeys.Aggregation)
  if (aggregationLocal !== null) {
    const maybeAgg = stringToAggregation(aggregationLocal)
    if (maybeAgg === null) {
      // Local value does not represent a valid Aggregation
      // console.log("Found invalid aggregation in localStorage")
    } else {
      // Local value represents a valid aggregation
      view.energyOverall.setAggregation(maybeAgg)
      // console.log("Got aggr enum: ", maybeAgg)
    }
    // view.setAggregation(aggregation)
    // console.log("Got aggr enum: ", aggregation)
  }
  const metricLocal = localStorage.getItem(localKeys.Metric)
  if (metricLocal !== null) {
    const maybeMetric = stringToOperatorTabMetric(metricLocal)
    if(maybeMetric === null) {
      // console.log("Found invalid metric in localStorage")
    } else {
      view.energyOverall.setMetric(maybeMetric)
    }
  }
  const activityLocal = localStorage.getItem(localKeys.Activity)
  if (activityLocal !== null) {
    const maybeActivity = stringToVesselActivity(activityLocal)
    if(maybeActivity === null) {
      // console.log("Found invalid activity in localStorage")
    } else {
      view.energyOverall.setActivity(maybeActivity)
    }
  }
  const timescaleLocal = localStorage.getItem(localKeys.Timescale)
  if (timescaleLocal !== null) {
    const maybeTimescale = stringToTimescale(timescaleLocal)
    if(maybeTimescale === null) {
      // console.log("Found invalid timescale in localStorage")
    } else {
      view.energyOverall.setTimescale(maybeTimescale)
    }
  }
  const timePeriodLocal = localStorage.getItem(localKeys.TimePeriod)
  if (timePeriodLocal !== null) {
    view.energyOverall.setTimePeriod(timePeriodLocal)
  }

  const energyOperatorVesselsLocal = localStorage.getItem(localKeys.EnergyOperatorVessels)
  if (energyOperatorVesselsLocal !== null) {
    let energyOperatorVessels: string[]
    if(energyOperatorVesselsLocal.length === 0) {
      energyOperatorVessels = []
    } else {
      energyOperatorVessels = energyOperatorVesselsLocal.split(",")
    }
    // console.log("Found local energy operators vessels: (", energyOperatorVesselsLocal, ") (", energyOperatorVessels, ")", energyOperatorVesselsLocal.length)
    view.energyOperator.setVessels(energyOperatorVessels)
  }
  const energyOperatorOperatorsLocal = localStorage.getItem(localKeys.EnergyOperatorOperators)
  if (energyOperatorOperatorsLocal !== null) {
    let energyOperatorOperators: string[]
    if(energyOperatorOperatorsLocal.length === 0) {
      energyOperatorOperators = []
    } else {
      energyOperatorOperators = energyOperatorOperatorsLocal.split(",")
    }
    // console.log("Found local energy operators operators: (", energyOperatorOperatorsLocal, ") (", energyOperatorOperators, ")", energyOperatorOperatorsLocal.length)
    view.energyOperator.setOperators(energyOperatorOperators)
  }
  const energyOperatorMetricLocal = localStorage.getItem(localKeys.EnergyOperatorMetric)
  if (energyOperatorMetricLocal !== null) {
    const maybeEnergyOperatorMetric = stringToOperatorTabMetric(energyOperatorMetricLocal)
    if (maybeEnergyOperatorMetric === null) {
      // console.log("Found invalid EnergyOperatorMetric in localStorage")
    } else {
      view.energyOperator.setMetric(maybeEnergyOperatorMetric)
    }
  }
  const energyOperatorActivitiesLocal = localStorage.getItem(localKeys.EnergyOperatorActivities)
  if (energyOperatorActivitiesLocal !== null) {
    const maybeEnergyOperatorActivities = stringToVesselActivities(energyOperatorActivitiesLocal)
    if (maybeEnergyOperatorActivities === null) {
      // console.log("Found invalid EnergyOperatorActivities in localStorage")
    } else {
      view.energyOperator.setActivities(maybeEnergyOperatorActivities)
    }
  }
  const energyOperatorFromTimeLocal = localStorage.getItem(localKeys.EnergyOperatorFromTime)
  if (energyOperatorFromTimeLocal !== null) {
    const maybeEnergyOperatorFromTime = stringToDate(energyOperatorFromTimeLocal)
    if (maybeEnergyOperatorFromTime === null) {
      // console.log("Found invalid EnergyOperatorFromTime in localStorage")
    } else {
      view.energyOperator.setFromTime(maybeEnergyOperatorFromTime)
    }
  }
  const energyOperatorToTimeLocal = localStorage.getItem(localKeys.EnergyOperatorToTime)
  if (energyOperatorToTimeLocal !== null) {
    const maybeEnergyOperatorToTime = stringToDate(energyOperatorToTimeLocal)
    if (maybeEnergyOperatorToTime === null) {
      // console.log("Found invalid EnergyOperatorToTime in localStorage")
    } else {
      view.energyOperator.setToTime(maybeEnergyOperatorToTime)
    }
  }
  const energyOperatorTimescaleLocal = localStorage.getItem(localKeys.EnergyOperatorTimescale)
  if (energyOperatorTimescaleLocal !== null) {
    const maybeEnergyOperatorTimescale = stringToTimescale(energyOperatorTimescaleLocal)
    if (maybeEnergyOperatorTimescale === null) {
      // console.log("Found invalid EnergyOperatorTimescale in localStorage")
    } else {
      view.energyOperator.setTimescale(maybeEnergyOperatorTimescale)
    }
  }
  const energyOperatorTimePeriodLocal = localStorage.getItem(localKeys.EnergyOperatorTimePeriod)
  if (energyOperatorTimePeriodLocal !== null) {
    view.energyOperator.setTimePeriod(energyOperatorTimePeriodLocal)
  }
  const energyOperatorAggregationLocal = localStorage.getItem(localKeys.EnergyOperatorAggregation)
  if (energyOperatorAggregationLocal !== null) {
    const maybeEnergyOperatorAggregation = stringToAggregation(energyOperatorAggregationLocal)
    if (maybeEnergyOperatorAggregation === null) {
      // console.log("Found invalid EnergyOperatorAggregation in localStorage")
    } else {
      view.energyOperator.setAggregation(maybeEnergyOperatorAggregation)
    }
  }
  
  // console.log("Got locals")
}
