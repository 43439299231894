interface TabProps {
  title: string,
  content: JSX.Element
}

export default function Tab(props: TabProps) {
  return (
    <div id={"tabComponent-"+props.title} className="flex flex-grow h-full w-full flex-col space-y-4 min-w-fit px-7 pb-7"> 
      {props.content}
    </div>
  )
}
