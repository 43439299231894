import React from "react"
import { useAppContext } from "../../context/variables"
import TabPage from "../TabPage"
import { AccessLevel, accessLevelAtMinimum } from "../../context/types"
import { environmentConfig } from "../../env/env"

export default function AdministrationPage() {
  const { vars } = useAppContext()
  const pageCfg = environmentConfig.features.administration
  if(pageCfg === false) return <></>
  const adminOrAbove = accessLevelAtMinimum(vars.auth.accessLevel[0], AccessLevel.Admin)
  const tabs = [
    { name: "Destinations", path: "/administration/destinations", visible: pageCfg.destinations },
    { name: "Geo Zones", path: "/administration/geo-zones", visible: adminOrAbove && pageCfg.geozones },
    { name: "Users", path: "/administration/users", visible: adminOrAbove && pageCfg.users },
    { name: "Operation Modes", path: "/administration/operation-mode", visible: adminOrAbove && pageCfg.operation_modes },
    { name: "Vessel Groups", path: "/administration/vessel-groups", visible: adminOrAbove && pageCfg.vessel_groups },
    { name: "Tag types", path: "/administration/tag-types", visible: adminOrAbove && pageCfg.tag_types },
    // { name: "Routes", path: "/administration/routes", visible: adminOrAbove && pageCfg. },
    { name: "Voyage Trips", path: "/administration/trips", visible: adminOrAbove && pageCfg.voyage_trips },
    // { name: "Vessels", path: "/administration/vessels", visible: pageCfg. },
  ]
  return (
    <TabPage title="Administration" tabs={tabs} />
  )
}
