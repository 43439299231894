import { environmentConfig } from "../../env/env";
import TabPage from "../TabPage";

export default function LogbookPage() {
  const pageCfg = environmentConfig.features.logbook
  if(pageCfg === false) return <></>
  const tabs = [
    { name: "Trips", path: "/logbook/trips", visible: pageCfg.trips },
    { name: "Tags", path: "/logbook/tags", visible: pageCfg.tags },
  ]
  return <TabPage title="Logbook" tabs={tabs}/>
}
