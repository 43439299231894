import { envConfig } from "../env";
import {
  keycloakInit,
  keycloakLogin,
  keycloakLogout,
} from "../../auth/keycloak";
import Keycloak from "keycloak-js";

// -- SFV production environment config

const sfvKeycloakClient = new Keycloak({
  url: "https://sfv.auth.cetasol.com/",
  realm: "users-v1",
  clientId: "client-users-v1",
});

export const productionSfvConfig: envConfig = {
  auth: {
    apiUrl: "https://sfv.api.cetasol.com/",
    loginPageFields: false,
    forgotPwLink: "",
    initFunction: (vars, navigate) =>
      keycloakInit(sfvKeycloakClient, vars, navigate),
    loginFunction: (vars, navigate, theUsername, thePassword) =>
      keycloakLogin(sfvKeycloakClient),
    logoutFunction: () => keycloakLogout(sfvKeycloakClient),
    getApiAuthorization: (vars) =>
      vars.auth.accessToken !== null ? "Bearer " + vars.auth.accessToken : null,
  },
  features: {
    overview: {
      fuel_total_this_week_card: false,
      vessels_card: true,
      user_details_card: true,
      custom_alarms_panel: false,
    },
    realtime: true,
    energy: {
      overall: true,
      operators: false,
    },
    performance: true,
    reports: true,
    logbook: {
      trips: true,
      tags: true,
    },
    administration: {
      destinations: true,
      users: false,
      geozones: true,
      operation_modes: true,
      vessel_groups: false,
      tag_types: true,
      voyage_trips: true,
    },
    customAlarms: false,
  },
};
