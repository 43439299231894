import TabPage from "./TabPage"

export default function RootPage() {
  const tabs = [
    { name: "Organisations", path: "/root/organisations", visible: true },
    { name: "Vessels", path: "/root/vessels", visible: true },
    { name: "Health Monitoring", path: "/root/status", visible: true }, 
    // { name: "Destinations", path: "/root/destinations", visible: true },
  ]
  return <TabPage title="Root access" tabs={tabs}/>
}
