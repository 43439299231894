import { useEffect, useState } from "react";
import Tab from "../../../tabs/Tab";
import useFetchData from "../../../apiComms/fetchData";
import { destinationEntry, useAppContext } from "../../../context/variables";
import { addButton } from "../../../components/Button";
import { latToPrettyString, lngToPrettyString } from "../../../components/Map";
import { EntityState } from "../../../context/types";
import { strNullToString } from "../../../helpers/stringHelpers";
import {
  genericTableRelatedBits,
  genericTableSelectableEditable,
} from "../../../components/Table";
import { AddDestModal } from "./AddDestModal";
import { MainDestsMap } from "./MainDestsMap";
import { ManageDestModal } from "./ManageDestModal";
import EmptyState from "../../../components/atoms/EmptyState";
import NoGPSIcon from "../../../assets/icons/EmptyStates/NoGPSIcon";

function clickHandler(e: any, chooseDest: (dest: string | null) => void) {
  const elementId: string = e.target.id;
  if (
    elementId === "tabPageContainer-Administration" ||
    elementId === "tabPageContent-Administration" ||
    elementId === "tabComponent-Destinations" ||
    elementId === "DestinationsContent" ||
    elementId === "DestinationsTable" ||
    elementId === "DestinationsTableHeaders"
  ) {
    chooseDest(null);
  }
}

function DestsTable2(
  chosenDest: string | null,
  chooseDest: (id: string | null) => void,
  destinationList: destinationEntry[] | null,
  setManagedDestId: (id: string | null) => void,
  setManageDestModalOpen: (open: boolean) => void,
  setAddDestModalOpen: (open: boolean) => void
) {
  const { data, vars } = useAppContext();
  const orgsList = data.org.organisationList;
  const organisationId = vars.auth.organisationId;
  const isRoot =
    vars.auth.userGroups !== null && vars.auth.userGroups.includes("root");
  const showExtraColumns = organisationId === null && isRoot;
  const destRows =
    destinationList === null
      ? null
      : destinationList.map((dest) => {
          const lid = dest.lid;
          const org_uid = dest.orgUid;
          const orgMaybe = orgsList?.find((org) => org.id === dest.orgUid);
          const orgName = orgMaybe === undefined ? "N/A" : orgMaybe.name;
          const display_name = strNullToString(dest.displayName);
          const latitude = latToPrettyString(dest.latitude);
          const longitude = lngToPrettyString(dest.longitude);
          const radiusStr =
            dest.radius === null
              ? null
              : "Circle (radius: " + dest.radius + " m)";
          const polyStr =
            dest.polygon === null
              ? null
              : "Polygon (" + dest.polygon.length.toString() + " corners)";
          const shapeStr = polyStr ? polyStr : radiusStr ? radiusStr : "N/A";
          const state = dest.state;
          const metadata = strNullToString(dest.metadata);
          const contents = showExtraColumns
            ? [
                display_name,
                latitude + ", " + longitude,
                shapeStr,
                state,
                lid,
                org_uid + " (" + orgName + ")",
                metadata,
              ]
            : [display_name, latitude + ", " + longitude, shapeStr, state];
          return { id: lid, contents: contents };
        });
  const columnNames = showExtraColumns
    ? [
        "Name",
        "Coordinates",
        "Edge shape",
        "State",
        "lid",
        "org uid (org name)",
        "metadata",
      ]
    : ["Name", "Coordinates", "Edge shape", "State"];

  return destinationList?.length === 0 ? (
    <EmptyState
      icon={<NoGPSIcon />}
      title="No destinations have been added"
      description="When you add a destination, it will appear here"
      buttonText="Add destination"
      onButtonClick={() => setAddDestModalOpen(true)}
    />
  ) : (
    <div className="rounded-xl">
      {genericTableSelectableEditable(
        columnNames,
        destRows,
        chosenDest,
        chooseDest,
        (id: string) => {
          setManagedDestId(id);
          setManageDestModalOpen(true);
        }
      )}
    </div>
  );
}

export default function DestinationsTab() {
  const { data, vars } = useAppContext();
  const { fetchDataDestinationsTab, fetchDataOrganisations } = useFetchData();

  useEffect(() => {
    fetchDataOrganisations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.idToken, vars.auth.organisationId]);
  useEffect(() => {
    fetchDataDestinationsTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.organisationId, vars.auth.userGroups]);

  useEffect(() => {
    window.addEventListener("click", (e) => clickHandler(e, chooseDest));
    window.removeEventListener(
      "click",
      (e) => clickHandler(e, chooseDest),
      true
    );
  }, []);

  const [chosenDest, setChosenDest] = useState<string | null>(null);
  function chooseDest(newDest: string | null) {
    setChosenDest(newDest);
  }

  const organisationId = vars.auth.organisationId;
  const isRoot =
    vars.auth.userGroups !== null && vars.auth.userGroups.includes("root");
  const showHiddenDests = organisationId === null && isRoot;
  const destinationList = data.org.destinationList.val;
  const destinationListFiltered =
    destinationList === null
      ? null
      : showHiddenDests
      ? destinationList
      : destinationList.filter(
          (entry) =>
            entry.state !== EntityState.Rejected &&
            entry.state !== EntityState.Removed
        );

  const [addDestModalOpen, setAddDestModalOpen] = useState(false);
  const [manageDestModalOpen, setManageDestModalOpen] = useState(false);
  const [managedDestId, setManagedDestId] = useState<string | null>(null);

  const destinations_content = (
    <div
      id="DestinationsContent"
      className="h-full flex flex-col space-y-3 text-deep_blue"
    >
      {MainDestsMap(
        data,
        vars,
        chosenDest,
        chooseDest,
        destinationListFiltered
      )}
      {addButton("Add new destination", () => setAddDestModalOpen(true))}
      {genericTableRelatedBits(
        "Destinations",
        destinationListFiltered?.length,
        fetchDataDestinationsTab
      )}
      {DestsTable2(
        chosenDest,
        chooseDest,
        destinationListFiltered,
        setManagedDestId,
        setManageDestModalOpen,
        setAddDestModalOpen
      )}
      {AddDestModal(
        addDestModalOpen,
        setAddDestModalOpen,
        destinationListFiltered
      )}
      {ManageDestModal(
        manageDestModalOpen,
        setManageDestModalOpen,
        managedDestId,
        setManagedDestId
      )}
    </div>
  );
  return <Tab title="Destinations" content={destinations_content} />;
}
