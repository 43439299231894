import styled from "styled-components";

export const DropdownContainer = styled.div<{ width?: string }>`
  position: relative;
  width: ${({ width }) => width || "100%"};
  height: 60px;
`;

export const DropdownButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !["disabled", "isPlaceholder"].includes(prop),
})<{ disabled?: boolean; isPlaceholder?: boolean }>`
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  background: ${({ disabled }) => (disabled ? "#e6e6e6" : "#fff")};
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: ${({ isPlaceholder }) => (isPlaceholder ? "#a8a8a8" : "#143452")};
  box-sizing: border-box;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z" fill="%236D9CBF"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;

  &:focus {
    outline: none;
    border-color: #143452;
  }
`;

export const DropdownList = styled.ul`
  position: absolute;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #d6d6d6;
  border-top: none;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 10px 10px;
  z-index: 1000;
  overflow: hidden;
`;

export const DropdownItem = styled.li<{ selected: boolean }>`
  padding: 15px;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: #143452;
  background: ${({ selected }) => (selected ? "#F3F9FD" : "#FFFFFF")};
  cursor: pointer;
  border-bottom: 1px solid #d6d6d6;

  &:hover {
    background: #f3f9fd;
  }

  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;
