import { Tooltip } from "@mui/material";
import { AiOutlinePlus } from "react-icons/ai";
import { FaPaperPlane, FaTrashAlt } from "react-icons/fa";
import { FaRedo } from "react-icons/fa";

interface buttonProps {
  text: string;
  action: () => void;
}

export function Button(props: buttonProps) {
  const button = (
    <button
      className="w-[370px] h-[84px] bg-deep_blue hover:bg-deep_blue/75 font-bold text-2xl text-white rounded-lg"
      type="submit"
      onClick={props.action}
      aria-label={props.text}
    >
      {props.text}
    </button>
  );
  return button;
}

export function iconButton(
  text: string,
  icon: JSX.Element,
  action: () => void,
  key?: string,
  orangeBg = false
) {
  const bgColor = orangeBg ? "bg-high_orange" : "bg-deep_blue";
  const iconButtonComponent = (
    <div
      key={key}
      className={`${bgColor} hover:opacity-80 cursor-pointer rounded-lg h-14 px-8 text-white font-bold flex justify-center items-center space-x-3`}
      onClick={action}
      role="button"
      aria-label={text}
    >
      {icon}
      <div>{text}</div>
    </div>
  );
  return iconButtonComponent;
}

export function makeSubmitButton(onClick: () => void) {
  return (
    <div
      className="bg-deep_blue hover:opacity-80 cursor-pointer rounded-lg w-48 h-14 text-white font-bold flex justify-center items-center space-x-3"
      onClick={onClick}
      role="button"
      aria-label="Submit"
    >
      <FaPaperPlane className="text-xl" />
      <div>{"Submit"}</div>
    </div>
  );
}

export function addButton(text: string, action: () => void) {
  return (
    <div
      className="bg-deep_blue hover:opacity-80 cursor-pointer rounded-xl w-72 h-14 text-white font-bold flex justify-center items-center space-x-4 shadow-tray"
      onClick={action}
      role="button"
      aria-label={text}
    >
      <AiOutlinePlus className="text-3xl" />
      <div>{text}</div>
    </div>
  );
}

export function deleteAllButton(text: string, action: () => void) {
  return (
    <div
      className="bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-4 cursor-pointer"
      onClick={action}
      role="button"
      aria-label={text}
    >
      <FaTrashAlt className="text-white text-2xl mr-2" />
      <div className="text-white">{text}</div>
    </div>
  );
}

export function makeReloadListButton(onClick: () => void, loading = false) {
  return (
    <Tooltip title={"Reload list"} placement="right">
      <div
        role="button"
        className="border-2 border-deep_blue hover:bg-deep_blue cursor-pointer rounded-md w-10 h-10 text-deep_blue hover:text-white font-bold flex justify-center items-center space-x-4 shadow-mini"
        onClick={onClick}
      >
        <FaRedo className={`text-xl ${loading ? "animate-spin-slow" : ""}`} />
      </div>
    </Tooltip>
  );
}

export function inputButton(
  text: string,
  action: () => void,
  icon?: JSX.Element,
  key?: string
) {
  const iconButtonComponent = (
    <div
      key={key}
      className={`bg-deep_blue border-deep_blue border-[1px] hover:opacity-80 cursor-pointer rounded-lg h-14 px-4 text-white text-base font-semibold flex justify-center items-center space-x-3`}
      onClick={action}
      role="button"
      aria-label={text}
    >
      {icon}
      <div>{text}</div>
    </div>
  );
  return iconButtonComponent;
}
