import { useAppContext } from "../../../../context/variables"
import { makeConfirmModal } from "../../../../components/Modal"
import useManageData from "../../../../apiComms/manageData"

export default function RemoveUserModal(open: boolean, setOpen: (open: boolean)=>void, managedUserId: string|null, onCloseManageUserModal: ()=>void) {
  const { data } = useAppContext()
  const { apiUserRemove } = useManageData()
  const theUserId = (managedUserId !== null) ? managedUserId : "N/A"
  const user = data.org.userList?.find((user)=>user.username === managedUserId)
  const userName = user?.name
  function onConfirmClick() {
    apiUserRemove(theUserId)
    setOpen(false)
    onCloseManageUserModal()
  }
  return makeConfirmModal("Confirm removing user", 'You are about to remove the user "'+userName+'" ('+theUserId+')', "Remove user", onConfirmClick, open, setOpen, true)
}
