import styled from 'styled-components';

export const StyledButtonEmptyState = styled.button`
  width: 290px;
  height: 55px;
  margin: 20px; 
  padding: 10px 20px; 
  background-color: #8AC1EA;
  color: #000000; 
  border: none;
  border-radius: 27.5px;
  font-size: 18px; 
  font-weight: 500;
  font-family: "Montserrat", sans-serif; 
  text-align: center; 
  line-height: normal; 
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease; 

  &:hover {
    background-color: #7BB0D8;
    transform: translateY(-2px);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(138, 193, 234, 0.5);
  }

  &:active {
    background-color: #6CA0C6;
    transform: translateY(0);
  }
`;
