import React, { useEffect, useState, useRef } from 'react';
import useFetchData from '../../apiComms/fetchData';
import { useAppContext } from '../../context/variables';
import { DropdownContainer, DropdownButton, DropdownList, DropdownItem } from '../styles/DropdownStyles';

interface GeoZoneListDropdownProps {
  value: string;
  onChange: (value: string) => void;
  width?: string;
  placeholder?: string;
}

const GeoZoneListDropdown: React.FC<GeoZoneListDropdownProps> = ({ value, onChange, width, placeholder }) => {
  const [geoZoneOptions, setGeoZoneOptions] = useState<Array<{ value: string; label: string }>>([]);
  const { data, vars } = useAppContext();
  const { fetchDataGeoZonesTab } = useFetchData();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchDataGeoZonesTab();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.organisationId, vars.auth.userGroups]);

  useEffect(() => {
    if (data.org.geoZoneList?.val) {
      const options = [
        { value: 'None', label: 'None' },
        ...data.org.geoZoneList.val.map((zone: any) => ({
          value: zone.gz_id,
          label: zone.name,
        })),
      ];
      setGeoZoneOptions(options);
    }
  }, [data.org.geoZoneList]);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleOptionClick = (optionValue: string) => {
    onChange(optionValue);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const selectedLabel = value === '' ? placeholder || 'Select an option' : geoZoneOptions.find(option => option.value === value)?.label || placeholder || 'Select an option';

  return (
    <DropdownContainer ref={dropdownRef} width={width}>
      <DropdownButton isPlaceholder={value === ''} onClick={handleToggle}>
        {selectedLabel}
      </DropdownButton>
      {isOpen && (
        <DropdownList>
          {geoZoneOptions.map(option => (
            <DropdownItem
              key={option.value}
              selected={option.value === value}
              onClick={() => handleOptionClick(option.value)}
            >
              {option.label}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default GeoZoneListDropdown;
