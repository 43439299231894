
import { useAppContext } from "../../context/variables"
import { Bar, BarChart, CartesianGrid, Label, Tooltip as RechartsTooltip, XAxis, YAxis } from "recharts"
import { fillGrayIfNoData, graphLoadOrNoDataNotice } from "../../helpers/graphHelpers"

export default function SogDistChart() {
  const { data } = useAppContext()
  const sogDistData = (data.performance.sogDistribution === null) ? undefined : data.performance.sogDistribution
  return <BarChart
    width={500}
    height={500}
    data={sogDistData}
    margin={{
      top: 20,
      right: 20,
      bottom: 20,
      left: 30,
    }}
  >
    <CartesianGrid strokeDasharray="3 3" fill={fillGrayIfNoData(sogDistData)}/>
    <XAxis dataKey="sogKn" interval={4} tickFormatter={(label) => {
        // console.log("formatting distr tt:", label, typeof label)
        const sogKnRounded = Math.round(parseFloat(label) * 1000)/1000
        return sogKnRounded.toString()
      }}>
      <Label
        value="Speed over ground (kn)"
        offset={0}
        position="bottom"
      />
    </XAxis>
    <YAxis unit={"h"}>
      <Label
        value="Time"
        offset={-10}
        position="left"
        angle={-90}
        style={{ textAnchor: 'middle' }}
      />
    </YAxis>
    <RechartsTooltip
      labelFormatter={(label) => {
        // console.log("formatting distr tt:", label, typeof label)
        const sogKnRounded = Math.round(parseFloat(label) * 1000)/1000
        return "Speed over ground: "+sogKnRounded+" kn"
      }}
      formatter={(value: string, name, props) => {
        const timeHoursRounded = Math.round(parseFloat(props.payload.timeHours) * 1000)/1000
        const timePercentRounded = Math.round(parseFloat(props.payload.timePercent) * 1000)/1000
        return [timeHoursRounded+" h ("+timePercentRounded+" %)", "Time"]
      }}
    />
    <Bar dataKey="timeHours" fill="#102840" name="Time" />
    {graphLoadOrNoDataNotice(sogDistData)}
  </BarChart>
}
