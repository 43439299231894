import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
  
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerWrapper = styled.div`
  display: inline-block;
  width: 2.625rem;
  height: 2.625rem;
  &:after {
    content: " ";
    display: block;
    width: 2rem;
    height: 2rem;
    margin: 0.5rem;
    border-radius: 100%;
    border-top: 3px solid rgba(20, 52, 82, 0.2);
    border-right: 3px solid rgba(20, 52, 82, 0.2);
    border-bottom: 3px solid rgba(20, 52, 82, 0.2);
    border-left: 3px solid rgba(20, 52, 82, 0.5);
    box-sizing: border-box;
    animation: ${spin} 1.2s linear infinite;
  }
`;
