import { setLocalAccessToken, setLocalUserGroups, setLocalUsername, setLocalOrganisationId, setLocalExp, setLocalIdToken, setLocalRefreshToken, setLocalIsOperator, setLocalIsAdmin, setLocalIsOwner, setLocalEmail, setLocalAccessLevel, localKeys } from "../context/locals";
import { AccessLevel } from "../context/types";
import { NavigateFunction } from "react-router-dom";
import { removeLocals } from "../context/locals";
import { apiData, logoutContext, variables, viewSettings } from "../context/variables";
import { environmentConfig} from "../env/env";

// ----- Accessing the current environment's data -----

const authConfig = environmentConfig.auth

export const theApiUrl = authConfig.apiUrl

export const authenticationLoginPageFields = authConfig.loginPageFields

export const authenticationForgotPwLink = authConfig.forgotPwLink

export const authenticationInit = authConfig.initFunction

export const authenticationLogin = authConfig.loginFunction

export function authenticationLogout(vars: variables, data: apiData, view: viewSettings, navigate: NavigateFunction) {
  console.log("Logging out")
  logoutContext(vars, data, view)
  removeLocals()
  authConfig.logoutFunction()
  // also clear token cookies later ???
  navigate("/login")
}

export const getApiAuthorization = authConfig.getApiAuthorization

// ----- General authentication functions -----

export function authenticationExpirationCheck(vars: variables, data: apiData, view: viewSettings, navigate: NavigateFunction) {
  // security-wise, it's not crucial to log people out after token expires since api won't give data for bad tokens
  // but it's less confusing for users to do so anyway
  // it should be enough to just check validity when it's convenient (on some clicks/loads) not neccessarily immediately
  // console.log("Running authenticationExpirationCheck")
  let expirationTime: number
  if (vars.auth.exp !== null) {
    expirationTime = vars.auth.exp
  } else {
    const expLocal = localStorage.getItem(localKeys.TokenExp)
    if (expLocal !== null) {
      expirationTime = parseInt(expLocal)
    } else {
      vars.login.setLoggedIn(false)
      // if(vars.login.loggedIn !== null) authenticationLogout(vars, data, view, navigate)
      return
    }
  }
  const currentTime: number = (new Date().getTime())/1000
  // console.log("Exp check current time: "+currentTime+", exp time: "+expirationTime+", will expire in "+(expirationTime-currentTime)+" secs")
  if (expirationTime <= currentTime){
    console.log("logging out because of authenticationExpirationCheck: expired token")
    authenticationLogout(vars, data, view, navigate)
  } else {
    vars.login.setLoggedIn(true)
  }
}

export function loginWithAuthData(
  vars: variables, 
  navigate: NavigateFunction,
  idToken: string,
  accessToken: string,
  refreshToken: string,
  username: string,
  email: string,
  isOperatorStr: string,
  isAdminStr: string,
  isOwnerStr: string,
  userGroups: string[],
  exp: number,
){
  // Updating login vars  in context
  vars.login.setLoggedIn(true)
  vars.login.setErrorCode(null)
  vars.login.setErrorMessage(null)

  // Updating context and locals - Tokens
  vars.auth.setIdToken(idToken)
  setLocalIdToken(idToken)
  vars.auth.setAccessToken(accessToken)
  setLocalAccessToken(accessToken)
  vars.auth.setRefreshToken(refreshToken)
  setLocalRefreshToken(refreshToken)

  // Updating context and locals - User info
  vars.auth.setUsername(username)
  setLocalUsername(username)
  vars.auth.setEmail(email)
  setLocalEmail(email)
  const isOperator = parseBooleanAttribute(isOperatorStr)
  vars.auth.setIsOperator(isOperator)
  setLocalIsOperator(isOperator)
  const isAdmin = parseBooleanAttribute(isAdminStr)
  vars.auth.setIsAdmin(isAdmin)
  setLocalIsAdmin(isAdmin)
  const isOwner = parseBooleanAttribute(isOwnerStr)
  vars.auth.setIsOwner(isOwner)
  setLocalIsOwner(isOwner)
  vars.auth.setUserGroups(userGroups)
  setLocalUserGroups(userGroups)
  const organisationId = extractOrgId(userGroups)
  vars.auth.setOrganisationId(organisationId)
  setLocalOrganisationId(organisationId)
  const accessLevel = extractAccessLevel(userGroups, isOwner, isAdmin)
  vars.auth.accessLevel[1](accessLevel)
  setLocalAccessLevel(accessLevel)
  vars.auth.setExp(exp)
  setLocalExp(exp)

  // Redirecting to overview page
  navigate("/overview")
}

// ----- Helper functions -----

export function parseBooleanAttribute(val: string) {
  return val === "true"
}

export function extractOrgId(groups: string[] | null | undefined) {
  if(groups === undefined || groups === null) return null
  // console.log("In extractOrgId, got:", groups)
  const groupName = groups.find((aGroupName: string)=>{
    // console.log("aGroupName:", aGroupName)
    const firstFour = aGroupName.substring(0, 4)
    // console.log("firstFour:", firstFour)
    return firstFour === 'org-'
  })
  if(groupName === undefined) {
    return null
  } else {
    return groupName.substring(4)
  }  
}

export function extractAccessLevel(userGroups: string[] | null, isOwner: boolean, isAdmin: boolean): AccessLevel {
  if(userGroups !== null && userGroups.includes("root")) return AccessLevel.Root
  if(isOwner) return AccessLevel.Owner
  if(isAdmin) return AccessLevel.Admin
  return AccessLevel.User
}
