import { NM_PER_KM } from "../context/constants";
import {
  Aggregation,
  OperatorTabMetric,
} from "../context/types";
import {
  apiData,
  realTimeData,
  vesselListEntryData,
} from "../context/variables";

export const metricBits = {
  [OperatorTabMetric.FuelVolume]: "total_fuel_l",
  [OperatorTabMetric.FuelOverDistance]: "avg_fuel_lpkm",
  [OperatorTabMetric.FuelOverTime]: "avg_fuel_lph",
  [OperatorTabMetric.TimeLoggedIn]: "total_time_h",
  [OperatorTabMetric.ElectricEnergy]: "total_elec_kwh",
  [OperatorTabMetric.ElectricOverTime]: "avg_elec_kw",
  [OperatorTabMetric.ElectricOverDistance]: "avg_elec_kwhpkm",
};

// const energyOperatorsMetricValMap = {
//   [OperatorTabMetric.FuelVolume] : "total_fuel_l",
//   [OperatorTabMetric.FuelOverTime] : "avg_fuel_lph",
//   [OperatorTabMetric.FuelOverDistance] : "avg_fuel_lpkm",
//   [OperatorTabMetric.TimeLoggedIn] : "total_time_h",
//   [OperatorTabMetric.ElectricEnergy] : "total_elec_kwh",
//   [OperatorTabMetric.ElectricOverTime] : "avg_elec_kw",
//   [OperatorTabMetric.ElectricOverDistance] : "avg_elec_kwhpkm",
// }

export const energyOperatorsMetricValueSetMap = {
  [OperatorTabMetric.FuelVolume]: ["total_fuel_l"],
  [OperatorTabMetric.FuelOverTime]: ["total_fuel_l", "total_time_h"],
  [OperatorTabMetric.FuelOverDistance]: ["total_fuel_l", "total_dist_km"],
  [OperatorTabMetric.TimeLoggedIn]: ["total_time_h"],
  [OperatorTabMetric.ElectricEnergy]: ["total_elec_kwh"],
  [OperatorTabMetric.ElectricOverTime]: ["total_elec_kwh", "total_time_h"],
  [OperatorTabMetric.ElectricOverDistance]: ["total_elec_kwh", "total_dist_km"],
};

export const energyOperatorsMetricCalculation = {
  [OperatorTabMetric.FuelVolume]: (sums: { [key: string]: number }) =>
    sums["total_fuel_l"],
  [OperatorTabMetric.FuelOverTime]: (sums: { [key: string]: number }) =>
    sums["total_fuel_l"] / sums["total_time_h"],
  [OperatorTabMetric.FuelOverDistance]: (sums: { [key: string]: number }) =>
    sums["total_fuel_l"] / (sums["total_dist_km"] * NM_PER_KM),
  [OperatorTabMetric.TimeLoggedIn]: (sums: { [key: string]: number }) =>
    sums["total_time_h"],
  [OperatorTabMetric.ElectricEnergy]: (sums: { [key: string]: number }) =>
    sums["total_elec_kwh"],
  [OperatorTabMetric.ElectricOverTime]: (sums: { [key: string]: number }) =>
    sums["total_elec_kwh"] / sums["total_time_h"],
  [OperatorTabMetric.ElectricOverDistance]: (sums: { [key: string]: number }) =>
    sums["total_elec_kwh"] / (sums["total_dist_km"] * NM_PER_KM),
};

export interface rawVesselEntry {
  uid: string;
  org_uid: string;
  name: string;
}

export function combineRealTimeData(
  newData: realTimeData,
  oldData: realTimeData | null,
): realTimeData {
  if(
    oldData === null
    || oldData.vessel !== newData.vessel
    || oldData.signal !== newData.signal
  ) return newData

  const newDataSig = newData.realTimePoints.filter(point => point.signalType === newData.signal)
  const oldDataSig = oldData.realTimePoints.filter(point => point.signalType === newData.signal)
  let allDataSig = newDataSig;
  oldDataSig.forEach((point) => {
    if (allDataSig.findIndex((p) => p.timestamp === point.timestamp) === -1) {
      allDataSig.push(point);
    }
  });
  allDataSig.sort((a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp));

  const combinedMaxAmountStored = Math.max(newData.maxAmountStored, oldData.maxAmountStored)

  return {
    realTimePoints: allDataSig,
    vessel: newData.vessel,
    signal: newData.signal,
    maxAmountStored: combinedMaxAmountStored,
  }
}

export function getAllVesselIds(entries: vesselListEntryData[]) {
  return entries.map((entry: vesselListEntryData) => {
    return entry.id;
  });
}

export function makeVesselList(vesselChoice: string, data: apiData) {
  let vessels;
  if (vesselChoice === "all_vessels") {
    vessels = getAllVesselIds(data.org.vesselList);
  } else {
    vessels = [vesselChoice];
  }
  return vessels;
}

export function estimateNumberOfBars(
  from: Date,
  to: Date,
  aggregation: Aggregation
) {
  const fromYear = from.getFullYear();
  const toYear = to.getFullYear();
  const yearDiff = toYear - fromYear;
  const fromMonth = from.getMonth();
  const toMonth = to.getMonth();
  const monthDiff = 12 * yearDiff + toMonth - fromMonth;
  const fromEpochMs = from.getTime();
  const toEpochMs = to.getTime();
  const msDiff = toEpochMs - fromEpochMs;
  const dayDiff = msDiff / (1000 * 60 * 60 * 24);
  // console.log("bar estimation:", fromYear, toYear, yearDiff, fromMonth, toMonth, monthDiff, fromEpochMs, toEpochMs, msDiff, dayDiff)
  if (aggregation === Aggregation.Year) {
    return yearDiff + 1;
  } else if (aggregation === Aggregation.Month) {
    return monthDiff + 1;
  } else if (aggregation === Aggregation.Day) {
    return dayDiff + 1;
  } else {
    // if(aggregation === Aggregation.Hour)
    return dayDiff * 24;
  }
}
