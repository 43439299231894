const UserIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="106" height="106" viewBox="0 0 106 106" fill="none">
    <path d="M99.7922 102.214C96.613 92.3019 90.3688 83.6553 81.96 77.521C73.5501 71.3866 63.4097 68.0811 53.0006 68.0811C42.5913 68.0811 32.4509 71.3866 24.0414 77.521C15.632 83.6553 9.38776 92.3019 6.20911 102.214H99.7922Z" stroke="#1F64E7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M53 68.143C70.6967 68.143 85.0431 53.797 85.0431 36.1003C85.0431 18.4036 70.6967 4.05762 53 4.05762C35.3033 4.05762 20.9573 18.4036 20.9573 36.1003C20.9573 53.797 35.3033 68.143 53 68.143Z" stroke="#1F64E7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M63.4634 48.498C63.4634 48.498 60.8047 53.7295 53.0004 53.7295C45.1962 53.7295 42.5375 48.498 42.5375 48.498" stroke="#1F64E7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M84.4623 29.9996C84.3904 30.0001 84.3192 30.0003 84.248 30.0003C74.9339 30.0003 66.5718 25.9254 60.8468 19.4612C55.1219 25.9256 46.7597 30.0005 37.4457 30.0005C32.0371 30.0005 26.9494 28.6264 22.5128 26.2082C26.6812 13.3526 38.7548 4.05762 52.9995 4.05762C68.61 4.05762 81.6132 15.2207 84.4623 29.9996Z" stroke="#1F64E7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M89 11.3271L103.544 11.4369M96.3271 4.00007L96.4368 18.5445L96.3271 4.00007Z" stroke="#1F64E7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default UserIcon;
