import styled from "styled-components";

export const StyledCheckbox = styled.div.withConfig({
  shouldForwardProp: (prop) => !["hasLabel"].includes(prop),
})<{ hasLabel?: boolean }>`
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    width: 1rem;
    height: 1rem;
    margin-right: ${({ hasLabel }) => (hasLabel ? "0.5rem" : 0)};
  }

  input[type="checkbox"]:checked {
    color: #fff;
    accent-color: #143452;
  }
`;
