import { AxiosResponse } from "axios";
import { Maybe, Vessel } from "../../../types";
import axiosInstance from "../../axiosInstance";
import { getErrorDetails, setAuthorizationHeader } from "../../helpers";
import { processOrgVesselList } from "./responseHandlers";
import { updateOrgVesselListContext } from "./contextHandlers";

export const getAllOrgVesselList = async (
  view: any,
  data: any,
  vars: any,
  navigate: any
): Promise<Maybe<Vessel[]>> => {
  const org = vars.auth.organisationId;
  if (org === null) {
    throw new Error("No organization found");
  }
  setAuthorizationHeader(vars, data, view, navigate, axiosInstance);

  try {
    const response: AxiosResponse = await axiosInstance.get(
      `vessels/org/${org}`
    );
    const orgVesselList = processOrgVesselList(response.data.vessels);
    updateOrgVesselListContext(orgVesselList, data);
    return orgVesselList;
  } catch (error) {
    getErrorDetails(error);
  }
};
