import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { AccessLevel, accessLevelAtMinimum } from "../../../context/types";
import { AddVoyageModalProps, useAppContext } from "../../../context/variables";
import useManageData from "../../../apiComms/manageData";
import { makeDropdownInput, makeOtherInput } from "../../../components/FormInputs";
import { makeSubmitButton } from "../../../components/Button";
import { makeModal } from "../../../components/Modal";
import { GeneralDateTimePicker } from "../../../components/timeInputs";
import { UtcNotice } from "../../../components/miscellaneous";

export const AddVoyageModal: React.FC<AddVoyageModalProps> = ({
  open,
  setOpen,
  fetchDataVoyages,
}) => {
  const { data, vars } = useAppContext();
  const { apiPostVoyagesAdd } = useManageData();

  const [vesselName, setVesselName] = useState("");

  const dateTime = new Date();
  dateTime.setSeconds(0);
  dateTime.setMilliseconds(0);

  const [endTime, setEndTime] = useState<Date | null>(dateTime);
  const [startTime, setStartTime] = useState<Date | null>(dateTime);

  const [startTimeWarning, setStartTimeWarning] = useState<string | null>(null);
  const [endTimeWarning, setEndTimeWarning] = useState<string | null>(null);
  const [dateTimeWarning, setDateTimeWarning] = useState<string | null>(null);
  const [state, setState] = useState("Pending");
  const [vesselNameWarning, setVesselNameWarning] = useState<string | null>(
    null
  );
  const [startTimeFormatted, setStartTimeFormatted] = useState("");
  const [endTimeFormatted, setEndTimeFormatted] = useState("");

  const clearWarnings = () => {
    setStartTime(null);
    setVesselNameWarning(null);
    setStartTimeWarning(null);
    setDateTimeWarning(null);
    setEndTimeWarning(null);
    setEndTime(null);
  };

  const onSubmitClick = async () => {
    clearWarnings();
    let hasError = false;
  
    if (!vesselName) {
      setVesselNameWarning("Vessel name is required.");
      hasError = true;
    }
  
    if (!startTime) {
      setStartTimeWarning("Start time is required.");
      hasError = true;
    }
  
    if (!endTime) {
      setEndTimeWarning("End time is required.");
      hasError = true;
    }
  
    if (!hasError && startTime && endTime) {
      try {
        await apiPostVoyagesAdd(
          vesselName,
          startTime.toISOString().slice(0, -1),
          endTime.toISOString().slice(0, -1),
          state
        );
        fetchDataVoyages();
        setOpen(false);
      } catch (error) {
        console.error("Error adding voyage:", error);
      }
    }
  };

  useEffect(() => {
    if (startTime) {
      setStartTimeFormatted(format(startTime, "yyyy-MM-dd HH:mm:ss"));
    }
    if (endTime) {
      setEndTimeFormatted(format(endTime, "yyyy-MM-dd HH:mm:ss"));
    }
  }, [startTime, endTime]);

  const vesselOptions = data.org.vesselList.map((vessel) => ({
    value: vessel.id,
    name: vessel.name,
  }));

  const handleVesselNameChange = (value: string | null) => {
    setVesselName(value || "");
    if (value) {
      setVesselNameWarning(null);
    }
  };

  const startTimePicker = (
    <div className="flex flex-col">
      {GeneralDateTimePicker(startTime, setStartTime)}
      {UtcNotice()}
    </div>
  );

  const endTimePicker = (
    <div className="flex flex-col">
      {GeneralDateTimePicker(endTime, setEndTime)}
      {UtcNotice()}
    </div>
  );

  const isRootUser = accessLevelAtMinimum(
    vars.auth.accessLevel[0],
    AccessLevel.Root
  );

  const content = (
    <div className="flex flex-col space-y-4">
      {makeDropdownInput(
        "Vessel Name",
        "Select the vessel name",
        "Select Vessel Name",
        vesselName,
        vesselOptions,
        handleVesselNameChange,
        vesselNameWarning
      )}

      {makeOtherInput(
        "Start Time",
        "Select the start time for the voyage",
        startTimePicker,
        null
      )}
      {makeOtherInput(
        "End Time",
        "Select the end time for the voyage",
        endTimePicker,
        null
      )}
      {isRootUser &&
        makeDropdownInput(
          "State",
          "Select the state of the voyage",
          "Select State",
          state,
          [
            { value: "Pending", name: "Pending" },
            { value: "Processed", name: "Processed" },
          ],
          setState,
          null
        )}
      {makeSubmitButton(onSubmitClick)}
    </div>
  );

  return makeModal("Add New Voyage", content, open, () => setOpen(false));
};
