import { useNavigate } from "react-router-dom";
import { setLocalOrganisationName } from "../context/locals";
import {
  accessLevelAtMinimum,
  AccessLevel,
  Aggregation,
  EntityState,
  OperatorTabMetric,
  RealTimeSignal,
  realTimeSignalMap,
  stringToEntityState,
  stringToTagScope,
  stringToTagValueType,
  Timescale,
  VesselActivity,
  TagValueType,
  TagScope,
} from "../context/types";
import {
  tagEvent,
  trip,
  rootStatusEntry,
  secondaryTripData,
  geoZonesEntry,
  dataIntegrityEntry,
  secondaryLegTripData,
  realTimeData,
  AlarmEvent,
  AlarmType,
  SignalType,
} from "../context/variables";
import {
  reportEntry,
  rootDestsEntry,
  tagType,
  useAppContext,
} from "../context/variables";
import { dateToString, findTimeBounds } from "../helpers/dateTimeHelpers";
import { apiCallGet } from "./call";
import {
  activitiesAtdock,
  activitiesSailing,
  makeEnergyOverallUrlGraph,
  makeEnergyOverallUrlTotal,
} from "./url";
import {
  combineRealTimeData,
  energyOperatorsMetricValueSetMap,
  estimateNumberOfBars,
  getAllVesselIds,
  makeVesselList,
  rawVesselEntry,
} from "./helpers";
import {
  apiConverterDestList,
  apiConverterTotalEngineDieselRateSog,
  apiConverterTotalEnginePowerSog,
  apiConverterGraph,
  apiConverterRealTimeData,
  apiConverterReportList,
  apiConverterUserEntry,
  apiConverterSogDist,
  apiConverterVesselList,
  apiEnergyOperatorsConverter,
  apiConverterEngineSpeedTorqueMaps,
} from "./converters";
import {
  getApiAuthorization,
  theApiUrl,
  authenticationExpirationCheck,
} from "../auth/authentication";
import {
  RealTimeFetchMode,
  realTimeFetchMap,
} from "../pages/realTime/RealTimePage";
import { useCallback } from "react";

export default function useFetchData() {
  const { view, data, vars } = useAppContext();
  const navigate = useNavigate();
  const authorization = getApiAuthorization(vars);

  function apiGet(
    url: string | null,
    handler: (responseJson: any, response: Response) => any
  ): void {
    apiCallGet(vars, data, view, navigate, url, handler);
  }

  const fetchDataOrganisations = () => {
    if (!vars.auth.userGroups?.includes("root")) {
      // console.log("it doesn't have root")
      fetchOrganisationName();
      fetchOrgVesselList();
      fetchOrgUsers();
    } else {
      // console.log("it has root")
      fetchOrganisationList();
      if (vars.auth.organisationId === null) {
        // console.log("org Id is null")
        fetchAllVesselList();
        fetchAllUsers();
      } else {
        fetchOrganisationName();
        fetchOrgVesselList();
        fetchOrgUsers();
      }
    }
    // What can I get for the overview?
    // their name/email, organisation: personalized
    // vessel names
    // some total from vessel-data?
    //
  };
  const fetchDataOverviewPageTotal = () => {
    const theDate = new Date();
    const { fromTime: theFromTime, toTime: theToTime } = findTimeBounds(
      theDate,
      Timescale.Week
    );
    const from = dateToString(theFromTime, false); //dateToString(new Date((new Date()).getTime() - (7*24*60*60*1000)), true) //"2020-07-10"
    const to = dateToString(theToTime, false); //dateToString(new Date(), true) //"2022-05-10"
    const vessels = getAllVesselIds(data.org.vesselList);
    const metric = OperatorTabMetric.FuelVolume;
    const activity = VesselActivity.Total;
    // console.log("For overview fetch, the metric is:", metric)
    // console.log("The vessels in ovf: ",vessels)
    if (vessels.length < 1) {
      data.energy.overall.setOverviewTotalWeek(null);
      return;
    }
    fetchOverviewTotalWeek(from, to, vessels, metric, activity);
    //fetchCustomAlarmTypes();
  };
  const fetchOverviewTotalWeek = (
    fromString: string,
    toString: string,
    vessels: string[],
    metric: OperatorTabMetric,
    activity: VesselActivity
  ) => {
    const org = vars.auth.organisationId;
    const weekUrl = makeEnergyOverallUrlTotal(
      org,
      fromString,
      toString,
      vessels,
      metric,
      activity
    );
    // console.log("going to fetch for overview:", weekUrl)
    const weekHandler = (responseJson: any) => {
      // console.log("response from fetching for overview:", responseJson)
      const returnData = responseJson.rows;
      // const columnList = responseJson.columns
      const overviewTotalWeek = returnData[0][0];
      // console.log("overviewTotalWeek:", returnData, overviewTotalWeek)
      data.energy.overall.setOverviewTotalWeek(overviewTotalWeek);
    };

    apiGet(weekUrl, weekHandler);
  };
  const fetchTotalFuelUsage = (
    fromString: string,
    toString: string,
    vessels: string[],
    metric: OperatorTabMetric,
    activity: VesselActivity
  ) => {
    const org = vars.auth.organisationId;
    const totalFuelUrl = makeEnergyOverallUrlTotal(
      org,
      fromString,
      toString,
      vessels,
      metric,
      activity
    );
    // console.log("Going to fetch total fuel overall:", totalFuelUrl)
    const totalFuelHandler = (responseJson: any) => {
      // console.log("totalFuelUsage response:", responseJson)
      const returnData = responseJson.rows;
      // const columnList = responseJson.columns
      // console.log("returnData:", returnData)
      // returnData[]
      // console.log("handling total response")
      const totalFuelUsage = returnData[0][0];
      // console.log("got back: "+totalFuelUsage)
      data.energy.overall.setTotalFuelUsage(totalFuelUsage);
    };
    apiGet(totalFuelUrl, totalFuelHandler);
  };
  const fetchGraphfuelUsage = (
    fromString: string,
    toString: string,
    vessels: string[],
    aggregation: Aggregation,
    metric: OperatorTabMetric,
    activity: VesselActivity
  ) => {
    const org = vars.auth.organisationId;
    data.energy.overall.setGraphFuelUsage(null);
    const graphFuelUrl = makeEnergyOverallUrlGraph(
      org,
      fromString,
      toString,
      vessels,
      aggregation,
      metric,
      activity
    );
    // console.log("Going to fetch graphFuelUsage:", graphFuelUrl)
    const graphFuelHandler = (responseJson: any) => {
      // console.log("response for overall graph:", responseJson)
      const returnData = responseJson.rows;
      const columnList = responseJson.columns;
      // console.log("handling graph response", returnData, columnList)
      const convertedData = returnData
        .map((data: any[]) =>
          apiConverterGraph(data, columnList, metric, aggregation)
        )
        .filter(
          (conv: { value: number | null; timestamp: string } | null) =>
            conv !== null
        );
      // console.log("graphFuelUsage convertedData: ", convertedData)
      let graphFuelUsage;
      if (convertedData[0] !== null) {
        // console.log("new batch: ", convertedData)
        graphFuelUsage = convertedData;
      } else {
        graphFuelUsage = [];
      }
      data.energy.overall.setGraphFuelUsage(graphFuelUsage);
    };
    apiGet(graphFuelUrl, graphFuelHandler);
  };
  // const fetchTotalDockFuelUsage = (fromString: string, toString: string, vessels: string[]) => {
  //   const totalDockFuelUrl = makeUrlTotalDock(fromString, toString, vessels)
  //   const totalDockFuelHandler = (responseJson: any) => {
  //     const returnData = responseJson.data
  //     const totalDockFuelUsage = returnData[0][0]
  //     // console.log("got back: "+totalDockFuelUsage)
  //     data.setTotalDockFuelUsage(totalDockFuelUsage)
  //   }
  //   apiGet(totalDockFuelUrl, totalDockFuelHandler)
  // }
  // const fetchGraphDockFuelUsage = (fromString: string, toString: string, vessels: string[], aggregation: Aggregation) => {
  //   const graphDockFuelUrl = makeUrlGraphDock(fromString, toString, vessels, aggregation)
  //   const graphDockFuelHandler = (responseJson: any) => {
  //     // console.log("logging responseJson: ", responseJson)
  //     const returnData = responseJson.data
  //     const columnList = responseJson.columns
  //     const convertedData = returnData.map((data: any[]) => apiConverterGraph(data, columnList))
  //     if (convertedData[0] !== null) {
  //       // console.log("new batch: ", convertedData)
  //       data.setGraphDockFuelUsage(convertedData)
  //     } else {
  //       data.setGraphDockFuelUsage([])
  //     }
  //   }
  //   apiGet(graphDockFuelUrl, graphDockFuelHandler)
  // }

  const fetchOrganisationList = () => {
    const url = theApiUrl + "orgs/";
    // console.log("Going to fetch orgList:", url)
    const organisationListHandler = (responseJson: any) => {
      // console.log("logging orglist responseJson: ", responseJson, typeof responseJson)
      // console.log("fetchorglist response:", responseJson)
      const orgsList = responseJson.orgs; //.organizations
      // console.log("orgsList:", orgsList)
      const organisationsList = orgsList.map(
        (entry: { uid: string; name: string }) => {
          return {
            id: entry.uid,
            name: entry.name,
          };
        }
      );
      // const orgName = organisationsEntry.name
      data.org.setOrganisationList(organisationsList);
      // setLocalOrganisationName(orgName)
      // console.log("Organisation list set to: ", organisationsList)
    };
    apiGet(url, organisationListHandler);
  };

  const fetchOrganisationName = () => {
    const org = vars.auth.organisationId;
    if (org === null) {
      // console.log("fetchOrganisationName: orgId is null, no API call made")
      return;
    }
    const url = theApiUrl + "orgs/" + org;
    // console.log("Going to fetch orgName:", url)
    const organisationNameHandler = (responseJson: any) => {
      // console.log("org is: ",org)
      // console.log("logging orgname responseJson: ", responseJson, typeof responseJson)
      // console.log("fetchorgname response:", responseJson)
      // const organisationsList = responseJson.organisations
      // console.log("organisationsList:", organisationsList)
      // const organisationsEntry = organisationsList.find((x: {name: string, id: string}) => x.id === org)
      const organisationsEntry = responseJson;
      if (organisationsEntry === undefined) {
        //console.log("Organisation name not in list")
      } else {
        const orgName = organisationsEntry.name;
        data.org.setOrganisationName(orgName);
        setLocalOrganisationName(orgName);
        // console.log("Organisation name set to: ", orgName)
      }
    };
    apiGet(url, organisationNameHandler);
  };

  const fetchAllVesselList = () => {
    const url = theApiUrl + "vessels/";
    // console.log("Going to fetch allVesselList:", url)
    const allVesselListHandler = (responseJson: any) => {
      // console.log("logging allVessellist responseJson: ", responseJson, typeof responseJson)
      const rawList = responseJson.vessels;
      // console.log("-> logging rawList for vesselList: ")
      // console.log(rawList)
      const convertedList = rawList.map(apiConverterVesselList);
      data.org.setVesselList(convertedList);
      // console.log("convertedList:",convertedList)
    };
    apiGet(url, allVesselListHandler);
  };

  const fetchOrgVesselList = () => {
    const org = vars.auth.organisationId;
    if (org === null) {
      // console.log("fetchOrgVesselList: orgId is null, no API call made")
      return;
    }
    const url = theApiUrl + "vessels/org/" + org;
    // console.log("Going to fetch orgVesselList:", url)
    const orgVesselListHandler = (responseJson: any) => {
      // console.log("logging orgVessellist responseJson: ", responseJson, typeof responseJson)
      const rawList: rawVesselEntry[] = responseJson.vessels;
      // console.log("-> logging rawList for vesselList: ", rawList)
      // const orgRawList = allRawList.filter((vessel) => vessel.org_uid === org)
      const convertedList = rawList.map(apiConverterVesselList);
      data.org.setVesselList(convertedList);
      // console.log("orgVesselList convertedList:",convertedList)
    };
    apiGet(url, orgVesselListHandler);
  };

  function fetchDataRealTimePage(
    vessel: string | null,
    signal: RealTimeSignal,
    fetchMode: RealTimeFetchMode
  ) {
    if (vessel === null) return;

    const outputs = ["lat", "lon", "heading_true"].concat(
      realTimeSignalMap[signal].apiRequestName
    );
    const endpointPart = "realtimedata";
    const vesselPart = "?vessel_id=" + vessel;
    const outputsPart = outputs.map((output) => "&outputs=" + output).join("");
    const agoPart = "&ago=" + realTimeFetchMap[fetchMode].ago; // requesting more first time
    const samplePart = "&sample=" + realTimeFetchMap[fetchMode].sample;
    const url =
      theApiUrl +
      endpointPart +
      vesselPart +
      outputsPart +
      agoPart +
      samplePart;

    const realTimeHandler = (responseJson: any) => {
      // console.log("Real time fetch response:", responseJson)
      const convertedData = responseJson.rows.map((data: any[]) =>
        apiConverterRealTimeData(data, responseJson.columns, signal)
      );
      const newData: realTimeData = {
        realTimePoints: convertedData,
        vessel: vessel,
        signal: signal,
        maxAmountStored: fetchMode,
      };
      const combinedData = combineRealTimeData(newData, data.realTime);
      if (vessel === view.realTime.vessel && signal === view.realTime.signal) {
        data.setRealTime(combinedData);
      }
    };
    apiGet(url, realTimeHandler);
  }

  function fetchDataDestinationsTab() {
    const organisationId = vars.auth.organisationId;
    const isRoot =
      vars.auth.userGroups !== null && vars.auth.userGroups.includes("root");
    // console.log("organisationId:", organisationId)
    let destinationsUrl: string;
    if (organisationId !== null) {
      destinationsUrl = theApiUrl + "dests/" + organisationId;
    } else {
      if (isRoot) {
        destinationsUrl = theApiUrl + "dests/";
      } else {
        data.org.destinationList.set([]);
        return;
      }
    }
    // console.log("Going to fetch destinations:", destinationsUrl)
    const destTabHandler = (responseJson: any) => {
      // console.log("fetching destinations response:", responseJson)
      const rawDestList = responseJson.dests;
      // console.log("rawDestList:", rawDestList)
      const convertedDestList = rawDestList.map(apiConverterDestList);
      // console.log("convertedDestList:", convertedDestList)
      data.org.destinationList.set(convertedDestList);
    };
    data.org.destinationList.set(null);
    apiGet(destinationsUrl, destTabHandler);
  }

  function fetchDataGeoZonesTab() {
    const organisationId = vars.auth.organisationId;
    const isRoot =
      vars.auth.userGroups !== null && vars.auth.userGroups.includes("root");

    let geoZonesUrl: string;
    if (organisationId !== null) {
      geoZonesUrl = theApiUrl + "geozones/" + organisationId;
    } else {
      if (isRoot) {
        geoZonesUrl = theApiUrl + "geozones";
      } else {
        data.org.geoZoneList.set([]);
        return;
      }
    }

    const geoZonesTabHandler = (responseJson: any) => {
      const rawGeoZoneList = responseJson.records || [];

      const convertedGeoZoneList: geoZonesEntry[] = rawGeoZoneList.map(
        (apiGeoZone: any) => ({
          gz_id: apiGeoZone.gz_id,
          org_id: apiGeoZone.org_id,
          name: apiGeoZone.name,
          state: apiGeoZone.state,
          shape: {
            type: apiGeoZone.shape.type,
            coordinates: apiGeoZone.shape.coordinates.map((coord: any) => ({
              longitude: coord.longitude,
              latitude: coord.latitude,
            })),
            radius: apiGeoZone.shape.radius,
          },
          metadata: apiGeoZone.metadata,
        })
      );

      data.org.geoZoneList.set(convertedGeoZoneList);
    };

    data.org.geoZoneList.set(null);
    apiGet(geoZonesUrl, geoZonesTabHandler);
  }

  function fetchDataRootOrgs() {
    if (vars.auth.accessLevel[0] !== AccessLevel.Root) return;
    const rootOrgsUrl = theApiUrl + "orgs/";
    const rootOrgsHandler = (responseJson: any) => {
      // console.log("fetching rootOrgs response:", responseJson)
      const orgs = responseJson.orgs;
      // console.log("orgs:", orgs)
      data.rootAccess.setOrgs(orgs);
    };
    data.rootAccess.setOrgs(null);
    apiGet(rootOrgsUrl, rootOrgsHandler);
  }

  function fetchDataRootVessels() {
    const rootVesselsUrl = theApiUrl + "vessels/";
    const rootVesselsHandler = (responseJson: any) => {
      // console.log("fetching rootVessels response:", responseJson)
      const vessels = responseJson.vessels;
      // console.log("vessels:", vessels)
      data.rootAccess.setVessels(vessels);
    };
    data.rootAccess.setVessels(null);
    apiGet(rootVesselsUrl, rootVesselsHandler);
  }

  function fetchAllUsers() {
    const url = theApiUrl + "users/";
    const handler = (responseJson: any) => {
      data.org.setUserList(responseJson.users.map(apiConverterUserEntry));
    };
    data.org.setUserList(null);
    apiGet(url, handler);
  }

  function fetchOrgUsers() {
    const org = vars.auth.organisationId;
    if (org === null) return;
    const url = theApiUrl + "users/" + org;
    const handler = (responseJson: any) => {
      data.org.setUserList(responseJson.users.map(apiConverterUserEntry));
    };
    data.org.setUserList(null);
    apiGet(url, handler);
  }

  function fetchUsersGeneral() {
    if (
      vars.auth.userGroups?.includes("root") &&
      vars.auth.organisationId === null
    ) {
      fetchAllUsers();
    } else {
      fetchOrgUsers();
    }
  }

  function fetchDataRootDests() {
    const rootDestsUrl = theApiUrl + "dests";
    const rootDestsHandler = (responseJson: any) => {
      // console.log("fetching rootDests response:", responseJson)
      const rawDests = responseJson.dests;
      // console.log("rawDests:", rawDests)
      const dests: rootDestsEntry[] = rawDests.map((rawDest: any) => {
        const location = rawDest.location;
        const metadata = rawDest.metadata;
        const metadataStr = metadata === null ? null : JSON.stringify(metadata);
        const entry: rootDestsEntry = {
          lid: rawDest.lid,
          org_uid: rawDest.org_uid,
          display_name: rawDest.display_name,
          latitude: location.latitude,
          longitude: location.longitude,
          radius: location.radius,
          metadata: metadataStr,
        };
        return entry;
      });
      // console.log("dests:", dests)
      data.rootAccess.setDests(dests);
    };
    data.rootAccess.setDests(null);
    apiGet(rootDestsUrl, rootDestsHandler);
  }

  function fetchDataSignalTypes() {
    const signalTypesUrl = theApiUrl + "alarms-types/signals";

    const signalTypesHandler = (responseJson: any) => {
      const signals: SignalType[] = Object.keys(responseJson).map((key) => ({
        label: key,
        value: responseJson[key][0],
        unit: responseJson[key][1],
      }));
      data.signalTypes.signalTypeList.set(signals);
    };

    data.signalTypes.signalTypeList.set(null);
    apiGet(signalTypesUrl, signalTypesHandler);
  }

  function fetchDataRootStatus(org_id?: string, vessel_id?: string) {
    let rootStatusUrl = `${theApiUrl}vessel-statuses/`;

    if (org_id && vessel_id) {
      rootStatusUrl = `${theApiUrl}vessel-statuses/${org_id}/${vessel_id}`;
    } else if (org_id) {
      rootStatusUrl = `${theApiUrl}vessel-statuses/${org_id}/`;
    }

    const rootStatusHandler = (responseJson: any) => {
      const vesselStatusData = responseJson.data;
      const status: rootStatusEntry[] = vesselStatusData.map(
        (vesselStatusData: any) => {
          const entry: rootStatusEntry = {
            org_id: vesselStatusData.org_id,
            vessel_id: vesselStatusData.vessel_id,
            datastreams: vesselStatusData.datastreams,
            lat: vesselStatusData.lat,
            lon: vesselStatusData.lon,
            status_code: vesselStatusData.status_code,
            timestamp: vesselStatusData.timestamp,
            ihelm_version: vesselStatusData.ihelm_version,
          };
          return entry;
        }
      );
      data.rootAccess.setStatus(status);
    };
    apiGet(rootStatusUrl, rootStatusHandler);
  }

  function fetchDataIntegrityStatus(org_id?: string, vessel_id?: string): void {
    let dataStatusUrl = `${theApiUrl}vessel-data-statuses/`;

    if (org_id && vessel_id) {
      dataStatusUrl = `${theApiUrl}vessel-data-statuses/${org_id}/${vessel_id}`;
    } else if (org_id) {
      dataStatusUrl = `${theApiUrl}vessel-data-statuses/${org_id}/`;
    }

    const dataStatusHandler = (responseJson: any) => {
      const vesselDataStatus = responseJson.data;
      const status: dataIntegrityEntry[] = vesselDataStatus.map(
        (vesselDataStatus: any) => {
          const entry: dataIntegrityEntry = {
            org_id: vesselDataStatus.org_id,
            vessel_id: vesselDataStatus.vessel_id,
            signals: vesselDataStatus.signals.map((signal: any) => ({
              description: signal.description,
              name: signal.name,
              status_code: signal.status_code,
            })),
            status_code: vesselDataStatus.status_code,
            timestamp: vesselDataStatus.timestamp.toString(),
          };
          return entry;
        }
      );
      data.rootAccess.setDataIntegrityStatus(status);
    };

    apiGet(dataStatusUrl, dataStatusHandler);
  }

  function fetchDataTagTypes() {
    const organisationId = vars.auth.organisationId;

    let url: string;
    if (organisationId !== null) {
      url = theApiUrl + "tags-types/" + organisationId;
    } else {
      if (accessLevelAtMinimum(vars.auth.accessLevel[0], AccessLevel.Root)) {
        url = theApiUrl + "tags-types/";
      } else {
        // console.log("No org in tag fetching, aborting")
        return;
      }
    }
    data.tagTypes.tagTypeList.set(null);
    const handler = (responseJson: any) => {
      // console.log("handling fetchDataTagTypes:", responseJson)
      const rawTagTypes = responseJson.records;
      const tagTypes: tagType[] = rawTagTypes.map((rawTagType: any) => {
        const valueTypeMaybe = stringToTagValueType(rawTagType.value_type);
        const valueType =
          valueTypeMaybe !== null ? valueTypeMaybe : TagValueType.None;
        const scopeMaybe = stringToTagScope(rawTagType.scope);
        const scope = scopeMaybe !== null ? scopeMaybe : TagScope.LegInstance;
        const stateMaybe = stringToEntityState(rawTagType.state);
        const state = stateMaybe !== null ? stateMaybe : EntityState.Active;
        const tagType: tagType = {
          id: rawTagType.tag_id,
          name: rawTagType.name,
          orgId: rawTagType.org_id,
          valueType: valueType,
          unit: rawTagType.unit,
          scope: scope,
          state: state,
          categories: rawTagType.categories,
        };
        // console.log("tagType conversion:", rawTagType, tagType)
        return tagType;
      });
      // console.log("Writing tagType list to cxt:", tagTypes)
      data.tagTypes.tagTypeList.set(tagTypes);
    };
    apiGet(url, handler);
  }

  function fetchDataOpModes(opModeId?: string, vesselId?: string) {
    const organisationId = vars.auth.organisationId;

    let url: string;

    if (accessLevelAtMinimum(vars.auth.accessLevel[0], AccessLevel.Root)) {
      url = theApiUrl + "opmode/";
    } else if (organisationId) {
      // TODO: This string type checking is a temp solution
      if (typeof opModeId === "string" && opModeId) {
        url = `${theApiUrl}opmode/${organisationId}/${opModeId}`;
      } else {
        url = `${theApiUrl}opmode/${organisationId}`;
      }
    } else if (vesselId) {
      url = theApiUrl + "opmode/vessel/";
    } else {
      return;
    }

    data.opModes.opModeList.set(null);
    const handler = (responseJson: any) => {
      const rawOpModes = responseJson.records;
      const opModes = rawOpModes.map((rawOpMode: any) => {
        const stateMaybe = stringToEntityState(rawOpMode.state);
        const state = stateMaybe !== null ? stateMaybe : EntityState.Active;
        const opMode = {
          orgId: rawOpMode.org_id,
          opModeId: rawOpMode.opmode_id,
          name: rawOpMode.name,
          description: rawOpMode.description || null,
          state: state,
        };
        return opMode;
      });
      data.opModes.opModeList.set(opModes);
    };
    apiGet(url, handler);
  }

  function fetchTripDataSecondary(
    vesselId: string | null,
    startDate: string | null,
    routeId: string | null
  ) {
    const organisationId = vars.auth.organisationId;

    if (
      !startDate ||
      typeof startDate !== "string" ||
      !routeId ||
      typeof routeId !== "string"
    ) {
      // console.log("Missing necessary data for fetching. Route or start date is not a valid string.");
      return;
    }

    let url: string;

    if (vesselId !== null) {
      url = `${theApiUrl}tripdata-secondary/${vesselId}/${routeId}/${startDate}`;
    } else if (organisationId !== null) {
      url = `${theApiUrl}tripdata-secondary/org/${organisationId}/${routeId}/${startDate}`;
    } else {
      console.error(
        "Neither vesselId nor organisationId provided. Aborting API call."
      );
      return;
    }

    apiGet(url, (responseJson: any, response: Response) => {
      // console.log("responseJson for secondary trips:", responseJson);

      if (response.status === 404) {
        // console.error("Record not found:", responseJson.message);
        return;
      } else if (response.status !== 200) {
        // console.error("Error fetching data:", responseJson.message);
        return;
      }

      const trip: secondaryTripData = convertRawTrip(responseJson);
      data.secondaryTrips.secondaryTripList[1]([trip]);
    });
  }

  function convertRawTrip(rawTrip: any): secondaryTripData {
    return {
      org_id: rawTrip.org_id,
      vessel_id: rawTrip.vessel_id,
      route_id: rawTrip.route_id,
      path_id: rawTrip.path_id,
      end_timestring: rawTrip.end_timestring,
      lats: Array.isArray(rawTrip.lats) ? rawTrip.lats : [],
      lons: Array.isArray(rawTrip.lons) ? rawTrip.lons : [],
      sogs: Array.isArray(rawTrip.sogs) ? rawTrip.sogs : [],
      diesel_rates: Array.isArray(rawTrip.diesel_rates)
        ? rawTrip.diesel_rates
        : [],
      elec_powers: Array.isArray(rawTrip.elec_powers)
        ? rawTrip.elec_powers
        : [],
      delta_dists: Array.isArray(rawTrip.delta_dists)
        ? rawTrip.delta_dists
        : [],
      delta_times: Array.isArray(rawTrip.delta_times)
        ? rawTrip.delta_times
        : [],
      dists: rawTrip.dists,
      times: rawTrip.times,
      timestring: rawTrip.timestring,
    };
  }

  async function fetchAllSecondaryTrips(tripType: string, trips: trip[]) {
    for (let i = 0; i < trips.length; i++) {
      try {
        await fetchTripsTypeSecondaryData(
          tripType,
          trips[i].vesselId,
          trips[i].routeId,
          trips[i].startTimestamp
        );
      } catch (error) {
        // console.error("Error fetching data for trip:", trips[i], error);
      }
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
  }

  function fetchTripsTypeSecondaryData(
    tripType: string,
    vesselId: string | null,
    routeId: string | null,
    timestring: string | null
  ): Promise<secondaryTripData> {
    return new Promise((resolve, reject) => {
      if (!timestring) {
        console.error(
          "Missing necessary timestring data for fetching secondary data."
        );
        reject("Missing necessary timestring data");
        return;
      }

      let url = `${theApiUrl}tripdata-${tripType}-secondary/`;

      switch (tripType) {
        case "leg":
        case "route":
          url += `${vesselId}`;
          if (routeId) {
            url += `/${routeId}`;
          }
          url += `/${timestring}`;
          break;
        case "voyage":
        case "opmode":
        case "engineonoff":
          url += `${vesselId}/${timestring}`;
          break;
        default:
          console.error(
            "Invalid trip type provided for fetching secondary trips"
          );
          reject("Invalid trip type");
          return;
      }

      apiGet(url, (responseJson, response) => {
        // console.log("API call response:", responseJson, response);

        if (response.status === 404) {
          console.error("Record not found:", responseJson.message);
          reject("Record not found");
          return;
        } else if (response.status !== 200) {
          console.error("Error fetching data:", responseJson.message);
          reject("Error fetching data");
          return;
        }

        const convertedTripData: secondaryTripData = {
          org_id: responseJson.org_id,
          vessel_id: responseJson.vessel_id,
          path_id: responseJson.path_id,
          end_timestring: responseJson.end_timestring,
          lats: Array.isArray(responseJson.lats) ? responseJson.lats : [],
          lons: Array.isArray(responseJson.lons) ? responseJson.lons : [],
          sogs: Array.isArray(responseJson.sogs) ? responseJson.sogs : [],
          diesel_rates: Array.isArray(responseJson.diesel_rates)
            ? responseJson.diesel_rates
            : [],
          elec_powers: Array.isArray(responseJson.elec_powers)
            ? responseJson.elec_powers
            : [],
          delta_dists: Array.isArray(responseJson.delta_dists)
            ? responseJson.delta_dists
            : [],
          delta_times: Array.isArray(responseJson.delta_times)
            ? responseJson.delta_times
            : [],
          dists: responseJson.dists || [],
          times: responseJson.times || [],
          timestring: responseJson.timestring,
        };

        resolve(convertedTripData);
      });
    });
  }

  function fetchSecondaryLegTypeData(
    tripType: string,
    vesselId: string | null,
    routeId: string | null,
    timestring: string | null
  ) {
    if (tripType !== "leg") {
      // console.error("Invalid trip type for secondary leg data fetching");
      return;
    }

    if (!timestring) {
      // console.error("Missing necessary timestring data for fetching secondary leg data.");
      return;
    }

    let url = `${theApiUrl}tripdata-${tripType}-secondary/`;
    url += `${vesselId}`;
    url += routeId ? `/${routeId}` : "";
    url += `/${timestring}`;

    apiGet(url, (responseJson, response) => {
      if (response.status === 404) {
        // console.error("Record not found:", responseJson.message);
        return;
      } else if (response.status !== 200) {
        // console.error("Error fetching data:", responseJson.message);
        return;
      }

      const convertedTripData: secondaryLegTripData = {
        vesselroute_id: responseJson.vesselroute_id,
        org_id: responseJson.org_id,
        vessel_id: responseJson.vessel_id,
        route_id: responseJson.route_id,
        path_id: responseJson.path_id,
        end_timestring: responseJson.end_timestring,
        lats: Array.isArray(responseJson.lats) ? responseJson.lats : [],
        lons: Array.isArray(responseJson.lons) ? responseJson.lons : [],
        sogs: Array.isArray(responseJson.sogs) ? responseJson.sogs : [],
        diesel_rates: Array.isArray(responseJson.diesel_rates)
          ? responseJson.diesel_rates
          : [],
        elec_powers: Array.isArray(responseJson.elec_powers)
          ? responseJson.elec_powers
          : [],
        delta_dists: Array.isArray(responseJson.delta_dists)
          ? responseJson.delta_dists
          : [],
        delta_times: Array.isArray(responseJson.delta_times)
          ? responseJson.delta_times
          : [],
        timestring: responseJson.timestring,
        dists: responseJson.dists || [],
        times: responseJson.times || [],
      };

      data.secondaryLegsTripsData.secondaryLegTripList[1]([convertedTripData]);
    });
  }

  function fetchPrimaryLegsTripsData(
    vesselId: any,
    startDate: any,
    endDate: any,
    timestring = null
  ) {
    const organisationId = vars.auth.organisationId;

    if (!startDate || !endDate || !vesselId) {
      // console.error("Required parameters for fetching leg trips are missing");
      return;
    }

    let url = `${theApiUrl}tripdata-leg-primary/`;
    if (vesselId) {
      url += `${vesselId}`;
      if (timestring) {
        url += `/${timestring}`;
      } else {
        url += `?start_time=${startDate}&end_time=${endDate}`;
      }
    }
    data.trips.tripList[1](null);

    const handler = async (responseJson: { records: any }) => {
      const rawTrips = responseJson.records;
      const trips = rawTrips.map(
        (rawTrip: {
          timestring: any;
          org_id: any;
          vessel_id: any;
          start_timestring: any;
          end_timestring: any;
          path_id: any;
          route_id: any;
          vesselroute_id: any;
          operators: any;
          avg_diesel: string | null;
          total_diesel: string | null;
          avg_elec: string | null;
          total_elec: string | null;
          total_dist: string | null;
          total_time: string | null;
        }) => {
          return {
            timestring: rawTrip.timestring,
            orgId: rawTrip.org_id,
            vesselId: rawTrip.vessel_id,
            startTimestamp: rawTrip.start_timestring,
            endTimestamp: rawTrip.end_timestring,
            pathId: rawTrip.path_id,
            routeId: rawTrip.route_id,
            vesselRouteId: rawTrip.vesselroute_id,
            operators: rawTrip.operators || [],
            dieselAverage:
              rawTrip.avg_diesel !== null
                ? parseFloat(rawTrip.avg_diesel)
                : null,
            dieselTotal:
              rawTrip.total_diesel !== null
                ? parseFloat(rawTrip.total_diesel)
                : null,
            electricAverage:
              rawTrip.avg_elec !== null ? parseFloat(rawTrip.avg_elec) : null,
            electricTotal:
              rawTrip.total_elec !== null
                ? parseFloat(rawTrip.total_elec)
                : null,
            distanceTotal:
              rawTrip.total_dist !== null
                ? parseFloat(rawTrip.total_dist)
                : null,
            timeTotal:
              rawTrip.total_time !== null
                ? parseInt(rawTrip.total_time, 10)
                : null,
          };
        }
      );
      data.trips.tripList[1](trips);
    };

    apiGet(url, handler);
  }

  function fetchTripsTypeData(
    tripType: any,
    vesselId: any,
    startDate: any,
    endDate: any,
    timestring = null
  ) {
    authenticationExpirationCheck(vars, data, view, navigate);
    const organisationId = vars.auth.organisationId;

    if (!startDate || !endDate) {
      return;
    }

    let url = `${theApiUrl}tripdata-${tripType}-primary/`;
    switch (tripType) {
      case "route":
      case "leg":
        url += `${vesselId}`;
        if (timestring) {
          url += `/${timestring}`;
        } else {
          url += `?start_time=${startDate}&end_time=${endDate}`;
        }
        if (tripType === "leg" && !vesselId && organisationId) {
          url = `${theApiUrl}tripdata-${tripType}-primary/org/${organisationId}?start_time=${startDate}&end_time=${endDate}`;
        }
        break;
      case "voyage":
      case "opmode":
      case "engineonoff":
        url += `${vesselId}`;
        if (timestring) {
          url += `/${timestring}`;
        } else {
          url += `?start_time=${startDate}&end_time=${endDate}`;
        }
        if (!vesselId && organisationId) {
          url = `${theApiUrl}tripdata-${tripType}-primary/org/${organisationId}?start_time=${startDate}&end_time=${endDate}`;
        }
        break;
    }
    data.trips.tripList[1](null);

    const handler = async (responseJson: { records: any }) => {
      console.log(responseJson, "responseJson");
      const rawTrips = responseJson.records;
      // console.log(rawTrips, "rawTrips");
      const trips = rawTrips.map(
        (rawTrip: {
          op_mode: string;
          org_id: any;
          vessel_id: any;
          start_timestring: any;
          timestring: any;
          end_timestring: any;
          path_id: any;
          route_id: any;
          vesselroute_id: any;
          operators: any;
          avg_diesel: string;
          total_diesel: string;
          avg_elec: string;
          total_elec: string;
          total_dist: string;
          total_time: string;
          cargo_end: any;
          cargo_median: any;
          cargo_start: any;
          cargo_types: any;
          current_dir: any;
          current_spd: any;
          end_dest_id: any;
          end_dest_in_eu: any;
          end_dest_lat: any;
          end_dest_lon: any;
          fuel_masses: any;
          op_mode_times: any;
          start_dest_id: any;
          start_dest_in_eu: any;
          start_dest_lat: any;
          start_dest_lon: any;
          transport_work: any;
          trim: any;
          wind_dir: any;
          wind_spd: any;
          current_dir_app: any;
          current_spd_app: any;
          wind_dir_app: any;
          wind_spd_app: any;
        }): trip => ({
          orgId: rawTrip.org_id,
          vesselId: rawTrip.vessel_id,
          startTimestamp: rawTrip.start_timestring || rawTrip.timestring,
          endTimestamp: rawTrip.end_timestring,
          pathId: rawTrip.path_id,
          routeId: rawTrip.route_id,
          vesselRouteId: rawTrip.vesselroute_id,
          operators: rawTrip.operators,
          dieselAverage: rawTrip.avg_diesel
            ? parseFloat(rawTrip.avg_diesel)
            : null,
          dieselTotal: rawTrip.total_diesel
            ? parseFloat(rawTrip.total_diesel)
            : null,
          electricAverage: rawTrip.avg_elec
            ? parseFloat(rawTrip.avg_elec)
            : null,
          electricTotal: rawTrip.total_elec
            ? parseFloat(rawTrip.total_elec)
            : null,
          distanceTotal: parseFloat(rawTrip.total_dist),
          timeTotal: parseInt(rawTrip.total_time, 10),
          timestring: rawTrip.timestring,
          cargo_end: rawTrip.cargo_end,
          cargo_median: rawTrip.cargo_median,
          cargo_start: rawTrip.cargo_start,
          cargo_types: rawTrip.cargo_types,
          current_dir: rawTrip.current_dir,
          current_spd: rawTrip.current_spd,
          end_dest_id: rawTrip.end_dest_id,
          end_dest_in_eu: rawTrip.end_dest_in_eu,
          end_dest_lat: rawTrip.end_dest_lat,
          end_dest_lon: rawTrip.end_dest_lon,
          fuel_masses: rawTrip.fuel_masses,
          op_mode_times: rawTrip.op_mode_times,
          start_dest_id: rawTrip.start_dest_id,
          start_dest_in_eu: rawTrip.start_dest_in_eu,
          start_dest_lat: rawTrip.start_dest_lat,
          start_dest_lon: rawTrip.start_dest_lon,
          transport_work: rawTrip.transport_work,
          trim: rawTrip.trim,
          wind_dir: rawTrip.wind_dir,
          wind_spd: rawTrip.wind_spd,
          end_timestring: rawTrip.end_timestring || null,
          total_diesel: rawTrip.total_diesel
            ? parseFloat(rawTrip.total_diesel)
            : null,
          total_dist: rawTrip.total_dist
            ? parseFloat(rawTrip.total_dist)
            : null,
          total_elec: rawTrip.total_elec
            ? parseFloat(rawTrip.total_elec)
            : null,
          total_time: rawTrip.total_time
            ? parseInt(rawTrip.total_time, 10)
            : null,
          current_dir_app: rawTrip.current_dir_app,
          current_spd_app: rawTrip.current_spd_app,
          wind_dir_app: rawTrip.wind_dir_app,
          wind_spd_app: rawTrip.wind_spd_app,
          op_mode: rawTrip.op_mode,
        })
      );

      data.trips.tripList[1](trips);
    };

    apiGet(url, handler);
  }

  // ENGINE ON OFF secondary and primary fetching

  function fetchPrimaryEngineOnOffTripsData(
    vesselId: any,
    startDate: any,
    endDate: any,
    timestring = null
  ) {
    if (!startDate || !endDate || !vesselId) {
      // console.error("Required parameters for fetching Engine On Off trips are missing");
      return;
    }

    let url = `${theApiUrl}/tripdata-engineonoff-primary/${vesselId}`;
    if (timestring) {
      url += `/${timestring}`;
    } else {
      url += `?start_time=${startDate}&end_time=${endDate}`;
    }

    // console.log(`Making API call to URL: ${url}`);

    apiGet(url, (responseJson) => {
      const trips = responseJson.records.map(
        (rawTrip: {
          timestring: any;
          org_id: any;
          vessel_id: any;
          start_timestring: any;
          end_timestring: any;
          operators: any;
          avg_diesel: string;
          total_diesel: string;
          avg_elec: string;
          total_elec: string;
          total_dist: string;
          total_time: string;
        }) => ({
          timestring: rawTrip.timestring,
          orgId: rawTrip.org_id,
          vesselId: rawTrip.vessel_id,
          startTimestamp: rawTrip.start_timestring,
          endTimestamp: rawTrip.end_timestring,
          operators: rawTrip.operators,
          dieselAverage: parseFloat(rawTrip.avg_diesel),
          dieselTotal: parseFloat(rawTrip.total_diesel),
          electricAverage: parseFloat(rawTrip.avg_elec),
          electricTotal: parseFloat(rawTrip.total_elec),
          distanceTotal: parseFloat(rawTrip.total_dist),
          timeTotal: parseInt(rawTrip.total_time, 10),
        })
      );

      // console.log("Fetched primary Engine On Off trips:", trips);
      data.engineOnOffTrips.engineOnOffTripList[1](trips);
    });
  }

  function fetchSecondaryEngineOnOffTripsData(vesselId: any, timestring: any) {
    return new Promise((resolve, reject) => {
      if (!timestring) {
        // console.error("Missing necessary timestring data for fetching secondary Engine On Off data.");
        reject("Missing timestring data");
        return;
      }

      let url = `${theApiUrl}/tripdata-engineonoff-secondary/${vesselId}/${timestring}`;
      // console.log(`Making API call to URL: ${url}`);

      apiGet(url, (responseJson, error) => {
        if (error) {
          reject(error);
        } else {
          const secondaryTrip = {
            vessel_id: responseJson.vessel_id,
            timestring: responseJson.timestring,
            delta_dists: responseJson.delta_dists,
            delta_times: responseJson.delta_times,
            diesel_rates: responseJson.diesel_rates,
            dists: responseJson.dists,
            elec_powers: responseJson.elec_powers,
            end_timestring: responseJson.end_timestring,
            lats: responseJson.lats,
            lons: responseJson.lons,
            org_id: responseJson.org_id,
            sogs: responseJson.sogs,
            times: responseJson.times,
          };
          // console.log("Fetched secondary Engine On Off trip:", secondaryTrip);
          resolve(secondaryTrip);
        }
      });
    });
  }

  function fetchTagEvents(
    vesselId: string | null,
    startDate: string | null,
    endDate: string | null
  ) {
    const organisationId = vars.auth.organisationId;
    const dateRangeQuery =
      startDate === null || endDate === null
        ? ""
        : "?start_time=" + startDate + "&end_time=" + endDate;
    let url: string;
    if (vesselId !== null) {
      url = theApiUrl + "tags-events/vessel/" + vesselId + dateRangeQuery;
    } else if (organisationId !== null) {
      url = theApiUrl + "tags-events/org/" + organisationId + dateRangeQuery; //+"?start_time=2023-09-01&end_time=2023-09-03"
    } else {
      // console.log("No vessel or org in tag event fetching, aborting")
      data.tagEvents.tagEventList.set([]);
      return;
    }

    const handler = (responseJson: any) => {
      // console.log("handling fetchTagEvents response:", responseJson)
      const rawTagEvents = responseJson.records;
      // console.log("rawTagEvents:", rawTagEvents)
      const tagTypeList = data.tagTypes.tagTypeList.val;
      const tagEvents: tagEvent[] = rawTagEvents.map((rawTagEvent: any) => {
        const tagType = tagTypeList?.find(
          (tagType) => tagType.id === rawTagEvent.tag_id
        );
        const value =
          tagType?.valueType === TagValueType.Number
            ? parseFloat(rawTagEvent.value)
            : rawTagEvent.value;
        const tagEvent: tagEvent = {
          orgId: rawTagEvent.org_id,
          vesselId: rawTagEvent.vessel_id,
          timestamp: rawTagEvent.timestring,
          tagTypeId: rawTagEvent.tag_id,
          value: value,
          userId: rawTagEvent.user_id,
          category: rawTagEvent.category,
        };
        // console.log("tag event conversion:", rawTagEvent, tagEvent)
        return tagEvent;
      });
      // console.log("converted tag events:", rawTagEvents, tagEvents)
      data.tagEvents.tagEventList.set(tagEvents);
    };
    data.tagEvents.tagEventList.set(null);
    apiGet(url, handler);
  }

  function fetchDataPerformancePage(
    vessel: string | null,
    fromTime: Date | null,
    timescale: Timescale
  ) {
    authenticationExpirationCheck(vars, data, view, navigate);
    if (vessel === null || fromTime === null) return;

    const timescalePart = "vpd-" + timescale;
    const fromPart = dateToString(fromTime, false);
    const url = theApiUrl + timescalePart + "/" + vessel + "/" + fromPart;

    function handler(responseJson: any, response: Response) {
      // console.log("Response for performance:", responseJson, response);
      if (response.status === 404) {
        data.performance.setEngineSpeedTorqueMaps([]);
        data.performance.setSogDistribution([]);
        data.performance.setTotalEngineDieselRateSogMap([]);
        data.performance.setTotalEnginePowerSogMap([]);
      }

      const sog_distribution = responseJson.sog_distribution;
      const engine_speed_torque_maps = responseJson.engine_speed_torque_maps;
      const total_engine_diesel_rate_sog_map =
        responseJson.total_engine_diesel_rate_sog_map;
      const total_engine_power_sog_map =
        responseJson.total_engine_power_sog_map;

      const convertedEngineSpeedTorqueMaps = apiConverterEngineSpeedTorqueMaps(
        engine_speed_torque_maps
      );
      const convertedSogDistribution =
        sog_distribution === null
          ? []
          : sog_distribution.map(apiConverterSogDist);
      const convertedTotalEngineDieselRateSogMap =
        total_engine_diesel_rate_sog_map === null
          ? []
          : total_engine_diesel_rate_sog_map.map(
              apiConverterTotalEngineDieselRateSog
            );
      const convertedTotalEnginePowerSogMap =
        total_engine_power_sog_map === null
          ? []
          : total_engine_power_sog_map.map(apiConverterTotalEnginePowerSog);

      data.performance.setEngineSpeedTorqueMaps(convertedEngineSpeedTorqueMaps);
      data.performance.setSogDistribution(convertedSogDistribution);
      data.performance.setTotalEngineDieselRateSogMap(
        convertedTotalEngineDieselRateSogMap
      );
      data.performance.setTotalEnginePowerSogMap(
        convertedTotalEnginePowerSogMap
      );
    }
    apiGet(url, handler);
  }

  const fetchDataReportsPage = useCallback(
    async (vesselId: string) => {
      const reportsUrl = `${theApiUrl}reports/vessel-reports/${vesselId}`;
      const reportsHandler = (responseJson: any) => {
        const rawVesselReportList = responseJson.vessel_reports;
        const convertedReportList = rawVesselReportList.map(
          apiConverterReportList
        );
        data.reports.setReportList(convertedReportList);
      };
      data.reports.setReportList(null);
      apiGet(reportsUrl, reportsHandler);
    },
    [theApiUrl, data.reports]
  );

  const fetchDataOrgReports = useCallback(
    async (orgId: string) => {
      const reportsUrl = `${theApiUrl}reports/org-reports/${orgId}`;
      const reportsHandler = (responseJson: any) => {
        const rawOrgReportList = responseJson.org_reports;
        const convertedReportList = rawOrgReportList.map(
          apiConverterReportList
        );
        const filteredReportList = convertedReportList.filter(
          (report: { orgId: string | null }) =>
            report.orgId === vars.auth.organisationId
        );

        data.reports.setReportList(filteredReportList);
      };
      data.reports.setReportList(null);
      apiGet(reportsUrl, reportsHandler);
    },
    [theApiUrl, data.reports, vars.auth.organisationId]
  );

  function fetchReportFile(report: reportEntry) {
    authenticationExpirationCheck(vars, data, view, navigate);
    const reportUrl = report.link;
    if (authorization === null || reportUrl === null) {
      return;
    }
    // console.log("Fetching a report:", reportUrl);
    fetch(reportUrl, {
      headers: new Headers({
        Authorization: authorization,
      }),
    })
      .then((response) => {
        // console.log("response:", response)
        // console.log("url", response.url)
        window.open(response.url);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchDataRoutes(routeId?: string, vesselId?: string) {
    const organisationId = vars.auth.organisationId;
    let url: string;

    if (accessLevelAtMinimum(vars.auth.accessLevel[0], AccessLevel.Root)) {
      url = theApiUrl + "routes/";
    } else if (organisationId) {
      if (routeId) {
        url = `${theApiUrl}routes/${organisationId}/${routeId}`;
      } else {
        url = `${theApiUrl}routes/${organisationId}/`;
      }
    } else if (vesselId) {
      url = theApiUrl + "routes/vessel/";
    } else {
      return;
    }

    data.routes.routeList.set(null);
    const handler = (responseJson: any) => {
      const rawRoutes = responseJson.records;
      const routes = rawRoutes.map((rawRoute: any) => {
        const stateMaybe = stringToEntityState(rawRoute.state);
        const state = stateMaybe !== null ? stateMaybe : EntityState.Active;
        const route = {
          orgId: rawRoute.org_id,
          routeId: rawRoute.route_id,
          name: rawRoute.name,
          destinationIds: rawRoute.destination_ids || null,
          state: state,
        };
        return route;
      });

      data.routes.routeList.set(routes);
    };
    apiGet(url, handler);
  }

  function fetchDataVesselGroups(groupId?: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const organisationId = vars.auth.organisationId;

      if (!vars.auth || !vars.auth.organisationId) {
        console.error("Organisation ID is missing");
        return;
      }

      let url: string;
      if (typeof groupId === "string" && groupId) {
        url = `${theApiUrl}groups/${organisationId}/${groupId}`;
      } else {
        url = `${theApiUrl}groups/${organisationId}/`;
      }

      data.vesselGroups.groupList.set(null);

      const handler = (responseJson: any, response: Response) => {
        if (!response.ok) {
          reject(new Error(`HTTP error! status: ${response.status}`));
          return;
        }

        const rawRecords = responseJson.records;
        const groups = rawRecords.map((record: any) => ({
          orgId: record.group.org_id,
          groupId: record.group.group_id,
          name: record.group.name,
          groupReport: record.group.group_report,
          sisterReport: record.group.sister_report,
          sisters: record.group.sisters,
          vessels: Array.isArray(record.vessels) ? record.vessels : [],
        }));

        data.vesselGroups.groupList.set(groups);
        resolve();
      };

      try {
        apiGet(url, handler);
      } catch (error) {
        reject(error);
      }
    });
  }

  function fetchEnergydata(
    aggregation: Aggregation,
    outputs: string[],
    from: Date | null,
    to: Date | null,
    vessels: string[] | null,
    operators: string[] | null,
    activities: VesselActivity[] | null,
    energyOperatorsHandler: (responseJson: any) => void
  ) {
    const organisationId = vars.auth.organisationId;
    // Checks for if the fetch should not occur
    if (
      outputs.length === 0 ||
      from === null ||
      to === null ||
      vessels?.length === 0 ||
      organisationId === null
    ) {
      // console.log("fetchEnergydata returning without fetching")
      return;
    }
    const numberOfXValues = estimateNumberOfBars(from, to, aggregation);
    // console.log("numberOfXValues:", numberOfXValues)
    if (numberOfXValues > 40) {
      // console.log("Aborting attempt to request too large amount of data from API")
      return;
    }

    const inclTime = false;
    const fromString = dateToString(from, inclTime);
    const toString = dateToString(to, inclTime);

    // console.log("En op view vars:", vessels, operators, outputs, activities, from, to, aggregation)
    // console.log("Stringified:", fromString, toString)

    // constructing the API URL
    const orgBit = "?org_id=" + organisationId;
    const aggregatesBit =
      "&aggregates=" + aggregation + "&aggregates=operator&aggregates=activity";
    const outputsBit = outputs.map((output) => "&outputs=" + output).join("");
    const fromBit = "&from_time=" + fromString;
    const toBit = "&to_time=" + toString;
    const vesselsBit =
      vessels === null
        ? ""
        : vessels.map((vessel) => "&vessel_id=" + vessel).join("");
    const operatorsBit =
      operators === null || operators.length === 0
        ? ""
        : operators.map((operator) => "&user_id=" + operator).join("");
    let activitiesBit: string = "";
    if (activities !== null) {
      const sailingPicked = activities.includes(VesselActivity.Sailing);
      const atDockPicked = activities.includes(VesselActivity.AtDock);
      const totalPicked = activities.includes(VesselActivity.Total);
      if (sailingPicked || totalPicked) {
        activitiesBit = activitiesBit.concat(activitiesSailing);
      }
      if (atDockPicked || totalPicked) {
        activitiesBit = activitiesBit.concat(activitiesAtdock);
      }
    }
    // const offsetBit = "&offset="
    const energyOperatorsUrl =
      theApiUrl +
      "energydata" +
      orgBit +
      aggregatesBit +
      outputsBit +
      fromBit +
      toBit +
      vesselsBit +
      operatorsBit +
      activitiesBit;

    // console.log("en op before apiCallGet:", energyOperatorsUrl)
    apiGet(energyOperatorsUrl, energyOperatorsHandler);
    // console.log("en op after apiCallGet")
  }

  function fetchDataEnergyOperatorsTab() {
    const vessels = view.energyOperator.vessels;
    const operators = view.energyOperator.operators;
    const metric = view.energyOperator.metric;
    const activities = view.energyOperator.activities;
    const fromTime = view.energyOperator.fromTime;
    const toTime = view.energyOperator.toTime;
    const aggregation = view.energyOperator.aggregation;

    if (
      activities.length === 0 ||
      vessels === null ||
      vessels.length === 0 ||
      operators === null ||
      operators.length === 0
    ) {
      data.energy.operators.setSeries([]);
      return;
    }

    const outputs = energyOperatorsMetricValueSetMap[metric];

    const energyOperatorsHandler = (responseJson: any) => {
      // console.log("Response of fetching energyOperators data:", responseJson)
      const columnList = responseJson.columns;
      const returnData = responseJson.rows;
      // console.log("columns and data:", columnList, returnData)
      const convertedData = apiEnergyOperatorsConverter(
        returnData,
        columnList,
        operators,
        data.org.userList,
        metric,
        activities,
        aggregation
      );
      // console.log("convertedData:", convertedData)
      data.energy.operators.setSeries(convertedData);
    };
    fetchEnergydata(
      aggregation,
      outputs,
      fromTime,
      toTime,
      vessels,
      operators,
      activities,
      energyOperatorsHandler
    );
  }

  const fetchDataEnergyPage = () => {
    const vessel = view.energyOverall.vessel;
    const aggregation = view.energyOverall.aggregation;
    const from = view.energyOverall.fromTime;
    const to = view.energyOverall.toTime;
    const metric = view.energyOverall.metric;
    const activity = view.energyOverall.activity;

    if (from === null || to === null || vessel === null) {
      return;
    }

    const numberOfBars = estimateNumberOfBars(from, to, aggregation);
    if (numberOfBars > 40) {
      // console.log("Aborting attempt to request too large amount of data from API")
      return;
    }

    // console.log("vessel is: "+vessel)
    const vessels = makeVesselList(vessel, data);
    const inclTime = false;
    const fromString = dateToString(from, inclTime);
    const toString = dateToString(to, inclTime);

    //const testUrl = "https://api.test.cetasol.com/vessel-data/fuel?vessel="+vessel+"&from_time=2020-01-01&to_time=2020-12-31"

    fetchTotalFuelUsage(fromString, toString, vessels, metric, activity);
    fetchGraphfuelUsage(
      fromString,
      toString,
      vessels,
      aggregation,
      metric,
      activity
    );
    // fetchTotalDockFuelUsage(fromString, toString, vessels)
    // fetchGraphDockFuelUsage(fromString, toString, vessels, aggregation)

    // fetchOrganisationName()
    // fetchVesselList()
  };

  function fetchDataVoyages(voyageId?: string, vesselId?: string) {
    const organisationId = vars.auth.organisationId;

    let url: string;

    if (accessLevelAtMinimum(vars.auth.accessLevel[0], AccessLevel.Admin)) {
      url = theApiUrl + "voyage/";
    } else if (organisationId) {
      if (voyageId) {
        url = `${theApiUrl}voyage/${organisationId}/${voyageId}`;
      } else {
        url = `${theApiUrl}voyage/${organisationId}`;
      }
    } else if (vesselId) {
      url = `${theApiUrl}voyage/${vesselId}`;
    } else {
      return;
    }

    data.voyages.voyageList.set(null);
    const handler = (responseJson: any) => {
      const rawVoyages = responseJson.records;
      const voyages = rawVoyages.map((rawVoyage: any) => {
        const voyage = {
          orgId: rawVoyage.org_id,
          vesselId: rawVoyage.vessel_id,
          startTime: rawVoyage.start_time,
          endTime: rawVoyage.end_time,
          state: rawVoyage.state || "Pending", // Default to 'Pending' if state is not provided
        };
        return voyage;
      });
      data.voyages.voyageList.set(voyages);
    };
    apiGet(url, handler);
  }

  function fetchDataAlarmHistory() {
    const organisationId = vars.auth.organisationId;

    let url = `${theApiUrl}alarms-events/org/${organisationId}`;

    data.alarmEvents.alarmEventList.set(null);
    const handler = (responseJson: any) => {
      console.log("API Response:", responseJson); // NOTE: Log the response for debugging, remove console log afterwards

      const rawAlarmEvents = responseJson.records || [];
      console.log("🚀 ~ handler ~ rawAlarmEvents:", rawAlarmEvents);
      const alarmEvents = rawAlarmEvents.map((rawAlarmEvent: any) => {
        const alarmEvent: AlarmEvent = {
          id: rawAlarmEvent.alarmevent_id,
          vesselName: rawAlarmEvent.vessel_name,
          alarmName: rawAlarmEvent.alarm_name,
          date: rawAlarmEvent.date,
          alarmLevel: rawAlarmEvent.alarm_level,
        };
        return alarmEvent;
      });
      data.alarmEvents.alarmEventList.set(alarmEvents);
    };

    return apiGet(url, handler);
  }

  function fetchCustomAlarmTypes(): Promise<AlarmType[]> {
    return new Promise((resolve, reject) => {
      const url = `${theApiUrl}alarms-types/`;
      data.alarmTypes.alarmTypeList.set(null);

      const handler = (responseJson: any) => {
        console.log("API Response:", responseJson);

        if (Array.isArray(responseJson)) {
          const rawAlarmTypes = responseJson;
          const alarmTypes = rawAlarmTypes.map((rawAlarmType: any) => ({
            id: rawAlarmType.alarm_id,
            vesselName: rawAlarmType.vessel_name,
            alarmName: rawAlarmType.alarm_name,
            date: rawAlarmType.date,
            alarmLevel: rawAlarmType.alarm_level,
            alarmType: rawAlarmType.alarm_type,
            operator: rawAlarmType.operator,
            threshold: rawAlarmType.threshold,
            geoZone: rawAlarmType.geozone,
            vesselId: rawAlarmType.vessel_id,
          }));

          data.alarmTypes.alarmTypeList.set(alarmTypes);

          resolve(alarmTypes);
        } else {
          console.error("Invalid response format", responseJson);
          data.alarmTypes.alarmTypeList.set([]);
          resolve([]);
        }
      };

      apiGet(url, handler);
    });
  }

  // const fetchCallbackTotalFuelUsage = (fromString: string, toString: string, vessels: string[], callback: Function) => {
  //   const totalFuelUrl = makeUrlTotal(fromString, toString, vessels)
  //   const totalFuelHandler = (responseJson: any) => {
  //     const returnData = responseJson.data
  //     const totalFuelUsage = returnData[0][0]
  //     // console.log("got back tot: "+totalFuelUsage)
  //     callback(totalFuelUsage)
  //   }
  //   apiGet(totalFuelUrl, totalFuelHandler)
  // }
  // const fetchCallbackTotalDockingFuelUsage = (fromString: string, toString: string, vessels: string[], callback: Function) => {
  //   const totalDockFuelUrl = makeUrlTotalDock(fromString, toString, vessels)
  //   const totalDockFuelHandler = (responseJson: any) => {
  //     const returnData = responseJson.data
  //     const totalDockFuelUsage = returnData[0][0]
  //     // console.log("got back dock: "+totalDockFuelUsage)
  //     callback(totalDockFuelUsage)
  //   }
  //   apiGet(totalDockFuelUrl, totalDockFuelHandler)
  // }
  // const fetchCallbackGraphFuelUsage = (fromString: string, toString: string, vessels: string[], aggregation: Aggregation, callback: Function) => {
  //   const graphFuelUrl = makeUrlGraph(fromString, toString, vessels, aggregation)
  //   const graphFuelHandler = (responseJson: any) => {
  //     const returnData = responseJson.data
  //     const columnList = responseJson.columns
  //     const convertedData = returnData.map((data: any[]) => apiConverterGraph(data, columnList))
  //     // console.log("convertedData: ", convertedData)
  //     let graphFuelUsage
  //     if (convertedData[0] !== null) {
  //       // console.log("new batch: ", convertedData)
  //       graphFuelUsage = convertedData
  //     } else {
  //       graphFuelUsage = []
  //     }
  //     callback(graphFuelUsage)
  //   }
  //   // console.log("about to apiCallGet", graphFuelUrl)
  //   apiGet(graphFuelUrl, graphFuelHandler)
  // }
  // const fetchDataComparison = (
  //   firstStartValue: Date,
  //   firstEndDate: Date,
  //   secondStartValue: Date,
  //   secondEndDate: Date,
  //   vessel: string,
  //   firstTotalCallback: Function,
  //   secondTotalCallback: Function,
  //   firstTotalDockingCallback: Function,
  //   secondTotalDockingCallback: Function
  // ) => {
  //   const inclTime = true
  //   // console.log("second start date: ",secondStartValue)
  //   const firstStartStr = dateToString(firstStartValue, inclTime)
  //   const firstEndStr = dateToString(firstEndDate, inclTime)
  //   const secondStartStr = dateToString(secondStartValue, inclTime)
  //   const secondEndStr = dateToString(secondEndDate, inclTime)
  //   const vessels = makeVesselList(vessel, data)
  //   fetchCallbackTotalFuelUsage(firstStartStr, firstEndStr, vessels, firstTotalCallback)
  //   fetchCallbackTotalFuelUsage(secondStartStr, secondEndStr, vessels, secondTotalCallback)
  //   fetchCallbackTotalDockingFuelUsage(firstStartStr, firstEndStr, vessels, firstTotalDockingCallback)
  //   fetchCallbackTotalDockingFuelUsage(secondStartStr, secondEndStr, vessels, secondTotalDockingCallback)
  // }
  // const fetchDataReport = (
  //   startDate: Date,
  //   endDate: Date,
  //   comparisonStartDate: Date,
  //   comparisonEndDate: Date,
  //   totalCallBack: Function,
  //   graphCallBack: Function,
  //   totalComparisonCallBack: Function,
  //   graphComparisonCallBack: Function,
  //   by: string
  // ) => {
  //   const inclTime = true
  //   const startStr = dateToString(startDate, inclTime)
  //   const endStr = dateToString(endDate, inclTime)
  //   const comparisonStartStr = dateToString(comparisonStartDate, inclTime)
  //   const comparisonEndStr = dateToString(comparisonEndDate, inclTime)
  //   const vessels = makeVesselList(view.vessel, data)
  //   const agg = stringToAggregation(by)
  //   if (agg === null) {
  //     console.log("Invalid aggregation!")
  //     return
  //   }
  //   fetchCallbackTotalFuelUsage(startStr, endStr, vessels, totalCallBack)
  //   fetchCallbackTotalFuelUsage(comparisonStartStr, comparisonEndStr, vessels, totalComparisonCallBack)
  //   fetchCallbackGraphFuelUsage(startStr, endStr, vessels, agg, graphCallBack)
  //   fetchCallbackGraphFuelUsage(comparisonStartStr, comparisonEndStr, vessels, agg, graphComparisonCallBack)
  // }

  // maybe clear data anyway or show that it's unavailable somehow?

  return {
    fetchDataEnergyPage,
    fetchDataRoutes,
    fetchDataRealTimePage,
    fetchDataDestinationsTab,
    fetchDataTagTypes,
    fetchTagEvents,
    fetchDataPerformancePage,
    fetchDataReportsPage,
    fetchReportFile,
    fetchDataEnergyOperatorsTab,
    fetchDataOrganisations,
    fetchDataOverviewPageTotal,
    fetchDataRootOrgs,
    fetchDataRootVessels,
    fetchUsersGeneral,
    fetchDataRootDests,
    fetchDataRootStatus,
    fetchTripDataSecondary,
    fetchDataOpModes,
    fetchDataVoyages,
    fetchDataGeoZonesTab,
    fetchDataIntegrityStatus,
    fetchTripsTypeData,
    fetchTripsTypeSecondaryData,
    fetchPrimaryLegsTripsData,
    fetchSecondaryLegTypeData,
    fetchPrimaryEngineOnOffTripsData,
    fetchSecondaryEngineOnOffTripsData,
    fetchDataVesselGroups,
    fetchDataOrgReports,
    fetchDataAlarmHistory,
    fetchCustomAlarmTypes,
    fetchDataSignalTypes,
  };
}
