import React from 'react';
import { useAppContext } from '../../context/variables';
import TabPage from '../TabPage';
import { AccessLevel, accessLevelAtMinimum } from '../../context/types';
import { environmentConfig } from '../../env/env';

const CustomAlarmPage: React.FC = () => {
  const { vars } = useAppContext();
  const pageCfg = environmentConfig.features.customAlarms;
  if (pageCfg === false) return <></>;

  const adminOrAbove = accessLevelAtMinimum(vars.auth.accessLevel[0], AccessLevel.User);
  const tabs = [
    { name: 'Customised', path: '/custom-alarms/customised', visible: true },
    { name: 'History', path: '/custom-alarms/history', visible: true },
  ];

  return <TabPage title="Alarms" tabs={tabs} />;
};

export default CustomAlarmPage;