import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../context/variables";
import { CetasolSymbolSvg, NewCetasolLogoPng } from "./Cetasol_logo";
import { VscPlug } from "react-icons/vsc";
import { BiWrench, BiTable, BiBook } from "react-icons/bi";
import { BsPerson } from "react-icons/bs";
import { IoSpeedometerOutline, IoPersonCircleSharp } from "react-icons/io5";
import {
  TbSpeedboat,
  TbArrowBarToLeft,
  TbArrowBarToRight,
  TbSquareRoot,
} from "react-icons/tb";
import { MdLogout } from "react-icons/md"; // MdInsights
import { MenuItem, Select, SelectChangeEvent, Tooltip } from "@mui/material";
import {
  setLocalOrganisationId,
  setLocalOrganisationName,
} from "../context/locals";
import { inputSetting, inputTray } from "./variousInputs";
import { authenticationLogout } from "../auth/authentication";
import { environmentConfig, pageVisible } from "../env/env";
import MenuAlarmIcon from "../assets/icons/CustomAlarms/MenuAlarmIcon";

interface MenuBarProps {
  linkTo: string;
  buttonText: string;
  icon: JSX.Element;
  collapsed: boolean;
  visible: boolean;
}

function MenuBarItem(props: MenuBarProps) {
  const location = useLocation();
  const currentPathname = location.pathname; 
  const currentPathParts = currentPathname.split("/");

  if (!props.visible) return null;

  let currentPagePath;
  if (currentPathParts.length >= 3) {
    currentPagePath = currentPathParts[0] + "/" + currentPathParts[1];
  } else {
    currentPagePath = currentPathname;
  }

  const isSelected = currentPagePath === props.linkTo;

  return (
    <Link to={props.linkTo} className="w-full h-10">
      <Tooltip title={props.buttonText} placement="right">
        <div
          className={`w-full h-full flex items-center px-4 ${
            isSelected
              ? "bg-[#FF4D00]/20 border-l-4 border-orange-600"
              : "hover:bg-gray-700 rounded-md"
          }`}
        >
          <div className={`text-2xl ${isSelected ? "text-white" : "text-gray-400"}`}>
            {props.icon}
          </div>
          {!props.collapsed && (
            <p className={`ml-4 font-normal text-lg whitespace-nowrap ${isSelected ? "text-white" : "text-[#D6D6D6]"} font-montserrat text-[18px]`}>
              {props.buttonText}
            </p>
          )}
        </div>
      </Tooltip>
    </Link>
  );
}

export default function MenuBar() {
  const { vars, data, view } = useAppContext();
  let navigate = useNavigate();
  const username = vars.auth.username;
  const email = vars.auth.email;
  const usernameToDisplay =
    username === null
      ? "N/A"
      : email === null
      ? username
      : email + " (" + username + ")";
  const [collapsed, setCollapsed] = useState(false);

  const pagePicker = (
    <div className="flex flex-col w-full h-auto pt-[142px] pl-8 space-y-6">
      <MenuBarItem
        linkTo="/overview"
        buttonText="Home"
        icon={<IoSpeedometerOutline />}
        collapsed={collapsed}
        visible={true}
      />
      <MenuBarItem
        linkTo="/realtime"
        buttonText="Real-time"
        icon={<TbSpeedboat />}
        collapsed={collapsed}
        visible={pageVisible(environmentConfig.features.realtime)}
      />
      <MenuBarItem
        linkTo="/energy"
        buttonText="Energy Use"
        icon={<VscPlug />}
        collapsed={collapsed}
        visible={pageVisible(environmentConfig.features.energy)}
      />
      <MenuBarItem
        linkTo="/performance"
        buttonText="Performance"
        icon={<BiWrench />}
        collapsed={collapsed}
        visible={pageVisible(environmentConfig.features.performance)}
      />
      <MenuBarItem
        linkTo="/reports"
        buttonText="Reports"
        icon={<BiTable />}
        collapsed={collapsed}
        visible={pageVisible(environmentConfig.features.reports)}
      />
      <MenuBarItem
        linkTo="/logbook"
        buttonText="Logbook"
        icon={<BiBook />}
        collapsed={collapsed}
        visible={pageVisible(environmentConfig.features.logbook)}
      />
      <MenuBarItem
        linkTo="/administration"
        buttonText="Admin"
        icon={<BsPerson />}
        collapsed={collapsed}
        visible={pageVisible(environmentConfig.features.administration)}
      />
      <MenuBarItem
        linkTo="/custom-alarms"
        buttonText="Alarms"
        icon={<MenuAlarmIcon />}
        collapsed={collapsed}
        visible={pageVisible(environmentConfig.features.customAlarms)}
      />
      <MenuBarItem
        linkTo="/root"
        buttonText="Root access"
        icon={<TbSquareRoot />}
        collapsed={collapsed}
        visible={
          vars.auth.userGroups !== null && vars.auth.userGroups.includes("root")
        }
      />
    </div>
  );

  const dropdownOrgId =
    vars.auth.organisationId === null ? "" : vars.auth.organisationId;
  const orgOptions =
    data.org.organisationList === null ? [] : data.org.organisationList;
  const orgOptionsWithNone = [{ name: "--No organisation--", id: "" }].concat(
    orgOptions
  );
  const orgDropdown = (
    <Select
      inputProps={{
        "aria-hidden": true,
        tabIndex: -1,
      }}
      aria-label="Organisation"
      label="Organisation"
      value={dropdownOrgId}
      onChange={(event: SelectChangeEvent) => {
        const newOrgIdStr = event.target.value;
        const newOrgId = newOrgIdStr === "" ? null : newOrgIdStr;
        vars.auth.setOrganisationId(newOrgId);
        setLocalOrganisationId(newOrgId);
        const newOrgInfo = orgOptionsWithNone.find(
          (orgInfo) => orgInfo.id === newOrgId
        );
        const newOrgName = newOrgInfo !== undefined ? newOrgInfo.name : null;
        data.org.setOrganisationName(newOrgName);
        setLocalOrganisationName(newOrgName);
      }}
    >
      {orgOptionsWithNone.map(({ name: theName, id: theValue }, index) => (
        <MenuItem
          aria-label="Organisation"
          key={index}
          value={theValue?.toString()}
        >
          {theName}
        </MenuItem>
      ))}
    </Select>
  );

  const orgSelectComponent =
    vars.auth.userGroups?.includes("root") &&
    data.org.organisationList !== null ? (
      <div className="py-8">
        {inputTray([[inputSetting("Organisation", orgDropdown, 240)]])}
      </div>
    ) : (
      <></>
    );
  const orgSelectCollapsedComponent =
    vars.auth.userGroups?.includes("root") &&
    data.org.organisationList !== null ? (
      <div className="py-8">
        {inputTray([[inputSetting("Organisation", orgDropdown, 60)]])}
      </div>
    ) : (
      <></>
    );

  const menuBarComponent = (
    <div className="w-[310px] h-full flex-grow-0 overflow-scroll">
      <div className="w-full h-fit min-h-full bg-deep_blue flex flex-col justify-between items-center space-y-2 relative">
        <div className="absolute top-0 left-0 pt-3 pl-5 h-[70px] w-full flex justify-start">
          {NewCetasolLogoPng("#FFFFFF", 70)}
        </div>
        {pagePicker}
        {orgSelectComponent}
        <div className="flex flex-col w-full text-4xl items-center space-y-7 pb-9">
          <div className="text-white flex w-full justify-end pr-3">
            <Tooltip title={"Collapse menubar"} placement="left">
              <div
                className="text-4xl cursor-pointer text-white opacity-60 hover:opacity-90"
                onClick={() => setCollapsed(true)}
                role="button"
              >
                <TbArrowBarToLeft />
              </div>
            </Tooltip>
          </div>
          <div className="flex items-center space-x-3 w-full px-3">
            <div className="text-sky_blue text-5xl">
              <IoPersonCircleSharp />
            </div>
            <div className="flex flex-col w-4/5 items-start text-white text-center text-lg">
              <div className="text-base">{"Logged in as:"}</div>
              <Tooltip title={usernameToDisplay} placement="right">
                <div className="font-bold w-full truncate">
                  {usernameToDisplay}
                </div>
              </Tooltip>
            </div>
          </div>
          <button
            className="w-[174px] h-[56px] bg-sky_blue_dark font-bold text-2xl text-white rounded-lg flex justify-center items-center space-x-2 hover:bg-sky_blue_dark/75"
            onClick={() => authenticationLogout(vars, data, view, navigate)}
            aria-label="Log out" // Added aria-label for accessibility
          >
            <MdLogout />
          </button>
        </div>
      </div>
    </div>
  );

  const menuBarCollapsedComponent = (
    <div className="w-[90px] h-full flex-grow-0 overflow-scroll">
      <div className="w-full h-fit min-h-full bg-deep_blue flex flex-col justify-between items-center space-y-2 relative">
        <div className="absolute top-0 left-0 pt-3 pl-5 h-[70px] w-full flex justify-start">
          {CetasolSymbolSvg("#FFFFFF", 50)}
        </div>
        {pagePicker}
        {orgSelectCollapsedComponent}
        <div className="flex flex-col w-full text-4xl items-center space-y-7 pb-9">
          <Tooltip title={"Expand menubar"} placement="right">
            <div
              className="text-4xl cursor-pointer text-white opacity-60 hover:opacity-90"
              onClick={() => setCollapsed(false)}
            >
              <TbArrowBarToRight />
            </div>
          </Tooltip>
          <div className="flex items-center justify-center space-x-3 w-full px-3">
            <Tooltip title={usernameToDisplay} placement="right">
              <div className="text-sky_blue text-5xl">
                <IoPersonCircleSharp />
              </div>
            </Tooltip>
          </div>
          <Tooltip title={"Log out"} placement="right">
            <button
              className="w-[56px] h-[56px] bg-sky_blue font-bold text-2xl text-white rounded-lg flex justify-center items-center space-x-2 hover:bg-sky_blue/75"
              onClick={() => authenticationLogout(vars, data, view, navigate)}
              aria-label="Log out" 
            >
              <MdLogout />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );

  return collapsed ? menuBarCollapsedComponent : menuBarComponent;
}
