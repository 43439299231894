import React, { useState, useEffect } from "react";
import {
  AddContainer,
  FormContainer,
  Button,
  InlineContainer,
} from "../styles/AddComponentStyles";
import Dropdown from "../atoms/Dropdown";
import Input from "../atoms/Input";
import ConditionInput from "../molecules/ConditionInput";
import VesselListDropdown from "./VesselListDropdown";
import useFetchData from "../../apiComms/fetchData";
import { useAppContext } from "../../context/variables";
import GeoZoneListDropdown from "./GeoZonesListDropdown";

interface AddComponentProps {
  onSave: (data: { [key: string]: any }, resetForm: () => void) => void;
  fields: Array<{
    label: string;
    name: string;
    type: string;
    placeholder?: string;
    options?: Array<{ value: string; label: string }>;
  }>;
  options: {
    alarmLevelOptions: Array<{ value: string; label: string }>;
    alarmTypeOptions?: Array<{ value: string; label: string }>;
  };
  includeVesselDropdown?: boolean;
  apiKey: string;
  vars: any;
  selectedGeoZone: string;
  setSelectedGeoZone: (geoZone: string) => void;
}

const AddComponent: React.FC<AddComponentProps> = ({
  onSave,
  fields,
  options = { alarmLevelOptions: [], alarmTypeOptions: [] },
  includeVesselDropdown = false,
  apiKey,
  vars,
  selectedGeoZone,
  setSelectedGeoZone,
}) => {
  const initialState = fields.reduce((acc, field) => {
    acc[field.name] = "";
    return acc;
  }, {} as { [key: string]: string });

  const [formData, setFormData] = useState(initialState);
  const [condition, setCondition] = useState<{
    parameter: string;
    comparison: string;
    value: string;
  }>({ parameter: "Signal", comparison: "higher", value: "" });
  const [geoZones, setGeoZones] = useState<Array<any>>([]);
  const { data } = useAppContext();
  const { fetchDataGeoZonesTab } = useFetchData();

  const resetForm = () => {
    setFormData(initialState);
    setCondition({ parameter: "Signal", comparison: "higher", value: "" });
    setSelectedGeoZone("");
  };

  useEffect(() => {
    const fetchGeoZones = async () => {
      await fetchDataGeoZonesTab();
    };

    fetchGeoZones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this runs only once

  useEffect(() => {
    if (data.org.geoZoneList?.val) {
      setGeoZones(data.org.geoZoneList.val);
    }
  }, [data.org.geoZoneList?.val]);

  const handleChange = (name: string, value: string) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleConditionChange = (field: string, value: string) => {
    setCondition({
      ...condition,
      [field]: value,
    });
    if (field === "parameter") {
      setFormData({
        ...formData,
        alarmType: value,
      });
    } else if (field === "comparison") {
      setFormData({
        ...formData,
        comparison: value,
      });
    } else if (field === "value") {
      setFormData({
        ...formData,
        threshold: value,
      });
    }
  };

  const handleSubmit = () => {
    onSave({ ...formData, condition: JSON.stringify(condition) }, resetForm);
  };

  return (
    <AddContainer>
      <FormContainer>
        <Input
          id="alarmName"
          type="text"
          name="alarmName"
          placeholder="Alarm name"
          value={formData["alarmName"]}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
        {includeVesselDropdown && (
          <VesselListDropdown
            value={formData["vessel"]}
            onChange={(value) => handleChange("vessel", value)}
            width="100%"
          />
        )}
        <InlineContainer>
          <Dropdown
            name="alarmLevel"
            value={formData["alarmLevel"]}
            options={options.alarmLevelOptions || []}
            onChange={handleChange}
            placeholder="Alarm level"
            width="100%"
          />
          <GeoZoneListDropdown
            value={selectedGeoZone}
            onChange={setSelectedGeoZone}
            placeholder="Select geo-zone"
            width="100%"
          />
        </InlineContainer>
        <ConditionInput
          condition={condition}
          onChange={handleConditionChange}
          onRemove={() => {}}
          disabled={false}
          alarmTypeOptions={options.alarmTypeOptions || []}
        />
        <Button onClick={handleSubmit}>Save</Button>
      </FormContainer>
      {/* // commented the map, it will be available for version two  */}
      {/* {hasGeoZone && (
        <MapContainer>
          <GeoZonesMapCustomAlarms
            geoZones={geoZones}
            selectedZone={selectedGeoZone}
            onZoneSelect={(zoneId) => setSelectedGeoZone(zoneId)}
          />
        </MapContainer>
      )} */}
    </AddContainer>
  );
};

export default AddComponent;
