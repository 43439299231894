import React from "react";
import TableCell from "../atoms/TableCell";
import {
  StyledTable,
  StyledTableHead,
  StyledTableRow,
} from "../styles/DynamicTableStyles";
import { WithSorting } from "../helpers/WithSorting";
import {
  CustomStyledTable,
  CustomStyledTableHead,
  CustomStyledTableRow,
  CustomStyledTableCell,
} from "../styles/CustomisedTableStyles";

interface DynamicTableProps {
  headers: { name: string }[];
  data: any[];
  renderRow: (data: any) => React.ReactNode[];
  showActions?: boolean;
  renderActions?: (data: any) => React.ReactNode;
  sortableColumns?: string[];
  customSort?: (a: any, b: any, column: string, sortOrder: string) => number;
  disableSorting?: boolean;
  isCustomizedTab?: boolean;
}

const DynamicTable: React.FC<DynamicTableProps> = ({
  headers,
  data,
  renderRow,
  showActions,
  renderActions,
  sortableColumns = [],
  customSort,
  disableSorting = false,
  isCustomizedTab = false, // Default to false
}) => {
  const { sortedData, handleSort, renderSortIcon } = WithSorting(
    data,
    headers,
    sortableColumns,
    customSort,
    disableSorting
  );

  const TableComponent = isCustomizedTab ? CustomStyledTable : StyledTable;
  const TableHeadComponent = isCustomizedTab
    ? CustomStyledTableHead
    : StyledTableHead;
  const TableRowComponent = isCustomizedTab
    ? CustomStyledTableRow
    : StyledTableRow;
  const TableCellComponent = isCustomizedTab
    ? CustomStyledTableCell
    : TableCell;

  return (
    <div className="relative overflow-x-auto">
      <TableComponent>
        <TableHeadComponent>
          <tr>
            {headers.map((header, index) => (
              <TableCellComponent
                key={index}
                isHeader
                onClick={() => handleSort(header.name)}
                isIcon={header.name === "Alarm Level"}
                isFirst={index === 0}
                isLast={index === headers.length - 1}
              >
                <div
                  className={`flex items-center cursor-pointer ${
                    header.name === "Alarm Level" ? "justify-center" : ""
                  }`}
                >
                  {header.name}
                  {renderSortIcon(header.name)}
                </div>
              </TableCellComponent>
            ))}
            {showActions && (
              <TableCellComponent isHeader isLast={true}>
                Actions
              </TableCellComponent>
            )}
          </tr>
        </TableHeadComponent>
        <tbody>
          {sortedData.map((row: any, rowIndex: number) => (
            <TableRowComponent key={rowIndex}>
              {renderRow(row).map((cell, colIndex) => (
                //TODO: Change TableCellComponent to something else that is not <td></td>
                <TableCellComponent
                  key={colIndex}
                  isFirst={colIndex === 0}
                  isLast={colIndex === headers.length - 1}
                >
                  {cell}
                </TableCellComponent>
              ))}
            </TableRowComponent>
          ))}
        </tbody>
      </TableComponent>
    </div>
  );
};

export default DynamicTable;
