import React, { useCallback, useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { HiPencil } from "react-icons/hi";

import { makeSubmitButton, iconButton } from "../../../components/Button";
import { makeConfirmModal, makeModal } from "../../../components/Modal";
import {
  getTextFieldValueById,
  makeDropdownInput,
  makeInput,
  makeRadioInput,
  makeTextInputSmall,
  makeTextOutput,
} from "../../../components/FormInputs";
import {
  coordinates,
  coords,
  coordsLindholmspiren,
  initializeMap,
  latToPrettyString,
  lngToPrettyString,
  makeLatLng,
  mapIconRedballoon,
} from "../../../components/Map";
import LoadingSpinner from "../../../components/atoms/LoadingSpinner";

import {
  GeoZoneEdgeType,
  EntityState,
  AccessLevel,
  accessLevelAtMinimum,
} from "../../../context/types";
import { geoZonesEntry, useAppContext } from "../../../context/variables";

import useManageData from "../../../apiComms/manageData";
import { strNullToString } from "../../../helpers/stringHelpers";

const defaultGeoZonesCoords = coordsLindholmspiren;
const defaultGeoZonesRadius = 40;
function simplePolyAroundCoords(coords: coords): coords[] {
  return [
    { lat: coords.lat + 0.001, lng: coords.lng },
    { lat: coords.lat, lng: coords.lng + 0.001 },
    { lat: coords.lat - 0.001, lng: coords.lng },
    { lat: coords.lat, lng: coords.lng - 0.001 },
  ];
}
const defaultGeoZonesPoly: coords[] = simplePolyAroundCoords(
  defaultGeoZonesCoords
);
const defaultGeoZonesZoom = 15;

let addGeoZonesMarker: google.maps.Marker | undefined;
let addGeoZonesCircle: google.maps.Circle | undefined;
let addGeoZonesPoly: google.maps.Polygon | undefined;
function AddGeoZonesMap(
  map: google.maps.Map | undefined,
  setMap: (map: google.maps.Map | undefined) => void,
  modalOpen: boolean,
  coords: coords,
  setCoords: (coords: coords) => void,
  setRadius: (radius: number) => void,
  geoZoneEdgeType: GeoZoneEdgeType,
  setPolyLength: (length: number) => void,
  setMarkerInPoly: (isInside: boolean) => void,
  closeWritingFields: () => void,
  GeoZonesListFiltered: geoZonesEntry[] | null
) {
  const { data } = useAppContext();
  const [mapInitialized, setMapInitialized] = useState(false);
  const mapElementId = "add_geoZone_map";

  useEffect(() => {
    if (modalOpen) {
      initializeMap(
        map,
        setMap,
        mapElementId,
        mapInitCustom,
        setMapInitialized,
        defaultGeoZonesCoords,
        defaultGeoZonesZoom
      );
    } else {
      setMapInitialized(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  function moveEverythingToExistingGeoZones() {
    if (
      GeoZonesListFiltered !== null &&
      GeoZonesListFiltered.length > 0 &&
      mapInitialized
    ) {
      const anExistingGeoZones = GeoZonesListFiltered[0];

      let coordinates: { latitude: string; longitude: string }[] = [];
      if (anExistingGeoZones.shape.type === "Circle") {
        // For Circle shape, access the single set of coordinates directly
        coordinates = [
          {
            latitude: anExistingGeoZones.shape.coordinates[0].latitude,
            longitude: anExistingGeoZones.shape.coordinates[0].longitude,
          },
        ];
      } else if (anExistingGeoZones.shape.type === "Polygon") {
        // For Polygon shape, access all coordinates in the "coords" array
        coordinates = anExistingGeoZones.shape.coordinates;
      }

      if (coordinates.length > 0) {
        const latitude = parseFloat(coordinates[0].latitude);
        const longitude = parseFloat(coordinates[0].longitude);

        const nearbyCoords = makeLatLng(latitude, longitude - 0.01);

        setCoords(nearbyCoords);

        if (anExistingGeoZones.shape.type === "Polygon") {
          addGeoZonesPoly?.setPath(simplePolyAroundCoords(nearbyCoords));
        } else if (anExistingGeoZones.shape.type === "Circle") {
          addGeoZonesCircle?.setCenter(nearbyCoords);
        }

        setMarkerInPoly(true);

        if (map) {
          map.setCenter(nearbyCoords);
        }
      }
    }
  }

  function updateShapeVisibility() {
    if (geoZoneEdgeType === GeoZoneEdgeType.Circle) {
      addGeoZonesCircle?.setVisible(true);
      addGeoZonesPoly?.setVisible(false);
    } else {
      addGeoZonesCircle?.setVisible(false);
      addGeoZonesPoly?.setVisible(true);
    }
  }

  useEffect(() => {
    if (mapInitialized) moveEverythingToExistingGeoZones();
    if (mapInitialized) updateShapeVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.org.geoZoneList, mapInitialized, geoZoneEdgeType]);

  function checkIfMarkerIsInPoly() {
    const markerPosNow = addGeoZonesMarker?.getPosition();
    if (
      addGeoZonesPoly !== undefined &&
      markerPosNow !== undefined &&
      markerPosNow !== null
    ) {
      const markerInPoly = google.maps.geometry.poly.containsLocation(
        markerPosNow,
        addGeoZonesPoly
      );
      setMarkerInPoly(markerInPoly);
    }
  }

  useEffect(() => {
    const length = addGeoZonesPoly?.getPath().getLength();

    if (length !== undefined) {
      setPolyLength(length);
    }
    addGeoZonesPoly?.getPath().addListener("insert_at", () => {
      const length = addGeoZonesPoly?.getPath().getLength();

      if (length !== undefined) {
        setPolyLength(length);
      }
      checkIfMarkerIsInPoly();
      closeWritingFields();
    });
    addGeoZonesPoly?.getPath().addListener("remove_at", () => {
      const length = addGeoZonesPoly?.getPath().getLength();

      if (length !== undefined) {
        setPolyLength(length);
      }
      checkIfMarkerIsInPoly();
      closeWritingFields();
    });
    addGeoZonesPoly?.getPath().addListener("set_at", () => {
      checkIfMarkerIsInPoly();
      closeWritingFields();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addGeoZonesPoly, geoZoneEdgeType]);

  function mapInitCustom(map: google.maps.Map) {
    addGeoZonesMarker = new google.maps.Marker({
      draggable: true,
      map: map,
      position: coords,
      icon: mapIconRedballoon(),
    });
    addGeoZonesMarker.addListener("position_changed", () => {
      const pos = addGeoZonesMarker?.getPosition();
      if (pos !== null && pos !== undefined) {
        setCoords(makeLatLng(pos.lat(), pos.lng()));
      }
      checkIfMarkerIsInPoly();
      closeWritingFields();
    });
    addGeoZonesCircle = new google.maps.Circle({
      map: map,
      center: coords,
      radius: defaultGeoZonesRadius,
      strokeColor: "#ff4d00",
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: "#ff4d00",
      fillOpacity: 0.5,
      draggable: false,
      editable: true,
    });
    addGeoZonesCircle.addListener("radius_changed", () => {
      const rad = addGeoZonesCircle?.getRadius();

      if (rad !== undefined) {
        setRadius(rad);
      }
      closeWritingFields();
    });
    addGeoZonesCircle.bindTo("center", addGeoZonesMarker, "position");
    addGeoZonesPoly = new google.maps.Polygon({
      map: map,
      paths: defaultGeoZonesPoly,
      strokeColor: "#ff4d00",
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: "#ff4d00",
      fillOpacity: 0.5,
      editable: true,
      draggable: true,
      geodesic: true,
    });
    addGeoZonesPoly.addListener(
      "dblclick",
      (mev: google.maps.PolyMouseEvent) => {
        const length = addGeoZonesPoly?.getPath().getLength();

        if (mev.vertex != null && length !== undefined && length > 3) {
          addGeoZonesPoly?.getPath().removeAt(mev.vertex);
        }
        closeWritingFields();
      }
    );
  }

  if (mapInitialized) {
    if (geoZoneEdgeType === GeoZoneEdgeType.Circle) {
      addGeoZonesCircle?.setVisible(true);
      addGeoZonesPoly?.setVisible(false);
    } else {
      addGeoZonesCircle?.setVisible(false);
      addGeoZonesPoly?.setVisible(true);
    }
  }

  return (
    <div
      id={mapElementId}
      className="w-full h-full rounded-xl text-black bg-[#8c8c8c] border-2 shadow-[4px_4px_8px_2px_rgba(0,0,0,0.1)]"
    />
  );
}

export function AddGeoZoneModal(
  open: boolean,
  setOpen: (open: boolean) => void,
  GeoZonesListFiltered: geoZonesEntry[] | null
) {
  const { vars, data } = useAppContext();
  const [geoZoneName, setGeoZoneName] = useState<string>("");
  const [confirmAddGeoZonesModalOpen, setConfirmAddGeoZonesModalOpen] =
    useState(false);
  const { apiPostGeoZonesAdd } = useManageData();
  const [shapeType, setShapeType] = useState<GeoZoneEdgeType>(
    GeoZoneEdgeType.Circle
  );

  const handleGeoZoneNameChange = (e: { target: { value: string } }) => {
    setGeoZoneName(e.target.value);
  };

  const [selectedShapeType, setSelectedShapeType] = useState<GeoZoneEdgeType>(
    GeoZoneEdgeType.Circle
  );
  const [geoZoneNameWarning, setGeoZonesNameWarning] = useState<string | null>(
    null
  );
  const [coordsWarning, setCoordsWarning] = useState<string | null>(null);
  const [radiusWarning, setRadiusWarning] = useState<string | null>(null);
  const [orgWarning, setOrgWarning] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  // ----- Standard modal bits -----
  function clearWarnings() {
    setGeoZonesNameWarning(null);
    setCoordsWarning(null);
    setRadiusWarning(null);
    setOrgWarning(null);
  }
  function resetValues() {
    setCoords(defaultGeoZonesCoords);
    setWritingCoords(false);
    setGeoZonesEdgeType(GeoZoneEdgeType.Circle);
    setRadius(defaultGeoZonesRadius);
    setWritingRadius(false);
    setChosenOrg(vars.auth.organisationId);
    setChosenGeoZonestate(EntityState.Active);
  }
  function onClose() {
    setOpen(false);
    clearWarnings();
    resetValues();
  }

  function onSubmitClick() {
    clearWarnings();
    closeWritingFields();

    if (!geoZoneName) {
      setGeoZonesNameWarning("GeoZones name required");
      return;
    }

    if (geoZoneEdgeType === GeoZoneEdgeType.Circle && Math.round(radius) < 10) {
      setRadiusWarning("Radius must be at least 10m");
      return;
    }

    setConfirmAddGeoZonesModalOpen(true);
  }

  type SingleCoord = { latitude: number; longitude: number };
  type MultipleCoords = SingleCoord[];

  async function onConfirmClick() {
    const geoZoneName = getTextFieldValueById("geoZoneName");

    if (!geoZoneName) {
      console.error("Geo Zone name is required.");
      return;
    }

    let shapeCoords: coordinates;

    if (shapeType === GeoZoneEdgeType.Circle) {
      shapeCoords = { latitude: coords.lat, longitude: coords.lng };
    } else {
      const polygonCoords = getPolygonCoordsFromMap();
      shapeCoords = {
        latitude: polygonCoords[0].latitude,
        longitude: polygonCoords[0].longitude,
      };
    }
    setLoading(true);

    try {
      apiPostGeoZonesAdd(
        chosenOrg,
        geoZoneName,
        shapeType,
        shapeCoords,
        shapeType === GeoZoneEdgeType.Circle ? Math.round(radius) : undefined,
        chosenGeoZonestate
      );
      setConfirmAddGeoZonesModalOpen(false);
      onClose();
      setGeoZoneName("");
    } catch (error) {
      console.error("Error adding Geo Zone:", error);
    } finally {
      setLoading(false);
    }
  }

  function getPolygonCoordsFromMap(): MultipleCoords {
    const polyPath = addGeoZonesPoly?.getPath();
    const polyArray = polyPath?.getArray();

    if (!Array.isArray(polyArray) || polyArray.length === 0) {
      console.error("No polygon coordinates defined.");
      return [];
    }

    return polyArray
      .map((point) => ({
        latitude: point.lat(),
        longitude: point.lng(),
      }))
      .filter((coord) => coord.latitude !== null && coord.longitude !== null);
  }

  // ----- Map -----
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);

  // ----- Coordinates -----
  const coordsTitle = "Coordinates (lat, long):";
  const coordsTooltip =
    'The coordinates of the GeoZones. Edit in the map by moving the pin, or by typing lat & long (as one number each, use "." for decimals, use negative values for °S and °W)'; // (a latitude between -90 and 90 and a longitude between -180 and 180)"
  const [coords, setCoords] = useState(defaultGeoZonesCoords);
  const newCoordPrettyStr =
    latToPrettyString(coords.lat) + ", " + lngToPrettyString(coords.lng);
  const [writingCoords, setWritingCoords] = useState(false);
  const coordsReadComp = (
    <div className="flex space-x-2 items-center">
      {makeTextOutput(coordsTitle, coordsTooltip, newCoordPrettyStr, null)}
      <Tooltip title="Edit by typing" placement="right">
        <div
          className="cursor-pointer"
          key={"edit"}
          onClick={() => {
            setWritingCoords(true);
            setWritingRadius(false);
          }}
        >
          <HiPencil className="text-2xl" />
        </div>
      </Tooltip>
    </div>
  );
  const emptyCoordsWarning = coordsWarning === null ? null : "";
  const coordsWriteComp = (
    <div className="flex space-x-2 items-center text-xl">
      {makeTextInputSmall(
        coordsTitle,
        coordsTooltip,
        "° Lat",
        "newGeoZonesWriteLat",
        emptyCoordsWarning,
        coords.lat.toString()
      )}
      <div className="flex space-x-6 justify-start items-center text-xl">
        {","}
      </div>
      {makeTextInputSmall(
        null,
        null,
        "° Long",
        "newGeoZonesWriteLng",
        coordsWarning,
        coords.lng.toString()
      )}
      {iconButton("Set", <></>, () => {
        const writeLatStr = (
          document.getElementById("newGeoZonesWriteLat") as HTMLInputElement
        ).value;
        const writeLat = Number(writeLatStr);
        const writeLngStr = (
          document.getElementById("newGeoZonesWriteLng") as HTMLInputElement
        ).value;
        const writeLng = Number(writeLngStr);

        if (writeLatStr.length < 1 || writeLngStr.length < 1) {
          setCoordsWarning("Please enter a latitude and a longitude");
        } else if (isNaN(writeLat) || isNaN(writeLng)) {
          setCoordsWarning("Coordinates must be numerical");
        } else if (
          writeLat > 90 ||
          writeLat < -90 ||
          writeLng > 180 ||
          writeLng < -180
        ) {
          setCoordsWarning("Coordinates must be within possible bounds");
        } else {
          const writeCoords = makeLatLng(writeLat, writeLng);
          addGeoZonesCircle?.setCenter(writeCoords);
          map?.setCenter(writeCoords);
          setCoords(writeCoords);
          setCoordsWarning(null);
          setWritingCoords(false);
        }
      })}
    </div>
  );
  const coordsComp = writingCoords ? coordsWriteComp : coordsReadComp;

  // ----- Edge type -----
  const [geoZoneEdgeType, setGeoZonesEdgeType] = useState(
    GeoZoneEdgeType.Circle
  );
  useEffect(() => {
    addGeoZonesPoly?.setPath(simplePolyAroundCoords(coords));
    setMarkerInPoly(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoZoneEdgeType]);
  const geoZonesEdgeTypeOptions: {
    value: GeoZoneEdgeType;
    name: string;
  }[] = Object.values(GeoZoneEdgeType).map((level) => {
    return { value: level, name: level };
  });

  useEffect(() => {
    updateShapeVisibility();
  }, [geoZoneEdgeType]);

  function updateShapeVisibility() {
    if (addGeoZonesCircle && addGeoZonesPoly) {
      if (geoZoneEdgeType === GeoZoneEdgeType.Circle) {
        addGeoZonesCircle.setVisible(true);
        addGeoZonesPoly.setVisible(false);
      } else {
        addGeoZonesCircle.setVisible(false);
        addGeoZonesPoly.setVisible(true);
      }
    }
  }

  const edgeTypePicker = makeRadioInput(
    "Edge shape:",
    "How to define the edge of what counts as the Geo Zone",
    geoZoneEdgeType,
    geoZonesEdgeTypeOptions,
    (newEdgeType) => {
      setGeoZonesEdgeType(newEdgeType);
      setShapeType(newEdgeType);
      updateShapeVisibility();
    }
  );

  // ----- Radius -----
  const radiusTitle = "Radius:";
  const radiusTooltip =
    "The radius of the circular edge of what counts as the Geo Zone. Edit in the map or by typing. Must be at least 10 m";
  const [radius, setRadius] = useState(defaultGeoZonesRadius);
  const roundedRadius = Math.round(radius);
  const [writingRadius, setWritingRadius] = useState(false);
  const readRadiusWarning = roundedRadius < 10 ? "Radius under 10 m" : null;
  const readRadiusField = (
    <div className="flex space-x-2 items-center">
      {makeTextOutput(
        radiusTitle,
        radiusTooltip,
        roundedRadius + " meters",
        readRadiusWarning
      )}
      <Tooltip title="Edit by typing" placement="right">
        <div
          className="cursor-pointer"
          key={"edit"}
          onClick={() => {
            setWritingRadius(true);
            setWritingCoords(false);
          }}
        >
          <HiPencil className="text-2xl" />
        </div>
      </Tooltip>
    </div>
  );
  const writeRadiusField = (
    <div className="flex space-x-2 items-center">
      {makeTextInputSmall(
        radiusTitle,
        radiusTooltip,
        defaultGeoZonesRadius.toString(),
        "newGeoZonesWriteRadius",
        radiusWarning,
        roundedRadius.toString()
      )}
      <div className="flex space-x-6 justify-start items-center text-xl">
        {"meters"}
      </div>
      {iconButton("Set", <></>, () => {
        const writeRadiusStr = (
          document.getElementById("newGeoZonesWriteRadius") as HTMLInputElement
        ).value;
        const writeRadius = Number(writeRadiusStr);
        if (writeRadiusStr.length < 1) {
          setRadiusWarning("Please enter a number");
        } else if (isNaN(writeRadius)) {
          setRadiusWarning("Radius must be numerical");
        } else if (writeRadius < 10) {
          setRadiusWarning("Radius under 10 m");
        } else {
          addGeoZonesCircle?.setRadius(writeRadius);
          setRadiusWarning(null);
          setWritingRadius(false);
        }
      })}
    </div>
  );
  const radiusField = writingRadius ? writeRadiusField : readRadiusField;

  // ----- Polygon -----
  const [polyLength, setPolyLength] = useState(defaultGeoZonesPoly.length);
  const [markerInPoly, setMarkerInPoly] = useState(true);
  const polygonField = makeTextOutput(
    "Polygon:",
    "The edge of what counts as the Geo Zone. Edit in the map, remove a point by double-clicking it. Must have between 3 and 12 corners",
    polyLength + " corners, see map",
    polyLength > 12
      ? "Maximum of 12 corners allowed"
      : !markerInPoly
      ? "Coordinates must be within polygon"
      : null
  );

  // ----- Organisation -----
  useEffect(() => {
    setChosenOrg(vars.auth.organisationId);
  }, [vars.auth.organisationId]);
  const [chosenOrg, setChosenOrg] = useState<string | null>(null);
  const orgOptions =
    data.org.organisationList === null
      ? []
      : data.org.organisationList.map((org) => {
          return { value: org.id, name: org.name };
        });
  const rootOrgPicker = makeDropdownInput(
    "Organisation:",
    "The organisation which the geoZone belongs to",
    "Organisation",
    chosenOrg,
    orgOptions,
    setChosenOrg,
    orgWarning
  );

// ----- Geo Zone state -----
const [chosenGeoZonestate, setChosenGeoZonestate] = useState<EntityState>(
  EntityState.Active // Set the default state to Active
);
  const stateOptions: { value: EntityState; name: string }[] = Object.values(
    EntityState
  ).map((state) => {
    return { value: state, name: state };
  });

  // Check if the user is a root user
  const isRootUser = accessLevelAtMinimum(
    vars.auth.accessLevel[0],
    AccessLevel.Root
  );

  // Use useEffect to check for changes in isRootUser
  useEffect(() => {
    if (!isRootUser) {
      setChosenGeoZonestate(EntityState.Active);
    }
  }, [isRootUser]);

  const rootStatePicker = makeDropdownInput(
    "Geo Zone state:",
    "The state of the Geo Zone. Determines whether it will be shown to customers as a suggestion, it is seen onboard, it is hidden, or it will be used to avoid generating the same suggestion twice.",
    "State",
    chosenGeoZonestate,
    stateOptions,
    setChosenGeoZonestate,
    null,
    !isRootUser
  );

  // ----- The confirmation modal -----
  const geoZonesNameField = document.getElementById(
    "geoZonesName"
  ) as HTMLInputElement;
  const confirmTextEdgePart =
    shapeType === GeoZoneEdgeType.Circle // change here
      ? "Circle with a radius of " + roundedRadius + " meters"
      : "Polygon with " + polyLength + " corners";

  const confirmStandardBit = [
    "You are about to add the following Geo Zone:",
    `- Name: "${geoZoneName}"`, // Use the state directly here
    "- Coordinates: " + newCoordPrettyStr,
    "- Shape: " + confirmTextEdgePart,
  ];
  const chosenOrgObj = orgOptions.find((org) => org.value === chosenOrg);
  const chosenOrgName = chosenOrgObj?.name;
  const confirmRootBit = [
    "- Org: " + strNullToString(chosenOrg) + " (" + chosenOrgName + ")",
    "- State: " + chosenGeoZonestate,
  ];
  const confirmText = vars.auth.userGroups?.includes("root")
    ? confirmStandardBit.concat(confirmRootBit)
    : confirmStandardBit;
  const confirmModal = makeConfirmModal(
    "Confirm adding Geo Zone",
    confirmText,
    "Confirm: Add Geo Zone",
    onConfirmClick,
    confirmAddGeoZonesModalOpen,
    setConfirmAddGeoZonesModalOpen
  );

  // ----- Putting together the modal -----
  function closeWritingFields() {
    setWritingCoords(false);
    setWritingRadius(false);
  }
  useEffect(() => {
    closeWritingFields();
  }, [geoZoneEdgeType, chosenOrg, chosenGeoZonestate]);

  type textEntryWarningTextPropsGeoZones = {
    prompt: string;
    name: string;
    type: string;
    warningStatus: boolean;
    warningText: string;
    onChange: (event: { target: { value: any } }) => void;
    defaultValue?: string;
  };

  interface textEntryPropsGeoZones {
    prompt: string;
    name: string;
    type: string;
    warningStatus: boolean;
    warningText?: string;
    defaultValue?: string;
    onChange: (event: { target: { value: any } }) => void;
  }

  const TextEntryGeoZones = (props: textEntryPropsGeoZones) => {
    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event);
      },
      [props.onChange]
    );

    const inputComponent = (
      <input
        autoFocus
        className={
          "w-[370px] h-[70px] border-[1px] border-deep_blue px-3 rounded-lg placeholder:italic"
        }
        value={props.defaultValue}
        placeholder={props.prompt}
        type={props.type}
        name={props.name}
        id={props.name}
        onChange={handleChange}
      />
    );

    return props.warningStatus ? (
      <div className="w-[370px] relative">
        {inputComponent}
        <div className="absolute w-max right-0 font-normal text-lg text-high_orange h-0">
          {props.warningText || ""}{" "}
        </div>
      </div>
    ) : (
      inputComponent
    );
  };

  function TextEntryWarningTextGeoZones(
    props: textEntryWarningTextPropsGeoZones
  ) {
    return (
      <div className="w-[370px] relative">
        <TextEntryGeoZones
          prompt={props.prompt}
          name={props.name}
          type={props.type}
          warningStatus={props.warningStatus}
          defaultValue={props.defaultValue}
          onChange={props.onChange}
        />
        <div className="absolute w-max right-0 font-normal text-lg text-high_orange h-0">
          {props.warningText}
        </div>
      </div>
    );
  }

  function makeTextInputGeoZone(
    title: string,
    tooltipText: string,
    fieldPrompt: string,
    fieldHandle: string,
    warning: string | null,
    onChange: (event: { target: { value: any } }) => void,
    defaultValue?: string
  ) {
    const warningStatus = warning !== null;
    const warningText = warningStatus ? warning : "";
    const inputComponent = (
      <TextEntryWarningTextGeoZones
        prompt={fieldPrompt}
        name={fieldHandle}
        type="text"
        warningStatus={warningStatus}
        warningText={warningText}
        onChange={onChange}
        defaultValue={defaultValue}
      />
    );
    return makeInput(title, tooltipText, inputComponent);
  }

  const geoZoneNameInput = makeTextInputGeoZone(
    "Geo Zone Name",
    "A Geo Zone Name uniquely identifies your geographical area of interest.",
    "Enter Geo Zone Name",
    "geoZoneName",
    geoZoneNameWarning ? "Geo Zone Name is needed" : null,
    handleGeoZoneNameChange,
    geoZoneName
  );

  const addGeoZonesContents = (
    <div className="flex space-x-6">
      <div className="flex flex-col space-y-6">
        {geoZoneNameInput}
        {coordsComp}
        {edgeTypePicker}
        {geoZoneEdgeType === GeoZoneEdgeType.Circle
          ? radiusField
          : polygonField}
        {vars.auth.userGroups?.includes("root") ? rootOrgPicker : <></>}
        {vars.auth.userGroups?.includes("root") ? rootStatePicker : <></>}
        {makeSubmitButton(onSubmitClick)}
        {loading && <LoadingSpinner />}
        {confirmModal}
      </div>
      <div className="w-[36rem] h-[36rem]">
        {AddGeoZonesMap(
          map,
          setMap,
          open,
          coords,
          setCoords,
          setRadius,
          geoZoneEdgeType,
          setPolyLength,
          setMarkerInPoly,
          closeWritingFields,
          GeoZonesListFiltered
        )}
      </div>
    </div>
  );
  return makeModal("Add new Geo Zone", addGeoZonesContents, open, onClose);
}
