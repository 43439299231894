import { useEffect, useState } from "react"
import { apiData, useAppContext } from "../../../context/variables"
import { makeSubmitButton } from "../../../components/Button"
import { makeConfirmModal, makeModal } from "../../../components/Modal"
import { makeCheckboxInput, makeDropdownInput, makeRadioInput, makeTextInput } from "../../../components/FormInputs"
import { AccessLevel } from "../../../context/types"
import { legalPin, legalUsername } from "../MembersTab"
import useManageData from "../../../apiComms/manageData"
import { booleanYesNoString } from "../../../helpers/stringHelpers"
import { getTextFieldContentsPretty, getTextFieldValue, isTextFieldValueEmpty } from "../../../helpers/elementHelpers"
import { makeAccessLevelOption } from "./manage/ManageUserModal"

export default function AddUserModal(open: boolean, setOpen: (open: boolean)=>void, data: apiData) {
  const { apiUserAdd } = useManageData()
  const { vars } = useAppContext()

  const [nameWarning, setNameWarning] = useState<string|null>(null)
  const [orgWarning, setOrgWarning] = useState<string|null>(null)
  const [emailWarning, setEmailWarning] = useState<string|null>(null)
  const [pinWarning, setPinWarning] = useState<string|null>(null)

  const orgOptionsList = (data.rootAccess.orgs === null) ? [] : data.rootAccess.orgs.map((org)=>{return {value: org.uid, name: org.name}})
  const orgOptions = [{value: "noOrg", name: "No organisation"}].concat(orgOptionsList)
  useEffect(() => {
    setChosenOrg(vars.auth.organisationId === null ? "noOrg" : vars.auth.organisationId)
  }, [vars.auth.organisationId])
  const [chosenOrg, setChosenOrg] = useState<string|null>("noOrg")
  const orgChanged = (value: string|null)=>{
    // console.log("value changed to:", value)
    setChosenOrg(value)
  }
  // useEffect(() => {
  //   if(data.rootAccess.orgs.length > 0) {
  //     orgChanged(data.rootAccess.orgs[0].uid)
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data.rootAccess.orgs])
  const rootOrgPicker = makeDropdownInput("Org:", "The org which the user belongs to", "Org", chosenOrg, orgOptions, orgChanged, orgWarning)

  const subjectUserAccessLevel = vars.auth.accessLevel[0]
  const subjectIsRoot = subjectUserAccessLevel === AccessLevel.Root
  const subjectIsOwner = subjectUserAccessLevel === AccessLevel.Owner

  const accessLevels = 
    subjectIsRoot ? Object.values(AccessLevel)
    : subjectIsOwner ? [AccessLevel.User, AccessLevel.Admin]
    : [AccessLevel.User]
  const accessLevelOptions: {value: AccessLevel, name: string}[] = accessLevels.map(makeAccessLevelOption)
  const [chosenAccessLevel, setChosenAccessLevel] = useState(AccessLevel.User)

  const [chosenWebAppStatus, setChosenWebAppStatus] = useState(true)

  const [chosenOperatorStatus, setChosenOperatorStatus] = useState(true)

  const [confirmAddUserModalOpen, setConfirmAddUserModalOpen] = useState(false)

  function clearWarnings() {
    setNameWarning(null)
    setOrgWarning(null)
    setEmailWarning(null)
    setPinWarning(null)
  }
  function resetValues() {
    orgChanged(vars.auth.organisationId === null ? "noOrg" : vars.auth.organisationId)
    setChosenAccessLevel(AccessLevel.User)
    setChosenOperatorStatus(true)
    setChosenWebAppStatus(true)
  }
  function onClose() {
    setOpen(false)
    clearWarnings()
    resetValues()
  }

  function onSubmitClick() {
    // console.log("Hit submit add user")
    clearWarnings()
    const theUserName = getTextFieldValue("userName")
    const theUserEmail = getTextFieldValue("userEmail")
    const theUserPin = getTextFieldValue("userPin")
    if(theUserName === null || theUserName.length < 1) {
      // console.log("User name not OK")
      setNameWarning("Please enter a name")
    } else if(chosenOrg === null) {
      // console.log("Org not OK")
      setOrgWarning("Org not OK")
    } else if(chosenAccessLevel !== AccessLevel.Root && chosenOrg === "noOrg") {
      // console.log("Non-root without org is not allowed")
      setOrgWarning("An org must be chosen for non-roots")
    } else if(chosenWebAppStatus && (theUserEmail === null || theUserEmail.length < 1)) {
      // console.log("Web app access requires an email")
      setEmailWarning("Email required for web app access")
    } else if(theUserEmail !== null && theUserEmail.length > 0 && !legalUsername(theUserEmail)) {
      // console.log("Invalid email address")
      setEmailWarning("Invalid email address")
    } else if(chosenOperatorStatus && (theUserPin === null || theUserPin.length < 1)) {
      // console.log("Pin required for operators")
      setPinWarning("PIN required for operators")
    } else if(theUserPin !== null && theUserPin.length > 0 && !legalPin(theUserPin)) {
      // console.log("Invalid pin")
      setPinWarning("Invalid PIN: Please enter four digits")
    } else { // OK
      setConfirmAddUserModalOpen(true)
    }
  }
  function onConfirmClick() {
    // console.log("Hit confirm add user")
    const theUserName = getTextFieldValue("userName")
    const theUserEmail = getTextFieldValue("userEmail")
    const theUserPin = getTextFieldValue("userPin")
    if(theUserName !== null && chosenOrg !== null) {
      // console.log("added a user: (name:", theUserName,
      //   ", org:", chosenOrg,
      //   ", access level:", chosenAccessLevel,
      //   ", web app status:", chosenWebAppStatus,
      //   ", email:", theUserEmail,
      //   ", operator status:", chosenOperatorStatus,
      //   ", pin:", theUserPin,
      //   ")")
      const org = (chosenOrg === "noOrg") ? null : chosenOrg
      const pin = (theUserPin !== null && theUserPin.length > 0) ? theUserPin : undefined
      const email = (theUserEmail !== null && theUserEmail.length > 0) ? theUserEmail : undefined
      apiUserAdd(
        theUserName,
        org,
        chosenAccessLevel,
        chosenWebAppStatus,
        email,
        chosenOperatorStatus,
        pin,
      )
    }
    setConfirmAddUserModalOpen(false)
    onClose()
  }

  const confirmTextOrgName = data.rootAccess.orgs?.find((org)=>org.uid === chosenOrg)?.name
  const emailPresent = !isTextFieldValueEmpty(getTextFieldValue("userEmail"))
  const pinPresent = !isTextFieldValueEmpty(getTextFieldValue("userPin"))
  const confirmText = [
    'You are about to add the following user:',
    '- Name: "'+getTextFieldContentsPretty("userName")+'"',
    subjectIsRoot ? '- Org: '+chosenOrg+' ('+confirmTextOrgName+')' : '',
    '- Access level: '+chosenAccessLevel,
    '- Web app access: '+booleanYesNoString(chosenWebAppStatus),
    emailPresent ? '- Email: "'+getTextFieldContentsPretty("userEmail")+'"' : '',
    '- Operator: '+booleanYesNoString(chosenOperatorStatus),
    pinPresent ? '- PIN: "'+getTextFieldContentsPretty("userPin")+'"' : '',
  ]

  const addUserContents = 
    <div className="flex flex-col space-y-4">
      {makeTextInput("Name:", "The display name of the user to be created", " Will Turner", "userName", nameWarning)}
      {vars.auth.userGroups?.includes("root") ? rootOrgPicker : <></>}
      {makeRadioInput("Access level:", "How much of the data and tools the user will have access to. You can only choose levels lower than your own", chosenAccessLevel, accessLevelOptions, setChosenAccessLevel)}
      {makeCheckboxInput("Web app status:", "Whether or not the user will have access to the web app", chosenWebAppStatus, setChosenWebAppStatus)}
      {makeTextInput("Email:", "The email adress used for account management and communication (required for web app access)", "will@example.com", "userEmail", emailWarning)}
      {makeCheckboxInput("Operator status:", "Whether or not the user will be able to log in onboard vessels", chosenOperatorStatus, setChosenOperatorStatus)}
      {chosenOperatorStatus ? makeTextInput("PIN:", "The four digit PIN used to log in onboard vessels (required for operators)", "1234", "userPin", pinWarning) : <></>}
      <div className="p-4"/>
      {makeSubmitButton(onSubmitClick)}
      {makeConfirmModal("Confirm adding user", confirmText, "Confirm: Add user", onConfirmClick, confirmAddUserModalOpen, setConfirmAddUserModalOpen)}
    </div>
  
  return makeModal("Add new user", addUserContents, open, onClose)
}
