import { useState } from "react";
import { makeConfirmModal, makeMenuModal } from "../../../components/Modal";
import { useAppContext } from "../../../context/variables";
import { strNullToString } from "../../../helpers/stringHelpers";
import { GiNuclearBomb, GiTexas } from "react-icons/gi";
import useManageData from "../../../apiComms/manageData";
import { AccessLevel, EntityState, accessLevelAtMinimum } from "../../../context/types";
import { FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import { HiOutlineTrash } from "react-icons/hi";
import { TbBulb, TbBulbOff } from "react-icons/tb";
import { EditStateTagTypeModal} from "./EditStateTagTypeModal"

function DeleteTagTypeModal(open: boolean, setOpen: (open: boolean)=>void, managedId: string|null, onCloseManageModal: ()=>void) {
  const { data } = useAppContext()
  const { apiDeleteTagTypes } = useManageData()

  const tagTypeList = data.tagTypes.tagTypeList.val
  const tagTypeMaybe = tagTypeList?.find((tag)=>tag.id === managedId)
  const tagTypeName = (tagTypeMaybe === undefined) ? "N/A" : tagTypeMaybe.name

  function onConfirmClick() {
    // console.log("Hit confirm delete tag type")
    // console.log("Deleted the tag type: ("+managedId+")")
    apiDeleteTagTypes(tagTypeMaybe?.orgId, managedId)
    setOpen(false)
    onCloseManageModal()
  }
  return makeConfirmModal(
    "Confirm deleting tag type",
    ['You are about to PERMANENTLY delete',
      '"'+tagTypeName+'" ('+strNullToString(managedId)+')',
      'from the database'
    ],
    "Confirm: Delete tag type",
    onConfirmClick, open, setOpen, true)
}

function StateChangeTagTypeModal(title: string, makeText: (name: string|undefined)=>string, buttonText: string, toEntityState: EntityState, open: boolean, setOpen: (open: boolean)=>void, managedId: string|null, onCloseManageDestModal: ()=>void, orangeButton = false) {
  const { data } = useAppContext()
  const { apiPutTagTypesState } = useManageData()
  // const theId = strNullToString(managedId)
  const tagType = data.tagTypes.tagTypeList.val?.find((tag)=>tag.id === managedId)
  const tagTypeName = tagType?.name
  const org = tagType?.orgId
  function onConfirmClick() {
    // console.log("State changed the tag type: ("+theId+" in the org "+org+" to "+toEntityState+")")
    apiPutTagTypesState(org, managedId, toEntityState)
    setOpen(false)
    onCloseManageDestModal()
  }
  return makeConfirmModal(title, makeText(tagTypeName), buttonText, onConfirmClick, open, setOpen, orangeButton)
}

function AcceptTagTypeModal(open: boolean, setOpen: (open: boolean)=>void, managedDestId: string|null, onCloseManageDestModal: ()=>void) {
  return StateChangeTagTypeModal("Confirm accepting tag type", (name)=>'You are about to accept "'+name+'"', "Confirm: Accept tag type", EntityState.Active, open, setOpen, managedDestId, onCloseManageDestModal)
}
function RejectTagTypeModal(open: boolean, setOpen: (open: boolean)=>void, managedDestId: string|null, onCloseManageDestModal: ()=>void) {
  return StateChangeTagTypeModal("Confirm rejecting tag type", (name)=>'You are about to reject "'+name+'"', "Confirm: Reject tag type", EntityState.Rejected, open, setOpen, managedDestId, onCloseManageDestModal)
}
function ActivateTagTypeModal(open: boolean, setOpen: (open: boolean)=>void, managedDestId: string|null, onCloseManageDestModal: ()=>void) {
  return StateChangeTagTypeModal("Confirm activating tag type", (name)=>'You are about to activate "'+name+'"', "Confirm: Activate tag type", EntityState.Active, open, setOpen, managedDestId, onCloseManageDestModal)
}
function DeactivateTagTypeModal(open: boolean, setOpen: (open: boolean)=>void, managedDestId: string|null, onCloseManageDestModal: ()=>void) {
  return StateChangeTagTypeModal("Confirm deactivating tag type", (name)=>'You are about to deactivate "'+name+'"', "Confirm: Deactivate tag type", EntityState.Inactive, open, setOpen, managedDestId, onCloseManageDestModal)
}
function RemoveTagTypeModal(open: boolean, setOpen: (open: boolean)=>void, managedDestId: string|null, onCloseManageDestModal: ()=>void) {
  return StateChangeTagTypeModal("Confirm removing tag type", (name)=>'You are about to remove "'+name+'"', "Confirm: Remove tag type", EntityState.Removed, open, setOpen, managedDestId, onCloseManageDestModal, true)
}

export function ManageTagTypeModal(open: boolean, setOpen: (open: boolean)=>void, managedId: string|null, setManagedId: (id: string|null)=>void) {
  const { data, vars } = useAppContext()

  const [acceptModalOpen, setAcceptModalOpen] = useState(false)
  const [rejectModalOpen, setRejectModalOpen] = useState(false)
  const [activateModalOpen, setActivateModalOpen] = useState(false)
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false)
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const [editStateModalOpen, setEditStateModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const tagTypeList = data.tagTypes.tagTypeList.val
  const tagTypeMaybe = tagTypeList?.find((tag)=>tag.id === managedId)
  const tagTypeName = (tagTypeMaybe === undefined) ? "N/A" : tagTypeMaybe.name
  const title = (managedId !== null) ? 'Manage tag type "'+tagTypeName+'"' : 'Manage tag type'

  const currentState = tagTypeMaybe?.state
  const removeOption = {buttonIcon: <HiOutlineTrash/>, buttonText: "Remove", onClick: ()=>setRemoveModalOpen(true), orangeBg: true}
  const nonRootOptions = 
    (currentState === EntityState.Suggested) ? [
    {buttonIcon: <FiThumbsUp/>, buttonText: "Accept suggestion", onClick: ()=>setAcceptModalOpen(true)},
    {buttonIcon: <FiThumbsDown/>, buttonText: "Reject suggestion", onClick: ()=>setRejectModalOpen(true)},
  ]
  : (currentState === EntityState.Active) ? [
    {buttonIcon: <TbBulbOff/>, buttonText: "Deactivate", onClick: ()=>setDeactivateModalOpen(true)},
    removeOption,
  ]
  : (currentState === EntityState.Inactive) ? [
    {buttonIcon: <TbBulb/>, buttonText: "Activate", onClick: ()=>setActivateModalOpen(true)},
    removeOption,
  ]
  : []
  const rootOnlyOptions = [
    {buttonIcon: <GiTexas/>, buttonText: "Edit state (root only)", onClick: ()=>{
      // console.log("clicked Edit state (root)")
      setEditStateModalOpen(true)
    }},
    {buttonIcon: <GiNuclearBomb/>, buttonText: "PERMA-Delete (root only)", onClick: ()=>{
      // console.log("clicked delete")
      setDeleteModalOpen(true)
    }, orangeBg: true}
  ]
  const isRoot = accessLevelAtMinimum(vars.auth.accessLevel[0], AccessLevel.Root)
  const options = isRoot ? nonRootOptions.concat(rootOnlyOptions) : nonRootOptions

  function onClose() {
    setManagedId(null)
    setOpen(false)
  }

  const modals = [
    AcceptTagTypeModal(acceptModalOpen, setAcceptModalOpen, managedId, onClose),
    RejectTagTypeModal(rejectModalOpen, setRejectModalOpen, managedId, onClose),
    ActivateTagTypeModal(activateModalOpen, setActivateModalOpen, managedId, onClose),
    DeactivateTagTypeModal(deactivateModalOpen, setDeactivateModalOpen, managedId, onClose),
    RemoveTagTypeModal(removeModalOpen, setRemoveModalOpen, managedId, onClose),
    EditStateTagTypeModal(editStateModalOpen, setEditStateModalOpen, managedId, onClose),
    DeleteTagTypeModal(deleteModalOpen, setDeleteModalOpen, managedId, onClose),
  ]
  
  return makeMenuModal(title, options, modals, open, onClose)
}
