interface PageProps {
  title: string,
  content: JSX.Element,
  bottomPadding?: boolean
}

export default function Page(props: PageProps) {
  return (
    <div className="w-0 flex-grow h-full bg-white flex flex-col space-y-2 pt-7">
      <div className="text-left h-16 flex-grow-0 text-4xl font-bold text-deep_blue pl-11 pr-11 pointer-events-none">
        {props.title}
      </div>
      <div className="w-full h-0 flex-grow bg-pale_blue shadow-[0px_-4px_8px_0px_rgba(0,0,0,0.1)] z-20 text-white overflow-scroll">
        <div className="min-w-fit pb-7 px-7">
          {props.content}
        </div>
      </div>
    </div>
  )
}
