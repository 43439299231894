import React from 'react';

interface AlarmIconHighProps {
    size?: number;
    color?: string;
}

const AlarmIconHigh: React.FC<AlarmIconHighProps> = ({ size = 27, color = '#E41B1B' }) => (
    <svg width={size} height={size} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.4456 0.664465C12.8903 -0.22149 14.1097 -0.221488 14.5544 0.664468L26.8635 25.1917C27.2766 26.0149 26.7022 27 25.8091 27H1.19086C0.297808 27 -0.276608 26.0149 0.136485 25.1917L12.4456 0.664465Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.0221 17.7881H12.2728C12.2728 15.4455 12.2728 11.5607 12.2728 9.21808H15.0221V17.7881ZM15.0221 23.7868H12.2728V20.7874H15.0221V23.7868Z" fill={color}/>
        <path d="M12.2728 17.7881H15.0221V9.21808H12.2728C12.2728 11.5607 12.2728 15.4455 12.2728 17.7881Z" fill="white"/>
        <path d="M12.2728 23.7868H15.0221V20.7874H12.2728V23.7868Z" fill="white"/>
    </svg>
);

export default AlarmIconHigh;
