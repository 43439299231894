import { Select } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { ReportFileFormat, useAppContext } from "../context/variables";
import useFetchData from "../apiComms/fetchData";
import Page from "./Page";
import { GrDocumentPdf, GrDocumentCsv, GrDocument } from "react-icons/gr";
import { HiDownload } from "react-icons/hi";
import { strNullToString } from "../helpers/stringHelpers";
import { ReportTimescale } from "../context/types";
import {
  inputSetting,
  inputTray,
  makeStandardMenuItem,
} from "../components/variousInputs";
import {
  IconButton,
  TableCellIconButton,
  genericTable2,
  genericTableRelatedBits,
} from "../components/Table";
import LoadingSpinner from "../components/atoms/LoadingSpinner";
import EmptyState from "../components/atoms/EmptyState";
import { CenteredContainer } from "../components/styles/EmptyStateStyles";
import DocumentIcon from "../assets/icons/EmptyStates/DocumentIcon";

interface ReportUnavailableProps {
  organisedBy: string;
}

const ReportUnavailable = ({ organisedBy }: ReportUnavailableProps) => {
  const title =
    organisedBy === "vessel"
      ? "Vessel report is not available"
      : "Fleet report is not available";
  const description = "Choose another timeframe to get data";

  return (
    <CenteredContainer>
      <EmptyState icon={<DocumentIcon />} title={title} description={description} />
    </CenteredContainer>
  );
};

export default function ReportsPage() {
  const { vars, data } = useAppContext();
  const {
    fetchDataOrganisations,
    fetchDataReportsPage,
    fetchReportFile,
    fetchDataOrgReports,
  } = useFetchData();

  const [organisedBy, setOrganisedBy] = useState("vessel");
  const [isLoading, setIsLoading] = useState(false);
  const organisedByOptions = [
    { name: "Vessel", value: "vessel" },
    { name: "Organisation", value: "org" },
  ];

  const organisedByDropdown = (
    <Select
      label="Organised by"
      value={organisedBy}
      onChange={(event) => {
        setOrganisedBy(event.target.value);
        fetchReportsData(organisedBy);
      }}
    >
      {organisedByOptions.map(makeStandardMenuItem)}
    </Select>
  );

  const [vessel, setVessel] = useState("");
  const vesselOptions = data.org.vesselList.map((vessel) => {
    return { name: vessel.name, value: vessel.id };
  });
  const vesselDropdown =
    organisedBy === "vessel" ? (
      <Select
        label="Vessel"
        value={vessel}
        onChange={(event) => setVessel(event.target.value)}
      >
        {vesselOptions.map(makeStandardMenuItem)}
      </Select>
    ) : null;

  useEffect(() => {
    fetchDataOrganisations();
  }, [vars.auth.idToken, vars.auth.organisationId]);

  useEffect(() => {
    if (organisedBy === "vessel") {
      if (data.org.vesselList.length > 0) {
        setVessel(data.org.vesselList[0].id);
      } else {
        setVessel("");
      }
    }
  }, [data.org.vesselList, organisedBy]);

  const fetchReportsData = useCallback(
    async (organisedBy: string) => {
      setIsLoading(true);
      const orgId = vars.auth.organisationId;
      if (organisedBy === "vessel") {
        await fetchDataReportsPage(vessel);
      } else {
        if (orgId) {
          fetchDataOrgReports(orgId);
        } else {
          console.error("orgId is null");
        }
      }
      setIsLoading(false);
    },
    [vessel, vars.auth.organisationId, fetchDataReportsPage, fetchDataOrgReports]
  );

  useEffect(() => {
    fetchReportsData(organisedBy);
  }, [organisedBy, vessel]);

  const [timescale, setTimescale] = useState(ReportTimescale.Weekly);
  const timescaleOptions = [
    { name: "Weekly", value: ReportTimescale.Weekly },
    { name: "Monthly", value: ReportTimescale.Monthly },
    { name: "Yearly", value: ReportTimescale.Yearly },
    { name: "Custom", value: ReportTimescale.Custom },
  ];
  const timescaleDropdown = (
    <Select
      label="Timescale"
      value={timescale}
      onChange={(event) => setTimescale(event.target.value as ReportTimescale)}
    >
      {timescaleOptions.map(makeStandardMenuItem)}
    </Select>
  );

  const [type, setType] = useState(
    organisedBy === "org" ? "group_report" : "basic_report"
  );
  const allTypeOptions = [
    { name: "Basic", value: "basic_report" },
    { name: "Leg", value: "leg_report" },
    { name: "Route", value: "route_report" },
    { name: "Voyage", value: "voyages_report" },
    { name: "Vessel operator", value: "operator_report" },
    { name: "Miscellaneous", value: "other_report" },
    { name: "Group", value: "group_report" },
  ];

  const reportKindList =
    data.reports.reportList !== null
      ? data.reports.reportList.map((aReportEntry) => aReportEntry.reportKind)
      : [];

  const typeOptions =
    organisedBy === "org"
      ? allTypeOptions.filter(({ value }) => value === "group_report")
      : allTypeOptions.filter(
          ({ value }) =>
            (reportKindList.includes(value) || value === "basic_report") &&
            value !== "group_report" // Exclude "Group" option when not organised by "org"
        );

  useEffect(() => {
    if (organisedBy === "org") {
      setType("group_report");
    } else if (organisedBy === "vessel") {
      setType("basic_report");
    }
  }, [organisedBy]);

  const typeDropdown = (
    <Select
      label="Report type"
      value={type}
      onChange={(event) => {
        setType(event.target.value);
        setLeg("allLegs");
      }}
      inputProps={{
        "aria-label": "Report type",
      }}
    >
      {typeOptions.map(makeStandardMenuItem)}
    </Select>
  );

  const [leg, setLeg] = useState("allLegs");
  const allLegNames =
    data.reports.reportList !== null
      ? data.reports.reportList.map((report) => report.legName)
      : [];
  const legNames: string[] = [];
  for (let legName of allLegNames) {
    if (legName !== null && !legNames.includes(legName)) {
      legNames.push(legName);
    }
  }
  const legOptions = legNames.map((legName) => {
    return { name: legName, value: legName };
  });
  const allLegOptions = [{ name: "All legs", value: "allLegs" }].concat(
    legOptions
  );
  if (
    allLegOptions.find(({ name, value }) => value === leg) === undefined &&
    data.reports.reportList !== null
  ) {
    setLeg("allLegs");
  }
  const legDropdown = (
    <Select
      label="Leg"
      value={leg}
      onChange={(event) => setLeg(event.target.value)}
      inputProps={{
        "aria-label": "Leg",
      }}
    >
      {allLegOptions.map(makeStandardMenuItem)}
    </Select>
  );

  const [format, setFormat] = useState<string>(ReportFileFormat.PDF);
  const formatOptions = [
    { name: "Show all", value: "allFormats" },
    { name: "PDF reports", value: ReportFileFormat.PDF },
    { name: "CSV tables", value: ReportFileFormat.CSV },
  ];
  const formatDropdown = (
    <Select
      label="Format"
      value={format}
      onChange={(event) => setFormat(event.target.value)}
    >
      {formatOptions.map(makeStandardMenuItem)}
    </Select>
  );

  const reportsSorted =
    data.reports.reportList === null
      ? null
      : data.reports.reportList
          .filter(
            (report) =>
              (report.vesselId === vessel || report.vesselId === undefined) &&
              (report.legName === leg || leg === "allLegs") &&
              (report.reportKind === type || type === "group_report") &&
              report.reportInterval === timescale &&
              (format === "allFormats" || report.fileFormat === format)
          )
          .sort((repA, repB) => repA.reportDate.localeCompare(repB.reportDate))
          .reverse();

  const columns =
    type === "leg_report" && leg === "allLegs"
      ? ["", "Title", "Leg", "Date generated", "Download"]
      : ["", "Title", "Date generated", "Download"];
  const rows =
    reportsSorted === null
      ? null
      : reportsSorted.map((report, index) => {
          const IconComponent =
            report.fileFormat === ReportFileFormat.CSV
              ? GrDocumentCsv
              : report.fileFormat === ReportFileFormat.PDF
              ? GrDocumentPdf
              : GrDocument;
          const iconCell = <IconComponent className="text-2xl" />;
          const downloadButton: IconButton = {
            Icon: HiDownload,
            onClick: () => fetchReportFile(report),
            tooltip: "Download " + report.filename,
          };
          const downloadCell = TableCellIconButton("download", downloadButton);
          const prettyLegName = strNullToString(report.legName);
          const row =
            type === "leg_report" && leg === "allLegs"
              ? [
                  iconCell,
                  report.filename,
                  prettyLegName,
                  report.reportDate,
                  downloadCell,
                ]
              : [iconCell, report.filename, report.reportDate, downloadCell];
          return { id: index.toString(), contents: row };
        });
  const standardReportTable = genericTable2(columns, rows);

  const vesselPicker = vesselDropdown
    ? inputSetting("Vessel", vesselDropdown, 240)
    : null;
  const organisedByPicker = inputSetting(
    "Organised by",
    organisedByDropdown,
    240
  );
  const timescalePicker = inputSetting("Timescale", timescaleDropdown, 180);
  const typePicker = inputSetting("Report type", typeDropdown, 180);
  const legPickerMaybe =
    type === "leg_report" ? inputSetting("Leg", legDropdown, 240) : <></>;
  const formatPicker = inputSetting("Format", formatDropdown, 180);

  const inputs = [
    [organisedByPicker, vesselPicker, typePicker, legPickerMaybe].filter(
      (input) => input !== null
    ),
    [timescalePicker, formatPicker],
  ];

  const validInputs = inputs.map((row) =>
    row.filter((input) => input !== null)
  );

  function removeNullElements(row: (JSX.Element | null)[]): JSX.Element[] {
    return row.filter((input) => input !== null) as JSX.Element[];
  }

  const isEmptyState = rows === null || rows.length === 0;

  const reports_content = (
    <div className="flex flex-col space-y-3 pt-4 text-deep_blue">
      <div className="flex space-x-4">
        {validInputs.length > 0 &&
          inputTray(validInputs.map(removeNullElements))}
      </div>
      {genericTableRelatedBits("Reports", rows?.length, () =>
        fetchReportsData(organisedBy)
      )}
      {isEmptyState ? (
        <ReportUnavailable organisedBy={organisedBy} />
      ) : (
        standardReportTable
      )}
    </div>
  );

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Page title={"Reports"} content={reports_content} />
  );
}
