import styled from "styled-components";

export const PanelUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const PanelUserUsernameSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-size: 1rem;
`;

export const PanelUserOrganisationSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const PanelUserOrganisationTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
`;
