import { rootOrgsEntry, useAppContext, usersEntry } from "../../../context/variables";
import { genericTableEditable } from "../../../components/Table";
import { strNullToString } from "../../../helpers/stringHelpers";
import { AccessLevel, accessLevelAbove, accessLevelAtMinimum } from "../../../context/types";

export default function UsersTable(
  users: usersEntry[] | null,
  orgs: rootOrgsEntry[] | null,
  setManagedUserId: (id: string | null) => void,
  setManageUserModalOpen: (open: boolean) => void
): JSX.Element {
  const { vars, data } = useAppContext();

  const organisationId = vars.auth.organisationId;
  const isRoot =
    vars.auth.userGroups !== null && vars.auth.userGroups.includes("root");
  const showExtraColumns = organisationId === null && isRoot;

  const columnNames = showExtraColumns
    ? ["ID", "Name", "org_id(org_name)", "Access level", "Email", "Operator status", "PIN", "Phone", "user_status"]
    : ["ID", "Name", "Access level", "Email", "Operator status", "PIN", "Phone"];

  const rows = users === null ? null : users.map((user) => {
    const username = user.username.toUpperCase();
    const name = strNullToString(user.name);
    const org_id = strNullToString(user.org_id);
    const email = strNullToString(user.email);
    const phone = strNullToString(user.phone);
    const access_level = user.access_level;
    const is_operator = user.is_operator ? "True" : "";
    const pin_code = strNullToString(user.pin_code, undefined, "");
    const user_status = user.user_status;
    const orgMaybe = orgs?.find((org) => org.uid === org_id);
    const orgName = orgMaybe === undefined ? "N/A" : orgMaybe.name;
    const contents = showExtraColumns
      ? [username, name, org_id + " (" + orgName + ")", access_level, email, is_operator, pin_code, phone, user_status]
      : [username, name, access_level, email, is_operator, pin_code, phone];

    const userEntry = data.org.userList?.find((user) => user.username === username);
    const subjectUserAccessLevel = vars.auth.accessLevel[0];
    const subjectIsRoot = subjectUserAccessLevel === AccessLevel.Root;
    const objectUserAccessLevel = userEntry !== undefined ? userEntry.access_level : null;
    const objectIsBelowSubject = accessLevelAbove(subjectUserAccessLevel, objectUserAccessLevel);
    const objectIsSubject = username !== null && username === vars.auth.username?.toUpperCase();
    const subjectIsAdminOrAbove = accessLevelAtMinimum(subjectUserAccessLevel, AccessLevel.Admin);
    const underOrSelfGEqAdmin = objectIsBelowSubject || (objectIsSubject && subjectIsAdminOrAbove);
    const allowManage = subjectIsRoot || underOrSelfGEqAdmin;

    return { id: username, contents: contents, allowManage: allowManage };
  });

  function onEditRowClick(username: string) {
    setManagedUserId(username);
    setManageUserModalOpen(true);
  }

  return genericTableEditable(columnNames, rows, onEditRowClick);
}
