import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Aggregation,
  OperatorTabMetric,
  Timescale,
  VesselActivity,
  dataSeries,
  graphSeriesColor,
  graphTimePoint,
  operatorTabMetricMap,
  prettyVesselActivityName,
} from "../../../context/types";
import {
  energyOperatorSeriesData,
  useAppContext,
} from "../../../context/variables";
import {
  epochAggrToPrettyString,
  epochToXAxisString,
} from "../../../helpers/dateTimeHelpers";
import {
  fillGrayIfNoData,
  graphLoadOrNoDataNotice,
  graphVerticalNowLine,
} from "../../../helpers/graphHelpers";

function convertDataCxtToGraph(
  series: energyOperatorSeriesData[] | null
): graphTimePoint[] | undefined {
  if (series === null) return undefined;

  const opActDataSeries: dataSeries[] = series.map(
    (seriesData: energyOperatorSeriesData) => {
      const title = seriesData.operator + "_" + seriesData.activity;
      return { series: title, data: seriesData.data };
    }
  );
  // console.log("opActDataSeries:", opActDataSeries)

  const timestampLists = opActDataSeries.map((dataSeries) => {
    const theData = dataSeries.data;
    return theData.map((graphPoint) => graphPoint.timestamp);
  });
  const timestampList = timestampLists.flat();
  const uniqueTimestamps = [...new Set(timestampList)];
  uniqueTimestamps.sort();
  // console.log("timestamps", timestampLists, timestampList, uniqueTimestamps)

  const finishedData = uniqueTimestamps.map((timestamp) => {
    const isoTime = Date.parse(timestamp);
    var theGraphTimePoint: graphTimePoint;
    theGraphTimePoint = { timestamp: isoTime };
    opActDataSeries.forEach((dataSeries) => {
      const theSeriesName = dataSeries.series;
      const theSeriesData = dataSeries.data;
      const theGraphPoint = theSeriesData.find(
        (graphPoint) => graphPoint.timestamp === timestamp
      );
      if (theGraphPoint !== undefined) {
        theGraphTimePoint[theSeriesName] = theGraphPoint.value;
      }
    });
    return theGraphTimePoint;
  });
  return finishedData;
}

function lineComponentsFromDataCxt(
  series: energyOperatorSeriesData[] | null,
  metric: OperatorTabMetric
): JSX.Element[] {
  if (series === null) return [];
  // Set appropriate color, shade, dasharray for each
  // Same op means same color
  // act determines shade and dasharray of that color
  // loop through like ten colors
  const allColors: graphSeriesColor[] = [
    { name: "red", lightShade: "#ff0000", darkShade: "#bf0000" },
    { name: "green", lightShade: "#00cf00", darkShade: "#009f00" },
    { name: "blue", lightShade: "#0000ff", darkShade: "#00009f" },
    { name: "orange", lightShade: "#ff5f00", darkShade: "#bf3f00" },
    { name: "purple", lightShade: "#bf00bf", darkShade: "#8f008f" },
    { name: "yellow", lightShade: "#cfcf00", darkShade: "#9f9f00" },
    { name: "magenta", lightShade: "#ff00ff", darkShade: "#bf00bf" },
    { name: "cyan", lightShade: "#00cfcf", darkShade: "#009f9f" },
    { name: "brown", lightShade: "#964b00", darkShade: "#4b2500" },
    { name: "pink", lightShade: "#ff6ec7", darkShade: "#bf5295" },
  ];

  const operators = series.map((seriesData) => seriesData.operator);
  const uniqueOperators = [...new Set(operators)];
  // console.log("uniqueOperators:", uniqueOperators)
  const operatorColors = uniqueOperators.map((op, i) => {
    const colorIndex = i % 10;
    const opCol = { operatorName: op, color: allColors[colorIndex] };
    // console.log("In opCol finder:", op, i, colorIndex, opCol)
    return opCol;
  });
  // console.log("operatorColors:", operatorColors) //, opCols2)
  const unitShort = " " + operatorTabMetricMap[metric].unitShort;

  const lineComponents = series.map((seriesData, index) => {
    const opColor = operatorColors.find(
      (opCol) => opCol.operatorName === seriesData.operator
    );
    // console.log("here", opColor, operatorColors)
    if (opColor === undefined) return <></>;
    // console.log("also here")
    const hexCode =
      seriesData.activity === VesselActivity.Total
        ? opColor.color.darkShade
        : opColor.color.lightShade;
    const dashArray =
      seriesData.activity === VesselActivity.AtDock ? "8 4" : "";
    // console.log("before making line:", seriesData, hexCode, dashArray)
    const lineComponent = (
      <Line
        key={index}
        connectNulls={false}
        type="monotone"
        dataKey={seriesData.operator + "_" + seriesData.activity}
        stroke={hexCode}
        strokeDasharray={dashArray}
        unit={unitShort}
      />
    );
    // console.log("lineComponent:", lineComponent) isAnimationActive={false}
    return lineComponent;
  });

  return lineComponents;
}

export default function EnergyOperatorsGraph() {
  const { data, view } = useAppContext()

  function userIdToName(id: string): string {
    if(id === "UNKNOWN") return "No operator"
    const userEntry = data.org.userList?.find((entry) => entry.username === id);
    if (userEntry === undefined || userEntry.name === null) {
      return id;
    } else {
      return userEntry.name;
    }
  }
  
  function prettySeriesName(uglyId: string) {
    const idParts = uglyId.split("_");
    const operatorName = userIdToName(idParts[0]);
    const activityPart = idParts[1];
    const maybeActivity = Object.values(VesselActivity).find(
      (x) => x === activityPart
    );
    let activityName: string;
    if (maybeActivity === undefined) {
      activityName = activityPart;
    } else {
      activityName = prettyVesselActivityName[maybeActivity];
    }
    return operatorName + " (" + activityName + ")";
  }

  const timescale: Timescale = view.energyOperator.timescale
  let xAxisTickAngle: number
  let xAxisTickTextAnchor: string
  if (timescale === Timescale.Year) {
    xAxisTickAngle = 0
    xAxisTickTextAnchor = "middle"
  } else if (timescale === Timescale.Month) {
    xAxisTickAngle = 0
    xAxisTickTextAnchor = "middle"
  } else if (timescale === Timescale.Week) {
    xAxisTickAngle = 0
    xAxisTickTextAnchor = "middle"
  } else {
    // if(timescale === Timescale.Day)
    xAxisTickAngle = -30
    xAxisTickTextAnchor = "end"
  }

  // ----- Red line marking current time in graph -----

  const aggr: Aggregation = view.energyOperator.aggregation;
  let aggrUnitStepMs: number;
  if (aggr === Aggregation.Hour) {
    aggrUnitStepMs = 3600 * 1000;
  } else if (aggr === Aggregation.Day) {
    aggrUnitStepMs = 24 * 3600 * 1000;
  } else if (aggr === Aggregation.Month) {
    aggrUnitStepMs = 31 * 24 * 3600 * 1000;
  } else { // if(aggr === Aggregation.Year)
    aggrUnitStepMs = 365.25 * 24 * 3600 * 1000;
  }

  const nowTimeNum = new Date().getTime();
  const redLineVal =
    nowTimeNum +
    new Date().getTimezoneOffset() * 60 * 1000 -
    aggrUnitStepMs / 2;

  // ----- Graph data & lines -----

  const graphData: graphTimePoint[] | undefined =
    convertDataCxtToGraph(data.energy.operators.series)

  const lineComponents: JSX.Element[] = lineComponentsFromDataCxt(
    data.energy.operators.series,
    view.energyOperator.metric
  );
  
  // ----- The graph -----

  return <LineChart
    width={1100}
    height={533}
    data={graphData}
    margin={{ top: 5, right: 40, left: 20, bottom: 5 }}
  >
    <CartesianGrid
      strokeDasharray="3 3"
      fill={fillGrayIfNoData(graphData)}
    />
    <XAxis
      dataKey="timestamp"
      domain={["dataMin", "dataMax"]}
      scale={"time"}
      type="number"
      interval={0}
      angle={xAxisTickAngle}
      textAnchor={xAxisTickTextAnchor}
      tickFormatter={(epochNum) =>
        epochToXAxisString(epochNum, view.energyOperator.timescale)
      }
    />
    <YAxis
      padding={{ top: 36 }}
      domain={["auto", "auto"]}
      label={{
        value:
          operatorTabMetricMap[view.energyOperator.metric].name +
          " (" +
          operatorTabMetricMap[view.energyOperator.metric].unitShort +
          ")",
        angle: -90,
        position: "insideLeft",
        offset: -10,
        style: { textAnchor: "middle" },
        fontSize: 16,
      }}
    />
    <RechartsTooltip
      formatter={(value: string, name, props) => {
        const valueRounded =
          Math.round(parseFloat(value) * 1000) / 1000;
        return [
          valueRounded.toLocaleString("en-IE"),
          prettySeriesName(name.toString()),
        ];
      }}
      labelFormatter={(label: number) => {
        const dateStr = epochAggrToPrettyString(
          label,
          view.energyOperator.aggregation
        );
        const metricName =
          operatorTabMetricMap[view.energyOperator.metric].name;
        return metricName + " - " + dateStr;
      }}
    />
    <Legend
      iconType={"plainline"}
      // margin={{ top: 50 }}
      wrapperStyle={{ paddingTop: "12px" }}
      formatter={(value, entry, index) => prettySeriesName(value)}
    />
    {graphVerticalNowLine(
      redLineVal,
      graphData !== undefined && graphData.length > 1
    )}
    {lineComponents}
    {graphLoadOrNoDataNotice(graphData)}
  </LineChart>
}
