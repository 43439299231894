import { AlarmType } from "../../../context/variables";

export const processAlarmTypesResponse = (responseJson: any): AlarmType[] => {
  if (Array.isArray(responseJson)) {
    const rawAlarmTypes = responseJson;
    const alarmTypes = rawAlarmTypes.map((rawAlarmType: any) => ({
      id: rawAlarmType.alarm_id,
      vesselName: rawAlarmType.vessel_name,
      alarmName: rawAlarmType.alarm_name,
      date: rawAlarmType.date,
      alarmLevel: rawAlarmType.alarm_level,
      alarmType: rawAlarmType.alarm_type,
      operator: rawAlarmType.operator,
      threshold: rawAlarmType.threshold,
      geoZone: rawAlarmType.geozone,
      vesselId: rawAlarmType.vessel_id,
    }));

    return alarmTypes;
  } else {
    return [];
  }
};
