
import React from 'react';
import { StyledCheckbox } from './Checkbox.styles';
import { isStringEmpty } from '../../../helpers/stringHelpers';

interface CheckboxProps {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string; 
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange, label }) => {
  const checkboxId = `checkbox-${label.replace(/\s+/g, '-')}`; // Generate unique ID for label association

  return (
    <StyledCheckbox hasLabel={isStringEmpty(label)}>
      <input
        id={checkboxId}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={checkboxId}>{label}</label>
    </StyledCheckbox>
  );
};

export default Checkbox;
