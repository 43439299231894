const AlarmIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="113" height="109" viewBox="0 0 113 109" fill="none">
    <path d="M48.1154 106.808H64.8846" stroke="#1F64E7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M90.0385 43.9232C90.0385 35.0282 86.5052 26.4977 80.2153 20.2079C73.9256 13.9183 65.395 10.3848 56.5 10.3848C47.605 10.3848 39.0744 13.9183 32.7847 20.2079C26.4951 26.4977 22.9615 35.0282 22.9615 43.9232V73.2694C22.9615 76.605 21.6364 79.8044 19.2778 82.163C16.9192 84.5215 13.7202 85.8463 10.3846 85.8463H102.615C99.28 85.8463 96.0804 84.5215 93.7218 82.163C91.3632 79.8044 90.0385 76.605 90.0385 73.2694V43.9232Z" stroke="#1F64E7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2 42.8331C2.00452 34.8521 3.90779 26.9867 7.55263 19.8866C11.1974 12.7866 16.4791 6.65556 22.9615 2" stroke="#1F64E7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M111 42.8331C110.996 34.8521 109.092 26.9867 105.448 19.8866C101.803 12.7866 96.5206 6.65556 90.0385 2" stroke="#1F64E7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default AlarmIcon;
