import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

interface TabPageProps {
  title: string;
  tabs: { name: string; path: string; visible: boolean }[];
}

export default function TabPage(props: TabPageProps) {
  const location = useLocation();
  const currentPathname = location.pathname; // getting what page/tab we're on

  const tabList = props.tabs.map((tab) => {
    if (!tab.visible) return <></>;
    const tabButtonSelected = (
      <Link
        to={tab.path}
        key={"tabList-" + tab.path}
        id={"tabList-" + tab.path}
        className="font-bold text-4xl text-deep_blue bg-pale_blue w-[20.5rem] h-[3.875rem] pt-3 pl-8 rounded-t-3xl shadow-[4px_0px_8px_0px_rgba(0,0,0,0.1)]"
      >
        {tab.name}
      </Link>
    );
    const tabButtonUnselected = (
      <Link
        to={tab.path}
        key={"tabList-" + tab.path}
        id={"tabList-" + tab.path}
        className="font-normal text-4xl text-deep_blue bg-sky_blue/10 hover:bg-sky_blue/25 w-[20.5rem] h-[3.875rem] pt-3 pl-8 rounded-t-3xl shadow-[4px_0px_8px_0px_rgba(0,0,0,0.1)] z-10"
      >
        {tab.name}
      </Link>
    );
    if (currentPathname === tab.path) {
      return tabButtonSelected;
    } else {
      return tabButtonUnselected;
    }
  });

  const tabPage_content = (
    <div className="w-full h-full flex flex-col justify-start items-start">
      <div className="h-20 flex-grow-0 w-full overflow-scroll">
        <div className="h-full w-fit flex px-14 justify-start items-end space-x-5">
          {tabList}
        </div>
      </div>
      <div
        id={"tabPageContainer-" + props.title}
        className="h-0 flex-grow pt-4 w-full overflow-scroll bg-pale_blue shadow-[0px_-4px_8px_0px_rgba(0,0,0,0.1)] z-20"
        tabIndex={0}
      >
        <div
          id={"tabPageContent-" + props.title}
          className="w-full flex flex-col pb-7"
        >
          <Outlet />
        </div>
      </div>
    </div>
  );

  const tabPageComponent = (
    <div className="w-0 flex-grow h-full bg-white flex flex-col space-y-2 pt-7">
      <div className="text-left h-12 flex-grow-0 text-4xl font-bold text-deep_blue pl-11 pr-11">
        {props.title}
      </div>
      <div className="w-full h-0 flex-grow text-white">{tabPage_content}</div>
    </div>
  );

  return tabPageComponent;
}
