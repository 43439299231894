import React, { useEffect, useState } from 'react';
import useFetchData from '../../apiComms/fetchData';
import { useAppContext } from '../../context/variables';
import Dropdown from '../atoms/Dropdown';

interface VesselListDropdownProps {
  value: string;
  onChange: (value: string) => void;
  width?: string; 
}

const VesselListDropdown: React.FC<VesselListDropdownProps> = ({ value, onChange, width }) => {
  const [vesselOptions, setVesselOptions] = useState<Array<{ value: string; label: string }>>([]);
  const { data, vars } = useAppContext();
  const { fetchDataOrganisations } = useFetchData();

  useEffect(() => {
    fetchDataOrganisations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.idToken, vars.auth.organisationId]);

  useEffect(() => {
    if (data.org.vesselList) {
      const options = [{ value: 'allVessels', label: 'All Vessels' }].concat(
        data.org.vesselList.map((vessel: any) => ({ value: vessel.id, label: vessel.name }))
      );
      setVesselOptions(options);
    }
  }, [data.org.vesselList]);

  return (
    <Dropdown
      name="vessel"
      value={value}
      options={vesselOptions}
      onChange={(_, selectedValue) => onChange(selectedValue)}
      placeholder="Select vessel"
      width={width} 
    />
  );
};

export default VesselListDropdown;
