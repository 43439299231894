import { ChangeEvent } from "react";

interface textEntryProps {
  prompt: string;
  name: string;
  type: string;
  warningStatus: boolean;
  defaultValue?: string;
  size: "Small" | "Medium" | "Large"
}

export function TextEntry(props: textEntryProps) {
  return <input className={`${props.size === "Small" ? "w-24" : props.size === "Medium" ? "w-72" : "w-[370px]"} h-[70px] ${props.warningStatus ? "border-[3px] border-high_orange" : "border-[1px] border-deep_blue"} px-3 rounded-lg placeholder:italic`} defaultValue={props.warningStatus ? undefined : props.defaultValue} placeholder={props.prompt} type={props.type} name={props.name} id={props.name}/>
}

interface textEntryWarningTextProps {
  prompt: string;
  name: string;
  type: string;
  warningStatus: boolean;
  warningText: string
  defaultValue?: string
  size: "Small" | "Medium" | "Large"
}

interface TextEntryWithCallbackProps {
  prompt: string;
  name: string;
  type: string;
  warningStatus: boolean;
  warningText: string;
  defaultValue?: string;
  size: "Small" | "Medium" | "Large";
  onChange?: (value: string) => void;  // Callback function for onChange
}


export function TextEntryWithCallback(props: TextEntryWithCallbackProps) {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(event.target.value); // Call the onChange callback with the new value
    }
  };
  return (
    <div className={`${props.size === "Small" ? "w-24" : props.size === "Medium" ? "w-72" : "w-[370px]"} relative`}>
      <input
        className={`${props.size === "Small" ? "w-24" : props.size === "Medium" ? "w-72" : "w-[370px]"} h-[70px] ${props.warningStatus ? "border-[3px] border-high_orange" : "border-[1px] border-deep_blue"} px-3 rounded-lg placeholder:italic`}
        defaultValue={props.warningStatus ? undefined : props.defaultValue}
        placeholder={props.prompt}
        type={props.type}
        name={props.name}
        id={props.name}
        onChange={handleChange} // Call handleChange function on input change
      />
      <div className="absolute w-max right-0 font-normal text-lg text-high_orange h-0">{props.warningText}</div>
    </div>
  );
}

export function TextEntryWarningText(props: textEntryWarningTextProps) {
  return <div className={`${props.size === "Small" ? "w-24" : props.size === "Medium" ? "w-72" : "w-[370px]"} relative`}>
    <TextEntry prompt={props.prompt} name={props.name} type={props.type} warningStatus={props.warningStatus} defaultValue={props.defaultValue} size={props.size}/>
    <div className="absolute w-max right-0 font-normal text-lg text-high_orange h-0">{props.warningText}</div>
  </div>
}

interface textEntryTitleWarningTextProps {
  title: string
  prompt: string;
  name: string;
  type: string;
  warningStatus: boolean;
  warningText: string
}

export function TextEntryTitleWarningText(props: textEntryTitleWarningTextProps) {
  return <div className="w-[370px]">
    <div className="pb-3">{props.title}</div>
    <TextEntry prompt={props.prompt} name={props.name} type={props.type} warningStatus={props.warningStatus} size={"Large"}/>
    <div className="font-normal text-lg text-high_orange text-right h-0">{props.warningText}</div>
  </div>
}

