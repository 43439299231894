import React from "react";

interface MenuAlarmIconProps {
  size?: number;
  color?: string;
}

const DeleteButtonIcon: React.FC<MenuAlarmIconProps> = ({
  size = 18,
  color = "white",
}) => (
  <svg
    width={size}
    height={size * (21 / 18)}
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2692 2.52632H12.4615V1.89474C12.4615 0.848289 11.5317 0 10.3846 0L7.61539 0C6.46832 0 5.53846 0.848289 5.53846 1.89474V2.52632L1.73077 2.52632C0.774909 2.52632 0 3.23325 0 4.10526L0 5.36842C0 5.71725 0.309938 6 0.692308 6L17.3077 6C17.6901 6 18 5.71725 18 5.36842V4.10526C18 3.23325 17.2251 2.52632 16.2692 2.52632ZM6.92308 1.89474C6.92308 1.54658 7.23375 1.26316 7.61539 1.26316L10.3846 1.26316C10.7663 1.26316 11.0769 1.54658 11.0769 1.89474V2.52632H6.92308V1.89474Z"
      fill={color}
    />
    <path
      d="M1.24563 7.00037C1.11854 7.00016 1.01711 7.09999 1.02296 7.21953L1.59102 18.8317C1.64352 19.9064 2.57957 20.7495 3.7218 20.7514L14.5378 20.7696C15.68 20.7715 16.6189 19.9315 16.675 18.857L17.2821 7.24682C17.2884 7.1273 17.1873 7.02713 17.0602 7.02692L1.24563 7.00037ZM11.999 8.69488C11.9996 8.32438 12.3188 8.02483 12.7123 8.02549C13.1058 8.02615 13.4241 8.32677 13.4234 8.69727L13.4088 17.4148C13.4081 17.7853 13.0889 18.0848 12.6954 18.0842C12.3019 18.0835 11.9837 17.7829 11.9843 17.4124L11.999 8.69488ZM8.43787 8.6889C8.43849 8.3184 8.75771 8.01886 9.15122 8.01952C9.54472 8.02018 9.86294 8.3208 9.86231 8.69129L9.84765 17.4088C9.84703 17.7793 9.52781 18.0789 9.1343 18.0782C8.7408 18.0775 8.42259 17.7769 8.42321 17.4064L8.43787 8.6889ZM4.87675 8.68292C4.87738 8.31243 5.1966 8.01288 5.5901 8.01354C5.98361 8.0142 6.30182 8.31482 6.3012 8.68531L6.28654 17.4028C6.28592 17.7733 5.96669 18.0729 5.57319 18.0722C5.17969 18.0716 4.86147 17.7709 4.86209 17.4005L4.87675 8.68292Z"
      fill={color}
    />
  </svg>
);

export default DeleteButtonIcon;
