import { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import {
  CustomTooltipProps,
  DieselRate,
  SecondaryTripsGraphProps,
} from "../../../context/variables";
import { graphLoadOrNoDataNotice } from "../../../helpers/graphHelpers";
import LoadingSpinner from "../../../components/atoms/LoadingSpinner";

function convertMetersPerSecondToKnots(mpsValue: number): number {
  return mpsValue * 1.9438452;
}

function metersToNauticalMiles(meterValue: number): number {
  return meterValue / 1852;
}

function calculateTicks(data: number[], defaultMaxTicks: number): number[] {
  const max = Math.max(...data);
  if (max <= 10) {
    return Array.from({ length: Math.ceil(max) + 1 }, (_, i) => i);
  }
  const interval = max / defaultMaxTicks;
  const roundedInterval = Math.ceil(interval);
  return Array.from(
    { length: defaultMaxTicks + 1 },
    (_, i) => i * roundedInterval
  );
}

function CustomTooltip({
  active,
  payload,
  label,
  valueType,
}: CustomTooltipProps) {
  if (active && payload && payload.length) {
    const value = parseFloat(payload[0].value);
    let formattedValue = "";
    let labelName = "Index";
    let valueName = "Value";

    switch (valueType) {
      case "speed":
        formattedValue = !isNaN(value) ? `${value.toFixed(2)} Kn` : "";
        labelName = "Speed";
        valueName = "Distance";
        if (typeof label !== "undefined") {
          label = parseFloat(label.toString()).toFixed(2) + " NM";
        }
        break;
      case "consumption":
        formattedValue = !isNaN(value) ? `${value.toFixed(2)} L/h` : "";
        labelName = "Consumption";
        valueName = "Distance";
        if (typeof label !== "undefined") {
          label = parseFloat(label.toString()).toFixed(2) + " NM";
        }
        break;
      case "electrical":
        formattedValue = !isNaN(value) ? `${value.toFixed(2)} KW` : "";
        labelName = "Electrical Consumption";
        valueName = "Distance";
        if (typeof label !== "undefined") {
          label = parseFloat(label.toString()).toFixed(2) + " NM";
        }
        break;
    }

    return (
      <div className="custom-tooltip bg-white p-2 border rounded shadow-md">
        <p className="value">{`${labelName}: ${formattedValue}`}</p>
        <p className="label">{`${valueName}: ${label}`}</p>
      </div>
    );
  }

  return null;
}

function VesselSignalGraph({ secondaryTripData }: SecondaryTripsGraphProps) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const dieselRatesData: DieselRate[] = secondaryTripData?.diesel_rates?.map(
    (value, index) => ({
      index: metersToNauticalMiles(secondaryTripData?.dists?.[index]),
      value: Number(value),
    })
  );

  const sogsData = secondaryTripData?.sogs?.map((value, index) => ({
    index: secondaryTripData?.times?.[index] / 60,
    value: convertMetersPerSecondToKnots(value),
  }));
  const formattedDieselRatesData =
    dieselRatesData?.map((data) => ({
      ...data,
      value: parseFloat(data.value.toString()),
    })) || [];

  const timeTicks = calculateTicks(
    sogsData.map((data) => data.index),
    10
  );
  const distanceTicks = calculateTicks(
    formattedDieselRatesData.map((data) => data.index),
    10
  );
  const maxSpeedValue = Math.max(...sogsData.map((data) => data.value));
  const maxConsumptionValue = Math.max(
    ...formattedDieselRatesData.map((data) => data.value)
  );
  const speedTickInterval = Math.ceil(maxSpeedValue / 10);
  const consumptionTickInterval = Math.ceil(maxConsumptionValue / 10);
  const dynamicSpeedTicks = Array.from(
    { length: 11 },
    (_, i) => i * speedTickInterval
  );
  const dynamicConsumptionTicks = Array.from(
    { length: 11 },
    (_, i) => i * consumptionTickInterval
  );

  const filteredData = formattedDieselRatesData.filter(
    (data) => data.index <= 20000
  );
  // Start in the first point of the graph
  if (filteredData.length > 0 && filteredData[0].index !== 0) {
    filteredData.unshift({ index: 0, value: 0 });
  }

  if (isLoading) {
    return (
      <div className="relative min-h-[450px]">
        <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-3xl font-bold text-gray-500 animate-pulse fill-gray-500">
          <LoadingSpinner />
        </span>
      </div>
    );
  }

  if (
    !secondaryTripData.dists ||
    !secondaryTripData.dists.length ||
    !secondaryTripData.times ||
    !secondaryTripData.times.length
  ) {
    return null;
  }

  const hasSogsData =
    secondaryTripData.sogs && secondaryTripData.sogs.length > 0;
  const hasDieselRatesData =
    secondaryTripData.diesel_rates && secondaryTripData.diesel_rates.length > 0;

  const hasElecPowersData =
    secondaryTripData.elec_powers &&
    secondaryTripData.elec_powers.length > 0;

  const METERS_TO_NM = 0.000539957;

  const sogsDataWithDistance = secondaryTripData.sogs.map((data, index) => ({
    distance: secondaryTripData.dists[index] * METERS_TO_NM,
    value: convertMetersPerSecondToKnots(data), 
  }));

  const sogDistanceTicks = calculateTicks(
    sogsDataWithDistance.map((data) => data.distance),
    10
  );

  const elecPowersData = secondaryTripData.elec_powers.map((value, index) => ({
    index: secondaryTripData.dists[index] * METERS_TO_NM, 
    value: value,
  }));

  function getGridClasses() {
    let classes = 'grid p-10 text-deep_blue w-full';

    if (hasSogsData && hasDieselRatesData && hasElecPowersData) {
      classes += ' grid-cols-3 gap-4 pl-4';
    } else if ((hasSogsData && hasDieselRatesData) || (hasSogsData && hasElecPowersData) || (hasDieselRatesData && hasElecPowersData)) {
      classes += ' grid-cols-2 gap-4';
    } else if (hasSogsData || hasDieselRatesData || hasElecPowersData) {
      classes += ' grid-cols-1 place-items-center px-20';
    }

    return classes;
}

  return (
    <div className={getGridClasses()}>
      {hasSogsData && (
        <div className="flex flex-col justify-center items-center">
          <div className="font-bold text-xl mb-4 text-deep_blue">
            Speed over ground
          </div>
          <LineChart
            width={hasSogsData && hasDieselRatesData ? 375 : 750}
            height={350}
            data={sogsDataWithDistance}
            margin={{ left: 30, right: 20, bottom: 10 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="distance"
              ticks={sogDistanceTicks}
              domain={[0, "dataMax"]}
              type="number"
              label={{
                value: "Distance (NM)",
                position: "insideBottom",
                offset: 0,
                dx: -10,
                dy: 5,
                fontSize: 12,
              }}
            />
            <YAxis
              dataKey="value"
              domain={[0, "dataMax"]}
              tickFormatter={(tick) => `${tick} Kn`}
              ticks={dynamicSpeedTicks}
              label={{
                value: "Speed (kn)",
                angle: -90,
                position: "insideLeft",
                dx: -15,
                dy: 45,
                fontSize: 12,
              }}
            />
            <Tooltip
              content={
                <CustomTooltip
                  valueType="speed"
                  active={undefined}
                  payload={undefined}
                  label={undefined}
                />
              }
            />
            <Line type="monotone" dataKey="value" stroke="#3498db" />
            {graphLoadOrNoDataNotice(sogsData)}
          </LineChart>
        </div>
      )}
      {hasDieselRatesData && (
        <div className="flex flex-col justify-center items-center">
        <div className="font-bold text-xl mb-4 text-deep_blue">
            Fuel consumption
          </div>
          <LineChart
            width={hasSogsData && hasDieselRatesData ? 375 : 750}
            height={350}
            data={dieselRatesData}
            margin={{ top: 10, left: 40, right: 0, bottom: 10 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="index"
              type="number"
              ticks={distanceTicks}
              domain={[0, "dataMax"]}
              label={{
                value: "Distance (NM)",
                position: "insideBottom",
                offset: 0,
                dx: -15,
                dy: 5,
                fontSize: 12,
              }}
            />
            <YAxis
              dataKey="value"
              domain={[0, "dataMax"]}
              tickFormatter={(tick) => `${tick}\nL/h`}
              ticks={dynamicConsumptionTicks}
              label={{
                value: "Consumption (L/h)",
                angle: -90,
                position: "insideLeft",
                dx: -29,
                dy: 45,
                fontSize: 12,
              }}
            />
            <Tooltip
              content={
                <CustomTooltip
                  valueType="consumption"
                  active={undefined}
                  payload={undefined}
                  label={undefined}
                />
              }
            />
            <Line
              dataKey="value"
              stroke="#102840"
              type="monotone"
              isAnimationActive={true}
              dot={true}
            />
            {graphLoadOrNoDataNotice(dieselRatesData)}
          </LineChart>
        </div>
      )}
      {hasElecPowersData && (
        <div className="flex flex-col justify-center items-center">
        <div className="font-bold text-xl mb-4 text-deep_blue">
            Electrical Consumption
          </div>
          <LineChart
                  width={hasSogsData && hasDieselRatesData ? 375 : 750}
            height={350}
            data={elecPowersData}
            margin={{ left: 40, right: 20, bottom: 10 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="index"
              type="number"
              ticks={distanceTicks}
              domain={[
                Math.min(...elecPowersData.map((data) => data.index)),
                Math.max(...elecPowersData.map((data) => data.index)),
              ]}
              tickFormatter={(value) => value.toFixed(2)}
              label={{
                value: "Distance (NM)",
                position: "insideBottom",
                offset: 0,
                dy: 5,
                fontSize: 12,
              }}
            />
            <YAxis
              dataKey="value"
              domain={[
                0,
                Math.max(...elecPowersData.map((data) => data.value)) * 1.1,
              ]}
              tickFormatter={(value) => value.toFixed(2)}
              label={{
                value: "Electrical Consumption (KW)",
                angle: -90,
                position: "insideLeft",
                dx: -29,
                dy: 45,
                fontSize: 12,
              }}
            />
            <Tooltip
              content={
                <CustomTooltip
                  valueType="electrical"
                  active={undefined}
                  payload={undefined}
                  label={undefined}
                />
              }
            />
            <Line
              dataKey="value"
              stroke="#102840"
              type="monotone"
              isAnimationActive={true}
              dot={true}
            />
          </LineChart>
        </div>
      )}
    </div>
  );
}

export default VesselSignalGraph;
