import { format } from "date-fns";
import {
  setLocalAggregation,
  setLocalEnergyOperatorAggregation,
  setLocalEnergyOperatorFromTime,
  setLocalEnergyOperatorTimePeriod,
  setLocalEnergyOperatorTimescale,
  setLocalEnergyOperatorToTime,
  setLocalFromTime,
  setLocalTimePeriod,
  setLocalTimescale,
  setLocalToTime,
} from "../context/locals";
import { Aggregation, Timescale } from "../context/types";
import { viewSettings } from "../context/variables";

export function zeroPad(x: number) {
  if (x < 10) {
    return "0" + x;
  } else {
    return x.toString();
  }
}

export function dateToString(date: Date, inclTime: boolean) {
  const yearStr = date.getFullYear().toString();
  const monthStr = zeroPad(date.getMonth() + 1);
  const dateStr = zeroPad(date.getDate());
  const hoursStr = zeroPad(date.getHours());
  const minutesStr = zeroPad(date.getMinutes());
  const secondsStr = zeroPad(date.getSeconds());
  if (inclTime) {
    return (
      yearStr +
      "-" +
      monthStr +
      "-" +
      dateStr +
      "T" +
      hoursStr +
      ":" +
      minutesStr +
      ":" +
      secondsStr
    );
  } else {
    return yearStr + "-" + monthStr + "-" + dateStr;
  }
}

export function dateToStringUtc(date: Date, inclTime: boolean) {
  const yearStr = date.getUTCFullYear().toString();
  const monthStr = zeroPad(date.getUTCMonth() + 1);
  const dateStr = zeroPad(date.getUTCDate());
  const hoursStr = zeroPad(date.getUTCHours());
  const minutesStr = zeroPad(date.getUTCMinutes());
  const secondsStr = zeroPad(date.getUTCSeconds());
  if (inclTime) {
    return (
      yearStr +
      "-" +
      monthStr +
      "-" +
      dateStr +
      "T" +
      hoursStr +
      ":" +
      minutesStr +
      ":" +
      secondsStr
    );
  } else {
    return yearStr + "-" + monthStr + "-" + dateStr;
  }
}

export function stringToDate(strDate: string) {
  const dateParts = strDate.split("-");
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1;
  const rest = dateParts[2];
  let date: number;
  if (strDate.includes("T")) {
    // yyyy-mm-ddThh:mm:ss
    const dateAndTime = rest.split("T");
    date = parseInt(dateAndTime[0]);
    const timeParts = dateAndTime[1].split(":");
    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);
    const seconds = parseInt(timeParts[2]);
    return new Date(year, month, date, hours, minutes, seconds);
  } else {
    // yyyy-mm-dd
    date = parseInt(rest);
    return new Date(year, month, date);
  }
}

function leapYear(year: number) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export function getWeek(date: Date) {
  const yearNum = date.getFullYear();
  const prevYearLeapYear: boolean = leapYear(yearNum - 1);

  const janFirst = new Date(yearNum, 0, 1); // ???

  const weekdayJanFirstWithZero = janFirst.getDay();
  const weekdayJanFirst =
    weekdayJanFirstWithZero === 0 ? 7 : weekdayJanFirstWithZero;
  let yearNumOfJanFirst: number;
  let weekNumOfJanFirst: number;
  if (weekdayJanFirst <= 4) {
    yearNumOfJanFirst = yearNum;
    weekNumOfJanFirst = 1;
  } else if (weekdayJanFirst === 5) {
    yearNumOfJanFirst = yearNum - 1;
    weekNumOfJanFirst = 53;
  } else if (weekdayJanFirst === 6) {
    yearNumOfJanFirst = yearNum - 1;
    weekNumOfJanFirst = prevYearLeapYear ? 53 : 52;
  } else {
    // if(weekdayJanFirst == 7)
    yearNumOfJanFirst = yearNum - 1;
    weekNumOfJanFirst = 52;
  }

  const msAfterJanFirst = date.valueOf() - janFirst.valueOf();
  const roughDaysAfterJanFirst = msAfterJanFirst / (1000 * 60 * 60 * 24);
  const daysAfterJanFirst = Math.round(roughDaysAfterJanFirst); // ???
  const quotient = Math.floor(daysAfterJanFirst / 7);
  const remainder = daysAfterJanFirst % 7;
  const remainderWeekDiff: number = weekdayJanFirst + remainder > 7 ? 1 : 0;

  // console.log("msAfterJanFirst:", msAfterJanFirst)
  // console.log("roughDaysAfterJanFirst:", roughDaysAfterJanFirst)
  // console.log("daysAfterJanFirst:", daysAfterJanFirst)
  // console.log("weekdayJanFirst:", weekdayJanFirst)
  // console.log("quotient:", quotient)
  // console.log("remainder:", remainder)
  // console.log("remainderWeekDiff:", remainderWeekDiff)
  const weekDiff = quotient + remainderWeekDiff;
  let yearNumTotal;
  let weekNumTotal;
  yearNumTotal =
    weekNumOfJanFirst === 1
      ? yearNum
      : weekDiff === 0
      ? yearNumOfJanFirst
      : yearNum;
  weekNumTotal =
    weekNumOfJanFirst === 1
      ? weekNumOfJanFirst + weekDiff
      : weekDiff === 0
      ? weekNumOfJanFirst
      : weekDiff;
  // console.log("weekNumOfJanFirst:", weekNumOfJanFirst)
  // console.log("weekDiff:", weekDiff)
  // console.log("weekNumTotal:", weekNumTotal)

  // If it's monday, tuesday or wednesday and thursday is next year, you're in week one of next year, otherwise change nothing
  const weekdayNumWithZero = date.getDay();
  const weekdayNum = weekdayNumWithZero === 0 ? 7 : weekdayNumWithZero;
  const janFirstNextYear = new Date(yearNum + 1, 0, 1);
  if (weekdayNum <= 3) {
    const daysUntilNextJanFirst = Math.floor(
      (janFirstNextYear.valueOf() - date.valueOf()) / (1000 * 60 * 60 * 24)
    );
    const daysUntilThursday = 4 - weekdayNum;
    if (daysUntilThursday >= daysUntilNextJanFirst) {
      yearNumTotal = yearNum + 1;
      weekNumTotal = 1;
    }
  }

  const yearStr =
    yearNumTotal >= 10
      ? yearNumTotal.toString()
      : "0" + yearNumTotal.toString();
  const weekStr =
    weekNumTotal >= 10
      ? weekNumTotal.toString()
      : "0" + weekNumTotal.toString();
  // console.log("yearStr:", yearStr, "weekStr:", weekStr)
  return yearStr + "-W" + weekStr;
}

export function findTimeBounds(date: Date, timescale: Timescale) {
  const year = date.getFullYear();
  const month = date.getMonth();
  const dayOfMonth = date.getDate();
  const weekDayNumWithZero = date.getDay(); // Sunday is 0, saturday is 6
  const weekDayNum = weekDayNumWithZero !== 0 ? weekDayNumWithZero : 7;
  const beginningOfYear = new Date(year, 0, 1);
  const endOfYear = new Date(year, 11, 31);
  const beginningOfMonth = new Date(year, month, 1);
  const endOfMonth = new Date(year, month + 1, 0);
  const beginningOfWeek = new Date(year, month, dayOfMonth - (weekDayNum - 1));
  const endOfWeek = new Date(year, month, dayOfMonth + (7 - weekDayNum));
  const beginningOfDay = new Date(year, month, dayOfMonth, 0, 0, 0);
  const endOfDay = new Date(year, month, dayOfMonth + 1, 0, 0, -1);
  // const theNextDay = new Date(year, month, dayOfMonth+1)

  let fromTime: Date;
  let toTime: Date;
  if (timescale === Timescale.Year) {
    fromTime = beginningOfYear;
    toTime = endOfYear;
  } else if (timescale === Timescale.Month) {
    fromTime = beginningOfMonth;
    toTime = endOfMonth;
  } else if (timescale === Timescale.Week) {
    fromTime = beginningOfWeek;
    toTime = endOfWeek;
  } else {
    //if(timescale === Timescale.Day)
    fromTime = beginningOfDay;
    toTime = endOfDay;
  }
  return { fromTime, toTime };
}

export function dateInNextPeriod(toDate: Date) {
  const year = toDate.getFullYear();
  const month = toDate.getMonth();
  const dayOfMonth = toDate.getDate();
  const dayAfter = new Date(year, month, dayOfMonth + 1);
  return dayAfter;
}

export function dateInPrevPeriod(fromDate: Date) {
  const year = fromDate.getFullYear();
  const month = fromDate.getMonth();
  const dayOfMonth = fromDate.getDate();
  const dayBefore = new Date(year, month, dayOfMonth - 1);
  return dayBefore;
}

export function findTimePeriod(date: Date, timescale: Timescale): string {
  const year = date.getFullYear();
  const yearStr = year.toString();
  const monthNum = date.getMonth() + 1;
  const monthStr =
    monthNum >= 10 ? monthNum.toString() : "0" + monthNum.toString();
  const dayOfMonthNum = date.getDate();
  const dayOfMonthStr =
    dayOfMonthNum >= 10
      ? dayOfMonthNum.toString()
      : "0" + dayOfMonthNum.toString();
  const weekStr: string = getWeek(date);
  // A year: 2022
  // A month: 2022-09
  // A week: 2022-W37
  // A day: 2022-09-13
  let timePeriod;
  if (timescale === Timescale.Year) {
    timePeriod = yearStr;
  } else if (timescale === Timescale.Month) {
    timePeriod = yearStr + "-" + monthStr;
  } else if (timescale === Timescale.Week) {
    timePeriod = weekStr;
  } else {
    //if(timescale === Timescale.Day)
    timePeriod = yearStr + "-" + monthStr + "-" + dayOfMonthStr;
  }
  return timePeriod;
}

export function timePeriodChanged(
  date: Date,
  timescale: Timescale,
  view: viewSettings
) {
  const timePeriod = findTimePeriod(date, timescale);
  view.energyOverall.setTimePeriod(timePeriod);
  setLocalTimePeriod(timePeriod);
  const { fromTime, toTime } = findTimeBounds(date, timescale);
  view.energyOverall.setFromTime(fromTime);
  setLocalFromTime(fromTime);
  view.energyOverall.setToTime(toTime);
  setLocalToTime(toTime);
}

export function timePeriodOperatorsChanged(
  date: Date,
  timescale: Timescale,
  view: viewSettings
) {
  const timePeriod = findTimePeriod(date, timescale);
  view.energyOperator.setTimePeriod(timePeriod);
  setLocalEnergyOperatorTimePeriod(timePeriod);
  const { fromTime, toTime } = findTimeBounds(date, timescale);
  view.energyOperator.setFromTime(fromTime);
  setLocalEnergyOperatorFromTime(fromTime);
  view.energyOperator.setToTime(toTime);
  setLocalEnergyOperatorToTime(toTime);
}

export function timescaleChanged(timescale: Timescale, view: viewSettings) {
  view.energyOverall.setTimescale(timescale);
  setLocalTimescale(timescale);
  let aggregation;
  if (timescale === Timescale.Year) {
    aggregation = Aggregation.Month;
  } else if (timescale === Timescale.Month) {
    aggregation = Aggregation.Day;
  } else if (timescale === Timescale.Week) {
    aggregation = Aggregation.Day;
  } else {
    // if(timescale === Timescale.Day)
    aggregation = Aggregation.Hour;
  }
  // setting aggregation
  view.energyOverall.setAggregation(aggregation);
  setLocalAggregation(aggregation);
  // setting timePeriod
  const theFromTime = view.energyOverall.fromTime;
  if (theFromTime != null) {
    timePeriodChanged(theFromTime, timescale, view);
  }
}

export function timescaleOperatorsChanged(
  timescale: Timescale,
  view: viewSettings
) {
  view.energyOperator.setTimescale(timescale);
  setLocalEnergyOperatorTimescale(timescale);
  let aggregation;
  if (timescale === Timescale.Year) {
    aggregation = Aggregation.Month;
  } else if (timescale === Timescale.Month) {
    aggregation = Aggregation.Day;
  } else if (timescale === Timescale.Week) {
    aggregation = Aggregation.Day;
  } else {
    // if(timescale === Timescale.Day)
    aggregation = Aggregation.Hour;
  }
  // setting aggregation
  view.energyOperator.setAggregation(aggregation);
  setLocalEnergyOperatorAggregation(aggregation);
  // setting timePeriod
  const theFromTime = view.energyOperator.fromTime;
  if (theFromTime != null) {
    timePeriodOperatorsChanged(theFromTime, timescale, view);
  }
}

export const monthNames = [
  { short: "Jan", long: "January" },
  { short: "Feb", long: "February" },
  { short: "Mar", long: "March" },
  { short: "Apr", long: "April" },
  { short: "May", long: "May" },
  { short: "Jun", long: "June" },
  { short: "Jul", long: "July" },
  { short: "Aug", long: "August" },
  { short: "Sep", long: "September" },
  { short: "Oct", long: "October" },
  { short: "Nov", long: "November" },
  { short: "Dec", long: "December" },
];

export function epochToPrettyString(epochTime: number) {
  const date = new Date(epochTime);
  const yearNum = date.getFullYear();
  const yearStr = yearNum.toString();
  const monthNum = date.getMonth();
  const monthStr = monthNames[monthNum]?.short;
  const dayOfMonthNum = date.getDate();
  const dayOfMonthStr =
    dayOfMonthNum >= 10
      ? dayOfMonthNum.toString()
      : "0" + dayOfMonthNum.toString();
  const hoursNum = date.getHours();
  const hoursStr =
    hoursNum >= 10 ? hoursNum.toString() : "0" + hoursNum.toString();
  const minutesNum = date.getMinutes();
  const minutesStr =
    minutesNum >= 10 ? minutesNum.toString() : "0" + minutesNum.toString();
  const secondsNum = date.getSeconds();
  const secondsStr =
    secondsNum >= 10 ? secondsNum.toString() : "0" + secondsNum.toString();

  const finalStr =
    yearStr +
    "-" +
    monthStr +
    "-" +
    dayOfMonthStr +
    " " +
    hoursStr +
    ":" +
    minutesStr +
    ":" +
    secondsStr;
  return finalStr;
}

export function epochToPrettyStringUtc(epochTime: number) {
  const date = new Date(epochTime);
  const yearNum = date.getUTCFullYear();
  const yearStr = yearNum.toString();
  const monthNum = date.getUTCMonth();
  const monthStr = monthNames[monthNum]?.short;
  const dayOfMonthNum = date.getUTCDate();
  const dayOfMonthStr =
    dayOfMonthNum >= 10
      ? dayOfMonthNum.toString()
      : "0" + dayOfMonthNum.toString();
  const hoursNum = date.getUTCHours();
  const hoursStr =
    hoursNum >= 10 ? hoursNum.toString() : "0" + hoursNum.toString();
  const minutesNum = date.getUTCMinutes();
  const minutesStr =
    minutesNum >= 10 ? minutesNum.toString() : "0" + minutesNum.toString();
  const secondsNum = date.getUTCSeconds();
  const secondsStr =
    secondsNum >= 10 ? secondsNum.toString() : "0" + secondsNum.toString();

  const finalStr =
    yearStr +
    "-" +
    monthStr +
    "-" +
    dayOfMonthStr +
    " " +
    hoursStr +
    ":" +
    minutesStr +
    ":" +
    secondsStr;
  return finalStr;
}

export function epochToPrettyStringRealTime(epochTime: number) {
  const date = new Date(epochTime);
  // const yearNum = date.getFullYear()
  // const yearStr = yearNum.toString()
  const monthNum = date.getMonth();
  const monthStr = monthNames[monthNum]?.short;
  const dayOfMonthNum = date.getDate();
  // const dayOfMonthStr = (dayOfMonthNum >= 10) ? dayOfMonthNum.toString() : "0" + dayOfMonthNum.toString()
  const hoursNum = date.getHours();
  const hoursStr =
    hoursNum >= 10 ? hoursNum.toString() : "0" + hoursNum.toString();
  const minutesNum = date.getMinutes();
  const minutesStr =
    minutesNum >= 10 ? minutesNum.toString() : "0" + minutesNum.toString();
  const secondsNum = date.getSeconds();
  const secondsStr =
    secondsNum >= 10 ? secondsNum.toString() : "0" + secondsNum.toString();

  const finalStr =
    dayOfMonthNum.toString() +
    " " +
    monthStr +
    " " +
    hoursStr +
    ":" +
    minutesStr +
    ":" +
    secondsStr;
  return finalStr;
}

export function epochAggrToPrettyString(
  epochTime: number,
  aggregation: Aggregation
) {
  const date = new Date(epochTime);
  const yearNum = date.getFullYear();
  const yearStr = yearNum.toString();
  const monthNum = date.getMonth();
  const monthStr = monthNames[monthNum]?.short;
  const dayOfMonthNum = date.getDate();
  const dayOfMonthStr =
    dayOfMonthNum >= 10
      ? dayOfMonthNum.toString()
      : "0" + dayOfMonthNum.toString();
  const hoursNum = date.getHours();
  const hoursStr =
    hoursNum >= 10 ? hoursNum.toString() : "0" + hoursNum.toString();
  const minutesNum = date.getMinutes();
  const minutesStr =
    minutesNum >= 10 ? minutesNum.toString() : "0" + minutesNum.toString();

  let finalStr: string;
  if (aggregation === Aggregation.Year) {
    finalStr = yearStr;
  } else if (aggregation === Aggregation.Month) {
    finalStr = monthStr + " " + yearStr;
  } else if (aggregation === Aggregation.Day) {
    finalStr = dayOfMonthNum.toString() + " " + monthStr;
  } else if (aggregation === Aggregation.Hour) {
    finalStr =
      dayOfMonthNum.toString() +
      " " +
      monthStr +
      " " +
      hoursStr +
      ":" +
      minutesStr;
  } else {
    finalStr =
      yearStr +
      "-" +
      monthStr +
      "-" +
      dayOfMonthStr +
      " " +
      hoursStr +
      ":" +
      minutesStr;
  }
  return finalStr;
}

export function epochToXAxisString(epochTime: number, timescale: Timescale) {
  const date = new Date(epochTime);
  const yearNum = date.getFullYear();
  const yearStr = yearNum.toString();
  const monthNum = date.getMonth();
  const monthStr = monthNames[monthNum]?.short;
  const dayOfMonthNum = date.getDate();
  const dayOfMonthStr =
    dayOfMonthNum >= 10
      ? dayOfMonthNum.toString()
      : "0" + dayOfMonthNum.toString();
  const hoursNum = date.getHours();
  const hoursStr =
    hoursNum >= 10 ? hoursNum.toString() : "0" + hoursNum.toString();
  const minutesNum = date.getMinutes();
  const minutesStr =
    minutesNum >= 10 ? minutesNum.toString() : "0" + minutesNum.toString();

  let finalStr: string;
  if (timescale === Timescale.Year) {
    finalStr = monthNames[monthNum].long;
  } else if (timescale === Timescale.Month) {
    finalStr = dayOfMonthNum.toString();
  } else if (timescale === Timescale.Week) {
    finalStr = dayOfMonthNum.toString() + " " + monthStr;
  } else if (timescale === Timescale.Day) {
    finalStr = hoursStr + ":" + minutesStr;
  } else {
    finalStr =
      yearStr +
      "-" +
      monthStr +
      "-" +
      dayOfMonthStr +
      " " +
      hoursStr +
      ":" +
      minutesStr;
  }
  return finalStr;
}

export const standardAggregationForTimescale = {
  [Timescale.Year]: Aggregation.Month,
  [Timescale.Month]: Aggregation.Day,
  [Timescale.Week]: Aggregation.Day,
  [Timescale.Day]: Aggregation.Hour,
};

export function nowDate(): Date {
  return new Date();
}

export function startOfUTCDay(date: Date): Date {
  const newDate = new Date(date);
  newDate.setUTCHours(0);
  newDate.setUTCMinutes(0);
  newDate.setUTCSeconds(0);
  newDate.setUTCMilliseconds(0);
  return newDate;
}

export function startOfUTCDayAfter(date: Date): Date {
  const newDate = new Date(date);
  newDate.setUTCDate(newDate.getUTCDate() + 1);
  newDate.setUTCHours(0);
  newDate.setUTCMinutes(0);
  newDate.setUTCSeconds(0);
  newDate.setUTCMilliseconds(0);
  return newDate;
}

export function addDays(date: Date, days: number): Date {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

export const formatDateTime = (dateTime: string | number | Date): string => {
  return format(new Date(dateTime), "dd MMM yyyy HH:mm:ss"); // format: 01 Jan 2024 23:59:00
};

export const formatDateTimeYearToMinute = (dateString: string): string => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // getMonth() returns 0 for January, 11 for December.
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Pad the month, day, hours, and minutes with leading zeros if they are less than 10
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${year}-${formattedMonth}-${formattedDay} (${formattedHours}:${formattedMinutes})`;
};
