import React, { useEffect, useState } from 'react';
import Tab from '../../../tabs/Tab';
import useFetchData from '../../../apiComms/fetchData';
import { useAppContext } from '../../../context/variables';
import { genericTableRelatedBits } from '../../../components/Table';
import { AddTagEventModal } from './AddTagEventModal';
import { ManageTagEventModal } from './ManageTagEventModal';
import { UtcNotice } from '../../../components/miscellaneous';
import TagEventsTable from './TagEventsTable';
import { inputSetting, inputTray, makeVesselPicker } from '../../../components/variousInputs';
import { GeneralDateRangePicker } from '../../../components/timeInputs';
import { addDays, dateToStringUtc, nowDate, startOfUTCDay, startOfUTCDayAfter } from '../../../helpers/dateTimeHelpers';
import EmptyState from '../../../components/atoms/EmptyState';
import TagIcon from '../../../assets/icons/EmptyStates/TagIcon';
import { addButton } from '../../../components/Button';

export default function TagEventsTab() {
  const { data, vars } = useAppContext();
  const { fetchTagEvents, fetchDataTagTypes, fetchDataOrganisations, fetchUsersGeneral } = useFetchData();

  useEffect(() => {
    fetchDataOrganisations();
    fetchUsersGeneral();
  }, [vars.auth.idToken, vars.auth.organisationId]);

  useEffect(() => {
    fetchDataTagTypes();
  }, [vars.auth.organisationId, vars.auth.accessLevel[0]]);

  function refreshList() {
    const vesselStrNull = vessel === 'allVessels' ? null : vessel;
    const startDateStrMaybe = startDate === null ? null : dateToStringUtc(startDate, true);
    const endDateStrMaybe = endDate === null ? null : dateToStringUtc(startOfUTCDayAfter(endDate), true);
    fetchTagEvents(vesselStrNull, startDateStrMaybe, endDateStrMaybe);
  }

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [manageModalOpen, setManageModalOpen] = useState(false);
  const [managedId, setManagedId] = useState<string | null>(null);

  const [vessel, setVessel] = useState('allVessels');
  const vesselOptions = [{ id: 'allVessels', name: 'All vessels' }].concat(data.org.vesselList);
  const vesselPicker = makeVesselPicker(vessel, vesselOptions, setVessel);
  const vesselDropdown = inputSetting('Vessel', vesselPicker, 240);

  const initialEndDate = startOfUTCDay(nowDate());
  const initialStartDate = addDays(initialEndDate, -6);
  const [startDate, setStartDate] = useState<Date | null>(initialStartDate);
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate);
  const dateRangePicker = GeneralDateRangePicker(startDate, endDate, setStartDate, setEndDate);

  useEffect(() => {
    refreshList();
  }, [vars.auth.idToken, vars.auth.organisationId, vessel, startDate, endDate]);

  const inputs = [[vesselDropdown, dateRangePicker]];

  const isEmptyState = !data.tagEvents.tagEventList.val || data.tagEvents.tagEventList.val.length === 0;

  const content = (
    <div className="flex flex-col space-y-3 text-deep_blue">
      {addButton('Apply tag', () => {
        setAddModalOpen(true);
      })}
      <div className="flex items-center space-x-4">
        {genericTableRelatedBits('Tags', data.tagEvents.tagEventList.val?.length, refreshList)}
        <UtcNotice />
      </div>
      {inputTray(inputs)}
      {isEmptyState ? (
        <EmptyState
          icon={<TagIcon />}
          title="No tags have been added"
          description="Add new tags to categorize your entries"
          buttonText="Add tag"
          onButtonClick={() => setAddModalOpen(true)}
        />
      ) : (
        TagEventsTable(setManagedId, setManageModalOpen)
      )}
      {AddTagEventModal(addModalOpen, setAddModalOpen, refreshList)}
      {ManageTagEventModal(manageModalOpen, setManageModalOpen, managedId, setManagedId, refreshList)}
    </div>
  );

  return <Tab title="Tag events" content={content} />;
}
