import React from 'react';
import { StyledTableCell, StyledTableHeaderCell, IconTableCell } from '../styles/TableCellStyles';

interface TableCellProps {
  children: React.ReactNode;
  className?: string;
  isHeader?: boolean;
  isIcon?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  onClick?: () => void;
  isCustomizedTab?: boolean;  
}


const TableCell: React.FC<TableCellProps> = ({
  children,
  className,
  isHeader = false,
  isIcon = false,
  isFirst = false,
  isLast = false,
  onClick,
  isCustomizedTab,  
}) => {
  if (isHeader) {
    return (
      <StyledTableHeaderCell
        className={className}
        isFirst={isFirst}
        isLast={isLast}
        isCustomizedTab={isCustomizedTab}  
        onClick={onClick}
      >
        {children}
      </StyledTableHeaderCell>
    );
  }
  if (isIcon) {
    return (
      <IconTableCell
        className={className}
        isFirst={isFirst}
        isLast={isLast}
        isCustomizedTab={isCustomizedTab}  
        onClick={onClick}
      >
        {children}
      </IconTableCell>
    );
  }
  return (
    <StyledTableCell
      className={className}
      isFirst={isFirst}
      isLast={isLast}
      isCustomizedTab={isCustomizedTab} 
      onClick={onClick}
    >
      {children}
    </StyledTableCell>
  );
};

export default TableCell;
