import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import {
  AppContext,
  useApiData,
  useVariables,
  useViewSettings,
} from "./context/variables";
import PageScreen from "./screens/PageScreen";

import LoginScreen from "./screens/LoginScreen";
import OverviewPage from "./pages/overview-page/OverviewPage";
import RealTimePage from "./pages/realTime/RealTimePage";
import EnergyPage from "./pages/energy/EnergyPage";
import { EnergyOverallTab } from "./pages/energy/overall/EnergyOverallTab";
import { EnergyOperatorsTab } from "./pages/energy/operators/EnergyOperatorsTab";
import PerformancePage from "./pages/performance/PerformancePage";
import AdministrationPage from "./pages/administration/AdministrationPage";
import RootPage from "./pages/RootPage";
// import WipPage from './pages/WipPage';
import ErrorPage from "./pages/ErrorPage";
import ReportsPage from "./pages/ReportsPage";
import DestinationsTab from "./pages/administration/destinations/DestinationsTab";
import TagTypesTab from "./pages/administration/tagTypes/TagTypesTab";
import OperationModeTab from "./pages/administration/operationalMode/opMode";
import LogbookPage from "./pages/logbook/LogbookPage";
import TripsTab from "./pages/logbook/trips/TripsTab";
import TagEventsTab from "./pages/logbook/tagEvents/TagEventsTab";
import RootOrganisationsTab from "./pages/rootAccess/organisations/RootOrganisationsTab";
import RootVesselsTab from "./pages/rootAccess/vessels/RootVesselsTab";
import UsersTab from "./pages/administration/users/UsersTab";
// import RootDestinationsTab from './tabs/RootDestinationsTab'
import ErrorTab from "./tabs/ErrorTab";
import "react-datepicker/dist/react-datepicker.css";

import { getLocals } from "./context/locals";
// import RoutesTab from './pages/administration/Routes/RoutesTab';
import VoyageTrips from "./pages/administration/voyageTrips/VoyageTrips";
import RootHealthStatusTab from "./pages/rootAccess/healthStatusMonitoring/RootHealthStatusTab";
import VesselGroupsTab from "./pages/administration/vesselGroups/vesselGroupsTab";
import { environmentConfig } from "./env/env";
import { AccessLevel, accessLevelAtMinimum } from "./context/types";
import GeoZonesTab from "./pages/administration/geozones/geoZonesTab";
import CustomAlarmPage from "./pages/CustomAlarms/CustomAlarmPage";
import HistoryTab from "./pages/CustomAlarms/HistoryTab";
import CustomisedTab from "./pages/CustomAlarms/CustomizedTab";

function pageRoute(
  pagePath: string,
  pageElement: JSX.Element,
  pageVisible: boolean,
  tabs?: { path: string; element: JSX.Element; visible: boolean }[]
): JSX.Element {
  if (!pageVisible) return <></>;
  if (tabs === undefined)
    return <Route path={pagePath} element={pageElement} />;

  const visibleTabs = tabs.filter((tab) => tab.visible);
  const defaultTabPath = visibleTabs.length > 0 ? visibleTabs[0].path : "";
  const defaultTabRedirect = (
    <Navigate to={"/" + pagePath + "/" + defaultTabPath} />
  );

  const tabRoutes = visibleTabs.map((tab, index) => (
    <Route key={index} path={tab.path} element={tab.element} />
  ));
  return (
    <Route path={pagePath} element={pageElement}>
      <Route path="" element={defaultTabRedirect} />
      {tabRoutes}
      <Route path="*" element={<ErrorTab />} />
    </Route>
  );
}
const queryClient = new QueryClient();

export default function App() {
  const variables = useVariables();
  const apiData = useApiData();
  const viewSettings = useViewSettings();
  const context = { vars: variables, data: apiData, view: viewSettings };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getLocals(context.vars, context.view, context.data), []);

  const adminOrAbove = accessLevelAtMinimum(
    context.vars.auth.accessLevel[0],
    AccessLevel.Admin
  );
  const isRoot = accessLevelAtMinimum(
    context.vars.auth.accessLevel[0],
    AccessLevel.Root
  );

  const featuresCfg = environmentConfig.features;
  const energyCfg = featuresCfg.energy;
  const energyVisible = energyCfg !== false;
  const logbookCfg = featuresCfg.logbook;
  const logbookVisible = logbookCfg !== false;
  const administrationCfg = featuresCfg.administration;
  const administrationVisible = administrationCfg !== false;
  const customAlarmsVisible = featuresCfg.customAlarms !== false;

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider value={context}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LoginScreen />} />{" "}
            {/* should this log you out? redirect to the overview? */}
            <Route path="/" element={<PageScreen />}>
              <Route path="" element={<Navigate to="/overview" />} />
              {pageRoute("overview", <OverviewPage />, true)}
              {pageRoute("realtime", <RealTimePage />, featuresCfg.realtime)}
              {/* {pageRoute("insights", <WipPage title='Insights for your operation' />)} */}
              {pageRoute("energy", <EnergyPage />, energyVisible, [
                {
                  path: "overall",
                  element: <EnergyOverallTab />,
                  visible: energyVisible && energyCfg.overall,
                },
                {
                  path: "operators",
                  element: <EnergyOperatorsTab />,
                  visible: energyVisible && energyCfg.operators,
                },
              ])}
              {pageRoute(
                "performance",
                <PerformancePage />,
                featuresCfg.performance
              )}
              {pageRoute("reports", <ReportsPage />, featuresCfg.reports)}
              {pageRoute("logbook", <LogbookPage />, logbookVisible, [
                {
                  path: "trips",
                  element: <TripsTab />,
                  visible: logbookVisible && logbookCfg.trips,
                },
                {
                  path: "tags",
                  element: <TagEventsTab />,
                  visible: logbookVisible && logbookCfg.tags,
                },
              ])}
              {pageRoute(
                "administration",
                <AdministrationPage />,
                administrationVisible,
                [
                  {
                    path: "destinations",
                    element: <DestinationsTab />,
                    visible:
                      administrationVisible && administrationCfg.destinations,
                  },
                  {
                    path: "users",
                    element: <UsersTab />,
                    visible:
                      administrationVisible &&
                      administrationCfg.users &&
                      adminOrAbove,
                  },
                  {
                    path: "operation-mode",
                    element: <OperationModeTab />,
                    visible:
                      administrationVisible &&
                      administrationCfg.operation_modes &&
                      adminOrAbove,
                  },
                  {
                    path: "vessel-groups",
                    element: <VesselGroupsTab />,
                    visible:
                      administrationVisible &&
                      administrationCfg.vessel_groups &&
                      adminOrAbove,
                  },
                  {
                    path: "geo-zones",
                    element: <GeoZonesTab />,
                    visible:
                      administrationVisible &&
                      administrationCfg.geozones &&
                      adminOrAbove,
                  },
                  {
                    path: "tag-types",
                    element: <TagTypesTab />,
                    visible:
                      administrationVisible &&
                      administrationCfg.tag_types &&
                      adminOrAbove,
                  },
                  // { path: "routes", element: <RoutesTab/>, visible: (administrationVisible && administrationCfg.) && adminOrAbove },
                  {
                    path: "trips",
                    element: <VoyageTrips />,
                    visible:
                      administrationVisible &&
                      administrationCfg.voyage_trips &&
                      adminOrAbove,
                  },
                  // { path: "vessels", element: <WipTab title='Vessels' />, visible: administrationVisible && administrationCfg. },
                ]
              )}
              {pageRoute("root", <RootPage />, isRoot, [
                {
                  path: "organisations",
                  element: <RootOrganisationsTab />,
                  visible: true,
                },
                { path: "vessels", element: <RootVesselsTab />, visible: true },
                {
                  path: "status",
                  element: <RootHealthStatusTab />,
                  visible: true,
                },
                // { path: "destinations", element: <RootDestinationsTab />, visible: true },
              ])}
              {pageRoute(
                "custom-alarms",
                <CustomAlarmPage />,
                customAlarmsVisible,
                [
                  {
                    path: "customised",
                    element: <CustomisedTab />,
                    visible: true,
                  },
                  { path: "history", element: <HistoryTab />, visible: true },
                ]
              )}
              <Route path="*" element={<ErrorPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AppContext.Provider>
    </QueryClientProvider>
  );
}

// if not logged in: show log in screen
// else: route according to url (/ -> overview, * -> error page)

// flex design, menubar jumping to smaller size?,
//  pages' contents  stacking up vertically,
//  map shrinking horizontally etc.
