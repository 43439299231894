import React from 'react';

const DocumentIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="250" height="200" viewBox="0 0 250 200" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M153.672 63.9997L162.974 131.842L163.809 138.649C164.079 140.841 162.519 142.837 160.327 143.106L101.766 150.297C99.5738 150.566 97.578 149.007 97.3088 146.814L88.2931 73.3865C88.1584 72.2901 88.9381 71.2922 90.0344 71.1576C90.0413 71.1568 90.0483 71.156 90.0552 71.1552L94.9136 70.6102M98.8421 70.1695L103.429 69.655L98.8421 70.1695Z" fill="white"/>
    <path d="M154.91 63.8299C154.816 63.1459 154.186 62.6675 153.502 62.7613C152.818 62.8551 152.34 63.4855 152.433 64.1695L154.91 63.8299ZM162.974 131.842L164.214 131.69C164.214 131.684 164.213 131.678 164.212 131.673L162.974 131.842ZM163.809 138.649L165.05 138.496L163.809 138.649ZM160.327 143.106L160.479 144.347L160.327 143.106ZM101.766 150.297L101.919 151.537L101.766 150.297ZM97.3088 146.814L98.5495 146.662L97.3088 146.814ZM90.0552 71.1552L90.1945 72.3974L90.0552 71.1552ZM95.0529 71.8524C95.739 71.7755 96.2327 71.1569 96.1558 70.4709C96.0788 69.7848 95.4603 69.291 94.7742 69.368L95.0529 71.8524ZM98.7028 68.9273C98.0167 69.0043 97.5229 69.6228 97.5999 70.3089C97.6768 70.9949 98.2954 71.4887 98.9814 71.4117L98.7028 68.9273ZM103.568 70.8972C104.255 70.8202 104.748 70.2017 104.671 69.5156C104.594 68.8296 103.976 68.3358 103.29 68.4128L103.568 70.8972ZM152.433 64.1695L161.735 132.012L164.212 131.673L154.91 63.8299L152.433 64.1695ZM161.733 131.995L162.569 138.801L165.05 138.496L164.214 131.69L161.733 131.995ZM162.569 138.801C162.754 140.309 161.682 141.681 160.174 141.866L160.479 144.347C163.357 143.994 165.403 141.374 165.05 138.496L162.569 138.801ZM160.174 141.866L101.614 149.056L101.919 151.537L160.479 144.347L160.174 141.866ZM101.614 149.056C100.107 149.241 98.7346 148.169 98.5495 146.662L96.0681 146.966C96.4215 149.844 99.0409 151.891 101.919 151.537L101.614 149.056ZM98.5495 146.662L89.5337 73.2341L87.0524 73.5388L96.0681 146.966L98.5495 146.662ZM89.5337 73.2341C89.4833 72.823 89.7756 72.4488 90.1867 72.3983L89.8821 69.9169C88.1005 70.1357 86.8336 71.7572 87.0524 73.5388L89.5337 73.2341ZM90.1867 72.3983C90.1893 72.398 90.1919 72.3977 90.1945 72.3974L89.9159 69.913C89.9046 69.9142 89.8933 69.9156 89.8821 69.9169L90.1867 72.3983ZM90.1945 72.3974L95.0529 71.8524L94.7742 69.368L89.9159 69.913L90.1945 72.3974ZM98.9814 71.4117L103.568 70.8972L103.29 68.4128L98.7028 68.9273L98.9814 71.4117Z" fill="#1F64E7"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M151.14 68.2696L159.56 129.753L160.317 135.922C160.561 137.909 159.167 139.715 157.203 139.956L104.761 146.395C102.798 146.636 101.008 145.221 100.764 143.234L92.6141 76.8572C92.4795 75.7608 93.2591 74.7629 94.3555 74.6283L100.843 73.8318" fill="#E8F0FE"/>
    <path d="M107.922 54C107.922 52.4812 109.153 51.25 110.672 51.25H156.229C156.958 51.25 157.657 51.5395 158.173 52.0549L171.616 65.4898C172.132 66.0056 172.422 66.7053 172.422 67.4349V130C172.422 131.519 171.191 132.75 169.672 132.75H110.672C109.153 132.75 107.922 131.519 107.922 130V54Z" fill="white" stroke="#1F64E7" strokeWidth="2.5"/>
    <path d="M156.672 52.4023V63.9995C156.672 65.6564 158.015 66.9995 159.672 66.9995H167.605" stroke="#1F64E7" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M118 118H144M118 67H144H118ZM118 79H161H118ZM118 92H161H118ZM118 105H161H118Z" stroke="#75A4FE" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default DocumentIcon;
