import { useEffect } from "react";
import { useAppContext } from "../../context/variables";
import useFetchData from "../../apiComms/fetchData";
import Page from "../Page";
import { environmentConfig } from "../../env/env";
import { PanelUser } from "../../components/molecules";
import { StyledWrapper } from "./OverviewPage.styles";
import { CustomAlarmsPanel, VesselsPanel, FuelUsagePanel } from "./panels";

export default function OverviewPage() {
  const { data, vars } = useAppContext();
  const { fetchDataOrganisations, fetchDataOverviewPageTotal } = useFetchData();

  useEffect(() => {
    fetchDataOrganisations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.idToken, vars.auth.organisationId]);
  useEffect(() => {
    fetchDataOverviewPageTotal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.org.vesselList]);

  const userPanel = <PanelUser title="Logged in as:" />;

  // ----- Putting together page -----
  const renderPanel = (card: JSX.Element, visible: boolean) => {
    if (!visible) return <></>;
    return card;
  };

  const overviewCfg = environmentConfig.features.overview;

  const overview_content = (
    <StyledWrapper>
      {renderPanel(userPanel, overviewCfg.user_details_card)}
      {renderPanel(<FuelUsagePanel />, overviewCfg.fuel_total_this_week_card)}
      {renderPanel(<VesselsPanel />, overviewCfg.vessels_card)}
      {renderPanel(<CustomAlarmsPanel />, overviewCfg.custom_alarms_panel)}
    </StyledWrapper>
  );
  return <Page title={"Overview"} content={overview_content} />;
}
