import { RealTimeSignal } from "../../context/types";
import { CartesianGrid, Label, Tooltip as RechartsTooltip, XAxis, YAxis, LineChart, Line, ReferenceLine } from "recharts"
import { graphPoint } from "../../context/variables";
import { realTimeSignalMap } from "../../context/types";
import { epochToPrettyStringRealTime } from "../../helpers/dateTimeHelpers";
import { fillGrayIfNoData, graphLoadOrNoDataNotice } from "../../helpers/graphHelpers";

export function convertGraphData(valTime: graphPoint) {
  const theTimestamp = Date.parse(valTime.timestamp)
  const theValue = valTime.value
  return {timestamp: theTimestamp, value: theValue}
}

export default function SignalGraph(graphData: {timestamp: number, value: number}[]|null, graphSignalType: RealTimeSignal | null, chosenTime: number, windowStartTime: number, windowEndTime: number) {
  const graphDataConverted = (graphData === null) ? undefined : graphData
  const dataCroppedToWindow = (graphDataConverted === undefined)
    ? undefined
    : graphDataConverted.filter(point=>{
      return windowStartTime <= point.timestamp && point.timestamp <= windowEndTime
    })
  const unitShort = (graphSignalType === null) ? "" : realTimeSignalMap[graphSignalType].unitShort
  const prettyName = (graphSignalType === null) ? "Value" : realTimeSignalMap[graphSignalType].prettyName
  const signalGraphComponent = <LineChart
    className="text-lg"
    width={1000}
    height={500}
    data={dataCroppedToWindow}
    margin={{
      top: 20,
      right: 70,
      bottom: 150,
      left: 70,
    }}
  >
    <CartesianGrid strokeDasharray="3 3" fill={fillGrayIfNoData(graphDataConverted)}/>
    <XAxis
      dataKey="timestamp"
      domain={["dataMin", "dataMax"]}
      type="number"
      angle={-30}
      textAnchor={"end"}
      tickCount={8}
      tickFormatter={(num) => {
        return (typeof num === 'number' && isFinite(num)) ? epochToPrettyStringRealTime(num) : ""
      }}
    />
    <YAxis unit={unitShort}>
      <Label
        value={prettyName}
        offset={50}
        position="left"
        angle={-90}
        style={{ textAnchor: 'middle' }}
      />
    </YAxis>
    <RechartsTooltip
      labelFormatter={(label) => {
        const tooltipLabelStr = (typeof label === 'number' && isFinite(label)) ? epochToPrettyStringRealTime(label) : ""
        return "Time: "+tooltipLabelStr
      }}
      formatter={(value: string, name, props) => {
        const valueRounded = Math.round(parseFloat(props.payload.value) * 1000)/1000
        return [valueRounded+" "+unitShort, prettyName]
      }}
    />
    <ReferenceLine x={chosenTime} stroke="#FF4D00" strokeWidth={2} isFront={false}/>
    <Line dataKey="value" stroke="#102840" type="monotone" isAnimationActive={false} dot={false} />
    {graphLoadOrNoDataNotice(graphDataConverted)}
  </LineChart>

  return signalGraphComponent
}
