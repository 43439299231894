
import { engineSpeedTorquePoint, useAppContext } from "../../context/variables"
import { CartesianGrid, Label, Scatter, ScatterChart, Tooltip as RechartsTooltip, XAxis, YAxis, ZAxis } from "recharts"
import { fillGrayIfNoData, graphLoadOrNoDataNotice } from "../../helpers/graphHelpers"

export default function EngineSpeedTorqueMapChart(props: {selectedEngine: string|null}) {
  const { data } = useAppContext()
  const allEnginesData = data.performance.engineSpeedTorqueMaps

  let selectedEngineData: engineSpeedTorquePoint[]|undefined
  if(allEnginesData === null) {
    selectedEngineData = undefined // Loading
  } else {
    const selectedEngineWithData = allEnginesData.find(data => data.engine === props.selectedEngine)
    if(selectedEngineWithData === undefined) {
      selectedEngineData = [] // No data
    } else {
      selectedEngineData = selectedEngineWithData.map.filter((p)=>{
        return p.timePercent > 0.1
      })
    }
  }

  return <ScatterChart
    width={500}
    height={500}
    margin={{
      top: 20,
      right: 20,
      bottom: 20,
      left: 30,
    }}
  >
    <CartesianGrid strokeDasharray="3 3" fill={fillGrayIfNoData(selectedEngineData)}/>
    <XAxis dataKey="engineSpeedRpm" type="number" name="engineSpeedRpm" >
      <Label
        value="Engine speed (rpm)"
        offset={0}
        position="bottom"
      />
    </XAxis>
    <YAxis dataKey="torquePercent" type="number" name="torquePercent" unit="%" >
      <Label
        value="Torque"
        offset={0}
        position="left"
        angle={-90}
        style={{ textAnchor: 'middle' }}
      />
    </YAxis>
    <ZAxis dataKey="timeHours" type="number" scale={"linear"} range={[10, 400]} name="time" unit="" />
    <RechartsTooltip 
      cursor={{ strokeDasharray: '3 3' }} 
      formatter={(value: string, name, props) => {
        const engineSpeedRpm = Math.round(parseFloat(props.payload.engineSpeedRpm) * 1000)/1000
        const torquePercent = Math.round(parseFloat(props.payload.torquePercent) * 1000)/1000
        const timeHoursRounded = Math.round(parseFloat(props.payload.timeHours) * 1000)/1000
        const timePercentRounded = Math.round(parseFloat(props.payload.timePercent) * 1000)/1000
        if(props.dataKey === "engineSpeedRpm") {
          return [engineSpeedRpm+" rpm", "Engine speed"]
        } else if(props.dataKey === "torquePercent") {
          return [torquePercent+" ", "Torque"]
        } else if(props.dataKey === "timeHours") {
          return [timeHoursRounded+" h ("+timePercentRounded+" %)", "Time"]
        } else {
          return [value, name]
        }
      }}
    />
    <Scatter data={selectedEngineData} fill={"#102840"} />
    {graphLoadOrNoDataNotice(selectedEngineData)}
  </ScatterChart>
}
