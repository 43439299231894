import React from 'react';

interface AlarmIconProps {
    size?: number;
    color?: string;
}

const AlarmIcon: React.FC<AlarmIconProps> = ({ size = 42, color = '#FF2A2A' }) => (
    <svg width={size} height={(size * 45) / 42} viewBox="0 0 42 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_35_1812)">
            <path fillRule="evenodd" clipRule="evenodd" d="M37.6668 20.0002C37.6668 29.2049 30.2049 36.6668 21.0002 36.6668C11.7954 36.6668 4.3335 29.2049 4.3335 20.0002C4.3335 10.7954 11.7954 3.3335 21.0002 3.3335C30.2049 3.3335 37.6668 10.7954 37.6668 20.0002ZM21.0002 29.9425L15.4446 24.2412H11.0002V17.3997H15.4446L21.0002 11.6985V29.9425ZM23.2224 16.2253C24.8668 17.069 26.0002 18.8022 26.0002 20.8205C26.0002 22.8387 24.8668 24.5719 23.2224 25.4043V16.2253ZM28.7779 20.8205C28.7779 17.2059 26.4335 14.15 23.2224 13.1694V10.8205C27.6779 11.8581 31.0002 15.9402 31.0002 20.8205C31.0002 25.7007 27.6779 29.7828 23.2224 30.8205V28.4716C26.4335 27.4909 28.7779 24.4351 28.7779 20.8205Z" fill={color} />
            <path d="M15.4446 24.2412L21.0002 29.9425V11.6985L15.4446 17.3997H11.0002V24.2412H15.4446Z" fill="black" fillOpacity="0.9" />
            <path d="M26.0002 20.8205C26.0002 18.8022 24.8668 17.069 23.2224 16.2253V25.4043C24.8668 24.5719 26.0002 22.8387 26.0002 20.8205Z" fill="black" fillOpacity="0.9" />
            <path d="M23.2224 13.1694C26.4335 14.15 28.7779 17.2059 28.7779 20.8205C28.7779 24.4351 26.4335 27.4909 23.2224 28.4716V30.8205C27.6779 29.7828 31.0002 25.7007 31.0002 20.8205C31.0002 15.9402 27.6779 11.8581 23.2224 10.8205V13.1694Z" fill="black" fillOpacity="0.9" />
        </g>
    </svg>
);

export default AlarmIcon;
