export function getTextFieldValue(elementId: string): string | null {
  const elem = document.getElementById(elementId) as HTMLInputElement;
  return elem === null ? null : elem.value;
}

export function isTextFieldValueEmpty(value: string|null) {
  return (value === null || value.length < 1)
}

export function getTextFieldContentsPretty(elementId: string): string {
  const element = document.getElementById(elementId) as HTMLInputElement
  return (element !== null) ? element.value : "N/A"
}
