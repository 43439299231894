import React, { useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import useFetchData from "../../../apiComms/fetchData";
import useManageData from "../../../apiComms/manageData";
import { useAppContext } from "../../../context/variables";
import LoadingSpinner from "../../../components/atoms/LoadingSpinner";

export const DeleteVesselGroupsModal: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  groupId: string | null;
  orgId: string | null;
  onCloseModal: () => void;
}> = ({ open, setOpen, groupId, orgId, onCloseModal }) => {
  const { data } = useAppContext();
  const { fetchDataVesselGroups } = useFetchData();
  const { apiDeleteSingleVesselGroup } = useManageData();

  const [isLoading, setIsLoading] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  async function onConfirmClick() {
    setIsLoading(true);
    try {
      if (orgId && groupId) {
        await apiDeleteSingleVesselGroup(orgId, groupId);
        await fetchDataVesselGroups();
        setIsLoading(false);
        setConfirmationModalOpen(true);
        onCloseModal();
        setOpen(false);
      } else {
        throw new Error("Missing organization ID or group ID for deletion");
      }
    } catch (error) {
      console.error("Delete failed", error);
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {open && (
        <div
          id="popup-modal"
          className={`fixed inset-0 flex items-center justify-center z-50 p-4 overflow-x-hidden overflow-y-auto ${
            open ? "block" : "hidden"
          }`}
        >
          <div className="relative w-full max-w-md">
            <div className="relative bg-white rounded-lg shadow">
              <div className="p-6 text-center">
                <svg
                  className="mx-auto mb-4 text-gray-400 w-12 h-12"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                <h3 className="mb-5 text-lg font-normal text-gray-500">
                  Are you sure you want to permanently delete the group?
                </h3>

                <button
                  onClick={onConfirmClick}
                  className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                >
                  Confirm
                </button>
                <button
                  onClick={() => setOpen(false)}
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ConfirmationModal
        confirmationModalOpen={confirmationModalOpen}
        setConfirmationModalOpen={setConfirmationModalOpen}
      />
    </>
  );
};

const ConfirmationModal: React.FC<{
  confirmationModalOpen: boolean;
  setConfirmationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ confirmationModalOpen, setConfirmationModalOpen }) => {
  return (
    <div
      id="popup-modal"
      className={`fixed inset-0 flex items-center justify-center z-50 p-4 overflow-x-hidden overflow-y-auto ${
        confirmationModalOpen ? "block" : "hidden"
      }`}
    >
      <div className="relative w-full max-w-md">
        <div className="relative bg-white rounded-lg shadow">
          <button
            onClick={() => setConfirmationModalOpen(false)}
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-2"
          >
            <span className="sr-only">Close modal</span>
          </button>
          <div className="p-6 text-center">
            <AiOutlineCheckCircle className="mx-auto mb-4 text-green-500 w-12 h-12" />
            <h3 className="mb-5 text-lg font-normal text-gray-500">
              Group deleted successfully!
            </h3>
            <button
              onClick={() => setConfirmationModalOpen(false)}
              className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm px-5 py-2.5 hover:text-gray-900"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteVesselGroupsModal;
