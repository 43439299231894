import { styled } from "styled-components";

export const StyledDeleteButton = styled.button<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  margin-bottom: 0.625rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
`;

export const StyledDeleteButtonIcon = styled.div`
  margin-right: 0.5rem;
`;

export const StyledDeleteButtonText = styled.p<{ color: string }>`
  margin: 0;
  padding: 0;
  color: ${(props) => props.color};
`;
