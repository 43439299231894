import { EnvironmentInfo } from "./types";
import { productionSfvConfig } from "./configs/production-sfv";
import { masterConfig } from "./configs/master";
import { productionConfig } from "./configs/production";
import { variables } from "../context/variables";
import { NavigateFunction } from "react-router-dom";

let awsBranch = "";
let gitBranch = "";
let gitCommitHash = "";
try {
  const temp = require("../generatedEnvInfo.json");
  ({ awsBranch, gitBranch, gitCommitHash } = temp);
} catch (error) {
  console.warn("generatedEnvInfo.json not found. Using default values.");
}

const currentEnvironmentInfo = new EnvironmentInfo(
  awsBranch,
  gitBranch,
  gitCommitHash
);
console.log(currentEnvironmentInfo);

export interface authenticationConfig {
  apiUrl: string;
  loginPageFields: boolean;
  forgotPwLink: string;
  initFunction: (vars: variables, navigate: NavigateFunction) => void;
  loginFunction: (
    vars: variables,
    navigate: NavigateFunction,
    theUsername: string,
    thePassword: string
  ) => void;
  logoutFunction: () => void;
  getApiAuthorization: (vars: variables) => string | null;
}

export interface featureConfig {
  overview: {
    fuel_total_this_week_card: boolean;
    vessels_card: boolean;
    user_details_card: boolean;
    custom_alarms_panel: boolean;
  };
  realtime: boolean;
  energy:
    | false
    | {
        overall: boolean;
        operators: boolean;
      };
  performance: boolean;
  reports: boolean;
  logbook:
    | false
    | {
        tags: boolean;
        trips: boolean;
      };
  administration:
    | false
    | {
        destinations: boolean;
        users: boolean;
        geozones: boolean;
        operation_modes: boolean;
        vessel_groups: boolean;
        tag_types: boolean;
        voyage_trips: boolean;
      };
  customAlarms:
    | false
    | {
        customized: boolean;
        history: boolean;
      };
}

export interface envConfig {
  auth: authenticationConfig;
  features: featureConfig;
}

export function pageVisible(pageConfig: boolean | Object): boolean {
  if (typeof pageConfig === "boolean") return pageConfig;
  return true;
}

export const environmentConfig =
  currentEnvironmentInfo.branch === "production"
    ? productionConfig
    : currentEnvironmentInfo.branch === "production-sfv"
    ? productionSfvConfig
    : masterConfig;

// console.log("Found the env config:", environmentConfig)
