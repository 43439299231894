import { useEffect, useState } from "react";
import {
  PerformanceGraph,
  Timescale,
  performanceGraphPretty,
} from "../../context/types";
import { useAppContext } from "../../context/variables";
import useFetchData from "../../apiComms/fetchData";
import {
  findTimeBounds,
  findTimePeriod,
  getWeek,
  dateInPrevPeriod,
} from "../../helpers/dateTimeHelpers";
import Page from "../Page";
import { TimePeriodPicker } from "../../components/timeInputs";
import {
  StandardDropdown,
  inputSetting,
  inputTray,
  makeVesselPicker,
} from "../../components/variousInputs";
import EngineSpeedTorqueMapChart from "./EngineSpeedTorqueMapChart";
import SogDistChart from "./SogDistChart";
import EngineDieselRateSogMapChart from "./EngineDieselRateSogMapChart";
import EnginePowerSogMapChart from "./EnginePowerSogMapChart";
import EmptyState from "../../components/atoms/EmptyState";
import { CenteredContainer } from "../../components/styles/EmptyStateStyles";
import DocumentIcon from "../../assets/icons/EmptyStates/DocumentIcon";

interface PerformanceGraphUnavailableProps {
  graph: PerformanceGraph;
}

const PerformanceGraphUnavailable = ({ graph }: PerformanceGraphUnavailableProps) => {
  let title, description;

  switch (graph) {
    case PerformanceGraph.SogDistChart:
      title = "Speed over ground is not available";
      break;
    case PerformanceGraph.EngineSpeedTorqueMapChart:
      title = "Torque-engine speed map is not available";
      break;
    case PerformanceGraph.EngineDieselRateSogMapChart:
      title = "Diesel usage-speed map is not available";
      break;
    case PerformanceGraph.EnginePowerSogMapChart:
      title = "Electricity usage-speed map is not available";
      break;
    default:
      title = "Graph is not available";
      break;
  }

  description = "Choose another timeframe to get data";

  return (
    <CenteredContainer>
      <EmptyState
        icon={<DocumentIcon />}
        title={title}
        description={description}
      />
    </CenteredContainer>
  );
};

export default function PerformancePage() {
  const { vars, data } = useAppContext();
  const { fetchDataPerformancePage, fetchDataOrganisations } = useFetchData();

  useEffect(() => {
    fetchDataOrganisations();
  }, [vars.auth.idToken, vars.auth.organisationId]);

  const [vessel, setVessel] = useState<string | null>(null);
  const vesselOptions = data.org.vesselList;
  const vesselDropdown = makeVesselPicker(vessel, vesselOptions, setVessel);
  const vesselPicker = inputSetting("Vessel", vesselDropdown, 240);

  useEffect(() => {
    if (data.org.vesselList.length > 0) {
      setVessel(data.org.vesselList[0].id);
    }
  }, [data.org.vesselList]);

  const [graph, setGraph] = useState(PerformanceGraph.SogDistChart);
  const graphOptions = Object.values(PerformanceGraph).map((graph) => {
    return { name: performanceGraphPretty[graph], value: graph };
  });
  const graphDropdown = StandardDropdown("Graph", graph, graphOptions, setGraph);

  const [engine, setEngine] = useState<string | null>(null);
  const engineKeys =
    data.performance.engineSpeedTorqueMaps === null
      ? []
      : data.performance.engineSpeedTorqueMaps.map((map) => map.engine);
  const engineOptions = engineKeys.map((key) => {
    return { name: key, value: key };
  });
  if (engine === null && engineKeys.length > 0) {
    setEngine(engineKeys[0]);
  }
  if (engine !== null && engineKeys.length > 0 && !engineKeys.includes(engine)) {
    setEngine(engineKeys[0]);
  }
  if (
    engine !== null &&
    engineKeys.length === 0 &&
    data.performance.sogDistribution !== null
  ) {
    setEngine(null);
  }
  const engineDropdownMaybe =
    graph === PerformanceGraph.EngineSpeedTorqueMapChart
      ? StandardDropdown("Engine", engine, engineOptions, setEngine, 300)
      : <></>;

  const [timescale, setTimescale] = useState<Timescale>(Timescale.Week);
  const timescaleOptions = [
    { value: Timescale.Month, name: "Month" },
    { value: Timescale.Week, name: "Week" },
  ];
  function timescaleChanged(timescale: Timescale) {
    setTimescale(timescale);
    if (fromTime !== null) timePeriodChanged(fromTime, timescale);
  }
  const timescaleDropdown = StandardDropdown(
    "Timescale",
    timescale,
    timescaleOptions,
    timescaleChanged,
    120
  );

  const inLastWeek: Date = dateInPrevPeriod(findTimeBounds(new Date(), Timescale.Week).fromTime);
  const { fromTime: defaultFromTime } = findTimeBounds(inLastWeek, Timescale.Week);
  const [fromTime, setFromTime] = useState<Date | null>(defaultFromTime);
  const [timePeriod, setTimePeriod] = useState<string>(getWeek(inLastWeek));
  function timePeriodChanged(date: Date, timescale: Timescale) {
    const timePeriod = findTimePeriod(date, timescale);
    setTimePeriod(timePeriod);
    const { fromTime } = findTimeBounds(date, timescale);
    setFromTime(fromTime);
  }
  const timePeriodPickerComp = TimePeriodPicker(timescale, fromTime, timePeriodChanged);

  useEffect(() => {
    data.performance.setEngineSpeedTorqueMaps(null);
    data.performance.setSogDistribution(null);
    data.performance.setTotalEngineDieselRateSogMap(null);
    data.performance.setTotalEnginePowerSogMap(null);
    fetchDataPerformancePage(vessel, fromTime, timescale);
  }, [vars.auth.idToken, vessel, fromTime, timescale]);

  const inputs = [
    [vesselPicker, timescaleDropdown, timePeriodPickerComp],
    [graphDropdown, engineDropdownMaybe],
  ];

  const graphComponents = {
    [PerformanceGraph.EngineSpeedTorqueMapChart]: (
      data.performance.engineSpeedTorqueMaps && data.performance.engineSpeedTorqueMaps.length > 0 
        ? <EngineSpeedTorqueMapChart selectedEngine={engine} /> 
        : null
    ),
    [PerformanceGraph.SogDistChart]: (
      data.performance.sogDistribution && data.performance.sogDistribution.length > 0 
        ? <SogDistChart /> 
        : null
    ),
    [PerformanceGraph.EngineDieselRateSogMapChart]: (
      data.performance.totalEngineDieselRateSogMap && data.performance.totalEngineDieselRateSogMap.length > 0 
        ? <EngineDieselRateSogMapChart /> 
        : null
    ),
    [PerformanceGraph.EnginePowerSogMapChart]: (
      data.performance.totalEnginePowerSogMap && data.performance.totalEnginePowerSogMap.length > 0 
        ? <EnginePowerSogMapChart /> 
        : null
    ),
  };

  const isEmptyState = !graphComponents[graph];

  const performance_content = (
    <div className="flex flex-col space-y-8 pt-4 text-deep_blue">
      {inputTray(inputs)}
      {isEmptyState ? (
        <PerformanceGraphUnavailable graph={graph} />
      ) : (
        <div className="flex flex-col items-center justify-center space-y-8">
          <h2 className="text-xl font-bold">
            {performanceGraphPretty[graph]}
          </h2>
          {graphComponents[graph]}
        </div>
      )}
    </div>
  );

  return <Page title={"Vessel performance"} content={performance_content} />;
}
