export class EnvironmentInfo {
    awsBranch: string;
    gitBranch: string;
    gitCommit: string;

    constructor(awsBranch: string, gitBranch: string, gitCommit: string) {
        this.awsBranch = awsBranch.toLowerCase();
        this.gitBranch = gitBranch.toLowerCase();
        this.gitCommit = gitCommit.toLowerCase();
    }

    get branch(): string {
        return this.awsBranch || this.gitBranch;
    }
}
