import React from 'react';
import { StyledInput } from '../styles/InputStyles';

interface InputProps {
  id: string;
  type: string;
  name: string;
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  width?: string;
  disabled?: boolean;
}

const Input: React.FC<InputProps> = ({ id, type, name, placeholder, value, onChange, width, disabled }) => {
  return (
    <StyledInput
      id={id}
      type={type}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      width={width}
      disabled={disabled}
    />
  );
};

export default Input;
