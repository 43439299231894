import styled from 'styled-components';

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
`;

export const TooltipText = styled.div`
  visibility: hidden;
  width: auto;
  max-width: 200px;
  background-color: #f6f6f6;
  color: #143452;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1000;
  left: 100%; 
  margin-left: 10px; 
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent #f6f6f6 transparent transparent;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 999; 
  overflow: visible;
`;
