import { ReferenceLine } from "recharts"

export function fillGrayIfNoData<T>(data: T[]|undefined) {
  if(data === undefined || data.length < 1) return "#BBBBBB"
  else return "#FFFFFF"
}

const loadingIcon = 
  <text className="font-bold text-3xl fill-gray-500 relative animate-pulse"
    x='50%'
    y='50%'
    dx={+40}
    textAnchor='middle'
  >
    {"Loading..."}
  </text>

const noDataNotice = 
  <text className="font-bold text-3xl fill-gray-500"
    x='50%'
    y='50%'
    dx={+40}
    textAnchor='middle'
  >
    {"No data available"}
  </text>

export function graphLoadOrNoDataNotice<T>(data: T[]|undefined): JSX.Element {
  if(data === undefined) { // Loading
    return loadingIcon
  } else if(data.length < 1) { // No data
    return noDataNotice
  } else { // Data
    return <></>
  }
}

export function graphVerticalNowLine(x: number, visible: boolean): JSX.Element {
  if(visible) return <ReferenceLine 
    x={x}
    label={{
      value: "Now",
      position: "top",
      fill: "#FF4D00",
      style: {
        textAnchor: 'middle',
        fontWeight: 600
      }}}
    stroke="#FF4D00"
    strokeWidth={1}
    isFront={false}
  />
  else return <></>
}
