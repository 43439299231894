import { Loader } from "@googlemaps/js-api-loader";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

export interface coords {
  lat: number;
  lng: number;
}

export interface coordinates {
  latitude: number;
  longitude: number;
}

export function makeLatLng(latitude: number, longitude: number): coords {
  return { lat: latitude, lng: longitude };
}

// const equatorZero = makeLatLng(0, 0)
export const coordsGothenburgKindaCentral = makeLatLng(
  57.70761821963519,
  11.945880206552513
);
// const cindyCentral = makeLatLng(59.385, 18.536)
// const uluru = makeLatLng(-25.344, 131.031)
// const sydney = makeLatLng(-33.867, 151.195)
// export const coordsTokyo = makeLatLng(35.69196927888576, 139.76805040828435)
// const between = makeLatLng(-30, 140)
// const test = makeLatLng(40.70466149037387, -74.01151938598817)
export const coordsLindholmspiren = makeLatLng(
  57.70555699445394,
  11.939930318195918
);

export function latToPrettyString(lat: number) {
  const cardinalDirection = lat < 0 ? "S" : "N";
  const absLat = Math.abs(lat);
  const roundedLat = Math.round(absLat * 1e6) / 1e6;
  const prettyString = roundedLat + "° " + cardinalDirection;
  return prettyString;
}

export function lngToPrettyString(lng: number) {
  const cardinalDirection = lng < 0 ? "W" : "E";
  const absLng = Math.abs(lng);
  const roundedLng = Math.round(absLng * 1e6) / 1e6;
  const prettyString = roundedLng + "° " + cardinalDirection;
  return prettyString;
}

function mapInitGeneric(mapElementId: string, center: coords, zoom: number) {
  // console.log("In mapInitGeneric")
  const mapCanvas = document.getElementById(mapElementId) as HTMLElement;
  // console.log("mapCanvas is:", mapCanvas)
  const mapOpts = {
    center: center,
    zoom: zoom,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    mapId: "d731787bb76c1393",
    clickableIcons: false,
    disableDefaultUI: true,
    fullscreenControl: true,
    zoomControl: true,
    scaleControl: true,
    // streetViewControl: false,
    // mapTypeControl: false,
    keyboardShortcuts: false,
  };
  // console.log("mapOpts is:", mapOpts)
  return new google.maps.Map(mapCanvas, mapOpts);
}

export function initializeMap(
  map: google.maps.Map | undefined,
  setMap: (map: google.maps.Map | undefined) => void,
  mapElementId: string,
  mapInitCustom: (map: google.maps.Map) => void,
  setMapInitialized: (init: boolean) => void,
  center: coords,
  zoom: number
) {
  // console.log("in initializeMap")
  const loader = new Loader({
    apiKey: "AIzaSyAFrWFda9joNnyn20PXgFWACROHq8Hwhxk",
    version: "weekly",
    libraries: ["geometry"],
    // ...additionalOptions,
  });

  // const mapElement = document.getElementById(mapElementId) as HTMLElement
  // console.log("mapElement 2:", mapElement, mapElementId)
  // document.onload("")

  // await mapElement !== null

  // useEffect(()=>{
  //   console.log("useEffect for mapInitGeneric")
  // console.log("Before then:", document.getElementById(mapElementId) as HTMLElement)
  loader.load().then(() => {
    // console.log("After then:", document.getElementById(mapElementId) as HTMLElement)
    const mapElement = document.getElementById(mapElementId) as HTMLElement;
    if (mapElement !== null) {
      const mapObj = mapInitGeneric(mapElementId, center, zoom);
      setMap(mapObj);
      mapInitCustom(mapObj);
      setMapInitialized(true);
    }
  });
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [mapElement])

  // useEffect(()=>{
  //   console.log("useEffect for mapInitCustom")
  //   if(map !== undefined) {
  //     mapInitCustom(map)
  //     // console.log("ran mapInitCustom")
  //     setMapInitialized(true)
  //     // console.log("set mapinit to true")
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [map])
}

export const mapIconRedballoon = () => {
  return {
    path: faMapMarkerAlt.icon[4] as string,
    fillColor: "#ff4d00",
    fillOpacity: 1,
    anchor: new google.maps.Point(
      faMapMarkerAlt.icon[0] / 2, // width
      faMapMarkerAlt.icon[1] // height
    ),
    strokeWeight: 1,
    strokeColor: "#000000",
    scale: 0.075,
  };
};
