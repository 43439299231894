import { useNavigate } from "react-router-dom";
import { PanelStatistic } from "../../../../components/molecules";
import { useAppContext } from "../../../../context/variables";
import { useQuery } from "@tanstack/react-query";
import { getOverviewTotalWeek } from "../../../../api/services";
import { useEffect } from "react";
import { FuelUsageTotalWeek, Maybe } from "../../../../types";

const FuelUsagePanel = () => {
  const { view, data, vars } = useAppContext();
  const navigate = useNavigate();
  const {
    data: fuelUsage,
    error,
    isLoading,
    refetch,
    isFetching,
  } = useQuery<Maybe<FuelUsageTotalWeek>, Error>({
    queryKey: ["fuelUsage"],
    queryFn: () => getOverviewTotalWeek(view, data, vars, navigate),
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.idToken, vars.auth.organisationId]);

  return (
    <PanelStatistic
      title="Fuel used this week"
      summary="Total amount of fuel used by your vessels this week, in liters."
      value={fuelUsage}
      unit="L"
      isEmpty={!fuelUsage}
      error={error}
      isLoading={isLoading || isFetching}
    />
  );
};

export default FuelUsagePanel;
