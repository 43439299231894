import { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import MenuBar from '../components/MenuBar';
import { useAppContext } from '../context/variables';
import { authenticationExpirationCheck } from '../auth/authentication';

export default function PageScreen() {
  const { vars, data, view } = useAppContext()
  let navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    authenticationExpirationCheck(vars, data, view, navigate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.exp, location.pathname])

  if (vars.login.loggedIn === false) {
    return (
      <Navigate to="/login"/>
    )
  } else {
    return (
      <div className="flex flex-row h-screen w-screen">
        <MenuBar/>
        <Outlet/>
      </div>
    );
  }
}
