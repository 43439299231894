import { CognitoUserPool } from "amazon-cognito-identity-js";
import { envConfig } from "../env";
import { variables } from "../../context/variables";
import { NavigateFunction } from "react-router-dom";
import { cognitoLogin, cognitoLogout } from "../../auth/cognito";

// -- AWS development environment/default config

const awsDevelopmentCognitoUserPool = new CognitoUserPool({
  UserPoolId: "eu-west-1_oY1gj0vtZ",
  ClientId: "73gsc3pth63gm02df2f3a3grov",
});

export const masterConfig: envConfig = {
  auth: {
    apiUrl: "https://api.test.cetasol.com/",
    loginPageFields: true,
    forgotPwLink:
      "https://cetasol-v2-test.auth.eu-west-1.amazoncognito.com/forgotPassword?client_id=73gsc3pth63gm02df2f3a3grov&response_type=code&redirect_uri=https%3A%2F%2Fmaster.dt95s3zvahsgd.amplifyapp.com%2F",
    initFunction: () => {},
    loginFunction: (
      vars: variables,
      navigate: NavigateFunction,
      theUsername: string,
      thePassword: string
    ) => {
      cognitoLogin(
        vars,
        navigate,
        theUsername,
        thePassword,
        awsDevelopmentCognitoUserPool
      );
    },
    logoutFunction: () => cognitoLogout(awsDevelopmentCognitoUserPool),
    getApiAuthorization: (vars) => vars.auth.idToken,
  },
  features: {
    overview: {
      fuel_total_this_week_card: true,
      vessels_card: true,
      user_details_card: true,
      custom_alarms_panel: true,
    },
    realtime: true,
    energy: {
      overall: true,
      operators: true,
    },
    performance: true,
    reports: true,
    logbook: {
      trips: true,
      tags: true,
    },
    administration: {
      destinations: true,
      geozones: true,
      users: true,
      operation_modes: true,
      vessel_groups: true,
      tag_types: true,
      voyage_trips: true,
    },
    customAlarms: {
      customized: true,
      history: true,
    },
  },
};
