import React, { useEffect } from "react";
import { Navigate, NavigateFunction, useNavigate } from "react-router-dom";
import boatpic from "../assets/images/landingImage.png";
import { Button } from "../components/Button";
import { TextEntryTitleWarningText } from "../components/TextEntry";
import { useAppContext, variables } from "../context/variables";
import { CetasolLogoSvg, NewCetasolLogoPng } from "../components/Cetasol_logo";
import {
  authenticationForgotPwLink,
  authenticationInit,
  authenticationLogin,
  authenticationLoginPageFields,
  authenticationExpirationCheck,
} from "../auth/authentication";

function loginButtonPress(vars: variables, navigate: NavigateFunction): void {
  // console.log("Login button pressed")
  const theUsername = authenticationLoginPageFields
    ? (document.getElementById("username") as HTMLInputElement).value
    : "";
  const thePassword = authenticationLoginPageFields
    ? (document.getElementById("pw") as HTMLInputElement).value
    : "";
  authenticationLogin(vars, navigate, theUsername, thePassword);
}

export default function LoginPage() {
  const { vars, data, view } = useAppContext();
  let navigate = useNavigate();

  useEffect(() => {
    authenticationInit(vars, navigate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    authenticationExpirationCheck(vars, data, view, navigate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.exp]);

  // if(window.location.hash) {
  //   console.log("Fragment exists: '", window.location.hash, "'")
  // } else {
  //   console.log("Fragment doesn't exist")
  // }

  const submitLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    loginButtonPress(vars, navigate);
  };
  let usernameWarningStatus: boolean;
  let passwordWarningStatus: boolean;
  let usernameWarningText: string;
  let passwordWarningText: string;
  // console.log("errmess:", vars.login.errorMessage)
  if (vars.login.errorMessage === "Missing required parameter USERNAME") {
    // Please enter an email address (red around email address?)
    usernameWarningStatus = true;
    passwordWarningStatus = false;
    usernameWarningText = "Please enter a username/email";
    passwordWarningText = "";
  } else if (
    vars.login.errorMessage === "User does not exist." ||
    vars.login.errorMessage === "Incorrect username or password."
  ) {
    // Email address not registered (red around email address?) OR Incorrect username or password. (Red around both?)
    usernameWarningStatus = true;
    passwordWarningStatus = true;
    usernameWarningText = "";
    passwordWarningText = "Incorrect username/email or password";
    // } else if (vars.login.errorMessage === "User does not exist.") { // Email address not registered (red around email address?) OR Incorrect username or password. (Red around both?)
    //   usernameWarningStatus = true
    //   passwordWarningStatus = false
    //   usernameWarningText = "Email address not recognized"
    //   passwordWarningText = ""
    // } else if (vars.login.errorMessage === "Incorrect username or password.") { // Incorrect password (red around password?) OR Incorrect username or password. (Red around both?)
    //   usernameWarningStatus = false
    //   passwordWarningStatus = true
    //   usernameWarningText = ""
    //   passwordWarningText = "Incorrect password"
  } else if (vars.login.errorMessage === "Password attempts exceeded") {
    // Password attempts exceeded, please wait (red around password?)
    usernameWarningStatus = false;
    passwordWarningStatus = true;
    usernameWarningText = "";
    passwordWarningText = "Password attempts exceeded, please wait";
  } else {
    usernameWarningStatus = false;
    passwordWarningStatus = false;
    usernameWarningText = "";
    passwordWarningText = "";
  }
  // console.log("u w:", usernameWarningStatus)
  // console.log("p w:", passwordWarningStatus)

  const authenticationDetailsEntryMaybe = authenticationLoginPageFields ? (
    <div className="text-2xl space-y-6">
      <label htmlFor="username" className="sr-only">
        Username/Email
      </label>
      <TextEntryTitleWarningText
        title="Username/Email"
        prompt="jane@example.com"
        name="username"
        type="text"
        warningStatus={usernameWarningStatus}
        warningText={usernameWarningText}
      />

      <label htmlFor="pw" className="sr-only">
        Password
      </label>
      <TextEntryTitleWarningText
        title="Password"
        prompt=""
        name="pw"
        type="password"
        warningStatus={passwordWarningStatus}
        warningText={passwordWarningText}
      />
      <div>
        <a
          href={authenticationForgotPwLink}
          className="font-normal italic underline mt-6"
        >
          {"Forgot your password?"}
        </a>
      </div>
    </div>
  ) : (
    <></>
  );

  if (vars.login.loggedIn === true) {
    return <Navigate to="/overview" />;
  } else {
    return (
      <div className="flex flex-col md:flex-row w-screen h-screen bg-pale_blue items-center justify-center bg-right">
        <img
          src={boatpic}
          className="border-0 bg-black h-full md:w-1/2 w-full opacity-100 object-cover object-right"
          alt="boat sailing on ocean"
        />

        <div className="absolute top-0 left-0 pt-5 pl-5 h-[106px] w-full flex justify-start">
          {NewCetasolLogoPng("#FFFFFF", 86)}
        </div>
        {/* Accesibility feature, this is used to inform users without JavaScript that the application requires it for proper functionality. */}
        <noscript>
          <div className="text-center p-4">
            <p>
              This application requires JavaScript to function properly. Please
              enable JavaScript in your web browser settings.
            </p>
          </div>
        </noscript>
        <h1 className="sr-only">Login to iHelm</h1>
        <form
          onSubmit={submitLogin}
          className="text-left text-4xl w-1/2 flex flex-col justify-center space-y-12 font-bold text-deep_blue pl-[100px] pr-[100px]"
        >
          <div>
            <div className="text-5xl">{"Welcome to iHelm."}</div>
            <div className="text-2xl">
              {"An innovation by "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.cetasol.com"
                className="underline"
              >
                {"Cetasol"}
              </a>
              {"."}
            </div>
          </div>
          {authenticationDetailsEntryMaybe}
          <div className="font-bold">
            <Button text={"LOG IN"} action={() => {}} />
          </div>
        </form>
      </div>
    );
  }
}
