import { Modal, Button } from "../../atoms";
import {
  StyledActionsWrapper,
  StyledFailedTitle,
  StyledIconWrapper,
  StyledMessageWrapper,
} from "./ConfirmActionModal.styles";
import { MdOutlineErrorOutline, MdCheckCircleOutline } from "react-icons/md";
import { useEffect } from "react";
interface ConfirmActionModalProps {
  isOpen: boolean;
  isSuccessful: boolean;
  message: string;
  feedbackMessage?: {
    success: string;
    error: string;
  };
  isLoading: boolean;
  hasFeedback: boolean;
  onConfirm: () => void;
  closeModal: () => void;
  setIsSuccessful: (isSuccessful: boolean) => void;
  setHasFeedback: (hasFeedback: boolean) => void;
  theme?: "light" | "dark";
}

const renderIcon = (isSuccess: boolean) => {
  if (isSuccess) {
    return <MdCheckCircleOutline color="#22C55E" fontSize={48} />;
  } else {
    return <MdOutlineErrorOutline fontSize={48} />;
  }
};

const ConfirmActionModal: React.FC<ConfirmActionModalProps> = ({
  isOpen,
  isSuccessful,
  message,
  feedbackMessage,
  isLoading,
  hasFeedback,
  onConfirm,
  closeModal,
  setIsSuccessful,
  setHasFeedback,
  theme = "light",
}) => {
  const resetModal = () => {
    setHasFeedback(false);
    setIsSuccessful(false);
  };

  useEffect(() => {
    resetModal();
  }, []);

  const getMessage = () => {
    if (hasFeedback) {
      return isSuccessful ? feedbackMessage?.success : feedbackMessage?.error;
    }
    return message;
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} theme={theme}>
      <StyledIconWrapper>{renderIcon(isSuccessful)}</StyledIconWrapper>
      <StyledFailedTitle>
        {hasFeedback && !isSuccessful ? "Failed to perform action" : null}
      </StyledFailedTitle>

      <StyledMessageWrapper>{getMessage()}</StyledMessageWrapper>
      <StyledActionsWrapper isHidden={hasFeedback}>
        <Button
          backgroundColor="#fff"
          color="#143452"
          onClick={onConfirm}
          text={isLoading ? "Processing..." : "Confirm"}
          disabled={isLoading}
        />
        <Button
          backgroundColor="#DC2626"
          color="#fff"
          onClick={closeModal}
          text="Cancel"
          disabled={isLoading}
        />
      </StyledActionsWrapper>
    </Modal>
  );
};

export default ConfirmActionModal;
