import { useEffect, useState } from "react"
import Tab from "../../../tabs/Tab"
import useFetchData from "../../../apiComms/fetchData"
import useManageData from "../../../apiComms/manageData"
import { rootOrgsEntry, useAppContext } from "../../../context/variables"
import { genericTableEditable, genericTableRelatedBits } from "../../../components/Table"
import { addButton, makeSubmitButton } from "../../../components/Button"
import { makeConfirmModal, makeMenuModal, makeModal } from "../../../components/Modal"
import { makeTextInput } from "../../../components/FormInputs"
import { HiOutlineTrash } from "react-icons/hi"
import { TbCursorText } from "react-icons/tb"

function rootOrgsTable(orgs: rootOrgsEntry[]|null, setManagedOrgId: (id: string|null)=>void, setManageOrgModalOpen: (open: boolean)=>void): JSX.Element {
  const orgRows = (orgs === null || orgs === undefined) ? null : orgs.map((org)=>{
    return {id: org.uid, contents: [org.uid, org.name]}
  })
  function onEditRowClick(uid: string) {
    // console.log("Clicked", uid)
    setManagedOrgId(uid)
    setManageOrgModalOpen(true)
  }
  return genericTableEditable(["uid", "name"], orgRows, onEditRowClick)
}

function RenameOrgModal(renameOrgModalOpen: boolean, setRenameOrgModalOpen: (open: boolean)=>void, managedOrgId: string|null, onCloseManageOrgModal: ()=>void) {
  const [newOrgNameWarning, setNewOrgNameWarning] = useState<string|null>(null)
  const [confirmRenameOrgModalOpen, setConfirmRenameOrgModalOpen] = useState(false)
  const { data } = useAppContext()
  const { apiPutRootOrgsRename } = useManageData()
  
  function clearWarnings() {
    setNewOrgNameWarning(null)
  }
  function onClose() {
    setRenameOrgModalOpen(false)
    clearWarnings()
  }
  function onSubmitClick() {
    // console.log("Hit submit rename org")
    clearWarnings()
    const theNewOrgName = (document.getElementById("newOrgName") as HTMLInputElement).value
    if(theNewOrgName.length < 1) { // Not OK: org name bad
      // console.log("New org name not OK")
      setNewOrgNameWarning("Org name not allowed")
    } else { // OK
      setConfirmRenameOrgModalOpen(true)
    }
  }
  function onConfirmClick() {
    // console.log("Hit confirm rename org")
    const theNewOrgName = (document.getElementById("newOrgName") as HTMLInputElement).value
    // console.log("renamed the org (", managedOrgId, ") to: (", theNewOrgName, ")")
    apiPutRootOrgsRename(managedOrgId, theNewOrgName)
    setConfirmRenameOrgModalOpen(false)
    onClose()
    onCloseManageOrgModal()
  }

  const newOrgNameField = document.getElementById("newOrgName") as HTMLInputElement
  const theNewOrgName = (newOrgNameField !== null) ? newOrgNameField.value : "N/A"
  // console.log("newOrgName:", newOrgNameField, theNewOrgName)

  const oldOrg = data.rootAccess.orgs?.find((org)=>org.uid === managedOrgId)
  const oldOrgName = oldOrg?.name
  // console.log("oldOrg:", oldOrg, oldOrgName)

  const renameOrgContents = 
    <div className="flex flex-col space-y-4">
      {makeTextInput("New org name:", "The new display name of the organisation", " Starfleet Command", "newOrgName", newOrgNameWarning, oldOrgName)}
      {makeSubmitButton(onSubmitClick)}
      {makeConfirmModal("Confirm renaming org", 'You are about to rename the org '+managedOrgId+' from "'+oldOrgName+'" to "'+theNewOrgName+'"', "Confirm: Rename org", onConfirmClick, confirmRenameOrgModalOpen, setConfirmRenameOrgModalOpen)}
    </div>
  return makeModal("Rename org", renameOrgContents, renameOrgModalOpen, onClose, 600, 250)
}

function DeleteOrgModal(open: boolean, setOpen: (open: boolean)=>void, managedOrgId: string|null, onCloseManageOrgModal: ()=>void) {
  const { data } = useAppContext()
  const { apiDeleteRootOrgs } = useManageData()
  const theOrgId = (managedOrgId !== null) ? managedOrgId : "N/A"
  const org = data.rootAccess.orgs?.find((org)=>org.uid === managedOrgId)
  const orgName = org?.name
  function onConfirmClick() {
    // console.log("Hit confirm delete org")
    // console.log("deleted the org: (", theOrgId, ")")
    apiDeleteRootOrgs(theOrgId)
    setOpen(false)
    onCloseManageOrgModal()
  }
  return makeConfirmModal("Confirm deleting org", "You are about to delete the org "+theOrgId+" ("+orgName+")", "Delete org", onConfirmClick, open, setOpen, true)
}

function ManageOrgModal(open: boolean, setOpen: (open: boolean)=>void, managedOrgId: string|null, setManagedOrgId: (id: string|null)=>void) {
  const [renameOrgModalOpen, setRenameOrgModalOpen] = useState(false)
  const [deleteOrgModalOpen, setDeleteOrgModalOpen] = useState(false)

  const title = (managedOrgId !== null) ? "Manage org "+managedOrgId : "Manage org"
  const menuOptions = [
    {buttonIcon: <TbCursorText/>, buttonText: "Rename", onClick: ()=>setRenameOrgModalOpen(true)},
    {buttonIcon: <HiOutlineTrash/>, buttonText: "Delete", onClick: ()=>setDeleteOrgModalOpen(true), orangeBg: true},
  ]
  const modals = [
    RenameOrgModal(renameOrgModalOpen, setRenameOrgModalOpen, managedOrgId, onClose),
    DeleteOrgModal(deleteOrgModalOpen, setDeleteOrgModalOpen, managedOrgId, onClose),
  ]
  function onClose() {
    setManagedOrgId(null)
    setOpen(false)
  }
  return makeMenuModal(title, menuOptions, modals, open, onClose, 400, 400)
  // const [selectedOperation, setSelectedOperation] = useState<string|null>(null)
  // const fieldArea = (selectedOperation === "rename") ? <div>rename, name field, submit, confirm</div> 
  //   : (selectedOperation === "delete") ? <div>delete, submit?, confirm</div> 
  //   : <div>yo</div> 
  // const manageOrgContents =
  //   <div className="h-full flex space-x-4">
  //     <div className=" h-full p-4 flex flex-col space-y-4 justify-start items-start bg-medium_yellow">
  //       {iconButtonDeepBlue("Rename", <></>, ()=>setSelectedOperation("rename"))}
  //       {iconButtonDeepBlue("Delete", <></>, ()=>setSelectedOperation("delete"))}
  //     </div>
  //     <div>
  //       {fieldArea}
  //     </div>
  //   </div>
  // return makeModal("Manage org", manageOrgContents, manageOrgModalOpen, onClose, 600, 600)
}

function AddOrgModal(addOrgModalOpen: boolean, setAddOrgModalOpen: (open: boolean)=>void) {
  const [orgNameWarning, setOrgNameWarning] = useState<string|null>(null)
  const [confirmAddOrgModalOpen, setConfirmAddOrgModalOpen] = useState(false)
  const { apiPostRootOrgsAdd } = useManageData()
  
  function clearWarnings() {
    setOrgNameWarning(null)
  }
  function onClose() {
    setAddOrgModalOpen(false)
    clearWarnings()
  }
  function onSubmitClick() {
    // console.log("Hit submit add org")
    clearWarnings()
    const theOrgName = (document.getElementById("orgName") as HTMLInputElement).value
    if(theOrgName.length < 1) { // Not OK: org name bad
      // console.log("Org name not OK")
      setOrgNameWarning("Org name not allowed")
    } else { // OK
      setConfirmAddOrgModalOpen(true)
    }
  }
  function onConfirmClick() {
    // console.log("Hit confirm add org")
    const theOrgName = (document.getElementById("orgName") as HTMLInputElement).value
    // console.log("added the org: (", theOrgName, ")")
    apiPostRootOrgsAdd(theOrgName)
    setConfirmAddOrgModalOpen(false)
    onClose()
  }

  const orgNameField = document.getElementById("orgName") as HTMLInputElement
  const theOrgName = (orgNameField !== null) ? orgNameField.value : "N/A"
  // console.log("orgName:", orgNameField, theOrgName)

  const addOrgContents = 
    <div className="flex flex-col space-y-4">
      {makeTextInput("Org name:", "The display name of the organisation to be created", " Starfleet Academy", "orgName", orgNameWarning)}
      {/* {makeTextInput("number two", "tippy", "HERE", "lol", true, "warning!")} */}
      {makeSubmitButton(onSubmitClick)}
      {makeConfirmModal("Confirm adding org", 'You are about to add the org: "'+theOrgName+'"', "Confirm: Add org", onConfirmClick, confirmAddOrgModalOpen, setConfirmAddOrgModalOpen)}
    </div>
  return makeModal("Add new org", addOrgContents, addOrgModalOpen, onClose, 550, 400)
}

export default function RootOrganisationsTab() {
  const { data } = useAppContext()
  const { fetchDataRootOrgs } = useFetchData()
  useEffect(() => {
    // console.log("running root orgs effect")
    fetchDataRootOrgs()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [addOrgModalOpen, setAddOrgModalOpen] = useState(false)
  const [manageOrgModalOpen, setManageOrgModalOpen] = useState(false)
  const [managedOrgId, setManagedOrgId] = useState<string|null>(null)
  const root_organisations_content = 
    <div className="flex flex-col space-y-4 pt-4 text-deep_blue">
      {addButton("Add new org", ()=>setAddOrgModalOpen(true))}
      {genericTableRelatedBits("Organisations", data.rootAccess.orgs?.length, fetchDataRootOrgs)}
      {rootOrgsTable(data.rootAccess.orgs, setManagedOrgId, setManageOrgModalOpen)}
      {AddOrgModal(addOrgModalOpen, setAddOrgModalOpen)}
      {ManageOrgModal(manageOrgModalOpen, setManageOrgModalOpen, managedOrgId, setManagedOrgId)}
    </div>
  return <Tab title="Organisations" content={root_organisations_content}/>
}
