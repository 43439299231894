import { AxiosError } from "axios";

export const getErrorDetails = (error: any): Error => {
  if (error.isAxiosError) {
    // This confirms it's an AxiosError, enabling type-safe access to specific properties
    const axiosError = error as AxiosError;

    if (axiosError.response) {
      // The server responded with an error status code
      switch (axiosError.response.status) {
        case 404:
          throw new Error("The resource you requested could not be found.");
        case 401:
          throw new Error(
            "You are not authorized. Please log in and try again."
          );
        case 500:
          throw new Error(
            "Our server is currently experiencing problems. Please try again later."
          );
        default:
          throw new Error(
            `A server error occurred: ${axiosError.response.status}. Please try again later.`
          );
      }
    } else if (axiosError.request) {
      // The request was made but no response was received

      throw new Error(
        `We couldn't connect to the server. Please check your internet connection and try again.`
      );
    } else {
      // Something happened in setting up the request
      throw new Error(
        "An error occurred while setting up the request. Please try again."
      );
    }
  } else {
    // Handle non-Axios errors if necessary
    throw new Error("An unexpected error occurred. Please try again.");
  }
};
