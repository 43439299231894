import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(243, 249, 253, var(--tw-bg-opacity));
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
`;

const Content = styled.div`
padding: 47px 57px 50px 57px;
  width: 100%;
  height: 100%;
`;

interface PageContainerProps {
  children: React.ReactNode;
}

const PageContainer: React.FC<PageContainerProps> = ({ children }) => {
  return (
    <Container>
      <Content>
        {children}
      </Content>
    </Container>
  );
};

export default PageContainer;
