import React from 'react';

interface AlarmIconMediumProps {
    size?: number;
    color?: string;
}

const AlarmIconMedium: React.FC<AlarmIconMediumProps> = ({ size = 27, color = '#FF910F' }) => (
    <svg width={size} height={size} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M27 13.5C27 20.9558 20.9558 27 13.5 27C6.04416 27 0 20.9558 0 13.5C0 6.04416 6.04416 0 13.5 0C20.9558 0 27 6.04416 27 13.5ZM14.85 6.75V14.85H12.15V6.75H14.85ZM14.85 17.55V20.25H12.15V17.55H14.85Z" fill={color}/>
        <path d="M14.85 14.85V6.75H12.15V14.85H14.85Z" fill="black" fillOpacity="0.9"/>
        <path d="M14.85 20.25V17.55H12.15V20.25H14.85Z" fill="black" fillOpacity="0.9"/>
    </svg>
);

export default AlarmIconMedium;
