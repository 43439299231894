import styled from "styled-components";
import {
  StyledTable,
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  StyledTableHeaderCell,
  IconTableCell,
} from "../styles/DynamicTableStyles";

export const CustomStyledTable = styled(StyledTable)`
  border-spacing: 0 10px; /* Additional spacing between rows */
`;

export const CustomStyledTableHead = styled(StyledTableHead)`
  background-color: #f3f9fd;
  height: 50px;
`;

export const CustomStyledTableRow = styled(StyledTableRow)`
  background-color: white;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  height: 50px; /* Height of each row */
`;

export const CustomStyledTableCell = styled(StyledTableCell).withConfig({
  shouldForwardProp: (prop) => !["isHeader", "isIcon"].includes(prop),
})<{ isFirst?: boolean; isLast?: boolean }>`
  padding: ${({ isFirst, isLast }) =>
    isFirst ? "5px 30px 5px 16px" : isLast ? "0" : "0"};
  font-size: 1.2rem;
`;

export const CustomStyledTableHeaderCell = styled(
  StyledTableHeaderCell
).withConfig({
  shouldForwardProp: (prop) => !["isHeader", "isIcon"].includes(prop),
})<{ isFirst?: boolean; isLast?: boolean }>`
  padding: ${({ isFirst, isLast }) =>
    isFirst ? "5px 30px 5px 16px" : isLast ? "0" : "0"};
  font-size: 1.2rem;
`;

export const CustomIconTableCell = styled(IconTableCell)`
  padding: 5px 0;
  font-size: 1.2rem;

  .icon-container {
    padding-top: 20px;
  }
  svg {
    width: 30px;
    height: 30px;
  }
`;
