// authHelpers.ts
import { AxiosInstance } from "axios";
import {
  authenticationExpirationCheck,
  getApiAuthorization,
} from "../../../auth/authentication";

export const setAuthorizationHeader = (
  vars: any,
  data: any,
  view: any,
  navigate: any,
  axiosInstance: AxiosInstance
) => {
  authenticationExpirationCheck(vars, data, view, navigate);

  const authorization = getApiAuthorization(vars);
  if (!authorization) {
    throw new Error("No authorization token found");
  }

  axiosInstance.defaults.headers.common["Authorization"] = authorization;
};
