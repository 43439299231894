import React from "react";
import { Maybe } from "../../../../types";
import { Panel, PanelStatusProps } from "../../../atoms";
import {
  PanelStatisticContainer,
  PanelStatisticSummary,
  PanelStatisticData,
} from "./PanelStatistic.styles";

type StatisticContentProps = {
  summary: string;
  value: Maybe<number>;
  unit?: string;
};

type PanelStatisticProps = StatisticContentProps &
  PanelStatusProps & {
    title: string;
  };

const StatisticContent: React.FC<StatisticContentProps> = ({
  summary,
  value,
  unit,
}) => {
  const valueToDisplay = value ? Math.round(100 * value) / 100 : "N/A";
  const unitToDisplay = value ? unit || "" : "";
  return (
    <PanelStatisticContainer>
      <PanelStatisticData>{`${valueToDisplay} ${unitToDisplay}`}</PanelStatisticData>
      <PanelStatisticSummary>{summary}</PanelStatisticSummary>
    </PanelStatisticContainer>
  );
};

const PanelStatistic: React.FC<PanelStatisticProps> = ({
  title,
  summary,
  value,
  unit,
  isLoading,
  error,
}) => {
  const isEmptyData = value === null || value === undefined;

  return (
    <Panel
      title={title}
      content={<StatisticContent summary={summary} value={value} unit={unit} />}
      isStatistical
      isLoading={isLoading}
      error={error}
      isEmpty={isEmptyData}
    />
  );
};

export default PanelStatistic;
