import { makeSubmitButton } from "../../../components/Button";
import { makeConfirmModal, makeModal } from "../../../components/Modal";
import {
  makeDropdownInput,
  makeTextInput,
  makeTextOutput,
} from "../../../components/FormInputs";
import {
  coords,
  coordsLindholmspiren,
  initializeMap,
  latToPrettyString,
  lngToPrettyString,
  makeLatLng,
  mapIconRedballoon,
} from "../../../components/Map";
import { iconButton } from "../../../components/Button";
import { HiPencil } from "react-icons/hi";
import { DestinationEdgeType, EntityState } from "../../../context/types";
import { strNullToString } from "../../../helpers/stringHelpers";
import useManageData from "../../../apiComms/manageData";
import { useEffect, useState } from "react";
import { destinationEntry, useAppContext } from "../../../context/variables";
import { Tooltip } from "@mui/material";

const defaultDestCoords = coordsLindholmspiren;
const defaultDestRadius = 40;
function simplePolyAroundCoords(coords: coords): coords[] {
  return [
    { lat: coords.lat + 0.001, lng: coords.lng },
    { lat: coords.lat, lng: coords.lng + 0.001 },
    { lat: coords.lat - 0.001, lng: coords.lng },
    { lat: coords.lat, lng: coords.lng - 0.001 },
  ];
}
const defaultDestPoly: coords[] = simplePolyAroundCoords(defaultDestCoords);
const defaultDestZoom = 15;

let addDestMarker: google.maps.Marker | undefined;
let addDestCircle: google.maps.Circle | undefined;
let addDestPoly: google.maps.Polygon | undefined;
function AddDestMap(
  map: google.maps.Map | undefined,
  setMap: (map: google.maps.Map | undefined) => void,
  modalOpen: boolean,
  coords: coords,
  setCoords: (coords: coords) => void,
  setRadius: (radius: number) => void,
  destEdgeType: DestinationEdgeType,
  setPolyLength: (length: number) => void,
  setMarkerInPoly: (isInside: boolean) => void,
  closeWritingFields: () => void,
  destinationListFiltered: destinationEntry[] | null
) {
  const { data } = useAppContext();
  const [mapInitialized, setMapInitialized] = useState(false);
  const mapElementId = "add_dest_map";

  useEffect(() => {
    if (modalOpen) {
      initializeMap(
        map,
        setMap,
        mapElementId,
        mapInitCustom,
        setMapInitialized,
        defaultDestCoords,
        defaultDestZoom
      );
    } else {
      setMapInitialized(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  function moveEverythingToExistingDest() {
    if (
      destinationListFiltered !== null &&
      destinationListFiltered.length > 0 &&
      mapInitialized
    ) {
      const anExistingDest = destinationListFiltered[0];
      const nearbyCoords = makeLatLng(
        anExistingDest.latitude,
        anExistingDest.longitude - 0.01
      );
      // console.log("Using coords:", nearbyCoords)
      setCoords(nearbyCoords);
      addDestCircle?.setCenter(nearbyCoords);
      addDestPoly?.setPath(simplePolyAroundCoords(nearbyCoords));
      setMarkerInPoly(true);
      // console.log("map is:", map)
      if (map) {
        // console.log("centering map")
        map.setCenter(nearbyCoords);
      }
    }
  }

  useEffect(() => {
    if (mapInitialized) moveEverythingToExistingDest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.org.destinationList, mapInitialized]);

  function checkIfMarkerIsInPoly() {
    const markerPosNow = addDestMarker?.getPosition();
    if (
      addDestPoly !== undefined &&
      markerPosNow !== undefined &&
      markerPosNow !== null
    ) {
      const markerInPoly = google.maps.geometry.poly.containsLocation(
        markerPosNow,
        addDestPoly
      );
      // console.log("markerInPoly:", markerPosNow?.lat(), markerPosNow?.lng(), addDestPoly.getPath(), markerInPoly)
      setMarkerInPoly(markerInPoly);
    }
  }

  useEffect(() => {
    // Tracking polygon length
    const length = addDestPoly?.getPath().getLength();
    // console.log("vertex counting effect, new length:", length)
    if (length !== undefined) {
      setPolyLength(length);
    }
    addDestPoly?.getPath().addListener("insert_at", () => {
      const length = addDestPoly?.getPath().getLength();
      // console.log("insert_at, new length:", length)
      if (length !== undefined) {
        setPolyLength(length);
      }
      checkIfMarkerIsInPoly();
      closeWritingFields();
    });
    addDestPoly?.getPath().addListener("remove_at", () => {
      const length = addDestPoly?.getPath().getLength();
      // console.log("remove_at, new length:", length)
      if (length !== undefined) {
        setPolyLength(length);
      }
      checkIfMarkerIsInPoly();
      closeWritingFields();
    });
    addDestPoly?.getPath().addListener("set_at", () => {
      // console.log("set_at")
      checkIfMarkerIsInPoly();
      closeWritingFields();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDestPoly, destEdgeType]);

  function mapInitCustom(map: google.maps.Map) {
    // console.log("mapInitCustom")
    addDestMarker = new google.maps.Marker({
      draggable: true,
      map: map,
      position: coords,
      icon: mapIconRedballoon(),
    });
    addDestMarker.addListener("position_changed", () => {
      const pos = addDestMarker?.getPosition();
      if (pos !== null && pos !== undefined) {
        setCoords(makeLatLng(pos.lat(), pos.lng()));
      }
      checkIfMarkerIsInPoly();
      closeWritingFields();
    });
    addDestCircle = new google.maps.Circle({
      map: map,
      center: coords,
      radius: defaultDestRadius,
      strokeColor: "#ff4d00",
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: "#ff4d00",
      fillOpacity: 0.5,
      draggable: false,
      editable: true,
    });
    addDestCircle.addListener("radius_changed", () => {
      const rad = addDestCircle?.getRadius();
      // console.log("radius listener fired:", rad)
      if (rad !== undefined) {
        setRadius(rad);
      }
      closeWritingFields();
    });
    addDestCircle.bindTo("center", addDestMarker, "position");
    addDestPoly = new google.maps.Polygon({
      map: map,
      paths: defaultDestPoly,
      strokeColor: "#ff4d00",
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: "#ff4d00",
      fillOpacity: 0.5,
      editable: true,
      draggable: true,
      geodesic: true,
    });
    addDestPoly.addListener("dblclick", (mev: google.maps.PolyMouseEvent) => {
      const length = addDestPoly?.getPath().getLength();
      // console.log("poly dblclick event", mev, typeof mev, mev.vertex, length)
      if (mev.vertex != null && length !== undefined && length > 3) {
        addDestPoly?.getPath().removeAt(mev.vertex);
      }
      closeWritingFields();
    });
  }

  if (mapInitialized) {
    if (destEdgeType === DestinationEdgeType.Circle) {
      addDestCircle?.setVisible(true);
      addDestPoly?.setVisible(false);
    } else {
      addDestCircle?.setVisible(false);
      addDestPoly?.setVisible(true);
    }
  }

  return (
    <div
      id={mapElementId}
      className="w-full h-full rounded-xl text-black bg-[#8c8c8c] border-2 shadow-[4px_4px_8px_2px_rgba(0,0,0,0.1)]"
    />
  );
}

export function AddDestModal(
  open: boolean,
  setOpen: (open: boolean) => void,
  destinationListFiltered: destinationEntry[] | null
) {
  const { vars, data } = useAppContext();
  const [confirmAddDestModalOpen, setConfirmAddDestModalOpen] = useState(false);
  const { apiPostDestsAdd } = useManageData();

  // ----- Warnings -----
  const [destNameWarning, setDestNameWarning] = useState<string | null>(null);
  const [coordsWarning, setCoordsWarning] = useState<string | null>(null);
  const [radiusWarning, setRadiusWarning] = useState<string | null>(null);
  const [orgWarning, setOrgWarning] = useState<string | null>(null);

  // ----- Standard modal bits -----
  function clearWarnings() {
    setDestNameWarning(null);
    setCoordsWarning(null);
    setRadiusWarning(null);
    setOrgWarning(null);
  }
  function resetValues() {
    setCoords(defaultDestCoords);
    setWritingCoords(false);
    setDestEdgeType(DestinationEdgeType.Circle);
    setRadius(defaultDestRadius);
    setWritingRadius(false);
    setChosenOrg(vars.auth.organisationId);
    setChosenDestState(EntityState.Active);
  }
  function onClose() {
    setOpen(false);
    clearWarnings();
    resetValues();
  }
  function onSubmitClick() {
    // console.log("Hit submit add destination")
    clearWarnings();
    closeWritingFields();
    const theDestName = (
      document.getElementById("destName") as HTMLInputElement
    ).value;
    const roundedRadius = Math.round(radius);
    if (theDestName.length < 1) {
      // Not OK: dest name bad
      // console.log("Submit warning: Dest name not OK")
      setDestNameWarning("Destination name required");
    } else if (
      destEdgeType === DestinationEdgeType.Circle &&
      roundedRadius < 10
    ) {
      // console.log("Submit warning: Radius too low")
      // radius warning already set
    } else if (
      destEdgeType === DestinationEdgeType.Polygon &&
      (polyLength < 3 || polyLength > 12)
    ) {
      // console.log("Submit warning: Too few/many polygon vertices")
      // poly count warning already set or should be impossible
    } else if (destEdgeType === DestinationEdgeType.Polygon && !markerInPoly) {
      // console.log("Submit warning: Marker not inside polygon")
      // marker not in poly warning already set
    } else if (
      vars.auth.userGroups?.includes("root") &&
      chosenOrg === null
    ) {
      // console.log("Submit warning: No organisation chosen for root")
      setOrgWarning("No organisation chosen");
    } else {
      // OK
      setConfirmAddDestModalOpen(true);
    }
  }
  function onConfirmClick() {
    // console.log("Hit confirm add destination")
    const theDestName = (
      document.getElementById("destName") as HTMLInputElement
    ).value;
    const roundedRadius = Math.round(radius);
    const polyPath = addDestPoly?.getPath();
    const polyArray = polyPath?.getArray();
    const polyCoordss = polyArray?.map((point) =>
      makeLatLng(point.lat(), point.lng())
    );
    // console.log("added the dest: (name:", theDestName,
    //   ", coords:", coords,
    //   ", shape:", destEdgeType,
    //   ", radius:", roundedRadius,
    //   ", poly:", polyCoordss,
    //   ", org:", chosenOrg,
    //   ", state:", chosenDestState,
    //   ")")
    apiPostDestsAdd(
      chosenOrg,
      theDestName,
      coords,
      destEdgeType,
      roundedRadius,
      polyCoordss,
      chosenDestState
    );
    setConfirmAddDestModalOpen(false);
    onClose();
  }

  // ----- Map -----
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);

  // ----- Coordinates -----
  const coordsTitle = "Coordinates (lat, long):";
  const coordsTooltip =
    'The coordinates of the destination. Edit in the map by moving the pin, or by typing lat & long (as one number each, use "." for decimals, use negative values for °S and °W)'; // (a latitude between -90 and 90 and a longitude between -180 and 180)"
  const [coords, setCoords] = useState(defaultDestCoords);
  const newCoordPrettyStr =
    latToPrettyString(coords.lat) + ", " + lngToPrettyString(coords.lng);
  const [writingCoords, setWritingCoords] = useState(false);
  const coordsReadComp = (
    <div className="flex space-x-2 items-center">
      {makeTextOutput(coordsTitle, coordsTooltip, newCoordPrettyStr, null)}
      <Tooltip title="Edit by typing" placement="right">
        <div
          className="cursor-pointer"
          key={"edit"}
          onClick={() => {
            setWritingCoords(true);
            setWritingRadius(false);
          }}
        >
          <HiPencil className="text-2xl" />
        </div>
      </Tooltip>
    </div>
  );
  const emptyCoordsWarning = coordsWarning === null ? null : "";
  const coordsWriteComp = (
    <div className="flex space-x-2 items-center text-xl">
      {makeTextInput(
        coordsTitle,
        coordsTooltip,
        "° Lat",
        "newDestWriteLat",
        emptyCoordsWarning,
        coords.lat.toString(),
        "Small"
      )}
      <div className="flex space-x-6 justify-start items-center text-xl">
        {","}
      </div>
      {makeTextInput(
        null,
        null,
        "° Long",
        "newDestWriteLng",
        coordsWarning,
        coords.lng.toString(),
        "Small"
      )}
      {iconButton("Set", <></>, () => {
        const writeLatStr = (
          document.getElementById("newDestWriteLat") as HTMLInputElement
        ).value;
        const writeLat = Number(writeLatStr);
        const writeLngStr = (
          document.getElementById("newDestWriteLng") as HTMLInputElement
        ).value;
        const writeLng = Number(writeLngStr);
        // console.log("coord inputs:", writeLat, writeLng)
        if (writeLatStr.length < 1 || writeLngStr.length < 1) {
          setCoordsWarning("Please enter a latitude and a longitude");
        } else if (isNaN(writeLat) || isNaN(writeLng)) {
          setCoordsWarning("Coordinates must be numerical");
        } else if (
          writeLat > 90 ||
          writeLat < -90 ||
          writeLng > 180 ||
          writeLng < -180
        ) {
          setCoordsWarning("Coordinates must be within possible bounds");
        } else {
          const writeCoords = makeLatLng(writeLat, writeLng);
          addDestCircle?.setCenter(writeCoords);
          map?.setCenter(writeCoords);
          setCoords(writeCoords);
          setCoordsWarning(null);
          setWritingCoords(false);
        }
      })}
    </div>
  );
  const coordsComp = writingCoords ? coordsWriteComp : coordsReadComp;

  // ----- Edge type -----
  const [destEdgeType, setDestEdgeType] = useState(DestinationEdgeType.Circle);
  useEffect(() => {
    addDestPoly?.setPath(simplePolyAroundCoords(coords));
    setMarkerInPoly(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destEdgeType]);
  // const destinationEdgeTypeOptions: {
  //   value: DestinationEdgeType;
  //   name: string;
  // }[] = Object.values(DestinationEdgeType).map((level) => {
  //   return { value: level, name: level };
  // });
  // const edgeTypePicker = makeRadioInput(
  //   "Edge shape:",
  //   "How to define the edge of what counts as the destination",
  //   destEdgeType,
  //   destinationEdgeTypeOptions,
  //   setDestEdgeType
  // );

  // ----- Radius -----
  const radiusTitle = "Radius:";
  const radiusTooltip =
    "The radius of the circular edge of what counts as the destination. Edit in the map or by typing. Must be at least 10 m";
  const [radius, setRadius] = useState(defaultDestRadius);
  const roundedRadius = Math.round(radius);
  const [writingRadius, setWritingRadius] = useState(false);
  const readRadiusWarning = roundedRadius < 10 ? "Radius under 10 m" : null;
  const readRadiusField = (
    <div className="flex space-x-2 items-center">
      {makeTextOutput(
        radiusTitle,
        radiusTooltip,
        roundedRadius + " meters",
        readRadiusWarning
      )}
      <Tooltip title="Edit by typing" placement="right">
        <div
          className="cursor-pointer"
          key={"edit"}
          onClick={() => {
            setWritingRadius(true);
            setWritingCoords(false);
          }}
        >
          <HiPencil className="text-2xl" />
        </div>
      </Tooltip>
    </div>
  );
  const writeRadiusField = (
    <div className="flex space-x-2 items-center">
      {makeTextInput(
        radiusTitle,
        radiusTooltip,
        defaultDestRadius.toString(),
        "newDestWriteRadius",
        radiusWarning,
        roundedRadius.toString(),
        "Small"
      )}
      <div className="flex space-x-6 justify-start items-center text-xl">
        {"meters"}
      </div>
      {iconButton("Set", <></>, () => {
        const writeRadiusStr = (
          document.getElementById("newDestWriteRadius") as HTMLInputElement
        ).value;
        const writeRadius = Number(writeRadiusStr);
        // console.log("writeRadius:", writeRadiusStr, writeRadius, typeof writeRadius)
        if (writeRadiusStr.length < 1) {
          setRadiusWarning("Please enter a number");
        } else if (isNaN(writeRadius)) {
          setRadiusWarning("Radius must be numerical");
        } else if (writeRadius < 10) {
          setRadiusWarning("Radius under 10 m");
        } else {
          addDestCircle?.setRadius(writeRadius);
          setRadiusWarning(null);
          setWritingRadius(false);
        }
      })}
    </div>
  );
  const radiusField = writingRadius ? writeRadiusField : readRadiusField;

  // ----- Polygon -----
  const [polyLength, setPolyLength] = useState(defaultDestPoly.length);
  const [markerInPoly, setMarkerInPoly] = useState(true);
  const polygonField = makeTextOutput(
    "Polygon:",
    "The edge of what counts as the destination. Edit in the map, remove a point by double-clicking it. Must have between 3 and 12 corners",
    polyLength + " corners, see map",
    polyLength > 12
      ? "Maximum of 12 corners allowed"
      : !markerInPoly
      ? "Coordinates must be within polygon"
      : null
  );

  // ----- Organisation -----
  useEffect(() => {
    setChosenOrg(vars.auth.organisationId);
  }, [vars.auth.organisationId]);
  const [chosenOrg, setChosenOrg] = useState<string | null>(null);
  const orgOptions =
    data.org.organisationList === null
      ? []
      : data.org.organisationList.map((org) => {
          return { value: org.id, name: org.name };
        });
  const rootOrgPicker = makeDropdownInput(
    "Organisation:",
    "The organisation which the dest belongs to",
    "Organisation",
    chosenOrg,
    orgOptions,
    setChosenOrg,
    orgWarning
  );

  // ----- Destination state -----
  const [chosenDestState, setChosenDestState] = useState<EntityState>(
    EntityState.Active
  );
  const stateOptions: { value: EntityState; name: string }[] = Object.values(
    EntityState
  ).map((state) => {
    return { value: state, name: state };
  });
  const rootStatePicker = makeDropdownInput(
    "Destination state:",
    "The state of the destination. Determines whether it will be shown to customers as a suggestion, it is seen onboard, it is hidden, or it will be used to avoid generating the same suggestion twice.",
    "State",
    chosenDestState,
    stateOptions,
    setChosenDestState,
    null
  );

  // ----- The confirmation modal -----
  const destNameField = document.getElementById("destName") as HTMLInputElement;
  const theDestName = destNameField !== null ? destNameField.value : "N/A";
  const confirmTextEdgePart =
    destEdgeType === DestinationEdgeType.Circle
      ? "Circle with a radius of " + roundedRadius + " meters"
      : "Polygon with " + polyLength + " corners";
  const confirmStandardBit = [
    "You are about to add the following destination:",
    '- Name: "' + theDestName + '"',
    "- Coordinates: " + newCoordPrettyStr,
    "- Shape: " + confirmTextEdgePart,
  ];
  const chosenOrgObj = orgOptions.find((org) => org.value === chosenOrg);
  const chosenOrgName = chosenOrgObj?.name;
  const confirmRootBit = [
    "- Org: " + strNullToString(chosenOrg) + " (" + chosenOrgName + ")",
    "- State: " + chosenDestState,
  ];
  const confirmText = vars.auth.userGroups?.includes("root")
    ? confirmStandardBit.concat(confirmRootBit)
    : confirmStandardBit;
  const confirmModal = makeConfirmModal(
    "Confirm adding destination",
    confirmText,
    "Confirm: Add destination",
    onConfirmClick,
    confirmAddDestModalOpen,
    setConfirmAddDestModalOpen
  );

  // ----- Putting together the modal -----
  function closeWritingFields() {
    setWritingCoords(false);
    setWritingRadius(false);
  }
  useEffect(() => {
    closeWritingFields();
  }, [destEdgeType, chosenOrg, chosenDestState]);

  const addDestContents = (
    <div className="flex space-x-6">
      <div className="flex flex-col space-y-6">
        {makeTextInput(
          "Destination name:",
          "How the destination will be referred to throughout iHelm",
          " Port Royal",
          "destName",
          destNameWarning
        )}
        {coordsComp}
        {/* {edgeTypePicker} */}
        {destEdgeType === DestinationEdgeType.Circle
          ? radiusField
          : polygonField}
        {vars.auth.userGroups?.includes("root") ? rootOrgPicker : <></>}
        {vars.auth.userGroups?.includes("root") ? rootStatePicker : <></>}
        {makeSubmitButton(onSubmitClick)}
        {confirmModal}
      </div>
      <div className="w-[36rem] h-[36rem]">
        {AddDestMap(
          map,
          setMap,
          open,
          coords,
          setCoords,
          setRadius,
          destEdgeType,
          setPolyLength,
          setMarkerInPoly,
          closeWritingFields,
          destinationListFiltered
        )}
      </div>
    </div>
  );
  return makeModal("Add new destination", addDestContents, open, onClose);
}
