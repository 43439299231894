import { useEffect, useState } from "react"
import { makeDropdownInput, makeTextInput } from "../../../components/FormInputs"
import { makeConfirmModal, makeModal } from "../../../components/Modal"
import { useAppContext } from "../../../context/variables"
import { TagScope, TagValueType, tagScopePretty, tagValueTypePretty, AccessLevel, EntityState, accessLevelAtMinimum } from "../../../context/types"
import { makeSubmitButton } from "../../../components/Button"
import { strNullToString } from "../../../helpers/stringHelpers"
import useManageData from "../../../apiComms/manageData"
import { getTextFieldValue } from "../../../helpers/elementHelpers"

export function AddTagTypeModal(open: boolean, setOpen: (open: boolean)=>void) {
  const { data, vars } = useAppContext()
  const { apiPostTagTypesAdd } = useManageData()
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  
  const [nameWarning, setNameWarning] = useState<string|null>(null)
  const [valueTypeWarning, setValueTypeWarning] = useState<string|null>(null)
  const [unitWarning, setUnitWarning] = useState<string|null>(null)
  const [orgWarning, setOrgWarning] = useState<string|null>(null)

  function clearWarnings() {
    setNameWarning(null)
    setValueTypeWarning(null)
    setUnitWarning(null)
    setOrgWarning(null)
  }
  function resetValues() {
    setScope(TagScope.LegInstance)
    setValueType(TagValueType.Number)
    setOrg(vars.auth.organisationId)
    setState(EntityState.Active)
  }
  function onClose() {
    setOpen(false)
    clearWarnings()
    resetValues()
  }
  function onSubmitClick() {
    // console.log("Hit submit add tag")
    clearWarnings()
    const theTagTypeName = (document.getElementById("tagTypeName") as HTMLInputElement).value
    // const unitElem = document.getElementById("tagTypeUnit") as HTMLInputElement
    // const unitVal = (unitElem === null) ? null : unitElem.value
    if(theTagTypeName.length < 1) {
      // console.log("Submit warning: Tag type name not OK")
      setNameWarning("Please enter a name")
    // } else if(valueType === TagValueType.Number && (unitVal === null || unitVal.length < 1)) {
    //   console.log("Submit warning: Tag type unit not OK")
    //   setUnitWarning("Unit required?")
    } else if(accessLevelAtMinimum(vars.auth.accessLevel[0], AccessLevel.Root) && org === null) {
      // console.log("Submit warning: No organisation chosen for root")
      setOrgWarning("No organisation chosen")
    } else {
      setConfirmModalOpen(true)
    }
  }
  function onConfirmClick() {
    // const nameElem = document.getElementById("tagTypeName") as HTMLInputElement
    const nameVal = getTextFieldValue("tagTypeName")// (nameElem === null) ? null : nameElem.value
    // const unitElem = document.getElementById("tagTypeUnit") as HTMLInputElement
    // const unitVal = (unitElem === null) ? null : unitElem.value
    const unitStr = getTextFieldValue("tagTypeUnit")
    const unitVal = (unitStr !== null && unitStr.length > 0) ? unitStr : null
    data.tagTypes.tagTypeList.set(null)
    apiPostTagTypesAdd(org, nameVal, scope, valueType, unitVal, state)
    setConfirmModalOpen(false)
    onClose()
  }

  // ----- Name -----
  const nameField = makeTextInput(
    "Name:", 
    "What the tag type will be called throughout iHelm", 
    " Lumber in cargo", 
    "tagTypeName", 
    nameWarning
  )

  // ----- Scope -----
  const [scope, setScope] = useState(TagScope.LegInstance)
  const scopeOptions = [
    {value: TagScope.LegInstance, name: tagScopePretty[TagScope.LegInstance]},
    {value: TagScope.VoyageInstance, name: tagScopePretty[TagScope.VoyageInstance]},
  ]
  const scopePicker = makeDropdownInput(
    "Scope:", 
    "Determines the period which the tag is applied to", 
    "Scope", 
    scope, scopeOptions, setScope, null
  )

  // ----- Value type -----
  const [valueType, setValueType] = useState(TagValueType.Number)
  const valueTypeOptions = [
    {value: TagValueType.None, name: tagValueTypePretty[TagValueType.None]},
    {value: TagValueType.Number, name: tagValueTypePretty[TagValueType.Number]},
    {value: TagValueType.String, name: tagValueTypePretty[TagValueType.String]},
    {value: TagValueType.Boolean, name: tagValueTypePretty[TagValueType.Boolean]},
  ]
  const valueTypePicker = makeDropdownInput(
    "Value type:", 
    "The type of values to be provided with the tags when they are applied", 
    "Value type", 
    valueType, valueTypeOptions, setValueType, valueTypeWarning
  )

  // ----- Unit -----
  const maybeUnitField = (valueType === TagValueType.Number) ? makeTextInput(
    "Unit:",
    "(Optional) The unit of the values to be provided with the tags when they are applied.",
    " Logs",
    "tagTypeUnit",
    unitWarning,
    undefined,
    "Small"
  ) : <></>

  // ----- Org -----
  useEffect(()=>{
    setOrg(vars.auth.organisationId)
  }, [vars.auth.organisationId])
  const [org, setOrg] = useState<string|null>(null)
  const orgOptions = data.org.organisationList === null ? [] : data.org.organisationList.map((org)=>{return {value: org.id, name: org.name}})
  const accessLevel = vars.auth.accessLevel[0]
  const showOrg = accessLevelAtMinimum(accessLevel, AccessLevel.Root)
  const maybeOrgPicker = showOrg ? makeDropdownInput(
    "Organisation:",
    "The org to add the tag type for",
    "Organisation",
    org, orgOptions, setOrg, orgWarning
  ) : <></>

  // ----- State -----
  const [state, setState] = useState<EntityState>(EntityState.Active)
  const stateOptions: {value: EntityState, name: string}[] = Object.values(EntityState).map((state)=>{
    return {value: state, name: state}
  })
  const maybeStatePicker = showOrg ? makeDropdownInput(
    "State:",
    "The state of the tag type. Determines whether it will be shown to customers as a suggestion, it is seen onboard, or it is hidden.",
    "State",
    state,
    stateOptions,
    setState,
    null
  ) : <></>

  // ----- Confirm -----
  const nameElem = document.getElementById("tagTypeName") as HTMLInputElement
  const nameVal = (nameElem === null) ? null : nameElem.value
  const unitVal = getTextFieldValue("tagTypeUnit")
  const orgObj = orgOptions.find((entry)=>entry.value === org)
  const orgName = orgObj?.name
  const confirmTextAlways = [
    "You are about to add the following tag type:",
    '- Name: "'+nameVal+'"',
    '- Scope: '+tagScopePretty[scope],
    '- Value type: '+tagValueTypePretty[valueType],
  ]
  const confirmTextNumber = [
    '- Unit: '+strNullToString(unitVal, (unit)=>'"'+unit+'"', "No unit"),
  ]
  const confirmTextStandard = (valueType === TagValueType.Number) ?
    confirmTextAlways.concat(confirmTextNumber)
    : confirmTextAlways
  const confirmTextRoot = [
    '- Organisation: '+strNullToString(org)+' ('+orgName+')',
    '- State: '+state,
  ]
  const immutableNotice = [
    'Note: Tag types cannot be edited after creation!'
  ]
  const confirmText = showOrg ?
    confirmTextStandard.concat(confirmTextRoot).concat(immutableNotice)
    : confirmTextStandard.concat(immutableNotice)
  const confirmModal = makeConfirmModal(
    "Confirm adding tag type",
    confirmText,
    "Confirm: Add tag type",
    onConfirmClick,
    confirmModalOpen,
    setConfirmModalOpen
  )

  const content = 
    <div className="flex flex-col space-y-4">
      {nameField}
      {scopePicker}
      {valueTypePicker}
      {maybeUnitField}
      {maybeOrgPicker}
      {maybeStatePicker}
      {makeSubmitButton(onSubmitClick)}
      {confirmModal}
    </div>
  return makeModal("Add new tag type", content, open, onClose)
}
