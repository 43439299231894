import { useEffect, useState } from "react";
import Tab from "../../../tabs/Tab";
import useFetchData from "../../../apiComms/fetchData";
import { useAppContext } from "../../../context/variables";
import { genericTableEditable, genericTableRelatedBits } from "../../../components/Table";
import { strNullToString } from "../../../helpers/stringHelpers";
import { addButton } from "../../../components/Button";
import EmptyState from "../../../components/atoms/EmptyState";
import TagIcon from "../../../assets/icons/EmptyStates/TagIcon";
import LoadingSpinner from "../../../components/atoms/LoadingSpinner";
import { AddTagTypeModal } from "./AddTagTypeModal"
import { ManageTagTypeModal } from "./ManageTagTypeModal";
import { TagValueType, tagScopePretty, tagValueTypePretty, AccessLevel, EntityState, accessLevelAtMinimum } from "../../../context/types";

export default function TagTypesTab() {
  const { vars, data } = useAppContext();
  const { fetchDataTagTypes, fetchDataOrganisations } = useFetchData();

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [manageModalOpen, setManageModalOpen] = useState(false);
  const [managedId, setManagedId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const showExtraData =
    vars.auth.organisationId === null &&
    accessLevelAtMinimum(vars.auth.accessLevel[0], AccessLevel.Root);
  const orgsList = data.org.organisationList;
  const tagTypeList = data.tagTypes.tagTypeList.val;
  const tagTypeListFiltered =
    tagTypeList === null
      ? null
      : showExtraData
      ? tagTypeList
      : tagTypeList.filter((entry) => entry.state !== EntityState.Rejected && entry.state !== EntityState.Removed);

  useEffect(() => {
    fetchDataOrganisations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.idToken, vars.auth.organisationId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchDataTagTypes();
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.organisationId, vars.auth.accessLevel[0]]);

  const columnNames = showExtraData
    ? ["Name", "Value type", "Unit", "State", "Scope", "categories", "id", "org id (org name)"]
    : ["Name", "Value type", "Unit", "State", "Scope"];

  const rows =
    tagTypeListFiltered === null
      ? null
      : tagTypeListFiltered.map((tag) => {
          const name = tag.name;
          const valueTypePretty = tagValueTypePretty[tag.valueType];
          const unitPretty = strNullToString(tag.unit, undefined, tag.valueType === TagValueType.Number ? "No unit" : "N/A");
          const state = tag.state;
          const scopePretty = tagScopePretty[tag.scope];
          const categoriesStrMaybe = tag.categories !== null ? tag.categories.join() : null;
          const categories = strNullToString(categoriesStrMaybe);
          const id = tag.id;
          const orgId = tag.orgId;
          const orgMaybe = orgsList?.find((org) => org.id === orgId);
          const orgName = orgMaybe === undefined ? "N/A" : orgMaybe.name;
          const row = showExtraData
            ? [name, valueTypePretty, unitPretty, state, scopePretty, categories, id, `${orgId} (${orgName})`] as string[]
            : [name, valueTypePretty, unitPretty, state, scopePretty] as string[];
          return { id: tag.id, contents: row };
        });

  const isEmptyState = !tagTypeListFiltered || tagTypeListFiltered.length === 0;

  const content = (
    <div className="h-full flex flex-col space-y-3 text-deep_blue">
      {addButton("Add new tag type", () => setAddModalOpen(true))}
      {genericTableRelatedBits("Tag types", tagTypeListFiltered?.length, fetchDataTagTypes)}
      {loading ? (
        <LoadingSpinner />
      ) : isEmptyState ? (
        <EmptyState
          icon={<TagIcon />}
          title="No tag types have been added"
          description="Add new tag types to categorize your entries"
          buttonText="Add tag type"
          onButtonClick={() => setAddModalOpen(true)}
        />
      ) : (
        genericTableEditable(columnNames, rows, (id) => {
          setManagedId(id);
          setManageModalOpen(true);
        })
      )}
      {AddTagTypeModal(addModalOpen, setAddModalOpen)}
      {ManageTagTypeModal(manageModalOpen, setManageModalOpen, managedId, setManagedId)}
    </div>
  );

  return <Tab title="Tags" content={content} />;
}
