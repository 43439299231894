import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Dropdown from "../atoms/Dropdown";
import Input from "../atoms/Input";
import SignalsDropdown from "./SignalsDropdown";
import { useAppContext } from "../../context/variables";

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  position: relative;
  padding-left: 10px;
`;

const ConditionText = styled.span`
  color: #143452;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 500;
`;

const UnitText = styled.span`
  color: #A8A8A8;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 500;
  margin-left: 10px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #143452;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
`;

interface ConditionInputProps {
  condition: {
    parameter: string;
    comparison: string;
    value: string;
  };
  onChange: (field: string, value: string) => void;
  onRemove: () => void;
  disabled: boolean;
  alarmTypeOptions: Array<{ value: string; label: string }>;
}

const ConditionInput: React.FC<ConditionInputProps> = ({
  condition,
  onChange,
  onRemove,
  disabled,
  alarmTypeOptions,
}) => {
  const { data } = useAppContext();
  const [signalUnitsMap, setSignalUnitsMap] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (data.signalTypes?.signalTypeList?.val) {
      const unitsMap = data.signalTypes.signalTypeList.val.reduce((acc, signal) => {
        acc[signal.value] = signal.unit;
        return acc;
      }, {} as { [key: string]: string });
      setSignalUnitsMap(unitsMap);
    }
  }, [data.signalTypes?.signalTypeList?.val]);

  const comparisonOptions = [
    { value: "higher", label: "Higher than" },
    { value: "lower", label: "Lower than" },
  ];

  const getUnitForParameter = (parameter: string): string => {
    return signalUnitsMap[parameter] || "";
  };

  return (
    <Row>
      <ConditionText>Alarm rings when</ConditionText>
      <SignalsDropdown
        value={condition.parameter}
        onChange={(value) => onChange("parameter", value)}
        width="30%"
      />
      <Dropdown
        name="comparison"
        value={condition.comparison}
        options={comparisonOptions}
        onChange={(name, value) => onChange(name, value)}
        width="25%"
        placeholder="Select comparison"
      />
      <div style={{ display: 'flex', alignItems: 'center', width: '30%' }}>
        <Input
          id="value"
          type="text"
          name="value"
          placeholder="Enter value"
          value={condition.value}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          width="100%"
          disabled={disabled}
        />
        <UnitText>{getUnitForParameter(condition.parameter)}</UnitText>
      </div>
      {/* <CloseButton onClick={onRemove}>×</CloseButton>  Commented because it is not needed with new design, but we will accept multiple conditions for version two so it is better to keep it */}
    </Row>
  );
};

export default ConditionInput;
