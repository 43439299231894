import React, { useEffect, useRef, useState } from "react";
import { GenericTableWithIconAndSorting } from "../../../components/Table";
import useFetchData from "../../../apiComms/fetchData";
import {
  Signal,
  dataIntegrityEntry,
  rootStatusEntry,
  useAppContext,
} from "../../../context/variables";
import {
  AiFillCheckCircle,
  AiFillExclamationCircle,
  AiFillQuestionCircle,
  AiOutlineClose,
  AiOutlineInfoCircle,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { latToPrettyString, lngToPrettyString } from "../../../components/Map";
import { Tooltip } from "@mui/material";
import { Loader } from "@googlemaps/js-api-loader";
import LoadingSpinner from "../../../components/atoms/LoadingSpinner";
import TextTooltip from "../../../components/atoms/TextTooltip";
import { SignalModal } from "./SignalModal";

function StatusIcon({
  colorClass,
  icon,
  size,
}: {
  colorClass: string;
  icon: JSX.Element;
  size: string;
}) {
  return React.cloneElement(icon, { className: `${size} ${colorClass}` });
}

function getStatusAndColor(
  statusCode: number,
  diffInHours: number,
  isDatastream: boolean = false
) {
  let status = "";
  let colorClass = "";
  let icon = <AiFillCheckCircle />;
  let size = isDatastream ? "w-4 h-4" : "w-6 h-6";

  // For Datastreams
  if (isDatastream) {
    if (statusCode === -1) {
      status = "No Data";
      colorClass = "text-gray-400";
      icon = <AiFillQuestionCircle />;
    } else if (statusCode === 0) {
      status = "Ok";
      colorClass = "text-green-600";
      icon = <AiFillExclamationCircle />;
    } else {
      status = `Error (${statusCode})`;
      colorClass = "text-red-600";
      icon = <AiFillExclamationCircle />;
    }
  } else {
    // For Status
    if (statusCode === 0) {
      status = "Unknown";
      colorClass = "text-gray-400";
      icon = <AiFillQuestionCircle />;
    } else if (statusCode >= 1 && statusCode <= 29) {
      status = "Ok";
      colorClass = "text-green-600";
      icon = <AiFillCheckCircle />;
    } else if (statusCode >= 30 && statusCode <= 39) {
      status = "Warning";
      colorClass = "text-orange-400";
      icon = <AiFillExclamationCircle />;
    } else if (statusCode >= 40 && statusCode <= 49) {
      status = `Error (${statusCode})`;
      colorClass = "text-red-600";
      icon = <AiFillExclamationCircle />;
    } else if (statusCode >= 50) {
      status = "Critical";
      colorClass = "text-red-700";
      icon = <AiFillExclamationCircle />;
    }
  }

  const svg = <StatusIcon colorClass={colorClass} icon={icon} size={size} />;
  return { status, colorClass, svg };
}

function getStatusIcon(statusCode: string) {
  let icon;
  let colorClass;

  switch (statusCode.toUpperCase()) {
    case "GOOD":
      icon = <AiFillCheckCircle />;
      colorClass = "text-green-600";
      break;
    case "BAD":
      icon = <AiFillExclamationCircle />;
      colorClass = "text-red-600";
      break;
    default:
      icon = <AiFillQuestionCircle />;
      colorClass = "text-gray-400";
  }

  return <span className={`inline-block ${colorClass}`}>{icon}</span>;
}

function formatTimeDifference(diffInMilliseconds: number) {
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInWeeks = Math.floor(diffInDays / 7);
  const diffInMonths = Math.floor(diffInDays / 30);

  if (diffInMonths > 0) {
    return `${diffInMonths} month${diffInMonths > 1 ? "s" : ""} ago`;
  } else if (diffInWeeks > 0) {
    return `${diffInWeeks} week${diffInWeeks > 1 ? "s" : ""} ago`;
  } else if (diffInDays > 0) {
    return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
  } else if (diffInHours > 0) {
    return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
  } else if (diffInMinutes > 0) {
    return `${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""} ago`;
  } else if (diffInSeconds > 0) {
    return `${diffInSeconds} second${diffInSeconds > 1 ? "s" : ""} ago`;
  } else {
    return "Now";
  }
}

export function useCoordinatesMap(
  coordinates: { lat: number; lng: number } | null,
  isMapShown: boolean
) {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const loader = useRef(
    new Loader({
      apiKey: "AIzaSyAFrWFda9joNnyn20PXgFWACROHq8Hwhxk",
      version: "weekly",
      libraries: ["geometry"],
    })
  );

  useEffect(() => {
    if (!isMapShown) {
      return;
    }

    loader.current.load().catch((error) => {
      console.error("Error Google Maps:", error);
    });
  }, [isMapShown]);

  useEffect(() => {
    let isComponentMounted = true;

    const initMap = async () => {
      try {
        await loader.current.load();
        if (isComponentMounted && isMapShown && coordinates) {
          const mapElement = document.getElementById("map") as HTMLElement;
          if (mapElement) {
            const newMap = new google.maps.Map(mapElement, {
              center: { lat: coordinates.lat, lng: coordinates.lng },
              zoom: 14,
            });
            new google.maps.Marker({
              position: { lat: coordinates.lat, lng: coordinates.lng },
              map: newMap,
            });
            setMap(newMap);
          }
        }
      } catch (error) {
        console.error("Error loading the Google Maps script", error);
      }
    };

    initMap();

    return () => {
      isComponentMounted = false;
    };
  }, [isMapShown, coordinates]);

  return isMapShown ? map : null;
}

export default function RootHealthStatusTab() {
  const { data } = useAppContext();
  const { fetchDataRootStatus, fetchDataIntegrityStatus } = useFetchData();
  const [coordinates, setCoordinates] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [isMapShown, setIsMapShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataIntegrityStatus, setDataIntegrityStatus] = useState<
    dataIntegrityEntry[]
  >([]);

  const map = useCoordinatesMap(coordinates, isMapShown);

  const handleCoordinateClick = (lat: number, lon: number) => {
    setCoordinates({ lat, lng: lon });
    setIsMapShown(true);
    setIsLoading(true);
  };
  const handleCloseClick = () => {
    setIsMapShown(false);
  };

  useEffect(() => {
    fetchDataRootStatus();
    fetchDataIntegrityStatus();
  }, []);

  return (
    <div className="flex flex-col pt-4 space-y-4 text-deep_blue pr-16">
      <h1 className="flex items-center pt-4 space-x-4 text-2xl font-bold ml-6">
        Root Health Status Tab
      </h1>
      <div className="grid lg:grid-cols-3 gap-4 items-start">
        <div className="lg:col-span-2">
          {RootStatusTable(data.rootAccess.status, data, handleCoordinateClick)}
        </div>
        {isMapShown && coordinates && coordinates.lat && coordinates.lng ? (
          <div
            id="map"
            className="rounded-xl bg-gray-500 border-2 shadow-md"
            style={{ width: "600px", height: "600px" }}
          >
            <button
              title="Close the map"
              onClick={handleCloseClick}
              className="absolute right-0 top-0 z-10"
            >
              <AiOutlineClose size={24} />
            </button>
            {isLoading ? (
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center space-y-2">
                <AiOutlineLoading3Quarters className="text-4xl animate-spin" />
                <span>Loading Map...</span>
              </div>
            ) : (
              <div id="map" style={{ width: "100%", height: "100%" }}></div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

function RootStatusTable(
  status: rootStatusEntry[],
  data: any,
  handleCoordinateClick: (lat: number, lon: number) => void
): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalSignals, setModalSignals] = useState<Signal[]>([]);
  const { fetchDataRootVessels, fetchDataRootOrgs } = useFetchData();

  useEffect(() => {
    // console.log("running root vessels effect")
    fetchDataRootOrgs();
    fetchDataRootVessels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModalWithSignals = (signals: Signal[]) => {
    setModalSignals(signals);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const statusRows = status.map((statusEntry) => {
    // Time Calculations
    const lastConnection = new Date(statusEntry.timestamp * 1000);
    const now = new Date();
    const diff = Math.abs(now.getTime() - lastConnection.getTime());
    const diffInHours = Math.floor(diff / (1000 * 60 * 60));
    const timeDifference = formatTimeDifference(diff);
    const dataIntegrityStatus = data.rootAccess.dataIntegrityStatus;

    const dataStatusEntry = dataIntegrityStatus.find(
      (entry: { vessel_id: string }) =>
        entry.vessel_id === statusEntry.vessel_id
    );

    const dataStatusWithIcon = dataStatusEntry ? (
      <div className="flex items-center">
        <span className="mr-1 text-sm font-medium text-gray-900">
          {dataStatusEntry.status_code}
        </span>
        <span className="ml-1">
          {getStatusIcon(dataStatusEntry.status_code)}
        </span>
      </div>
    ) : (
      "N/A"
    );

    function formatDate(timestamp: string) {
      const date = new Date(parseInt(timestamp, 10) * 1000); // Convert to milliseconds
      return date.toLocaleString(); // Converts to local date string
    }

    const dataRecordedTimeFormatted = dataStatusEntry?.timestamp
      ? formatDate(dataStatusEntry.timestamp)
      : "N/A";

    function formatBadSignalsList(signals: any[]) {
      return signals.map(
        (
          signal:
            | string
            | number
            | boolean
            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
            | Iterable<React.ReactNode>
            | React.ReactPortal
            | null
            | undefined,
          index: React.Key | null | undefined
        ) => <li key={index}>{signal}</li>
      );
    }

    const badSignalsFormatted = dataStatusEntry?.signals
      ? formatBadSignalsList(dataStatusEntry.signals)
      : "N/A";

    const dataStatus = dataStatusEntry?.status_code || "N/A";
    const dataRecordedTime = dataStatusEntry?.timestamp || "N/A";
    const badSignalsList = dataStatusEntry?.signals || [];
    const viewSignalsButton =
      dataStatusEntry?.signals?.length > 0 ? (
        <button
          onClick={() => openModalWithSignals(dataStatusEntry.signals)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          View Signals
        </button>
      ) : (
        "N/A"
      );

    // Values
    const org_id = statusEntry.org_id;
    const vessel_id = statusEntry.vessel_id;
    const newCoordPrettyStr = (
      <span
        className="cursor-pointer hover:text-blue-500 flex justify-center items-center"
        onClick={() => handleCoordinateClick(statusEntry.lat, statusEntry.lon)}
      >
        {latToPrettyString(statusEntry.lat)},{" "}
        {lngToPrettyString(statusEntry.lon)}
      </span>
    );
    const { status, colorClass, svg } = getStatusAndColor(
      statusEntry.status_code,
      diffInHours
    );
    const statusAndSvg = (
      <div className="flex items-center space-x-2">
        <span>{status}</span>
        {svg}
      </div>
    );
    const org = data.rootAccess.orgs?.find(
      (org: { uid: string }) => org.uid === org_id
    );

    const orgNameWithID = `${org_id} (${org?.name || "N/A"})`;

    // Helper function to get vessel name by ID, matching against 'uid'
    function getVesselNameById(vessels: any[], id: string) {
      if (!vessels || vessels.length === 0) {
        // console.log("Vessel data is not available or empty."); // Debugging log
        return "N/A"; // Handle missing or empty vessels data
      }
      // Adjusting the matching property to 'uid' from 'id'
      const vessel = vessels.find((vessel) => vessel.uid === id);
      return vessel ? vessel.name : "N/A";
    }

    const vesselName = getVesselNameById(
      data.rootAccess.vessels,
      statusEntry.vessel_id
    );
    const vesselNameWithID = `${statusEntry.vessel_id} (${vesselName})`;

    function getColorByTimeDifference(diffInHours: number): string {
      if (diffInHours < 1) {
        return "green";
      } else if (diffInHours < 24) {
        return "orange";
      } else {
        return "red";
      }
    }

    const timeDifferenceColor = getColorByTimeDifference(diffInHours);

    const datastreamCount = statusEntry.datastreams.length;
    const datastreamListDescription =
      datastreamCount > 1
        ? "Click to view Datastream List"
        : datastreamCount > 0
        ? ""
        : "N/A";

    let datastreamList = null;
    let expandedContent = undefined;

    const ihelmVersion = <TextTooltip text={statusEntry.ihelm_version} />;

    if (datastreamCount > 0) {
      datastreamList = (
        <ul className="divide-y divide-gray-200">
          <h3 className="text-lg font-bold text-gray-900 px-3 py-2">
            Datastream List
          </h3>
          {statusEntry.datastreams.map((ds: any) => {
            const { status: dsStatus, svg: dsSvg } = getStatusAndColor(
              ds.status_code,
              diffInHours,
              true
            );
            return (
              <li key={ds.datastream_id} className="py-4 flex">
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-900">
                    Host: {ds.host}
                  </p>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <div className="flex items-center space-x-2">
                      <span>Status: {dsStatus}</span>
                      {dsSvg}
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      );
      if (datastreamListDescription !== "N/A") {
        expandedContent = (
          <tr>
            <td className="mt20" colSpan={4}>
              {datastreamList}
            </td>
          </tr>
        );
      }
    }
    return {
      id: vessel_id,
      contents: [
        orgNameWithID,
        vesselNameWithID,
        statusAndSvg,
        <span style={{ color: timeDifferenceColor }}>{timeDifference}</span>,
        dataStatusWithIcon,
        dataRecordedTimeFormatted,
        viewSignalsButton,
        ihelmVersion,
      ],
      expandedContent: expandedContent,
      color: colorClass,
      status,
      svg,
      onClick: () => handleCoordinateClick(statusEntry.lat, statusEntry.lon),
    };
  });

  const coordinatesTitle = (
    <div className="flex items-center justify-center">
      <span>Coordinates</span>
      <Tooltip
        arrow
        title="Click on the coordinates to see a map"
        placement="top"
      >
        <div
          className="cursor-pointer hover:text-blue-500 flex items-start ml-1 mb-2 text-sm"
          role="tooltip"
          aria-label="Click on the coordinates to see a map"
        >
          <AiOutlineInfoCircle />
        </div>
      </Tooltip>
    </div>
  );
  return (
    <>
      {GenericTableWithIconAndSorting(
        [
          "Organization (Org name)",
          "Vessel (Vessel name)",
          "Status",
          "Last Connection",
          "Data Status",
          "Data Recorded Time",
          "Bad Signals List",
          "iHelm Version",
        ],
        statusRows.length,
        statusRows,
        data === undefined,
        [
          "Organization (Org name)",
          "Vessel (Vessel name)",
          "Status",
          "Last Connection",
          "Data Status",
          "Data Recorded Time",
          "iHelm Version",
        ]
      )}
      <SignalModal
        isOpen={isModalOpen}
        signals={modalSignals}
        onClose={closeModal}
      />
    </>
  );
}
