import ReactDOM from "react-dom";
import { Signal } from "../../../context/variables";
import { useState } from "react";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import {
  AiFillCheckCircle,
  AiFillExclamationCircle,
  AiFillQuestionCircle,
} from "react-icons/ai";

interface SignalModalProps {
  isOpen: boolean;
  signals: Signal[];
  onClose: () => void;
}

function getStatusIcon(statusCode: string) {
  let icon;
  let colorClass;

  switch (statusCode.toUpperCase()) {
    case "GOOD":
      icon = <AiFillCheckCircle />;
      colorClass = "text-green-600";
      break;
    case "BAD":
      icon = <AiFillExclamationCircle />;
      colorClass = "text-red-600";
      break;
    default:
      icon = <AiFillQuestionCircle />;
      colorClass = "text-gray-400";
  }

  return <span className={`inline-block ${colorClass}`}>{icon}</span>;
}

export const SignalModal: React.FC<SignalModalProps> = ({
  isOpen,
  signals,
  onClose,
}) => {
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);
  const [sortColumn, setSortColumn] = useState<keyof Signal | null>(null);

  const sortSignals = (signals: Signal[]): Signal[] => {
    if (!sortOrder || !sortColumn) return signals;

    return [...signals].sort((a, b) => {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];
      if (valueA < valueB) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  const sortedSignals = sortSignals(signals);

  const renderSortIcons = (column: keyof Signal) => {
    return (
      <span className="ml-2 inline-flex flex-col items-center justify-center cursor-pointer">
        <FaAngleUp
          onClick={() => {
            setSortColumn(column);
            setSortOrder("asc");
          }}
          className={
            sortOrder === "asc" && sortColumn === column
              ? "text-blue-500"
              : "text-gray-400"
          }
        />
        <FaAngleDown
          onClick={() => {
            setSortColumn(column);
            setSortOrder("desc");
          }}
          className={
            sortOrder === "desc" && sortColumn === column
              ? "text-blue-500"
              : "text-gray-400"
          }
        />
      </span>
    );
  };

  if (!isOpen) return null;

  const modalContent = (
    <div className="fixed inset-0 z-[10000] overflow-auto bg-gray-800 bg-opacity-25 flex justify-center items-center">
      <div className="relative p-8 bg-white w-full max-w-6xl m-auto flex-col flex rounded-lg shadow-xl">
        <div className="flex justify-between items-center pb-3">
          <p className="text-2xl font-bold text-gray-900">Signal Details</p>
          <button className="modal-close cursor-pointer z-50" onClick={onClose}>
            <span className="text-2xl font-semibold text-gray-900">
              &times;
            </span>
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-900">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100">
              <tr>
                <th scope="col" className="px-6 py-3">
                  <div className="flex items-center">
                    Name <div>{renderSortIcons("name")}</div>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3">
                  <div className="flex items-center ">
                    Description <div>{renderSortIcons("description")}</div>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3">
                  <div className="flex items-center">
                    Status Code <div>{renderSortIcons("status_code")}</div>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              {sortedSignals.map((signal, index) => (
                <tr key={index} className="bg-white border-b">
                  <td className="px-6 py-4">{signal.name}</td>
                  <td className="px-6 py-4">{signal.description}</td>
                  <td className="px-6 py-4 flex items-center">
                    <span className="ml-2 mr-2">{signal.status_code}</span>{" "}
                    {/* Added margin-right */}
                    {getStatusIcon(signal.status_code)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modalContent, document.body);
};
