import React from 'react';

interface MenuAlarmIconProps {
    size?: number;
    color?: string;
}

const MenuAlarmIcon: React.FC<MenuAlarmIconProps> = ({ size = 18, color = 'white' }) => (
    <svg width={size} height={size * (21/18)} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9 21C10.1 21 10.99 20.1307 10.99 19.0563H7.01C7.01 20.1307 7.9 21 9 21ZM16 15.1395V9.27907C16 6.00698 13.64 3.27209 10.5 2.60791V1.46512C10.5 0.654419 9.83 0 9 0C8.17 0 7.5 0.654419 7.5 1.46512V2.60791C4.36 3.27209 2 6.00698 2 9.27907V15.1395L0 17.093V18.0698H18V17.093L16 15.1395Z" fill={color}/>
    </svg>
);

export default MenuAlarmIcon;
