import styled from "styled-components";

export const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  width: 100%;
  background-color: #f3f9fd;
  overflow: hidden;

  @media (max-height: 700px) {
    height: 90vh;
  }
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 474px;
  background-color: #f3f9fd;
  text-align: center;
  padding: 40px;
  border-radius: 28px;
  box-shadow: 0px 11px 23px rgba(0, 0, 0, 0.18);
`;

export const IconContainer = styled.div`
  margin-bottom: 66px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Title = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: #4a4a4a;
  margin-bottom: 12px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
`;

export const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #4a4a4a;
  text-align: center;
  font-family: "Montserrat", sans-serif;
`;
