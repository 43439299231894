import { useEffect, useState } from "react"
import { apiData, rootOrgsEntry, rootVesselsEntry, useAppContext } from "../../../context/variables"
import useFetchData from "../../../apiComms/fetchData"
import Tab from "../../../tabs/Tab"
import { genericTableEditable, genericTableRelatedBits } from "../../../components/Table"
import { makeConfirmModal, makeMenuModal, makeModal } from "../../../components/Modal"
import { addButton, makeSubmitButton } from "../../../components/Button"
import { makeDropdownInput, makeTextInput } from "../../../components/FormInputs"
import useManageData from "../../../apiComms/manageData"
import { TbCursorText } from "react-icons/tb"
import { HiOutlineTrash } from "react-icons/hi"

function rootVesselsTable(vessels: rootVesselsEntry[]|null, orgs: rootOrgsEntry[]|null, setManagedVesselId: (id: string|null)=>void, setManageVesselModalOpen: (open: boolean)=>void): JSX.Element {
  const vesselRows = (vessels === null) ? null : vessels.map((vessel)=>{
    const orgMaybe = orgs?.find((org)=>org.uid === vessel.org_uid)
    const orgName = (orgMaybe === undefined) ? "N/A" : orgMaybe.name
    return {id: vessel.uid, contents: [vessel.uid, vessel.name, vessel.org_uid + " (" + orgName + ")"]}
  })
  function onEditRowClick(uid: string) {
    setManagedVesselId(uid)
    setManageVesselModalOpen(true)
  }
  return genericTableEditable(["uid", "name", "org uid (org name)"], vesselRows, onEditRowClick)
}

function AddVesselModal(addVesselModalOpen: boolean, setAddVesselModalOpen: (open: boolean)=>void, data: apiData, apiPostRootVesselsAdd: (name: string, org_id: string) => void) {
  const [vesselNameWarning, setVesselNameWarning] = useState<string|null>(null)
  const [orgWarning, setOrgWarning] = useState<string|null>(null)

  const orgOptions = (data.rootAccess.orgs === null) ? [] : data.rootAccess.orgs.map((org)=>{return {value: org.uid, name: org.name}})
  const [chosenOrg, setChosenOrg] = useState<string|null>(null)
  const orgChanged = (value: string|null)=>{
    // console.log("value changed to:", value)
    setChosenOrg(value)
  }
  useEffect(() => {
    if(data.rootAccess.orgs !== null && data.rootAccess.orgs.length > 0) {
      orgChanged(data.rootAccess.orgs[0].uid)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.rootAccess.orgs])

  const [confirmAddVesselModalOpen, setConfirmAddVesselModalOpen] = useState(false)

  function clearWarnings() {
    setVesselNameWarning(null)
    setOrgWarning(null)
  }
  function onClose() {
    setAddVesselModalOpen(false)
    clearWarnings()
  }
  function onSubmitClick() {
    // console.log("Hit submit add vessel")
    clearWarnings()
    const theVesselName = (document.getElementById("vesselName") as HTMLInputElement).value
    if(theVesselName.length < 1) { // Not OK: vessel name bad
      // console.log("Vessel name not OK")
      setVesselNameWarning("Vessel name not allowed")
    } else if(chosenOrg === null) { // Not OK: org bad
      // console.log("Org not OK")
      setOrgWarning("Org not OK")
    } else { // OK
      setConfirmAddVesselModalOpen(true)
    }
  }
  function onConfirmClick() {
    // console.log("Hit confirm add vessel")
    const theVesselName = (document.getElementById("vesselName") as HTMLInputElement).value
    if(chosenOrg !== null) {
      // console.log("added a vessel: (name:", theVesselName, ", org:", chosenOrg, ")")
      apiPostRootVesselsAdd(theVesselName, chosenOrg)
    }
    setConfirmAddVesselModalOpen(false)
    onClose()
  }

  const vesselNameField = document.getElementById("vesselName") as HTMLInputElement
  const theVesselName = (vesselNameField !== null) ? vesselNameField.value : "N/A"

  const org = data.rootAccess.orgs?.find((org)=>org.uid === chosenOrg)
  const orgName = org?.name

  const addVesselContents = 
    <div className="flex flex-col space-y-4">
      {makeTextInput("Vessel name:", "The display name of the vessel to be created", " Kobayashi Maru", "vesselName", vesselNameWarning)}
      {makeDropdownInput("Org:", "The org which the vessel belongs to", "Org", chosenOrg, orgOptions, orgChanged, orgWarning)}
      {makeSubmitButton(onSubmitClick)}
      {makeConfirmModal("Confirm adding vessel", 'You are about to add a vessel with the name "'+theVesselName+'" to the org '+chosenOrg+' ('+orgName+')', "Confirm: Add vessel", onConfirmClick, confirmAddVesselModalOpen, setConfirmAddVesselModalOpen)}
    </div>
  return makeModal("Add new vessel", addVesselContents, addVesselModalOpen, onClose, 550, 400)
}

function RenameVesselModal(open: boolean, setOpen: (open: boolean)=>void, managedVesselId: string|null, onCloseManageVesselModal: ()=>void) {
  const [newVesselNameWarning, setNewVesselNameWarning] = useState<string|null>(null)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const { data } = useAppContext()
  const { apiPutRootVesselsRename } = useManageData()
  
  function clearWarnings() {
    setNewVesselNameWarning(null)
  }
  function onClose() {
    setOpen(false)
    clearWarnings()
  }
  function onSubmitClick() {
    // console.log("Hit submit rename vessel")
    clearWarnings()
    const theNewVesselName = (document.getElementById("newVesselName") as HTMLInputElement).value
    if(theNewVesselName.length < 1) { // Not OK: vessel name bad
      // console.log("New vessel name not OK")
      setNewVesselNameWarning("Vessel name not allowed")
    } else { // OK
      setConfirmModalOpen(true)
    }
  }
  function onConfirmClick() {
    // console.log("Hit confirm rename vessel")
    const theNewVesselName = (document.getElementById("newVesselName") as HTMLInputElement).value
    // console.log("renamed the vessel (", managedVesselId, ") to: (", theNewVesselName, ")")
    apiPutRootVesselsRename(managedVesselId, theNewVesselName)
    setConfirmModalOpen(false)
    onClose()
    onCloseManageVesselModal()
  }

  const newVesselNameField = document.getElementById("newVesselName") as HTMLInputElement
  const theNewVesselName = (newVesselNameField !== null) ? newVesselNameField.value : "N/A"

  const oldVessel = data.rootAccess.vessels?.find((vessel)=>vessel.uid === managedVesselId)
  const oldVesselName = oldVessel?.name

  const renameVesselContents = 
    <div className="flex flex-col space-y-4">
      {makeTextInput("New vessel name:", "The new display name of the vessel", " USS Voyager", "newVesselName", newVesselNameWarning, oldVesselName)}
      {makeSubmitButton(onSubmitClick)}
      {makeConfirmModal("Confirm renaming vessel", 'You are about to rename the vessel '+managedVesselId+' from "'+oldVesselName+'" to "'+theNewVesselName+'"', "Confirm: Rename vessel", onConfirmClick, confirmModalOpen, setConfirmModalOpen)}
    </div>
  return makeModal("Rename vessel", renameVesselContents, open, onClose, 600, 250)
}

function DeleteVesselModal(open: boolean, setOpen: (open: boolean)=>void, managedVesselId: string|null, onCloseManageVesselModal: ()=>void) {
  const { data } = useAppContext()
  const { apiDeleteRootVessels } = useManageData()
  const theVesselId = (managedVesselId !== null) ? managedVesselId : "N/A"
  const vessel = data.rootAccess.vessels?.find((vessel)=>vessel.uid === managedVesselId)
  const vesselName = vessel?.name
  function onConfirmClick() {
    // console.log("Hit confirm delete vessel")
    // console.log("deleted the vessel: (", theVesselId, ")")
    apiDeleteRootVessels(theVesselId)
    setOpen(false)
    onCloseManageVesselModal()
  }
  return makeConfirmModal("Confirm deleting vessel", "You are about to delete the vessel "+theVesselId+" ("+vesselName+")", "Delete vessel", onConfirmClick, open, setOpen, true)
}

function ManageVesselModal(open: boolean, setOpen: (open: boolean)=>void, managedVesselId: string|null, setManagedVesselId: (id: string|null)=>void) {
  const [renameVesselModalOpen, setRenameVesselModalOpen] = useState(false)
  const [deleteVesselModalOpen, setDeleteVesselModalOpen] = useState(false)

  const title = (managedVesselId !== null) ? "Manage vessel "+managedVesselId : "Manage vessel"
  const menuOptions = [
    {buttonIcon: <TbCursorText/>, buttonText: "Rename", onClick: ()=>setRenameVesselModalOpen(true)},
    {buttonIcon: <HiOutlineTrash/>, buttonText: "Delete", onClick: ()=>setDeleteVesselModalOpen(true), orangeBg: true},
  ]
  const modals: JSX.Element[] = [
    RenameVesselModal(renameVesselModalOpen, setRenameVesselModalOpen, managedVesselId, onClose),
    DeleteVesselModal(deleteVesselModalOpen, setDeleteVesselModalOpen, managedVesselId, onClose),
  ]
  function onClose() {
    setManagedVesselId(null)
    setOpen(false)
  }
  return makeMenuModal(title, menuOptions, modals, open, onClose, 400, 400)
}

export default function RootVesselsTab() {
  const { data } = useAppContext()
  const { fetchDataRootVessels, fetchDataRootOrgs } = useFetchData()
  const { apiPostRootVesselsAdd } = useManageData()
  useEffect(() => {
    // console.log("running root vessels effect")
    fetchDataRootOrgs()
    fetchDataRootVessels()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [addVesselModalOpen, setAddVesselModalOpen] = useState(false)
  const [manageVesselModalOpen, setManageVesselModalOpen] = useState(false)
  const [managedVesselId, setManagedVesselId] = useState<string|null>(null)
  const root_vessels_content = 
    <div className="flex flex-col space-y-4 pt-4 text-deep_blue">
      {addButton("Add new vessel", ()=>setAddVesselModalOpen(true))}
      {genericTableRelatedBits("Vessels", data.rootAccess.vessels?.length, fetchDataRootVessels)}
      {rootVesselsTable(data.rootAccess.vessels, data.rootAccess.orgs, setManagedVesselId, setManageVesselModalOpen)}
      {AddVesselModal(addVesselModalOpen, setAddVesselModalOpen, data, apiPostRootVesselsAdd)}
      {ManageVesselModal(manageVesselModalOpen, setManageVesselModalOpen, managedVesselId, setManagedVesselId)}
    </div>
  return <Tab title="Vessels" content={root_vessels_content}/>
}
