import { useState } from "react";
import { makeSubmitButton } from "../../../components/Button";
import { makeTextInput } from "../../../components/FormInputs";
import { makeConfirmModal, makeModal } from "../../../components/Modal";
import { useAppContext } from "../../../context/variables";
import useManageData from "../../../apiComms/manageData";

export function RenameDestModal(open: boolean, setOpen: (open: boolean)=>void, managedDestId: string|null, onCloseManageDestModal: ()=>void) {
  const [newDestNameWarning, setNewDestNameWarning] = useState<string|null>(null)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const { data } = useAppContext()
  const { apiPutDestsRename } = useManageData()

  function clearWarnings() {
    setNewDestNameWarning(null)
  }
  function onClose() {
    setOpen(false)
    clearWarnings()
  }
  function onSubmitClick() {
    // console.log("Hit submit rename dest")
    clearWarnings()
    const theNewDestName = (document.getElementById("newDestName") as HTMLInputElement).value
    if(theNewDestName.length < 1) { // Not OK: dest name bad
      // console.log("New dest name not OK")
      setNewDestNameWarning("Please enter a name")
    } else { // OK
      setConfirmModalOpen(true)
    }
  }
  function onConfirmClick() {
    // console.log("Hit confirm rename dest")
    const dest = data.org.destinationList.val?.find((dest)=>dest.lid === managedDestId)
    // const oldDestName = dest?.displayName
    const orgId = dest?.orgUid
    const theNewDestName = (document.getElementById("newDestName") as HTMLInputElement).value
    // console.log('renamed the destination '+managedDestId+' in the org '+orgId+' from "'+oldDestName+'" to "'+theNewDestName+'"')
    apiPutDestsRename(orgId, managedDestId, theNewDestName)
    setConfirmModalOpen(false)
    onClose()
    onCloseManageDestModal()
  }

  const title = "Rename destination"

  const dest = data.org.destinationList.val?.find((dest)=>dest.lid === managedDestId)
  const oldDestName = dest?.displayName

  const newDestNameField = document.getElementById("newDestName") as HTMLInputElement
  const theNewDestName = (newDestNameField !== null) ? newDestNameField.value : "N/A"

  const prompt = (oldDestName !== undefined) ? oldDestName : " Tortuga"

  const content = 
    <div className="flex flex-col space-y-4">
      {makeTextInput("New destination name:", "How the destination will be referred to throughout iHelm", prompt, "newDestName", newDestNameWarning, oldDestName)}
      {makeSubmitButton(onSubmitClick)}
      {makeConfirmModal("Confirm renaming destination", 'You are about to rename a destination from "'+oldDestName+'" to "'+theNewDestName+'"', "Confirm: Rename destination", onConfirmClick, confirmModalOpen, setConfirmModalOpen)}
    </div>
  return makeModal(title, content, open, onClose)
}
