import React from "react"
import Page from "./Page"

export default function ErrorPage() {
  const error_title = ""
  const error_content = 
    // <main className="p-4 h-full flex justify-center items-center">
    //   <h2 className="text-high_orange py-20 h-full text-5xl">There is no page here!</h2>
    // </main>
    <div className="min-w-fit flex justify-center text-black text-3xl">
      <div className="w-[37.5rem] flex flex-col justify-start items-center">
        <div className="text-deep_blue font-bold text-[64px] pt-6">{"Error: Page not found"}</div>
        <div className="text-black/50 text-4xl pt-8 pb-32">{"There is no content at this location!"}</div>
        {/* <div className="relative h-[400px]">
          <img src={blob} className="pt-[34px]" alt="blob"/>
          <img src={cartoon} className="absolute top-0 left-[172px]" alt="cartoon"/>
        </div> */}
      </div>
    </div>
  return (
    <Page title={error_title} content={error_content}/>
  )
}
