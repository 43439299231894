import { styled } from "styled-components";

export const StyledButton = styled.button<{
  backgroundcolor: string;
  color: string;
}>`
  background: ${({ backgroundcolor }) => backgroundcolor};
  color: ${({ color }) => color};
  padding: 0.625rem 1.25rem;
  border: none;
  border-radius: 0.625rem;
  cursor: pointer;
  margin-right: 0.625rem;
`;
