import { makeSubmitButton } from "../../../components/Button"
import { makeConfirmModal, makeModal } from "../../../components/Modal"
import { makeTextInput, makeTextOutput } from "../../../components/FormInputs"
import { coords, coordsLindholmspiren, initializeMap, latToPrettyString, lngToPrettyString, makeLatLng, mapIconRedballoon } from "../../../components/Map"
import { iconButton } from "../../../components/Button";
import { HiPencil } from "react-icons/hi";
import { DestinationEdgeType } from "../../../context/types";
import useManageData from "../../../apiComms/manageData";
import { useEffect, useState } from "react";
import { useAppContext } from "../../../context/variables";
import { Tooltip } from "@mui/material";

const defaultDestCoords = coordsLindholmspiren
const defaultDestRadius = 40
function simplePolyAroundCoords(coords: coords): coords[] {
  return [
    {lat: coords.lat + 0.001, lng: coords.lng },
    {lat: coords.lat, lng: coords.lng + 0.001 },
    {lat: coords.lat - 0.001, lng: coords.lng },
    {lat: coords.lat, lng: coords.lng - 0.001 },
  ]
}
const defaultDestPoly: coords[] = simplePolyAroundCoords(defaultDestCoords)
const defaultDestZoom = 15

let destMarker: google.maps.Marker|undefined
let destCircle: google.maps.Circle|undefined
let destPoly: google.maps.Polygon|undefined
function EditGeographicalDestMap(
  map: google.maps.Map|undefined, 
  setMap: (map: google.maps.Map|undefined)=>void, 
  modalOpen: boolean, 
  coords: coords, 
  setCoords: (coords: coords)=>void, 
  setRadius: (radius: number)=>void, 
  destEdgeType: DestinationEdgeType, 
  setPolyLength: (length: number)=>void, 
  setMarkerInPoly: (isInside: boolean)=>void, 
  closeWritingFields: ()=>void, 
  managedDestId: string|null, 
  setDestEdgeType: (edgeType: DestinationEdgeType)=>void
) {
  const { data } = useAppContext()
  const [mapInitialized, setMapInitialized] = useState(false)
  const mapElementId = "edit_geo_dest_map"

  useEffect(()=>{
    if(modalOpen) {
      initializeMap(map, setMap, mapElementId, mapInitCustom, setMapInitialized, defaultDestCoords, defaultDestZoom)
    } else {
      setMapInitialized(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen])

  function moveEverythingToPreDest() {
    const anExistingDest = data.org.destinationList.val?.find((dest)=>dest.lid === managedDestId)
    if(anExistingDest !== undefined && mapInitialized) {
      const preCoords = makeLatLng(anExistingDest.latitude, anExistingDest.longitude)
      // console.log("Using coords:", preCoords)
      setCoords(preCoords)
      // console.log("anExistingDest:", anExistingDest)
      const destEdgeType = (anExistingDest.polygon !== null) ? DestinationEdgeType.Polygon : DestinationEdgeType.Circle
      setDestEdgeType(destEdgeType)
      destCircle?.setCenter(preCoords)
      const path = (anExistingDest.polygon !== null) ? anExistingDest.polygon : simplePolyAroundCoords(preCoords)
      // console.log("edge, path:", destEdgeType, path, destPoly)
      destPoly?.setPath(path)
      const radius = (anExistingDest.radius !== null) ? anExistingDest.radius : defaultDestRadius
      destCircle?.setRadius(radius)
      setRadius(radius)
      setPolyLength(path.length)
      setMarkerInPoly(true)
      // console.log("map is:", map)
      if(map) {
        // console.log("centering map")
        map.setCenter(preCoords)
      }
    }
  }

  useEffect(()=>{
    if(mapInitialized) moveEverythingToPreDest()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managedDestId, mapInitialized])

  function checkIfMarkerIsInPoly() {
    const markerPosNow = destMarker?.getPosition()
    if(destPoly !== undefined && markerPosNow !== undefined && markerPosNow !== null) {
      const markerInPoly = google.maps.geometry.poly.containsLocation(markerPosNow, destPoly)
      // console.log("markerInPoly:", markerPosNow?.lat(), markerPosNow?.lng(), destPoly.getPath(), markerInPoly)
      setMarkerInPoly(markerInPoly)
    }
  }

  useEffect(()=>{ // Tracking polygon length
    const length = destPoly?.getPath().getLength()
    // console.log("vertex counting effect, new length:", length)
    if(length !== undefined) {
      setPolyLength(length)
    }
    destPoly?.getPath().addListener("insert_at", ()=>{
      const length = destPoly?.getPath().getLength()
      // console.log("insert_at, new length:", length)
      if(length !== undefined) {
        setPolyLength(length)
      }
      checkIfMarkerIsInPoly()
      closeWritingFields()
    })
    destPoly?.getPath().addListener("remove_at", ()=>{
      const length = destPoly?.getPath().getLength()
      // console.log("remove_at, new length:", length)
      if(length !== undefined) {
        setPolyLength(length)
      }
      checkIfMarkerIsInPoly()
      closeWritingFields()
    })
    destPoly?.getPath().addListener("set_at", ()=>{
      // console.log("set_at")
      checkIfMarkerIsInPoly()
      closeWritingFields()
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destPoly, destEdgeType])

  function mapInitCustom(map: google.maps.Map) {
    // console.log("mapInitCustom")
    destMarker = new google.maps.Marker({
      draggable: true,
      map: map,
      position: coords,
      icon: mapIconRedballoon(),
    })
    destMarker.addListener("position_changed", ()=>{
      const pos = destMarker?.getPosition()
      if(pos !== null && pos !== undefined) {
        setCoords(makeLatLng(pos.lat(), pos.lng()))
      }
      checkIfMarkerIsInPoly()
      closeWritingFields()
    })
    destCircle = new google.maps.Circle({
      map: map,
      center: coords,
      radius: defaultDestRadius,
      strokeColor: "#ff4d00",
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: "#ff4d00",
      fillOpacity: 0.5,
      draggable: false,
      editable: true,
    })
    destCircle.addListener("radius_changed", ()=>{
      const rad = destCircle?.getRadius()
      // console.log("radius listener fired:", rad)
      if(rad !== undefined) {
        setRadius(rad)
      }
      closeWritingFields()
    })
    destCircle.bindTo("center", destMarker, "position")
    destPoly = new google.maps.Polygon({
      map: map,
      paths: defaultDestPoly,
      strokeColor: "#ff4d00",
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: "#ff4d00",
      fillOpacity: 0.5,
      editable: true,
      draggable: true,
      geodesic: true,
    })
    destPoly.addListener("dblclick", (mev: google.maps.PolyMouseEvent)=>{
      const length = destPoly?.getPath().getLength()
      // console.log("poly dblclick event", mev, typeof mev, mev.vertex, length)
      if(mev.vertex != null && length !== undefined && length > 3) {
        destPoly?.getPath().removeAt(mev.vertex)
      }
      closeWritingFields()
    })
  }

  if(mapInitialized) {
    if(destEdgeType === DestinationEdgeType.Circle) {
      destCircle?.setVisible(true)
      destPoly?.setVisible(false)
    } else {
      destCircle?.setVisible(false)
      destPoly?.setVisible(true)
    }
  }

  return <div id={mapElementId} className="w-full h-full rounded-xl text-black bg-[#8c8c8c] border-2 shadow-[4px_4px_8px_2px_rgba(0,0,0,0.1)]"/>
}

export function EditGeographicalDestModal(open: boolean, setOpen: (open: boolean)=>void, managedDestId: string|null, onCloseManageDestModal: ()=>void) {
  const { data } = useAppContext()
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const { apiPutDestsLocation } = useManageData()
  const destinationList = data.org.destinationList.val
  const destMaybe = destinationList?.find((dest)=>dest.lid === managedDestId)
  const destName = (destMaybe === undefined) ? "N/A" : destMaybe.displayName

  // ----- Warnings -----
  const [coordsWarning, setCoordsWarning] = useState<string|null>(null)
  const [radiusWarning, setRadiusWarning] = useState<string|null>(null)

  // ----- Standard modal bits -----
  function clearWarnings() {
    setCoordsWarning(null)
    setRadiusWarning(null)
  }
  function resetValues() {
    setCoords(defaultDestCoords)
    setWritingCoords(false)
    setDestEdgeType(DestinationEdgeType.Circle)
    setRadius(defaultDestRadius)
    setWritingRadius(false)
  }
  function onClose() {
    setOpen(false)
    clearWarnings()
    resetValues()
  }
  function onSubmitClick() {
    // console.log("Hit submit edit geo destination")
    clearWarnings()
    closeWritingFields()
    const roundedRadius = Math.round(radius)
    if(destEdgeType === DestinationEdgeType.Circle && roundedRadius < 10) {
      // console.log("Submit warning: Radius too low")
      // radius warning already set
    } else if(destEdgeType === DestinationEdgeType.Polygon && (polyLength < 3 || polyLength > 12)) {
      // console.log("Submit warning: Too few/many polygon vertices")
      // poly count warning already set or should be impossible
    } else if(destEdgeType === DestinationEdgeType.Polygon && !markerInPoly) {
      // console.log("Submit warning: Marker not inside polygon")
      // marker not in poly warning already set
    } else { // OK
      setConfirmModalOpen(true)
    }
  }
  function onConfirmClick() {
    // console.log("Hit confirm edit geo destination")
    const roundedRadius = Math.round(radius)
    const polyPath = destPoly?.getPath()
    const polyArray = polyPath?.getArray()
    const polyCoordss = polyArray?.map((point)=>makeLatLng(point.lat(), point.lng()))
    // console.log("edited location data of a dest: (coords:", coords, 
    //   ", shape:", destEdgeType, 
    //   ", radius:", roundedRadius, 
    //   ", poly:", polyCoordss, 
    //   ")")
    const orgMaybe = (destMaybe === undefined) ? null : destMaybe.orgUid
    apiPutDestsLocation(orgMaybe, managedDestId, coords, destEdgeType, roundedRadius, polyCoordss)
    // apiPostDestsAdd(theDestName, coords, destEdgeType, roundedRadius, polyCoordss)
    setConfirmModalOpen(false)
    onClose()
    onCloseManageDestModal()
  }

  // ----- Map -----
  const [map, setMap] = useState<google.maps.Map|undefined>(undefined)

  // ----- Coordinates -----
  const coordsTitle = "Coordinates (lat, long):"
  const coordsTooltip = 'The coordinates of the destination. Edit in the map by moving the pin, or by typing lat & long (as one number each, use "." for decimals, use negative values for °S and °W)'// (a latitude between -90 and 90 and a longitude between -180 and 180)"
  const [coords, setCoords] = useState(defaultDestCoords)
  const newCoordPrettyStr = latToPrettyString(coords.lat)+", "+lngToPrettyString(coords.lng)
  const [writingCoords, setWritingCoords] = useState(false)
  const coordsReadComp = 
    <div className="flex space-x-2 items-center">
      {makeTextOutput(coordsTitle, coordsTooltip, newCoordPrettyStr, null)}
      <Tooltip title="Edit by typing" placement="right">
        <div className="cursor-pointer" key={"edit"} onClick={()=>{
          setWritingCoords(true)
          setWritingRadius(false)
        }}>
          <HiPencil className="text-2xl"/>
        </div>
      </Tooltip>
    </div>
  const emptyCoordsWarning = (coordsWarning === null) ? null : ""
  const coordsWriteComp = 
    <div className="flex space-x-2 items-center text-xl">
      {makeTextInput(coordsTitle, coordsTooltip, "° Lat", "newDestWriteLat", emptyCoordsWarning, coords.lat.toString(), "Small")}
      <div className="flex space-x-6 justify-start items-center text-xl">{","}</div>
      {makeTextInput(null, null, "° Long", "newDestWriteLng", coordsWarning, coords.lng.toString(), "Small")}
      {iconButton("Set", <></>, ()=>{
        const writeLatStr = (document.getElementById("newDestWriteLat") as HTMLInputElement).value
        const writeLat = Number(writeLatStr)
        const writeLngStr = (document.getElementById("newDestWriteLng") as HTMLInputElement).value
        const writeLng = Number(writeLngStr)
        // console.log("coord inputs:", writeLat, writeLng)
        if(writeLatStr.length < 1 || writeLngStr.length < 1) {
          setCoordsWarning("Please enter a latitude and a longitude")
        } else if(isNaN(writeLat) || isNaN(writeLng)) {
          setCoordsWarning("Coordinates must be numerical")
        } else if(writeLat > 90 || writeLat < -90 || writeLng > 180 || writeLng < -180) {
          setCoordsWarning("Coordinates must be within possible bounds")
        } else {
          const writeCoords = makeLatLng(writeLat, writeLng)
          destCircle?.setCenter(writeCoords)
          map?.setCenter(writeCoords)
          setCoords(writeCoords)
          setCoordsWarning(null)
          setWritingCoords(false)
        }
      })}
    </div>
  const coordsComp = writingCoords ? coordsWriteComp : coordsReadComp

  // ----- Edge type -----
  const [destEdgeType, setDestEdgeType] = useState(DestinationEdgeType.Circle)
  // function manualChangeDestEdge(edgeType: DestinationEdgeType) {
  //   setDestEdgeType(edgeType)
  //   destPoly?.setPath(simplePolyAroundCoords(coords))
  //   setMarkerInPoly(true)
  // }
  // const destinationEdgeTypeOptions: {value: DestinationEdgeType, name: string}[] = Object.values(DestinationEdgeType).map((level)=>{
  //   return {value: level, name: level}
  // })
  // const edgeTypePicker = makeRadioInput(
  //   "Edge shape:", 
  //   "How to define the edge of what counts as the destination", 
  //   destEdgeType, 
  //   destinationEdgeTypeOptions, 
  //   manualChangeDestEdge
  // )

  // ----- Radius -----
  const radiusTitle = "Radius:"
  const radiusTooltip = "The radius of the circular edge of what counts as the destination. Edit in the map or by typing. Must be at least 10 m"
  const [radius, setRadius] = useState(defaultDestRadius)
  const roundedRadius = Math.round(radius)
  const [writingRadius, setWritingRadius] = useState(false)
  const readRadiusWarning = (roundedRadius < 10) ? "Radius under 10 m" : null
  const readRadiusField = 
    <div className="flex space-x-2 items-center">
      {makeTextOutput(radiusTitle, radiusTooltip, roundedRadius+" meters", readRadiusWarning)}
      <Tooltip title="Edit by typing" placement="right">
        <div className="cursor-pointer" key={"edit"} onClick={()=>{
          setWritingRadius(true)
          setWritingCoords(false)
        }}>
          <HiPencil className="text-2xl"/>
        </div>
      </Tooltip>
    </div>
  const writeRadiusField = 
    <div className="flex space-x-2 items-center">
      {makeTextInput(radiusTitle, radiusTooltip, defaultDestRadius.toString(), "newDestWriteRadius", radiusWarning, roundedRadius.toString(), "Small")}
      <div className="flex space-x-6 justify-start items-center text-xl">{"meters"}</div>
      {iconButton("Set", <></>, ()=>{
        const writeRadiusStr = (document.getElementById("newDestWriteRadius") as HTMLInputElement).value
        const writeRadius = Number(writeRadiusStr)
        // console.log("writeRadius:", writeRadiusStr, writeRadius, typeof writeRadius)
        if(writeRadiusStr.length < 1) {
          setRadiusWarning("Please enter a number")
        } else if(isNaN(writeRadius)) {
          setRadiusWarning("Radius must be numerical")
        } else if(writeRadius < 10) {
          setRadiusWarning("Radius under 10 m")
        } else {
          destCircle?.setRadius(writeRadius)
          setRadiusWarning(null)
          setWritingRadius(false)
        }
      })}
    </div>
  const radiusField = writingRadius ? writeRadiusField : readRadiusField

  // ----- Polygon -----
  const [polyLength, setPolyLength] = useState(defaultDestPoly.length)
  const [markerInPoly, setMarkerInPoly] = useState(true)
  const polygonField = makeTextOutput(
    "Polygon:", 
    "The edge of what counts as the destination. Edit in the map, remove a point by double-clicking it. Must have between 3 and 12 corners", 
    polyLength+" corners, see map", 
    (polyLength > 12) ? "Maximum of 12 corners allowed" : (!markerInPoly) ? "Coordinates must be within polygon" : null
  )

  // ----- The confirmation modal -----
  const confirmTextEdgePart = 
    destEdgeType === DestinationEdgeType.Circle ? 
    'Circle with a radius of '+roundedRadius+' meters'
    : 'Polygon with '+polyLength+' corners'
  const confirmText = [
    'You are about to set the location of "'+destName+'" to:',
    '- Coordinates: '+newCoordPrettyStr,
    '- Shape: '+confirmTextEdgePart,
  ]
  const confirmModal = makeConfirmModal(
    'Confirm editing location', 
    confirmText, 
    "Confirm: Set location", 
    onConfirmClick, 
    confirmModalOpen, 
    setConfirmModalOpen
  )

  // ----- Putting together the modal -----
  function closeWritingFields() {
    setWritingCoords(false)
    setWritingRadius(false)
  }
  useEffect(()=>{
    closeWritingFields()
  }, [destEdgeType])

  const title = (managedDestId !== null) ? 'Edit location of "'+destName+'"' : "Edit location of destination"

  const editGeographicalDestContents =
    <div className="flex space-x-6">
      <div className="flex flex-col space-y-8 w-[512px]">
        {coordsComp}
        {/* {edgeTypePicker} */}
        {destEdgeType === DestinationEdgeType.Circle ? radiusField : polygonField}
        {makeSubmitButton(onSubmitClick)}
        {confirmModal}
      </div>
      <div className="w-[36rem] h-[36rem]">
        {EditGeographicalDestMap(map, setMap, open, coords, setCoords, setRadius, destEdgeType, setPolyLength, setMarkerInPoly, closeWritingFields, managedDestId, setDestEdgeType)}
      </div>
    </div>
  return makeModal(title, editGeographicalDestContents, open, onClose)
}
