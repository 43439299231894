import React from "react"
import Tab from "./Tab"

export default function ErrorTab() {
  const error_title = ""
  const error_content = 
    <div className="min-w-fit flex justify-center text-black text-3xl">
      <div className="w-[37.5rem] flex flex-col justify-start items-center">
        <div className="text-deep_blue font-bold text-[64px] pt-6">{"Error: Tab not found"}</div>
        <div className="text-black/50 text-4xl pt-8 pb-32">{"There is no content at this location!"}</div>
      </div>
    </div>
  return (
    <Tab title={error_title} content={error_content}/>
  )
}
