import React, { useEffect, useState } from "react";
import AlarmTable from "../../components/organisms/AlarmTable";
import Pagination from "../../components/atoms/Pagination";
import PageContainer from "../../components/organisms/PageContainer";
import LoadingSpinner from "../../components/atoms/LoadingSpinner";
import AddComponent from "../../components/molecules/AddComponent";
import EmptyState from "../../components/atoms/EmptyState";
import AlarmIcon from "../../assets/icons/EmptyStates/AlarmIcon";
import useFetchData from "../../apiComms/fetchData";
import useManageData from "../../apiComms/manageData";
import {
  DeleteAlarmEventPayload,
  useAppContext,
} from "../../context/variables";
import { useConfirmActionModal, useModal } from "../../hooks";
import { ConfirmActionModal } from "../../components/molecules";
import { ApiResponseAxios } from "../../types";
import { getVesselId } from "../../helpers/vesselHelpers";
import { DeleteButton } from "../../components/atoms";

const CustomizedTab: React.FC = () => {
  const { vars, data } = useAppContext();
  const {
    fetchCustomAlarmTypes,
    fetchDataOrganisations,
    fetchDataSignalTypes,
  } = useFetchData();
  const { addCustomAlarm, apiDeleteCustomAlarms } = useManageData();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false); 
  const [selectedGeoZone, setSelectedGeoZone] = useState<string>("");
  const [selectedRows, setSelectedRows] = useState<DeleteAlarmEventPayload[]>(
    []
  );
  const [showEmptyState, setShowEmptyState] = useState(true);
  const rowsPerPage = 10;

  const { isOpen, closeModal, openModal } = useModal();
  const {
    isSuccess,
    setIsSuccess,
    isLoading: isModalLoading,
    handleConfirm,
    hasFeedback,
    setHasFeedback,
  } = useConfirmActionModal();

  const orgId = vars.auth.organisationId;

  const vessels = data.org.vesselList;

  const alarmLevelOptions = [
    { value: "low", label: "Low" },
    { value: "medium", label: "Medium" },
    { value: "high", label: "High" },
  ];
  const fetchInitialData = async () => {
    await fetchDataOrganisations();
    await fetchCustomAlarmTypes();
    await fetchDataSignalTypes();
    setLoading(false);
    setDataLoaded(true); // Set data load completion to true
  };

  useEffect(() => {
    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.idToken, vars.auth.organisationId]);

  useEffect(() => {
    if (isSuccess && !isOpen) {
      setLoading(true);
      fetchInitialData();
      setSelectedRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isOpen]);

  const handleSave = async (
    data: { [key: string]: any },
    resetForm: () => void
  ) => {
    console.log("Saving alarm with data:", data);

    // Parse the condition string to extract parameter, comparison, and value
    const condition = JSON.parse(data.condition);

    // Map comparison to the correct operator
    const operatorMapping: { [key: string]: string } = {
      higher: ">",
      lower: "<",
    };

    const alarmLevelMapping: { [key: string]: string } = {
      low: "Low",
      medium: "Medium",
      high: "High",
    };

    const formattedAlarmLevel = alarmLevelMapping[data.alarmLevel];
    const formattedOperator = operatorMapping[condition.comparison];
    const formattedThreshold = parseFloat(condition.value);

    if (isNaN(formattedThreshold)) {
      console.error("Threshold must be a numeric value");
      return;
    }

    try {
      setLoading(true);
      await addCustomAlarm(
        data.vessel,
        condition.parameter,
        data.alarmName,
        formattedAlarmLevel,
        formattedOperator,
        formattedThreshold.toString(),
        selectedGeoZone,
        data.vessel
      );
      await fetchCustomAlarmTypes();
      resetForm();
      setShowEmptyState(false);
    } finally {
      setLoading(false);
    }
  };

  const alarmData = (data.alarmTypes.alarmTypeList.val || []).map((event) => {
    const alarmTypeLabel =
      data.signalTypes.signalTypeList.val?.find(
        (option) => option.value === event.alarmType
      )?.label || event.alarmType;
    const conditionText = `${alarmTypeLabel} ${
      event.operator === "<" ? "lower" : "higher"
    } than ${event.threshold}`;
    const geoZoneName =
      data.org.geoZoneList?.val?.find((zone) => zone.gz_id === event.geoZone)
        ?.name || "N/A";

    return {
      id: event.id,
      vesselName: event.vesselName,
      alarmName: event.alarmName,
      date: event.date,
      alarmLevel: event.alarmLevel,
      geoZone: geoZoneName,
      condition: conditionText,
    };
  });

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentData = alarmData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(alarmData.length / rowsPerPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const onCheckedRow = (id: string, vesselName: string) => {
    const vesselId = getVesselId(vesselName, vessels);

    if (selectedRows.some((row) => row.alarm_id === id)) {
      return setSelectedRows(selectedRows.filter((row) => row.alarm_id !== id));
    } else {
      return setSelectedRows((prev) => [
        ...prev,
        { alarm_id: id, vessel_id: vesselId, org_id: orgId || "" },
      ]);
    }
  };

  const handleDeleteCustomAlarms = async (): Promise<ApiResponseAxios> => {
    return await apiDeleteCustomAlarms(selectedRows);
  };

  const onConfirmAction = async () => {
    await handleConfirm(handleDeleteCustomAlarms);
  };

  const content = (
    <div
      className="flex flex-col items-center justify-between w-full h-full"
      data-testid="customized-tab"
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="w-full">
            {dataLoaded && alarmData.length === 0 && showEmptyState ? (
              <EmptyState
                icon={<AlarmIcon />}
                title="No alarm has been added"
                description="Come back later or configure new alarms"
                buttonText="Add alarm"
                onButtonClick={() => setShowEmptyState(false)}
              />
            ) : (
              <>
                <AddComponent
                  onSave={handleSave}
                  fields={[
                    {
                      label: "Enter an alarm name",
                      name: "alarmName",
                      type: "text",
                      placeholder: "Enter an alarm name",
                    },
                    {
                      label: "Select vessel",
                      name: "vessel",
                      type: "select",
                      placeholder: "Select vessel",
                    },
                    {
                      label: "Select alarm level",
                      name: "alarmLevel",
                      type: "select",
                      placeholder: "Select alarm level",
                    },
                    {
                      label: "Select alarm type",
                      name: "alarmType",
                      type: "select",
                      placeholder: "Select alarm type",
                    },
                    {
                      label: "Enter value",
                      name: "threshold",
                      type: "text",
                      placeholder: "Enter value",
                    },
                    {
                      label: "Select comparison",
                      name: "comparison",
                      type: "select",
                      options: [
                        { value: "<", label: "Lower than" },
                        { value: ">", label: "Higher than" },
                      ],
                      placeholder: "Select comparison",
                    },
                  ]}
                  options={{
                    alarmTypeOptions: data.signalTypes?.signalTypeList?.val || [],
                    alarmLevelOptions,
                  }}
                  includeVesselDropdown={true}
                  apiKey={vars.auth.idToken as string}
                  vars={vars}
                  selectedGeoZone={selectedGeoZone}
                  setSelectedGeoZone={setSelectedGeoZone}
                />
                {alarmData.length > 0 && (
                  <>
                    <AlarmTable
                      data={currentData}
                      showActions={false}
                      showCheckbox={true}
                      additionalColumns={[
                        { name: "Geo Zone", key: "geoZone" },
                        { name: "Conditions", key: "condition" },
                      ]}
                      disableSorting={true}
                      isCustomizedTab={true}
                      selectedRows={selectedRows}
                      onCheckedRow={onCheckedRow}
                    />
                    <div className="mt-4 flex justify-end">
                      <DeleteButton
                        onClick={openModal}
                        additionalText={`Alarm (${selectedRows.length})`}
                        disabled={selectedRows.length === 0}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          {dataLoaded && alarmData.length > 0 && (
            <div className="mt-4 w-full flex justify-center">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </>
      )}
      <ConfirmActionModal
        isOpen={isOpen}
        isSuccessful={isSuccess}
        message="Do you wish to delete these alarms permanently?"
        feedbackMessage={{
          success: "Alarms deleted successfully.",
          error: "Failed to delete one or more alarms",
        }}
        isLoading={isModalLoading}
        hasFeedback={hasFeedback}
        onConfirm={onConfirmAction}
        closeModal={closeModal}
        setIsSuccessful={setIsSuccess}
        setHasFeedback={setHasFeedback}
        theme="dark"
      />
    </div>
  );

  return <PageContainer>{content}</PageContainer>;
};

export default CustomizedTab;
