import { useEffect, useRef } from "react";
import { coordsGothenburgKindaCentral } from "../../../components/Map";
import { SecondaryTripsMapProps } from "../../../context/variables";
import { Loader } from "@googlemaps/js-api-loader";

export default function SecondaryTripsMap({
  secondaryTripData,
}: SecondaryTripsMapProps) {
  const mapRef = useRef<google.maps.Map | null>(null);
  const markerRef = useRef<google.maps.Marker | null>(null);
  const tripPolylineRef = useRef<google.maps.Polyline | null>(null);

  useEffect(() => {
    const loader = new Loader({
      apiKey: "AIzaSyAFrWFda9joNnyn20PXgFWACROHq8Hwhxk",
      version: "weekly",
      libraries: ["geometry"],
    });

    loader
      .load()
      .then(() => {
        const circleIcon: google.maps.Symbol = {
          path: google.maps.SymbolPath.CIRCLE,
          fillOpacity: 1,
          fillColor: "white",
          strokeWeight: 0,
          scale: 5,
        };
        const tripPath = secondaryTripData.lats.map((lat, index) => {
          return {
            lat: Number(lat),
            lng: Number(secondaryTripData.lons[index]),
          };
        });

        const initialCoords =
          secondaryTripData?.lats?.length > 0
            ? {
                lat: Number(
                  secondaryTripData.lats[secondaryTripData.lats.length - 1]
                ),
                lng: Number(
                  secondaryTripData.lons[secondaryTripData.lons.length - 1]
                ),
              }
            : coordsGothenburgKindaCentral;

        const boatPolyline = new google.maps.Polyline({
          path: [
            { lat: 22.291, lng: 153.027 },
            { lat: 18.291, lng: 153.027 },
          ],
          map: mapRef.current,
          visible: true,
          strokeColor: "#ffffff",
          strokeOpacity: 0,
          icons: [
            {
              icon: circleIcon,
              offset: "0",
              repeat: "20px",
            },
          ],
          zIndex: 1,
        });

        let count = 0;
        window.setInterval(() => {
          count = (count + 1) % 200;
          const icons = boatPolyline.get("icons");
          icons[0].offset = count / 2 + "%";
          boatPolyline.set("icons", icons);
        }, 20);

        const mapOpts = {
          center: initialCoords,
          zoom: 10,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          mapId: "d731787bb76c1393",
          clickableIcons: false,
          disableDefaultUI: true,
          fullscreenControl: true,
          zoomControl: true,
          scaleControl: true,
          keyboardShortcuts: false,
        };

        const mapElement = document.getElementById("map_canvas");
        if (mapElement && !mapRef.current) {
          mapRef.current = new google.maps.Map(mapElement, mapOpts);
        }

        if (
          secondaryTripData?.lats?.length &&
          secondaryTripData?.lons?.length
        ) {
          const lastCoord = {
            lat: Number(
              secondaryTripData.lats[secondaryTripData.lats.length - 1]
            ),
            lng: Number(
              secondaryTripData.lons[secondaryTripData.lons.length - 1]
            ),
          };

          // Remove old marker
          if (markerRef.current) {
            markerRef.current.setMap(null);
          }

          // Add the marker for vessel's current location
          markerRef.current = new google.maps.Marker({
            position: lastCoord,
            map: mapRef.current,
            icon: circleIcon,
          });

          // Remove old polyline
          if (tripPolylineRef.current) {
            tripPolylineRef.current.setMap(null);
          }

          // Create and add the polyline for the vessel's trip
          tripPolylineRef.current = new google.maps.Polyline({
            path: tripPath,
            geodesic: true,
            strokeColor: "#ffffff",
            strokeOpacity: 1.0,
            strokeWeight: 2,
          });
          tripPolylineRef.current.setMap(mapRef.current);

          if (mapRef.current) {
            mapRef.current.setCenter(lastCoord);
          }
        }

        // Force the map to redraw itself, to prevent it from being blank when changing rows
        if (mapRef.current) {
          google.maps.event.trigger(mapRef.current, "resize");
        }
      })
      .catch((err) => {
        console.error("Error loading Google Maps:", err);
      });

    return () => {
      if (markerRef.current) {
        markerRef.current.setMap(null);
      }
      if (tripPolylineRef.current) {
        tripPolylineRef.current.setMap(null);
      }
      mapRef.current = null;
    };
  }, []);

  return (
    <div
      id="map_canvas"
      className="w-1/3 h-[30rem] rounded-xl text-black bg-[#8c8c8c] border-2 shadow-[4px_4px_8px_2px_rgba(0,0,0,0.1)]"
    />
  );
}
