import { makeSubmitButton } from "../../../components/Button";
import { makeConfirmModal, makeModal } from "../../../components/Modal";
import {
  getTextFieldValueById,
  makeDropdownInput,
  makeInput,
  makeRadioInput,
  makeTextInput,
  makeTextInputSmall,
  makeTextOutput,
} from "../../../components/FormInputs";
import {
  coordinates,
  coords,
  coordsLindholmspiren,
  initializeMap,
  latToPrettyString,
  lngToPrettyString,
  makeLatLng,
  mapIconRedballoon,
} from "../../../components/Map";
import { iconButton } from "../../../components/Button";
import { HiPencil } from "react-icons/hi";
import {
  GeoZoneEdgeType,
  EntityState,
  accessLevelAtMinimum,
  AccessLevel,
} from "../../../context/types";
import { strNullToString } from "../../../helpers/stringHelpers";
import useManageData from "../../../apiComms/manageData";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import {
  EditGeoZoneModalProps,
  geoZonesEntry,
  textEntryWarningTextProps,
  useAppContext,
} from "../../../context/variables";
import { Tooltip } from "@mui/material";
import React from "react";
import LoadingSpinner from "../../../components/atoms/LoadingSpinner";
import useFetchData from "../../../apiComms/fetchData";

const defaultGeoZonesCoords = coordsLindholmspiren;
const defaultGeoZonesRadius = 40;
function simplePolyAroundCoords(coords: coords): coords[] {
  return [
    { lat: coords.lat + 0.001, lng: coords.lng },
    { lat: coords.lat, lng: coords.lng + 0.001 },
    { lat: coords.lat - 0.001, lng: coords.lng },
    { lat: coords.lat, lng: coords.lng - 0.001 },
  ];
}
const defaultGeoZonesPoly: coords[] = simplePolyAroundCoords(
  defaultGeoZonesCoords
);
const defaultGeoZonesZoom = 15;
let shapeCoords: coordinates;

let editGeoZonesMarker: google.maps.Marker | undefined;
let editGeoZonesCircle: google.maps.Circle | undefined;
let editGeoZonesPoly: google.maps.Polygon | undefined;
function EditGeoZonesMap(
  map: google.maps.Map | undefined,
  setMap: (map: google.maps.Map | undefined) => void,
  modalOpen: boolean,
  coords: coords,
  setCoords: (coords: coords) => void,
  setRadius: (radius: number) => void,
  geoZoneEdgeType: GeoZoneEdgeType,
  setPolyLength: (length: number) => void,
  setMarkerInPoly: (isInside: boolean) => void,
  closeWritingFields: () => void,
  GeoZonesListFiltered: geoZonesEntry[] | null
) {
  const { data } = useAppContext();

  const [mapInitialized, setMapInitialized] = useState(false);
  const mapElementId = "edit_geoZone_map";

  useEffect(() => {
    if (modalOpen) {
      initializeMap(
        map,
        setMap,
        mapElementId,
        mapInitCustom,
        setMapInitialized,
        defaultGeoZonesCoords,
        defaultGeoZonesZoom
      );
    } else {
      setMapInitialized(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  function moveEverythingToExistingGeoZones() {
    if (
      GeoZonesListFiltered !== null &&
      GeoZonesListFiltered.length > 0 &&
      mapInitialized
    ) {
      const anExistingGeoZones = GeoZonesListFiltered[0];

      let coordinates: { latitude: string; longitude: string }[] = [];
      if (anExistingGeoZones.shape.type === "Circle") {
        // For Circle shape, access the single set of coordinates directly
        coordinates = [
          {
            latitude: anExistingGeoZones.shape.coordinates[0].latitude,
            longitude: anExistingGeoZones.shape.coordinates[0].longitude,
          },
        ];
      } else if (anExistingGeoZones.shape.type === "Polygon") {
        // For Polygon shape, access all coordinates in the "coords" array
        coordinates = anExistingGeoZones.shape.coordinates;
      }
      // Convert Latitude and Longitude to numbers
      if (coordinates.length > 0) {
        const latitude = parseFloat(coordinates[0].latitude);
        const longitude = parseFloat(coordinates[0].longitude);

        const nearbyCoords = makeLatLng(latitude, longitude - 0.01);

        setCoords(nearbyCoords);

        if (anExistingGeoZones.shape.type === "Polygon") {
          editGeoZonesPoly?.setPath(simplePolyAroundCoords(nearbyCoords));
        } else if (anExistingGeoZones.shape.type === "Circle") {
          editGeoZonesCircle?.setCenter(nearbyCoords);
        }

        setMarkerInPoly(true);

        if (map) {
          map.setCenter(nearbyCoords);
        }
      }
    }
  }

  function updateShapeVisibility() {
    if (geoZoneEdgeType === GeoZoneEdgeType.Circle) {
      editGeoZonesCircle?.setVisible(true);
      editGeoZonesPoly?.setVisible(false);
    } else {
      editGeoZonesCircle?.setVisible(false);
      editGeoZonesPoly?.setVisible(true);
    }
  }

  useEffect(() => {
    if (mapInitialized) moveEverythingToExistingGeoZones();
    if (mapInitialized) updateShapeVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.org.geoZoneList, mapInitialized, geoZoneEdgeType]);

  function checkIfMarkerIsInPoly() {
    const markerPosNow = editGeoZonesMarker?.getPosition();
    if (
      editGeoZonesPoly !== undefined &&
      markerPosNow !== undefined &&
      markerPosNow !== null
    ) {
      const markerInPoly = google.maps.geometry.poly.containsLocation(
        markerPosNow,
        editGeoZonesPoly
      );
      // console.log("markerInPoly:", markerPosNow?.lat(), markerPosNow?.lng(), addDestPoly.getPath(), markerInPoly)
      setMarkerInPoly(markerInPoly);
    }
  }

  useEffect(() => {
    // Tracking polygon length
    const length = editGeoZonesPoly?.getPath().getLength();
    // console.log("vertex counting effect, new length:", length)
    if (length !== undefined) {
      setPolyLength(length);
    }
    editGeoZonesPoly?.getPath().addListener("insert_at", () => {
      const length = editGeoZonesPoly?.getPath().getLength();
      // console.log("insert_at, new length:", length)
      if (length !== undefined) {
        setPolyLength(length);
      }
      checkIfMarkerIsInPoly();
      closeWritingFields();
    });
    editGeoZonesPoly?.getPath().addListener("remove_at", () => {
      const length = editGeoZonesPoly?.getPath().getLength();
      // console.log("remove_at, new length:", length)
      if (length !== undefined) {
        setPolyLength(length);
      }
      checkIfMarkerIsInPoly();
      closeWritingFields();
    });
    editGeoZonesPoly?.getPath().addListener("set_at", () => {
      // console.log("set_at")
      checkIfMarkerIsInPoly();
      closeWritingFields();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editGeoZonesPoly, geoZoneEdgeType]);

  function mapInitCustom(map: google.maps.Map) {
    // console.log("mapInitCustom")
    editGeoZonesMarker = new google.maps.Marker({
      draggable: true,
      map: map,
      position: coords,
      icon: mapIconRedballoon(),
    });
    editGeoZonesMarker.addListener("position_changed", () => {
      const pos = editGeoZonesMarker?.getPosition();
      if (pos !== null && pos !== undefined) {
        setCoords(makeLatLng(pos.lat(), pos.lng()));
      }
      checkIfMarkerIsInPoly();
      closeWritingFields();
    });
    editGeoZonesCircle = new google.maps.Circle({
      map: map,
      center: coords,
      radius: defaultGeoZonesRadius,
      strokeColor: "#ff4d00",
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: "#ff4d00",
      fillOpacity: 0.5,
      draggable: false,
      editable: true,
    });
    editGeoZonesCircle.addListener("radius_changed", () => {
      const rad = editGeoZonesCircle?.getRadius();
      // console.log("radius listener fired:", rad)
      if (rad !== undefined) {
        setRadius(rad);
      }
      closeWritingFields();
    });
    editGeoZonesCircle.bindTo("center", editGeoZonesMarker, "position");
    editGeoZonesPoly = new google.maps.Polygon({
      map: map,
      paths: defaultGeoZonesPoly,
      strokeColor: "#ff4d00",
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: "#ff4d00",
      fillOpacity: 0.5,
      editable: true,
      draggable: true,
      geodesic: true,
    });
    editGeoZonesPoly.addListener(
      "dblclick",
      (mev: google.maps.PolyMouseEvent) => {
        const length = editGeoZonesPoly?.getPath().getLength();
        // console.log("poly dblclick event", mev, typeof mev, mev.vertex, length)
        if (mev.vertex != null && length !== undefined && length > 3) {
          editGeoZonesPoly?.getPath().removeAt(mev.vertex);
        }
        closeWritingFields();
      }
    );
  }

  if (mapInitialized) {
    if (geoZoneEdgeType === GeoZoneEdgeType.Circle) {
      editGeoZonesCircle?.setVisible(true);
      editGeoZonesPoly?.setVisible(false);
    } else {
      editGeoZonesCircle?.setVisible(false);
      editGeoZonesPoly?.setVisible(true);
    }
  }

  return (
    <div
      id={mapElementId}
      className="w-full h-full rounded-xl text-black bg-[#8c8c8c] border-2 shadow-[4px_4px_8px_2px_rgba(0,0,0,0.1)]"
    />
  );
}

export function EditGeoZoneModal({
  open,
  setOpen,
  GeoZonesListFiltered,
  editGeoZoneName,
  geoZoneId,
  orgId,
}: EditGeoZoneModalProps) {
  const { vars, data } = useAppContext();
  const { fetchDataGeoZonesTab } = useFetchData();
  const { apiPutGeoZones } = useManageData();
  const [geoZoneName, setGeoZoneName] = useState<string>("");
  const [confirmEditGeoZonesModalOpen, setConfirmEditGeoZonesModalOpen] =
    useState(false);
  const [geoZoneEdgeType, setGeoZonesEdgeType] = useState<GeoZoneEdgeType>(
    GeoZoneEdgeType.Circle
  );
  const [coords, setCoords] = useState(defaultGeoZonesCoords);
  const [radius, setRadius] = useState(defaultGeoZonesRadius);
  const [chosenOrg, setChosenOrg] = useState<string | null>(null);
  const [chosenGeoZoneState, setChosenGeoZoneState] = useState<EntityState>(
    EntityState.Suggested
  );
  const [geoZoneNameWarning, setGeoZonesNameWarning] = useState<string | null>(
    null
  );
  const [coordsWarning, setCoordsWarning] = useState<string | null>(null);
  const [radiusWarning, setRadiusWarning] = useState<string | null>(null);
  const [orgWarning, setOrgWarning] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      initializeState();
    }
  }, [open, editGeoZoneName, geoZoneId]);

  const fetchGeoZoneData = (geoZoneId: string): geoZonesEntry | null => {
    const geoZone = GeoZonesListFiltered?.find(
      (zone) => zone.gz_id === geoZoneId
    );
    return geoZone || null;
  };

  const initializeState = () => {
    const geoZoneData = geoZoneId ? fetchGeoZoneData(geoZoneId) : null;
    setGeoZoneName(editGeoZoneName || "");
    if (geoZoneData) {
      setGeoZonesEdgeType(
        geoZoneData.shape.type === "Circle"
          ? GeoZoneEdgeType.Circle
          : GeoZoneEdgeType.Polygon
      );
      setCoords({
        lat: parseFloat(geoZoneData.shape.coordinates[0].latitude),
        lng: parseFloat(geoZoneData.shape.coordinates[0].longitude),
      });
      setRadius(
        geoZoneData.shape.radius
          ? parseFloat(geoZoneData.shape.radius)
          : defaultGeoZonesRadius
      );
    } else {
      setGeoZonesEdgeType(GeoZoneEdgeType.Circle);
      setCoords(defaultGeoZonesCoords);
      setRadius(defaultGeoZonesRadius);
    }
    setChosenOrg(vars.auth.organisationId);
    setChosenGeoZoneState(EntityState.Suggested);
  };

  const handleGeoZoneNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGeoZoneName(e.target.value);
  };

  const geoZonesEdgeTypeOptions = Object.values(GeoZoneEdgeType).map(
    (value) => ({
      value,
      name: value,
    })
  );

  const handleEditButtonClick = (geoZoneId: string) => {
    setOpen(true);
  };

  // ----- Standard modal bits -----
  function clearWarnings() {
    setGeoZonesNameWarning(null);
    setCoordsWarning(null);
    setRadiusWarning(null);
    setOrgWarning(null);
  }

  function resetValues() {
    setCoords(defaultGeoZonesCoords);
    setWritingCoords(false);
    setGeoZonesEdgeType(GeoZoneEdgeType.Circle);
    setRadius(defaultGeoZonesRadius);
    setWritingRadius(false);
    setChosenOrg(vars.auth.organisationId);
    setChosenGeoZoneState(EntityState.Suggested);
  }

  function onClose() {
    setOpen(false);
    clearWarnings();
    resetValues();
  }

  function onSubmitClick() {
    clearWarnings();
    closeWritingFields();

    if (!geoZoneName.trim()) {
      setGeoZonesNameWarning("Geo Zone name is required");
      return;
    }

    if (geoZoneEdgeType === GeoZoneEdgeType.Circle && Math.round(radius) < 10) {
      setRadiusWarning("Radius must be at least 10m");
      return;
    }

    setConfirmEditGeoZonesModalOpen(true);
  }

  type SingleCoord = { latitude: number; longitude: number };
  type MultipleCoords = SingleCoord[];

  async function onConfirmClick() {
    setLoading(true);

    try {
      const editedGeoZoneName = geoZoneName.trim();

      if (!geoZoneId || !orgId) {
        console.error("Critical data is missing. Cannot proceed:", {
          geoZoneId,
          orgId,
        });
        return;
      }

      let shapeCoords: coordinates;

      if (geoZoneEdgeType === GeoZoneEdgeType.Circle) {
        shapeCoords = { latitude: coords.lat, longitude: coords.lng };
      } else {
        const polygonCoords = getPolygonCoordsFromMap();
        shapeCoords = {
          latitude: polygonCoords[0].latitude,
          longitude: polygonCoords[0].longitude,
        };
      }

      const radiusNumberOrNull =
        geoZoneEdgeType === GeoZoneEdgeType.Circle
          ? radius !== undefined
            ? Math.round(radius)
            : null
          : null;

      await apiPutGeoZones(
        orgId,
        geoZoneId,
        editedGeoZoneName,
        geoZoneEdgeType,
        shapeCoords,
        radiusNumberOrNull,
        chosenGeoZoneState
      );

      await fetchDataGeoZonesTab();
      setConfirmEditGeoZonesModalOpen(false);
      onClose();
    } catch (error) {
      console.error("Error editing Geo Zone:", error);
    } finally {
      setLoading(false);
    }
  }

  function getPolygonCoordsFromMap(): MultipleCoords {
    const polyPath = editGeoZonesPoly?.getPath();
    const polyArray = polyPath?.getArray();

    if (!Array.isArray(polyArray) || polyArray.length === 0) {
      console.error("No polygon coordinates defined.");
      return [];
    }

    return polyArray
      .map((point) => ({
        latitude: point.lat(),
        longitude: point.lng(),
      }))
      .filter((coord) => coord.latitude !== null && coord.longitude !== null);
  }

  // ----- Map -----
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);

  // ----- Coordinates -----
  const coordsTitle = "Coordinates (lat, long):";
  const coordsTooltip =
    'The coordinates of the GeoZones. Edit in the map by moving the pin, or by typing lat & long (as one number each, use "." for decimals, use negative values for °S and °W)';
  const newCoordPrettyStr =
    latToPrettyString(coords.lat) + ", " + lngToPrettyString(coords.lng);
  const [writingCoords, setWritingCoords] = useState(false);
  const coordsReadComp = (
    <div className="flex space-x-2 items-center">
      {makeTextOutput(coordsTitle, coordsTooltip, newCoordPrettyStr, null)}
      <Tooltip title="Edit by typing" placement="right">
        <div
          className="cursor-pointer"
          key={"edit"}
          onClick={() => {
            setWritingCoords(true);
            setWritingRadius(false);
          }}
        >
          <HiPencil className="text-2xl" />
        </div>
      </Tooltip>
    </div>
  );
  const emptyCoordsWarning = coordsWarning === null ? null : "";
  const coordsWriteComp = (
    <div className="flex space-x-2 items-center text-xl">
      {makeTextInputSmall(
        coordsTitle,
        coordsTooltip,
        "° Lat",
        "newGeoZonesWriteLat",
        emptyCoordsWarning,
        coords.lat.toString()
      )}
      <div className="flex space-x-6 justify-start items-center text-xl">
        {","}
      </div>
      {makeTextInputSmall(
        null,
        null,
        "° Long",
        "newGeoZonesWriteLng",
        coordsWarning,
        coords.lng.toString()
      )}
      {iconButton("Set", <></>, () => {
        const writeLatStr = (
          document.getElementById("newGeoZonesWriteLat") as HTMLInputElement
        ).value;
        const writeLat = Number(writeLatStr);
        const writeLngStr = (
          document.getElementById("newGeoZonesWriteLng") as HTMLInputElement
        ).value;
        const writeLng = Number(writeLngStr);
        if (writeLatStr.length < 1 || writeLngStr.length < 1) {
          setCoordsWarning("Please enter a latitude and a longitude");
        } else if (isNaN(writeLat) || isNaN(writeLng)) {
          setCoordsWarning("Coordinates must be numerical");
        } else if (
          writeLat > 90 ||
          writeLat < -90 ||
          writeLng > 180 ||
          writeLng < -180
        ) {
          setCoordsWarning("Coordinates must be within possible bounds");
        } else {
          const writeCoords = { lat: writeLat, lng: writeLng };
          editGeoZonesCircle?.setCenter(writeCoords);
          map?.setCenter(writeCoords);
          setCoords(writeCoords);
          setCoordsWarning(null);
          setWritingCoords(false);
        }
      })}
    </div>
  );
  const coordsComp = writingCoords ? coordsWriteComp : coordsReadComp;

  // ----- Edge type -----
  useEffect(() => {
    if (geoZoneEdgeType === GeoZoneEdgeType.Polygon) {
      editGeoZonesPoly?.setPath(simplePolyAroundCoords(coords));
      setMarkerInPoly(true);
    }
  }, [geoZoneEdgeType]);

  useEffect(() => {
    updateShapeVisibility();
  }, [geoZoneEdgeType]);

  function updateShapeVisibility() {
    if (editGeoZonesCircle && editGeoZonesPoly) {
      if (geoZoneEdgeType === GeoZoneEdgeType.Circle) {
        editGeoZonesCircle.setVisible(true);
        editGeoZonesPoly.setVisible(false);
      } else {
        editGeoZonesCircle.setVisible(false);
        editGeoZonesPoly.setVisible(true);
      }
    }
  }

  const edgeTypePicker = makeRadioInput(
    "Edge shape:",
    "How to define the edge of what counts as the Geo Zone",
    geoZoneEdgeType,
    geoZonesEdgeTypeOptions,
    (newEdgeType) => {
      setGeoZonesEdgeType(newEdgeType);
      updateShapeVisibility();
    }
  );

  // ----- Radius -----
  const radiusTitle = "Radius:";
  const radiusTooltip =
    "The radius of the circular edge of what counts as the Geo Zone. Edit in the map or by typing. Must be at least 10 m";
  const [writingRadius, setWritingRadius] = useState(false);
  const readRadiusWarning =
    Math.round(radius) < 10 ? "Radius under 10 m" : null;
  const readRadiusField = (
    <div className="flex space-x-2 items-center">
      {makeTextOutput(
        radiusTitle,
        radiusTooltip,
        Math.round(radius) + " meters",
        readRadiusWarning
      )}
      <Tooltip title="Edit by typing" placement="right">
        <div
          className="cursor-pointer"
          key={"edit"}
          onClick={() => {
            setWritingRadius(true);
            setWritingCoords(false);
          }}
        >
          <HiPencil className="text-2xl" />
        </div>
      </Tooltip>
    </div>
  );
  const writeRadiusField = (
    <div className="flex space-x-2 items-center">
      {makeTextInputSmall(
        radiusTitle,
        radiusTooltip,
        defaultGeoZonesRadius.toString(),
        "newGeoZonesWriteRadius",
        radiusWarning,
        Math.round(radius).toString()
      )}
      <div className="flex space-x-6 justify-start items-center text-xl">
        {"meters"}
      </div>
      {iconButton("Set", <></>, () => {
        const writeRadiusStr = (
          document.getElementById("newGeoZonesWriteRadius") as HTMLInputElement
        ).value;
        const writeRadius = Number(writeRadiusStr);
        if (writeRadiusStr.length < 1) {
          setRadiusWarning("Please enter a number");
        } else if (isNaN(writeRadius)) {
          setRadiusWarning("Radius must be numerical");
        } else if (writeRadius < 10) {
          setRadiusWarning("Radius under 10 m");
        } else {
          editGeoZonesCircle?.setRadius(writeRadius);
          setRadius(writeRadius);
          setRadiusWarning(null);
          setWritingRadius(false);
        }
      })}
    </div>
  );

  const [editPolyLength, setEditPolyLength] = useState(
    defaultGeoZonesPoly.length
  );
  const [markerInPoly, setMarkerInPoly] = useState(true);
  const editPolygonField = makeTextOutput(
    "Polygon:",
    "The edge of what counts as the Geo Zone. Edit in the map, remove a point by double-clicking it. Must have between 3 and 12 corners",
    editPolyLength + " corners, see map",
    editPolyLength > 12
      ? "Maximum of 12 corners allowed"
      : !markerInPoly
      ? "Coordinates must be within polygon"
      : null
  );

  // ----- Organisation for Editing Geo Zone -----
  useEffect(() => {
    setChosenOrg(vars.auth.organisationId);
  }, [vars.auth.organisationId]);
  const editOrgOptions =
    data.org.organisationList === null
      ? []
      : data.org.organisationList.map((org) => {
          return { value: org.id, name: org.name };
        });
  const rootEditOrgPicker = makeDropdownInput(
    "Organisation:",
    "The organisation which the edited geoZone belongs to",
    "Organisation",
    chosenOrg,
    editOrgOptions,
    setChosenOrg,
    orgWarning
  );

  // ----- Geo Zone state for Editing Geo Zone -----
  const editStateOptions: { value: EntityState; name: string }[] =
    Object.values(EntityState).map((state) => {
      return { value: state, name: state };
    });

  const isRootUser = accessLevelAtMinimum(
    vars.auth.accessLevel[0],
    AccessLevel.Root
  );

  const rootEditStatePicker = makeDropdownInput(
    "Geo Zone state:",
    "The state of the edited Geo Zone. Determines whether it will be shown to customers as a suggestion, it is seen onboard, it is hidden, or it will be used to avoid generating the same suggestion twice.",
    "State",
    chosenGeoZoneState,
    editStateOptions,
    setChosenGeoZoneState,
    null,
    !isRootUser
  );

  // ----- The confirmation modal for editing -----
  const confirmTextEdgePartEdit =
    geoZoneEdgeType === GeoZoneEdgeType.Circle
      ? "Circle with a radius of " + Math.round(radius) + " meters"
      : "Polygon with " + editPolyLength + " corners";
  const confirmStandardBitEdit = [
    "You are about to edit the following Geo Zone:",
    `- Name: "${geoZoneName}"`,
    "- Coordinates: " + newCoordPrettyStr,
    "- Shape: " +
      (geoZoneEdgeType === GeoZoneEdgeType.Circle
        ? "Circle with a radius of " + Math.round(radius) + " meters"
        : "Polygon with " + editPolyLength + " corners"),
  ];
  const chosenEditOrgObj = editOrgOptions.find(
    (org) => org.value === chosenOrg
  );
  const chosenEditOrgName = chosenEditOrgObj?.name;
  const confirmRootBitEdit = [
    "- Org: " + chosenOrg + " (" + chosenEditOrgName + ")",
    "- State: " + chosenGeoZoneState,
  ];
  const confirmTextEdit = vars.auth.userGroups?.includes("root")
    ? confirmStandardBitEdit.concat(confirmRootBitEdit)
    : confirmStandardBitEdit;
  const confirmEditModal = makeConfirmModal(
    "Confirm editing Geo Zone",
    confirmTextEdit,
    "Confirm: Edit Geo Zone",
    onConfirmClick,
    confirmEditGeoZonesModalOpen,
    setConfirmEditGeoZonesModalOpen
  );

  // ----- Putting together the modal for Editing Geo Zone -----

  function closeWritingFields() {
    setWritingCoords(false);
    setWritingRadius(false);
  }

  useEffect(() => {
    closeWritingFields();
  }, [geoZoneEdgeType, chosenOrg, chosenGeoZoneState]);

  type textEntryWarningTextPropsGeoZones = {
    prompt: string;
    name: string;
    type: string;
    warningStatus: boolean;
    warningText: string;
    onChange: (event: { target: { value: any } }) => void;
    defaultValue?: string;
  };

  interface textEntryPropsGeoZones {
    prompt: string;
    name: string;
    type: string;
    warningStatus: boolean;
    warningText?: string;
    defaultValue?: string;
    onChange: (event: { target: { value: any } }) => void;
  }

  const TextEntryGeoZones = (props: textEntryPropsGeoZones) => {
    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event);
      },
      [props.onChange]
    );

    const inputComponent = (
      <input
        autoFocus
        className={
          "w-[370px] h-[70px] border-[1px] border-deep_blue px-3 rounded-lg placeholder:italic"
        }
        value={props.defaultValue}
        placeholder={props.prompt}
        type={props.type}
        name={props.name}
        id={props.name}
        onChange={handleChange}
      />
    );

    return props.warningStatus ? (
      <div className="w-[370px] relative">
        {inputComponent}
        <div className="absolute w-max right-0 font-normal text-lg text-high_orange h-0">
          {props.warningText || ""}{" "}
        </div>
      </div>
    ) : (
      inputComponent
    );
  };

  function makeEditTextInputGeoZone(
    title: string,
    tooltipText: string,
    fieldPrompt: string,
    fieldHandle: string,
    warning: string | null,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    defaultValue?: string,
    initialValue?: string
  ) {
    const warningStatus = warning !== null;
    const warningText = warningStatus ? warning : "";

    return (
      <div className="w-[370px] relative">
        <input
          autoFocus
          className={
            "w-[370px] h-[70px] border-[1px] border-deep_blue px-3 rounded-lg placeholder:italic"
          }
          value={defaultValue || initialValue}
          placeholder={fieldPrompt}
          type="text"
          name={fieldHandle}
          onChange={onChange}
        />
        {warningStatus && (
          <div className="absolute w-max right-0 font-normal text-lg text-high_orange h-0">
            {warningText}
          </div>
        )}
      </div>
    );
  }

  const geoZoneNameInput = makeEditTextInputGeoZone(
    "Geo Zone Name",
    "A Geo Zone Name uniquely identifies your geographical area of interest.",
    "Enter Geo Zone Name",
    "GeoZoneName",
    geoZoneNameWarning ? "Geo Zone Name is needed" : null,
    handleGeoZoneNameChange,
    geoZoneName
  );

  const editGeoZonesContents = (
    <div className="flex space-x-6">
      <div className="flex flex-col space-y-6">
        {geoZoneNameInput}
        {coordsComp}
        {edgeTypePicker}
        {geoZoneEdgeType === GeoZoneEdgeType.Circle
          ? readRadiusField
          : editPolygonField}
        {vars.auth.userGroups?.includes("root") ? rootEditOrgPicker : <></>}
        {vars.auth.userGroups?.includes("root") ? rootEditStatePicker : <></>}
        {makeSubmitButton(onSubmitClick)}
        {loading && <LoadingSpinner />}
        {confirmEditModal}
      </div>
      <div className="w-[36rem] h-[36rem]">
        {EditGeoZonesMap(
          map,
          setMap,
          open,
          coords,
          setCoords,
          setRadius,
          geoZoneEdgeType,
          setEditPolyLength,
          setMarkerInPoly,
          closeWritingFields,
          GeoZonesListFiltered
        )}
      </div>
    </div>
  );

  return makeModal("Edit Geo Zone", editGeoZonesContents, open, onClose);
}
