import axios, { AxiosResponse } from "axios";
import {
  DeleteAlarmEventPayload,
  OpModeState,
  geoZonesEntry,
  useAppContext,
} from "../context/variables";
import { useNavigate } from "react-router-dom";
import useFetchData from "./fetchData";
import {
  apiCallDelete,
  apiCallDeleteData,
  apiCallDeleteDataAxios,
  apiCallPost,
  apiCallPut,
} from "./call";
import {
  AccessLevel,
  DestinationEdgeType,
  EntityState,
  GeoZoneEdgeType,
  TagScope,
  TagValueType,
} from "../context/types";
import { coordinates, coords } from "../components/Map";
import { theApiUrl } from "../auth/authentication";
import { isTextFieldValueEmpty } from "../helpers/elementHelpers";
import { ApiResponseAxios } from "../types";

export default function useManageData() {
  const { view, data, vars } = useAppContext();
  const {
    fetchDataRootOrgs,
    fetchDataRootVessels,
    fetchUsersGeneral,
    fetchDataDestinationsTab,
    fetchDataTagTypes,
    fetchDataRoutes,
    fetchDataOpModes,
    fetchDataVoyages,
    fetchDataGeoZonesTab,
    fetchDataVesselGroups,
    fetchCustomAlarmTypes,
  } = useFetchData();
  const navigate = useNavigate();

  function apiPost(
    url: string | null,
    body: string,
    handler: (responseJson: any, response: Response) => any
  ) {
    apiCallPost(vars, data, view, navigate, url, body, handler);
  }
  function apiDelete(
    url: string | null,
    handler: (responseJson: any, response: Response) => any
  ) {
    apiCallDelete(vars, data, view, navigate, url, handler);
  }
  function apiDeleteData(
    url: string | null,
    handler: (responseJson: any, response: Response) => any,
    body?: any
  ) {
    apiCallDeleteData(vars, data, view, navigate, url, handler, body);
  }

  function apiDeleteDataAxios(
    url: string | null,
    handler: (response: ApiResponseAxios) => any,
    body?: any
  ) {
    apiCallDeleteDataAxios(vars, data, view, navigate, url, handler, body);
  }

  function apiPut(
    url: string | null,
    body: string,
    handler: (responseJson: any, response: Response) => any
  ) {
    apiCallPut(vars, data, view, navigate, url, body, handler);
  }

  function apiPostRootOrgsAdd(name: string) {
    // console.log("In apiPostRootOrgsAdd")
    const url = theApiUrl + "orgs/";
    const body = JSON.stringify({ name: name });
    const handler = (responseJson: any) => {
      // console.log("apiPostRootOrgsAdd response:", responseJson)
      fetchDataRootOrgs();
    };
    data.rootAccess.setOrgs(null);
    apiPost(url, body, handler);
  }

  function apiDeleteRootOrgs(org_id: string) {
    // console.log("In apiDeleteRootOrgs")
    const url = theApiUrl + "orgs/" + org_id;
    const handler = (responseJson: any) => {
      // console.log("apiDeleteRootOrgs response:", responseJson)
      fetchDataRootOrgs();
    };
    data.rootAccess.setOrgs(null);
    apiDelete(url, handler);
  }

  function apiPutRootOrgsRename(org_id: string | null, new_name: string) {
    // console.log("In apiPutRootOrgsRename")
    if (org_id === null) {
      // console.log("Trying to rename null org: Aborting")
      return;
    }
    const url = theApiUrl + "orgs/" + org_id;
    const body = JSON.stringify({ name: new_name });
    const handler = (responseJson: any) => {
      // console.log("apiPutRootOrgsRename response:", responseJson)
      fetchDataRootOrgs();
    };
    data.rootAccess.setOrgs(null);
    apiPut(url, body, handler);
  }

  function apiPostRootVesselsAdd(name: string, org_id: string) {
    // console.log("In apiPostRootVesselsAdd")
    const url = theApiUrl + "vessels/org/" + org_id;
    const body = JSON.stringify({ name: name });
    const handler = (responseJson: any) => {
      // console.log("apiPostRootVesselsAdd response:", responseJson)
      fetchDataRootVessels();
    };
    data.rootAccess.setVessels(null);
    apiPost(url, body, handler);
  }

  function apiDeleteRootVessels(vessel_id: string) {
    // console.log("In apiDeleteRootVessels")
    const url = theApiUrl + "vessels/" + vessel_id;
    const handler = (responseJson: any) => {
      // console.log("apiDeleteRootVessels response:", responseJson)
      fetchDataRootVessels();
    };
    data.rootAccess.setVessels(null);
    apiDelete(url, handler);
  }

  function apiPutRootVesselsRename(vessel_id: string | null, new_name: string) {
    // console.log("In apiPutRootVesselsRename")
    if (vessel_id === null) {
      // console.log("Trying to rename null vessel: Aborting")
      return;
    }
    const url = theApiUrl + "vessels/" + vessel_id;
    const body = JSON.stringify({ name: new_name });
    const handler = (responseJson: any) => {
      // console.log("apiPutRootVesselsRename response:", responseJson)
      fetchDataRootVessels();
    };
    data.rootAccess.setVessels(null);
    apiPut(url, body, handler);
  }

  function apiUserAdd(
    name: string,
    org: string | null,
    accessLevel: AccessLevel,
    webAppStatus: boolean,
    email: string | undefined,
    operatorStatus: boolean,
    pin: string | undefined
  ) {
    // console.log("In apiUserAdd")
    const url = theApiUrl + "users";
    const body = JSON.stringify({
      name: name,
      org_id: org,
      access_level: accessLevel,
      is_web_app_accessor: webAppStatus,
      email: email,
      is_operator: operatorStatus,
      pin_code: pin,
    });
    const handler = (responseJson: any) => {
      // console.log("apiUserAdd response:", responseJson)
      fetchUsersGeneral();
    };
    data.org.setUserList(null);
    apiPost(url, body, handler);
  }

  function apiUserDelete(username: string) {
    // console.log("In apiUserDelete")
    const url = theApiUrl + "users/user/" + username;
    const handler = (responseJson: any) => {
      // console.log("apiUserDelete response:", responseJson)
      fetchUsersGeneral();
    };
    data.org.setUserList(null);
    apiDelete(url, handler);
  }

  function apiUserRemove(username: string) {
    // console.log("In apiUserRemove")
    const url = theApiUrl + "users/user/" + username;
    const body = JSON.stringify({
      remove_user: true,
    });
    const handler = (responseJson: any) => {
      // console.log("apiUserRemove response:", responseJson)
      fetchUsersGeneral();
    };
    data.org.setUserList(null);
    apiPut(url, body, handler);
  }

  function apiUserRename(username: string | null, new_name: string | null) {
    if (username === null || isTextFieldValueEmpty(new_name)) {
      return;
    }
    const url = theApiUrl + "users/user/" + username;
    const body = JSON.stringify({
      name: new_name,
    });
    const handler = (responseJson: any) => {
      // console.log("apiUserRename response:", responseJson)
      fetchUsersGeneral();
    };
    data.org.setUserList(null);
    apiPut(url, body, handler);
  }

  function apiUserEditAccessLevel(
    username: string | null,
    new_access_level: AccessLevel
  ) {
    if (username === null) {
      return;
    }
    const url = theApiUrl + "users/user/" + username;
    const body = JSON.stringify({
      access_level: new_access_level,
    });
    const handler = (responseJson: any) => {
      // console.log("apiUserEditAccessLevel response:", responseJson)
      fetchUsersGeneral();
    };
    data.org.setUserList(null);
    apiPut(url, body, handler);
  }

  function apiUserRevokeOperatorStatus(username: string | null) {
    if (username === null) {
      return;
    }
    const url = theApiUrl + "users/user/" + username;
    const body = JSON.stringify({
      is_operator: false,
    });
    const handler = (responseJson: any) => {
      // console.log("apiUserRevokeOperatorStatus response:", responseJson)
      fetchUsersGeneral();
    };
    data.org.setUserList(null);
    apiPut(url, body, handler);
  }

  function apiUserGrantOperatorStatus(
    username: string | null,
    new_pin: string | null
  ) {
    if (username === null || isTextFieldValueEmpty(new_pin)) {
      return;
    }
    const url = theApiUrl + "users/user/" + username;
    const body = JSON.stringify({
      is_operator: true,
      pin: new_pin,
    });
    const handler = (responseJson: any) => {
      // console.log("apiUserGrantOperatorStatus response:", responseJson)
      fetchUsersGeneral();
    };
    data.org.setUserList(null);
    apiPut(url, body, handler);
  }

  function apiUserEditPin(username: string | null, new_pin: string | null) {
    if (username === null || isTextFieldValueEmpty(new_pin)) {
      return;
    }
    const url = theApiUrl + "users/user/" + username;
    const body = JSON.stringify({
      pin: new_pin,
    });
    const handler = (responseJson: any) => {
      // console.log("apiUserEditPin response:", responseJson)
      fetchUsersGeneral();
    };
    data.org.setUserList(null);
    apiPut(url, body, handler);
  }

  function apiUserResendConfirmation(username: string | null) {
    if (username === null) {
      return;
    }
    const url = theApiUrl + "users/user/" + username + "/resend_confirmation";
    const body = JSON.stringify({});
    const handler = (responseJson: any) => {
      // console.log("apiUserResendConfirmation response:", responseJson)
      fetchUsersGeneral();
    };
    data.org.setUserList(null);
    apiPut(url, body, handler);
  }

  function apiPostDestsAdd(
    org_id: string | null,
    name: string,
    coords: coords,
    shape: DestinationEdgeType,
    radius: number,
    polygon: coords[] | undefined,
    state: EntityState
  ) {
    // console.log("In apiPostDestsAdd")
    if (org_id === null) {
      // console.log("No org in dest adding!")
      return;
    }
    if (polygon === undefined) {
      // console.log("No poly in dest adding!")
      return;
    }
    const convertedPoly = polygon.map((point) => {
      return { latitude: point.lat, longitude: point.lng };
    });
    const url = theApiUrl + "dests/" + org_id;
    const body = JSON.stringify({
      location: {
        latitude: coords.lat,
        longitude: coords.lng,
        radius: shape === DestinationEdgeType.Circle ? radius : undefined,
        polygon:
          shape === DestinationEdgeType.Polygon ? convertedPoly : undefined,
      },
      display_name: name,
      state: state,
    });
    const handler = (responseJson: any) => {
      // console.log("apiPostDestsAdd response:", responseJson)
      fetchDataDestinationsTab();
    };
    data.org.destinationList.set(null);
    apiPost(url, body, handler);
  }

  function apiDeleteDests(org_id: string | undefined, dest_id: string | null) {
    // console.log("In apiDeleteDests")
    if (org_id === undefined) {
      // console.log("No org in dest deletion!")
      return;
    }
    if (dest_id === null) {
      // console.log("No dest in dest deletion!")
      return;
    }
    const url = theApiUrl + "dests/" + org_id + "/" + dest_id;
    const handler = (responseJson: any) => {
      // console.log("apiDeleteDests response:", responseJson)
      fetchDataDestinationsTab();
    };
    data.org.destinationList.set(null);
    apiDelete(url, handler);
  }

  function apiPutDestsRename(
    org_id: string | undefined,
    dest_id: string | null,
    new_name: string
  ) {
    // console.log("In apiPutDestsRename")
    if (org_id === undefined) {
      // console.log("No org in dest renaming!")
      return;
    }
    if (dest_id === null) {
      // console.log("No dest in dest renaming!")
      return;
    }
    const url = theApiUrl + "dests/" + org_id + "/" + dest_id;
    const body = JSON.stringify({
      display_name: new_name,
    });
    const handler = (responseJson: any) => {
      // console.log("apiPutDestsRename response:", responseJson)
      fetchDataDestinationsTab();
    };
    data.org.destinationList.set(null);
    apiPut(url, body, handler);
  }

  function apiPutDestsLocation(
    org_id: string | null,
    dest_id: string | null,
    new_coords: coords,
    new_shape: DestinationEdgeType,
    new_radius: number,
    new_polygon: coords[] | undefined
  ) {
    // console.log("In apiPutDestsLocation")
    if (org_id === null) {
      // console.log("No org in dest location editing!")
      return;
    }
    if (dest_id === null) {
      // console.log("No dest in dest location editing!")
      return;
    }
    if (new_polygon === undefined) {
      // console.log("No poly in dest location editing!")
      return;
    }
    const convertedPoly = new_polygon.map((point) => {
      return { latitude: point.lat, longitude: point.lng };
    });
    const url = theApiUrl + "dests/" + org_id + "/" + dest_id;
    const body = JSON.stringify({
      location: {
        latitude: new_coords.lat,
        longitude: new_coords.lng,
        radius:
          new_shape === DestinationEdgeType.Circle ? new_radius : undefined,
        polygon:
          new_shape === DestinationEdgeType.Polygon ? convertedPoly : undefined,
      },
    });
    const handler = (responseJson: any) => {
      // console.log("apiPutDestsLocation response:", responseJson)
      fetchDataDestinationsTab();
    };
    data.org.destinationList.set(null);
    apiPut(url, body, handler);
  }

  function apiPutDestsState(
    org_id: string | undefined,
    dest_id: string | null,
    new_state: EntityState
  ) {
    // console.log("In apiPutDestsState")
    if (org_id === undefined) {
      // console.log("No org in dest state editing!")
      return;
    }
    if (dest_id === null) {
      // console.log("No dest in dest state editing!")
      return;
    }
    const url = theApiUrl + "dests/" + org_id + "/" + dest_id;
    const body = JSON.stringify({
      state: new_state,
    });
    const handler = (responseJson: any) => {
      // console.log("apiPutDestsState response:", responseJson)
      fetchDataDestinationsTab();
    };
    data.org.destinationList.set(null);
    apiPut(url, body, handler);
  }

  function apiPostTagTypesAdd(
    org_id: string | null,
    name: string | null,
    scope: TagScope,
    valueType: TagValueType,
    unit: string | null,
    state: EntityState
  ) {
    if (org_id === null || name === null) return;

    data.tagTypes.tagTypeList.set(null);
    const url = theApiUrl + "tags-types/" + org_id;
    const body = JSON.stringify({
      name: name,
      value_type: valueType,
      scope: scope,
      state: state,
      unit: valueType === TagValueType.Number ? unit : undefined,
      categories: undefined,
    });
    const handler = (responseJson: any) => {
      // console.log("apiPostTagTypesAdd response:", responseJson)
      fetchDataTagTypes();
    };
    apiPost(url, body, handler);
  }

  function apiDeleteTagTypes(
    org_id: string | undefined,
    tag_id: string | null
  ) {
    // console.log("In apiDeleteTagTypes")
    if (org_id === undefined) {
      // console.log("No org in tag type deletion!")
      return;
    }
    if (tag_id === null) {
      // console.log("No tag in tag type deletion!")
      return;
    }
    data.tagTypes.tagTypeList.set(null);
    const url = theApiUrl + "tags-types/" + org_id + "/" + tag_id;
    const handler = (responseJson: any) => {
      // console.log("apiDeleteTagTypes response:", responseJson)
      fetchDataTagTypes();
    };
    apiDelete(url, handler);
  }

  function apiPutTagTypesState(
    org_id: string | undefined,
    tag_id: string | null,
    new_state: EntityState
  ) {
    // console.log("In apiPutTagTypesState")
    if (org_id === undefined) {
      // console.log("No org in tag types state editing!")
      return;
    }
    if (tag_id === null) {
      // console.log("No tag in tag types state editing!")
      return;
    }
    data.tagTypes.tagTypeList.set(null);
    const url = theApiUrl + "tags-types/" + org_id + "/" + tag_id;
    const body = JSON.stringify({
      state: new_state,
    });
    const handler = (responseJson: any) => {
      // console.log("apiPutTagTypesState response:", responseJson)
      fetchDataTagTypes();
    };
    apiPut(url, body, handler);
  }

  function apiPostTagEventsAdd(
    orgId: string | null,
    vesselId: string | null,
    userId: string | null,
    dateTime: string | null,
    tagTypeId: string | null,
    value: boolean | string | number | null,
    refreshList: () => void
  ) {
    if (
      orgId === null ||
      vesselId === null ||
      dateTime === null ||
      tagTypeId === null
    )
      return;

    data.tagEvents.tagEventList.set(null);
    const url = theApiUrl + "tags-events/";
    const body = JSON.stringify({
      tag_id: tagTypeId,
      timestring: dateTime,
      org_id: orgId,
      vessel_id: vesselId,
      user_id: userId,
      value: value,
      // category: Optional[str] = None,
    });
    function handler(responseJson: any) {
      // console.log("apiPostTagEventsAdd response:", responseJson)
      refreshList();
    }
    apiPost(url, body, handler);
  }

  function apiDeleteTagEvents(
    tagTypeId: string | null,
    timestamp: string | null,
    refreshList: () => void
  ) {
    // console.log("In apiDeleteTagEvents")
    const url = theApiUrl + "tags-events/" + tagTypeId + "/" + timestamp;
    const handler = (responseJson: any) => {
      // console.log("apiDeleteTagEvents response:", responseJson)
      refreshList();
    };
    data.tagEvents.tagEventList.set(null);
    apiDelete(url, handler);
  }

  function apiPostRoutesAdd(
    org_id: string | null,
    name: string,
    state: EntityState,
    destination_ids?: string[]
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!org_id || !name) {
        reject("Organization ID or Name is missing");
        return;
      }

      const url = theApiUrl + "routes/add";
      const body = JSON.stringify({
        name: name,
        destination_ids: destination_ids,
        state: state,
        org_id: org_id,
      });

      const handler = (responseJson: any) => {
        fetchDataRoutes();
        resolve();
      };

      apiPost(url, body, handler);
    });
  }

  function apiPostGroupsAdd(
    org_id: string | null,
    name: string,
    vessels: string[],
    group_report: boolean = false,
    sister_report: boolean = false,
    sisters: boolean = false
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!org_id || !name) {
        reject("Organization ID or Name is missing");
        return;
      }

      const url = theApiUrl + `groups/add`;
      const body = JSON.stringify({
        org_id: org_id,
        name: name,
        vessels: vessels,
        group_report: group_report,
        sister_report: sister_report,
        sisters: sisters,
      });

      const handler = (responseJson: any) => {
        fetchDataVesselGroups();
        resolve();
      };

      apiPost(url, body, handler);
    });
  }

  function apiDeleteRoutes(
    orgId: string | null | undefined,
    routeId: string | null
  ) {
    if (!orgId || !routeId) {
      return;
    }
    data.routes.routeList.set(null);
    const url = theApiUrl + `routes/${orgId}/${routeId}`;
    const handler = (responseJson: any) => {
      fetchDataRoutes();
    };
    apiDelete(url, handler);
  }

  function apiDeleteGeoZones(orgId: string | undefined, gzId: string | null) {
    if (!orgId || !gzId) {
      return;
    }

    const url = theApiUrl + `geozones/${orgId}/${gzId}`;

    const handler = (responseJson: any) => {
      fetchDataGeoZonesTab();
    };

    apiDelete(url, handler);
  }

  function apiPutRoutes(
    orgId: string | undefined,
    routeId: string | null,
    name: string | null,
    destination_ids: string[] | null,
    state: EntityState | null
  ) {
    return new Promise<void>((resolve, reject) => {
      console.log("In apiPutRoutes");
      if (!orgId || !routeId) {
        reject("Missing orgId or routeId");
        return;
      }

      const url = theApiUrl + `routes/${orgId}/${routeId}`;
      const body = JSON.stringify({
        name: name,
        destination_ids: destination_ids,
        state: state,
      });

      const handler = (responseJson: any) => {
        fetchDataRoutes();
        resolve();
      };

      apiPut(url, body, handler);
    });
  }

  function apiPutVesselGroups(
    orgId: string | undefined,
    groupId: string | null,
    name: string | null,
    vessels: string[] | null,
    groupReport: boolean | null,
    sisters: boolean | null,
    sisterReport: boolean | null
  ) {
    return new Promise<void>((resolve, reject) => {
      if (!orgId || !groupId) {
        reject("Missing orgId or groupId");
        return;
      }

      const url = theApiUrl + `groups/${orgId}/${groupId}`;
      const body = JSON.stringify({
        name: name,
        vessels: vessels,
        group_report: groupReport,
        sisters: sisters,
        sister_report: sisterReport,
      });

      const handler = (responseJson: any) => {
        fetchDataVesselGroups()
          .then(() => {
            console.log("Data re-fetched successfully");
            resolve();
          })
          .catch((error) => {
            console.error("Failed to fetch data:", error);
            reject(error);
          });
      };

      apiPut(url, body, handler);
    });
  }

  function apiPutGeoZones(
    org_id: string | null,
    gz_id: string | null,
    name: string,
    shapeType: GeoZoneEdgeType,
    coords: coordinates,
    radius: number | null | undefined,
    state: EntityState
  ) {
    if (org_id === null || gz_id === null) {
      return;
    }

    let shapeObject: geoZonesEntry["shape"];

    if (shapeType === GeoZoneEdgeType.Circle) {
      shapeObject = {
        type: "Circle",
        coordinates: Array.isArray(coords)
          ? coords.map((coord) => ({
              latitude: coord.latitude.toString(),
              longitude: coord.longitude.toString(),
            }))
          : [
              {
                latitude: coords.latitude.toString(),
                longitude: coords.longitude.toString(),
              },
            ],
        radius: radius?.toString(),
      };
    } else {
      shapeObject = {
        type: "Polygon",
        coordinates: Array.isArray(coords)
          ? coords.map((coord) => ({
              latitude: coord.latitude.toString(),
              longitude: coord.longitude.toString(),
            }))
          : [
              {
                latitude: coords.latitude.toString(),
                longitude: coords.longitude.toString(),
              },
            ],
      };
    }

    const body = JSON.stringify({
      name: name,
      shape: shapeObject,
      state: state,
    });

    const url = theApiUrl + `geozones/${org_id}/${gz_id}`;
    const handler = (responseJson: any) => {
      fetchDataGeoZonesTab();
    };

    apiPut(url, body, handler);
  }

  function apiPostOpModesAdd(
    org_id: string | null,
    name: string,
    description: string | null,
    state: OpModeState
  ) {
    if (!org_id || !name) {
      return;
    }

    const url = theApiUrl + "opmode/add";

    const body = JSON.stringify({
      name: name,
      description: description,
      state: state,
      org_id: org_id,
    });

    const handler = (responseJson: any) => {
      fetchDataOpModes();
    };

    apiPost(url, body, handler);
  }
  function addCustomAlarm(
    vessel: string,
    alarmType: string,
    alarmName: string,
    alarmLevel: string,
    comparison: string,
    threshold: string,
    geoZone?: string,
    vesselId?: string
  ): Promise<void> {
    const org_id = vars.auth.organisationId;

    return new Promise((resolve, reject) => {
      if (
        !org_id ||
        !vessel ||
        !alarmType ||
        !alarmName ||
        !alarmLevel ||
        !comparison ||
        !threshold ||
        !vesselId
      ) {
        reject("Missing required fields for adding custom alarm");
        return;
      }

      const url = `${theApiUrl}alarms-types/`;
      const body = JSON.stringify({
        vessel_name: vessel,
        alarm_name: alarmName,
        alarm_level: alarmLevel,
        alarm_type: alarmType,
        operator: comparison,
        threshold: threshold,
        geozone: geoZone,
        vessel_id: vesselId,
      });

      const handler = (responseJson: any) => {
        //TODO Remove console log and commented code
        console.log("Alarm added successfully", responseJson);
        // fetchCustomAlarmTypes();
        resolve();
      };

      console.log("Making API call with URL:", url, "and body:", body);
      apiPost(url, body, handler);
    });
  }

  function apiDeleteOpModes(
    orgId: string | undefined,
    opModeId: string | null
  ) {
    if (orgId === undefined) {
      return;
    }

    if (opModeId === null) {
      return;
    }
    data.opModes.opModeList.set(null);
    const url = theApiUrl + `opmode/${orgId}/${opModeId}`;
    const handler = (responseJson: any) => {
      fetchDataOpModes();
    };
    apiDelete(url, handler);
  }

  function apiPutOpModes(
    orgId: string | undefined,
    opModeId: string | null,
    name: string | null,
    description: string | null | undefined,
    state: OpModeState | null
  ) {
    if (orgId === undefined) {
      return;
    }

    if (opModeId === null) {
      return;
    }

    const url = theApiUrl + `opmode/${orgId}/${opModeId}`;
    const body = JSON.stringify({
      name: name,
      description: description,
      state: state,
    });

    const handler = (responseJson: any) => {
      fetchDataOpModes();
    };

    apiPut(url, body, handler);
  }

  function apiDeactivateRoute(
    orgId: string | undefined,
    routeId: string | null
  ) {
    if (!orgId || !routeId) {
      return;
    }

    const url = theApiUrl + `routes/${orgId}/${routeId}`;
    const body = JSON.stringify({
      state: "Inactive",
    });

    apiPut(url, body, (responseJson: any) => {
      fetchDataRoutes();
    });
  }

  function apiSetStateRemoved(
    orgId: string | undefined,
    routeId: string | null
  ) {
    if (!orgId || !routeId) {
      return;
    }

    const url = theApiUrl + `routes/${orgId}/${routeId}`;
    const body = JSON.stringify({
      state: "Removed",
    });

    apiPut(url, body, (responseJson: any) => {
      fetchDataRoutes();
    });
  }

  function apiPostVoyagesAdd(
    vesselId: string,
    startTime: string,
    endTime: string,
    state: string
  ) {
    if (!vesselId || !startTime || !endTime) {
      return;
    }

    const url = theApiUrl + "voyage/add";

    const body = JSON.stringify({
      vessel_id: vesselId,
      start_time: startTime,
      end_time: endTime,
      state: state,
    });

    const handler = (responseJson: any) => {
      fetchDataVoyages();
    };

    apiPost(url, body, handler);
  }

  function apiDeleteVoyageTrip(
    vesselId: string | null,
    startTime: string | null
  ) {
    return new Promise((resolve, reject) => {
      if (!vesselId || !startTime) {
        reject(new Error("vesselId or startTime is missing"));
        return;
      }

      const url = `${theApiUrl}voyage/delete`;

      const handler = (responseJson: unknown, response: { ok: any }) => {
        if (response.ok) {
          resolve(responseJson);
        } else {
          reject(new Error("Failed to delete voyage"));
        }
      };

      const requestBody = JSON.stringify({
        vessel_id: vesselId,
        start_time: startTime,
      });

      apiDeleteData(url, handler, requestBody);
    });
  }

  function apiDeleteSingleVesselGroup(orgId: string, groupId: string) {
    return new Promise((resolve, reject) => {
      if (!orgId || !groupId) {
        reject(new Error("orgId or groupId is missing"));
        return;
      }

      const url = `${theApiUrl}groups/${orgId}/${groupId}`;

      const handler = (responseJson: unknown, response: { ok: any }) => {
        if (response.ok) {
          resolve(responseJson);
        } else {
          reject(new Error("Failed to delete the vessel group"));
        }
      };

      apiDeleteData(url, handler);
    });
  }

  function apiDeleteAllVesselGroups(orgId: string) {
    return new Promise((resolve, reject) => {
      if (!orgId) {
        reject(new Error("orgId is missing"));
        return;
      }

      const url = `${theApiUrl}groups/${orgId}`;

      const handler = (responseJson: unknown, response: { ok: any }) => {
        if (response.ok) {
          resolve(responseJson);
        } else {
          reject(new Error("Failed to delete vessel groups"));
        }
      };

      apiDeleteData(url, handler);
    });
  }

  function apiPostGeoZonesAdd(
    org_id: string | null,
    name: string,
    shapeType: GeoZoneEdgeType,
    coords: coordinates,
    radius: number | undefined,
    state: EntityState
  ) {
    if (org_id === null) {
      return;
    }

    // Define the shape object based on the interface structure
    let shapeObject: geoZonesEntry["shape"];

    if (shapeType === GeoZoneEdgeType.Circle) {
      shapeObject = {
        type: "Circle",
        coordinates: Array.isArray(coords)
          ? coords.map((coord) => ({
              latitude: coord.latitude.toString(),
              longitude: coord.longitude.toString(),
            }))
          : [
              {
                latitude: coords.latitude.toString(),
                longitude: coords.longitude.toString(),
              },
            ],
        radius: radius?.toString(),
      };
    } else {
      shapeObject = {
        type: "Polygon",
        coordinates: Array.isArray(coords)
          ? coords.map((coord) => ({
              latitude: coord.latitude.toString(),
              longitude: coord.longitude.toString(),
            }))
          : [
              {
                latitude: coords.latitude.toString(),
                longitude: coords.longitude.toString(),
              },
            ],
      };
    }

    const body = JSON.stringify({
      name: name,
      shape: shapeObject,
      state: state,
      org_id: org_id,
    });

    const url = theApiUrl + "geozones/add";
    const handler = (responseJson: any) => {
      fetchDataGeoZonesTab();
    };

    apiPost(url, body, handler);
  }

  function apiDeleteCustomAlarms(
    data: DeleteAlarmEventPayload[]
  ): Promise<ApiResponseAxios> {
    return new Promise((resolve, reject) => {
      const url = `${theApiUrl}alarms-types/`;

      const handler = (response: ApiResponseAxios) => {
        resolve(response);
      };

      const body = JSON.stringify(data);

      apiDeleteDataAxios(url, handler, body);
    });
  }

  return {
    apiPostRootOrgsAdd,
    apiDeleteRootOrgs,
    apiPutRootOrgsRename,
    apiPostRootVesselsAdd,
    apiDeleteRootVessels,
    apiPutRootVesselsRename,
    apiUserAdd,
    apiUserDelete,
    apiUserRemove,
    apiUserRename,
    apiUserEditAccessLevel,
    apiUserRevokeOperatorStatus,
    apiUserGrantOperatorStatus,
    apiUserEditPin,
    apiUserResendConfirmation,
    apiPostDestsAdd,
    apiDeleteDests,
    apiPutDestsRename,
    apiPutDestsLocation,
    apiPutDestsState,
    apiPostTagTypesAdd,
    apiDeleteTagTypes,
    apiPutTagTypesState,
    apiPostTagEventsAdd,
    apiDeleteTagEvents,
    apiDeleteOpModes,
    apiPostOpModesAdd,
    apiPutOpModes,
    apiPutRoutes,
    apiPostRoutesAdd,
    apiDeleteRoutes,
    apiDeactivateRoute,
    apiSetStateRemoved,
    apiPostVoyagesAdd,
    apiDeleteVoyageTrip,
    apiPostGeoZonesAdd,
    apiDeleteGeoZones,
    apiPutGeoZones,
    apiDeleteSingleVesselGroup,
    apiDeleteAllVesselGroups,
    apiPostGroupsAdd,
    apiPutVesselGroups,
    addCustomAlarm,
    apiDeleteCustomAlarms,
  };
}
