import { Box, Modal } from "@mui/material";
import { CgClose } from "react-icons/cg";
import { iconButton, makeSubmitButton } from "./Button";
import { useState } from "react";

export function makeModal(title: string, content: JSX.Element, open: boolean, onClose: ()=>void, width?: number, height?: number) {
  return <Modal open={open} onClose={onClose} key={title}>
      <div className="outline-none">
        <Box position="absolute" right="0" left="0" top="0" bottom="0" margin="auto" className="h-fit w-fit min-w-[500px]">
          <div className="h-fit w-full bg-white rounded-lg font-bold shadow-modal flex flex-col space-y-4 p-4">
            <div className="mb-2 w-full h-8 flex justify-start items-center relative ">
              <div className="text-3xl pr-16">{title}</div>
              <CgClose onClick={onClose} className="text-4xl text-black absolute right-0 hover:cursor-pointer" />
            </div>
            {content}
          </div>
        </Box>
      </div>
    </Modal>
}

export interface menuModalOption {
  buttonIcon: JSX.Element
  buttonText: string
  onClick: ()=>void
  orangeBg?: boolean
}

export function makeMenuModal(title: string, options: menuModalOption[], modals: JSX.Element[], open: boolean, onClose: ()=>void, width?: number, height?: number) {
  const cont = 
    <div className="h-full overflow-scroll">
      <div className="h-fit flex flex-col space-y-4 justify-start items-start">
        {options.map((opt, index)=>iconButton(opt.buttonText, <div className="text-2xl">{opt.buttonIcon}</div>, opt.onClick, index.toString(), opt.orangeBg))}
      </div>
      {modals}
    </div>
  return makeModal(title, cont, open, onClose, width, height)
}

export function makeConfirmModal(title: string, text: string[]|string, buttonText: string, onConfirmClick: ()=>void, open: boolean, setOpen: (open: boolean)=>void, orangeButton = false) {
  function onClose() {
    setOpen(false)
  }
  const confirmButton = iconButton(buttonText, <></>, onConfirmClick, undefined, orangeButton)
  const textBits: string[] = (typeof text === 'string') ? [text] : text
  const cont = 
    <div className="h-full flex flex-col space-y-3 justify-between items-start text-2xl">
      <div className="w-full">
        {textBits.map((textBit, index)=>
          <div key={index} className="w-full overflow-clip overflow-ellipsis">
            {textBit}
          </div>
        )}
      </div>
      <div className="flex flex-col space-y-3 pt-8">
        <div>{'Are you sure?'}</div>
        {confirmButton}
      </div>
    </div>
  return makeModal(title, cont, open, onClose, 500, 350)
}

export interface submitCondition {
  condition: () => boolean
  action: () => void
}

export function StandardModalWithConfirmation(
  open: boolean, 
  setOpen: (open: boolean)=>void, 
  title: string,
  confirmTitle: string,
  confirmText: string | string[],
  confirmButtonText: string, 
  inputComponents: JSX.Element[], 
  onConfirm: ()=>void, 
  warningSetters?: ((warning: string|null)=>void)[], 
  submitConditions?: submitCondition[], 
) {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  function clearWarnings() {
    warningSetters?.forEach(element => {
      element(null)
    })
  }
  function onClose() {
    setOpen(false)
    clearWarnings()
  }
  function onSubmitClick() {
    clearWarnings()
    if(submitConditions !== undefined) {
      for(const element of submitConditions) {
        // console.log("Running submit cond")
        if(element.condition()) {
          // console.log("True cond")
          element.action()
          return
        }
      }
    }
    // No condition was true, proceed
    setConfirmModalOpen(true)
  }
  function onConfirmClick() {
    // console.log("Clicked confirm in "+confirmTitle)
    onConfirm()
    setConfirmModalOpen(false)
    onClose()
  }

  const content = <div className="flex flex-col space-y-4">
    {inputComponents}
    <div className="p-2"/>
    {makeSubmitButton(onSubmitClick)}
    {makeConfirmModal(confirmTitle, confirmText, confirmButtonText, onConfirmClick, confirmModalOpen, setConfirmModalOpen)}
  </div>

  return makeModal(title, content, open, onClose)
}

export interface menuModalStandardOption {
  id: string
  buttonIcon: JSX.Element
  buttonText: string
  orangeBg?: boolean
  showButton: boolean
  modalComponent: (open: boolean, setOpen: (open: boolean)=>void, managedId: string|null, onCloseManageModal: ()=>void)=>JSX.Element
}

export function MenuModalStandard(open: boolean, setOpen: (open: boolean)=>void, managedId: string|null, setManagedId: (id: string|null)=>void, title: string, options: menuModalStandardOption[]) {
  const [openOption, setOpenOption] = useState<string|null>(null)

  function onClose() {
    setManagedId(null)
    setOpen(false)
  }

  const menuOptions: menuModalOption[] = []
  const modals: JSX.Element[] = []

  options.forEach((option)=>{
    if(option.showButton) {
      const menuOption: menuModalOption = {buttonIcon: option.buttonIcon, buttonText: option.buttonText, onClick: ()=>setOpenOption(option.id), orangeBg: option.orangeBg}
      menuOptions.push(menuOption)
    }
    const modal = option.modalComponent(
      openOption === option.id,
      (open)=>setOpenOption(open ? option.id : null),
      managedId,
      onClose
    )
    modals.push(modal)
  })

  return makeMenuModal(title, menuOptions, modals, open, onClose)
}

export function makeConfirmModalWithIcons(title: string, content: JSX.Element, buttonText: string, onConfirmClick: () => void, open: boolean, setOpen: (open: boolean) => void, orangeButton = false) {
  function onClose() {
    setOpen(false);
  }

  const confirmButton = iconButton(buttonText, <></>, onConfirmClick, undefined, orangeButton);

  const modalContent = (
    <div className="h-full flex flex-col space-y-3 justify-between items-start text-2xl">
      <div className="w-full">
        {content}
      </div>
      <div className="flex flex-col space-y-3 pt-8">
        <div>{'Are you sure?'}</div>
        {confirmButton}
      </div>
    </div>
  );

  return makeModal(title, modalContent, open, onClose, 500, 350);
}
