import { makeConfirmModal, makeMenuModal, menuModalOption } from "../../../components/Modal";
import { HiOutlineTrash } from "react-icons/hi";
import useManageData from "../../../apiComms/manageData";
import { useAppContext } from "../../../context/variables";
import { epochToPrettyString } from "../../../helpers/dateTimeHelpers";
import { strNullToString } from "../../../helpers/stringHelpers";
import { useState } from "react";

function DeleteTagEventModal(open: boolean, setOpen: (open: boolean)=>void, managedId: string|null, onCloseManageModal: ()=>void, refreshList: ()=>void) {
  const { data } = useAppContext()
  const { apiDeleteTagEvents } = useManageData()
  const pieces = (managedId === null) ? null : managedId.split("+")
  const tagTypeId = (pieces === null || pieces.length < 1) ? null : pieces[0]
  const timestamp = (pieces === null || pieces.length < 2) ? null : pieces[1]

  const tagEventList = data.tagEvents.tagEventList.val
  const tagEvent = tagEventList?.find((tagEvent)=>tagEvent.tagTypeId === tagTypeId && tagEvent.timestamp === timestamp)

  const tagType = data.tagTypes.tagTypeList.val?.find((tagType)=>tagType.id === tagTypeId)
  const tagTypeStr = (tagType === undefined) ? "N/A" : tagType.name
  const prettyTime = (tagEvent === undefined) ? null : epochToPrettyString(new Date(tagEvent.timestamp).valueOf())
  const vesselId = tagEvent?.vesselId
  const vessel = data.org.vesselList.find((ves)=>ves.id === vesselId)
  const vesselName = (vessel === undefined) ? vesselId : vessel.name
  // console.log("in DeleteTagEventModal:", vesselName, vessel, vesselId, tagEvent, data.org.vesselList)

  function onConfirmClick() {
    // console.log("deleted the tag event: (", tagTypeId, timestamp, ")")
    apiDeleteTagEvents(tagTypeId, timestamp, refreshList)
    setOpen(false)
    onCloseManageModal()
  }
  return makeConfirmModal(
    "Delete tag",
    ['You are about to delete the following tag:',
      '- Vessel: '+vesselName,
      '- Date & time: '+strNullToString(prettyTime),
      '- Tag type: '+tagTypeStr,
    ],
    "Confirm: Delete tag",
    onConfirmClick, open, setOpen, true
    )
}

export function ManageTagEventModal(open: boolean, setOpen: (open: boolean)=>void, managedId: string|null, setManagedId: (id: string|null)=>void, refreshList: ()=>void) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const title = "Manage tag"
  const options: menuModalOption[] = [
    {buttonIcon: <HiOutlineTrash/>, buttonText: "Delete", onClick: ()=>setDeleteModalOpen(true), orangeBg: true}
  ]
  const modals: JSX.Element[] = [
    DeleteTagEventModal(deleteModalOpen, setDeleteModalOpen, managedId, onClose, refreshList),
  ]
  function onClose() {
    setManagedId(null)
    setOpen(false)
  }
  return makeMenuModal(title, options, modals, open, onClose)
}
