//--------------------------------------
// Enum types
//--------------------------------------

import { KNOTS_PER_MPS } from "./constants"
import { graphPoint } from "./variables"

export enum EntityState {
  Suggested = "Suggested",
  Active = "Active",
  Inactive = "Inactive",
  Rejected = "Rejected",
  Removed = "Removed",
}

export enum DestinationEdgeType {
  Circle = "Circle",
  Polygon = "Polygon",
}

export enum GeoZoneEdgeType {
  Circle = "Circle",
  Polygon = "Polygon",
}

export enum AccessLevel { // In increasing access order, which matters
  User = "User",
  Admin = "Admin",
  Owner = "Owner",
  Root = "Root",
}

export function accessLevelAtMinimum(userAccessLevel: AccessLevel|null, minimum: AccessLevel): boolean {
  if(userAccessLevel === null) {
    // console.log("userAccessLevel was null", userAccessLevel, minimum)
    return false
  }
  const list = Object.values(AccessLevel)
  const userIndex = list.findIndex((level)=>level === userAccessLevel)
  const minIndex = list.findIndex((level)=>level === minimum)
  return userIndex >= minIndex
}

export function accessLevelAbove(userAccessLevel: AccessLevel|null, minimum: AccessLevel|null): boolean {
  if(userAccessLevel === null || minimum === null) {
    return false
  }
  const list = Object.values(AccessLevel)
  const userIndex = list.findIndex((level)=>level === userAccessLevel)
  const minIndex = list.findIndex((level)=>level === minimum)
  return userIndex > minIndex
}

export enum Aggregation {
  Hour = "hour",
  Day = "day",
  Month = "month",
  Year = "year"
}

// export enum Metric {
//   AbsoluteFuel = "absoluteFuel",
//   FuelOverTime = "fuelOverTime",
//   FuelOverDistance = "fuelOverDistance",
//   AbsoluteFuelAtDock = "absoluteFuelAtDock",
//   FuelOverTimeAtDock = "fuelOverTimeAtDock",
//   FuelOverDistanceAtDock = "fuelOverDistanceAtDock",
// }

export enum Timescale {
  Day = "day",
  Week = "week",
  Month = "month",
  Year = "year",
}

export enum PerformanceGraph {
  EngineSpeedTorqueMapChart = "EngineSpeedTorqueMapChart",
  SogDistChart = "SogDistChart",
  EngineDieselRateSogMapChart = "EngineDieselRateSogMapChart",
  EnginePowerSogMapChart = "EnginePowerSogMapChart",
}

export const performanceGraphPretty = {
  [PerformanceGraph.EngineSpeedTorqueMapChart]: "Torque-Engine speed map",
  [PerformanceGraph.SogDistChart]: "Speed over ground distribution",
  [PerformanceGraph.EngineDieselRateSogMapChart]: "Diesel usage-Speed over ground map",
  [PerformanceGraph.EnginePowerSogMapChart]: "Electricity usage-Speed over ground map",
}

export enum ReportTimescale {
  Weekly = "weekly",
  Monthly = "monthly",
  Yearly = "yearly",
  Custom = "custom",
}

export enum TagValueType {
  Boolean = "boolean",
  Number = "number",
  String = "string",
  None = "none",
}

export const tagValueTypePretty = {
  [TagValueType.Boolean]: "True/False",
  [TagValueType.Number]: "Number",
  [TagValueType.String]: "Text",
  [TagValueType.None]: "No value",
};

export enum TagValueBoolean {
  True = "True",
  False = "False",
}

export function tagValueBooleanToBoolean(value: TagValueBoolean): boolean {
  return (value === TagValueBoolean.True)
}

export enum TagScope {
  LegInstance = "leg_instance",
  Trip = "trip",
  DockingInstance = "docking_instance",
  VoyageInstance = "voyage_instance",
}

export const tagScopePretty = {
  [TagScope.LegInstance]: "Leg",
  [TagScope.Trip]: "Trip",
  [TagScope.DockingInstance]: "Docking",
  [TagScope.VoyageInstance]: "Voyage",
}

export interface graphTimePoint {
  timestamp: number;
  [key: string]: any;
}

export interface dataSeries {
  series: string;
  data: graphPoint[];
}

export interface graphSeriesColor {
  name: string;
  lightShade: string;
  darkShade: string;
}

export enum OperatorTabMetric {
  FuelVolume = "FuelVolume",
  FuelOverTime = "FuelOverTime",
  FuelOverDistance = "FuelOverDistance",
  TimeLoggedIn = "TimeLoggedIn",
  ElectricEnergy = "ElectricEnergy",
  ElectricOverTime = "ElectricOverTime",
  ElectricOverDistance = "ElectricOverDistance",
}

export const operatorTabMetricMap = {
  [OperatorTabMetric.FuelVolume]: { name: "Fuel volume", unitShort: "L", unitLong: "Litres" },
  [OperatorTabMetric.FuelOverTime]: { name: "Fuel over time", unitShort: "L/h", unitLong: "Litres/Hour" },
  [OperatorTabMetric.FuelOverDistance]: { name: "Fuel over distance", unitShort: "L/NM", unitLong: "Litres/Nautical mile" },
  [OperatorTabMetric.TimeLoggedIn]: { name: "Time recorded", unitShort: "h", unitLong: "Hours" },
  [OperatorTabMetric.ElectricEnergy]: { name: "Electric energy", unitShort: "kWh", unitLong: "Kilowatt-hours" },
  [OperatorTabMetric.ElectricOverTime]: { name: "Electricity over time", unitShort: "kW", unitLong: "Kilowatts" },
  [OperatorTabMetric.ElectricOverDistance]: { name: "Electricity over distance", unitShort: "kWh/NM", unitLong: "Kilowatt-hours/Nautical mile" },
}

export enum VesselActivity {
  Total = "total",
  Sailing = "sailing",
  AtDock = "atDock",
}

export const prettyVesselActivityName = {
  [VesselActivity.Total]: "Total",
  [VesselActivity.Sailing]: "Sailing",
  [VesselActivity.AtDock]: "At dock",
}

export enum RealTimeSignal {
  FuelOverTime = "FuelOverTime",
  ElectricOverTime = "ElectricOverTime",
  WindDirection = "WindDirection",
  WindSpeed = "WindSpeed",
  SpeedOverGround = "SpeedOverGround",
  // HeadingTrue = "HeadingTrue",
  CourseOverGroundTrue = "CourseOverGroundTrue",
}

export const realTimeSignalMap = {
  [RealTimeSignal.FuelOverTime] : {prettyName: "Fuel over time", unitShort: "L/h", apiRequestName: "fuel_lph", apiColumnName: "avg_fuel_lph", conversionFactor: 1},
  [RealTimeSignal.ElectricOverTime] : {prettyName: "Electricity over time", unitShort: "kW", apiRequestName: "elec_kw", apiColumnName: "avg_elec_kw", conversionFactor: 1},
  [RealTimeSignal.WindDirection] : {prettyName: "Wind direction", unitShort: "deg", apiRequestName: "wind_direc", apiColumnName: "avg_wind_direc", conversionFactor: 1},
  [RealTimeSignal.WindSpeed] : {prettyName: "Wind speed", unitShort: "m/s", apiRequestName: "wind_speed_mps", apiColumnName: "avg_wind_speed_mps", conversionFactor: 1},
  [RealTimeSignal.SpeedOverGround] : {prettyName: "Speed over ground", unitShort: "kn", apiRequestName: "sog_mps", apiColumnName: "avg_sog_mps", conversionFactor: KNOTS_PER_MPS},
  // [RealTimeSignal.HeadingTrue] : {prettyName: "Heading (true)", unitShort: "deg", apiRequestName: "heading_true", apiColumnName: "avg_heading_true", conversionFactor: 1},
  [RealTimeSignal.CourseOverGroundTrue] : {prettyName: "Course over ground (true)", unitShort: "deg", apiRequestName: "cog_true", apiColumnName: "avg_cog_true", conversionFactor: 1},
}

//--------------------------------------
// Converters for enum types to/from string
//--------------------------------------

export function aggregationToString (agg: Aggregation): string {
  return agg
}

export function stringToAggregation (str: string): Aggregation|null {
  const aggregation = Object.values(Aggregation).find((x) => {
    return (x === str)
  })
  if (aggregation !== undefined) {
    return aggregation
  } else {
    return null
  }
}

// export function metricToString (metric: Metric): string {
//   return metric
// }

// export function stringToMetric (str: string): Metric|null {
//   const metric = Object.values(Metric).find((x) => {
//     return (x === str)
//   })
//   if (metric !== undefined) {
//     return metric
//   } else {
//     return null
//   }
// }

export function timescaleToString (tim: Timescale): string {
  return tim
}

export function stringToTimescale (str: string): Timescale|null {
  const timescale = Object.values(Timescale).find((x) => {
    return (x === str)
  })
  if (timescale !== undefined) {
    return timescale
  } else {
    return null
  }
}

export function operatorTabMetricToString(metric: OperatorTabMetric): string {
  return metric
}

export function stringToOperatorTabMetric(str: string): OperatorTabMetric | null {
  const operatorTabMetric = Object.values(OperatorTabMetric).find((el) => el === str)
  if (operatorTabMetric !== undefined) {
    return operatorTabMetric
  } else {
    return null
  }
}

export function vesselActivityToString(activity: VesselActivity): string {
  return activity
}

export function stringToVesselActivity(str: string): VesselActivity | null {
  const maybeActivity = Object.values(VesselActivity).find((el) => el === str)
  if (maybeActivity !== undefined) {
    return maybeActivity
  } else {
    return null
  }
}

export function vesselActivitiesToString(activities: VesselActivity[]): string {
  const joinedActivities = activities.join()
  // console.log("joinedActivities:", joinedActivities, typeof joinedActivities)
  return joinedActivities
}

export function stringToVesselActivities(str: string): VesselActivity[] | null {
  const activitiesStr = str.split(",")
  // console.log("split up:", activitiesStr)
  const activities: VesselActivity[] = []
  activitiesStr.forEach((activityStr: string) => {
    const maybeActivity = Object.values(VesselActivity).find((el) => el === activityStr)
    if (maybeActivity !== undefined) {
      activities.push(maybeActivity)
    }
  })
  return activities
}

export function entityStateToString (state: EntityState): string {
  return state
}

export function stringToEntityState (str: string): EntityState|null {
  const entityState = Object.values(EntityState).find((x) => {
    return (x === str)
  })
  if (entityState !== undefined) {
    return entityState
  } else {
    return null
  }
}

export function stringToEnum<T extends string, K extends string>(enumType: {[key in K]: T}, str: string): T|null {
  const enumMaybe = Object.values<T>(enumType).find((x) => {
    return x === str
  })
  // console.log("Found the enum:", enumMaybe, str, enumType)
  if(enumMaybe !== undefined) {
    return enumMaybe
  } else {
    return null
  }
}

export function tagValueTypeToString(tagValueType: TagValueType): string {
  return tagValueType
}

export function stringToTagValueType(str: string): TagValueType|null {
  return stringToEnum(TagValueType, str)
}

export function tagScopeToString(tagScope: TagScope): string {
  return tagScope
}

export function stringToTagScope(str: string): TagScope|null {
  return stringToEnum(TagScope, str)
}
