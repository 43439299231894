import { useEffect, useState } from "react"
import { coords, coordsGothenburgKindaCentral, initializeMap, latToPrettyString, lngToPrettyString, makeLatLng } from "../../../components/Map"
import { apiData, destinationEntry, variables } from "../../../context/variables"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"

// let map: google.maps.Map
// let mapInitialized = false

let allDestMarkers: google.maps.Marker[] = []
let allDestCircles: google.maps.Circle[] = []
let allDestPolys: google.maps.Polygon[] = []
let chosenDestMarker: google.maps.Marker
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let chosenDestCircle: google.maps.Circle
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let chosenDestPoly: google.maps.Polygon
let chosenCoords: coords|null
let chosenContentString: string
let infoWindow: google.maps.InfoWindow|undefined

export function MainDestsMap(data: apiData, vars: variables, chosenDest: string|null, chooseDest: (x: string|null)=>void, destinationList: destinationEntry[]|null): JSX.Element {
  // console.log("in MainDestsMap")
  const [map, setMap] = useState<google.maps.Map|undefined>(undefined)
  const [mapInitialized, setMapInitialized] = useState(false)
  // console.log("map vars state:", map, typeof map, mapInitialized)
  const orgsList = data.org.organisationList
  const organisationId = vars.auth.organisationId
  const isRoot = vars.auth.userGroups !== null && vars.auth.userGroups.includes("root")
  const showExtraFields = organisationId === null && isRoot

  function mapInitCustom(map: google.maps.Map) {
    infoWindow = new google.maps.InfoWindow()
  }
  useEffect(()=>{
    initializeMap(map, setMap, "map_canvas", mapInitCustom, setMapInitialized, coordsGothenburgKindaCentral, 10)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=>{
    // console.log("mapInitialized:", mapInitialized)
    if(mapInitialized && map !== undefined) {
      // console.log("useEffect for centering map")
      if(destinationList !== null && destinationList.length > 0) {
        map.setCenter(makeLatLng(destinationList[0].latitude, destinationList[0].longitude))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapInitialized, data.org.destinationList])

  if(mapInitialized) {
    if(chosenDest === null) {
      chosenCoords = null
    }
    for (let i = 0; i < allDestMarkers.length; i++) {
      allDestMarkers[i].setMap(null)
    }
    for (let i = 0; i < allDestCircles.length; i++) {
      allDestCircles[i].setMap(null)
    }
    for (let i = 0; i < allDestPolys.length; i++) {
      allDestPolys[i].setMap(null)
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    allDestMarkers = (destinationList === null) ? [] : destinationList.map((destData)=>{
      const destCoords = makeLatLng(destData.latitude, destData.longitude)
      const markerColor = (destData.lid === chosenDest) ? "#ff4d00" : "#4bd4ba"
      const destMarker = new google.maps.Marker({
        map: map,
        position: destCoords,
        icon: {
          path: faMapMarkerAlt.icon[4] as string,
          fillColor: markerColor,
          fillOpacity: 1,
          anchor: new google.maps.Point(
            faMapMarkerAlt.icon[0] / 2, // width
            faMapMarkerAlt.icon[1] // height
          ),
          strokeWeight: 1,
          strokeColor: "#000000",
          scale: 0.075,
        },
      })
      if(destData.lid === chosenDest) {
        chosenDestMarker = destMarker
        chosenCoords = destCoords
        // map?.setCenter(chosenCoords)
        const orgMaybe = orgsList?.find((org)=>org.id === destData.orgUid)
        const orgName = (orgMaybe === undefined) ? "N/A" : orgMaybe.name
        chosenContentString =
          '<div id="content">' +
          '<div id="siteNotice">' +
          "</div>" +
          // '<h1 id="firstHeading" class="firstHeading">' + destData.displayName + '</h1>' +
          '<div id="bodyContent">' +
          '<p><b>Name</b>: '+destData.displayName+"</p>" +
          '<p><b>Latitude</b>: '+latToPrettyString(destData.latitude)+"</p>" +
          '<p><b>Longitude</b>: '+lngToPrettyString(destData.longitude)+"</p>" +
          '<p><b>State</b>: '+destData.state+"</p>" +
          (showExtraFields ? '<p><b>lid</b>: '+destData.lid+"</p>" : "") +
          (showExtraFields ? '<p><b>org</b>: '+destData.orgUid+" ("+orgName+")</p>" : "") +
          // '<p><b>Radius</b>: '+destData.radius+"</p>" +
          "</div>" +
          "</div>";
      }
      destMarker.addListener("click", ()=>{
        chooseDest(destData.lid)
      })
      return destMarker
    })
    allDestCircles = (destinationList === null) ? [] : destinationList.map((destData)=>{
      const destCoords = makeLatLng(destData.latitude, destData.longitude)
      const circleColor = (destData.lid === chosenDest) ? "#ff4d00" : "#4bd4ba"
      const radiusIfNoPoly = (destData.polygon !== null) ? null : destData.radius
      const destCircle = new google.maps.Circle({
        map: map,
        center: destCoords,
        radius: radiusIfNoPoly,
        strokeColor: circleColor,
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: circleColor,
        fillOpacity: 0.5,
      })
      if(destData.lid === chosenDest) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        chosenDestCircle = destCircle
      }
      return destCircle
    })
    allDestPolys = (destinationList === null) ? [] : destinationList.map((destData)=>{
      const circleColor = (destData.lid === chosenDest) ? "#ff4d00" : "#4bd4ba"
      const path = (destData.polygon === null) ? [] : destData.polygon
      const destPoly = new google.maps.Polygon({
        map: map,
        paths: path,
        strokeColor: circleColor,
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: circleColor,
        fillOpacity: 0.5,
        geodesic: true,
      })
      if(destData.lid === chosenDest) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        chosenDestPoly = destPoly
      }
      return destPoly
    })
    // console.log("allDestMarkers:", allDestMarkers)
    if(infoWindow !== undefined) {
      infoWindow.setPosition(chosenCoords)
      if(chosenCoords !== null) {
        infoWindow.open({
          anchor: chosenDestMarker
        })
        infoWindow.setContent(chosenContentString)
      }
    }
  }
  return <div id="map_canvas" className="w-full h-[28rem] rounded-xl text-black bg-[#8c8c8c] border-2 shadow-[4px_4px_8px_2px_rgba(0,0,0,0.1)]"/>
}
