type IconLabelProps = {
  icon: React.ReactNode;
  label: string;
};

const IconLabel: React.FC<IconLabelProps> = ({ icon, label }) => (
  <div className="flex items-center">
    <div className="mr-2">{icon}</div>
    <div>{label}</div>
  </div>
);

export default IconLabel;
