import { useState } from "react"
import { useAppContext } from "../../../../context/variables"
import { StandardModalWithConfirmation, submitCondition } from "../../../../components/Modal"
import useManageData from "../../../../apiComms/manageData"
import { makeTextInput } from "../../../../components/FormInputs"
import { getTextFieldValue, isTextFieldValueEmpty } from "../../../../helpers/elementHelpers"
import { legalPin } from "../../MembersTab"

export default function EditPinModal(open: boolean, setOpen: (open: boolean)=>void, managedUserId: string|null, onCloseManageUserModal: ()=>void) {
  const { apiUserEditPin } = useManageData()
  const { data } = useAppContext()

  const [pinWarning, setPinWarning] = useState<string|null>(null)

  const title = "Edit PIN"
  const confirmTitle = "Confirm editing PIN"
  const theUserId = (managedUserId !== null) ? managedUserId : "N/A"
  const userName = data.org.userList?.find((user)=>user.username === managedUserId)?.name
  const confirmText = [
    'You are about to edit the PIN for "'+userName+'" ('+theUserId+')',
    'to "'+getTextFieldValue("newPin")+'".'
  ]
  const confirmButtonText = "Edit PIN"

  const inputComponents = [
    makeTextInput("PIN:", "The four digit PIN used to log in onboard vessels", "2345", "newPin", pinWarning)
  ]
  function onConfirm() {
    apiUserEditPin(managedUserId, getTextFieldValue("newPin"))
    onCloseManageUserModal()
  }
  const warningSetters = [setPinWarning]
  const submitConditions: submitCondition[] = [{
    condition: ()=>isTextFieldValueEmpty(getTextFieldValue("newPin")),
    action: ()=>setPinWarning("Please enter a four-digit PIN")
  },
  {
    condition: ()=>!isTextFieldValueEmpty(getTextFieldValue("newPin")) && !legalPin(getTextFieldValue("newPin")),
    action: ()=>setPinWarning("Invalid PIN: Please enter four digits")
  }]

  return StandardModalWithConfirmation(open, setOpen, title, confirmTitle, confirmText, confirmButtonText, inputComponents, onConfirm, warningSetters, submitConditions)
}
