import React from "react";
import { MdClose } from "react-icons/md";
import { ModalOverlay, ModalContent, CloseButton } from "./Modal.styles";

interface ModalProps {
  children: React.ReactNode;
  theme?: "light" | "dark";
  isOpen: boolean;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({
  children,
  theme = "light",
  isOpen,
  onClose,
}) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent theme={theme} onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>
          <MdClose />
        </CloseButton>
        {children}
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;
