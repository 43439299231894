import React, { useEffect, useState } from "react";

import { makeDropdownInput, makeTextInput } from "../../../components/FormInputs";
import { makeConfirmModal, makeModal } from "../../../components/Modal";
import { makeSubmitButton } from "../../../components/Button";
import { OpModeState, useAppContext } from "../../../context/variables";
import { AccessLevel, accessLevelAtMinimum } from "../../../context/types";

import { strNullToString } from "../../../helpers/stringHelpers";
import useManageData from "../../../apiComms/manageData";
import { getTextFieldValue } from "../../../helpers/elementHelpers";

export function AddOpModeModal(open: boolean, setOpen: (open: boolean) => void) {
  const { data, vars } = useAppContext();
  const { apiPostOpModesAdd } = useManageData();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [nameWarning, setNameWarning] = useState<string | null>(null);
  const [descriptionWarning, setDescriptionWarning] = useState<string | null>(null);
  const [orgWarning, setOrgWarning] = useState<string | null>(null);
  const [org, setOrg] = useState<string | null>(null);
  const [state, setState] = useState<OpModeState>(OpModeState.Active);

  const orgOptions = data.org.organisationList === null ? [] : data.org.organisationList.map((org) => ({ value: org.id, name: org.name }));
  const stateOptions = Object.values(OpModeState).map((state) => ({ value: state, name: state }));
  const accessLevel = vars.auth.accessLevel[0];
  const showOrg = accessLevelAtMinimum(accessLevel, AccessLevel.Root);


  function clearWarnings() {
    setNameWarning(null);
    setDescriptionWarning(null);
    setOrgWarning(null);
  }

  function resetValues() {
    setOrg(vars.auth.organisationId);
    setState(OpModeState.Active);
  }

  function onClose() {
    setOpen(false);
    clearWarnings();
    resetValues();
  }

  function onSubmitClick() {
    clearWarnings();

    const theOpModeName = getTextFieldValue("opModeName");

    if (!theOpModeName || theOpModeName.length < 1) {
      setNameWarning("Please enter a name");
    } else if (accessLevelAtMinimum(vars.auth.accessLevel[0], AccessLevel.Root) && org === null) {
      setOrgWarning("No organisation chosen");
    } else {
      setConfirmModalOpen(true);
    }
  }

  function onConfirmClick() {
    const nameVal = getTextFieldValue("opModeName");
    const descriptionVal = getTextFieldValue("opModeDescription");

    if (nameVal === null || descriptionVal === null || org === null) {
      return;
    }

    data.opModes.opModeList.set(null);
    apiPostOpModesAdd(org, nameVal, descriptionVal, state);
    setConfirmModalOpen(false);
    onClose();
  }

  const nameField = makeTextInput(
    "Name:",
    "Provide a unique name that will represent this operational mode throughout the system.",
    "e.g. Eco Mode",
    "opModeName",
    nameWarning
  );

  const descriptionField = makeTextInput(
    "Description:",
    "Optionally offer a brief overview that clarifies the purpose or characteristics of this operational mode.",
    "e.g. Energy-saving operational setting",
    "opModeDescription",
    descriptionWarning
  );

  useEffect(() => {
    setOrg(vars.auth.organisationId);
  }, [vars.auth.organisationId]);

  const maybeOrgPicker = showOrg ? makeDropdownInput(
    "Organisation:",
    "Select the organization where this operational mode will be applied.",
    "Organisation",
    org, orgOptions, setOrg, orgWarning
  ) : <></>;

  const maybeStatePicker = showOrg ? makeDropdownInput(
    "State:",
    "Establishes the initial status of the operational mode. Defaults to 'Active' when adding. Can be modified later in Edit mode.",
    "State",
    state,
    stateOptions,
    setState,
    null,
    true
  ) : <></>;

  const confirmText = [
    "You are about to add the following operational mode:",
    `- Name: "${getTextFieldValue("opModeName")}"`,
    `- Description: ${strNullToString(getTextFieldValue("opModeDescription"), (description) => `"${description}"`, "No description")}`,
    showOrg ? `- Organisation: ${strNullToString(org, (orgId) => orgId, "Not set")} (${orgOptions.find((entry) => entry.value === org)?.name})` : "",
    `- State: ${state}`,
  ];

  const confirmModal = makeConfirmModal(
    "Confirm adding operational mode",
    confirmText,
    "Confirm: Add operational mode",
    onConfirmClick,
    confirmModalOpen,
    setConfirmModalOpen
  );

  const content =
    <div className="flex flex-col space-y-4">
      <div className="mb-4">
        {nameField}
      </div>
      {descriptionField}
      {maybeOrgPicker}
      {maybeStatePicker}
      {makeSubmitButton(onSubmitClick)}
      {confirmModal}
    </div>;

  return makeModal("Add new operational mode", content, open, onClose);
}
