import { styled } from "styled-components";
export const StyledActionsWrapper = styled.div<{ isHidden: boolean }>`
  display: ${(props) => (props.isHidden ? "none" : "flex")};
  justify-content: space-between;
  margin-top: 1.25rem;
`;

export const StyledIconWrapper = styled.div`
  color: #9ca3af;
  margin-bottom: 1.25rem;
`;

export const StyledMessageWrapper = styled.div`
  margin-top: 1.25rem;
  font-size: 1rem;
  color: #fff;
`;

export const StyledFailedTitle = styled.p`
  color: #dc2626;
  font-size: 1rem;
  font-weight: 600;
`;
