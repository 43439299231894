import styled from "styled-components";

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px; /* Spacing between rows */
`;

export const StyledTableHead = styled.thead`
  background-color: #f3f9fd;
  height: 50px;
`;

export const StyledTableRow = styled.tr`
  background-color: white;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 50px; /* Height of each row */
`;

export const StyledTableCell = styled.td.withConfig({
  shouldForwardProp: (prop) => !["isFirst", "isLast"].includes(prop),
})<{ isFirst?: boolean; isLast?: boolean }>`
  padding: ${({ isFirst, isLast }) =>
    isFirst ? "5px 30px 5px 16px" : isLast ? "5px 16px" : "5px 20px"};
  text-align: left;
  color: #143452;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  border-top-left-radius: ${({ isFirst }) => (isFirst ? "10px" : "0")};
  border-bottom-left-radius: ${({ isFirst }) => (isFirst ? "10px" : "0")};
  border-top-right-radius: ${({ isLast }) => (isLast ? "10px" : "0")};
  border-bottom-right-radius: ${({ isLast }) => (isLast ? "10px" : "0")};
`;

export const StyledTableHeaderCell = styled.th.withConfig({
  shouldForwardProp: (prop) => !["isFirst", "isLast"].includes(prop),
})<{ isFirst?: boolean; isLast?: boolean }>`
  padding: ${({ isFirst, isLast }) =>
    isFirst ? "5px 30px 5px 16px" : isLast ? "5px 0" : "5px 20px"};
  text-align: left;
  color: #143452;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  border-top-left-radius: ${({ isFirst }) => (isFirst ? "10px" : "0")};
  border-bottom-left-radius: ${({ isFirst }) => (isFirst ? "10px" : "0")};
  border-top-right-radius: ${({ isLast }) => (isLast ? "10px" : "0")};
  border-bottom-right-radius: ${({ isLast }) => (isLast ? "10px" : "0")};
`;

export const IconTableCell = styled.td.withConfig({
  shouldForwardProp: (prop) => !["isFirst", "isLast"].includes(prop),
})<{ isFirst?: boolean; isLast?: boolean }>`
  padding: 5px 0;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #143452;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  border-top-left-radius: ${({ isFirst }) => (isFirst ? "10px" : "0")};
  border-bottom-left-radius: ${({ isFirst }) => (isFirst ? "10px" : "0")};
  border-top-right-radius: ${({ isLast }) => (isLast ? "10px" : "0")};
  border-bottom-right-radius: ${({ isLast }) => (isLast ? "10px" : "0")};

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 20px;
  }
  svg {
    width: 30px;
    height: 30px;
  }
`;

export const EmptyStateMessage = styled.span`
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  color: #143452;
`;
