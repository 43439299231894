import React, { useEffect, useState } from "react";
import {
  makeTextInputWithCallback,
  MultiDropdownCheckboxInput,
} from "../../../components/FormInputs";
import { makeConfirmModal, makeModal } from "../../../components/Modal";
import { makeSubmitButton } from "../../../components/Button";
import { CheckboxProps, useAppContext } from "../../../context/variables";
import useManageData from "../../../apiComms/manageData";
import useFetchData from "../../../apiComms/fetchData";
import LoadingSpinner from "../../../components/atoms/LoadingSpinner";

export function AddVesselGroupModal(
  open: boolean,
  setOpen: (open: boolean) => void
) {
  const { data, vars } = useAppContext();
  const { apiPostGroupsAdd } = useManageData();
  const { fetchDataOrganisations, fetchDataVesselGroups } = useFetchData();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [nameWarning, setNameWarning] = useState<string | null>(null);
  const [name, setName] = useState("");
  const [groupReport, setGroupReport] = useState(false);
  const [sisterReport, setSisterReport] = useState(false);
  const [sisters, setSisters] = useState(false);
  const [selectedVesselIds, setSelectedVesselIds] = useState<string[]>([]);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDataOrganisations();
  }, [vars.auth.organisationId, vars.auth.accessLevel[0]]);

  useEffect(() => {
    setName("");
    setNameWarning(null);
    setGroupReport(false);
    setSisterReport(false);
    setSisters(false);
    setSelectedVesselIds([]);
  }, [open]);

  function onClose() {
    setOpen(false);
  }

  function onSubmitClick() {
    setSubmitAttempted(true);
    if (!name || name.length < 1) {
      setNameWarning("Please enter a name");
    } else {
      setConfirmModalOpen(true);
    }
  }
  function onConfirmClick() {
    setLoading(true);
    apiPostGroupsAdd(
      vars.auth.organisationId,
      name,
      selectedVesselIds,
      groupReport,
      sisterReport,
      sisters
    )
      .then(() => {
        fetchDataVesselGroups();
        setOpen(false);
        setConfirmModalOpen(false);
      })
      .catch((error) => {
        console.error("Failed to add vessel group:", error);
      })
      .finally(() => {
        setLoading(false);
        setName("");
        setSelectedVesselIds([]);
      });
  }

  const nameField = makeTextInputWithCallback(
    "Name:",
    "Provide a unique name for the vessel group.",
    "e.g. Vessel Group 1",
    "vesselGroupName",
    nameWarning,
    undefined,
    "Large",
    (newValue: string) => setName(newValue) 
  );

  const Checkbox: React.FC<CheckboxProps> = ({
    id,
    checked,
    setChecked,
    label,
  }) => (
    <div className="flex items-center space-x-2 mb-4">
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
        className="form-checkbox h-5 w-5 border-gray-300 rounded text-blue-600 focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-blue-200"
      />
      <label
        htmlFor={id}
        className="cursor-pointer hover:text-blue-500 flex justify-center items-center text-gray-700 text-xl"
      >
        {label}
      </label>
    </div>
  );

  const groupReportCheckbox = (
    <Checkbox
      id="group-report-checkbox"
      checked={groupReport}
      setChecked={setGroupReport}
      label="Group Report"
    />
  );
  const sisterReportCheckbox = (
    <Checkbox
      id="sister-report-checkbox"
      checked={sisterReport}
      setChecked={setSisterReport}
      label="Sister Report"
    />
  );
  const sistersCheckbox = (
    <Checkbox
      id="sisters-checkbox"
      checked={sisters}
      setChecked={setSisters}
      label="Sisters"
    />
  );

  const handleMultiVesselGroupSelection = (value: string[]) => {
    setSelectedVesselIds(value);
  };

  const selectedVesselsString = selectedVesselIds.join(",");

  const vesselGroupMultiDropdown = (
    <MultiDropdownCheckboxInput
      title="Select Vessels:"
      tooltipText="Choose the vessel groups for this group"
      prompt="Vessels"
      selectedValues={selectedVesselIds}
      destinationOptions={data.org.vesselList.map((vessel) => ({
        value: vessel.id,
        name: vessel.name,
      }))}
      onSelectionChange={handleMultiVesselGroupSelection}
      warning={
        submitAttempted && selectedVesselIds.length === 0
          ? "Please select at least one vessel group"
          : null
      }
      pageType="vessels"
    />
  );

  const getVesselNameFromId = (vesselId: any) => {
    if (data.org.vesselList) {
      return (
        data.org.vesselList.find(
          (vessel: { id: any }) => vessel.id === vesselId
        )?.name || "N/A"
      );
    } else {
      console.error("data.org.vesselList is undefined");
      return "N/A";
    }
  };

  const confirmModal = makeConfirmModal(
    "Confirm adding vessel group",
    [
      `You are about to add the following vessel group:`,
      `- Name: "${name}"`,
      `- Group Report: ${groupReport ? "Yes" : "No"}`,
      `- Sister Report: ${sisterReport ? "Yes" : "No"}`,
      `- Sisters: ${sisters ? "Yes" : "No"}`,
      `- Vessels: ${
        selectedVesselIds.length > 0
          ? selectedVesselIds.map(getVesselNameFromId).join(", ")
          : "No vessels available"
      }`,
    ],
    "Confirm: Add vessel group",
    onConfirmClick,
    confirmModalOpen,
    setConfirmModalOpen
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  const content = (
    <div className="flex flex-col space-y-4">
      <div className="mb-4">{nameField}</div>
      <div className="mb-6">{vesselGroupMultiDropdown}</div>{" "}
      <div className="mb-4">{groupReportCheckbox}</div>
      <div className="mb-4">{sisterReportCheckbox}</div>
      <div className="mb-4">{sistersCheckbox}</div>
      {makeSubmitButton(onSubmitClick)}
      {confirmModal}
    </div>
  );

  return (
    <>
      {loading ? null : makeModal("Add new vessel group", content, open, onClose)}
    </>
  );
}
