import { CognitoUserPool } from "amazon-cognito-identity-js";
import { envConfig } from "../env";
import { variables } from "../../context/variables";
import { NavigateFunction } from "react-router-dom";
import { cognitoLogin, cognitoLogout } from "../../auth/cognito";

// -- AWS production environment config

const awsProductionCognitoUserPool = new CognitoUserPool({
  UserPoolId: "eu-west-1_3TVH7lxv9",
  ClientId: "nobgvggmc8b8o3bl49hu3msss",
});

export const productionConfig: envConfig = {
  auth: {
    apiUrl: "https://api.cetasol.com/",
    loginPageFields: true,
    forgotPwLink:
      "https://auth.cetasol.com/forgotPassword?client_id=nobgvggmc8b8o3bl49hu3msss&response_type=code&redirect_uri=https%3A%2F%2Fapp.cetasol.com%2F",
    initFunction: () => {},
    loginFunction: (
      vars: variables,
      navigate: NavigateFunction,
      theUsername: string,
      thePassword: string
    ) => {
      cognitoLogin(
        vars,
        navigate,
        theUsername,
        thePassword,
        awsProductionCognitoUserPool
      );
    },
    logoutFunction: () => cognitoLogout(awsProductionCognitoUserPool),
    getApiAuthorization: (vars) => vars.auth.idToken,
  },
  features: {
    overview: {
      fuel_total_this_week_card: true,
      vessels_card: true,
      user_details_card: true,
      custom_alarms_panel: false,
    },
    realtime: true,
    energy: {
      overall: true,
      operators: true,
    },
    performance: true,
    reports: true,
    logbook: {
      trips: true,
      tags: true,
    },
    administration: {
      destinations: true,
      geozones: false,
      users: true,
      operation_modes: true,
      vessel_groups: false,
      tag_types: true,
      voyage_trips: true,
    },
    customAlarms: false,
  },
};
