import { useEffect, useState } from "react";
import { format } from "date-fns";
import { FaTrashAlt } from "react-icons/fa";
import {
  useAppContext,
  GenericTableWithDeleteIconRow,
} from "../../../context/variables";
import { AccessLevel, accessLevelAtMinimum } from "../../../context/types";
import useFetchData from "../../../apiComms/fetchData";
import { getAllVesselIds } from "../../../apiComms/helpers";
import { addButton } from "../../../components/Button";
import {
  genericTableRelatedBits,
  genericTableWithIconAction,
} from "../../../components/Table";
import LoadingSpinner from "../../../components/atoms/LoadingSpinner";
import EmptyState from "../../../components/atoms/EmptyState";
import HandTabletWrittenIcon from "../../../assets/icons/EmptyStates/HandTabletWrittenIcon";
import { AddVoyageModal } from "./AddVoyageModal";
import { DeleteVoyageTripModal } from "./DeleteVoyageTripModal";

export default function VoyageTrips() {
  const { vars, data } = useAppContext();
  const { fetchDataVoyages, fetchDataOrganisations } = useFetchData();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [deleteVoyageModalOpen, setDeleteVoyageModalOpen] = useState(false);
  const [managedOrgId, setManagedOrgId] = useState<string | null>(null);
  const [managedVoyageId, setManagedVoyageId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  const voyages = data.voyages.voyageList.val;
  const vesselIds = getAllVesselIds(data.org.vesselList);

  useEffect(() => {
    const fetchOrganisations = async () => {
      setIsLoading(true);
      await fetchDataOrganisations();
      setIsLoading(false);
    };
    fetchOrganisations();
  }, [vars.auth.idToken, vars.auth.organisationId]);

  useEffect(() => {
    const fetchVoyages = async () => {
      setIsLoading(true);
      await fetchDataVoyages();
      setIsLoading(false);
    };
    fetchVoyages();
  }, [vars.auth.organisationId, vars.auth.accessLevel[0]]);

  const isRootUser = accessLevelAtMinimum(
    vars.auth.accessLevel[0],
    AccessLevel.Root
  );
  const hasManagementPermissions =
    accessLevelAtMinimum(vars.auth.accessLevel[0], AccessLevel.Admin) ||
    isRootUser;

  const formatDateTime = (dateTime: string | number | Date) => {
    return format(new Date(dateTime), "PPPpp"); // format: Jan 1, 2020, 1:00 PM
  };

  const formatState = (state: string) => {
    return state === "Pending" ? "Registered - Awaiting Processing" : state;
  };

  const handleOpenModal = () => {
    setIsAddModalOpen(true);
  };

  const handleOpenDeleteModal = (voyageId: string, orgId: string) => {
    setManagedVoyageId(voyageId);
    setManagedOrgId(orgId);
    setDeleteVoyageModalOpen(true);
  };

  const columnNames = isRootUser
    ? [
        "Vessel Name",
        "Start Time",
        "End Time",
        "State",
        "Organisation ID",
        "Vessel ID",
        "Actions",
      ]
    : ["Vessel Name", "Start Time", "End Time", "State"];

  const rows =
    voyages === null
      ? null
      : voyages.map((voyage, index) => {
          const vesselName = vesselIds.includes(voyage.vesselId)
            ? data.org.vesselList.find((v) => v.id === voyage.vesselId)?.name ||
              "N/A"
            : "N/A";
          const startTime = formatDateTime(voyage.startTime);
          const endTime = formatDateTime(voyage.endTime);
          const state = formatState(voyage.state);
          const orgId = voyage.orgId.toString();
          const vesselId = voyage.vesselId;

          const row = isRootUser
            ? ([
                vesselName,
                startTime,
                endTime,
                state,
                orgId,
                vesselId,
              ] as string[])
            : ([vesselName, startTime, endTime, state] as string[]);

          const actions = isRootUser ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaTrashAlt
                onClick={() =>
                  handleOpenDeleteModal(
                    voyage.vesselId,
                    voyage.orgId.toString()
                  )
                }
                style={{ cursor: "pointer" }}
                className="text-2xl text-red-600 hover:text-red-700 cursor-pointer"
              />
            </div>
          ) : null;

          return {
            id: `row-${voyage.vesselId}-${index}`,
            contents: row,
            actions: actions,
          };
        });

  return (
    <div className="h-full flex flex-col space-y-3 text-deep_blue px-7">
      {hasManagementPermissions && addButton("Add New Voyage", handleOpenModal)}
      {genericTableRelatedBits(
        "Voyage Trips",
        voyages?.length,
        fetchDataVoyages
      )}

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {voyages && voyages.length === 0 && (
            <EmptyState
              icon={<HandTabletWrittenIcon />}
              title="Voyage is not available"
              description="Log a new voyage to see it here"
              buttonText="Add voyage"
              onButtonClick={handleOpenModal}
            />
          )}
          {voyages &&
            voyages.length > 0 &&
            genericTableWithIconAction(columnNames, rows)}

          {deleteVoyageModalOpen && (
            <DeleteVoyageTripModal
              open={deleteVoyageModalOpen}
              setOpen={setDeleteVoyageModalOpen}
              orgId={managedOrgId}
              voyageId={managedVoyageId}
              onCloseManageModal={() => {
                setManagedVoyageId(null);
                setManagedOrgId(null);
              }}
              setIsProcessing={setIsProcessing}
            />
          )}

          {isAddModalOpen && !isProcessing && (
            <AddVoyageModal
              open={isAddModalOpen}
              setOpen={setIsAddModalOpen}
              fetchDataVoyages={fetchDataVoyages}
            />
          )}
        </>
      )}
    </div>
  );
}
