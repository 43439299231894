import { useEffect, useState } from "react";
import { useAppContext, vesselListEntryData } from "../../../context/variables";
import useFetchData from "../../../apiComms/fetchData";
import Tab from "../../../tabs/Tab";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  LabelList,
} from "recharts";
import {
  setLocalActivity,
  setLocalMetric,
  setLocalVessel,
} from "../../../context/locals";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-ie";
import { HiChevronRight, HiChevronLeft } from "react-icons/hi";
import {
  dateInNextPeriod,
  dateInPrevPeriod,
  epochAggrToPrettyString,
  epochToXAxisString,
  monthNames,
  timePeriodChanged,
  timescaleChanged,
} from "../../../helpers/dateTimeHelpers";
import {
  Aggregation,
  OperatorTabMetric,
  Timescale,
  VesselActivity,
  operatorTabMetricMap,
} from "../../../context/types";
import { inputSetting, inputTray, makeMetricPicker } from "../../../components/variousInputs";
import {
  fillGrayIfNoData,
  graphLoadOrNoDataNotice,
  graphVerticalNowLine,
} from "../../../helpers/graphHelpers";
import { UtcNotice } from "../../../components/miscellaneous";
import { GeneralWeekPicker } from "../../../components/timeInputs";
import RealTimeDataAvailability from "./RealTimeDataAvailability";
import { CenteredContainer } from "../../../components/styles/EmptyStateStyles";

dayjs.locale("en-ie");

export interface DropdownOption<T> {
  value: T;
  name: string;
}

function CustomYear() {
  const { view } = useAppContext();
  const locale = "en-ie";
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <DatePicker
        aria-label="Calendar year"
        orientation="portrait"
        views={["year"]}
        label="Calendar year"
        minDate={dayjs("2020")}
        maxDate={dayjs()}
        slotProps={{
          textField: { onKeyDown: (e) => e.preventDefault() },
          toolbar: { toolbarFormat: "YYYY" },
        }}
        value={dayjs(view.energyOverall.fromTime)}
        onChange={(newValue) => {
          if (newValue !== null) {
            timePeriodChanged(newValue.toDate(), Timescale.Year, view);
          }
        }}
        format={"YYYY"}
      />
    </LocalizationProvider>
  );
}

function CustomMonth() {
  const { view } = useAppContext();
  const locale = "en-ie";
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <DatePicker
        orientation="portrait"
        views={["year", "month"]}
        label="Calendar month"
        minDate={dayjs("2020")}
        maxDate={dayjs()}
        openTo="month"
        slotProps={{
          textField: { onKeyDown: (e) => e.preventDefault() },
          toolbar: { toolbarFormat: "MMMM YYYY" },
        }}
        value={dayjs(view.energyOverall.fromTime)}
        onChange={(newValue) => {
          if (newValue !== null) {
            timePeriodChanged(newValue.toDate(), Timescale.Month, view);
          }
        }}
        format={"MMMM YYYY"}
      />
    </LocalizationProvider>
  );
}

function CustomWeek() {
  const { view } = useAppContext();
  const [hoveredDay, setHoveredDay] = useState<Dayjs | null>(null)
  const value = dayjs(view.energyOverall.fromTime);
  function setValue(newValue: Dayjs | null) {
    if (newValue !== null) {
      timePeriodChanged(newValue.toDate(), Timescale.Week, view);
    }
  }
  return GeneralWeekPicker(value, setValue, hoveredDay, setHoveredDay)
}

function CustomDay() {
  const { view } = useAppContext();
  const locale = "en-ie";
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <DatePicker
        orientation="portrait"
        slotProps={{
          textField: { onKeyDown: (e) => e.preventDefault() },
          toolbar: { toolbarFormat: "D MMM YYYY" },
        }}
        views={["year", "month", "day"]}
        label="Calendar date"
        minDate={dayjs("2020")}
        maxDate={dayjs()}
        value={dayjs(view.energyOverall.fromTime)}
        onChange={(newValue) => {
          if (newValue !== null) {
            timePeriodChanged(newValue.toDate(), Timescale.Day, view);
          }
        }}
        format={"D MMM YYYY"}
      />
    </LocalizationProvider>
  );
}

export function EnergyOverallTab() {
  const context = useAppContext();
  const { vars, view, data } = context;

  const { fetchDataEnergyPage, fetchDataOrganisations } = useFetchData();
  useEffect(() => {
    fetchDataOrganisations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.idToken, vars.auth.organisationId]);
  const dependencies = [
    view.energyOverall.aggregation,
    view.energyOverall.fromTime,
    view.energyOverall.toTime,
    view.energyOverall.vessel,
    view.energyOverall.metric,
    view.energyOverall.activity,
    vars.auth.idToken,
    data.org.vesselList,
  ];
  useEffect(() => {
    fetchDataEnergyPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  function convertGraphData(valTime: { value: number; timestamp: string }) {
    const theName = Date.parse(valTime.timestamp);
    const theValue = valTime.value;
    return { name: theName, value: theValue };
  }

  function vesselChanged(option: string) {
    view.energyOverall.setVessel(option);
    setLocalVessel(option);
  }

  function metricChanged(option: OperatorTabMetric) {
    view.energyOverall.setMetric(option);
    setLocalMetric(option);
  }

  function activityChanged(option: VesselActivity) {
    view.energyOverall.setActivity(option);
    setLocalActivity(option);
  }

  const graphFuelUsage = data.energy.overall.graphFuelUsage;
  const graphData =
    graphFuelUsage === null ? undefined : graphFuelUsage.map(convertGraphData);

  const aggr: Aggregation = view.energyOverall.aggregation;

  let aggrUnitStepMs: number;
  if (aggr === Aggregation.Hour) {
    aggrUnitStepMs = 3600 * 1000;
  } else if (aggr === Aggregation.Day) {
    aggrUnitStepMs = 24 * 3600 * 1000;
  } else if (aggr === Aggregation.Month) {
    aggrUnitStepMs = 31 * 24 * 3600 * 1000;
  } else {
    aggrUnitStepMs = 365.25 * 24 * 3600 * 1000;
  }

  const metricMetadata = operatorTabMetricMap[view.energyOverall.metric];
  const metricName = metricMetadata.name;
  const metricUnitShort = metricMetadata.unitShort;

  const domainStart =
    graphData !== undefined && graphData.length > 0
      ? graphData[0].name - aggrUnitStepMs
      : 0;
  const domainEnd =
    graphData !== undefined && graphData.length > 0
      ? graphData[graphData.length - 1].name + aggrUnitStepMs
      : 0;

  function timePeriodToPrettyString(theTimePeriod: string): string {
    const theYear = theTimePeriod.slice(0, 4);
    if (theTimePeriod.length === 8) {
      const theWeekNum = parseInt(theTimePeriod.slice(6, 8));
      return "week " + theWeekNum + " of " + theYear;
    }
    if (theTimePeriod.length === 4) {
      return theYear;
    }
    const theMonthNum = parseInt(theTimePeriod.slice(5, 7)) - 1;
    if (theTimePeriod.length === 7) {
      return monthNames[theMonthNum].long + " " + theYear;
    }
    const theDate = parseInt(theTimePeriod.slice(8, 10));
    return theDate + " " + monthNames[theMonthNum].long + " " + theYear;
  }

  const vesselOptionAll: { name: string; value: string }[] = [
    {
      name: "Sum of all vessels",
      value: "all_vessels",
    },
  ];
  const vesselOptionsVessels: { name: string; value: string }[] =
    data.org.vesselList.map(
      (entry: vesselListEntryData): DropdownOption<string> => {
        return {
          name: entry.name,
          value: entry.id,
        };
      }
    );

  let vesselOptions: { name: string; value: string }[];
  if (vesselOptionsVessels.length <= 1) {
    vesselOptions = vesselOptionsVessels;
    if (
      view.energyOverall.vessel === "all_vessels" &&
      vesselOptionsVessels.length === 1
    ) {
      view.energyOverall.setVessel(vesselOptionsVessels[0].value);
    }
  } else {
    vesselOptions = vesselOptionAll.concat(vesselOptionsVessels);
  }
  const vesselPicker = (
    <Select
      label="Vessel"
      value={view.energyOverall.vessel}
      onChange={(event: SelectChangeEvent) => {
        vesselChanged(event.target.value);
      }}
    >
      {vesselOptions.map(({ name: theName, value: theValue }, index) => (
        <MenuItem key={index} value={theValue}>
          {theName}
        </MenuItem>
      ))}
    </Select>
  );
  const vesselInput = inputSetting("Vessel", vesselPicker, 240);

  const metricOptions = Object.values(OperatorTabMetric).map((metric) => {
    return {
      name:
        operatorTabMetricMap[metric].name +
        " (" +
        operatorTabMetricMap[metric].unitShort +
        ")",
      value: metric,
    };
  });
  const metricPicker = makeMetricPicker(
    view.energyOverall.metric,
    metricOptions,
    metricChanged
  );

  const metricInput = inputSetting("Metric", metricPicker, 240);

  const activityOptions: { name: string; value: VesselActivity }[] = [
    { name: "Total", value: VesselActivity.Total },
    { name: "Sailing", value: VesselActivity.Sailing },
    { name: "At dock", value: VesselActivity.AtDock },
  ];
  const activityPicker = (
    <Select
      label="Activity"
      value={view.energyOverall.activity}
      onChange={(event: SelectChangeEvent<VesselActivity>) => {
        activityChanged(event.target.value as VesselActivity);
      }}
    >
      {activityOptions.map(({ name: theName, value: theValue }, index) => {
        return (
          <MenuItem key={index} value={theValue}>
            {theName}
          </MenuItem>
        );
      })}
    </Select>
  );
  const activityInput = inputSetting("Activity", activityPicker, 120);

  const timescaleOptions: { name: string; value: Timescale }[] = [
    { name: "Year", value: Timescale.Year },
    { name: "Month", value: Timescale.Month },
    { name: "Week", value: Timescale.Week },
    { name: "Day", value: Timescale.Day },
  ];
  const timescalePicker = (
    <Select
      label="Timescale"
      value={view.energyOverall.timescale}
      onChange={(event: SelectChangeEvent) => {
        timescaleChanged(event.target.value as Timescale, view);
      }}
    >
      {timescaleOptions.map(({ name: theName, value: theValue }, index) => {
        return (
          <MenuItem key={index} value={theValue}>
            {theName}
          </MenuItem>
        );
      })}
    </Select>
  );

  const timescaleInput = inputSetting("Timescale", timescalePicker, 120);

  const timescale: Timescale = view.energyOverall.timescale;
  let timePeriodInput: JSX.Element;
  let xAxisTickAngle: number;
  let xAxisTickTextAnchor: string;
  if (timescale === Timescale.Year) {
    timePeriodInput = <CustomYear />;
    xAxisTickAngle = -30;
    xAxisTickTextAnchor = "end";
  } else if (timescale === Timescale.Month) {
    timePeriodInput = <CustomMonth />;
    xAxisTickAngle = 0;
    xAxisTickTextAnchor = "middle";
  } else if (timescale === Timescale.Week) {
    timePeriodInput = <CustomWeek />;
    xAxisTickAngle = 0;
    xAxisTickTextAnchor = "middle";
  } else {
    timePeriodInput = <CustomDay />;
    xAxisTickAngle = -30;
    xAxisTickTextAnchor = "end";
  }

  const theVesselData = vesselOptions.find(
    ({ name: theName, value: theValue }) => {
      return theValue === view.energyOverall.vessel;
    }
  );

  let theVesselName: { short: string; long: string };
  if (theVesselData === undefined || theVesselData.value === "all_vessels") {
    theVesselName = { short: "all vessels", long: "all of your vessels" };
  } else {
    theVesselName = { short: theVesselData.name, long: theVesselData.name };
  }

  const activity = view.energyOverall.activity;
  const activityText =
    activity === VesselActivity.Sailing
      ? { short: ", sailing", long: "while sailing" }
      : activity === VesselActivity.AtDock
      ? { short: ", at dock", long: "while at dock" }
      : { short: "", long: "" };
  const prettyMetricShort = metricName + activityText.short;
  const prettyMetricLong =
    metricName.toLowerCase() +
    " (" +
    metricUnitShort +
    ") " +
    activityText.long +
    " for";

  let shortDateRange: string;
  let longDateRange: string;
  if (
    view.energyOverall.fromTime === null ||
    view.energyOverall.toTime === null
  ) {
    shortDateRange = "";
    longDateRange = "";
  } else {
    const fromDate = view.energyOverall.fromTime;
    const toDate = view.energyOverall.toTime;
    const fromDayOfMonth = fromDate.getDate();
    const fromMonth = monthNames[fromDate.getMonth()];
    const toDayOfMonth = toDate.getDate();
    const toMonth = monthNames[toDate.getMonth()];
    shortDateRange =
      "(" +
      fromDayOfMonth +
      " " +
      fromMonth.short +
      " - " +
      toDayOfMonth +
      " " +
      toMonth.short +
      ")";
    longDateRange =
      "(" +
      fromDayOfMonth +
      " " +
      fromMonth.long +
      " - " +
      toDayOfMonth +
      " " +
      toMonth.long +
      ")";
  }

  const minLegalDate = dayjs("2020");
  const fromTime = view.energyOverall.fromTime;
  let prevPeriodDisabled: boolean;
  if (fromTime === null) {
    prevPeriodDisabled = true;
  } else {
    const prevDate: Date = dateInPrevPeriod(fromTime);
    prevPeriodDisabled = minLegalDate.isAfter(prevDate);
  }
  function goToPrevPeriod() {
    if (prevPeriodDisabled) return;
    if (fromTime !== null) {
      const prevDate: Date = dateInPrevPeriod(fromTime);
      timePeriodChanged(prevDate, timescale, view);
    }
  }
  const prevPeriodArrow = prevPeriodDisabled ? (
    <div className="w-fit text-4xl cursor-not-allowed opacity-25" role="button">
      <HiChevronLeft />
    </div>
  ) : (
    <div
      className="w-fit text-4xl cursor-pointer opacity-75"
      onClick={goToPrevPeriod}
      aria-label="Previous week"
      role="button"
    >
      <HiChevronLeft />
    </div>
  );
  const prevPeriodComponent = (
    <Tooltip title={"Previous " + timescale} placement="right">
      {prevPeriodArrow}
    </Tooltip>
  );

  const maxLegalDate = dayjs();
  const toTime = view.energyOverall.toTime;
  let nextPeriodDisabled: boolean;
  if (toTime === null) {
    nextPeriodDisabled = true;
  } else {
    const nextDate: Date = dateInNextPeriod(toTime);
    nextPeriodDisabled = maxLegalDate.isBefore(nextDate);
  }
  function goToNextPeriod() {
    if (nextPeriodDisabled) return;
    if (toTime !== null) {
      const nextDate: Date = dateInNextPeriod(toTime);
      timePeriodChanged(nextDate, timescale, view);
    }
  }
  const nextPeriodArrow = nextPeriodDisabled ? (
    <div className="w-fit text-4xl cursor-not-allowed opacity-25" role="button">
      <HiChevronRight />
    </div>
  ) : (
    <div
      className="w-fit text-4xl cursor-pointer opacity-75"
      onClick={goToNextPeriod}
      aria-label="Next week"
      role="button"
    >
      <HiChevronRight />
    </div>
  );
  const nextPeriodComponent = (
    <Tooltip title={"Next " + timescale} placement="left">
      {nextPeriodArrow}
    </Tooltip>
  );

  const redLineXVal =
    new Date().getTime() +
    new Date().getTimezoneOffset() * 60 * 1000 -
    aggrUnitStepMs / 2;

  const inputs = [
    [vesselInput, metricInput, activityInput],
    [timescaleInput, timePeriodInput],
  ];

  const isEmptyState = !graphData || graphData.length === 0;

  const emptyStateComponent = (
    <div>
      {inputTray(inputs)}
      <CenteredContainer>
        <RealTimeDataAvailability dataType={view.energyOverall.metric} />
      </CenteredContainer>
    </div>
  );

  const energyOverallTabComponent = (
    <div className="text-black h-full w-fit flex flex-col space-y-4 pt-4">
      {inputTray(inputs)}
      <div className="w-fit flex flex-col bg-white text-black h-full space-y-3 p-4 rounded-xl shadow-tray">
        <div className="w-full flex flex-col items-start font-bold text-deep_blue pt-2 pb-2 pl-10 space-y-2">
          <div className="text-xl">
            {view.energyOverall.timescale === Timescale.Week
              ? prettyMetricShort +
                ", " +
                theVesselName.short +
                ", " +
                timePeriodToPrettyString(view.energyOverall.timePeriod) +
                " " +
                shortDateRange
              : prettyMetricShort +
                ", " +
                theVesselName.short +
                ", " +
                timePeriodToPrettyString(view.energyOverall.timePeriod)}
          </div>
          <div className="text-lg font-normal italic pl-5">
            {view.energyOverall.timescale === Timescale.Week
              ? "The " +
                prettyMetricLong +
                " " +
                theVesselName.long +
                ", each " +
                view.energyOverall.aggregation +
                " during " +
                timePeriodToPrettyString(view.energyOverall.timePeriod) +
                " " +
                longDateRange
              : "The " +
                prettyMetricLong +
                " " +
                theVesselName.long +
                ", each " +
                view.energyOverall.aggregation +
                " during the " +
                view.energyOverall.timescale +
                " of " +
                timePeriodToPrettyString(view.energyOverall.timePeriod)}
          </div>
        </div>
        <div className="relative mr-8">
          <BarChart
            width={1100}
            height={533}
            data={graphData}
            barSize={200}
            className={"text-sm"}
            margin={{ top: 18, right: 5, left: 25, bottom: 25 }}
          >
            <CartesianGrid vertical={false} fill={fillGrayIfNoData(graphData)} />
            <XAxis
              className=" text-sm"
              dataKey="name"
              domain={[domainStart, domainEnd]}
              scale="time"
              type="number"
              interval={0}
              angle={xAxisTickAngle}
              textAnchor={xAxisTickTextAnchor}
              tickFormatter={(num) =>
                epochToXAxisString(num, view.energyOverall.timescale)
              }
            />
            <YAxis
              padding={{ top: 36 }}
              label={{
                value: metricName + " (" + metricUnitShort + ")",
                angle: -90,
                position: "insideLeft",
                offset: -10,
                style: { textAnchor: "middle" },
                fontSize: 16,
              }}
            />
            <RechartsTooltip
              formatter={(value: string) => {
                const valueRounded =
                  Math.round(parseFloat(value) * 1000) / 1000;
                return valueRounded.toLocaleString("en-IE");
              }}
              labelFormatter={(label: number) => {
                const dateStr = epochAggrToPrettyString(
                  label,
                  view.energyOverall.aggregation
                );
                return dateStr;
              }}
            />
            {graphVerticalNowLine(
              redLineXVal,
              graphData !== undefined && graphData.length > 0
            )}
            <Bar
              isAnimationActive={false}
              dataKey="value"
              fill="#102840"
              name={metricName}
              unit={" " + metricUnitShort}
            >
              {graphData !== undefined && graphData.length <= 26 && (
                <LabelList
                  dataKey="value"
                  formatter={(num: any) => {
                    const rounded = Math.round(num * 1000) / 1000;
                    return rounded === 0 ? "" : rounded.toLocaleString("en-IE");
                  }}
                  position="top"
                  fontSize={13}
                  fill="#102840"
                  fontWeight={600}
                />
              )}
            </Bar>
            {graphLoadOrNoDataNotice(graphData)}
          </BarChart>
          <div className="absolute bottom-4 -right-3 left-16 flex justify-between">
            {prevPeriodComponent}
            {nextPeriodComponent}
          </div>
        </div>
        <div className="w-full flex justify-end pr-8">
          <UtcNotice />
        </div>
      </div>
    </div>
  );

  return <Tab title="Overall" content={isEmptyState ? emptyStateComponent : energyOverallTabComponent} />;
}
