import React from "react";

import {
  StyledDeleteButton,
  StyledDeleteButtonIcon,
  StyledDeleteButtonText,
} from "./DeleteButton.styles";
import DeleteButtonIcon from "../../../assets/icons/CustomAlarms/DeleteButtonIcon";

interface DeleteButtonProps {
  onClick: () => void;
  additionalText: string;
  className?: string;
  disabled?: boolean;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({
  onClick,
  additionalText,
  className,
  disabled = false,
}) => {
  return (
    <StyledDeleteButton
      onClick={onClick}
      className={`${className}`}
      disabled={disabled}
    >
      <StyledDeleteButtonIcon>
        <DeleteButtonIcon color={disabled ? "#143452" : "#FF2A2A"} />
      </StyledDeleteButtonIcon>
      <StyledDeleteButtonText color={disabled ? "#143452" : "#FF2A2A"}>
        Delete {additionalText}
      </StyledDeleteButtonText>
    </StyledDeleteButton>
  );
};

export default DeleteButton;
