import ActionMenu from "../molecules/ActionMenu";
import DynamicTable from "../molecules/DynamicTable";
import { format, parseISO } from "date-fns";
import { GreyText } from "../styles/InputStyles";
import { DeleteAlarmEventPayload } from "../../context/variables";
import { Checkbox, TableCell } from "../atoms";
import Tooltip from "../atoms/Tooltip";
import AlarmIconLow from "../../assets/icons/CustomAlarms/AlarmIconLow";
import AlarmIconMedium from "../../assets/icons/CustomAlarms/AlarmIconMedium";
import AlarmIconHigh from "../../assets/icons/CustomAlarms/AlarmIconHigh";

interface AlarmTableProps {
  data: {
    id: string;
    vesselName: string;
    alarmName: string;
    date: string;
    alarmLevel: string;
    geoZone?: string;
    condition?: string;
    [key: string]: any;
  }[];
  showActions: boolean;
  showCheckbox?: boolean;
  additionalColumns?: { name: string; key: string }[];
  disableSorting?: boolean;
  isCustomizedTab?: boolean;
  selectedRows?: DeleteAlarmEventPayload[];
  onCheckedRow?: (id: string, vesselName: string) => void;
}

const AlarmTable: React.FC<AlarmTableProps> = ({
  data,
  showActions,
  showCheckbox,
  additionalColumns = [],
  disableSorting = false,
  isCustomizedTab = false,
  selectedRows = [],
  onCheckedRow,
}) => {
  const headers = [
    ...(showCheckbox ? [{ name: "" }] : []),
    { name: "Vessel Name" },
    { name: "Alarm Name" },
    { name: "Date/Time" },
    ...additionalColumns.map((col) => ({ name: col.name })),
    { name: "Alarm Level" },
    ...(showActions ? [{ name: "" }] : []),
  ];

  const customSort = (a: any, b: any, column: string, sortOrder: string) => {
    if (disableSorting) return 0;
    if (column === "Alarm Level") {
      const levelOrder: { [key: string]: number } = {
        high: 1,
        medium: 2,
        low: 3,
      };
      const valA = levelOrder[a.alarmLevel.toLowerCase()];
      const valB = levelOrder[b.alarmLevel.toLowerCase()];
      return sortOrder === "asc" ? valA - valB : valB - valA;
    } else if (column === "Date/Time") {
      const valA = new Date(a.date);
      const valB = new Date(b.date);
      return sortOrder === "asc"
        ? valA.getTime() - valB.getTime()
        : valB.getTime() - valA.getTime();
    } else {
      const valA = a[column.replace(/\s+/g, "").toLowerCase()];
      const valB = b[column.replace(/\s+/g, "").toLowerCase()];
      if (typeof valA === "string" && typeof valB === "string") {
        return sortOrder === "asc"
          ? valA.localeCompare(valB)
          : valB.localeCompare(valA);
      }
      return 0;
    }
  };

  const onChangeCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
    vesselName: string
  ) => {
    if (onCheckedRow) {
      onCheckedRow(id, vesselName);
    }
    return;
  };

  const renderCells = (row: any) => [
    ...(showCheckbox
      ? [
          <TableCell
            key="checkbox"
            isFirst={true}
            isCustomizedTab={isCustomizedTab}
          >
            <Checkbox
              checked={selectedRows.some((r) => r.alarm_id === row.id)}
              onChange={(e) => onChangeCheckbox(e, row.id, row.vesselName)}
              label=""
            />
          </TableCell>,
        ]
      : []),
    <TableCell
      key="vesselName"
      isFirst={!showCheckbox}
      isCustomizedTab={isCustomizedTab}
    >
      {row.vesselName}
    </TableCell>,
    <TableCell key="alarmName" isCustomizedTab={isCustomizedTab}>
      {row.alarmName}
    </TableCell>,
    <TableCell key="date" isCustomizedTab={isCustomizedTab}>
      {formatDate(row.date)}
    </TableCell>,
    ...additionalColumns.map((col, index) => (
      <TableCell key={index} isCustomizedTab={isCustomizedTab}>
        {row[col.key] === "N/A" ? (
          <GreyText>{row[col.key]}</GreyText>
        ) : (
          row[col.key]
        )}
      </TableCell>
    )),
    <TableCell
      key="alarmLevel"
      isIcon
      isLast={!showActions}
      isCustomizedTab={isCustomizedTab}
    >
      <Tooltip text={getAlarmLevelText(row.alarmLevel)}>
        <span className="icon-container">{renderIcon(row.alarmLevel)}</span>
      </Tooltip>
    </TableCell>,
    ...(showActions
      ? [
          <TableCell
            key="actions"
            isLast={true}
            isCustomizedTab={isCustomizedTab}
          >
            <ActionMenu
              onDelete={() => console.log("Delete", row.id)}
              onEdit={() => console.log("Edit", row.id)}
            />
          </TableCell>,
        ]
      : []),
  ];

  const getAlarmLevelText = (level: string) => {
    switch (level.toLowerCase()) {
      case "high":
        return "High level";
      case "medium":
        return "Medium level";
      case "low":
        return "Low level";
      default:
        return "";
    }
  };

  const formatDate = (dateString: string) => {
    try {
      const date = parseISO(dateString);
      return format(date, "yyyy-MM-dd (HH:mm)");
    } catch (error) {
      console.error("Invalid date:", dateString, error);
      return dateString;
    }
  };

  const renderIcon = (level: string) => {
    switch (level.toLowerCase()) {
      case "high":
        return <AlarmIconHigh />;
      case "medium":
        return <AlarmIconMedium />;
      case "low":
        return <AlarmIconLow />;
      default:
        return null;
    }
  };

  const renderActions = (row: any) => (
    <ActionMenu
      onDelete={() => console.log("Delete", row.id)}
      onEdit={() => console.log("Edit", row.id)}
    />
  );

  return (
    <div className="flex flex-col items-stretch">
      <DynamicTable
        headers={headers}
        data={data}
        renderRow={renderCells}
        showActions={showActions}
        renderActions={renderActions}
        sortableColumns={
          disableSorting
            ? []
            : [
                "Vessel Name",
                "Alarm Name",
                "Date/Time",
                "Alarm Level",
                ...additionalColumns.map((col) => col.name),
              ]
        }
        customSort={customSort}
        disableSorting={disableSorting}
        isCustomizedTab={isCustomizedTab}
      />
    </div>
  );
};

export default AlarmTable;
