import React from 'react';

const TagIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90" fill="none">
    <path d="M4.6222 60.3708L29.6293 85.378C30.5334 86.2806 31.7588 86.7878 33.0365 86.7878C34.3142 86.7878 35.5396 86.2806 36.4436 85.378L86.0721 35.7494C86.3216 35.511 86.5144 35.2199 86.6366 34.8973C86.7593 34.5748 86.8076 34.2289 86.7793 33.8851L82.9864 9.26368C82.9556 8.67694 82.7087 8.12248 82.2934 7.70707C81.8775 7.29159 81.3233 7.0446 80.7364 7.01368L56.1151 3.22082C55.7713 3.19229 55.4254 3.24097 55.1029 3.36332C54.7803 3.48567 54.4891 3.67859 54.2508 3.92797L4.6222 53.5565C3.71931 54.4605 3.21216 55.686 3.21216 56.9637C3.21216 58.2414 3.71931 59.4668 4.6222 60.3708Z" stroke="#1F64E7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M63.5724 29.6424C61.7973 29.6424 60.3582 28.2033 60.3582 26.4282C60.3582 24.653 61.7973 23.2139 63.5724 23.2139C65.3478 23.2139 66.7865 24.653 66.7865 26.4282C66.7865 28.2033 65.3478 29.6424 63.5724 29.6424Z" stroke="#1F64E7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default TagIcon;
