import { useEffect, useState } from "react";
import { inputSetting, inputTray, makeSignalPicker, makeVesselPicker } from "../../components/variousInputs";
import { RealTimeSignal } from "../../context/types";
import { useAppContext } from "../../context/variables";
import useFetchData from "../../apiComms/fetchData";
import Page from "../Page";
import { graphPoint } from "../../context/variables";
import { epochToPrettyStringRealTime } from "../../helpers/dateTimeHelpers";
import { Checkbox, FormControl, FormControlLabel, Slider, SliderThumb } from "@mui/material";
import { UtcNotice } from "../../components/miscellaneous";
import RealTimeMap from "./RealTimeMap";
import SignalGraph, { convertGraphData } from "./SignalGraph";

function CustomThumbComponent(props: any) {
  const { children, className, ...other } = props;
  return (
    <SliderThumb {...other} className={className + " " + (other["data-index"] === 0 ? "first-thumb" : other["data-index"] === 1 ? "second-thumb" : "third-thumb")}>
      {children}
    </SliderThumb>
  );
}

function timeSlider(value: number[], setValue: (newValue: number | number[]) => void, graphData: { timestamp: number; value: number }[] | null, setLiveChecked: (liveChecked: boolean) => void) {
  const startTime = (graphData !== null && graphData.length > 0) ? graphData[0].timestamp : undefined;
  const endTime = (graphData !== null && graphData.length > 0) ? graphData[graphData.length - 1].timestamp : undefined;
  const disabled = graphData === null || graphData.length < 1;
  const timeSliderComponent = (
    <div className="w-[35rem] pt-3">
      <Slider
        sx={{
          '& .MuiSlider-thumb': {
            height: 20,
            width: 20,
            "&.first-thumb": {
              height: 40,
              width: 12,
              backgroundColor: '#fff',
              border: "1px solid currentColor",
              borderTopLeftRadius: 12,
              borderBottomLeftRadius: 12,
              borderTopRightRadius: 1,
              borderBottomRightRadius: 1,
            },
            "&.second-thumb": {
              zIndex: 1,
            },
            "&.third-thumb": {
              height: 40,
              width: 12,
              backgroundColor: '#fff',
              border: "1px solid currentColor",
              borderTopLeftRadius: 1,
              borderBottomLeftRadius: 1,
              borderTopRightRadius: 12,
              borderBottomRightRadius: 12,
            },
            '&:hover': {
              boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
            },
          }
        }}
        components={{ Thumb: CustomThumbComponent }}
        value={value}
        aria-label="timeSlider"
        min={startTime}
        max={endTime}
        disabled={disabled}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => {
          const sliderLabel = (typeof value === 'number' && isFinite(value)) ? epochToPrettyStringRealTime(value) : "";
          return sliderLabel;
        }}
        onChange={(event, value) => setValue(value)}
      />
    </div>
  );
  return timeSliderComponent;
}

function liveCheckbox(liveChecked: boolean, setLiveChecked: (x: boolean) => void) {
  const liveCheckboxComponent = (
    <FormControl component="fieldset">
      <FormControlLabel
        control={
          <Checkbox
            checked={liveChecked}
            onChange={(e) => setLiveChecked(e.target.checked)}
            name="liveCheckbox"
            color="primary"
          />
        }
        label="Track latest"
      />
    </FormControl>
  );
  return liveCheckboxComponent;
}

export enum RealTimeFetchMode { // Order of entries matters
  HighRes1Min,
  LowRes24H,
  LowRes48H,
}

export const realTimeFetchMap = {
  [RealTimeFetchMode.HighRes1Min]: { ago: "10s", sample: "1s" },
  [RealTimeFetchMode.LowRes24H]: { ago: "1d", sample: "1m" },
  [RealTimeFetchMode.LowRes48H]: { ago: "2d", sample: "1m" },
};

export default function RealTimePage() {
  const { vars, data, view } = useAppContext();
  const { fetchDataOrganisations, fetchDataRealTimePage } = useFetchData();

  function getNewData(fetchMode: RealTimeFetchMode) {
    fetchDataRealTimePage(view.realTime.vessel, view.realTime.signal, fetchMode);
  }

  const currentTime = new Date().getTime();
  const [windowStartTime, setWindowStartTime] = useState(0);
  const [windowEndTime, setWindowEndTime] = useState(currentTime);
  const [chosenTime, setChosenTime] = useState(currentTime);
  const [liveChecked, setLiveChecked] = useState(true);

  function setSliderValue(newValue: number | number[]) {
    if (typeof newValue === "number") return;
    if (chosenTime !== newValue[1] || windowEndTime !== newValue[2]) setLiveChecked(false);
    setWindowStartTime(newValue[0]);
    setChosenTime(newValue[1]);
    setWindowEndTime(newValue[2]);
  }

  const graphSignalType = (data.realTime !== null && data.realTime.realTimePoints.length > 0) ? data.realTime.realTimePoints[0].signalType : null;
  const graphDataStr: graphPoint[] | null = (data.realTime === null) ? null : data.realTime.realTimePoints.map((point) => {
    return { timestamp: point.timestamp, value: point.signalValue };
  }).reverse();
  const graphDataNum = (graphDataStr === null) ? null : graphDataStr.map(convertGraphData);

  // ----- Effects -----

  useEffect(() => {
    // Fetching org-related data
    fetchDataOrganisations();
  }, [vars.auth.idToken, vars.auth.organisationId]);

  useEffect(() => {
    // Selecting a vessel on vessellist load
    if (data.org.vesselList.length > 0) {
      view.realTime.setVessel(data.org.vesselList[0].id);
    }
  }, [data.org.vesselList]);

  useEffect(() => {
    // Clearing and fetching new data on first load and on switching vessel/signal
    data.setRealTime(null);
    getNewData(RealTimeFetchMode.LowRes48H);
  }, [view.realTime.vessel, view.realTime.signal]);

  const loopFrequency = 3000;
  const [loopIteration, setLoopIteration] = useState<number>(0);
  useEffect(() => {
    // Fetching latest data recurringly
    getNewData(
      data.realTime?.maxAmountStored === RealTimeFetchMode.LowRes48H
        ? RealTimeFetchMode.HighRes1Min
        : RealTimeFetchMode.LowRes48H
    );
    setTimeout(() => {
      setLoopIteration(loopIteration + 1);
    }, loopFrequency);
  }, [loopIteration]);

  useEffect(() => {
    // Following latest incoming data if tracking checked
    if (liveChecked) {
      if (data.realTime !== null && data.realTime.realTimePoints.length > 0) {
        setChosenTime(Date.parse(data.realTime.realTimePoints[0].timestamp));
        setWindowEndTime(Date.parse(data.realTime.realTimePoints[0].timestamp));
      }
    }
  }, [data.realTime?.realTimePoints, liveChecked]);

  // ----- Components -----

  const vesselOptions = data.org.vesselList;
  const vesselDropdown = makeVesselPicker(view.realTime.vessel, vesselOptions, view.realTime.setVessel);
  const vesselPicker = inputSetting("Vessel", vesselDropdown, 240);

  const signalOptions = Object.values(RealTimeSignal);
  const signalDropdown = makeSignalPicker(view.realTime.signal, signalOptions, view.realTime.setSignal);
  const signalPicker = inputSetting("Signal", signalDropdown, 240);

  const timePicker = timeSlider([windowStartTime, chosenTime, windowEndTime], setSliderValue, graphDataNum, setLiveChecked);

  const trackLatestCheckbox = liveCheckbox(liveChecked, setLiveChecked);

  // ----- Whole page -----

  const real_time_content = (
    <div className="h-full w-fit flex flex-col space-y-5 pt-4 text-2xl text-deep_blue">
      {inputTray([[vesselPicker]])}
      {RealTimeMap(data, view, chosenTime, windowStartTime, windowEndTime)}
      {inputTray([[signalPicker, timePicker, trackLatestCheckbox]], true)}
      <div className="w-full flex justify-end pr-16">
        <UtcNotice />
      </div>
      {SignalGraph(graphDataNum, graphSignalType, chosenTime, windowStartTime, windowEndTime)}
    </div>
  );

  return <Page title={"Real-time view"} content={real_time_content} />;
}
