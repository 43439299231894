import { useAppContext } from "../../../context/variables";
import { genericTableEditable } from "../../../components/Table";
import { booleanToString, numNullToString, strNullToString } from "../../../helpers/stringHelpers";
import { epochToPrettyString } from "../../../helpers/dateTimeHelpers";
import { TagValueType, tagScopePretty, AccessLevel, accessLevelAtMinimum } from "../../../context/types";

export default function TagEventsTable(setManagedId: (id: string|null)=>void, setManageModalOpen: (open: boolean)=>void): JSX.Element {
  const { data, vars } = useAppContext()

  const isRoot = accessLevelAtMinimum(vars.auth.accessLevel[0], AccessLevel.Root)
  const tagEventList = data.tagEvents.tagEventList.val
  const tagTypeList = data.tagTypes.tagTypeList.val

  const rows = (tagEventList === null) ? null : tagEventList.map((tagEvent)=>{
    const tagType = tagTypeList?.find((tagType)=>tagType.id === tagEvent.tagTypeId)
    const tagTypeNameScope = (tagType === undefined) ? tagEvent.tagTypeId : tagType.name+" ("+tagScopePretty[tagType.scope]+")"
    const prettyTime = epochToPrettyString(new Date(tagEvent.timestamp).valueOf())
    const vessel = data.org.vesselList.find((ves)=>ves.id === tagEvent.vesselId)
    const vesselName = (vessel === undefined) ? tagEvent.vesselId : vessel.name
    const org = data.org.organisationList?.find((org)=>org.id === tagEvent.orgId)
    const orgStr = (org === undefined) ? tagEvent.orgId : org.name+" ("+tagEvent.orgId+")"
    const user = data.org.userList?.find((user)=>user.username === tagEvent.userId)
    const userStr = (tagEvent.userId === "NOUSER") ? "No user" : (user === undefined) ? strNullToString(tagEvent.userId) : (user.name === null) ? user.username : user.name
    const unitStr = (tagType === undefined) ? "" : (tagType.unit === null) ? "" : " "+tagType.unit
    const value = (tagType === undefined) ? "N/A"
      : (tagType.valueType === TagValueType.String) ? '"'+tagEvent.value+'"'
      : (tagType.valueType === TagValueType.Number) ? numNullToString(tagEvent.value, (val)=>val.toString()+unitStr)
      : (tagType.valueType === TagValueType.Boolean) ? booleanToString(tagEvent.value)
      : "N/A"
    
    const alwaysRow = [
      vesselName,
      prettyTime,
      tagTypeNameScope,
      value,
      userStr,
    ]
    const rootOnlyRow = [
      orgStr,
      strNullToString(tagEvent.category),
    ]
    const row = isRoot ? alwaysRow.concat(rootOnlyRow) : alwaysRow

    return {id: tagEvent.tagTypeId+"+"+tagEvent.timestamp, contents: row}
  })

  const alwaysColumnNames = [
    "Vessel",
    "Date & time",
    "Tag type (scope)",
    "Value",
    "User",
  ]
  const rootOnlyColumnNames = [
    "organisation",
    "category",
  ]
  const columnNames = isRoot ? alwaysColumnNames.concat(rootOnlyColumnNames) : alwaysColumnNames
  
  return genericTableEditable(columnNames, rows, (id: string)=>{
    setManagedId(id)
    setManageModalOpen(true)
  })
}
