import React, { useEffect, useState } from "react";
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  setLocalEnergyOperatorActivities,
  setLocalEnergyOperatorMetric,
  setLocalEnergyOperatorOperators,
  setLocalEnergyOperatorVessels,
} from "../../../context/locals";
import {
  Timescale,
  VesselActivity,
  prettyVesselActivityName,
} from "../../../context/types";
import { useAppContext, vesselListEntryData } from "../../../context/variables";
import { DropdownOption } from "../overall/EnergyOverallTab";
import Tab from "../../../tabs/Tab";
import useFetchData from "../../../apiComms/fetchData";
import {
  timePeriodOperatorsChanged,
  timescaleOperatorsChanged,
} from "../../../helpers/dateTimeHelpers";
import { UtcNotice } from "../../../components/miscellaneous";
import {
  PrevNextPeriodButtons,
  TimePeriodPicker,
} from "../../../components/timeInputs";
import {
  StandardDropdownMultipleWithAll,
  inputSetting,
  inputTray,
} from "../../../components/variousInputs";
import EnergyOperatorsGraph from "./EnergyOperatorsGraph";
import LoadingSpinner from "../../../components/atoms/LoadingSpinner";
import OperatorDataAvailability from "./OperatorDataAvailability";
import { CenteredContainer } from "../../../components/styles/EmptyStateStyles";

// Define OperatorTabMetric as a type with specific string literals
type OperatorTabMetric =
  | "FuelVolume"
  | "FuelOverTime"
  | "FuelOverDistance"
  | "TimeLoggedIn"
  | "ElectricEnergy"
  | "ElectricOverTime"
  | "ElectricOverDistance";

// Define a default value for OperatorTabMetric to use as a fallback
const defaultMetric: OperatorTabMetric = "FuelVolume";

export function EnergyOperatorsTab() {
  const { vars, data, view } = useAppContext();
  const {
    fetchDataOrganisations,
    fetchDataEnergyOperatorsTab,
    fetchUsersGeneral,
  } = useFetchData();

  const operatorEntries =
    data.org.userList === null
      ? []
      : data.org.userList.filter((entry) => entry.is_operator);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await fetchDataOrganisations();
      await fetchUsersGeneral();
      setIsLoading(false);
    };
    fetchData();
  }, [vars.auth.idToken, vars.auth.organisationId]);

  useEffect(() => {
    view.energyOperator.setVessels(data.org.vesselList.map((ves) => ves.id));
  }, [data.org.vesselList]);

  useEffect(() => {
    const newOperators = ["UNKNOWN"].concat(
      operatorEntries.map((op) => op.username)
    );
    if (
      data.org.userList !== null &&
      !(
        view.energyOperator.operators?.every((op) =>
          newOperators.includes(op)
        ) &&
        newOperators.every((op) => view.energyOperator.operators?.includes(op))
      )
    ) {
      view.energyOperator.setOperators(newOperators);
    }
  }, [data.org.userList]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      data.energy.operators.setSeries(null);
      await fetchDataEnergyOperatorsTab();
      setIsLoading(false);
    };
    fetchData();
  }, [
    view.energyOperator.vessels,
    view.energyOperator.operators,
    view.energyOperator.metric,
    view.energyOperator.activities,
    view.energyOperator.fromTime,
    view.energyOperator.toTime,
    view.energyOperator.aggregation,
  ]);

  const vesselOptions = data.org.vesselList.map(
    (entry: vesselListEntryData): DropdownOption<string> => {
      return {
        name: entry.name,
        value: entry.id,
      };
    }
  );
  const vesselPickerValue =
    view.energyOperator.vessels === null
      ? vesselOptions.map((ves) => ves.value)
      : view.energyOperator.vessels;
  function vesselsChanged(vessels: string[]) {
    view.energyOperator.setVessels(vessels);
    setLocalEnergyOperatorVessels(vessels);
  }
  const vesselInput = StandardDropdownMultipleWithAll(
    "Vessels",
    vesselPickerValue,
    vesselOptions,
    vesselsChanged,
    "All Vessels"
  );

  const operatorOptions: { name: string; value: string }[] = [
    { name: "No operator", value: "UNKNOWN" },
  ].concat(
    operatorEntries.map((entry) => {
      const theName = entry.name === null ? entry.username : entry.name;
      return {
        name: theName,
        value: entry.username,
      };
    })
  );
  const operatorPickerValue =
    view.energyOperator.operators === null
      ? operatorOptions.map((op) => op.value)
      : view.energyOperator.operators;
  function operatorsChanged(ops: string[]) {
    view.energyOperator.setOperators(ops);
    setLocalEnergyOperatorOperators(ops);
  }
  const operatorInput = StandardDropdownMultipleWithAll(
    "Operators",
    operatorPickerValue,
    operatorOptions,
    operatorsChanged,
    "All Operators"
  );

  function metricChanged(metr: OperatorTabMetric) {
    view.energyOperator.setMetric(metr as any); // Use 'any' to force the type
    setLocalEnergyOperatorMetric(metr as any); // Use 'any' to force the type
  }
  const metricOptions: { name: string; value: OperatorTabMetric }[] = [
    { name: "Fuel volume (m³)", value: "FuelVolume" },
    { name: "Fuel over time (m³/h)", value: "FuelOverTime" },
    { name: "Fuel over distance (m³/km)", value: "FuelOverDistance" },
    { name: "Time logged in (h)", value: "TimeLoggedIn" },
    { name: "Electric energy (kWh)", value: "ElectricEnergy" },
    { name: "Electricity over time (kWh/h)", value: "ElectricOverTime" },
    {
      name: "Electricity over distance (kWh/km)",
      value: "ElectricOverDistance",
    },
  ];
  const metricPicker = (
    <Select
      label="Metric"
      value={view.energyOperator.metric || defaultMetric}
      onChange={(event: SelectChangeEvent<OperatorTabMetric>) => {
        metricChanged(event.target.value as OperatorTabMetric);
      }}
    >
      {metricOptions.map(({ name: theName, value: theValue }, index) => {
        return (
          <MenuItem key={index} value={theValue}>
            {theName}
          </MenuItem>
        );
      })}
    </Select>
  );
  const metricInput = inputSetting("Metric", metricPicker, 240);

  function activityChanged(acts: VesselActivity[]) {
    let newActivities: VesselActivity[] = [];
    Object.values(VesselActivity).forEach((activity) => {
      if (acts.includes(activity)) {
        newActivities = newActivities.concat(activity);
      }
    });
    view.energyOperator.setActivities(newActivities);
    setLocalEnergyOperatorActivities(newActivities);
  }
  const activityOptions: { name: string; value: VesselActivity }[] = [
    { name: "Total", value: VesselActivity.Total },
    { name: "Sailing", value: VesselActivity.Sailing },
    { name: "At dock", value: VesselActivity.AtDock },
  ];
  const activityPicker = (
    <Select
      label="Activities"
      multiple
      value={view.energyOperator.activities}
      onChange={(event: SelectChangeEvent<VesselActivity[]>) => {
        activityChanged(event.target.value as VesselActivity[]);
      }}
      renderValue={(selected) =>
        selected.map((act) => prettyVesselActivityName[act]).join(", ")
      }
    >
      {activityOptions.map(({ name: theName, value: theValue }, index) => {
        return (
          <MenuItem key={index} value={theValue}>
            <Checkbox
              checked={view.energyOperator.activities.includes(theValue)}
            />
            <ListItemText primary={theName} />
          </MenuItem>
        );
      })}
    </Select>
  );
  const activityInput = inputSetting("Activities", activityPicker, 240);

  const timescaleOptions: { name: string; value: Timescale }[] = [
    { name: "Year", value: Timescale.Year },
    { name: "Month", value: Timescale.Month },
    { name: "Week", value: Timescale.Week },
    { name: "Day", value: Timescale.Day },
  ];
  const timescalePicker = (
    <Select
      label="Timescale"
      value={view.energyOperator.timescale}
      onChange={(event: SelectChangeEvent) => {
        timescaleOperatorsChanged(event.target.value as Timescale, view);
      }}
    >
      {timescaleOptions.map(({ name: theName, value: theValue }, index) => {
        return (
          <MenuItem key={index} value={theValue}>
            {theName}
          </MenuItem>
        );
      })}
    </Select>
  );
  const timescaleInput = inputSetting("Timescale", timescalePicker, 120);

  const timePeriodInput = TimePeriodPicker(
    view.energyOperator.timescale,
    view.energyOperator.fromTime,
    (date: Date, timescale: Timescale) =>
      timePeriodOperatorsChanged(date, timescale, view)
  );

  function timePeriodChanged(date: Date) {
    timePeriodOperatorsChanged(date, view.energyOperator.timescale, view);
  }

  const prevNextPeriodButtons = PrevNextPeriodButtons(
    view.energyOperator.fromTime,
    view.energyOperator.toTime,
    view.energyOperator.timescale,
    timePeriodChanged
  );

  const inputs = [
    [vesselInput, metricInput, timescaleInput, timePeriodInput],
    [operatorInput, activityInput],
  ];

  const isEmptyState =
    !data.energy.operators.series || data.energy.operators.series.length === 0;

  const emptyStateComponent = (
    <div>
      {inputTray(inputs)}
      <CenteredContainer>
        <OperatorDataAvailability
          dataType={view.energyOperator.metric || defaultMetric}
        />
      </CenteredContainer>
    </div>
  );

  const energyOperatorsTabComponent = (
    <div className="text-black h-full w-fit flex flex-col space-y-4 pt-4">
      {inputTray(inputs)}
      <div className="w-fit flex flex-col bg-white text-black h-full space-y-3 p-4 rounded-xl shadow-tray">
        <div className="relative mr-8">
          <EnergyOperatorsGraph />
          {prevNextPeriodButtons}
        </div>
        <div className="w-full flex justify-end pr-8">
          <UtcNotice />
        </div>
      </div>
    </div>
  );

  return (
    <Tab
      title="Operators"
      content={
        isLoading ? (
          <LoadingSpinner />
        ) : isEmptyState ? (
          emptyStateComponent
        ) : (
          energyOperatorsTabComponent
        )
      }
    />
  );
}
