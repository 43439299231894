
import EnergyConsumptionIcon from "../../../assets/icons/EmptyStates/EnergyConsumptionIcon";
import EmptyState from "../../../components/atoms/EmptyState";

type OperatorDataAvailabilityProps = {
  dataType: string;
};

const OperatorDataAvailability = ({ dataType }: OperatorDataAvailabilityProps) => {
  let title, description, icon;

  switch (dataType) {
    case "FuelVolume":
      title = "Fuel volume is not available";
      description = "Choose another timeframe to get data";
      icon = <EnergyConsumptionIcon />;
      break;
    case "FuelOverTime":
      title = "Fuel over time is not available";
      description = "Choose another timeframe to get data";
      icon = <EnergyConsumptionIcon />;
      break;
    case "FuelOverDistance":
      title = "Fuel over distance is not available";
      description = "Choose another timeframe to get data";
      icon = <EnergyConsumptionIcon />;
      break;
    case "TimeLoggedIn":
      title = "Time recorded is not available";
      description = "Choose another timeframe to get data";
      icon = <EnergyConsumptionIcon />;
      break;
    case "ElectricEnergy":
      title = "Electric energy is not available";
      description = "Choose another timeframe to get data";
      icon = <EnergyConsumptionIcon />;
      break;
    case "ElectricOverTime":
      title = "Electricity over time is not available";
      description = "Choose another timeframe to get data";
      icon = <EnergyConsumptionIcon />;
      break;
    case "ElectricOverDistance":
      title = "Electricity over distance is not available";
      description = "Choose another timeframe to get data";
      icon = <EnergyConsumptionIcon />;
      break;
    default:
      title = "Operators data is not available";
      description = "Choose another timeframe to get data";
      icon = <EnergyConsumptionIcon />;
      break;
  }

  return (
    <EmptyState
      icon={icon}
      title={title}
      description={description}
    />
  );
};

export default OperatorDataAvailability;
