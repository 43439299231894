import { theApiUrl } from "../auth/authentication"
import { Aggregation, OperatorTabMetric, VesselActivity, aggregationToString } from "../context/types"
import { metricBits } from "./helpers"

export const activitiesSailing = "&activity=MANOEUVRING&activity=CRUISING&activity=STOPPED&activity=UNKNOWN"
export const activitiesAtdock = "&activity=DOCKED&activity=DOCKING"

export function makeEnergyOverallUrl(organisationId: string|null, from: string, to: string, vessels: string[], aggregation: Aggregation|null, metric: OperatorTabMetric, activity: VesselActivity) {
  if (vessels.length < 1 || organisationId === null) {
    // console.log("Calling makeEnergyOverallUrl with no vessels")
    return null
  }
  const orgBit = "?org_id="+organisationId
  let aggregationBit
  if (aggregation === null) {
    aggregationBit = ""
  } else {
    aggregationBit = "&aggregates="+aggregationToString(aggregation).toLowerCase()
  }
  // aggregationBit = aggregation ? "&aggregation="+aggregationToString(aggregation).toLowerCase() : ""
  const metricBit = "&outputs="+metricBits[metric]
  const activityBit = 
    (activity === VesselActivity.Sailing) ? activitiesSailing : 
    (activity === VesselActivity.AtDock) ? activitiesAtdock : ""
  const fromBit = "&from_time="+from
  const toBit = "&to_time="+to
  const vesselBit = vessels.map((ves)=>"&vessel_id="+ves).join("")
  // const extraBit = extraStr ? extraStr : ""
  const url = theApiUrl+"energydata"
    +orgBit
    +aggregationBit
    +metricBit
    +activityBit
    +fromBit
    +toBit
    +vesselBit
  return (url)
}

export function makeEnergyOverallUrlTotal(organisationId: string|null, from: string, to: string, vessels: string[], metric: OperatorTabMetric, activity: VesselActivity) {
  return makeEnergyOverallUrl(organisationId, from, to, vessels, null, metric, activity)
}
export function makeEnergyOverallUrlGraph(organisationId: string|null, from: string, to: string, vessels: string[], aggregation: Aggregation, metric: OperatorTabMetric, activity: VesselActivity) {
  return makeEnergyOverallUrl(organisationId, from, to, vessels, aggregation, metric, activity)
}
// function makeUrlTotalDock(from: string, to: string, vessels: string[], metric: Metric) {
//   const extraStr = "&activity=docked&activity=idle"
//   return makeUrl(from, to, vessels, null, extraStr, metric)
// }
// function makeUrlGraphDock(from: string, to: string, vessels: string[], aggregation: Aggregation, metric: Metric) {
//   const extraStr = "&activity=docked&activity=idle"
//   return makeUrl(from, to, vessels, aggregation, extraStr, metric)
// }
