import styled from 'styled-components';

export const StyledInput = styled.input<{ width?: string; disabled?: boolean }>`
  width: ${({ width }) => width || '100%'};
  height: 60px;
  padding: 10px;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  background-color: white;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: #143452;
  box-sizing: border-box;

  ::placeholder {
    color: #a8a8a8;
  }

  &:focus {
    outline: none;
    border-color: #143452;
  }
`;

export const GreyText = styled.span`
  color: #a8a8a8;
`;
