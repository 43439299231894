import React from "react";
import { Panel, PanelStatusProps } from "../../../atoms";
import {
  PanelTableContainer,
  PanelTableHeader,
  PanelTableHeaderCell,
  PanelTableBody,
  PanelTableBodyCell,
} from "./PanelTable.styles";

type Column = {
  label: string;
  key: string;
  formatter?: (value: any, row?: any) => React.ReactNode;
};

type TableProps = {
  columns: Column[];
  data: any[] | null | undefined;
};

type PanelTableProps = TableProps &
  PanelStatusProps & {
    title: string;
  };

const Table: React.FC<TableProps> = ({ columns, data }) => {
  if (!data || data.length === 0) {
    return <></>;
  }

  return (
    <PanelTableContainer>
      <PanelTableHeader>
        <tr>
          {columns.map((column, index) => (
            <PanelTableHeaderCell key={index}>
              {column.label}
            </PanelTableHeaderCell>
          ))}
        </tr>
      </PanelTableHeader>
      <PanelTableBody>
        {data.map((row: any, index: number) => (
          <tr key={index}>
            {columns.map((column, index) => (
              <PanelTableBodyCell key={index}>
                {column.formatter
                  ? column.formatter(row[column.key], row)
                  : row[column.key]}
              </PanelTableBodyCell>
            ))}
          </tr>
        ))}
      </PanelTableBody>
    </PanelTableContainer>
  );
};

const PanelTable: React.FC<PanelTableProps> = ({
  title,
  columns,
  data,
  isLoading,
  error,
}) => {
  return (
    <Panel
      isLoading={isLoading}
      title={title}
      content={<Table columns={columns} data={data} />}
      error={error}
      isEmpty={!data || data.length === 0}
    />
  );
};
export default PanelTable;
