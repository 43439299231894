import React, { useEffect, useState } from "react"
import { Tooltip } from "@mui/material"
// import { HiPencil, HiOutlineTrash } from "react-icons/hi";
// import { AiOutlinePlus } from "react-icons/ai";
// import { CgClose } from "react-icons/cg";
// import { FaPaperPlane, FaSlash } from "react-icons/fa";
// import { BsThreeDots } from "react-icons/bs";
// import { TbSpeedboat } from 'react-icons/tb'
// import { BsQuestionLg } from "react-icons/bs";
import Tab from "../../tabs/Tab"
import { usersEntry, useAppContext } from "../../context/variables"
// import { TextEntryWarningText } from "../components/TextEntry";
// import { iconButtonDeepBlue, iconButtonHighOrange } from "../components/Button";
import { AccessLevel } from "../../context/types";
import useFetchData from "../../apiComms/fetchData";

const accessLevelPrettyNames = {
  [AccessLevel.Root]: "Root",
  [AccessLevel.Owner]: "Owner",
  [AccessLevel.Admin]: "Admin",
  [AccessLevel.User]: "User",
}

function memberRow(memberEntry: usersEntry, setManagedMember: (member: usersEntry|null)=>void, ownUsername: string|null) {
  const nameText = (memberEntry.name === null) ? "" : memberEntry.name
  const emailText = (memberEntry.email === null) ? "" : memberEntry.email
  const accessText = accessLevelPrettyNames[memberEntry.access_level]
  const operatorText = (memberEntry.is_operator) ? "Yes" : ""
  // const editable = memberEntry.accessLevel === AccessLevel.User || memberEntry.username === ownUsername
  // const editColumn = editable ? 
  //   <Tooltip title={"Manage member"} placement="top">
  //     <div className="cursor-pointer" onClick={() => {
  //       console.log("Clicked pen for", memberEntry.username)
  //       setManagedMember(memberEntry)
  //     }}>
  //       <HiPencil/>
  //     </div>  
  //   </Tooltip> : <></>
  const memberRowComponent = 
    <div id={"memberId-"+memberEntry.username} key={"memberId-"+memberEntry.username} className="h-16 hover:bg-[#d9d9d980] first:rounded-t-xl last:rounded-b-xl flex justify-start items-center pl-4 border-b-[1px] last:border-b-0 space-x-4 border-[#d9d9d9] relative">
      <div className="w-40">
        <Tooltip title={nameText} placement="top">
          <div className="w-fit max-w-full truncate">
            {nameText}
          </div>
        </Tooltip>
      </div>
      <div className="w-40">
        <Tooltip title={emailText} placement="top">
          <div className="w-fit max-w-full truncate">
            {emailText}
          </div>
        </Tooltip>
      </div>
      <div className="w-28">
        {memberEntry.username}
      </div>
      <div className="w-36">
        {accessText}
      </div>
      <div className="w-24">
        {operatorText}
      </div>
      {/* {editColumn} */}
    </div>
  return memberRowComponent
}

const emptyMemberRow =
  <div id={"memberRowEmpty"} key={"memberRowEmpty"} className="h-16 hover:bg-[#d9d9d980] first:rounded-t-xl last:rounded-b-xl flex justify-start items-center pl-4 border-b-[1px] last:border-b-0 space-x-4 border-[#d9d9d9] relative">
    <div className="w-40">
      <div className="w-fit max-w-full truncate">
        {"-"}
      </div>
    </div>
    <div className="w-40">
      <div className="w-fit max-w-full truncate">
        {"-"}
      </div>
    </div>
    <div className="w-28">
      {"-"}
    </div>
    <div className="w-36">
      {"-"}
    </div>
    <div className="w-24">
      {"-"}
    </div>
  </div>

function memberTable(memberList: usersEntry[]|null, setManagedMember: (member: usersEntry|null)=>void, username: string|null) {
  const memberRows = (memberList === null) ? [] : memberList.map((memberEntry)=>memberRow(memberEntry, setManagedMember, username))
  const memberRowsToShow = memberRows.length > 0 ? memberRows : [emptyMemberRow]
  const memberTableComponent = 
    <div className="min-h-[4rem] flex flex-col rounded-xl bg-white text-deep_blue font-normal shadow-tray">
      {memberRowsToShow}
    </div>
  return memberTableComponent
}

export function legalUsername(username: string) {
  if(!username.includes("@")) return false
  const charsBeforeAt = username.split("@")[0].length
  if(charsBeforeAt < 1) return false
  const domainPart = username.slice(charsBeforeAt+1)
  if(!domainPart.includes(".")) return false
  const domainParts = domainPart.split(".")
  const domainPartLengths = domainParts.map((str: string)=>str.length)
  return domainPartLengths.every((length)=>length > 0)
}

export function legalPin(pin: string|null) {
  const legalChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
  if(pin === null || pin.length !== 4) return false
  for(let i = 0; i<4; i++) {
    if(!legalChars.includes(pin[i])) return false
  }
  return true
}

export default function MembersTab() {
  const { data, vars } = useAppContext()
  const { fetchDataOrganisations, fetchUsersGeneral } = useFetchData()

  useEffect(() => {
    fetchDataOrganisations()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.idToken, vars.auth.organisationId])
  useEffect(()=>{
    getMemberList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.idToken, vars.auth.organisationId])

  function getMemberList() {
    // console.log("Dummy send: list_members(", vars.auth.organisationId, ")")
    fetchUsersGeneral()
    setTimeout(() => {
      // after 3s get list again, to let auth provider update
      // console.log("Dummy send: list_members(", vars.auth.organisationId, ")")
      fetchUsersGeneral()
    }, 3000);
  }

  const memberList = data.org.userList
  // console.log(memberList)
  const [managedMember, setManagedMember] = useState<usersEntry|null>(null)

  // function handleCloseAddMemberModal() {
  //   vars.modals.setOpenAddMember(false)
  // }
  // function AddMemberModal() {
  //   const { vars } = useAppContext()
  //   const modalWidth = 700
  //   const modalHeight = 350

  //   const [operatorCheckbox, setOperatorCheckbox] = useState(false)
  //   const [usernameWarningStatus, setUsernameWarningStatus] = useState(false)
  //   const [usernameWarningText, setUsernameWarningText] = useState("")
  
  //   const addMemberModalComponent = <Modal
  //       open={vars.modals.openAddMember}
  //       onClose={handleCloseAddMemberModal}
  //       id="addMember-modal"
  //     >
  //       <div className="outline-none">
  //         <Box position="absolute" right="0" left="0" top="0" bottom="0" margin="auto" width={modalWidth} height={modalHeight}>
  //           <div className="h-full w-full bg-white rounded-lg font-bold shadow-modal flex flex-col space-y-4">
  //             <div className="p-3 w-full h-16-resp flex justify-start items-center relative">
  //               <div className="text-4xl pl-10-resp">{"Add a member"}</div>
  //               <CgClose onClick={handleCloseAddMemberModal} className="text-4xl text-black absolute right-3 hover:cursor-pointer" />
  //             </div>
  //             <div className="flex flex-col space-y-3 justify-start items-start pl-3 text-2xl">
  //               <div className="flex space-x-6 justify-start items-center">
  //                 <div className="flex">
  //                   <div>
  //                     {"Email/username:"}
  //                   </div>
  //                   <Tooltip title="An email address to use as the username for the new member" placement="top">
  //                     <div>
  //                       <BsQuestionLg className="text-xs"/>
  //                     </div>
  //                   </Tooltip>
  //                 </div>
  //                 {/* <TextEntry prompt=" sam@example.com" name="newUsername" type="text" warningStatus={usernameWarningStatus}/> */}
  //                 <TextEntryWarningText prompt=" sam@example.com" name="newUsername" type="text" warningStatus={usernameWarningStatus} warningText={usernameWarningText}/>
  //               </div>
  //               <div className="flex space-x-2 justify-start items-center pb-10">
  //                 <div className="flex">
  //                   <div>
  //                     {"Vessel operator:"}
  //                   </div>
  //                   <Tooltip title="Whether to make the new member a vessel operator. Vessel operators get a PIN number and have the ability to log in onboard your vessels" placement="top">
  //                     <div>
  //                       <BsQuestionLg className="text-xs"/>
  //                     </div>
  //                   </Tooltip>
  //                 </div>
  //                 <Checkbox checked={operatorCheckbox} onClick={()=>setOperatorCheckbox(!operatorCheckbox)}/>
  //               </div>
  //               <div className="bg-deep_blue hover:opacity-80 cursor-pointer rounded-lg w-48 h-14 text-white font-bold flex justify-center items-center space-x-3" onClick={() => {
  //                 const theNewUsername = (document.getElementById("newUsername") as HTMLInputElement).value
  //                 console.log("Hit submit add member")
  //                 setUsernameWarningStatus(false)
  //                 setUsernameWarningText("")
  //                 if(legalUsername(theNewUsername)) { // OK
  //                   console.log("dummy send: add_member(", vars.auth.organisationId, theNewUsername, operatorCheckbox.valueOf(), AccessLevel.User, ")")
  //                   handleCloseAddMemberModal()
  //                   getMemberList()
  //                 } else { // Not OK
  //                   console.log("Username not OK")
  //                   setUsernameWarningStatus(true)
  //                   setUsernameWarningText("Invalid email adress")
  //                 }
  //               }}>
  //                 <FaPaperPlane className="text-xl"/>
  //                 <div>
  //                   {"Submit"}
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </Box>
  //       </div>
  //     </Modal>
  //   return addMemberModalComponent
  // }

  // const confirmModalWidth = 500
  // const confirmModalHeight = 300

  // const confirmDeleteMemberButton = iconButtonHighOrange("Confirm: Delete member", <HiOutlineTrash className="text-2xl"/>, ()=>{
  //   console.log("Hit confirm deleting member button")
  //   console.log("Dummy send: delete_member("+managedMember?.username+")")
  //   setOpenConfirmDeleteModal(false)
  //   setManagedMember(null)
  //   getMemberList()
  // })
  // const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  // function handleCloseConfirmDeleteModal() {
  //   setOpenConfirmDeleteModal(false)
  // }
  // function ConfirmDeleteModal() {
  //   const confirmDeleteModalComponent = <Modal
  //     open={openConfirmDeleteModal}
  //     onClose={handleCloseConfirmDeleteModal}
  //     id="confirmDelete-modal"
  //   >
  //     <div className="outline-none text-deep_blue">
  //       <Box position="absolute" right="0" left="0" top="0" bottom="0" margin="auto" width={confirmModalWidth} height={confirmModalHeight}>
  //         <div className="h-full w-full bg-white rounded-lg font-bold shadow-modal flex flex-col space-y-4">
  //           <div className="p-3 w-full h-16-resp flex justify-start items-center relative">
  //             <div className="text-4xl pl-10-resp">{"Confirm delete"}</div>
  //             <CgClose onClick={handleCloseConfirmDeleteModal} className="text-4xl text-black absolute right-3 hover:cursor-pointer" />
  //           </div>
  //           <div className="flex flex-col space-y-3 justify-start items-start pl-3 text-2xl">
  //             <div>
  //               {'Are you sure?'}
  //             </div>
  //             <div>
  //               {'You are about to delete the member: "'+managedMember?.username+'"'}
  //             </div>
  //             {confirmDeleteMemberButton}
  //           </div>
  //         </div>
  //       </Box>
  //     </div>
  //   </Modal>
  //   return confirmDeleteModalComponent
  // }

  // const revokeOpStatusIcon = 
  //   <div className="text-2xl relative">
  //     <TbSpeedboat/>
  //     <FaSlash className="absolute top-0"/>
  //   </div>
  // const revokeOpStatusButton = iconButtonDeepBlue("Revoke operator status", revokeOpStatusIcon, ()=>{
  //   console.log("Hit Revoke operator status button")
  //   console.log("dummy send: set_operator_status("+managedMember?.username+", false)")
  //   setManagedMember(null)
  //   getMemberList()
  // })
  // const revokeOpStatusComponent = 
  //   <div className="flex space-x-2">
  //     {revokeOpStatusButton}
  //     <Tooltip title="Makes it so this member will no longer be a vessel operator. They will lose access to logging in onboard your vessels" placement="top">
  //       <div>
  //         <BsQuestionLg className="text-sm"/>
  //       </div>
  //     </Tooltip>
  //   </div>
  // const grantOpStatusButton = iconButtonDeepBlue("Grant operator status", <TbSpeedboat className="text-2xl"/>, ()=>{
  //   console.log("Hit Grant operator status button")
  //   console.log("dummy send: set_operator_status("+managedMember?.username+", true)")
  //   setManagedMember(null)
  //   getMemberList()
  // })
  // const grantOpStatusComponent = 
  //   <div className="flex space-x-2">
  //     {grantOpStatusButton}
  //     <Tooltip title="Makes this member into a vessel operator. Vessel operators get a PIN and have the ability to log in onboard your vessels" placement="top">
  //       <div>
  //         <BsQuestionLg className="text-sm"/>
  //       </div>
  //     </Tooltip>
  //   </div>
  // const resetPinButton = iconButtonDeepBlue("Generate new PIN", <BsThreeDots className="text-2xl"/>, ()=>{
  //   console.log("Hit reset PIN button")
  //   console.log("dummy send: generate_new_pin("+managedMember?.username+")")
  //   setManagedMember(null)
  // })
  // const resetPinComponent = 
  //   <div className="flex space-x-2">
  //     {resetPinButton}
  //     <Tooltip title="Generates a new PIN for this member and sends it to their email address. The PIN is used to log in onboard your vessels" placement="top">
  //       <div>
  //         <BsQuestionLg className="text-sm text-deep_blue"/>
  //       </div>
  //     </Tooltip>
  //   </div>
  // const deleteMemberButton = iconButtonHighOrange("Delete member", <HiOutlineTrash className="text-2xl"/>, ()=>{
  //   console.log("Hit delete member button")
  //   setOpenConfirmDeleteModal(true)
  // })
  // const deleteMemberComponent = 
  //   <div className="flex space-x-2">
  //     {deleteMemberButton}
  //     <Tooltip title="Removes this member’s account entirely" placement="top">
  //       <div>
  //         <BsQuestionLg className="text-sm text-high_orange"/>
  //       </div>
  //     </Tooltip>
  //   </div>

  // function handleCloseManageMemberModal() {
  //   setManagedMember(null)
  // }
  // function ManageMemberModal() {
  //   const modalWidth = 700
  //   const modalHeight = 350
  
  //   const manageMemberModalComponent = <Modal
  //       open={managedMember !== null}
  //       onClose={handleCloseManageMemberModal}
  //       id="manageMember-modal"
  //     >
  //       <div className="outline-none text-deep_blue">
  //         <Box position="absolute" right="0" left="0" top="0" bottom="0" margin="auto" width={modalWidth} height={modalHeight}>
  //           <div className="h-full w-full bg-white rounded-lg font-bold shadow-modal flex flex-col space-y-4">
  //             <div className="p-3 w-full h-16-resp flex justify-start items-center relative">
  //               <div className="text-4xl pl-10-resp">{"Manage member"}</div>
  //               <CgClose onClick={handleCloseManageMemberModal} className="text-4xl text-black absolute right-3 hover:cursor-pointer" />
  //             </div>
  //             <div className="flex flex-col space-y-3 justify-start items-start pl-3 text-2xl">
  //               <div className="flex space-x-2">
  //                 <div>
  //                   {"Username:"}
  //                 </div>
  //                 <div className="italic">
  //                   {managedMember?.username}
  //                 </div>
  //               </div>
  //               {managedMember?.operatorStatus ? revokeOpStatusComponent : grantOpStatusComponent}
  //               {managedMember?.operatorStatus ? resetPinComponent : <></>}
  //               {managedMember?.accessLevel === AccessLevel.User ? deleteMemberComponent : <></>}
  //             </div>
  //             <ConfirmDeleteModal/>
  //           </div>
  //         </Box>
  //       </div>
  //     </Modal>
  //   return manageMemberModalComponent
  // }

  const content = <div className="h-full text-2xl pl-7 flex flex-col space-y-3 text-deep_blue">
      {/* <div className="bg-deep_blue hover:opacity-80 cursor-pointer rounded-xl w-72 h-14 text-white font-bold flex justify-center items-center space-x-4 shadow-tray" onClick={()=>
        vars.modals.setOpenAddMember(true)
      }>
        <AiOutlinePlus className="text-3xl"/>
        <div>
          {"Add new member"}
        </div>
      </div> */}
      {/* {"memberList: "+memberList} */}
      <div id="MembersTable" className="text-2xl min-w-[43rem] text-deep_blue">
        <div id="MembersTableHeaders" className="pl-4 h-14 flex justify-start items-center font-bold relative space-x-4">
          <div className="w-40 pointer-events-none">
            {"Name"}
          </div>
          <div className="w-40 pointer-events-none">
            {"Email"}
          </div>
          <div className="w-28 pointer-events-none">
            {"Username"}
          </div>
          <div className="w-36 pointer-events-none">
            {"Access level"}
          </div>
          <div className="w-24 pointer-events-none">
            {"Operator"}
          </div>
          {/* <div className="pointer-events-none">
            {"Manage"}
          </div> */}
        </div>
        {memberTable(memberList, setManagedMember, vars.auth.username)}
      </div>
      {/* {"managedMember: "+managedMember} */}
      {/* <AddMemberModal/> */}
      {/* <ManageMemberModal/> */}
    </div>

  return <Tab title="Members" content={content}/>
}