import React, { useEffect, useState } from "react";
import { makeConfirmModal, makeModal } from "../../../components/Modal";
import { useAppContext } from "../../../context/variables";
import { makeDropdownInput } from "../../../components/FormInputs";
import { EntityState } from "../../../context/types";
import { makeSubmitButton } from "../../../components/Button";
import useManageData from "../../../apiComms/manageData";

export function EditStateTagTypeModal(open: boolean, setOpen: (open: boolean)=>void, managedId: string|null, onCloseManageModal: ()=>void) {
  const { data } = useAppContext()
  const { apiPutTagTypesState } = useManageData()
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  const tagTypeList = data.tagTypes.tagTypeList.val
  const tagTypeMaybe = tagTypeList?.find((tag)=>tag.id === managedId)
  const tagTypeName = (tagTypeMaybe === undefined) ? "N/A" : tagTypeMaybe.name

  function onClose() {
    setOpen(false)
    setState(EntityState.Active)
  }
  function onSubmitClick() {
    // console.log("Hit submit edit tag type state")
    setConfirmModalOpen(true)
  }
  function onConfirmClick() {
    // console.log("Hit confirm edit tag type state")
    // const tagTypeName = tagTypeMaybe?.name
    const orgId = tagTypeMaybe?.orgId
    // console.log('edited the state of "'+tagTypeName+'" ('+managedId+') in the org '+orgId+' to '+state)
    apiPutTagTypesState(orgId, managedId, state)
    setConfirmModalOpen(false)
    onClose()
    onCloseManageModal()
  }

  const oldState = (tagTypeMaybe !== undefined) ? tagTypeMaybe.state : EntityState.Active
  const [state, setState] = useState(oldState)
  useEffect(()=>{
    setState(oldState)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managedId])
  const stateOptions: {value: EntityState, name: string}[] = Object.values(EntityState).map((state)=>{
    return {value: state, name: state}
  })
  const content = 
    <div className="flex flex-col space-y-4">
      {makeDropdownInput(
        "Tag type state:", 
        "The state of the tag type. Determines whether it will be shown to customers as a suggestion, it is seen onboard, or it is hidden.",
        "State", 
        state, stateOptions, setState, null
      )}
      {makeSubmitButton(onSubmitClick)}
      {makeConfirmModal(
        "Confirm editing state",
        'You are about to set the state of "'+tagTypeName+'" to '+state,
        "Confirm: Edit state",
        onConfirmClick,
        confirmModalOpen,
        setConfirmModalOpen
      )}
    </div>
  const title = (managedId !== null) ? 'Edit state of "'+tagTypeName+'"' : "Edit state of tag"
  
  return makeModal(title, content, open, onClose)
}
