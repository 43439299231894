import React from 'react';

const HandTabletWrittenIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="122" height="122" viewBox="0 0 122 122" fill="none">
    <path d="M70.1071 1H10.2143C5.12537 1 1 5.12537 1 10.2143V111.571C1 116.66 5.12537 120.786 10.2143 120.786H93.1429C98.2319 120.786 102.357 116.66 102.357 111.571V70.1071" stroke="#1B69FD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 93.1426H102.357" stroke="#1B69FD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M53.5 68.4995L55.5 57L57.5 44.5L78.5 64.0001L65 66.4993L59.5 67.4993L53.5 68.4995Z" fill="#75A4FE" fill-opacity="0.15"/>
    <path d="M28.6428 24.0361H47.0714" stroke="#1B69FD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M28.6428 47.0713H37.8571" stroke="#1B69FD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M79.2674 65.5534L51.6245 70.5292L56.2317 42.5177L95.2077 3.72557C96.0646 2.86193 97.0837 2.17644 98.207 1.70864C99.3293 1.24084 100.534 1 101.75 1C102.967 1 104.171 1.24084 105.294 1.70864C106.417 2.17644 107.436 2.86193 108.292 3.72557L118.059 13.4927C118.923 14.3493 119.609 15.3684 120.076 16.4912C120.544 17.6141 120.785 18.8185 120.785 20.0349C120.785 21.2512 120.544 22.4556 120.076 23.5784C119.609 24.7013 118.923 25.7205 118.059 26.577L79.2674 65.5534Z" stroke="#1B69FD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default HandTabletWrittenIcon;
