import styled from "styled-components";

export const PanelStatisticContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PanelStatisticSummary = styled.p`
  color: #595959;
  font-weight: normal;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
`;

export const PanelStatisticData = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
`;
