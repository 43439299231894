import { useState } from "react"
import { useAppContext } from "../../../../context/variables"
import { StandardModalWithConfirmation } from "../../../../components/Modal"
import useManageData from "../../../../apiComms/manageData"
import { makeTextInput } from "../../../../components/FormInputs"
import { getTextFieldContentsPretty, getTextFieldValue, isTextFieldValueEmpty } from "../../../../helpers/elementHelpers"

export default function RenameModal(open: boolean, setOpen: (open: boolean)=>void, managedUserId: string|null, onCloseManageUserModal: ()=>void) {
  const { apiUserRename } = useManageData()
  const { data } = useAppContext()
  const [nameWarning, setNameWarning] = useState<string|null>(null)

  const title = "Rename user"
  const confirmTitle = "Confirm renaming user"
  const theUserId = (managedUserId !== null) ? managedUserId : "N/A"
  const userOldName = data.org.userList?.find((user)=>user.username === managedUserId)?.name
  const userNewNamePretty = getTextFieldContentsPretty("newName")
  const confirmText = [
    'You are about to rename the user '+theUserId,
    'from "'+userOldName+'" to "'+userNewNamePretty+'"'
  ]
  const confirmButtonText = "Rename user"
  
  const defaultValue = (userOldName !== null && userOldName !== undefined) ? userOldName : undefined
  const prompt = (defaultValue !== undefined) ? defaultValue : " William"

  const inputComponents = [
    makeTextInput("Name:", "The new display name of the user", prompt, "newName", nameWarning, defaultValue)
  ]
  function onConfirm() {
    apiUserRename(managedUserId, getTextFieldValue("newName"))
    onCloseManageUserModal()
  }
  const warningSetters = [setNameWarning]
  const submitConditions = [{
      condition: ()=>isTextFieldValueEmpty(getTextFieldValue("newName")), 
      action: ()=>setNameWarning("Name not allowed")
  }]
  
  return StandardModalWithConfirmation(open, setOpen, title, confirmTitle, confirmText, confirmButtonText, inputComponents, onConfirm, warningSetters, submitConditions)
}
