import React, { useEffect, useState } from "react";
import Tab from "../../../tabs/Tab";
import { useAppContext } from "../../../context/variables";
import TableWithFilter, {
  genericTableRelatedBits,
} from "../../../components/Table";
import { addButton, deleteAllButton } from "../../../components/Button";
import useFetchData from "../../../apiComms/fetchData";
import { AccessLevel, accessLevelAtMinimum } from "../../../context/types";
import { AddVesselGroupModal } from "./AddVesselGroupsModal";
import { DeleteVesselGroupsModal } from "./DeleteVesselGroupsModal";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import { EditVesselGroupsModal } from "./EditVesselGroupsModal";
import { DeleteAllVesselGroupsModal } from "./DeleteAllVesselGroupsModal";
import LoadingSpinner from "../../../components/atoms/LoadingSpinner";
import HandTabletWrittenIcon from "../../../assets/icons/EmptyStates/HandTabletWrittenIcon";
import EmptyState from "../../../components/atoms/EmptyState";

export default function VesselGroupsTab() {
  const { vars, data } = useAppContext();
  const { fetchDataVesselGroups } = useFetchData();
  const [isLoading, setIsLoading] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [deleteAllModalOpen, setDeleteAllModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState<string | null>(null);

  const [groupToEdit, setGroupToEdit] = useState<string | null>(null);
  const [managedOrgId, setManagedOrgId] = useState<string | null>(null);
  const orgId = vars.auth.organisationId;

  const isAdmin = accessLevelAtMinimum(
    vars.auth.accessLevel[0],
    AccessLevel.Admin
  );

  const isRootUser = accessLevelAtMinimum(
    vars.auth.accessLevel[0],
    AccessLevel.Root
  );

  useEffect(() => {
    fetchDataVesselGroups();
  }, [orgId, vars.auth.accessLevel[0]]);

  const groupList = data.vesselGroups.groupList.val || [];

  const handleOpenDeleteModal = (groupId: string) => {
    const group = groupList?.find((g) => g.groupId === groupId);
    if (group) {
      setGroupToDelete(groupId);
      setManagedOrgId(group.orgId);
      setDeleteModalOpen(true);
    } else {
      console.error("Group or Org ID not found for deletion");
    }
  };

  const handleOpenEditModal = (groupId: string) => {
    const group = groupList?.find((g) => g.groupId === groupId);
    if (group) {
      setGroupToEdit(groupId);
      setManagedOrgId(group.orgId);
      setEditModalOpen(true);
    } else {
      console.error("Group or Org ID not found for editing");
    }
  };

  const handleDeleteAllGroups = () => {
    const orgId = vars.auth.organisationId;
    if (orgId) {
      setManagedOrgId(orgId);
      setDeleteAllModalOpen(true);
    } else {
      console.error("Organization ID not found for deletion");
    }
  };

  const getVesselNameFromId = (vesselId: any) => {
    if (data.org.vesselList) {
      return (
        data.org.vesselList.find(
          (vessel: { id: any }) => vessel.id === vesselId
        )?.name || "N/A"
      );
    } else {
      console.error("data.org.vesselList is undefined");
      return "N/A";
    }
  };

  const rows = groupList.map((group: any) => {
    const name = group.name;
    const groupReport = group.groupReport ? "Yes" : "No";
    const sisters = group.sisters ? "Yes" : "No";
    const sisterReport = group.sisterReport ? "Yes" : "No";
    const groupId = group.groupId || "N/A";
    const vesselIds = group.vessels.join(", ") || "N/A";
    const orgId = group.orgId.toString() || "N/A";
    const vesselNames =
      group.vessels.map(getVesselNameFromId).join(", ") || "N/A";

    let contents = [name, groupReport, sisters, sisterReport, vesselNames];

    if (isRootUser) {
      contents = [...contents, vesselIds, groupId, orgId];
    }

    const actions =
      isRootUser || isAdmin ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FaEdit
            onClick={() => handleOpenEditModal(groupId)}
            style={{ cursor: "pointer" }}
            className="text-2xl text-blue-600 hover:text-blue-700 cursor-pointer mr-2"
          />
          <FaTrashAlt
            onClick={() => handleOpenDeleteModal(groupId)}
            style={{ cursor: "pointer" }}
            className="text-2xl text-red-600 hover:text-red-700 cursor-pointer"
          />
        </div>
      ) : null;

    return {
      id: groupId,
      contents: [...contents, actions],
    };
  });

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setGroupToDelete(null);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setGroupToEdit(null);
  };

  const columnNames = [
    "Name",
    "Group Report",
    "Sister Report",
    "Sisters",
    "Vessel Names",
  ];

  if (isRootUser) {
    columnNames.push("Vessel ID", "Group ID", "Organisation ID");
  }

  if (isRootUser || isAdmin) {
    columnNames.push("Actions", "");
  }

  if (!isRootUser && !isAdmin) {
    columnNames.push("");
  }

  const content = isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="h-full flex flex-col space-y-3 text-deep_blue">
      <div className="flex justify-start space-x-4">
        {addButton("Add new vessel group", () => setAddModalOpen(true))}
        {(isRootUser || isAdmin) &&
          deleteAllButton("Delete All Groups", handleDeleteAllGroups)}
      </div>
      {rows.length === 0 ? (
        <EmptyState
          icon={<HandTabletWrittenIcon />}
          title="No vessel groups available"
          description="When you add a new vessel group, it will appear here"
          buttonText="Add vessel group"
          onButtonClick={() => setAddModalOpen(true)}
        />
      ) : (
        <>
          {genericTableRelatedBits(
            "Vessel Groups",
            groupList.length,
            fetchDataVesselGroups
          )}
          <TableWithFilter
            columnNames={columnNames}
            rows={rows}
            sortableColumns={[
              "Name",
              "Group Report",
              "Sister Report",
              "Sisters",
            ]}
            onEdit={handleOpenEditModal}
            onDelete={handleOpenDeleteModal}
            onDeactivate={(groupId: string) => {}}
            onSetStateRemoved={(groupId: string) => {}}
            showExtraData={false}
            isRoot={false}
          />
        </>
      )}
      {DeleteVesselGroupsModal({
        open: deleteModalOpen,
        setOpen: setDeleteModalOpen,
        groupId: groupToDelete,
        orgId: managedOrgId || vars.auth.organisationId,
        onCloseModal: handleCloseDeleteModal,
      })}
      {editModalOpen && groupList.length > 0 && (
        <EditVesselGroupsModal
          open={editModalOpen}
          setOpen={setEditModalOpen}
          groupId={groupToEdit}
          orgId={managedOrgId}
          onCloseManageModal={handleCloseEditModal}
          groupList={groupList}
        />
      )}
    </div>
  );

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <Tab title="Vessel Groups" content={content} />
      {AddVesselGroupModal(addModalOpen, setAddModalOpen)}

      {DeleteAllVesselGroupsModal({
        open: deleteAllModalOpen,
        setOpen: setDeleteAllModalOpen,
        groupId: groupToDelete,
        orgId: managedOrgId || vars.auth.organisationId,
        onCloseModal: handleCloseDeleteModal,
      })}
    </>
  );
}
