
import { useAppContext } from "../../context/variables"
import { CartesianGrid, Label, Scatter, ScatterChart, Tooltip as RechartsTooltip, XAxis, YAxis, ZAxis } from "recharts"
import { fillGrayIfNoData, graphLoadOrNoDataNotice } from "../../helpers/graphHelpers"

export default function EngineDieselRateSogMapChart() {
  const { data } = useAppContext()
  const engineDieselRateSogMapData = (data.performance.totalEngineDieselRateSogMap === null) ? undefined : data.performance.totalEngineDieselRateSogMap.filter((p)=>{
    return p.timePercent > 0.1
  })
  return <ScatterChart
    width={500}
    height={500}
    margin={{
      top: 20,
      right: 20,
      bottom: 20,
      left: 30,
    }}
  >
    <CartesianGrid strokeDasharray="3 3" fill={fillGrayIfNoData(engineDieselRateSogMapData)} />
    <XAxis dataKey="sogKn" type="number" name="sogKn" >
      <Label
        value="Speed over ground (kn)"
        offset={0}
        position="bottom"
      />
    </XAxis>
    <YAxis dataKey="engineDieselRateLph" type="number" name="engineDieselRateLph" unit="L/h" >
      <Label
        value="Diesel usage (vessel total)"
        offset={20}
        position="left"
        angle={-90}
        style={{ textAnchor: 'middle' }}
      />
    </YAxis>
    <ZAxis dataKey="timeHours" type="number" scale={"linear"} range={[10, 400]} name="time" unit="" />
    <RechartsTooltip 
      cursor={{ strokeDasharray: '3 3' }} 
      formatter={(value: string, name, props) => {
        const sogKn = Math.round(parseFloat(props.payload.sogKn) * 1000)/1000
        const engineDieselRateLph = Math.round(parseFloat(props.payload.engineDieselRateLph) * 1000)/1000
        const timeHoursRounded = Math.round(parseFloat(props.payload.timeHours) * 1000)/1000
        const timePercentRounded = Math.round(parseFloat(props.payload.timePercent) * 1000)/1000
        if(props.dataKey === "sogKn") {
          return [sogKn+" kn", "Speed over ground"]
        } else if(props.dataKey === "engineDieselRateLph") {
          return [engineDieselRateLph+" ", "Diesel usage (vessel total)"]
        } else if(props.dataKey === "timeHours") {
          return [timeHoursRounded+" h ("+timePercentRounded+" %)", "Time"]
        } else {
          return [value, name]
        }
      }}
    />
    <Scatter data={engineDieselRateSogMapData} fill={"#102840"} />
    {graphLoadOrNoDataNotice(engineDieselRateSogMapData)}
  </ScatterChart>
}
