import { BsQuestionLg } from "react-icons/bs";
import { TextEntryWarningText, TextEntryWithCallback } from "./TextEntry";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  OutlinedInput,
  Radio,
  Tooltip,
  Typography,
} from "@mui/material";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { MultiDropdownCheckboxInputProps } from "../context/variables";
import { Link } from "react-router-dom";
import { inputSetting } from "./variousInputs";

export function makeInput(
  title: string | null,
  tooltipText: string | null,
  inputComponent: JSX.Element
) {
  const titleMaybe = title !== null ? <div>{title}</div> : <></>;
  const tooltipMaybe =
    tooltipText !== null ? (
      <Tooltip title={tooltipText} placement="top">
        <div>
          <BsQuestionLg className="text-xs" />
        </div>
      </Tooltip>
    ) : (
      <></>
    );
  const input =
    title !== null || tooltipText !== null ? (
      <div className="flex space-x-6 justify-start items-center text-xl">
        <div className="flex">
          {titleMaybe}
          {tooltipMaybe}
        </div>
        {inputComponent}
      </div>
    ) : (
      <div className="text-xl">{inputComponent}</div>
    );
  return input;
}

export function makeCheckboxInput(
  title: string,
  tooltipText: string,
  value: boolean | undefined,
  valueChanged: (value: boolean) => void
) {
  const inputComponent = (
    <Checkbox checked={value} onClick={() => valueChanged(!value)} />
  );
  return makeInput(title, tooltipText, inputComponent);
}

export function makeRadioInput<T>(
  title: string,
  tooltipText: string,
  value: T,
  valueOptions: { value: T; name: string }[],
  valueChanged: (value: T) => void
) {
  const radios = valueOptions.map((opt, index) => {
    const chosen = opt.value === value;
    const namePart = chosen ? (
      <div className="font-bold">{opt.name}</div>
    ) : (
      <div>{opt.name}</div>
    );
    return (
      <div
        key={index}
        className="flex items-center"
        onClick={() => valueChanged(opt.value)}
      >
        <Radio checked={chosen} />
        {namePart}
      </div>
    );
  });
  const inputComponent = (
    <div className="flex flex-col font-normal">{radios}</div>
  );
  return makeInput(title, tooltipText, inputComponent);
}

export function makeTextInput(
  title: string | null,
  tooltipText: string | null,
  fieldPrompt: string,
  fieldHandle: string,
  warning: string | null,
  defaultValue?: string,
  size: "Small" | "Medium" | "Large" = "Large"
) {
  const warningStatus = warning !== null;
  const warningText = warningStatus ? warning : "";
  const inputComponent = (
    <TextEntryWarningText
      prompt={fieldPrompt}
      name={fieldHandle}
      type="text"
      warningStatus={warningStatus}
      warningText={warningText}
      defaultValue={defaultValue}
      size={size}
    />
  );
  return makeInput(title, tooltipText, inputComponent);
}

export function makeTextInputSmall(
  title: string | null,
  tooltipText: string | null,
  fieldPrompt: string,
  fieldHandle: string,
  warning: string | null,
  defaultValue?: string
) {
  const warningStatus = warning !== null;
  const warningText = warningStatus ? warning : "";
  const inputComponent = (
    <TextEntryWarningText
      prompt={fieldPrompt}
      name={fieldHandle}
      type="text"
      warningStatus={warningStatus}
      warningText={warningText}
      defaultValue={defaultValue} size={"Small"}    />
  );
  return makeInput(title, tooltipText, inputComponent);
}

export function getTextFieldValueById(elementId: string): string | null {
  const elem = document.getElementById(elementId) as HTMLInputElement;
  return elem === null ? null : elem.value;
}

export function makeTextInputWithCallback(
  title: string | null,
  tooltipText: string | null,
  fieldPrompt: string,
  fieldHandle: string,
  warning: string | null,
  defaultValue?: string,
  size: "Small" | "Medium" | "Large" = "Large",
  onChangeCallback?: (newValue: string) => void // Callback function for onChange
) {
  const warningStatus = warning !== null;
  const warningText = warningStatus ? warning : "";
  const inputComponent = (
    <TextEntryWithCallback
      prompt={fieldPrompt}
      name={fieldHandle}
      type="text"
      warningStatus={warningStatus}
      warningText={warningText}
      defaultValue={defaultValue}
      size={size}
      onChange={onChangeCallback}
    />
  );
  return makeInput(title, tooltipText, inputComponent);
}

export function makeTextOutput(
  title: string | null,
  tooltipText: string | null,
  text: string,
  warning: string | null
) {
  const warningStatus = warning !== null;
  const warningText = warningStatus ? warning : "";
  const textComponent = warningStatus ? (
    <div className="px-4 border-2 -my-[2px] rounded-lg border-high_orange">
      {text}
    </div>
  ) : (
    <div>{text}</div>
  );
  const inputComponent = (
    <div className="relative">
      {textComponent}
      <div className="absolute w-max right-0 font-normal text-lg text-high_orange h-0">
        {warningText}
      </div>
    </div>
  );
  return makeInput(title, tooltipText, inputComponent);
}

export function makeDropdownInput<T extends string>(
  title: string,
  tooltipText: string,
  prompt: string,
  value: T | null,
  valueOptions: { value: T; name: string }[],
  valueChanged: (value: T) => void,
  warning: string | null,
  disabled?: boolean
) {
  const valueStr = value !== null ? value : "";
  const warningStatus = warning !== null;
  const warningText = warningStatus ? warning : "";
  const selectComponent = (
    <Select
      label={prompt}
      value={valueStr}
      onChange={(event: SelectChangeEvent) => {
        valueChanged(event.target.value as T);
      }}
      disabled={disabled}
    >
      {valueOptions.map((valOpt, index) => (
        <MenuItem key={index} value={valOpt.value}>
          {valOpt.name}
        </MenuItem>
      ))}
    </Select>
  );

  const inputNormal = (
    <div className="p-2 rounded-lg">
      {inputSetting(prompt, selectComponent, 240)}
    </div>
  );

  const inputWarning = (
    <div className="p-2 rounded-lg border-high_orange border-2">
      {inputSetting(prompt, selectComponent, 240)}
    </div>
  );

  const inputComponent = (
    <div>
      {warningStatus ? inputWarning : inputNormal}
      <div className="font-normal text-lg text-high_orange text-right h-0">
        {warningText}
      </div>
    </div>
  );

  return makeInput(title, tooltipText, inputComponent);
}

export function makeOtherInput(
  title: string | null,
  tooltipText: string | null,
  inputComponent: JSX.Element,
  warning: string | null
) {
  const warningStatus = warning !== null;
  const warningText = warningStatus ? warning : "";
  const component = (
    <div>
      <div
        className={`p-2 rounded-lg ${
          warningStatus ? "border-high_orange border-2" : ""
        }`}
      >
        {inputComponent}
      </div>
      <div className="font-normal text-lg text-high_orange text-right h-0">
        {warningText}
      </div>
    </div>
  );
  return makeInput(title, tooltipText, component);
}

export const MultiDropdownCheckboxInput: React.FC<
  MultiDropdownCheckboxInputProps & {
    pageType: "destinations" | "vessels" | "routes";
  }
> = ({
  title,
  tooltipText,
  prompt,
  selectedValues,
  destinationOptions,
  onSelectionChange,
  warning,
  pageType,
}) => {
  const handleChange = (event: SelectChangeEvent<typeof selectedValues>) => {
    const value = event.target.value;
    onSelectionChange(typeof value === "string" ? value.split(",") : value);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 224,
        width: 250,
      },
    },
  };

  const titleAndTooltip = (
    <div className="flex space-x-6 justify-start items-center text-xl">
      <div className="flex">
        {title && <div>{title}</div>}
        {tooltipText && (
          <Tooltip title={tooltipText} placement="top">
            <div>
              <BsQuestionLg className="text-xs" />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );

  const noContent = (
    <div>
      <Select
        labelId="mutiple-checkbox-label"
        id="mutiple-checkbox"
        multiple
        value={[]}
        displayEmpty
        onChange={handleChange}
        input={<OutlinedInput label={prompt} />}
        renderValue={() => `No ${pageType} available.`}
        MenuProps={MenuProps}
        disabled
        sx={{ width: "100%" }}
      >
        <MenuItem disabled value="">
          {`No ${pageType} available.`}
        </MenuItem>
      </Select>
      {pageType === "destinations" && (
        <Typography variant="caption" display="block" gutterBottom>
          <Link
            to={`/administration/${pageType}`}
            className="text-gray-500 text-sm italic hover:underline"
          >
            {`Click here to add ${pageType}.`}
          </Link>
        </Typography>
      )}
    </div>
  );

  const dropdownContent =
    destinationOptions.length > 0 ? (
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="mutiple-checkbox-label">{prompt}</InputLabel>
        <Select
          labelId="mutiple-checkbox-label"
          id="mutiple-checkbox"
          multiple
          value={selectedValues}
          onChange={handleChange}
          input={<OutlinedInput label={prompt} />}
          renderValue={(selected) =>
            selected
              .map(
                (value) =>
                  destinationOptions.find((option) => option.value === value)
                    ?.name || value
              )
              .join(", ")
          }
          MenuProps={MenuProps}
        >
          {destinationOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={selectedValues.indexOf(option.value) > -1} />
              <ListItemText primary={option.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ) : (
      noContent
    );

  return (
    <div className="flex  space-x-2 space-y-2 items-center text-xl w-full">
      {titleAndTooltip}
      {dropdownContent}
    </div>
  );
};
export function makeTextInputWithChange(
  title: string,
  tooltipText: string,
  fieldPrompt: string,
  fieldHandle: string,
  warning: string | null,
  defaultValue?: string,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
) {
  const warningStatus = warning !== null;
  const warningText = warningStatus ? warning : "";
  const inputComponent = (
    <TextEntryWarningText
      prompt={fieldPrompt}
      name={fieldHandle}
      type="text"
      warningStatus={warningStatus}
      warningText={warningText}
      defaultValue={defaultValue}
      size={"Large"}
    />
  );
  return makeInput(title, tooltipText, inputComponent);
}
