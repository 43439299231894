import React, { useEffect, useState } from "react";
import { makeConfirmModal, makeModal } from "../../../components/Modal";
import { useAppContext } from "../../../context/variables";
import { makeDropdownInput } from "../../../components/FormInputs";
import { EntityState } from "../../../context/types";
import { makeSubmitButton } from "../../../components/Button";
import useManageData from "../../../apiComms/manageData";

export function EditStateDestModal(open: boolean, setOpen: (open: boolean)=>void, managedDestId: string|null, onCloseManageDestModal: ()=>void) {
  const { data } = useAppContext()
  const { apiPutDestsState } = useManageData()
  const [confirmEditStateDestModalOpen, setConfirmEditStateDestModalOpen] = useState(false)

  const destinationList = data.org.destinationList.val
  const destMaybe = destinationList?.find((dest)=>dest.lid === managedDestId)
  const destName = (destMaybe === undefined) ? "N/A" : destMaybe.displayName

  function onClose() {
    setOpen(false)
  }
  function onSubmitClick() {
    // console.log("Hit submit edit destination state")
    setConfirmEditStateDestModalOpen(true)
  }
  function onConfirmClick() {
    // console.log("Hit confirm edit destination state")
    const dest = data.org.destinationList.val?.find((dest)=>dest.lid === managedDestId)
    // const destName = dest?.displayName
    const orgId = dest?.orgUid
    // console.log('edited the state of "'+destName+'" ('+managedDestId+') in the org '+orgId+' to '+state)
    apiPutDestsState(orgId, managedDestId, state)
    setConfirmEditStateDestModalOpen(false)
    onClose()
    onCloseManageDestModal()
  }

  const oldState = (destMaybe !== undefined) ? destMaybe.state : EntityState.Active
  const [state, setState] = useState(oldState)
  useEffect(()=>{
    setState(oldState)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managedDestId])
  const stateOptions: {value: EntityState, name: string}[] = Object.values(EntityState).map((state)=>{
    return {value: state, name: state}
  })
  const content = 
    <div className="flex flex-col space-y-4">
      {makeDropdownInput(
        "Destination state:", 
        "The state of the destination. Determines whether it will be shown to customers as a suggestion, it is seen onboard, it is hidden, or it will be used to avoid generating the same suggestion twice.", 
        "State", 
        state, stateOptions, setState, null
      )}
      {makeSubmitButton(onSubmitClick)}
      {makeConfirmModal(
        "Confirm editing state",
        'You are about to set the state of "'+destName+'" to '+state,
        "Confirm: Edit state",
        onConfirmClick,
        confirmEditStateDestModalOpen,
        setConfirmEditStateDestModalOpen
      )}
    </div>
  const title = (managedDestId !== null) ? 'Edit state of "'+destName+'"' : "Edit state of destination"
  
  return makeModal(title, content, open, onClose)
}
