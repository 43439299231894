import React, { useState, useEffect } from "react";
import { makeModal } from "../../../components/Modal";
import { makeSubmitButton } from "../../../components/Button";
import LoadingSpinner from "../../../components/atoms/LoadingSpinner";
import useManageData from "../../../apiComms/manageData";
import { BsQuestionLg } from "react-icons/bs";
import { Tooltip } from "@mui/material";
import { MultiDropdownCheckboxInput } from "../../../components/FormInputs";
import { useAppContext } from "../../../context/variables";

interface CheckboxProps {
  id: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  checked,
  onChange,
  label,
}) => (
  <div className="flex items-center space-x-2 mb-4">
    <input
      id={id}
      type="checkbox"
      checked={checked}
      onChange={(e) => onChange(e.target.checked)} 
      className="form-checkbox h-5 w-5 border-gray-300 rounded text-blue-600 focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-blue-200"
    />
    <label
      htmlFor={id}
      className="cursor-pointer hover:text-blue-500 flex justify-center items-center text-gray-700 text-xl"
    >
      {label}
    </label>
  </div>
);

export function EditVesselGroupsModal(props: {
  open: boolean;
  setOpen: (open: boolean) => void;
  groupId: string | null;
  orgId: string | null;
  onCloseManageModal: () => void;
  groupList: any[];
}) {
  const { open, setOpen, groupId, orgId, onCloseManageModal, groupList } =
    props;
  const { apiPutVesselGroups } = useManageData();
  const [isLoading, setIsLoading] = useState(false);
  const { data } = useAppContext();

  const getGroup = () => {
    return groupList.find((group) => group.groupId === groupId);
  };

  // Initialize state with values from group or default values
  const group = getGroup();
  const [name, setName] = useState<string>(group?.name || "Group Name");
  const [inputName, setInputName] = useState<string>(
    group?.name || "Group Name"
  );
  const [groupReport, setGroupReport] = useState<boolean>(
    group?.groupReport || false
  );
  const [sisters, setSisters] = useState<boolean>(group?.sisters || false);
  const [sisterReport, setSisterReport] = useState<boolean>(
    group?.sisterReport || false
  );
  const [selectedVesselIds, setSelectedVesselIds] = useState<string[]>(
    group?.vessels || []
  );

  // Update state when groupId changes
  useEffect(() => {
    const group = groupList.find((group) => group.groupId === groupId);
    if (group) {
      setName(group.name);
      setInputName(group.name);
      setGroupReport(!!group.group_report);
      setSisters(group.sisters);
      setSisterReport(group.sisterReport);
      setSelectedVesselIds(group.vessels);
    } else {
      // console.log("Group not found in groupList");
    }
  }, [groupId, groupList]);

  // Function to handle vessel selection change
  const handleMultiVesselGroupSelection = (value: string[]) => {
    setSelectedVesselIds(value);
  };

  const handleGroupReportChange = (isChecked: boolean) => {
    setGroupReport(isChecked);
  };

  const handleSistersChange = (isChecked: boolean) => {
    setSisters(isChecked);
  };

  const handleSisterReportChange = (isChecked: boolean) => {
    setSisterReport(isChecked);
  };

  const onSubmitClick = async () => {
    // console.log("Submitting:", {
    //   inputName,
    //   selectedVesselIds,
    //   groupReport,
    //   sisters,
    //   sisterReport,
    // });
    setIsLoading(true);
    try {
      if (orgId !== null && groupId !== null) {
        await apiPutVesselGroups(
          orgId,
          groupId,
          inputName,
          selectedVesselIds,
          groupReport,
          sisters,
          sisterReport
        );
        // console.log("Update was successful");
        onCloseManageModal(); 
        setOpen(false);
      } else {
        throw new Error("Organization ID or Group ID is null");
      }
    } catch (error) {
      console.error("Error updating vessel group:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const modalContent = (
    <div className="flex flex-col space-y-4">
      {open && (
        <>
          <div className="flex space-x-6 justify-start items-center text-xl">
            <div className="flex">
              <div>Group Name</div>
              <Tooltip
                title="Provide a unique name for this vessel group."
                placement="top"
              >
                <div>
                  <BsQuestionLg className="text-xs" />
                </div>
              </Tooltip>
            </div>
            <input
              placeholder={name}
              name="groupNameHandle"
              type="text"
              value={inputName}
              onChange={(e) => setInputName(e.target.value)}
              className={`w-[370px] h-[70px] px-3 rounded-lg placeholder:italic border-[1px] border-deep_blue`}
            />
          </div>
          <div className="mb-6">
            <MultiDropdownCheckboxInput
              title="Select Vessels:"
              tooltipText="Choose the vessel groups for this group"
              prompt="Vessels"
              selectedValues={selectedVesselIds}
              destinationOptions={data.org.vesselList.map((vessel) => ({
                value: vessel.id,
                name: vessel.name,
              }))}
              onSelectionChange={handleMultiVesselGroupSelection}
              warning={
                selectedVesselIds.length === 0
                  ? "Please select at least one vessel group"
                  : null
              }
              pageType="vessels"
            />
          </div>
          <div className="flex items-center space-x-4">
            <Checkbox
              id="group-report-checkbox"
              checked={groupReport}
              onChange={handleGroupReportChange}
              label="Group Report"
            />
          </div>
          <div className="flex items-center space-x-4">
            <Checkbox
              id="sisters-checkbox"
              checked={sisters}
              onChange={handleSistersChange}
              label="Sisters"
            />
          </div>
          <div className="flex items-center space-x-4">
            <Checkbox
              id="sister-report-checkbox"
              checked={sisterReport}
              onChange={handleSisterReportChange}
              label="Sister Report"
            />
          </div>
        </>
      )}
      {makeSubmitButton(onSubmitClick)}
    </div>
  );

  return (
    <>
      {open && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
          <LoadingSpinner />
        </div>
      )}
      {makeModal(
        groupId !== null ? `Edit vessel group "${name}"` : "Edit Vessel Group",
        modalContent,
        open,
        () => setOpen(false)
      )}
    </>
  );
}
