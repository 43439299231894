import { useEffect, useState } from "react";
import { useAppContext } from "../../../context/variables";
import useFetchData from "../../../apiComms/fetchData";
import Tab from "../../../tabs/Tab";
import { genericTableRelatedBits } from "../../../components/Table";
import { addButton } from "../../../components/Button";
import AddUserModal from "./AddUserModal";
import ManageUserModal from "./manage/ManageUserModal";
import UsersTable from "./UsersTable";
import UserIcon from "../../../assets/icons/EmptyStates/UserIcon";
import EmptyState from "../../../components/atoms/EmptyState";

export default function UsersTab() {
  const { data, vars } = useAppContext();
  const { fetchUsersGeneral, fetchDataRootOrgs, fetchDataOrganisations } = useFetchData();

  useEffect(() => {
    fetchDataRootOrgs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    fetchDataOrganisations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars.auth.idToken, vars.auth.organisationId]);

  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [manageUserModalOpen, setManageUserModalOpen] = useState(false);
  const [managedUserId, setManagedUserId] = useState<string | null>(null);

  const content = (
    <div className="flex flex-col space-y-4 pt-4 text-deep_blue">
      {addButton("Add new user", () => setAddUserModalOpen(true))}
      {genericTableRelatedBits("Users", data.org.userList?.length, fetchUsersGeneral)}
      {data.org.userList?.length ? (
        UsersTable(data.org.userList, data.rootAccess.orgs, setManagedUserId, setManageUserModalOpen)
      ) : (
        <EmptyState
          icon={<UserIcon />}
          title="No users have been added"
          description="When you add a user, it will appear here"
          buttonText="Add user"
          onButtonClick={() => setAddUserModalOpen(true)}
        />
      )}
      {AddUserModal(addUserModalOpen, setAddUserModalOpen, data)}
      {ManageUserModal(manageUserModalOpen, setManageUserModalOpen, managedUserId, setManagedUserId)}
    </div>
  );

  return <Tab title="Users" content={content} />;
}
