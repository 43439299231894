import React, { useState } from "react";
import {
  DatePicker,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MenuItem, Select, SelectChangeEvent, Tooltip } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import { Timescale } from "../context/types";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { styled } from "@mui/material/styles";
import utc from "dayjs/plugin/utc";
import { dateInNextPeriod, dateInPrevPeriod } from "../helpers/dateTimeHelpers";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
dayjs.extend(utc);

dayjs.extend(isBetweenPlugin);

const LOCALE = "en-ie";

interface timePeriodProps {
  fromTime: Date | null;
  onChange: (date: Date, timescale: Timescale) => void;
}

interface GeneralDateTimePickerProps {
  value: Date | null;
  setValue: React.Dispatch<React.SetStateAction<Date | null>>;
}

function CustomYear(props: timePeriodProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={LOCALE}>
      <DatePicker
        orientation="portrait"
        views={["year"]}
        label="Calendar year"
        minDate={dayjs("2020")}
        maxDate={dayjs()}
        slotProps={{
          textField: { onKeyDown: (e) => e.preventDefault() },
          toolbar: { toolbarFormat: "YYYY" },
        }}
        value={dayjs(props.fromTime)}
        onChange={(newValue) => {
          if (newValue !== null) {
            props.onChange(newValue.toDate(), Timescale.Year);
          }
        }}
        format={"YYYY"}
      />
    </LocalizationProvider>
  );
}

function CustomMonth(props: timePeriodProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={LOCALE}>
      <DatePicker
        orientation="portrait"
        views={["year", "month"]}
        label="Calendar month"
        minDate={dayjs("2020")}
        maxDate={dayjs()}
        openTo="month"
        slotProps={{
          textField: { onKeyDown: (e) => e.preventDefault() },
          toolbar: { toolbarFormat: "MMMM YYYY" },
        }}
        value={dayjs(props.fromTime)}
        onChange={(newValue) => {
          if (newValue !== null) {
            props.onChange(newValue.toDate(), Timescale.Month);
          }
        }}
        format={"MMMM YYYY"}
      />
    </LocalizationProvider>
  );
}

// dayjs.extend(isBetweenPlugin);

// function CustomWeek(props: timePeriodProps) {
//   const value = dayjs(props.fromTime)

//   // const renderWeekPickerDay = (
//   //   date: Dayjs,
//   //   selectedDates: Array<Dayjs | null>,
//   //   // ...pickersDayProps: PickersDayProps<Dayjs>,
//   // ) => {
//   //   if (!value) {
//   //     return <PickersDay {...pickersDayProps} />;
//   //   }

//   //   const start = value.startOf('week');
//   //   const end = value.endOf('week');
//   //   // console.log("start:", start)
//   //   // console.log("end:", end)

//   //   const dayIsBetween = date.isBetween(start, end, null, '[]');
//   //   const isFirstDay = date.isSame(start, 'day');
//   //   const isLastDay = date.isSame(end, 'day');

//   //   return (
//   //     <CustomPickersDay
//   //       {...pickersDayProps}
//   //       disableMargin
//   //       dayIsBetween={dayIsBetween}
//   //       isFirstDay={isFirstDay}
//   //       isLastDay={isLastDay}
//   //     />
//   //   );
//   // };

//   return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={LOCALE}>
//     <DatePicker
//       orientation="portrait"
//       // displayStaticWrapperAs="desktop"
//       views={['year', 'month', 'day']}
//       label="Calendar week"
//       minDate={dayjs('2020')}
//       maxDate={dayjs()}
//       // InputProps={{ onKeyDown: e => e.preventDefault() }}
//       slotProps={{
//         textField: { inputProps: { onKeyDown: e => e.preventDefault() } },
//         toolbar: { toolbarFormat: props.timePeriod }
//       }}
//       value={value}
//       onChange={(newValue) => {
//         if(newValue !== null) {
//           props.onChange(newValue.toDate(), Timescale.Week)
//         }
//       }}
//       // renderDay={renderWeekPickerDay} ???
//       // slotProps={{ textField: {} }}
//       // renderInput={(params) => <TextField {...params} />}
//       format={props.timePeriod}
//     />
//   </LocalizationProvider>
// }

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  isSelected: boolean;
  isHovered: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "isHovered",
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.primary[theme.palette.mode],
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary[theme.palette.mode],
    },
  }),
  ...(day.day() === 1 && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(day.day() === 0 && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
})) as React.ComponentType<CustomPickerDayProps>;

const isInSameWeek = (dayA: Dayjs, dayB: Dayjs | null | undefined) => {
  if (dayB == null) {
    return false;
  }

  return dayA.isSame(dayB, "week");
};

function Day(
  props: PickersDayProps<Dayjs> & {
    selectedDay?: Dayjs | null;
    hoveredDay?: Dayjs | null;
  }
) {
  const { day, selectedDay, hoveredDay, ...other } = props;

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    />
  );
}

export function GeneralWeekPicker(
  value: Dayjs | null,
  setValue: (value: Dayjs | null) => void,
  hoveredDay: Dayjs | null,
  setHoveredDay: (day: Dayjs | null) => void,
) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={LOCALE}>
      <DatePicker
        value={value}
        onChange={setValue}
        minDate={dayjs("2020")}
        maxDate={dayjs()}
        views={["year", "month", "day"]}
        label="Calendar week"
        showDaysOutsideCurrentMonth
        // displayWeekNumber
        slots={{ day: Day }}
        format={"Week of D MMM YYYY"}
        slotProps={{
          textField: { onKeyDown: (e) => e.preventDefault() },
          day: (ownerState) =>
            ({
              selectedDay: value,
              hoveredDay,
              onPointerEnter: () => setHoveredDay(ownerState.day),
              onPointerLeave: () => setHoveredDay(null),
            } as any),
        }}
      />
    </LocalizationProvider>
  );
}

export function GeneralDateRangePicker(
  startValue: Date | null,
  endValue: Date | null,
  setStartValue: (value: Date | null) => void,
  setEndValue: (value: Date | null) => void
) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={LOCALE}>
      <DatePicker
        value={dayjs.utc(startValue)}
        onChange={(newValue) => {
          if (newValue !== null) {
            setStartValue(newValue.toDate());
          }
        }}
        minDate={dayjs.utc("2020")}
        maxDate={dayjs.utc()}
        views={["year", "month", "day"]}
        label="Start date"
        format={"D MMM YYYY"}
      />
      <DatePicker
        value={dayjs.utc(endValue)}
        onChange={(newValue) => {
          if (newValue !== null) {
            setEndValue(newValue.toDate());
          }
        }}
        minDate={dayjs.utc("2020")}
        maxDate={dayjs.utc()}
        views={["year", "month", "day"]}
        label="End date"
        format={"D MMM YYYY"}
      />
    </LocalizationProvider>
  );
}

export function CustomDay(props: timePeriodProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={LOCALE}>
      <DatePicker
        orientation="portrait"
        slotProps={{
          textField: { onKeyDown: (e) => e.preventDefault() },
          toolbar: { toolbarFormat: "D MMM YYYY" },
        }}
        views={["year", "month", "day"]}
        label="Calendar date"
        minDate={dayjs("2020")}
        maxDate={dayjs()}
        value={dayjs(props.fromTime)}
        onChange={(newValue) => {
          if (newValue !== null) {
            props.onChange(newValue.toDate(), Timescale.Day);
          }
        }}
        format={"D MMM YYYY"}
      />
    </LocalizationProvider>
  );
}

export function GeneralDateTimePicker(
  value: Date | null,
  setValue: (value: Date | null) => void
) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={LOCALE}>
      <DateTimePicker
        value={dayjs.utc(value)}
        onChange={(newValue) => {
          if (newValue !== null) {
            setValue(newValue.toDate());
          }
        }}
        minDate={dayjs.utc("2020")}
        maxDateTime={dayjs.utc()}
        views={["year", "month", "day", "hours", "minutes"]}
        label="Date & time"
        format={"D MMM YYYY - HH:mm"}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
      />
    </LocalizationProvider>
  );
}

export function TimePeriodPicker(
  timescale: Timescale,
  fromTime: Date | null,
  onChange: (date: Date, timescale: Timescale) => void
) {
  const [hoveredDay, setHoveredDay] = useState<Dayjs | null>(null)
  let timePeriodInput: JSX.Element
  if (timescale === Timescale.Year) {
    timePeriodInput = (
      <CustomYear
        fromTime={fromTime}
        onChange={onChange}
      />
    );
  } else if (timescale === Timescale.Month) {
    timePeriodInput = (
      <CustomMonth
        fromTime={fromTime}
        onChange={onChange}
      />
    );
  } else if (timescale === Timescale.Week) {
    // timePeriodInput = <CustomWeek fromTime={fromTime} timePeriod={timePeriod} onChange={onChange}/>
    timePeriodInput = GeneralWeekPicker(dayjs(fromTime), (newValue) => {
      if (newValue !== null) {
        onChange(newValue.toDate(), Timescale.Week);
      }
    }, hoveredDay, setHoveredDay)
  } else {
    // if(timescale === Timescale.Day)
    timePeriodInput = (
      <CustomDay
        fromTime={fromTime}
        onChange={onChange}
      />
    );
  }
  return timePeriodInput;
}

export function makeTimescalePicker(
  timescale: Timescale,
  timescaleChanged: (timescale: Timescale) => void
) {
  const timescaleOptions: { name: string; value: Timescale }[] = [
    { name: "Year", value: Timescale.Year },
    { name: "Month", value: Timescale.Month },
    { name: "Week", value: Timescale.Week },
    { name: "Day", value: Timescale.Day },
  ];
  const timescalePickerComponent = (
    <Select
      label="Timescale"
      value={timescale}
      onChange={(event: SelectChangeEvent) => {
        timescaleChanged(event.target.value as Timescale);
      }}
    >
      {timescaleOptions.map(({ name: theName, value: theValue }, index) => {
        return (
          <MenuItem key={index} value={theValue}>
            {theName}
          </MenuItem>
        );
      })}
    </Select>
  );
  return timescalePickerComponent;
}

export const GeneralDateTimePickerWithProps: React.FC<
  GeneralDateTimePickerProps
> = ({ value, setValue }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        value={dayjs.utc(value)}
        onChange={(newValue) => {
          if (newValue !== null) {
            setValue(newValue.toDate());
          }
        }}
        minDateTime={dayjs.utc("2020")}
        maxDateTime={dayjs.utc()}
        views={["year", "month", "day", "hours", "minutes"]}
        label="Date & Time"
        format={"D MMM YYYY - HH:mm"}
      />
    </LocalizationProvider>
  );
};

export function PrevNextPeriodButtons(fromTime: Date|null, toTime: Date|null, timescale: Timescale, timePeriodChanged: (date: Date)=>void) {
  const minLegalDate = dayjs("2020");
  let prevPeriodDisabled: boolean;
  if (fromTime === null) {
    prevPeriodDisabled = true;
  } else {
    const prevDate: Date = dateInPrevPeriod(fromTime);
    prevPeriodDisabled = minLegalDate.isAfter(prevDate);
  }
  function goToPrevPeriod() {
    if (prevPeriodDisabled) return;
    if (fromTime !== null) {
      const prevDate: Date = dateInPrevPeriod(fromTime);
      timePeriodChanged(prevDate)
    }
  }
  
  const prevPeriodArrow = prevPeriodDisabled ? (
    <div
      className="w-fit text-4xl cursor-not-allowed opacity-25"
      aria-hidden="true"
      role="button"
    >
      <HiChevronLeft />
    </div>
  ) : (
    <div
      className="w-fit text-4xl cursor-pointer opacity-75"
      onClick={goToPrevPeriod}
      aria-label="Previous week"
      role="button"
      data-mui-internal-clone-element="true"
    >
      <HiChevronLeft />
    </div>
  );  

  const prevPeriodComponent = (
    <Tooltip title={"Previous " + timescale} placement="right">
      {prevPeriodArrow}
    </Tooltip>
  );

  const maxLegalDate = dayjs();
  let nextPeriodDisabled: boolean;
  if (toTime === null) {
    nextPeriodDisabled = true;
  } else {
    const nextDate: Date = dateInNextPeriod(toTime);
    nextPeriodDisabled = maxLegalDate.isBefore(nextDate);
  }
  function goToNextPeriod() {
    if (nextPeriodDisabled) return;
    if (toTime !== null) {
      const nextDate: Date = dateInNextPeriod(toTime);
      timePeriodChanged(nextDate)
    }
  }
  const nextPeriodArrow = nextPeriodDisabled ? (
    <div className="w-fit text-4xl cursor-not-allowed opacity-25" role="button">
      <HiChevronRight />
    </div>
  ) : (
    <div
      className="w-fit text-4xl cursor-pointer opacity-75"
      onClick={goToNextPeriod}
      aria-label="Next week"
      role="button"
    >
      <HiChevronRight />
    </div>
  );
  const nextPeriodComponent = (
    <Tooltip title={"Next " + timescale} placement="left">
      {nextPeriodArrow}
    </Tooltip>
  )

  const prevNextPeriodButtons =
    <div className="absolute bottom-3 right-5 left-16 flex justify-between">
      {prevPeriodComponent}
      {nextPeriodComponent}
    </div>
  
  return prevNextPeriodButtons
}
