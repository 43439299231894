import { NavigateFunction } from "react-router-dom";
import { apiData, variables, viewSettings } from "../context/variables";
import {
  getApiAuthorization,
  authenticationExpirationCheck,
} from "../auth/authentication";
import axios from "axios";
import { ApiResponseAxios } from "../types";

export async function apiCallGeneralAxios(
  vars: variables,
  data: apiData,
  view: viewSettings,
  navigate: NavigateFunction,
  url: string | null,
  method: string | undefined,
  body: string | null | undefined,
  handler: (response: ApiResponseAxios) => any
) {
  authenticationExpirationCheck(vars, data, view, navigate);
  const authorization = getApiAuthorization(vars);
  if (authorization === null || url === null) {
    // console.log("Calling apiCallGeneral with authorization:", authorization, "and url:", url)
    return;
  }
  // console.log("Making an api call:", url, method, body) //, "the authorization is: ", authorization)
  try {
    const response = await axios({
      method: method,
      url: url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authorization,
      },
      data: body,
    });

    handler({ response });
  } catch (error: any) {
    console.log("Error in apiCallGeneralAxios:", error);
    handler({ response: null, error: error });
  }
}

export function apiCallDeleteDataAxios(
  vars: variables,
  data: apiData,
  view: viewSettings,
  navigate: NavigateFunction,
  url: string | null,
  handler: (response: ApiResponseAxios) => any,
  body?: any
) {
  apiCallGeneralAxios(vars, data, view, navigate, url, "DELETE", body, handler);
}

export function apiCallGeneral(
  vars: variables,
  data: apiData,
  view: viewSettings,
  navigate: NavigateFunction,
  url: string | null,
  method: string | undefined,
  body: string | null | undefined,
  handler: (responseJson: any, response: Response) => any
) {
  authenticationExpirationCheck(vars, data, view, navigate);
  const authorization = getApiAuthorization(vars);
  if (authorization === null || url === null) {
    // console.log("Calling apiCallGeneral with authorization:", authorization, "and url:", url)
    return;
  }
  // console.log("Making an api call:", url, method, body) //, "the authorization is: ", authorization)
  fetch(url, {
    method: method,
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: authorization,
    }),
    body: body,
  })
    .then(async (response) => {
      handler(await response.json(), response);
    })
    .catch((error) => {
      // console.log(error)
    });
}

export function apiCallGet(
  vars: variables,
  data: apiData,
  view: viewSettings,
  navigate: NavigateFunction,
  url: string | null,
  handler: (responseJson: any, response: Response) => any
) {
  apiCallGeneral(vars, data, view, navigate, url, "GET", undefined, handler);
}

export function apiCallPost(
  vars: variables,
  data: apiData,
  view: viewSettings,
  navigate: NavigateFunction,
  url: string | null,
  body: string,
  handler: (responseJson: any, response: Response) => any
) {
  apiCallGeneral(vars, data, view, navigate, url, "POST", body, handler);
}

export function apiCallDelete(
  vars: variables,
  data: apiData,
  view: viewSettings,
  navigate: NavigateFunction,
  url: string | null,
  handler: (responseJson: any, response: Response) => any
) {
  apiCallGeneral(vars, data, view, navigate, url, "DELETE", undefined, handler);
}

export function apiCallDeleteData(
  vars: variables,
  data: apiData,
  view: viewSettings,
  navigate: NavigateFunction,
  url: string | null,
  handler: (responseJson: any, response: Response) => any,
  body?: any
) {
  apiCallGeneral(vars, data, view, navigate, url, "DELETE", body, handler);
}

export function apiCallPut(
  vars: variables,
  data: apiData,
  view: viewSettings,
  navigate: NavigateFunction,
  url: string | null,
  body: string,
  handler: (responseJson: any, response: Response) => any
) {
  apiCallGeneral(vars, data, view, navigate, url, "PUT", body, handler);
}
