import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { findTimeBounds, getWeek } from "../helpers/dateTimeHelpers";
import {
  AccessLevel,
  Aggregation,
  EntityState,
  OperatorTabMetric,
  RealTimeSignal,
  ReportTimescale,
  TagScope,
  TagValueType,
  Timescale,
  VesselActivity,
} from "./types";
import { coords } from "../components/Map";
import { RealTimeFetchMode } from "../pages/realTime/RealTimePage";

//--------------------------------------
// The structure of the variables stored in context
//--------------------------------------

interface authenticationVars {
  idToken: string | null;
  setIdToken: React.Dispatch<React.SetStateAction<string | null>>;
  accessToken: string | null;
  setAccessToken: React.Dispatch<React.SetStateAction<string | null>>;
  refreshToken: string | null;
  setRefreshToken: React.Dispatch<React.SetStateAction<string | null>>;
  username: string | null;
  setUsername: React.Dispatch<React.SetStateAction<string | null>>;
  email: string | null;
  setEmail: React.Dispatch<React.SetStateAction<string | null>>;
  isOperator: boolean | null;
  setIsOperator: React.Dispatch<React.SetStateAction<boolean | null>>;
  isAdmin: boolean | null;
  setIsAdmin: React.Dispatch<React.SetStateAction<boolean | null>>;
  isOwner: boolean | null;
  setIsOwner: React.Dispatch<React.SetStateAction<boolean | null>>;
  organisationId: string | null;
  setOrganisationId: React.Dispatch<React.SetStateAction<string | null>>;
  userGroups: string[] | null;
  setUserGroups: React.Dispatch<React.SetStateAction<string[] | null>>;
  exp: number | null;
  setExp: React.Dispatch<React.SetStateAction<number | null>>;
  accessLevel: getAndSet2<AccessLevel | null>;
}

interface loginVars {
  loggedIn: boolean | null;
  // true:  logged in
  // false: not logged in
  // null:  awaiting localStorage retrieval of loggedIn state
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean | null>>;
  errorCode: string | null;
  setErrorCode: React.Dispatch<React.SetStateAction<string | null>>;
  errorMessage: string | null;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
}

export interface variables {
  auth: authenticationVars;
  login: loginVars;
}

interface organisationEntry {
  name: string;
  id: string;
}

export interface vesselListEntryData {
  id: string;
  name: string;
}

export interface tripTypeListEntryData {
  value: string;
  name: string;
}

export interface destinationEntry {
  lid: string;
  orgUid: string;
  displayName: string;
  latitude: number;
  longitude: number;
  radius: number | null;
  polygon: coords[] | null;
  metadata: string | null;
  state: EntityState;
}

export interface geoZonesEntry {
  gz_id: string;
  org_id: string;
  name: string;
  state: EntityState;
  shape: {
    type: "Circle" | "Polygon";
    coordinates: Array<{
      latitude: string;
      longitude: string;
    }>;
    radius?: string; // Radius is optional and applicable only if type is 'Circle'
  };
  metadata?: string;
}

export interface usersEntry {
  username: string;
  name: string | null;
  org_id: string | null;
  email: string | null;
  phone: string | null;
  access_level: AccessLevel;
  is_operator: boolean;
  pin_code: string | null;
  user_status: string;
}

interface organisationData {
  organisationList: organisationEntry[] | null;
  setOrganisationList: React.Dispatch<
    React.SetStateAction<organisationEntry[] | null>
  >;
  organisationName: string | null;
  setOrganisationName: React.Dispatch<React.SetStateAction<string | null>>;
  vesselList: vesselListEntryData[];
  setVesselList: React.Dispatch<React.SetStateAction<vesselListEntryData[]>>;
  destinationList: getAndSet<destinationEntry[] | null>;
  opModeList: getAndSet<OpMode[] | null>;
  // destinationList: destinationEntry[];
  // setDestinationList: Setter<destinationEntry[]>;
  userList: usersEntry[] | null;
  setUserList: React.Dispatch<React.SetStateAction<usersEntry[] | null>>;
  geoZoneList: getAndSet<geoZonesEntry[] | null>;
}

export interface realTimePoint {
  timestamp: string;
  latitude: number | null;
  longitude: number | null;
  headingTrue: number | null;
  signalType: RealTimeSignal;
  signalValue: number;
}

export interface realTimeData {
  realTimePoints: realTimePoint[];
  vessel: string;
  signal: RealTimeSignal;
  maxAmountStored: RealTimeFetchMode;
}

export interface graphPoint {
  timestamp: string;
  value: number;
}

interface energyOverallData {
  totalFuelUsage: number | null;
  setTotalFuelUsage: React.Dispatch<React.SetStateAction<number | null>>;
  graphFuelUsage: graphPoint[] | null;
  setGraphFuelUsage: React.Dispatch<React.SetStateAction<graphPoint[] | null>>;
  totalDockFuelUsage: number | null;
  setTotalDockFuelUsage: React.Dispatch<React.SetStateAction<number | null>>;
  graphDockFuelUsage: graphPoint[];
  setGraphDockFuelUsage: React.Dispatch<React.SetStateAction<graphPoint[]>>;
  overviewTotalWeek: number | null;
  setOverviewTotalWeek: React.Dispatch<React.SetStateAction<number | null>>;
}

export interface energyOperatorSeriesData {
  operator: string;
  activity: VesselActivity;
  data: graphPoint[];
}

interface energyOperatorsData {
  series: energyOperatorSeriesData[] | null;
  setSeries: React.Dispatch<
    React.SetStateAction<energyOperatorSeriesData[] | null>
  >;
}

interface energyData {
  overall: energyOverallData;
  operators: energyOperatorsData;
}

export interface engineSpeedTorquePoint {
  engineSpeedRpm: number;
  torquePercent: number;
  timePercent: number;
  timeHours: number;
}

export interface sogDistPoint {
  sogKn: number;
  timePercent: number;
  timeHours: number;
}

export interface engineDieselRateSogPoint {
  engineDieselRateLph: number;
  sogKn: number;
  timePercent: number;
  timeHours: number;
}

export interface enginePowerSogPoint {
  enginePowerKw: number;
  sogKn: number;
  timePercent: number;
  timeHours: number;
}

interface performanceData {
  engineSpeedTorqueMaps:
    | { engine: string; map: engineSpeedTorquePoint[] }[]
    | null;
  setEngineSpeedTorqueMaps: React.Dispatch<
    React.SetStateAction<
      { engine: string; map: engineSpeedTorquePoint[] }[] | null
    >
  >;
  sogDistribution: sogDistPoint[] | null;
  setSogDistribution: React.Dispatch<
    React.SetStateAction<sogDistPoint[] | null>
  >;
  totalEngineDieselRateSogMap: engineDieselRateSogPoint[] | null;
  setTotalEngineDieselRateSogMap: React.Dispatch<
    React.SetStateAction<engineDieselRateSogPoint[] | null>
  >;
  totalEnginePowerSogMap: enginePowerSogPoint[] | null;
  setTotalEnginePowerSogMap: React.Dispatch<
    React.SetStateAction<enginePowerSogPoint[] | null>
  >;
}

export enum ReportFileFormat {
  PDF = "PDF",
  CSV = "CSV",
  Unknown = "Unknown",
}

export interface reportEntry {
  filename: string;
  fileFormat: ReportFileFormat;
  link: string;
  path: string;
  reportDate: string;
  reportInterval: ReportTimescale;
  reportKind: string;
  vesselId: string;
  legName: string | null;
  orgId: string | null;
}

interface vesselGroupsEntry {
  orgId: string;
  groupId: string;
  name: string;
  groupReport: boolean;
  sisterReport: boolean;
  sisters: boolean;
  vessels: string[];
}

export interface CheckboxProps {
  id: string;
  checked: boolean;
  setChecked: (checked: boolean) => void;
  label: string;
}

interface reportsData {
  reportList: reportEntry[] | null;
  setReportList: React.Dispatch<React.SetStateAction<reportEntry[] | null>>;
}

type Setter<T> = React.Dispatch<React.SetStateAction<T>>;

type getAndSet<T> = {
  val: T;
  set: Setter<T>;
};

type getAndSet2<T> = [val: T, set: Setter<T>];

function defaultGetAndSet<T>(defaultVal: T): getAndSet<T> {
  return {
    val: defaultVal,
    set: () => 0,
  };
}

function defaultGetAndSet2<T>(defaultVal: T): getAndSet2<T> {
  return [defaultVal, () => 0];
}

export interface tagType {
  id: string;
  name: string;
  orgId: string;
  valueType: TagValueType; // None needs API/core update
  unit: string | null; // Only used for number valueType
  scope: TagScope;
  state: EntityState;
  categories: string[] | null; // Not used yet
}

interface tagTypesData {
  tagTypeList: getAndSet<tagType[] | null>;
  // setTagTypeList: Setter<tagType[]>
}

interface OpModesData {
  opModeList: {
    val: OpMode[] | null;
    set: React.Dispatch<React.SetStateAction<OpMode[] | null>>;
  };
}

export interface tagEvent {
  orgId: string;
  vesselId: string;
  timestamp: string;
  tagTypeId: string;
  value: any;
  userId: string | null;
  category: string | null;
}

interface tagEventsData {
  tagEventList: getAndSet<tagEvent[] | null>;
}

export enum OpModeState {
  Active = "Active",
  Inactive = "Inactive",
  Removed = "Removed",
}

export interface OpMode {
  id: string;
  orgId: string;
  opModeId: string;
  name: string;
  description?: string;
  state: OpModeState;
}

interface OpModesData {
  opModeList: getAndSet<OpMode[] | null>;
}

export interface SignalType {
  label: string;
  value: string;
  unit: string;
}

interface SignalTypesData {
  signalTypeList: getAndSet<SignalType[] | null>;
}

const defaultSignalTypesData: SignalTypesData = {
  signalTypeList: {
    val: null,
    set: () => {},
  },
};

export interface AlarmEvent {
  id: string;
  vesselName: string;
  alarmName: string;
  date: string;
  alarmLevel: string;
}

export interface DeleteAlarmEventPayload {
  alarm_id: string;
  org_id: string;
  vessel_id: string;
}

interface AlarmEventsData {
  alarmEventList: getAndSet<AlarmEvent[] | null>;
}

export interface AlarmType {
  id: string;
  vesselName: string;
  alarmName: string;
  date: string;
  alarmLevel: string;
  alarmType: string;
  operator: string;
  threshold: string;
  geoZone?: string;
  vesselId?: string;
}
interface AlarmTypesData {
  alarmTypeList: getAndSet<AlarmType[] | null>;
}

interface vesselGroupsData {
  groupList: getAndSet<vesselGroupsEntry[] | null>;
}

export enum VoyageState {
  Pending = "Pending",
  Processed = "Processed",
}

export interface Voyage {
  orgId: string;
  vesselId: string;
  startTime: string;
  endTime: string;
  state: VoyageState;
}

interface VoyagesData {
  voyageList: getAndSet<Voyage[] | null>;
}

export interface CustomTextInputProps {
  title: string;
  tooltipText: string;
  fieldPrompt: string;
  fieldHandle: string;
  warning: string | null;
  defaultValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface trip {
  orgId: string;
  vesselId: string;
  startTimestamp: string;
  endTimestamp: string;
  pathId: string;
  routeId: string;
  vesselRouteId: string;
  operators: string[] | null;
  dieselAverage: number | null;
  dieselTotal: number | null;
  electricAverage: number | null;
  electricTotal: number | null;
  distanceTotal: number;
  timeTotal: number;
  timestring: string;
  // Optional voyage-specific properties
  cargo_end?: number;
  cargo_median?: number;
  cargo_start?: number;
  cargo_types?: any[];
  current_dir?: number | null;
  current_spd?: number | null;
  end_dest_id?: string | null;
  end_dest_in_eu?: boolean | null;
  end_dest_lat?: number | null;
  end_dest_lon?: number | null;
  fuel_masses?: FuelMasses;
  op_mode_times?: OperationModeTimes;
  start_dest_id?: string | null;
  start_dest_in_eu?: boolean | null;
  start_dest_lat?: number | null;
  start_dest_lon?: number | null;
  transport_work?: string | null;
  trim?: string | null;
  wind_dir?: number | null;
  wind_spd?: number | null;
  end_timestring: string | null;
  total_diesel: number | null;
  total_dist: number | null;
  total_elec: number | null;
  total_time: number | null;
  current_dir_app?: number | null;
  current_spd_app?: number | null;
  wind_dir_app?: number | null;
  wind_spd_app?: number | null;
  op_mode: string;
}

interface tripsData {
  tripList: getAndSet2<trip[] | null>;
}

export interface rootOrgsEntry {
  uid: string;
  name: string;
}

export interface rootVesselsEntry {
  uid: string;
  org_uid: string;
  name: string;
}

export interface rootDestsEntry {
  lid: string;
  org_uid: string;
  display_name: string | null;
  // location (lat: number/lng: number/radius: number|null)
  latitude: number;
  longitude: number;
  radius: number | null;
  metadata: string | null;
}

export interface rootStatusEntry {
  org_id: string;
  vessel_id: string;
  datastreams: {
    datastream_id: string;
    host: string;
    status_code: number;
  }[];
  lat: number;
  lon: number;
  status_code: number;
  timestamp: number;
  ihelm_version: string;
}

interface rootAccessData {
  orgs: rootOrgsEntry[] | null;
  setOrgs: React.Dispatch<React.SetStateAction<rootOrgsEntry[] | null>>;
  vessels: rootVesselsEntry[] | null;
  setVessels: React.Dispatch<React.SetStateAction<rootVesselsEntry[] | null>>;
  dests: rootDestsEntry[] | null;
  setDests: React.Dispatch<React.SetStateAction<rootDestsEntry[] | null>>;
  status: rootStatusEntry[];
  setStatus: React.Dispatch<React.SetStateAction<rootStatusEntry[]>>;
  geoZones: geoZonesEntry[] | null;
  setGeoZones: React.Dispatch<React.SetStateAction<geoZonesEntry[] | null>>;
  dataIntegrityStatus: dataIntegrityEntry[];
  setDataIntegrityStatus: React.Dispatch<
    React.SetStateAction<dataIntegrityEntry[]>
  >;
}

export interface apiData {
  energy: energyData;
  performance: performanceData;
  reports: reportsData;
  vesselGroups: vesselGroupsData;
  tagTypes: tagTypesData;
  opModes: OpModesData;
  tagEvents: tagEventsData;
  trips: tripsData;
  rootAccess: rootAccessData;
  secondaryTrips: secondaryTripsData;
  secondaryLegsTripsData: secondaryLegsTripData;
  legInstancesTrips: LegInstancesTripsData;
  org: organisationData;
  realTime: realTimeData | null;
  setRealTime: React.Dispatch<React.SetStateAction<realTimeData | null>>;
  routes: RoutesData;
  voyages: VoyagesData;
  vessels: any;
  engineOnOffTrips: EngineOnOffTripsData;
  engineOnOffSecondaryTrips: EngineOnOffSecondaryTripsData;
  alarmEvents: AlarmEventsData;
  alarmTypes: AlarmTypesData;
  signalTypes: SignalTypesData;
}

interface realTimeView {
  vessel: string | null;
  setVessel: React.Dispatch<React.SetStateAction<string | null>>;
  signal: RealTimeSignal;
  setSignal: React.Dispatch<React.SetStateAction<RealTimeSignal>>;
}

export interface energyOverallView {
  fromTime: Date | null;
  setFromTime: React.Dispatch<React.SetStateAction<Date | null>>;
  toTime: Date | null;
  setToTime: React.Dispatch<React.SetStateAction<Date | null>>;
  vessel: string;
  setVessel: React.Dispatch<React.SetStateAction<string>>;
  metric: OperatorTabMetric;
  setMetric: React.Dispatch<React.SetStateAction<OperatorTabMetric>>;
  activity: VesselActivity;
  setActivity: React.Dispatch<React.SetStateAction<VesselActivity>>;
  timescale: Timescale;
  setTimescale: React.Dispatch<React.SetStateAction<Timescale>>;
  timePeriod: string;
  setTimePeriod: React.Dispatch<React.SetStateAction<string>>;
  aggregation: Aggregation;
  setAggregation: React.Dispatch<React.SetStateAction<Aggregation>>;
}

export interface energyOperatorView {
  vessels: string[] | null;
  setVessels: React.Dispatch<React.SetStateAction<string[] | null>>;
  operators: string[] | null;
  setOperators: React.Dispatch<React.SetStateAction<string[] | null>>;
  metric: OperatorTabMetric;
  setMetric: React.Dispatch<React.SetStateAction<OperatorTabMetric>>;
  activities: VesselActivity[];
  setActivities: React.Dispatch<React.SetStateAction<VesselActivity[]>>;
  fromTime: Date | null;
  setFromTime: React.Dispatch<React.SetStateAction<Date | null>>;
  toTime: Date | null;
  setToTime: React.Dispatch<React.SetStateAction<Date | null>>;
  timescale: Timescale;
  setTimescale: React.Dispatch<React.SetStateAction<Timescale>>;
  timePeriod: string;
  setTimePeriod: React.Dispatch<React.SetStateAction<string>>;
  aggregation: Aggregation;
  setAggregation: React.Dispatch<React.SetStateAction<Aggregation>>;
}

export interface viewSettings {
  realTime: realTimeView;
  energyOverall: energyOverallView;
  energyOperator: energyOperatorView;
}

export interface context {
  vars: variables;
  data: apiData;
  view: viewSettings;
}

//--------------------------------------
// Default values for everything in context
//--------------------------------------

const defaultAuthenticationVars: authenticationVars = {
  idToken: null,
  setIdToken: () => 0,
  accessToken: null,
  setAccessToken: () => 0,
  refreshToken: null,
  setRefreshToken: () => 0,
  username: null,
  setUsername: () => 0,
  email: null,
  setEmail: () => 0,
  isOperator: null,
  setIsOperator: () => 0,
  isAdmin: null,
  setIsAdmin: () => 0,
  isOwner: null,
  setIsOwner: () => 0,
  organisationId: null,
  setOrganisationId: () => 0,
  userGroups: null,
  setUserGroups: () => 0,
  exp: null,
  setExp: () => 0,
  accessLevel: defaultGetAndSet2<AccessLevel | null>(null),
};

const defaultLoginVars: loginVars = {
  loggedIn: null,
  setLoggedIn: () => 0,
  errorCode: null,
  setErrorCode: () => 0,
  errorMessage: null,
  setErrorMessage: () => 0,
};

const defaultVariables: variables = {
  auth: defaultAuthenticationVars,
  login: defaultLoginVars,
};

const defaultOrganisationData: organisationData = {
  organisationList: null,
  setOrganisationList: () => 0,
  organisationName: null,
  setOrganisationName: () => 0,
  vesselList: [],
  setVesselList: () => 0,
  destinationList: defaultGetAndSet<destinationEntry[] | null>(null),
  opModeList: defaultGetAndSet<OpMode[] | null>(null),
  geoZoneList: defaultGetAndSet<geoZonesEntry[] | null>(null),
  // {
  //   val: [],
  //   set: () => 0,
  // },
  // destinationList: [],
  // setDestinationList: () => 0,
  userList: null,
  //   {username: "Sash", name: "Sasha Johansson", email: "Sasha@theboatcompany.co.uk", phone: null, accessLevel: AccessLevel.User, userStatus: "Confirmed", operatorStatus: true},
  //   {username: "Pete", name: "Peter Smith", email: "Peter@theboatcompany.co.uk", phone: null, accessLevel: AccessLevel.Admin, userStatus: "Change_PW", operatorStatus: true},
  //   {username: "Gunn", name: "Gunnar", email: "Gunnar@theboatcompany.co.uk", phone: null, accessLevel: AccessLevel.User, userStatus: "Change_PW", operatorStatus: false},
  //   {username: "Welm", name: "Welma", email: "Welma@theboatcompany.co.uk", phone: "37462", accessLevel: AccessLevel.Admin, userStatus: "Confirmed", operatorStatus: false},
  //   {username: "Aver", name: "Avery", email: "Avery@theboatcompany.co.uk", phone: null, accessLevel: AccessLevel.User, userStatus: "Confirmed", operatorStatus: true},
  //   {username: "Anth", name: "Dr. Anthony", email: "Anthony@theboatcompany.co.uk", phone: null, accessLevel: AccessLevel.User, userStatus: "Change_PW", operatorStatus: false},
  //   {username: "John", name: "Johnson", email: "Johnson@theboatcompany.co.uk", phone: null, accessLevel: AccessLevel.Owner, userStatus: "Change_PW", operatorStatus: false},
  // ],
  setUserList: () => 0,
};

const defaultRealTimeData: realTimeData | null = null;

const defaultEnergyOverallData: energyOverallData = {
  totalFuelUsage: null,
  setTotalFuelUsage: () => 0,
  graphFuelUsage: null,
  setGraphFuelUsage: () => 0,
  totalDockFuelUsage: null,
  setTotalDockFuelUsage: () => 0,
  graphDockFuelUsage: [],
  setGraphDockFuelUsage: () => 0,
  overviewTotalWeek: null,
  setOverviewTotalWeek: () => 0,
};

const defaultEnergyOperatorsData: energyOperatorsData = {
  series: null,
  // [
  //   {operator: "AAAA", activity: VesselActivity.Sailing, data: [{timestamp: "0", value: 3}, {timestamp: "1", value: 4}, {timestamp: "2", value: 7}]},
  //   {operator: "AAAA", activity: VesselActivity.AtDock, data: [{timestamp: "0", value: 1}, {timestamp: "1", value: 2}, {timestamp: "2", value: 2}]},
  //   {operator: "BBBB", activity: VesselActivity.Sailing, data: [{timestamp: "0", value: 10}, {timestamp: "1", value: 10}, {timestamp: "2", value: 9}]},
  //   {operator: "BBBB", activity: VesselActivity.AtDock, data: [{timestamp: "0", value: 3}, {timestamp: "1", value: 4}, {timestamp: "2", value: 3}]},
  //   {operator: "BBBB", activity: VesselActivity.Total, data: [{timestamp: "0", value: 14}, {timestamp: "1", value: 12}, {timestamp: "2", value: 12}]},
  //   {operator: "CCCC", activity: VesselActivity.Sailing, data: [{timestamp: "0", value: 11}, {timestamp: "1", value: 8}, {timestamp: "2", value: 4}]},
  //   {operator: "CCCC", activity: VesselActivity.AtDock, data: [{timestamp: "0", value: 7}, {timestamp: "1", value: 9}, {timestamp: "2", value: 15}]},
  //   {operator: "DDDD", activity: VesselActivity.Sailing, data: [{timestamp: "0", value: 1}, {timestamp: "1", value: 2}, {timestamp: "2", value: 4}]},
  //   {operator: "EEEE", activity: VesselActivity.Sailing, data: [{timestamp: "0", value: 2}, {timestamp: "1", value: 3}, {timestamp: "2", value: 8}]},
  //   {operator: "FFFF", activity: VesselActivity.Sailing, data: [{timestamp: "0", value: 3}, {timestamp: "1", value: 4}, {timestamp: "2", value: 16}]},
  //   {operator: "GGGG", activity: VesselActivity.Sailing, data: [{timestamp: "0", value: 4}, {timestamp: "1", value: 5}, {timestamp: "2", value: 32}]},
  //   {operator: "HHHH", activity: VesselActivity.Sailing, data: [{timestamp: "0", value: 5}, {timestamp: "1", value: 6}, {timestamp: "2", value: 6}]},
  //   {operator: "IIII", activity: VesselActivity.Sailing, data: [{timestamp: "0", value: 6}, {timestamp: "1", value: 7}, {timestamp: "2", value: 12}]},
  //   {operator: "JJJJ", activity: VesselActivity.Sailing, data: [{timestamp: "0", value: 7}, {timestamp: "1", value: 8}, {timestamp: "2", value: 5}]},
  //   {operator: "KKKK", activity: VesselActivity.Sailing, data: [{timestamp: "0", value: 8}, {timestamp: "1", value: 9}, {timestamp: "2", value: 4}]},
  //   {operator: "48HY", activity: VesselActivity.Sailing, data: [{timestamp: "0", value: 9}, {timestamp: "1", value: 10}, {timestamp: "2", value: 1}]},
  // ],
  setSeries: () => 0,
};

const defaultEnergyData: energyData = {
  overall: defaultEnergyOverallData,
  operators: defaultEnergyOperatorsData,
};

const defaultPerformanceData: performanceData = {
  engineSpeedTorqueMaps: null,
  setEngineSpeedTorqueMaps: () => 0,
  sogDistribution: null,
  setSogDistribution: () => 0,
  totalEngineDieselRateSogMap: null,
  setTotalEngineDieselRateSogMap: () => 0,
  totalEnginePowerSogMap: null,
  setTotalEnginePowerSogMap: () => 0,
};

const defaultReportsData: reportsData = {
  reportList: [],
  setReportList: () => 0,
};

const defaultVesselsGroupData: vesselGroupsData = {
  groupList: {
    val: null,
    set: () => null,
  },
};

const defaultTagTypesData: tagTypesData = {
  tagTypeList: {
    val: null,
    set: () => 0,
  },
};

const defaultOpModesData: OpModesData = {
  opModeList: {
    val: null,
    set: () => {},
  },
};

const defaultVoyagesData: VoyagesData = {
  voyageList: {
    val: null,
    set: () => {},
  },
};

const defaultAlarmEventsData: AlarmEventsData = {
  alarmEventList: {
    val: null,
    set: () => {},
  },
};

const defaultAlarmTypesData: AlarmTypesData = {
  alarmTypeList: {
    val: null,
    set: () => {},
  },
};

const defaultTagEventsData: tagEventsData = {
  tagEventList: {
    val: null,
    set: () => 0,
  },
};

const defaultTripsData: tripsData = {
  tripList: defaultGetAndSet2<trip[] | null>(null),
};

const defaultRootAccessData: rootAccessData = {
  orgs: null,
  setOrgs: () => 0,
  vessels: null,
  setVessels: () => 0,
  dests: null,
  setDests: () => 0,
  geoZones: null,
  setGeoZones: () => 0,
  status: [],
  setStatus: () => 0,
  dataIntegrityStatus: [],
  setDataIntegrityStatus: () => 0,
};

const defaultSecondaryTripsData: secondaryTripsData = {
  secondaryTripList: defaultGetAndSet2<secondaryTripData[] | null>(null),
};

const defaultRoutesData: RoutesData = {
  routeList: {
    val: null,
    set: () => {},
  },
  secondaryTripList: defaultGetAndSet2<secondaryTripData[] | null>(null),
};

export interface LegInstancesTripsData {
  legInstanceTripList: getAndSet2<LegInstanceTrip[] | null>;
}

const defaultLegInstancesTrips: LegInstancesTripsData = {
  legInstanceTripList: defaultGetAndSet2<LegInstanceTrip[] | null>(null),
};

const defaultSecondaryLegsTripsData: secondaryLegsTripData = {
  secondaryLegTripList: defaultGetAndSet2<secondaryLegTripData[] | null>(null),
};

export const defaultEngineOnOffTrips: EngineOnOffTripsData = {
  engineOnOffTripList: defaultGetAndSet2<EngineOnOffTrip[] | null>(null),
};

const useEngineOnOffTripsData = (): EngineOnOffTripsData => {
  const [engineOnOffTripListStateful, engineOnOffTripListUpdater] = useState(
    defaultEngineOnOffTrips.engineOnOffTripList[0]
  );

  const instantiatedEngineOnOffTripsData: EngineOnOffTripsData = {
    engineOnOffTripList: [
      engineOnOffTripListStateful,
      engineOnOffTripListUpdater,
    ],
  };

  return instantiatedEngineOnOffTripsData;
};

const defaultEngineOnOffSecondaryTrips: EngineOnOffSecondaryTripsData = {
  engineOnOffSecondaryTripList: defaultGetAndSet2<
    EngineOnOffSecondaryTrip[] | null
  >(null),
};

const useEngineOnOffSecondaryTripsData = (): EngineOnOffSecondaryTripsData => {
  const [
    engineOnOffSecondaryTripListStateful,
    engineOnOffSecondaryTripListUpdater,
  ] = useState(
    defaultEngineOnOffSecondaryTrips.engineOnOffSecondaryTripList[0]
  );

  const instantiatedEngineOnOffSecondaryTripsData: EngineOnOffSecondaryTripsData =
    {
      engineOnOffSecondaryTripList: [
        engineOnOffSecondaryTripListStateful,
        engineOnOffSecondaryTripListUpdater,
      ],
    };

  return instantiatedEngineOnOffSecondaryTripsData;
};

const defaultApiData: apiData = {
  org: defaultOrganisationData,
  realTime: defaultRealTimeData,
  setRealTime: () => 0,
  energy: defaultEnergyData,
  performance: defaultPerformanceData,
  reports: defaultReportsData,
  vesselGroups: defaultVesselsGroupData,
  tagTypes: defaultTagTypesData,
  opModes: defaultOpModesData,
  tagEvents: defaultTagEventsData,
  trips: defaultTripsData,
  secondaryTrips: defaultSecondaryTripsData,
  rootAccess: defaultRootAccessData,
  routes: defaultRoutesData,
  voyages: defaultVoyagesData,
  vessels: undefined,
  legInstancesTrips: defaultLegInstancesTrips,
  secondaryLegsTripsData: defaultSecondaryLegsTripsData,
  engineOnOffTrips: defaultEngineOnOffTrips,
  engineOnOffSecondaryTrips: defaultEngineOnOffSecondaryTrips,
  alarmEvents: defaultAlarmEventsData,
  alarmTypes: defaultAlarmTypesData,
  signalTypes: defaultSignalTypesData,
};

const defaultRealTimeView: realTimeView = {
  vessel: null,
  setVessel: () => 0,
  signal: RealTimeSignal.SpeedOverGround,
  setSignal: () => 0,
};

const defaultDate = new Date();
// const defaultWeekDayNumWithZero = defaultDate.getDay() // Sunday is 0, saturday is 6
// const defaultWeekDayNum = (defaultWeekDayNumWithZero !== 0) ? defaultWeekDayNumWithZero : 7
// const defaultYear = defaultDate.getFullYear()
// const defaultMonth = defaultDate.getMonth()
// const defaultDayOfMonth = defaultDate.getDate()
// const defaultBeginningOfWeek = new Date(defaultYear, defaultMonth, defaultDayOfMonth-(defaultWeekDayNum-1))
// const defaultEndOfWeek = new Date(defaultYear, defaultMonth, defaultDayOfMonth+(7-defaultWeekDayNum))
const { fromTime: defaultFromTime, toTime: defaultToTime } = findTimeBounds(
  defaultDate,
  Timescale.Week
);

const defaultEnergyOverallView: energyOverallView = {
  fromTime: defaultFromTime, //new Date((new Date()).getTime() - (7*24*60*60*1000)),
  setFromTime: () => 0,
  toTime: defaultToTime, //new Date(),
  setToTime: () => 0,
  vessel: "all_vessels", //null,
  setVessel: () => 0,
  metric: OperatorTabMetric.FuelVolume,
  setMetric: () => 0,
  activity: VesselActivity.Total,
  setActivity: () => 0,
  timescale: Timescale.Week,
  setTimescale: () => 0,
  timePeriod: getWeek(defaultDate),
  setTimePeriod: () => 0,
  aggregation: Aggregation.Day,
  setAggregation: () => 0,
};

const defaultEnergyOperatorView: energyOperatorView = {
  vessels: null,
  setVessels: () => 0,
  operators: null,
  setOperators: () => 0,
  metric: OperatorTabMetric.FuelVolume,
  setMetric: () => 0,
  activities: [VesselActivity.Total],
  setActivities: () => 0,
  fromTime: defaultFromTime,
  setFromTime: () => 0,
  toTime: defaultToTime,
  setToTime: () => 0,
  timescale: Timescale.Week,
  setTimescale: () => 0,
  timePeriod: getWeek(defaultDate),
  setTimePeriod: () => 0,
  aggregation: Aggregation.Day,
  setAggregation: () => 0,
};

const defaultViewSettings: viewSettings = {
  realTime: defaultRealTimeView,
  energyOverall: defaultEnergyOverallView,
  energyOperator: defaultEnergyOperatorView,
};

const defaultContext: context = {
  vars: defaultVariables,
  data: defaultApiData,
  view: defaultViewSettings,
  // Can put more variables here (config, settings, data from backend etc.)
};

//--------------------------------------
// Instantiation of the stateful functionality for everything in context
//--------------------------------------

const useAuthenticationVars = (): authenticationVars => {
  const [accessTokenStateful, accessTokenUpdater] = useState(
    defaultVariables.auth.accessToken
  );
  const [refreshTokenStateful, refreshTokenUpdater] = useState(
    defaultVariables.auth.refreshToken
  );
  const [idTokenStateful, idTokenUpdater] = useState(
    defaultVariables.auth.idToken
  );
  const [usernameStateful, usernameUpdater] = useState(
    defaultVariables.auth.username
  );
  const [emailStateful, emailUpdater] = useState(defaultVariables.auth.email);
  const [isOperatorStateful, isOperatorUpdater] = useState(
    defaultVariables.auth.isOperator
  );
  const [isAdminStateful, isAdminUpdater] = useState(
    defaultVariables.auth.isAdmin
  );
  const [isOwnerStateful, isOwnerUpdater] = useState(
    defaultVariables.auth.isOwner
  );
  const [organisationIdStateful, organisationIdUpdater] = useState(
    defaultVariables.auth.organisationId
  );
  const [userGroupsStateful, userGroupsUpdater] = useState(
    defaultVariables.auth.userGroups
  );
  const [expStateful, expUpdater] = useState(defaultVariables.auth.exp);
  // const [accessLevelStateful, accesslevelUpdater] = useState(defaultVariables.auth.accessLevel)
  const instantiatedAuthenticationVars: authenticationVars = {
    idToken: idTokenStateful,
    setIdToken: idTokenUpdater,
    accessToken: accessTokenStateful,
    setAccessToken: accessTokenUpdater,
    refreshToken: refreshTokenStateful,
    setRefreshToken: refreshTokenUpdater,
    username: usernameStateful,
    setUsername: usernameUpdater,
    email: emailStateful,
    setEmail: emailUpdater,
    isOperator: isOperatorStateful,
    setIsOperator: isOperatorUpdater,
    isAdmin: isAdminStateful,
    setIsAdmin: isAdminUpdater,
    isOwner: isOwnerStateful,
    setIsOwner: isOwnerUpdater,
    organisationId: organisationIdStateful,
    setOrganisationId: organisationIdUpdater,
    userGroups: userGroupsStateful,
    setUserGroups: userGroupsUpdater,
    exp: expStateful,
    setExp: expUpdater,
    accessLevel: useState(defaultVariables.auth.accessLevel[0]),
  };
  return instantiatedAuthenticationVars;
};

const useLoginVars = (): loginVars => {
  const [loggedInStateful, loggedInUpdater] = useState(
    defaultVariables.login.loggedIn
  );
  const [errorCodeStateful, errorCodeUpdater] = useState(
    defaultVariables.login.errorCode
  );
  const [errorMessageStateful, errorMessageUpdater] = useState(
    defaultVariables.login.errorMessage
  );
  const instantiatedLoginVars: loginVars = {
    loggedIn: loggedInStateful,
    setLoggedIn: loggedInUpdater,
    errorCode: errorCodeStateful,
    setErrorCode: errorCodeUpdater,
    errorMessage: errorMessageStateful,
    setErrorMessage: errorMessageUpdater,
  };
  return instantiatedLoginVars;
};

export const useVariables = (): variables => {
  const vars: variables = {
    auth: useAuthenticationVars(),
    login: useLoginVars(),
  };
  return vars;
};

const useOrganisationData = (): organisationData => {
  const [organisationListStateful, organisationListUpdater] = useState(
    defaultOrganisationData.organisationList
  );
  const [organisationNameStateful, organisationNameUpdater] = useState(
    defaultOrganisationData.organisationName
  );
  const [vesselListStateful, vesselListUpdater] = useState(
    defaultOrganisationData.vesselList
  );
  const [destinationListStateful, destinationsListUpdater] = useState(
    defaultOrganisationData.destinationList.val
  );
  const [userListStateful, userListUpdater] = useState(
    defaultOrganisationData.userList
  );
  const [opModeListStateful, opModeListUpdater] = useState(
    defaultOrganisationData.opModeList.val
  );
  const [geoZoneListStateful, geoZonesListUpdater] = useState(
    defaultOrganisationData.geoZoneList.val
  );

  const instantiatedOrganisationName: organisationData = {
    organisationList: organisationListStateful,
    setOrganisationList: organisationListUpdater,
    organisationName: organisationNameStateful,
    setOrganisationName: organisationNameUpdater,
    vesselList: vesselListStateful,
    setVesselList: vesselListUpdater,
    destinationList: {
      val: destinationListStateful,
      set: destinationsListUpdater,
    },
    opModeList: {
      val: opModeListStateful,
      set: opModeListUpdater,
    },
    geoZoneList: {
      val: geoZoneListStateful,
      set: geoZonesListUpdater,
    },
    // destinationList: destinationListStateful,
    // setDestinationList: destinationsListUpdater,
    userList: userListStateful,
    setUserList: userListUpdater,
  };
  return instantiatedOrganisationName;
};

const useEnergyOverallData = (): energyOverallData => {
  const [totalFuelUsageStateful, totalFuelUsageUpdater] = useState(
    defaultEnergyOverallData.totalFuelUsage
  );
  const [graphFuelUsageStateful, graphFuelUsageUpdater] = useState(
    defaultEnergyOverallData.graphFuelUsage
  );
  const [totalDockFuelUsageStateful, totalDockFuelUsageUpdater] = useState(
    defaultEnergyOverallData.totalDockFuelUsage
  );
  const [graphDockFuelUsageStateful, graphDockFuelUsageUpdater] = useState(
    defaultEnergyOverallData.graphDockFuelUsage
  );
  const [overviewTotalWeekStateful, overviewTotalWeekUpdater] = useState(
    defaultEnergyOverallData.overviewTotalWeek
  );
  const instantiatedEnergyOverallData: energyOverallData = {
    totalFuelUsage: totalFuelUsageStateful,
    setTotalFuelUsage: totalFuelUsageUpdater,
    graphFuelUsage: graphFuelUsageStateful,
    setGraphFuelUsage: graphFuelUsageUpdater,
    totalDockFuelUsage: totalDockFuelUsageStateful,
    setTotalDockFuelUsage: totalDockFuelUsageUpdater,
    graphDockFuelUsage: graphDockFuelUsageStateful,
    setGraphDockFuelUsage: graphDockFuelUsageUpdater,
    overviewTotalWeek: overviewTotalWeekStateful,
    setOverviewTotalWeek: overviewTotalWeekUpdater,
  };
  return instantiatedEnergyOverallData;
};

const useEnergyOperatorsData = (): energyOperatorsData => {
  const [seriesStateful, seriesUpdater] = useState(
    defaultEnergyOperatorsData.series
  );
  const instantiatedEnergyOperatorsData: energyOperatorsData = {
    series: seriesStateful,
    setSeries: seriesUpdater,
  };
  return instantiatedEnergyOperatorsData;
};

const useEnergyData = (): energyData => {
  const instantiatedEnergyData: energyData = {
    overall: useEnergyOverallData(),
    operators: useEnergyOperatorsData(),
  };
  return instantiatedEnergyData;
};

const usePerformanceData = (): performanceData => {
  const [engineSpeedTorqueMapsStateful, engineSpeedTorqueMapsUpdater] =
    useState(defaultPerformanceData.engineSpeedTorqueMaps);
  const [sogDistributionStateful, sogDistributionUpdater] = useState(
    defaultPerformanceData.sogDistribution
  );
  const [
    totalEngineDieselRateSogMapStateful,
    totalEngineDieselRateSogMapUpdater,
  ] = useState(defaultPerformanceData.totalEngineDieselRateSogMap);
  const [totalEnginePowerSogMapStateful, totalEnginePowerSogMapUpdater] =
    useState(defaultPerformanceData.totalEnginePowerSogMap);
  const instantiatedPerformanceData: performanceData = {
    engineSpeedTorqueMaps: engineSpeedTorqueMapsStateful,
    setEngineSpeedTorqueMaps: engineSpeedTorqueMapsUpdater,
    sogDistribution: sogDistributionStateful,
    setSogDistribution: sogDistributionUpdater,
    totalEngineDieselRateSogMap: totalEngineDieselRateSogMapStateful,
    setTotalEngineDieselRateSogMap: totalEngineDieselRateSogMapUpdater,
    totalEnginePowerSogMap: totalEnginePowerSogMapStateful,
    setTotalEnginePowerSogMap: totalEnginePowerSogMapUpdater,
  };
  return instantiatedPerformanceData;
};

const useReportsData = (): reportsData => {
  const [reportListStateful, reportListUpdater] = useState(
    defaultReportsData.reportList
  );
  const instantiatedReportsData: reportsData = {
    reportList: reportListStateful,
    setReportList: reportListUpdater,
  };
  return instantiatedReportsData;
};

const useVesselGroupsData = (): vesselGroupsData => {
  const [groupListStateful, groupListUpdater] = useState<
    vesselGroupsEntry[] | null
  >(defaultVesselsGroupData.groupList.val);

  const instantiatedVesselsGroupData: vesselGroupsData = {
    groupList: {
      val: groupListStateful,
      set: groupListUpdater,
    },
  };

  return instantiatedVesselsGroupData;
};

const useTagTypesData = (): tagTypesData => {
  const [tagTypeListStateful, tagTypeListUpdater] = useState(
    defaultTagTypesData.tagTypeList.val
  );
  const instantiatedTagTypesData: tagTypesData = {
    tagTypeList: {
      val: tagTypeListStateful,
      set: tagTypeListUpdater,
    },
  };
  return instantiatedTagTypesData;
};

const useOpModesData = (): OpModesData => {
  const [opModeListStateful, opModeListUpdater] = useState(
    defaultOpModesData.opModeList.val
  );
  const instantiatedOpModesData: OpModesData = {
    opModeList: {
      val: opModeListStateful,
      set: opModeListUpdater,
    },
  };
  return instantiatedOpModesData;
};

const useSignalTypesData = (): SignalTypesData => {
  const [signalTypeList, setSignalTypeList] = useState<SignalType[] | null>(
    null
  );

  return {
    signalTypeList: {
      val: signalTypeList,
      set: setSignalTypeList,
    },
  };
};

const useVoyagesData = (): VoyagesData => {
  const [voyageListStateful, voyageListUpdater] = useState(
    defaultVoyagesData.voyageList.val
  );

  const instantiatedVoyagesData: VoyagesData = {
    voyageList: {
      val: voyageListStateful,
      set: voyageListUpdater,
    },
  };

  return instantiatedVoyagesData;
};

const useLegInstancesTripsData = (): LegInstancesTripsData => {
  // Destructure the tuple directly
  const [legInstanceTripListStateful, legInstanceTripListUpdater] = useState(
    defaultLegInstancesTrips.legInstanceTripList[0] // Accessing the first element of the tuple
  );

  // Use the tuple structure for instantiatedLegInstancesTripsData
  const instantiatedLegInstancesTripsData: LegInstancesTripsData = {
    legInstanceTripList: [
      legInstanceTripListStateful,
      legInstanceTripListUpdater,
    ],
  };

  return instantiatedLegInstancesTripsData;
};

const useTagEventsData = (): tagEventsData => {
  const [tagEventListStateful, tagEventListUpdater] = useState(
    defaultTagEventsData.tagEventList.val
  );
  return {
    tagEventList: {
      val: tagEventListStateful,
      set: tagEventListUpdater,
    },
  };
};

const useTripsData = (): tripsData => {
  return {
    tripList: useState(defaultApiData.trips.tripList[0]),
  };
};

const useSecondaryTripsData = (): secondaryTripsData => {
  return {
    secondaryTripList: useState<secondaryTripData[] | null>(
      defaultApiData.secondaryTrips.secondaryTripList[0]
    ),
  };
};

const useSecondaryLegsData = (): secondaryLegsTripData => {
  const [secondaryLegTripList, setSecondaryLegTripList] = useState<
    secondaryLegTripData[] | null
  >(null);

  return {
    secondaryLegTripList: [secondaryLegTripList, setSecondaryLegTripList],
  };
};

const useRoutesData = (): RoutesData => {
  const [routeListValue, setRouteListValue] = useState<Route[] | null>(
    defaultRoutesData.routeList.val
  );

  return {
    routeList: {
      val: routeListValue,
      set: setRouteListValue,
    },
  };
};

const useAlarmEventsData = (): AlarmEventsData => {
  const [alarmEventListValue, setAlarmEventListValue] = useState<
    AlarmEvent[] | null
  >(null);

  return {
    alarmEventList: {
      val: alarmEventListValue,
      set: setAlarmEventListValue,
    },
  };
};

const useAlarmTypesData = (): AlarmTypesData => {
  const [alarmTypeListValue, setAlarmTypeListValue] = useState<
    AlarmType[] | null
  >(null);

  return {
    alarmTypeList: {
      val: alarmTypeListValue,
      set: setAlarmTypeListValue,
    },
  };
};

const useRootAccessData = (): rootAccessData => {
  const [orgsStateful, orgsUpdater] = useState(defaultRootAccessData.orgs);
  const [vesselsStateful, vesselsUpdater] = useState(
    defaultRootAccessData.vessels
  );
  const [destsStateful, destsUpdater] = useState(defaultRootAccessData.dests);
  const [geoZonesStateful, geoZonesUpdater] = useState(
    defaultRootAccessData.geoZones
  );
  const [statusStateful, statusUpdater] = useState(
    defaultRootAccessData.status
  );
  const [dataIntegrityStatusStateful, setDataIntegrityStatusUpdater] = useState(
    defaultRootAccessData.dataIntegrityStatus
  );
  const instantiatedRootAccessData: rootAccessData = {
    orgs: orgsStateful,
    setOrgs: orgsUpdater,
    vessels: vesselsStateful,
    setVessels: vesselsUpdater,
    dests: destsStateful,
    setDests: destsUpdater,
    geoZones: geoZonesStateful,
    setGeoZones: geoZonesUpdater,
    status: statusStateful,
    setStatus: statusUpdater,
    dataIntegrityStatus: dataIntegrityStatusStateful,
    setDataIntegrityStatus: setDataIntegrityStatusUpdater,
  };
  return instantiatedRootAccessData;
};

export const useApiData = (): apiData => {
  const [realTimeStateful, realTimeUpdater] = useState<realTimeData | null>(
    defaultRealTimeData
  );
  const data: apiData = {
    org: {
      ...useOrganisationData(),
      opModeList: useOpModesData().opModeList,
    },
    realTime: realTimeStateful,
    setRealTime: realTimeUpdater,
    energy: useEnergyData(),
    performance: usePerformanceData(),
    reports: useReportsData(),
    vesselGroups: useVesselGroupsData(),
    tagTypes: useTagTypesData(),
    opModes: useOpModesData(),
    tagEvents: useTagEventsData(),
    trips: useTripsData(),
    rootAccess: useRootAccessData(),
    secondaryTrips: useSecondaryTripsData(),
    routes: useRoutesData(),
    voyages: useVoyagesData(),
    vessels: undefined,
    legInstancesTrips: useLegInstancesTripsData(),
    secondaryLegsTripsData: useSecondaryLegsData(),
    engineOnOffTrips: useEngineOnOffTripsData(),
    engineOnOffSecondaryTrips: useEngineOnOffSecondaryTripsData(),
    alarmEvents: useAlarmEventsData(),
    alarmTypes: useAlarmTypesData(),
    signalTypes: useSignalTypesData(),
  };
  return data;
};

const useRealTimeView = (): realTimeView => {
  const [vesselStateful, vesselUpdater] = useState(defaultRealTimeView.vessel);
  const [signalStateful, signalUpdater] = useState(defaultRealTimeView.signal);
  const view: realTimeView = {
    vessel: vesselStateful,
    setVessel: vesselUpdater,
    signal: signalStateful,
    setSignal: signalUpdater,
  };
  return view;
};

export const useEnergyOverallView = (): energyOverallView => {
  const [fromTimeStateful, fromTimeUpdater] = useState(
    defaultEnergyOverallView.fromTime
  );
  const [toTimeStateful, toTimeUpdater] = useState(
    defaultEnergyOverallView.toTime
  );
  const [vesselStateful, vesselUpdater] = useState(
    defaultEnergyOverallView.vessel
  );
  const [metricStateful, metricUpdater] = useState(
    defaultEnergyOverallView.metric
  );
  const [activityStateful, activityUpdater] = useState(
    defaultEnergyOverallView.activity
  );
  const [timescaleStateful, timescaleUpdater] = useState(
    defaultEnergyOverallView.timescale
  );
  const [timePeriodStateful, timePeriodUpdater] = useState(
    defaultEnergyOverallView.timePeriod
  );
  const [aggregationStateful, aggregationUpdater] = useState(
    defaultEnergyOverallView.aggregation
  );
  const view: energyOverallView = {
    fromTime: fromTimeStateful,
    setFromTime: fromTimeUpdater,
    toTime: toTimeStateful,
    setToTime: toTimeUpdater,
    vessel: vesselStateful,
    setVessel: vesselUpdater,
    metric: metricStateful,
    setMetric: metricUpdater,
    activity: activityStateful,
    setActivity: activityUpdater,
    timescale: timescaleStateful,
    setTimescale: timescaleUpdater,
    timePeriod: timePeriodStateful,
    setTimePeriod: timePeriodUpdater,
    aggregation: aggregationStateful,
    setAggregation: aggregationUpdater,
  };
  return view;
};

export const useEnergyOperatorView = (): energyOperatorView => {
  const [vesselsStateful, vesselsUpdater] = useState(
    defaultEnergyOperatorView.vessels
  );
  const [operatorsStateful, operatorsUpdater] = useState(
    defaultEnergyOperatorView.operators
  );
  const [metricStateful, metricUpdater] = useState(
    defaultEnergyOperatorView.metric
  );
  const [activitiesStateful, activitiesUpdater] = useState(
    defaultEnergyOperatorView.activities
  );
  const [fromTimeStateful, fromTimeUpdater] = useState(
    defaultEnergyOperatorView.fromTime
  );
  const [toTimeStateful, toTimeUpdater] = useState(
    defaultEnergyOperatorView.toTime
  );
  const [timescaleStateful, timescaleUpdater] = useState(
    defaultEnergyOperatorView.timescale
  );
  const [timePeriodStateful, timePeriodUpdater] = useState(
    defaultEnergyOperatorView.timePeriod
  );
  const [aggregationStateful, aggregationUpdater] = useState(
    defaultEnergyOperatorView.aggregation
  );

  const view: energyOperatorView = {
    vessels: vesselsStateful,
    setVessels: vesselsUpdater,
    operators: operatorsStateful,
    setOperators: operatorsUpdater,
    metric: metricStateful,
    setMetric: metricUpdater,
    activities: activitiesStateful,
    setActivities: activitiesUpdater,
    fromTime: fromTimeStateful,
    setFromTime: fromTimeUpdater,
    toTime: toTimeStateful,
    setToTime: toTimeUpdater,
    timescale: timescaleStateful,
    setTimescale: timescaleUpdater,
    timePeriod: timePeriodStateful,
    setTimePeriod: timePeriodUpdater,
    aggregation: aggregationStateful,
    setAggregation: aggregationUpdater,
  };
  return view;
};

export const useViewSettings = (): viewSettings => {
  const view: viewSettings = {
    realTime: useRealTimeView(),
    energyOverall: useEnergyOverallView(),
    energyOperator: useEnergyOperatorView(),
  };
  return view;
};

export interface secondaryTripData {
  org_id: string;
  vessel_id: string;
  route_id?: string;
  path_id: string;
  end_timestring: string;
  lats: number[];
  lons: number[];
  sogs: number[];
  diesel_rates: number[];
  elec_powers: number[];
  delta_dists: number[];
  delta_times: number[];
  [key: string]: any;
  timestring: string;
  dists: number[];
  times: number[];
}

export interface secondaryLegTripData {
  vesselroute_id: string;
  timestring: string;
  delta_dists: number[];
  delta_times: number[];
  diesel_rates: number[];
  elec_powers: number[];
  end_timestring: string;
  lats: number[];
  lons: number[];
  org_id: string;
  path_id: string;
  route_id: string;
  sogs: number[];
  vessel_id: string;
  dists: number[];
  times: number[];
}

export interface secondaryLegsTripData {
  secondaryLegTripList: getAndSet2<secondaryLegTripData[] | null>;
}

export interface secondaryTripsData {
  secondaryTripList: getAndSet2<secondaryTripData[] | null>;
}

export interface SecondaryTripsMapProps {
  secondaryTripData: {
    lats: number[];
    lons: number[];
  };
}

interface RoutesData {
  [x: string]: any;
  routeList: getAndSet<Route[] | null>;
}

export interface SecondaryTripsGraphProps {
  secondaryTripData: secondaryTripData | secondaryLegTripData;
  chosenTime: number;
  signals: string[];
}

type ValueType = "speed" | "consumption" | "electrical";

export interface CustomTooltipProps {
  active: boolean | undefined;
  payload: any[] | undefined;
  label: string | number | undefined;
  valueType: ValueType;
}

export interface DieselRate {
  index: number;
  value: number;
}

export interface Route {
  id: string;
  orgId: string;
  routeId: string;
  name: string;
  description?: string;
  state: EntityState;
  destinationIds: string[];
}

export interface CustomTextInputProps {
  title: string;
  tooltipText: string;
  fieldPrompt: string;
  fieldHandle: string;
  warning: string | null;
  defaultValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export type DestinationOption = {
  value: string;
  name: string;
};

export interface MultiDropdownCheckboxInputProps {
  title: string;
  tooltipText: string;
  prompt: string;
  selectedValues: string[];
  destinationOptions: DestinationOption[];
  onSelectionChange: (selected: string[]) => void;
  warning: string | null;
}

export interface AddVoyageModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  fetchDataVoyages: (opModeId?: string, vesselId?: string) => void;
}

export interface textEntryWarningTextProps {
  prompt: string;
  name: string;
  type: string;
  warningStatus: boolean;
  warningText: string;
  defaultValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface GenericTableWithDeleteIconRow {
  id: string;
  contents: (string | JSX.Element)[];
  actions?: JSX.Element | null;
  onDelete?: (id: string) => void;
}

export interface Signal {
  description: string;
  name: string;
  status_code: string;
}

export interface dataIntegrityEntry {
  org_id: string;
  vessel_id: string;
  signals: Signal[];
  status_code: string;
  timestamp: string;
}

export interface LegInstanceTrip {
  [key: string]: any;
  timestring: string;
  orgId: string;
  vesselId: string;
  startTimestamp: string;
  endTimestamp: string;
  pathId: string;
  routeId: string;
  vesselRouteId: string;
  operators: string[];
  dieselAverage: number | null;
  dieselTotal: number | null;
  electricAverage: number | null;
  electricTotal: number | null;
  distanceTotal: number | null;
  timeTotal: number | null;
}

export interface FuelMasses {
  MGO: number;
}

export interface OperationModeTimes {
  [key: string]: { N: string };
}

export interface VoyageTrip {
  vesselId: string;
  timestring: string;
  end_timestring: string;
  avg_diesel: number | null;
  avg_elec: number | null;
  cargo_end: number;
  cargo_median: number;
  cargo_start: number;
  cargo_types: any[];
  current_dir: number | null;
  current_spd: number | null;
  end_dest_id: string | null;
  end_dest_in_eu: boolean | null;
  fuel_masses: FuelMasses;
  op_mode_times: OperationModeTimes;
  operators: any[];
  start_dest_id: string | null;
  start_dest_in_eu: boolean | null;
  total_diesel: number | null;
  total_dist: number | null;
  total_elec: number | null;
  total_time: number | null;
  transport_work: string | null;
  trim: string | null;
  wind_dir: number | null;
  wind_spd: number | null;
  routeId?: string;
}
// EngineOnOffTrip interface

export interface EngineOnOffTrip {
  [key: string]: any;
  timestring: string;
  orgId: string;
  vesselId: string;
  startTimestamp: string;
  endTimestamp: string;
  operators: string[];
  dieselAverage: number | null;
  dieselTotal: number | null;
  electricAverage: number | null;
  electricTotal: number | null;
  distanceTotal: number | null;
  timeTotal: number | null;
}

export interface EngineOnOffSecondaryTrip {
  vessel_id: string;
  timestring: string;
  delta_dists: number[];
  delta_times: number[];
  diesel_rates: number[];
  dists: number[];
  elec_powers: number[];
  end_timestring: string;
  lats: number[];
  lons: number[];
  org_id: string;
  sogs: number[];
  times: number[];
}

export interface EngineOnOffTripsData {
  engineOnOffTripList: getAndSet2<EngineOnOffTrip[] | null>;
}

export interface EngineOnOffSecondaryTripsData {
  engineOnOffSecondaryTripList: getAndSet2<EngineOnOffSecondaryTrip[] | null>;
}

export interface EditGeoZoneModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  GeoZonesListFiltered: geoZonesEntry[] | null;
  editGeoZoneName: string;
  geoZoneId: string | null;
  orgId: string | null;
}

//--------------------------------------
// Other
//--------------------------------------

export function logoutContext(
  vars: variables,
  data: apiData,
  view: viewSettings
) {
  vars.auth.setIdToken(defaultVariables.auth.idToken);
  vars.auth.setAccessToken(defaultVariables.auth.accessToken);
  vars.auth.setRefreshToken(defaultVariables.auth.refreshToken);
  vars.auth.setUsername(defaultVariables.auth.username);
  vars.auth.setEmail(defaultVariables.auth.email);
  vars.auth.setIsOperator(defaultVariables.auth.isOperator);
  vars.auth.setIsAdmin(defaultVariables.auth.isAdmin);
  vars.auth.setIsOwner(defaultVariables.auth.isOwner);
  vars.auth.setOrganisationId(defaultVariables.auth.organisationId);
  vars.auth.setUserGroups(defaultVariables.auth.userGroups);
  vars.auth.setExp(defaultVariables.auth.exp);
  vars.auth.accessLevel[1](defaultVariables.auth.accessLevel[0]);
  vars.login.setLoggedIn(false);
  vars.login.setErrorCode(defaultVariables.login.errorCode);
  vars.login.setErrorMessage(defaultVariables.login.errorMessage);
  data.org.setOrganisationList(defaultApiData.org.organisationList);
  data.org.setOrganisationName(defaultApiData.org.organisationName);
  data.org.setVesselList(defaultApiData.org.vesselList);
  data.org.destinationList.set(defaultApiData.org.destinationList.val);
  data.org.setUserList(defaultApiData.org.userList);
  data.setRealTime(defaultRealTimeData);
  data.engineOnOffTrips.engineOnOffTripList[1](
    defaultEngineOnOffTrips.engineOnOffTripList[0]
  );
  data.engineOnOffSecondaryTrips.engineOnOffSecondaryTripList[1](
    defaultEngineOnOffSecondaryTrips.engineOnOffSecondaryTripList[0]
  );
  data.energy.overall.setTotalFuelUsage(
    defaultApiData.energy.overall.totalFuelUsage
  );
  data.energy.overall.setGraphFuelUsage(
    defaultApiData.energy.overall.graphFuelUsage
  );
  data.energy.overall.setTotalDockFuelUsage(
    defaultApiData.energy.overall.totalDockFuelUsage
  );
  data.energy.overall.setGraphDockFuelUsage(
    defaultApiData.energy.overall.graphDockFuelUsage
  );
  data.energy.overall.setOverviewTotalWeek(
    defaultApiData.energy.overall.overviewTotalWeek
  );
  data.energy.operators.setSeries(defaultApiData.energy.operators.series);
  data.performance.setEngineSpeedTorqueMaps(
    defaultPerformanceData.engineSpeedTorqueMaps
  );
  data.performance.setSogDistribution(defaultPerformanceData.sogDistribution);
  data.performance.setTotalEngineDieselRateSogMap(
    defaultPerformanceData.totalEngineDieselRateSogMap
  );
  data.performance.setTotalEnginePowerSogMap(
    defaultPerformanceData.totalEnginePowerSogMap
  );
  data.org.geoZoneList.set(defaultApiData.org.geoZoneList.val);
  data.reports.setReportList(defaultApiData.reports.reportList);
  data.tagTypes.tagTypeList.set(defaultTagTypesData.tagTypeList.val);
  data.opModes.opModeList.set(defaultOpModesData.opModeList.val);
  data.tagEvents.tagEventList.set(defaultTagEventsData.tagEventList.val);
  data.trips.tripList[1](defaultTripsData.tripList[0]);
  data.secondaryTrips.secondaryTripList =
    defaultSecondaryTripsData.secondaryTripList;
  data.rootAccess.setOrgs(defaultRootAccessData.orgs);
  data.rootAccess.setVessels(defaultRootAccessData.vessels);
  data.rootAccess.setDests(defaultRootAccessData.dests);
  data.rootAccess.setStatus(defaultRootAccessData.status);
  data.routes.routeList.set(defaultRoutesData.routeList.val);
  data.vesselGroups.groupList.set(defaultVesselsGroupData.groupList.val);
  data.alarmTypes.alarmTypeList.set(
    defaultApiData.alarmTypes.alarmTypeList.val
  );
  data.signalTypes.signalTypeList.set(
    defaultSignalTypesData.signalTypeList.val
  );
  view.realTime.setVessel(defaultRealTimeView.vessel);
  view.realTime.setSignal(defaultRealTimeView.signal);
  view.energyOverall.setFromTime(defaultEnergyOverallView.fromTime);
  view.energyOverall.setToTime(defaultEnergyOverallView.toTime);
  view.energyOverall.setVessel(defaultEnergyOverallView.vessel);
  view.energyOverall.setMetric(defaultEnergyOverallView.metric);
  view.energyOverall.setActivity(defaultEnergyOverallView.activity);
  view.energyOverall.setTimescale(defaultEnergyOverallView.timescale);
  view.energyOverall.setTimePeriod(defaultEnergyOverallView.timePeriod);
  view.energyOverall.setAggregation(defaultEnergyOverallView.aggregation);
  view.energyOperator.setVessels(defaultEnergyOperatorView.vessels);
  view.energyOperator.setOperators(defaultEnergyOperatorView.operators);
  view.energyOperator.setMetric(defaultEnergyOperatorView.metric);
  view.energyOperator.setActivities(defaultEnergyOperatorView.activities);
  view.energyOperator.setFromTime(defaultEnergyOperatorView.fromTime);
  view.energyOperator.setToTime(defaultEnergyOperatorView.toTime);
  view.energyOperator.setTimescale(defaultEnergyOperatorView.timescale);
  view.energyOperator.setTimePeriod(defaultEnergyOperatorView.timePeriod);
  view.energyOperator.setAggregation(defaultEnergyOperatorView.aggregation);
}

export const AppContext = React.createContext(defaultContext);

export const useAppContext = () => useContext(AppContext);
