import { useState } from "react"
import { makeMenuModal } from "../../../components/Modal"
import { useAppContext } from "../../../context/variables"
import { EditGeographicalDestModal } from "./EditGeographicalDestModal"
import { EditStateDestModal } from "./EditStateDestModal"
import { RenameDestModal } from "./RenameDestModal"
import { makeConfirmModal } from "../../../components/Modal"
import { strNullToString } from "../../../helpers/stringHelpers"
import useManageData from "../../../apiComms/manageData"
import { EntityState } from "../../../context/types"
import { HiOutlineTrash } from "react-icons/hi"
import { FiThumbsUp, FiThumbsDown } from "react-icons/fi"
import { GiTexas, GiNuclearBomb } from "react-icons/gi"
import { TbMap2 } from "react-icons/tb"
import { TbBulb, TbBulbOff, TbCursorText } from "react-icons/tb"

function DeleteDestModal(open: boolean, setOpen: (open: boolean)=>void, managedDestId: string|null, onCloseManageDestModal: ()=>void) {
  const { data } = useAppContext()
  const { apiDeleteDests } = useManageData()
  const theDestId = strNullToString(managedDestId)
  const dest = data.org.destinationList.val?.find((dest)=>dest.lid === managedDestId)
  const destName = dest?.displayName
  const destOrg = dest?.orgUid
  function onConfirmClick() {
    // console.log("Hit confirm delete dest")
    // console.log("deleted the dest: (", theDestId, "in the org "+destOrg+")")
    apiDeleteDests(destOrg, managedDestId)
    setOpen(false)
    onCloseManageDestModal()
  }
  return makeConfirmModal(
    "Confirm deleting destination", 
    ['You are about to PERMANENTLY delete', 
      '"'+destName+'" ('+theDestId+')', 
      'from the database'
    ], 
    "Confirm: Delete destination", 
    onConfirmClick, open, setOpen, true
  )
}

function AcceptDestModal(open: boolean, setOpen: (open: boolean)=>void, managedDestId: string|null, onCloseManageDestModal: ()=>void) {
  const { data } = useAppContext()
  const { apiPutDestsState } = useManageData()
  // const theDestId = strNullToString(managedDestId)
  const dest = data.org.destinationList.val?.find((dest)=>dest.lid === managedDestId)
  const destName = dest?.displayName
  const destOrg = dest?.orgUid
  function onConfirmClick() {
    // console.log("Accepted the dest: ("+theDestId+" in the org "+destOrg+")")
    apiPutDestsState(destOrg, managedDestId, EntityState.Active)
    setOpen(false)
    onCloseManageDestModal()
  }
  return makeConfirmModal("Confirm accepting destination", 'You are about to accept "'+destName+'"', "Confirm: Accept destination", onConfirmClick, open, setOpen)
}

function RejectDestModal(open: boolean, setOpen: (open: boolean)=>void, managedDestId: string|null, onCloseManageDestModal: ()=>void) {
  const { data } = useAppContext()
  const { apiPutDestsState } = useManageData()
  // const theDestId = strNullToString(managedDestId)
  const dest = data.org.destinationList.val?.find((dest)=>dest.lid === managedDestId)
  const destName = dest?.displayName
  const destOrg = dest?.orgUid
  function onConfirmClick() {
    // console.log("Rejected the dest: ("+theDestId+" in the org "+destOrg+")")
    apiPutDestsState(destOrg, managedDestId, EntityState.Rejected)
    setOpen(false)
    onCloseManageDestModal()
  }
  return makeConfirmModal("Confirm rejecting destination", 'You are about to reject "'+destName+'"', "Confirm: Reject destination", onConfirmClick, open, setOpen)
}

function ActivateDestModal(open: boolean, setOpen: (open: boolean)=>void, managedDestId: string|null, onCloseManageDestModal: ()=>void) {
  const { data } = useAppContext()
  const { apiPutDestsState } = useManageData()
  // const theDestId = strNullToString(managedDestId)
  const dest = data.org.destinationList.val?.find((dest)=>dest.lid === managedDestId)
  const destName = dest?.displayName
  const destOrg = dest?.orgUid
  function onConfirmClick() {
    // console.log("Activated the dest: ("+theDestId+" in the org "+destOrg+")")
    apiPutDestsState(destOrg, managedDestId, EntityState.Active)
    setOpen(false)
    onCloseManageDestModal()
  }
  return makeConfirmModal("Confirm activating destination", 'You are about to activate "'+destName+'"', "Confirm: Activate destination", onConfirmClick, open, setOpen)
}

function DeactivateDestModal(open: boolean, setOpen: (open: boolean)=>void, managedDestId: string|null, onCloseManageDestModal: ()=>void) {
  const { data } = useAppContext()
  const { apiPutDestsState } = useManageData()
  // const theDestId = strNullToString(managedDestId)
  const dest = data.org.destinationList.val?.find((dest)=>dest.lid === managedDestId)
  const destName = dest?.displayName
  const destOrg = dest?.orgUid
  function onConfirmClick() {
    // console.log("Deactivated the dest: ("+theDestId+" in the org "+destOrg+")")
    apiPutDestsState(destOrg, managedDestId, EntityState.Inactive)
    setOpen(false)
    onCloseManageDestModal()
  }
  return makeConfirmModal("Confirm deactivating destination", 'You are about to deactivate "'+destName+'"', "Confirm: Deactivate destination", onConfirmClick, open, setOpen)
}

function RemoveDestModal(open: boolean, setOpen: (open: boolean)=>void, managedDestId: string|null, onCloseManageDestModal: ()=>void) {
  const { data } = useAppContext()
  const { apiPutDestsState } = useManageData()
  // const theDestId = strNullToString(managedDestId)
  const dest = data.org.destinationList.val?.find((dest)=>dest.lid === managedDestId)
  const destName = dest?.displayName
  const destOrg = dest?.orgUid
  function onConfirmClick() {
    // console.log("Removed the dest: ("+theDestId+" in the org "+destOrg+")")
    apiPutDestsState(destOrg, managedDestId, EntityState.Removed)
    setOpen(false)
    onCloseManageDestModal()
  }
  return makeConfirmModal(
    "Confirm removing destination", 
    'You are about to remove "'+destName+'"', 
    "Confirm: Remove destination", 
    onConfirmClick, open, setOpen, true)
}


export function ManageDestModal(open: boolean, setOpen: (open: boolean)=>void, managedDestId: string|null, setManagedDestId: (id: string|null)=>void) {
  const [renameDestModalOpen, setRenameDestModalOpen] = useState(false)
  const [editGeographicalDestModalOpen, setEditGeographicalDestModalOpen] = useState(false)
  const [acceptDestModalOpen, setAcceptDestModalOpen] = useState(false)
  const [rejectDestModalOpen, setRejectDestModalOpen] = useState(false)
  const [activateDestModalOpen, setActivateDestModalOpen] = useState(false)
  const [deactivateDestModalOpen, setDeactivateDestModalOpen] = useState(false)
  const [removeDestModalOpen, setRemoveDestModalOpen] = useState(false)
  const [editStateDestModalOpen, setEditStateDestModalOpen] = useState(false)
  const [deleteDestModalOpen, setDeleteDestModalOpen] = useState(false)
  const { data, vars } = useAppContext()

  const destinationList = data.org.destinationList.val
  const destMaybe = destinationList?.find((dest)=>dest.lid === managedDestId)
  const destName = (destMaybe === undefined) ? "N/A" : destMaybe.displayName
  const title = (managedDestId !== null) ? 'Manage destination "'+destName+'"' : "Manage destination"

  const destState = destMaybe?.state
  const removeOption = {buttonIcon: <HiOutlineTrash/>, buttonText: "Remove", onClick: ()=>{
    // console.log("clicked Remove")
    setRemoveDestModalOpen(true)
  }, orangeBg: true}
  const standardMenuOptions = (destState !== EntityState.Suggested) ? [
    {buttonIcon: <TbCursorText/>, buttonText: "Rename", onClick: ()=>{
      // console.log("clicked Rename")
      setRenameDestModalOpen(true)
    }},
    {buttonIcon: <TbMap2/>, buttonText: "Edit location", onClick: ()=>{
      // console.log("clicked Edit location")
      setEditGeographicalDestModalOpen(true)
    }},
  ] : []
  const stateMenuOptions = 
    (destState === EntityState.Suggested) ? [
      {buttonIcon: <FiThumbsUp/>, buttonText: "Accept suggestion", onClick: ()=>{
        // console.log("clicked Accept suggestion")
        setAcceptDestModalOpen(true)
      }},
      {buttonIcon: <FiThumbsDown/>, buttonText: "Reject suggestion", onClick: ()=>{
        // console.log("clicked Reject suggestion")
        setRejectDestModalOpen(true)
      }},
    ]
    : (destState === EntityState.Active) ? [
      {buttonIcon: <TbBulbOff/>, buttonText: "Deactivate", onClick: ()=>{
        // console.log("clicked Deactivate")
        setDeactivateDestModalOpen(true)
      }},
      removeOption,
    ]
    : (destState === EntityState.Inactive) ? [
      {buttonIcon: <TbBulb/>, buttonText: "Activate", onClick: ()=>{
        // console.log("clicked Activate")
        setActivateDestModalOpen(true)
      }},
      removeOption,
    ]
    : []
  
  const nonRootMenuOptions = standardMenuOptions.concat(stateMenuOptions)

  const rootOnlyMenuOptions = [
    {buttonIcon: <GiTexas/>, buttonText: "Edit state (root only)", onClick: ()=>{
      // console.log("clicked Edit state (root)")
      setEditStateDestModalOpen(true)
    }},
    {buttonIcon: <GiNuclearBomb/>, buttonText: "PERMA-Delete (root only)", onClick: ()=>{
      // console.log("clicked Delete (root)")
      setDeleteDestModalOpen(true)
    }, orangeBg: true},
  ]
  const isRoot = vars.auth.userGroups !== null && vars.auth.userGroups.includes("root")
  const menuOptions = isRoot ? nonRootMenuOptions.concat(rootOnlyMenuOptions) : nonRootMenuOptions
  const modals: JSX.Element[] = [
    RenameDestModal(renameDestModalOpen, setRenameDestModalOpen, managedDestId, onClose),
    EditGeographicalDestModal(editGeographicalDestModalOpen, setEditGeographicalDestModalOpen, managedDestId, onClose),
    AcceptDestModal(acceptDestModalOpen, setAcceptDestModalOpen, managedDestId, onClose),
    RejectDestModal(rejectDestModalOpen, setRejectDestModalOpen, managedDestId, onClose),
    ActivateDestModal(activateDestModalOpen, setActivateDestModalOpen, managedDestId, onClose),
    DeactivateDestModal(deactivateDestModalOpen, setDeactivateDestModalOpen, managedDestId, onClose),
    RemoveDestModal(removeDestModalOpen, setRemoveDestModalOpen, managedDestId, onClose),
    EditStateDestModal(editStateDestModalOpen, setEditStateDestModalOpen, managedDestId, onClose),
    DeleteDestModal(deleteDestModalOpen, setDeleteDestModalOpen, managedDestId, onClose),
  ]
  function onClose() {
    setManagedDestId(null)
    setOpen(false)
  }
  return makeMenuModal(title, menuOptions, modals, open, onClose)
}

// editStateDest
